import React, { Component } from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import styled from "styled-components";
import { Link } from "react-router-dom";

class ModelWakel extends Component {
  render() {
    return (
      <ProductConsumer>
        {(value) => {
          const {
            RW_modalOpen,
            RW_CloseModal,
            Rw_serchwakil,
            RW_jwalakan,
            AddProChange,
            RW_zh_barez,
            RW_nawibrez,
            RW_from,
            RW_to,
            RW_setstInputEmpty,
          } = value;

          if (!RW_modalOpen) {
            return null;
          } else {
            return (
              <ModalContainer>
                <div className="container">
                  <div className="row">
                    <div
                      id="modal"
                      className="col-lg-8 col-sm-12 mx-auto col-md-10 col-lg-6 text-center text-capitalize p-5"
                    >
                      <div className="row">
                        <div className="col-md-12 mb2">
                          <h3>ڕاپۆرتی وەکیل</h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            onChange={AddProChange}
                            name="RW_nawibrez"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? Rw_serchwakil(event)
                                : null
                            }
                            value={RW_nawibrez}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            onChange={AddProChange}
                            onClick={RW_setstInputEmpty}
                            name="RW_zh_barez"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? Rw_serchwakil(event)
                                : null
                            }
                            value={RW_zh_barez === 0 ? "" : RW_zh_barez}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-3">
                          <label className="text-danger  font-weight-bold">
                            وەکیل
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-danger  font-weight-bold">
                              هەتاوەکو
                            </label>
                            <br />
                            <input
                              type="date"
                              onChange={AddProChange}
                              name="RW_to"
                              value={RW_to}
                              className="selectpicker btn btn-dark"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-danger  font-weight-bold">
                              لە
                            </label>
                            <br />
                            <input
                              type="date"
                              onChange={AddProChange}
                              name="RW_from"
                              value={RW_from}
                              className="selectpicker btn btn-dark"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <Link to="/Product-Raport">
                            <button
                              className="btn btn-success"
                              onClick={() => RW_CloseModal()}
                            >
                              چوونەدەرەوە
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/Raport-wakel">
                            <button
                              className="btn btn-info"
                              onClick={(e) => {
                                RW_jwalakan(e);
                              }}
                            >
                              هەموو جووڵەکان
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalContainer>
            );
          }
        }}
      </ProductConsumer>
    );
  }
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  #modal {
    background: #f3e29f;
  }
`;

export default ModelWakel;
