import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import "./rent.css";

const FooterOfRent = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            R_kreysayara,
            R_totalID,
            R_waslKrdn,
            R_waslKrdnID,
            R_total,
            R_kreykrekar,
            R_isChecked,
            R_rasidWakildolar,
            R_rasidWakildinar,
            R_frosth,
            R_dashkan,
            R_kamkrdnawayNrx$,
            R_rasidWakildolarNew,
            R_rasidWakildinarNew,
            R_paraDanID,
            R_paraDan$,
            R_kamkrdnawayNrxID,
            R_dashkanID,
            R_joripara,
            dolartodinar,
            dinartodolar,
            R_note
          } = value;
          return (
            <>
              {R_joripara === "دۆلار" ? (
                <>
                  <div className="row ">
                    <div className="col-5 ">
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={R_total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={dolartodinar(
                            R_dashkan !== 0 ? R_kamkrdnawayNrx$() : R_total
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"ID"}
                        />
                      </h3>
                      <hr />
                      {R_dashkan !== 0 && (
                        <>
                          <h3 className="mb3 tc">
                            <NumberFormat
                              name="R_dashkan"
                              value={R_dashkan}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              className=" border-0"
                            />
                          </h3>
                          <hr />
                          <h3 className="mb3 tc">
                            <NumberFormat
                              value={
                                R_dashkan !== 0 ? R_kamkrdnawayNrx$() : R_total
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </h3>
                          <hr />
                        </>
                      )}
                      {R_paraDan$ !== 0 && (
                        <>
                          {" "}
                          <h3 className="mb3 tc">
                            <NumberFormat
                              value={R_paraDan$ === "" ? 0 : R_paraDan$}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              className=" border-0"
                            />
                          </h3>
                          {R_paraDan$ !== R_total && (
                            <>
                              <hr />{" "}
                              <h3 className="mb3 tc">
                                <NumberFormat
                                  value={
                                    R_paraDan$ !== ""
                                      ? R_waslKrdn()
                                      : R_kamkrdnawayNrx$()
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </h3>
                              <hr />
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-4">
                      <h3 className=" ml5 mb3">:$ کۆی گشتی</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:ID کۆی گشتی</h3>
                      <hr />
                      {R_dashkan !== 0 && (
                        <>
                          {" "}
                          <h3 className=" ml5 mb3">:کەمکردنەوە</h3>
                          <hr />
                          <h3 className=" ml5 mb3">:$ پارەی ماوە</h3>
                        </>
                      )}
                      {R_paraDan$ !== 0 && (
                        <>
                          <hr />
                          <h3 className=" ml5 mb3">:$ پارەی دراو</h3>

                          {R_paraDan$ !== R_total && (
                            <>
                              <hr />
                              <h3 className=" ml5 mb3">:$ پارەی نەدراو</h3>
                              <hr />
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-5 tc">
                      <div className="row">
                        <div className="col-3 mb3">
                          {R_isChecked === true ? (
                            <>
                              <h3>
                                <NumberFormat
                                  value={R_kreysayara}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={R_joripara === "دۆلار" ? "$" : "ID"}
                                />
                              </h3>
                              <hr />
                              <h3>
                                <NumberFormat
                                  value={R_kreykrekar}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={R_joripara === "دۆلار" ? "$" : "ID"}
                                />
                              </h3>
                              <hr />
                            </>
                          ) : null}
                          {R_frosth === "فرۆشتنی معمیل" ? (
                            <>
                              <h3>
                                <NumberFormat
                                  value={R_rasidWakildinarNew}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              </h3>
                              <hr />
                              <h3>
                                <NumberFormat
                                  value={R_rasidWakildolar}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </h3>
                              <hr />
                              <h3>
                                <NumberFormat
                                  value={R_rasidWakildolarNew}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </h3>
                              <hr />
                            </>
                          ) : null}
                        </div>
                        <div className="col-9 text-right mb3 ">
                          {R_isChecked === true ? (
                            <>
                              <h3 className="ml5">:کرێ سەیارە</h3>
                              <hr />
                              <h3 className="ml5">:کرێ کرێکار</h3>
                              <hr />
                            </>
                          ) : null}
                          {R_frosth === "فرۆشتنی معمیل" ? (
                            <>
                              <h3 className="ml5">:ID قەرزی کۆن</h3>
                              <hr />
                              <h3 className="ml5">:$ قەرزی کۆن</h3>
                              <hr />
                              <h3 className="ml5"> :$ قەرزی نوێ</h3>
                              <hr />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* start ID */}

              {R_joripara === "دینار" ? (
                <>
                  <div className="row ">
                    <div className="col-5 ">
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={R_totalID}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"ID"}
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={dinartodolar(
                            R_dashkanID !== 0 ? R_kamkrdnawayNrxID() : R_totalID
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          name="R_dashkanID"
                          value={R_dashkanID}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"ID"}
                          className=" border-0"
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={
                            R_dashkanID !== 0 ? R_kamkrdnawayNrxID() : R_totalID
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"ID"}
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={R_paraDanID === "" ? 0 : R_paraDanID}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"ID"}
                          className=" border-0"
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={
                            R_paraDanID !== ""
                              ? R_waslKrdnID()
                              : R_kamkrdnawayNrxID()
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"ID"}
                        />
                      </h3>
                      <hr />
                    </div>
                    <div className="col-4">
                      <h3 className=" ml5 mb3">:ID کۆی گشتی</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:$ کۆی گشتی</h3>
                      <hr />

                      <h3 className=" ml5 mb3">:کەمکردنەوە</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:ID پارەی ماوە</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:ID پارەی دراو</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:ID پارەی نەدراو</h3>
                      <hr />
                    </div>

                    <div className="col-5">
                      <div className="row">
                        <div className="col-3 mb3 tc">
                          {R_isChecked === true ? (
                            <>
                              <h3>
                                <NumberFormat
                                  value={R_kreysayara}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              </h3>
                              <hr />
                              <h3>
                                <NumberFormat
                                  value={R_kreykrekar}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              </h3>
                              <hr />
                            </>
                          ) : null}
                          {R_frosth === "فرۆشتنی معمیل" ? (
                            <>
                              <h3>
                                <NumberFormat
                                  value={R_rasidWakildolarNew}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </h3>
                              <hr />
                              <h3>
                                <NumberFormat
                                  value={R_rasidWakildinar}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              </h3>
                              <hr />
                              <h3>
                                <NumberFormat
                                  value={R_rasidWakildinarNew}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              </h3>
                              <hr />
                            </>
                          ) : null}
                        </div>
                        <div className="col-9 text-right mb3">
                          {R_isChecked === true ? (
                            <>
                              <h3 className="ml5">:کرێ سەیارە</h3>
                              <hr />
                              <h3 className="ml5">:کرێ کرێکار</h3>
                              <hr />
                            </>
                          ) : null}
                          {R_frosth === "فرۆشتنی معمیل" ? (
                            <>
                              <h3 className="ml5">:$ قەرزی کۆن</h3>
                              <hr />
                              <h3 className="ml5">:ID قەرزی کۆن</h3>
                              <hr />
                              <h3 className="ml5">:ID قەرزی نوێ</h3>
                              <hr />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="text-right fk f4">
                {R_note}:تێبینی
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfRent;
