import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import FormMob from "./FormMob";
import { Link } from "react-router-dom";

const WakilMob = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            Ap_code,
            Ap_naw,
            Ap_mob,
            Ap_jor,
            Ap_getWakilDataBySearchWakil,
            edit,
            clear_edit,
            Ap_setWakilEmpty,
            Ap_updtae_Wakil,
            Ap_onSaveWakel,
            Ap_tofirstInput,
            Ap_showrasid,
            Ap_show,
            role,
            L_user,
          } = value;
          return (
            <>
              <form>
                <div className="mt5">
                  <h1
                    className="tc border p-3  border-warning text-warning"
                    style={{ backgroundColor: "#2e3541 " }}
                  >
                    تۆمارکردنی ناوی کەسەکان/کۆمپانیاکان
                  </h1>
                  <div className="b--black text-right"></div>
                  <div
                    className="row "
                    style={{ color: "#000C66" }}
                    onChange={AddProChange}
                  >
                    <div className="col-md-2 text-right  px-2 ">
                      <div className="form-group">
                        <label className="font-weight-bold">کۆد</label>
                        <input
                          type="number"
                          value={Ap_code === 0 ? "" : Ap_code}
                          name="Ap_code"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Ap_getWakilDataBySearchWakil(event)
                              : null
                          }
                          onChange={AddProChange}
                          className="form-control  text-right font-weight-bold"
                        />
                      </div>
                    </div>
                    <div className="col-md-2 text-right px-2 ">
                      <div className="form-group">
                        <label className="font-weight-bold">ناو</label>
                        <input
                          type="text"
                          value={Ap_naw}
                          name="Ap_naw"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Ap_getWakilDataBySearchWakil(event)
                              : null
                          }
                          onChange={AddProChange}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-md-2 text-right px-2 ">
                      <div className="form-group">
                        <label className="font-weight-bold">مۆبایل</label>
                        <input
                          type="number"
                          value={Ap_mob > 0 ? Ap_mob : ""}
                          name="Ap_mob"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Ap_getWakilDataBySearchWakil(event)
                              : null
                          }
                          onChange={AddProChange}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>
                    <div className="col-md-2 text-right px-2 ">
                      <div className="form-group">
                        <label className="font-weight-bold">جۆر</label>
                        <select
                          className="form-control text-right font-weight-bold selectpicker pull-right"
                          name="Ap_jor"
                          value={Ap_jor === null ? "" : Ap_jor}
                          onChange={AddProChange}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Ap_tofirstInput(event)
                              : null
                          }
                        >
                          <option className="ml5">..جۆری پیشە</option>
                          <option>فرۆشیار</option>
                          <option>شاگرد</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12 px-2 mt2 mb2">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-2">
                          {role === "admin" ? (
                            Ap_show === false ? (
                              <button
                                onClick={(e) => Ap_showrasid(e, L_user._id)}
                                className="btn btn-primary"
                                style={{ backgroundColor: "orange" }}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                            ) : (
                              <button
                                onClick={Ap_showrasid}
                                className="btn btn-primary"
                                style={{ backgroundColor: "orange" }}
                              >
                                <i className="fas fa-eye-slash"></i>
                              </button>
                            )
                          ) : null}
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-2">
                          {edit !== null ? (
                            <button
                              onClick={clear_edit}
                              className="btn btn-danger"
                            >
                              <i className="fas fa-window-close"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) => Ap_setWakilEmpty(e)}
                              className="btn btn-primary"
                            >
                              <i className="fas fa-file"></i>
                            </button>
                          )}
                        </div>
                        <div className="col-md-2">
                          {edit !== null ? (
                            <button
                              onClick={(e) => Ap_updtae_Wakil(e, edit)}
                              className="btn btn-primary"
                              style={{ backgroundColor: "navy" }}
                            >
                              <i className="far fa-edit"></i>
                            </button>
                          ) : (
                            <button
                              onClick={Ap_onSaveWakel}
                              className="btn btn-success"
                            >
                              <i className="far fa-save"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-md-12">
                  <FormMob />
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default WakilMob;
