import React from "react";
import styled from "styled-components";
import { ProductConsumer } from "../Context/AddProWakel";
import raportykala from "./../img/raportykala.png";
import raportyRozhana from "./../img/raportyRozhana.png";
import raportymaxzan from "./../img/raportymaxzan.png";
import wakel from "./../img/wakel.png";
import Navbar from "../Navbar/Navbar";
import qarderastawxo from "./../img/qarderastawxo.png";
import chin from "./../img/chin.png";
import remove from "./../img/remove.png";
import setting from "./../img/setting.jpg";
import paradan from "./../img/paradan.jpg";
import darhenan from "./../img/darhenan.png";
import zalam from "./../img/zalam.png";
import kas from "./../img/wakel.png";
import mandub from "./../img/mandub.png";
import wasl from "./../img/wasl.png";
import qarziasato from "./../img/QarziAsto.png";
import raportywakil from "./../img/raportywakil.png";
import { Link } from "react-router-dom";
import change from "./../img/change.png";
import kashf from "./../img/kashf.png";
import kashf_kas from "./../img/kashfkas.png";
const ProductRaport = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            D_OpenModal,
            RMS_OpenModal,
            RT_OpenModal,
            RMM_OpenModal,
            RPW_OpenModal,
            RP_OpenModal,
            KT_OpenModal,
            L_user,
            JM_OpenModal,
            QR_OpenModal,
            K_OpenModal,
            KN_OpenModal,
            RPD_OpenModal,
            RPM_OpenModal,
            QA_OpenModal,
            RW_OpenModal,
            RPG_OpenModal,
            KF_OpenModal,
          } = value;
          return (
            <>
              <Navbar />
              <div
                className="row "
                style={{ backgroundColor: "#2C3539" }}
              ></div>
              <div
                className="container mt5"
                style={{ backgroundColor: "#2C3539" }}
              >
                <ProductWraper
                  className="row"
                  style={{ backgroundColor: "#2C3539" }}
                >
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={raportykala}
                          onClick={(e) => K_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">ڕاپۆرتی کاڵا</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={raportyRozhana}
                          onClick={(e) => D_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2 "
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">ڕاپۆرتی ڕۆژانە</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          onClick={(e) => {
                            JM_OpenModal(e, L_user._id);
                          }}
                          src={raportymaxzan}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2 "
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">ڕاپۆرتی کۆگا</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/Raport-qarzeWakel">
                          <img
                            src={raportywakil}
                            onClick={(e) => RW_OpenModal(e, L_user._id)}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">ڕاپۆرتی معمیل</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={kashf}
                          onClick={(e) => KF_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 "> کەشف حساب</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={qarderastawxo}
                          onClick={(e) => QR_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 "> ڕاستەوخۆ</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/add-wakel">
                          <img
                            src={wakel}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">معمیل</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/Raport-china">
                          <img
                            src={chin}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">نرخی بنچینەی</p>
                      </div>
                    </div>
                  </div>

                  {/* kalay nafroshraw */}
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={remove}
                          onClick={(e) => KN_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">نەفرۆشراو</p>
                      </div>
                    </div>
                  </div>

                  {/* raporty masrufat */}
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={setting}
                          onClick={(e) => RMS_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">مەسروفەکان</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={paradan}
                          onClick={(e) => RPD_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">پارەدانەکان</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={darhenan}
                          onClick={(e) => RPM_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">
                          پارەی دەرهێنراو
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={zalam}
                          height="148px"
                          onClick={(e) => RP_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">پارەی نەگەڕاوە</p>
                      </div>
                    </div>
                  </div>

                  {/* third row */}
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={wasl}
                          height="148px"
                          onClick={(e) => RPW_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">پارەوەرگرتن</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={mandub}
                          height="148px"
                          onClick={(e) => RMM_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 "> کۆمپانیاکان</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={kas}
                          height="148px"
                          onClick={(e) => RT_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 "> ڕاپۆرتی کەس</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={qarziasato}
                          height="148px"
                          onClick={(e) => QA_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">قەرزی ئەستۆ</p>
                      </div>
                    </div>
                  </div>

                  {/* raporty para gorenawa */}
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={change}
                          height="148px"
                          onClick={(e) => RPG_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 "> پارەگۆڕینەوە</p>
                      </div>
                    </div>
                  </div>
                             {/* raporty Kashfe Kasakn */}
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <img
                          src={kashf_kas}
                          height="148px"
                          onClick={(e) => KT_OpenModal(e, L_user._id)}
                          alt="product"
                          className="card-img-top "
                        />
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">کەشفی حیسابی کەس</p>
                      </div>
                    </div>
                  </div>
                </ProductWraper>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

const ProductWraper = styled.div`
  .card {
    border-color: transparent;
    transition: all 1s linear;
  }

  .card-footer {
    background: transparent;
    border-top: transparent;
    transition: all 1s linear;
  }
  &:hover {
    .card {
      border: 0.04rem solid rgba(0, 0, 0, 0.2);
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    }

    .card-footer {
      background: rgba();
    }
  }
  .img-container {
    position: relative;
    overflow: hidden;
  }
  .card-img-top {
    transition: all 1s linear;
  }
  .img-container:hover .card-img-top {
    transform: scale(1.2);
  }
  .card-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.2rem 0.4rem;
    background: var(--lightBlue);
    border: none;
    color: var(--mainWhite);
    font-size: 1.4rem;
    border-radius: 0.5rem 0 0 0;
    transform: translate(100%, 100%);
    transition: all 1s linear;
  }
  .img-container:hover .card-btn {
    transform: translate(0, 0);
  }
  .card-btn:hover {
    color: var(--mainBlue);
    cursor: pointer;
  }
`;

export default ProductRaport;
