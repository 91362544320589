import React from 'react'
import './NavbarRozhana.css'
import { ProductConsumer } from '../../../Context/AddProWakel'
const NavbarOfQazanj = () => {
  return (<>
    <ProductConsumer>
        {value=>{
          const {Q_wasl,Q_rastawxo,Q_kala,Q_wakel,Q_getrastawxo,Q_getwakel,Q_getkala,Q_getwasl
          } = value
      return(<>

    <div className="sidebar  text-warning font-weight-bold f4" style={{backgroundColor:"#2C3539"}}>
      <center>
        <h4 className="f2 pr2 text-white"style={{backgroundColor:"#2C3539"}}>قازانج</h4>
      </center>
      <td style={{backgroundColor:`${Q_kala===true?"#63120f":''}`}}><span className="font-weight-bold pointer f4" onClick={Q_getkala} style={{paddingLeft:"50px"}} >بەگوێرەی ناوی کاڵا</span></td>
      <td style={{backgroundColor:`${Q_rastawxo===true?"#63120f":''}`}}><span className="font-weight-bold  pointer f4" onClick={Q_getrastawxo} style={{paddingLeft:"50px"}} >  بەگوێرەی ڕاستەوخۆ</span></td>
      <td style={{backgroundColor:`${Q_wasl===true?"#63120f":''}`}}><span className="font-weight-bold  pointer f4" onClick={Q_getwasl} style={{paddingLeft:"50px"}} >بەگوێرەی پسوولە</span></td>
      <td style={{backgroundColor:`${Q_wakel===true?"#63120f":''}`}}><span className="font-weight-bold  pointer f4" onClick={Q_getwakel} style={{paddingLeft:"50px"}} >بەگوێرەی معمیل</span></td>
    </div>



                           
    </>) 
    }}
      </ProductConsumer>
      </>
    
              )
    }
    

export default NavbarOfQazanj
