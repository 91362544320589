import React from "react";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import { Link } from "react-router-dom";
const Header = () => {
  var today = new Date();
  var kat =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  return (
    <ProductConsumer>
      {(value) => {
        const {
          RW_zh_barez,
          RW_nawibrez,
          AddProChange,
          RW_from,
          RW_to,
          exportToCSV,
          RW_dataWakelRaport,
          getPrint,
          RW_jwalakan,
          RW_rozhanafroshtn,
          RW_rozhanaparawagrtn,
          RW_jwllakan,
          RW_hamw,
          RW_froshtn,
          RW_parawargrtn,
          RW_wakilGaranawa,
          RW_garanawa,
          RW_koyGaranawadolar,
          RW_koyGaranawadianr,
          RW_hsabat_,
        } = value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-warning ">
                {" "}
                <Link to="/print-raportywakel">
                  <button
                    className="btn btn-warning"
                    onClick={(e) => getPrint(e)}
                  >
                    چاپکردن
                  </button>
                </Link>
              </div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                <h5>ڕاپۆرتی وەکیل</h5>
              </div>
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-warning">
                <button
                  className="btn btn-warning"
                  onClick={(e) =>
                    exportToCSV(RW_dataWakelRaport, `${kat}-وەکیل`)
                  }
                >
                  دابەزاندن
                </button>
              </div>
            </div>
            <div
              className="row  tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-4 mt4 font-weight-bold  pointer form-group text-warning">
                {" "}
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    RW_jwalakan(e);
                  }}
                >
                  پیشاندان
                </button>
              </div>
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-warning ">
                :بۆ بەرواری{" "}
                <span className="text-warning">
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RW_to"
                    value={RW_to}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                :لە بەرواری
                <span className="text-warning">
                  {" "}
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RW_from"
                    value={RW_from}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
            </div>

            <div
              className="row text-right pt2"
              style={{ backgroundColor: "#2e3541 " }}
            >
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${RW_hamw === true ? "4px solid white" : ""}`,
                }}
                onClick={RW_jwllakan}
              >
                <button className="btn btn-info">هەموو جووڵەکان</button>
              </div>
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    RW_froshtn === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={RW_rozhanafroshtn}
              >
                <button className="btn btn-info">فرۆشتن</button>
              </div>
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    RW_parawargrtn === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={RW_rozhanaparawagrtn}
              >
                <button className="btn btn-info">پارەوەرگرتن</button>
              </div>
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    RW_garanawa === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={RW_wakilGaranawa}
              >
                <button className="btn btn-info">گەڕانەوە</button>
              </div>
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                onClick={RW_hsabat_}
              >
                <button className="btn btn-info"> کەشف حساب</button>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
