import React from "react";
import { Link } from "react-router-dom";
import Time from "react-time-format";
import { ProductConsumer } from "../../../Context/AddProWakel";
const FillDetailKoga3arz = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { AddProChange, HK_name, HK_code, getPrint } = value;
          return (
            <>
              <div className="row" style={{ backgroundColor: "#2C3539" }}>
                <div className="col-lg-3 col-md-5 form-inline">
                  <div className="row form-inline">
                    <div className="col-md-7 form-inline">
                      {/* <p className="font-weight-bold bg-white text-black f4">
            <Time format="YYYY-MM-DD" />
           </p> */}
                      <span style={{ color: "#2C3539" }}>:</span>
                      {/* <p className="font-weight-bold bg-white text-black f4">
            {HK_barwar}
           </p> */}
                    </div>
                    <div className='col-md-5 form-inline"'>
                      <Link to="/print-3arz" onClick={getPrint}>
                        <button className="btn btn-warning ml-4">چاپ</button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-7  mt3">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="HK_name"
                        value={HK_name}
                        onChange={AddProChange}
                        aria-label="Large"
                        className="form-control"
                        placeholder="گەڕان بە ناو"
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        name="HK_code"
                        value={HK_code}
                        onChange={AddProChange}
                        aria-label="Large"
                        className="form-control"
                        placeholder="گەڕان بە کۆد"
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="text-warning f4 font-weight-bold">
                        گەڕان
                      </label>
                    </div>
                  </div>
                  <br />
                </div>
                <div className="col-lg-4 col-md-6 mt3">
                  <div className="row">
                    <div className="col-lg-3 col-md-3">
                      {/* <Link to="/print-Koga">
            <button className="btn  btn-secondary ml3" onClick={RKP_getPrint}>
             چاپکردن
            </button>
           </Link> */}
                    </div>
                    <div className="col-lg-6 col-md-6 text-warning f4 font-weight-bold">
                      ڕاپۆرتی پیشاندانی كاڵا
                    </div>
                    <div className="col-lg-3 col-md-3"></div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FillDetailKoga3arz;
