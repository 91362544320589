import React from 'react'
import { ProductConsumer } from '../../../Context/AddProWakel'
import NumberFormat  from 'react-number-format'
const FooterOfRaportWakel = () => {
    return (<>
        <ProductConsumer>
            {value=>{
              const {RW_rasedyKotaye$,RW_rasedyKotayeID,RW_koyfroshtndianr,RW_koyfroshtndolar} = value
          return(<>

                {/* second row */}
                <br/>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
              <div className="row ">
                          <div className="col-lg-2  col-md-3">
              <h3 className='bg-white tc'>
              <NumberFormat value={RW_koyfroshtndolar}   className='bg-white f4 font-weight-bold  tc border-0' displayType={'text'} thousandSeparator={true}/>
              </h3>
              </div>   
                          <div className="col-lg-3 col-md-3">
              <h3 className="text-warning tc " >$کۆی فرۆشتن</h3>
            </div>
                          <div className="col-lg-1 col-md-2">
                <h3 className="bg-warning tc" >دۆلار</h3>
            </div>

          

                          <div className="col-lg-2 col-md-2">
                <h3 className='bg-white tc'>
            <NumberFormat value={RW_rasedyKotaye$}  className='bg-white f4 font-weight-bold  tc border-0' displayType={'text'} thousandSeparator={true}/>
               </h3>
            </div>
                          <div className="col-lg-2 col-md-2">
                <h3 className="text-warning tc" >قەرزی کۆتایی</h3>
            </div>
            </div>
            </div>
            </div>

            {/* third row */}
            <div className="row">
                  <div className="col-lg-12 col-md-12">
              <div className="row ">
                          <div className="col-lg-2 col-md-2 ">
              <h3 className='bg-white tc'>
              <NumberFormat value={RW_koyfroshtndianr}  className=' f4 font-weight-bold  tc border-0' displayType={'text'} thousandSeparator={true}/>
              </h3>
              </div>   
                          <div className="col-lg-3 col-md-4">
              <h3 className="text-warning tc">IDکۆی فرۆشتن</h3>
              </div>
                          <div className="col-lg-1 col-md-2">
                <h3 className="bg-warning tc">دینار</h3>
            </div>
                          <div className="col-lg-2 col-md-3">
            <h3 className='bg-white tc'>
            <NumberFormat value={RW_rasedyKotayeID}  className='bg-white f4 font-weight-bold  tc border-0' displayType={'text'} thousandSeparator={true}/>
            </h3>
            </div>
            
            </div>
            </div>
            </div>
            </>) 
        }}
  </ProductConsumer>
  </>

          )
    }


export default FooterOfRaportWakel
