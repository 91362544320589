import React from 'react'
import './../RaportyRozhana/NavbarRozhana.css'
import {ProductConsumer} from '../../../Context/AddProWakel'
import { Link } from "react-router-dom";
const NavbarOfKas = () => {
  return (
    <ProductConsumer>
    {value=>{
        const {RT_Paradanakan,RT_mandubakan,RT_paradan,RT_mandub,getPrint} = value
  
      return ( 
        <div>

<div className="sidebar text-warning  font-weight-bold f4" style={{backgroundColor:"#2C3539"}} >
      <center>
        <h4 className="text-white pr2">جووڵەی کەسەکان</h4>
      </center>
      <td style={{backgroundColor:`${RT_paradan===true?"#63120f":''}`}}><span className={`font-weight-bold  pointer`}  onClick={RT_Paradanakan} style={{paddingLeft:"80px"}} >پارەدان</span></td>
      <td style={{backgroundColor:`${RT_mandub===true?"#63120f":''}`}}><span className={`font-weight-bold  pointer`}  onClick={RT_mandubakan} style={{paddingLeft:"80px"}} >کۆمپانیا</span></td>
    </div>



                           
        </div>
 )

}}
    
</ProductConsumer>
                

)
}
export default NavbarOfKas
