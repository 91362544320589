import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
const FooterOfKoga = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            BN_onSaveRent,
            AddProChange,
            BN_waslKrdn,
            BN_total,
            dolartodinar,
            getPrint,
            BN_search_wasl,
            BN_del_dis,
            BN_joripara,
            BN_save,
            BN_getPrintPasswordRent,
            G_open,
            BN_paraDan$,
            BN_pageminsone,
            BN_pageplusone,
            BN_showLoader,
            BN_hideLoader,
            BN_loading,
          } = value;
          return (
            <>
              <div className="row">
                <>
                  <div className="col-md-4">
                    <div className=" col-md-10">
                      <table className="table" id="yusif">
                        <thead>
                          <tr
                            className={`${
                              BN_save === true || BN_search_wasl === true
                                ? "bg-white"
                                : ""
                            }`}
                          >
                            <td className="text-center bg-white">
                              <NumberFormat
                                value={BN_total}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                className="f4"
                              />
                            </td>
                            <th
                              className="text-danger f4 bg-white"
                              style={{ width: "45%" }}
                            >
                              : کۆی گشتی
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <table className="table">
                      <thead>
                        <tr
                          className={`${
                            BN_save === true || BN_search_wasl === true
                              ? "bg-white"
                              : ""
                          }`}
                        >
                          <td className="f4 bg-white">
                            <NumberFormat
                              value={dolartodinar(BN_total)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"ID"}
                            />
                          </td>
                          <th
                            style={{ width: "45%" }}
                            className="text-danger f4 bg-white"
                          >
                            :ID کۆی گشتی
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </>
                <div className=" justify-content-center col-md-1 mt-2">
                  <div className="btn-group ml4">
                    {BN_search_wasl !== null ? (
                      <>
                        <Link to="/Print_kren">
                          <button
                            type="button"
                            disabled={G_open === true ? true : null}
                            onClick={BN_getPrintPasswordRent}
                            className="btn btn-info mr1"
                            style={{
                              backgroundColor: "#9900ff",
                              border: "1px solid #9900ff",
                            }}
                          >
                            <i className="fas fa-print fa-lg"></i>
                          </button>
                        </Link>
                      </>
                    ) : BN_save === true ? (
                      <>
                        <Link to="/Print_kren">
                          <button
                            type="button"
                            onClick={getPrint}
                            className="btn btn-info"
                            style={{
                              backgroundColor: "#9900ff",
                              border: "1px solid #9900ff",
                            }}
                          >
                            <i className="fas fa-print fa-lg"></i>
                          </button>
                        </Link>
                      </>
                    ) : (
                      <>
                        {BN_loading ? (
                          <button
                            type="button"
                            onDoubleClick={BN_hideLoader}
                            className="btn btn-success "
                          >
                            <i class="fas fa-spinner animate__animated animate__rotateIn animate__infinite"></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={(e) => {
                              BN_showLoader(e);
                              BN_onSaveRent(e);
                            }}
                            className="btn btn-success"
                          >
                            <i className="far fa-save fa-lg"></i>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-dark p3 px-4"
                        onClick={BN_pageminsone}
                        disabled={G_open === true ? true : null}
                        style={{
                          backgroundColor: "#00695c",
                          border: "1px solid #00695c",
                        }}
                      >
                        <i class="fas fa-caret-left f4"></i>
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-dark p3 px-4 ml3"
                        onClick={BN_pageplusone}
                        disabled={G_open === true ? true : null}
                        style={{
                          backgroundColor: "#00695c",
                          border: "1px solid #00695c",
                        }}
                      >
                        <i class="fas fa-caret-right f4"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfKoga;
