import React from 'react'
import rahel from './../../../img/rahel1.jpg'
import FillTakeMoneyPrint from './FillTakeMoneyPrint'
const PrintAllTakeMoney = () => {
    return (
        <>
                    <img src={rahel}  alt='rahel'/>
                    <FillTakeMoneyPrint/>
        </>
    )
}

export default PrintAllTakeMoney
