import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              RT_data,
              RT_gotoParadanakan,
              RT_gotoMandub,
              RT_kren,
              RT_jor,
            } = value;
            const wakelRaport = RT_data.filter((el) => {
              if (RT_jor === "$") {
                return el.joripara === "دۆلار";
              } else if (RT_jor === "ID") {
                return el.joripara === "دینار";
              } else if (RT_jor === "R") {
                return el.joripara === "ڕیمێمبی";
              } else {
                return el;
              }
            }).map((user, i) => {
              return (
                <tr key={i} className=" ">
                  {RT_kren !== true ? (
                    <td className="" style={{ border: "2px solid #f0ad4e" }}>
                      {user.note}
                    </td>
                  ) : (
                    ""
                  )}

                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.nawewargr}
                  </td>
                  <td
                    style={{ border: "2px solid #f0ad4e" }}
                    className={` ${
                      user.joripara === "دۆلار" ? "dark-green" : "dark-red"
                    }`}
                  >
                    {user.joripara}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={Number(user.koywasl).toFixed(2)}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>

                  {RT_kren !== true ? (
                    <td
                      className=" text-danger"
                      style={{ border: "2px solid #f0ad4e" }}
                    >
                      {user.wasl}
                    </td>
                  ) : (
                    ""
                  )}
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.jwlla}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <Time
                      value={user.barwar}
                      format="YYYY-MM-DD"
                      style={{ color: "black" }}
                    />
                  </td>
                  <td
                    className=""
                    style={{ border: "2px solid #f0ad4e", cursor: "pointer" }}
                    onClick={(e) => {
                      user.jwlla === "پارەدان"
                        ? RT_gotoParadanakan(e, user.code)
                        : RT_gotoMandub(e, user.code);
                    }}
                  >
                    <Link
                      to={`${
                        user.jwlla === "پارەدان"
                          ? "/Paradan"
                          : user.jwlla === "مافی.کۆمپانیا"
                          ? "/Mandub"
                          : "/raporty-kasakan"
                      }`}
                    >
                      {user.code}
                    </Link>
                  </td>
                </tr>
              );
            });

            return (
              <div className="tableFixHead ">
                <table
                  id="example"
                  className="table  table-bordered text-right table-hover"
                >
                  <thead>
                    <tr
                      className="text-warning "
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      {RT_kren !== true ? (
                        <th style={{ border: "2px solid #f0ad4e" }}>تێبینی</th>
                      ) : (
                        ""
                      )}

                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ناوی وەرگر
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>دراو</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        کۆی پسوولە
                      </th>
                      {RT_kren !== true ? (
                        <th style={{ border: "2px solid #f0ad4e" }}>
                          و.کۆمپانیا
                        </th>
                      ) : (
                        ""
                      )}

                      <th style={{ border: "2px solid #f0ad4e" }}>جوولە</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>بەروار</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>ژمارە</th>
                    </tr>
                  </thead>
                  <tbody>{wakelRaport}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
