import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
const FooterOfQarzWakel = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { QW_rasiddolar, QW_rasiddinar, Qw_footer_qarzID } = value;
          return (
            <>
              {/* second row */}
              <div className="row pt2">
                <div className="col-lg-10 col-md-12">
                  <div className="row ">
                    <div className="col-lg-2 col-md-4">
                      <h3 className="bg-white tc mt2">
                        <NumberFormat
                          displayType="text"
                          thousandSeparator={true}
                          value={QW_rasiddolar}
                        />
                      </h3>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <h3 className="text-warning tc  mt1">قەرز بە دۆلار</h3>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {/* third row */}
              <div className="row ">
                <div className="col-lg-10 col-md-12">
                  <div className="row ">
                    <div className="col-lg-2 col-md-4">
                      <h3 className="bg-white tc">
                        <NumberFormat
                          displayType="text"
                          thousandSeparator={true}
                          value={QW_rasiddinar}
                        />
                      </h3>
                    </div>
                    <div className="col-lg-2 col-md-4">
                      <h3 className="text-warning tc ">قەرز بە دینار</h3>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfQarzWakel;
