import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const Footer = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            RW_rasedyKotaye$,
            RW_rasedyKotayeID,
            RW_koyfroshtndianr,
            RW_koyfroshtndolar,
            Rw_koyparaydrawbaID,
            Rw_koyparaydrawba$,
            RW_koyGaranawadolar,
            RW_koyGaranawadianr,
          } = value;
          return (
            <>
              <br />
              <div className="row mb4">
                <div className="col-md-12 ">
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="text-warning tc">کۆی فرۆشتن</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          value={
                            Number.isInteger(Number(RW_koyfroshtndolar))
                              ? Number(RW_koyfroshtndolar)
                              : Number(RW_koyfroshtndolar).toFixed(2)
                          }
                          className="  font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={
                            Number.isInteger(Number(RW_koyfroshtndianr))
                              ? Number(RW_koyfroshtndianr)
                              : Number(RW_koyfroshtndianr).toFixed(2)
                          }
                          prefix="ID"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning">پ.دراو</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="  font-weight-bold  "
                          thousandSeparator={true}
                          value={
                            Number.isInteger(Number(Rw_koyparaydrawba$))
                              ? Number(Rw_koyparaydrawba$)
                              : Number(Rw_koyparaydrawba$).toFixed(2)
                          }
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={
                            Number.isInteger(Number(Rw_koyparaydrawbaID))
                              ? Number(Rw_koyparaydrawbaID)
                              : Number(Rw_koyparaydrawbaID).toFixed(2)
                          }
                          prefix="ID"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning tc">قەرزی کۆتایی</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="  font-weight-bold  "
                          thousandSeparator={true}
                          value={
                            Number.isInteger(Number(RW_rasedyKotaye$))
                              ? Number(RW_rasedyKotaye$)
                              : Number(RW_rasedyKotaye$).toFixed(2)
                          }
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={
                            Number.isInteger(Number(RW_rasedyKotayeID))
                              ? Number(RW_rasedyKotayeID)
                              : Number(RW_rasedyKotayeID).toFixed(2)
                          }
                          prefix="ID"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning tc">گەڕانەوە</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="  font-weight-bold  "
                          thousandSeparator={true}
                          value={RW_koyGaranawadolar}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={RW_koyGaranawadianr}
                          prefix="ID"
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default Footer;
