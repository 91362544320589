import React from 'react'
import ScroolTableCin from './ScroolTableCin'
import { ProductConsumer } from '../../../Context/AddProWakel'


const AddProductCinLeft = () => {
    return (<>
              <ProductConsumer>
                  {value=>{

                return(<>
                   <div className='mt5'>
                   
                     <h1 className='tc border p-2 mx-5 border-warning  text-warning'style={{backgroundColor:"#2C3539"}}>کاتی گەیشتنی شتەکان</h1>


            </div>
                 <ScroolTableCin/>
              </>) 
          }}
            </ProductConsumer>
            </>
      )
    }

export default AddProductCinLeft
