import React from "react";
import FooterOfRentPrint from "./FooterOfRentPrint";
import TableScrolePrint from "./TableScrolePrint";

const TableRentAllPrint = () => {
  return (
    <body>
      <TableScrolePrint />
      <FooterOfRentPrint />
    </body>
  );
};

export default TableRentAllPrint;
