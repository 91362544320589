import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { KN_data } = value;
            const wakelRaport = KN_data.map((user, i) => {
              return (
                <tr key={i} className=" ">
                  <td className="" style={{ border: "1px solid #f0ad4e" }}>
                    {user.price}
                  </td>
                  <td className="" style={{ border: "1px solid #f0ad4e" }}>
                    {user.count}
                  </td>
                  <td className="" style={{ border: "1px solid #f0ad4e" }}>
                    {user.nameofproduct}
                  </td>
                  <td className="" style={{ border: "1px solid #f0ad4e" }}>
                    {user.code}
                  </td>
                </tr>
              );
            });

            return (
              <div className="JM ">
                <table
                  id="example"
                  className="table table-hover table-bordered text-right "
                >
                  <thead>
                    <tr
                      className="text-warning "
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <th style={{ border: "1px solid #f0ad4e" }}>نرخ</th>
                      <th style={{ border: "1px solid #f0ad4e" }}>
                        دانەی فرۆشراو
                      </th>
                      <th style={{ border: "1px solid #f0ad4e" }}>
                        ناوەی کاڵا
                      </th>
                      <th style={{ border: "1px solid #f0ad4e" }}>کۆد</th>
                    </tr>
                  </thead>
                  <tbody>{wakelRaport}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
