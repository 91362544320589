import React from "react";
import { Link } from "react-router-dom";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
const Header = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const {
          AddProChange,
          JM_name,
          JM_code,
          JM_yaksanbasfrfn,
          JM_zyatrlasfrfn,
          JM_barwar,
          JM_pshandan,
          RKP_getPrint,
        } = value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning ">
                <Link to="/print-Koga">
                  <button className="btn btn-warning" onClick={RKP_getPrint}>
                    چاپکردن
                  </button>
                </Link>
              </div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                <h4 className="">ڕاپۆرتی کۆگا</h4>
                <h6 className="text-white">{JM_barwar}</h6>
              </div>
              <div className="col-md-5 mt1 font-weight-bold  pointer form-group text-warning"></div>
            </div>

            <div
              className="row text-right pt2"
              style={{ backgroundColor: "#2e3541 " }}
            >
              <div className="col-md-6 text-warning font-weight-bold  pointer form-group">
                <input
                  type="text"
                  name="JM_name"
                  value={JM_name}
                  onChange={AddProChange}
                  aria-label="Large"
                  className="form-control"
                  placeholder="ناوی کاڵا"
                />
              </div>
              <div className="col-md-6 text-warning font-weight-bold  pointer form-group">
                <input
                  type="text"
                  name="JM_code"
                  value={JM_code}
                  onChange={AddProChange}
                  aria-label="Large"
                  className="form-control"
                  placeholder="کۆدی کاڵا"
                />
              </div>
              <div className="col-md-4 text-warning font-weight-bold  pointer form-group">
                <button className="btn  btn-success " onClick={JM_zyatrlasfrfn}>
                  ماوە
                </button>
              </div>
              <div className="col-md-4 text-warning font-weight-bold  pointer form-group">
                <button className="btn  btn-warning" onClick={JM_yaksanbasfrfn}>
                  نەماوە
                </button>
              </div>
              <div className="col-md-4 text-warning font-weight-bold  pointer form-group">
                <button className="btn  btn-info ml3" onClick={JM_pshandan}>
                  هەر هەمویان
                </button>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
