import React from "react";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import { Link } from "react-router-dom";
const Header = () => {
  var today = new Date();
  var kat =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  return (
    <ProductConsumer>
      {(value) => {
        const { AddProChange, QW_zhmara, Qw_naw } = value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning "></div>
              <div className="col-md-6 mt1 font-weight-bold   form-group text-warning tc">
                <h5>ڕاپۆرتی قەرزی وەکیل</h5>
              </div>
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning"></div>
            </div>
            {/* //// */}
            <div
              className="row  tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-6 mt1 font-weight-bold   form-group text-warning tc">
                <input
                  type="search"
                  onChange={AddProChange}
                  aria-label="Large"
                  name="Qw_naw"
                  value={Qw_naw}
                  className="form-control"
                />
              </div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                <input
                  type="search"
                  onChange={AddProChange}
                  aria-label="Large"
                  name="QW_zhmara"
                  value={QW_zhmara}
                  className="form-control"
                />
              </div>
              <div className="col-md-2 mt1 font-weight-bold  pointer form-group text-warning">
                <label className="text-warning  font-weight-bold">گەڕان</label>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
