import React from 'react'
import Navbar from '../../../Navbar/Navbar'
import FormParadarhenan from './FormParadarhenan'

const Paradarhenan = () => {
    return (
        <>
            <Navbar/>
            <div className="mt5"></div>
            <FormParadarhenan/>
        
            
            
        </>
    )
}

export default Paradarhenan
