import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const SearchParadan = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            PD_note,
            AddProChange,
            PD_getParaDataBySearchMoney,
            PD_joredraw,
            PD_qardekonID,
            PD_qardekon$,
            PD_getIsChecked,
            PD_getkasDataBySearchMoney,
            PD_zhmara,
            Ap_kasakan,
            PD_nawebarez,
            PD_isChecked,
            PD_peshandaniQarzyPeshw,
          } = value;
          const wakilakn = Ap_kasakan.filter((user) => {
            return user.Name.trim().startsWith(PD_nawebarez);
          }).map((item, index) => {
            return (
              <option className="fk" key={index}>
                {item.Name}
              </option>
            );
          });
          return (
            <>
              <div
                className="card card-primary text-warning font-weight-bold"
                style={{ backgroundColor: "#2C3539" }}
              >
                <div
                  className="card-header border-bottom border-warning"
                  style={{ backgroundColor: "#2C3539" }}
                >
                  <h3 className="card-title tc "> گەران بە دوای </h3>
                </div>

                <form>
                  <div className="card-body text-right">
                    <div className="form-group">
                      <label className="font-weight-bold f4">:پسوولە</label>
                      <input
                        onKeyPress={(event) =>
                          event.key === "Enter"
                            ? PD_getParaDataBySearchMoney(event)
                            : null
                        }
                        type="text"
                        onChange={AddProChange}
                        value={PD_zhmara}
                        name="PD_zhmara"
                        className="form-control text-right font-weight-bold"
                        placeholder="...گەڕان"
                      />
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold f4">: معمیل </label>
                      <select
                        className="form-control text-right font-weight-bold selectpicker pull-right "
                        onChange={AddProChange}
                        name="PD_nawebarez"
                        value={PD_nawebarez}
                        onKeyPress={(event) =>
                          event.key === "Enter"
                            ? PD_getkasDataBySearchMoney(event)
                            : null
                        }
                      >
                        <option className="ml5">Search</option>
                        {wakilakn}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="font-weight-bold f4">:تێبینی </label>
                      <input
                        type="text"
                        onChange={AddProChange}
                        value={PD_note}
                        name="PD_note"
                        className="form-control text-right font-weight-bold"
                        placeholder="...تێبینی"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-right ml5"></div>

                  <div className="col-md-12 text-right ml5">
                    <table id="non">
                      <thead>
                        <tr>
                          <th className="text-right pb-2 text-warning f4">
                            <input
                              onChange={PD_getIsChecked}
                              checked={PD_isChecked === true ? true : false}
                              value={PD_isChecked}
                              type="checkbox"
                              className="mr3"
                            />
                            :پیشاندانی قەرزی پێشوو
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {PD_isChecked === true ? (
                          <>
                            <tr>
                              <td className="text-right">
                                <label className="font-weight-bold text-warning f4">
                                  : قەرزی کۆن
                                </label>
                                <NumberFormat
                                  value={
                                    PD_joredraw === "دۆلار"
                                      ? PD_qardekon$
                                      : PD_qardekonID
                                  }
                                  displayType={"text"}
                                  className="form-control text-right font-weight-bold"
                                  thousandSeparator={true}
                                  prefix={
                                    PD_joredraw === null
                                      ? ""
                                      : PD_joredraw === "دۆلار"
                                      ? "$"
                                      : "ID"
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="text-right ">
                                <label className="font-weight-bold text-warning f4">
                                  :قەرزی ئێستا
                                </label>
                                <NumberFormat
                                  value={PD_peshandaniQarzyPeshw()}
                                  displayType={"text"}
                                  className="form-control text-right font-weight-bold"
                                  thousandSeparator={true}
                                  prefix={
                                    PD_joredraw === null
                                      ? ""
                                      : PD_joredraw === "دۆلار"
                                      ? "$"
                                      : "ID"
                                  }
                                />
                              </td>
                            </tr>
                          </>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="card-footer"
                    style={{ backgroundColor: "#2C3539" }}
                  ></div>
                </form>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default SearchParadan;
