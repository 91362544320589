import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
const FooterOfKoga = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            G_onSaveRent,
            AddProChange,
            G_totalID,
            G_waslKrdn,
            G_waslKrdnID,
            G_total,
            dolartodinar,
            G_getPrint,
            G_kartonakan,
            G_search_wasl,
            G_koywaznkarton,
            G_del_dis,
            L_user,
            G_joripara,
            G_paraDanID,
            G_save,
            G_wasl,
            G_updateSale,
            G_getPrintPasswordRent,
            G_open,
            G_openUpdate,
            G_paraDan$,
            dinartodolar,
            G_pageminsone,
            G_pageplusone,
            G_showLoader,
            G_hideLoader,
            G_loading,
            G_zh_barez,
          } = value;
          return (
            <>
              <div className="row">
                {G_joripara === "دۆلار" ? (
                  <>
                    <div className="col-md-4">
                      <div className=" col-md-10">
                        <table className="table" id="yusif">
                          <thead>
                            <tr
                              className={`${
                                G_save === true || G_search_wasl === true
                                  ? "bg-white"
                                  : ""
                              }`}
                            >
                              <td className="text-center bg-white">
                                <NumberFormat
                                  value={G_total}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  className="f4"
                                />
                              </td>
                              <th
                                className="text-danger f4 bg-white"
                                style={{ width: "45%" }}
                              >
                                : کۆی گشتی
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center">
                                <NumberFormat
                                  displayType={"input"}
                                  thousandSeparator={true}
                                  disabled={G_del_dis === true ? true : null}
                                  name="G_paraDan$"
                                  onChange={AddProChange}
                                  value={G_paraDan$}
                                  style={{ padding: "8px 4px" }}
                                  className={`border-0 f5 pl-4 ${
                                    G_save === true || G_search_wasl === true
                                      ? "bg-white"
                                      : ""
                                  }`}
                                />
                              </td>
                              <th className="text-warning f4">:$ پارەی دراو</th>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td className="text-center bg-white">
                                {G_paraDan$ !== "" ? (
                                  <NumberFormat
                                    value={G_waslKrdn()}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    className="f4"
                                  />
                                ) : (
                                  <NumberFormat
                                    value={G_total}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    className="f4"
                                  />
                                )}
                              </td>
                              <th className="text-danger f4 bg-white">
                                :پارەی نەدراو
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <table className="table">
                        <thead>
                          <tr
                            className={`${
                              G_save === true || G_search_wasl === true
                                ? "bg-white"
                                : ""
                            }`}
                          >
                            <td className="f4 bg-white">
                              <NumberFormat
                                value={
                                  G_paraDan$ !== ""
                                    ? dolartodinar(G_waslKrdn())
                                    : dolartodinar(G_total)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"ID"}
                              />
                            </td>
                            <th
                              style={{ width: "45%" }}
                              className="text-danger f4 bg-white"
                            >
                              :ID کۆی گشتی
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {/* start ID */}
                {G_joripara === "دینار" ? (
                  <>
                    <div className="col-md-4">
                      <div className=" col-md-10">
                        <table className="table" id="yusif">
                          <thead>
                            <tr
                              className={`${
                                G_save === true || G_search_wasl === true
                                  ? "bg-white"
                                  : ""
                              }`}
                            >
                              <td className="text-center bg-white">
                                <NumberFormat
                                  value={G_totalID}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                  className="f4"
                                />
                              </td>
                              <th
                                className="text-danger f4 bg-white"
                                style={{ width: "45%" }}
                              >
                                : کۆی گشتی
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center">
                                <NumberFormat
                                  disabled={G_del_dis === true ? true : null}
                                  name="G_paraDanID"
                                  onChange={AddProChange}
                                  value={G_paraDanID}
                                  style={{ padding: "8px 4px" }}
                                  className={`border-0 f5 pl-4 ${
                                    G_save === true || G_search_wasl === true
                                      ? "bg-white"
                                      : ""
                                  }`}
                                  displayType={"input"}
                                  thousandSeparator={true}
                                />
                              </td>
                              <th className="text-warning f4">
                                :ID پارەی دراو
                              </th>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td className="text-center bg-white">
                                {G_paraDanID !== "" ? (
                                  <NumberFormat
                                    value={G_waslKrdnID()}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"ID"}
                                    className="f4"
                                  />
                                ) : (
                                  <NumberFormat
                                    value={G_totalID}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"ID"}
                                    className="f4"
                                  />
                                )}
                              </td>
                              <th className="text-danger f4 bg-white">
                                : پارەی نەدراو
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <table className="table">
                        <thead>
                          <tr
                            className={`${
                              G_save === true || G_search_wasl === true
                                ? "bg-white"
                                : ""
                            }`}
                          >
                            <td className="f4 text-center bg-white">
                              <NumberFormat
                                value={
                                  G_paraDanID !== ""
                                    ? dinartodolar(G_waslKrdnID())
                                    : dinartodolar(G_totalID)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                            <th className="text-danger f4 text-right  bg-white">
                              :$ کۆی گشتی
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-md-1 mt-3 ">
                  <h4 className=" border-0 text-right bg-white text-center">
                    {G_kartonakan}
                  </h4>
                </div>
                <div className="col-md-1 mt-3 ">
                  <h4 className=" border-0 text-right bg-white text-center">{`${G_koywaznkarton} kg`}</h4>
                </div>
                <div className=" justify-content-center col-md-1 mt-2">
                  <div className="btn-group ml4">
                    {G_search_wasl !== null ? (
                      <>
                        <Link to="/print-garanawa">
                          <button
                            type="button"
                            disabled={G_open === true ? true : null}
                            onClick={() => G_getPrintPasswordRent(G_zh_barez)}
                            className="btn btn-info mr1"
                            style={{
                              backgroundColor: "#9900ff",
                              border: "1px solid #9900ff",
                            }}
                          >
                            <i className="fas fa-print fa-lg"></i>
                          </button>
                        </Link>
                        {G_open === null ? (
                          <button
                            onClick={(e) =>
                              G_openUpdate(G_wasl.numberOfWakil, L_user._id, e,G_wasl._id)
                            }
                            className="btn btn-warning "
                            style={{
                              backgroundColor: "silver",
                              border: "1px solid silver",
                            }}
                          >
                            <i class="fas fa-lock"></i>
                          </button>
                        ) : (
                          <button
                            onClick={(e) =>
                              G_updateSale(G_zh_barez, G_wasl._id, e)
                            }
                            className="btn btn-primary "
                            style={{ backgroundColor: "navy" }}
                          >
                            <i className="fas fa-edit "></i>
                          </button>
                        )}
                      </>
                    ) : G_save === true ? (
                      <>
                        {G_open === null ? (
                          <button
                            onClick={(e) =>
                              G_openUpdate(G_wasl.numberOfWakil, L_user._id, e,G_wasl._id)
                            }
                            className="btn btn-warning "
                            style={{
                              backgroundColor: "silver",
                              border: "1px solid silver",
                            }}
                          >
                            <i class="fas fa-lock"></i>
                          </button>
                        ) : (
                          <button
                            onClick={(e) =>
                              G_updateSale(G_zh_barez, G_wasl._id, e)
                            }
                            className="btn btn-primary "
                            style={{ backgroundColor: "navy" }}
                          >
                            <i className="fas fa-edit "></i>
                          </button>
                        )}
                        <Link to="/print-garanawa">
                          <button
                            type="button"
                            onClick={() => G_getPrint(G_zh_barez)}
                            className="btn btn-info"
                            style={{
                              backgroundColor: "#9900ff",
                              border: "1px solid #9900ff",
                            }}
                          >
                            <i className="fas fa-print fa-lg"></i>
                          </button>
                        </Link>
                      </>
                    ) : (
                      <>
                        {G_loading ? (
                          <button
                            type="button"
                            onDoubleClick={G_hideLoader}
                            className="btn btn-success "
                          >
                            <i class="fas fa-spinner animate__animated animate__rotateIn animate__infinite"></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={(e) => {
                              G_showLoader(e);
                              G_onSaveRent(e);
                            }}
                            className="btn btn-success"
                          >
                            <i className="far fa-save fa-lg"></i>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-dark p3 px-4"
                        onClick={G_pageminsone}
                        disabled={G_open === true ? true : null}
                        style={{
                          backgroundColor: "#00695c",
                          border: "1px solid #00695c",
                        }}
                      >
                        <i class="fas fa-caret-left f4"></i>
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-dark p3 px-4 ml3"
                        onClick={G_pageplusone}
                        disabled={G_open === true ? true : null}
                        style={{
                          backgroundColor: "#00695c",
                          border: "1px solid #00695c",
                        }}
                      >
                        <i class="fas fa-caret-right f4"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfKoga;
