import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class ScoollTableKashfKas extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              KT_datatable,
              RW_gotowaslrentsearch,
              KM_GotoKren,
              RT_gotoParadanakan,
              RT_gotoMandub,
            } = value;
            const wakelRaport = KT_datatable.sort(
              (a, b) =>
                new Date(...a.date.split("/").reverse()) -
                new Date(...b.date.split("/").reverse())
            ).map((user, i) => {
              return (
                <tr key={i} className="fk f4">
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={user.total}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={user.darzan}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={user.carton}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    style={{ border: "2px solid #f0ad4e" }}
                    className={`fk ${
                      user.joripara === "دۆلار" ? "dark-green" : "dark-red"
                    }`}
                  >
                    {user.joridraw}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={Number(user.nrx).toFixed(2)}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {user.name}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {user.jwlla}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <Time
                      value={user.date}
                      format="YYYY-MM-DD"
                      style={{ color: "black" }}
                    />
                  </td>

                  <td
                    className="fk"
                    style={{ border: "2px solid #f0ad4e", cursor: "pointer" }}
                    onClick={(e) => RW_gotowaslrentsearch(e, user.code)}
                    onClick={(e) => {
                      user.jwlla === "پارەدان"
                        ? RT_gotoParadanakan(e, user.code)
                        : user.jwlla === "مەندوب"
                        ? RT_gotoMandub(e, user.code)
                        : KM_GotoKren(e, user.code);
                    }}
                  >
                    <Link
                      to={`${
                        user.jwlla === "پارەدان"
                          ? "/Paradan"
                          : user.jwlla === "مەندوب"
                          ? "/Mandub"
                          : "/Kren-Mawad"
                      }`}
                    >
                      {user.code}
                    </Link>
                  </td>
                </tr>
              );
            });

            return (
              <div className="tableFixHead ">
                <table
                  id="example"
                  className="table  table-bordered text-right table-hover"
                >
                  <thead>
                    <tr
                      className="text-warning f4"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>کۆی گشتی</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        مەتر/دەرزەن
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        لەفە/کارتۆن
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>دراو</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>نرخ</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>ناوی کاڵا</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>جوڵە</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>بەروار</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>ژ.پسولە</th>
                    </tr>
                  </thead>
                  <tbody>{wakelRaport}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default ScoollTableKashfKas;
