import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

class FormPrintWakel extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { QW_dataWakelQarz, QW_zhmara, Qw_naw, checkedValues } =
              value;
            const wakil = QW_dataWakelQarz.filter((data) => {
              if (Qw_naw !== "") {
                return data.Name.toLowerCase().startsWith(Qw_naw.toLowerCase());
              } else if (QW_zhmara !== "") {
                return data.code.toString().startsWith(QW_zhmara);
              } else {
                return data;
              }
            }).map((user, i) => {
              if (checkedValues.includes(user._id)) {
                return (
                  <tr key={i} className="fk">
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      {user.adress}
                    </td>
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      <NumberFormat
                        value={Number(user.rejaDinar).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"IQD"}
                        className="f4"
                      />
                    </td>
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      <NumberFormat
                        value={Number(user.rejaDolar).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        className="f4"
                      />
                    </td>
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      {user.phone}
                    </td>
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      {user.Name}
                    </td>
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      {user.code}
                    </td>
                  </tr>
                );
              } else if (checkedValues.length === 0) {
                return (
                  <tr key={i} className="fk">
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      {user.adress}
                    </td>
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      <NumberFormat
                        value={Number(user.rejaDinar).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"ID"}
                        className="f4"
                      />
                    </td>
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      <NumberFormat
                        value={Number(user.rejaDolar).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        className="f4"
                      />
                    </td>
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      {user.phone}
                    </td>
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      {user.Name}
                    </td>
                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                      {user.code}
                    </td>
                  </tr>
                );
              }
            });

            return (
              <table
                id="example"
                className="table table-striped table-bordered text-right "
              >
                <thead>
                  <tr className="text-black f4 fk">
                    <th style={{ border: "2px solid #f0ad4e" }}> ناونیشان</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>IQD قەرز</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>$ قەرز</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>مۆبایل</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>ناو</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                  </tr>
                </thead>
                <tbody>{wakil}</tbody>
              </table>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default FormPrintWakel;
