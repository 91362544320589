import React, { Component } from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import styled from "styled-components";
import { Link } from "react-router-dom";

class ModelKalaRaport extends Component {
  render() {
    return (
      <ProductConsumer>
        {(value) => {
          const {
            K_modalOpen,
            K_CloseModal,
            K_from,
            K_to,
            K_naweKala,
            K_zhamara,
            AddProChange,
            K_getproduct,
            K_pshandan,
            K_suggestions,
            Search_Jarde_Koga_Handle,
            K_suggestionSelected,
          } = value;
          const renderSuggestions = () => {
            if (K_suggestions.length === 0) {
              return null;
            }
            return (
              <div className="srchList_r_kala ">
                <ul className="uly_r_kala ">
                  {K_suggestions.map((item) => (
                    <li onClick={() => K_suggestionSelected(item._id)}>
                      {item.nameofproduct}
                    </li>
                  ))}
                </ul>
              </div>
            );
          };
          if (!K_modalOpen) {
            return null;
          } else {
            return (
              <ModalContainer>
                <div className="container">
                  <div className="row">
                    <div
                      id="modal"
                      className="col-lg-8 col-sm-12 mx-auto col-md-10 col-lg-6 text-center text-capitalize p-5"
                    >
                      <div className="row">
                        <div className="col-md-12 mb2">
                          <h3>ڕاپۆرتی كاڵا</h3>
                        </div>
                      </div>
                      <form className="row">
                        <div className="col-md-9">
                          <input
                            onChange={Search_Jarde_Koga_Handle}
                            value={K_naweKala}
                            name="K_naweKala"
                            type="text"
                            aria-label="Large"
                            className="form-control"
                            autocomplete="off"
                            onKeyPress={(event) =>
                              event.key === "Enter" ? K_getproduct(event) : null
                            }
                          />
                          <div className="justify-content-md-center text-center">
                            {renderSuggestions()}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <label className="text-danger f4 font-weight-bold">
                            ناوی کاڵا
                          </label>
                        </div>
                      </form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-danger f4 font-weight-bold">
                              هەتاوەکو
                            </label>
                            <br />
                            <input
                              onChange={AddProChange}
                              value={K_to}
                              name="K_to"
                              type="date"
                              className="selectpicker btn btn-dark"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-danger f4 font-weight-bold">
                              لە
                            </label>
                            <br />
                            <input
                              onChange={AddProChange}
                              value={K_from}
                              name="K_from"
                              type="date"
                              className="selectpicker btn btn-dark"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <Link to="/Product-Raport">
                            <button
                              className="btn btn-success"
                              onClick={(e) => K_CloseModal(e)}
                            >
                              چوونەدەرەوە
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/Raport-kala">
                            <button
                              className="btn btn-info px-4"
                              onClick={(e) => K_pshandan(e)}
                            >
                              پێشاندان
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalContainer>
            );
          }
        }}
      </ProductConsumer>
    );
  }
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  #modal {
    background: #f3e29f;
  }
`;

export default ModelKalaRaport;
