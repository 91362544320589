import React from 'react'
import FormPrintRaportyRozhana from './FormPrintRaportyRozhana'
import rahel from './../../../img/rahel1.jpg'
import { ProductConsumer } from '../../../Context/AddProWakel'
import NumberFormat  from 'react-number-format'
import Time from 'react-time-format'
const InputRaportyRozhana = () => {
  return (<>
    <ProductConsumer>
        {value=>{
          const {d_from,d_to
          } = value
      return(<>
                    <img src={rahel} alt='rahel' />
         <div className="row mb2">
          <div className="col-4 text-right form-inline "></div>
          <div className="col-4 text-right form-inline ">
                      <div className="form-group ">
                      <label className="font-weight-bold f3 ml5">ڕاپۆرتی ڕۆژانە</label>
                      </div>
                  </div>
          <div className="col-4 text-right form-inline"></div>
            </div>

                    {/* second row */}
            <div className="row text-dark font-weight-bold f3 mx-4" style={{border:"2px solid black"}}>
          <div className="col-2 text-right  form-inline"></div>

          <div className="col-4 text-right  form-inline ">
                      <div className="form-group">
                    <h5 className="font-weight-bold mt1 ml5 ">{d_to}</h5>
                      <label className="font-weight-bold mb-2 ml1">: بۆ بەرواری</label>
                      </div>
              </div>
              
          <div className="col-4 text-right  form-inline ">
                      <div className="form-group">
                    <h5 className="font-weight-bold mt1 ml5  ">{d_from}</h5>
                      <label className="font-weight-bold mb-2  ml1">: لە بەرواری </label>
                      </div>
              </div>
          <div className="col-2 text-right  form-inline"></div>



            </div>


            
                    
                    
                    <div className="mt4"></div>
                   <FormPrintRaportyRozhana/>

                   </>) 
}}
  </ProductConsumer>
  </>

          )
}

export default InputRaportyRozhana
