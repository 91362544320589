import React from 'react'
import Navbar from '../../../Navbar/Navbar'
import FormParawargrtn from './FormParawargrtn'

const Parawargrtn = () => {
    return (
        <>
            <Navbar/>
            <div className="mt5"></div>
            <FormParawargrtn/>
        
            
            
        </>
    )
}

export default Parawargrtn
