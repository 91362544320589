import React from "react";
import { ProductConsumer } from "../../Context/AddProWakel";
import "./form.css";
import logo from "../../img/rahel1.jpg";
import { isMobile } from "react-device-detect";

const LoginForm = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const {
          loginuser,
          L_error,
          L_name,
          L_password,
          AddProChange,
          L_ClearError,
        } = value;

        return (
          <div className="fk">
            <div className="sidenav">
              <div className="login-main-text text-right">
                <h3>
                  ! کارەکەت سەرکەوتووتر بکە لەگەڵ ئێمە
                  <br />
                </h3>
                <p>فۆڕمی‌ چوونه‌ژوره‌وه</p>
                <img
                  className="mt3"
                  style={{ opacity: "0.5", borderRadius: "5px" }}
                  src={logo}
                  alt="logo"
                />
                <div className="mt4 text-left">
                  <h6>
                    <span
                      className="text-warning"
                      style={{ fontWeight: "normal" }}
                    >
                      Developed By
                    </span>{" "}
                    <span>
                      Ayat Khalid <span className="text-info">&#x26; </span>{" "}
                      Yusif Najat
                    </span>
                  </h6>
                  <h6>
                    <span
                      className="text-warning"
                      style={{ fontWeight: "normal" }}
                    >
                      Contact
                    </span>{" "}
                    <span>
                      {" "}
                      <span
                        className="text-warning ml2 mr2"
                        style={{ fontWeight: "normal" }}
                      >
                        0750
                      </span>
                      3492995 <span className="text-info">&#x26; </span>
                      <span
                        className="text-warning mr2"
                        style={{ fontWeight: "normal" }}
                      >
                        0750
                      </span>
                      8946634
                    </span>
                  </h6>
                  <hr style={{ borderTop: "1px solid #f0ad4e" }} />
                  <h6>
                    <span className="text-warning ">&#169; Copy Right</span>{" "}
                    {new Date().getFullYear()}
                  </h6>
                </div>
              </div>
            </div>
            <div className="main">
              <div className="col-md-6 col-sm-12">
                <div className="login-form">
                  <form onSubmit={loginuser}>
                    <div className="form-group text-right">
                      <label>ناوی به‌كارهێنه‌ر</label>
                      <input
                        type="text"
                        className="form-control text-right"
                        name="L_name"
                        placeholder="...ناوی به‌كارهێنه‌ر "
                        style={{ border: "1px solid #2e3541" }}
                        value={L_name}
                        onChange={AddProChange}
                      />
                    </div>
                    <div className="form-group text-right">
                      <label>وشه‌ی نهێنی</label>
                      <input
                        type="password"
                        className="form-control text-right"
                        name="L_password"
                        placeholder="...وشه‌ی نهێنی "
                        style={{ border: "1px solid #2e3541" }}
                        value={L_password}
                        onChange={AddProChange}
                        required
                      />
                    </div>
                    {isMobile ? (
                      <>
                        <button
                          type="submit"
                          className="btn bt-lg mb5 px-5"
                          style={{ backgroundColor: "#2e3541", color: "white" }}
                        >
                          چوونه‌ژوره‌وه‌
                        </button>
                      </>
                    ) : (
                      <button
                        type="submit"
                        className="btn"
                        style={{ backgroundColor: "#2e3541", color: "white" }}
                      >
                        چوونه‌ژوره‌وه‌
                      </button>
                    )}
                  </form>
                  <div className="question">
                    {L_error !== null && (
                      <button className="danger" type="button">
                        {L_error.msg}
                        <span onClick={() => L_ClearError()}>X</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </ProductConsumer>
  );
};
export default LoginForm;
