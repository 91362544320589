import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

class FormMob extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { Ap_data, Ap_clickOnDeleteWakel, edit_product, Ap_show } =
              value;
            const wakil = Ap_data.map((user, i) => {
              return (
                <tr key={i} className=" ">
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.jor}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {Ap_show === true ? (
                      <NumberFormat
                        value={user.paradinar.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"ID"}
                        className=""
                      />
                    ) : (
                      "*********"
                    )}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {Ap_show === true ? (
                      <NumberFormat
                        value={user.paradolar.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        className=""
                      />
                    ) : (
                      "*********"
                    )}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.phone}
                  </td>
                  <td style={{ border: "2px solid #f0ad4e" }} className="">
                    {user.Name}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.code}
                  </td>
                  <td
                    style={{ border: "2px solid #f0ad4e" }}
                    className="pt-1 pb-0 "
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          onClick={() => Ap_clickOnDeleteWakel(user._id)}
                          className="btn btn-danger btn-sm "
                        >
                          <i
                            className="fas fa-trash fa-sm"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          onClick={() => edit_product(user)}
                          className="btn btn-primary btn-sm"
                          style={{ backgroundColor: "navy" }}
                        >
                          <i className="fas fa-edit" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            });

            return (
              <div className="tableFixHead">
                <table
                  id="example"
                  className="table table-striped table-bordered text-right "
                >
                  <thead>
                    <tr
                      className="text-warning "
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>پیشە</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>IDپارە</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>$پارە</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>مۆبایل</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>ناو</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                      <th
                        style={{ border: "2px solid #f0ad4e" }}
                        className="tc"
                      >
                        X
                      </th>
                    </tr>
                  </thead>
                  <tbody>{wakil}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default FormMob;
