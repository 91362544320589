import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";

class FormPrintWakel extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              QR_data,
              QR_gotowaslrentsearch,
              QR_name,
              QR_selectRow,
              QR_checkedValues,
            } = value;
            const qarzeWakel = QR_data.filter((data) => {
              if (QR_name !== "") {
                return data.wakil.toLowerCase().includes(QR_name.toLowerCase());
              } else {
                return data;
              }
            }).map((user, i) => {
              if (QR_checkedValues.includes(user._id)) {
                return (
                  <tr key={i} className="fk f4">
                    <td className="fk" style={{ border: "2px solid black" }}>
                      <NumberFormat
                        value={
                          user.joripara === "دۆلار"
                            ? user.qarzinwedolar
                            : user.qarzinwedinar
                        }
                        thousandSeparator={true}
                        displayType="text"
                      />
                    </td>
                    <td className="fk" style={{ border: "2px solid black" }}>
                      <NumberFormat
                        value={
                          user.joripara === "دۆلار"
                            ? user.paraydrawdolar
                            : user.paraydrawdinar
                        }
                        thousandSeparator={true}
                        displayType="text"
                      />
                    </td>
                    <td className="fk" style={{ border: "2px solid black" }}>
                      <NumberFormat
                        value={
                          user.joripara === "دۆلار"
                            ? user.dashkandolar
                            : user.dashkandinar
                        }
                        thousandSeparator={true}
                        displayType="text"
                      />
                    </td>
                    <td
                      style={{ border: "2px solid black" }}
                      className={`fk ${
                        user.joripara === "دۆلار" ? "text-black" : "text-black"
                      }`}
                    >
                      {user.joripara}
                    </td>
                    <td className="fk" style={{ border: "2px solid black" }}>
                      <NumberFormat
                        value={
                          user.joripara === "دۆلار"
                            ? user.koalldolar
                            : user.koalldinar
                        }
                        thousandSeparator={true}
                        displayType="text"
                      />
                    </td>
                    <td className="fk" style={{ border: "2px solid black" }}>
                      {user.wakil}
                    </td>
                    <td className="fk" style={{ border: "2px solid black" }}>
                      <Time
                        value={user.date}
                        format="YYYY-MM-DD"
                        style={{ color: "black" }}
                      />
                    </td>
                    <td
                      className="fk"
                      style={{ border: "2px solid black", cursor: "pointer" }}
                    >
                      {user.numberofwasl}
                    </td>
                  </tr>
                );
              } else if (QR_checkedValues.length === 0) {
                return (
                  <tr key={i} className="fk f4">
                    <td className="fk" style={{ border: "2px solid black" }}>
                      <NumberFormat
                        value={
                          user.joripara === "دۆلار"
                            ? user.qarzinwedolar
                            : user.qarzinwedinar
                        }
                        thousandSeparator={true}
                        displayType="text"
                      />
                    </td>
                    <td className="fk" style={{ border: "2px solid black" }}>
                      <NumberFormat
                        value={
                          user.joripara === "دۆلار"
                            ? user.paraydrawdolar
                            : user.paraydrawdinar
                        }
                        thousandSeparator={true}
                        displayType="text"
                      />
                    </td>
                    <td className="fk" style={{ border: "2px solid black" }}>
                      <NumberFormat
                        value={
                          user.joripara === "دۆلار"
                            ? user.dashkandolar
                            : user.dashkandinar
                        }
                        thousandSeparator={true}
                        displayType="text"
                      />
                    </td>
                    <td
                      style={{ border: "2px solid black" }}
                      className={`fk ${
                        user.joripara === "دۆلار" ? "text-black" : "text-black"
                      }`}
                    >
                      {user.joripara}
                    </td>
                    <td className="fk" style={{ border: "2px solid black" }}>
                      <NumberFormat
                        value={
                          user.joripara === "دۆلار"
                            ? user.koalldolar
                            : user.koalldinar
                        }
                        thousandSeparator={true}
                        displayType="text"
                      />
                    </td>
                    <td className="fk" style={{ border: "2px solid black" }}>
                      {user.wakil}
                    </td>
                    <td className="fk" style={{ border: "2px solid black" }}>
                      <Time
                        value={user.date}
                        format="YYYY-MM-DD"
                        style={{ color: "black" }}
                      />
                    </td>
                    <td
                      className="fk"
                      style={{ border: "2px solid black", cursor: "pointer" }}
                    >
                      {user.numberofwasl}
                    </td>
                  </tr>
                );
              }
            });

            return (
              <div>
                <table
                  id="example"
                  className="table table-hover table-bordered text-right"
                >
                  <thead>
                    <tr
                      className="text-black f4"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <th style={{ border: "2px solid black" }}>پارەی ماوە</th>
                      <th style={{ border: "2px solid black" }}>پارەی دراو</th>
                      <th style={{ border: "2px solid black" }}>داشکان</th>
                      <th style={{ border: "2px solid black" }}>دراو</th>
                      <th style={{ border: "2px solid black" }}>کۆی پسوولە</th>
                      <th style={{ border: "2px solid black" }}>ناوی بەڕێز</th>
                      <th style={{ border: "2px solid black" }}>بەروار</th>
                      <th style={{ border: "2px solid black" }}>ژمارە</th>
                    </tr>
                  </thead>
                  <tbody>{qarzeWakel}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default FormPrintWakel;
