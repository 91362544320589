import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableBroken from "./ScroollTableBroken";
import FooterOfBroken from "./FooterOfBroken";
import FillDetailBroken from "./FillDetailBroken";

const AllBroken = () => {
  return (
    <>
      <Navbar />
      <div style={{ backgroundColor: "#2C3539" }}>
        <FillDetailBroken />

        <br></br>
        <div className="bg-white">
          <ScroollTableBroken />
        </div>
        <br></br>
        {/* wasl para */}
        <FooterOfBroken />
      </div>
      {/* end wasl u para */}
    </>
  );
};

export default AllBroken;
