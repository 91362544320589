import React from "react"
import { ProductConsumer } from '../../../Context/AddProWakel'
import ScrollKalayNafroshraw from './ScrollKalayNafroshraw'
import NumberFormat from 'react-number-format'

class ScroollTableKalayNafroshraw extends React.Component {

    render() {
        return (
        
            <>
            <ProductConsumer >
                
                {value=>{
                    const {KN_data}=value
                        const wakelRaport=KN_data.map((user,i)=>{
                            return (
                            <tr key={i} className="f4 fk"   >
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}><NumberFormat value={((user.rasidKarton * user.numberOfDarzan) * user.price).toFixed(2)} displayType='text' thousandSeparator={true} /></td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.numberOfDarzan}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.price}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{(user.rasidKarton * user.numberOfDarzan).toFixed(2)}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.rasidKarton}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.nameofproduct}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.code}</td>
                                
                                
                            </tr>
                            )
                            
            
        })
    
        return (
           
            <div className="JM ">
                
               <table id="example" className="table table-hover table-bordered text-right "  >
            <thead>
                <tr className='text-warning f4' style={{backgroundColor:"#2C3539"}}>
                    <th style={{border:"2px solid #f0ad4e"}}>$ کۆی گشتی </th>
                    <th style={{border:"2px solid #f0ad4e"}}>ڕێژە</th>
                    <th style={{border:"2px solid #f0ad4e"}}>$ كڕین</th>
                    <th style={{border:"2px solid #f0ad4e"}}>دانە</th>
                    <th style={{border:"2px solid #f0ad4e"}}>كارتۆنی ماوە</th>
                    <th style={{border:"2px solid #f0ad4e"}}>ناوەی کاڵا</th>
                    <th style={{border:"2px solid #f0ad4e"}}>کۆد</th>

    
                </tr>
            </thead>
            <tbody>
            {wakelRaport}
            </tbody>
        </table> 
        </div>
        )
            }}
            </ProductConsumer>
            
            </>
        )
    }
}
export default ScroollTableKalayNafroshraw