import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";

const FillTakeMoneyPrint = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            P_wargr,
            P_zhmara,
            P_joripara,
            P_nawe_barez,
            P_brePara,
            P_note,
            P_barwar,
            P_dashkan,
            P_kamkrdnawayNrx,
            bripara,
            P_qarzeKonDolar,
            P_qarzeKonID,
            P_zh_barez, dolartodinar, dinartodolar
          } = value;

          return (
            <>
              <div className="mt3 fk">
                <div className="row mb2">
                  <div className="col-4 text-right form-inline "></div>
                  <div className="col-4 text-right form-inline ">
                    <div className="form-group ">
                      <label className="font-weight-bold f3 ml5">
                        پسوولەی وەرگرتنی پارە
                      </label>
                    </div>
                  </div>
                  <div className="col-4 text-right form-inline"></div>
                </div>

                <div className="row text-dark font-weight-bold f3 border border-dark mx-4">
                  <div className="col-4 text-right  form-inline">
                    <div className="form-group ">
                      <h5 className="font-weight-bold mt2 ml4 f3">
                        <Time
                          value={P_barwar}
                          format="hh:mm:ss"
                          style={{ color: "black" }}
                        />
                      </h5>
                      <label className="font-weight-bold f4 ">:کات</label>
                    </div>
                  </div>

                  <div className="col-4 text-right  form-inline ">
                    <div className="form-group">
                      <h5 className="font-weight-bold mt2 ml5 f3">
                        <Time
                          value={P_barwar}
                          format="YYYY-MM-DD"
                          style={{ color: "black" }}
                        />
                      </h5>
                      <label className="font-weight-bold f3 ml1">
                        : بەروار
                      </label>
                    </div>
                  </div>

                  <div className="col-4 text-right form-inline">
                    <div className="form-group ml6">
                      <h5 className="mt2 f3">{P_zhmara}</h5>
                      <label className="font-weight-bold f3 ml2">: ژمارە</label>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              {/* row 2 */}
              <form
                style={{
                  borderBottom: "2px solid black",
                  borderTop: "2px solid black",
                  borderLeft: "2px solid black",
                  borderRight: "2px solid black",
                }}
              >
                <br />
                <div className="row fk">
                  {/* <div className="col-4 text-right  form-inline">
                <div className="form-group ">
                <h5 className="font-weight-bold mt2 ml4 f3"><Time value={P_barwar}  format="hh:mm:ss" style={{color:'black'}} /></h5>
                <label className="font-weight-bold f4 ">:کات</label>
                    </div>
               </div> */}

                  <div className="col-4 text-right form-inline ">
                    <div className="form-group ">
                      <h5 className="mt2 ml4 f3">
                        {" "}
                        {`${
                          P_joripara === "دۆلار"
                            ? `${P_brePara} $`
                            : `${P_brePara} ID`
                        }`}{" "}
                      </h5>
                      <label className="font-weight-bold f3 ml1">
                        :بڕی پارە
                      </label>
                    </div>
                  </div>

                  <div className="col-4 text-right  form-inline">
                    <div className="form-group">
                      <h5 className="mt2  bold f3 "> {P_nawe_barez}</h5>
                      <label className="font-weight-bold f3 ml2 ">
                        : بەرێز
                      </label>
                    </div>
                  </div>

                  <div className="col-4 text-right  form-inline">
                    <div className="form-group">
                      <h5 className="mt2 ml6 bold f3">{P_zh_barez}</h5>
                      <label className="font-weight-bold f3 ml3 ">
                        :ژ.معمیل
                      </label>
                    </div>
                  </div>
                </div>
                <br />
                <p>
                  <hr
                    style={{ width: "1000px", border: "1px solid #7f7f7f" }}
                  />
                  <br />

                  <hr
                    style={{ width: "1000px", border: "1px solid #7f7f7f" }}
                  />
                </p>

                <br />
                <div className="row " style={{ borderTop: "3px solid black" }}>
                  <div className="col-4 text-right form-inline mt3">
                    <div className="form-group ">
                      <label className="font-weight-bold f3 ml5">:واژوو</label>
                    </div>
                  </div>

                  <div className="col-4 text-right  form-inline mt3">
                    <div className="form-group ">
                      <h5 className="mt2 ml5 bold f3">{P_joripara}</h5>
                      <label className="font-weight-bold f3 ml3 ">
                        : جۆری دراو
                      </label>
                    </div>
                  </div>

                  <div className="col-4 text-right  form-inline mt3">
                    <div className="form-group ">
                      <h5 className="mt2 ml5 bold f3">{P_dashkan}</h5>
                      <label className="font-weight-bold f3 ml3 ">
                        : داشکاندن
                      </label>
                    </div>
                  </div>
                </div>
                <br />
              </form>
              <br />
              <div className="row fk">
                <div className="col-8">
                  <div className="row">
                    <div className="col-6">
                          <h3 className="tc">
                            <NumberFormat
                              value={P_qarzeKonID}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"ID"}
                            />
                          </h3>
                          <hr />
                          <h3 className="tc">
                            <NumberFormat
                              value={P_qarzeKonDolar}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </h3>
                          <hr />
                          <h3 className="tc">
                            <NumberFormat
                              value={P_brePara}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={P_joripara === "دۆلار" ? "$" : "ID"}
                            />
                          </h3>
                          <hr />
                          <h3 className="tc">
                            <NumberFormat
                          value={P_joripara === "دۆلار" ? dolartodinar(P_brePara) : dinartodolar(P_brePara)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={P_joripara === "دۆلار" ? "$" : "ID"}
                        />
                      </h3>
                      <hr />
                      <h3 className="tc">
                        <NumberFormat
                              value={
                                P_dashkan > 0 ? P_kamkrdnawayNrx() : bripara()
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={P_joripara === "دۆلار" ? "$" : "ID"}
                            />
                          </h3>
                          <hr />
                    </div>
                    <div className="col-6">
                          <h3 className="ml5">:ID قەرزی کۆن</h3>
                          <hr />
                          <h3 className="ml5">:$ قەرزی کۆن</h3>
                          <hr />
                          <h3 className="ml5">:پارەی دراو</h3>
                          <hr />
                      <h3 className="ml5">:{P_joripara === "دۆلار" ? "$" : "ID"} پارەی دراو</h3>
                      <hr />
                          <h3 className="ml5">:قەرزی ماوە </h3>
                          <hr />
                    </div>
                  </div>
                </div>
                <div className=" col-4 text-right form-inline ">
                  <div
                    className="form-group text-right "
                    style={{
                      borderTop: "1px solid black",
                      borderLeft: "1px solid black",
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    <h5 className="mt2 ml5 bold f3">{P_wargr==="‌‌‌‌‌‌حاجی ڕەقیب"?"‌‌‌‌‌‌حاجی ڕەقیب":P_wargr==="حاجی رەمەزان"?"حاجی سعید":""}</h5>
                    <label className="font-weight-bold f3 ml3 text-right">
                      :ناوی وەرگر
                    </label>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="row">
                <div className=" col-4 text-right form-inline"></div>
                <div className=" col-8 text-right form-inline ">
                  <div
                    className="form-group text-right "
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                    }}
                  >
                    <h5 className="mt2 ml5 bold f3 font-weight-bold">
                      {" "}
                      {P_note}
                    </h5>
                    <label className="font-weight-bold f3 ml3 text-right">
                      :تێبینی
                    </label>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FillTakeMoneyPrint;
