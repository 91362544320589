import React from "react";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import { Link } from "react-router-dom";
const Header = () => {
  var today = new Date();
  var kat =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  return (
    <ProductConsumer>
      {(value) => {
        const {
          D_rozhanafroshtn,
          D_rozhanaparawagrtn,
          D_jwllakan,
          d_hamw,
          d_froshtn,
          d_parawargrtn,
          D_rozhanaGaranawa,
          d_garanawa,
          getPrint,
        } = value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning ">
                <Link to="/print-raportyRozhana">
                  <button className="btn btn-warning" onClick={getPrint}>
                    چاپکردن
                  </button>
                </Link>
              </div>
              <div className="col-md-6 mt1 font-weight-bold   form-group text-warning tc">
                <h4 className="">جووڵەی ڕۆژانە</h4>
              </div>
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning"></div>
            </div>

            <div
              className="row text-right pt2"
              style={{ backgroundColor: "#2e3541 " }}
            >
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${d_hamw === true ? "4px solid white" : ""}`,
                }}
                onClick={D_jwllakan}
              >
                <button className="btn btn-info">هەموو جووڵەکان</button>
              </div>
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    d_froshtn === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={D_rozhanafroshtn}
              >
                <button className="btn btn-info">فرۆشتن</button>
              </div>
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    d_parawargrtn === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={D_rozhanaparawagrtn}
              >
                <button className="btn btn-info">پارەوەرگرتن</button>
              </div>
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    d_garanawa === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={D_rozhanaGaranawa}
              >
                <button className="btn btn-info">گەڕانەوە</button>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
