import React from 'react'
import Navbar from '../../../Navbar/Navbar'
import FormParaGorenawa from './FormParaGorenawa'

const ParaGorenawa = () => {
    return (
        <>
            <Navbar/>
            <div className="mt5"></div>
            <FormParaGorenawa/>
        
            
            
        </>
    )
}

export default ParaGorenawa
