import React from 'react'
import { ProductConsumer } from '../../../Context/AddProWakel'
import FormMasruf from './FormMasruf'
import NumberFormat  from 'react-number-format'

const MasrufSecond = () => {
  return (<>
    <ProductConsumer>
        {value=>{
          const {AddProChange,Ms_onSaveWakel,Ms_tofirstInput,Ms_draw,Ms_koymasrufID,Ms_koymasruf$,Ms_showrasid,
            clear_edit,edit,Ms_updtae_Wakil,Ms_koypara,Ms_naw,toanotherInput,Ms_show,
            Ms_setWakilEmpty,role,L_user
          } = value
      return(<>
      <form>
         <div className='mt5'>
           <h1 className='tc border p-3 mr7 ml7 border-warning text-warning'style={{backgroundColor:"#2C3539"}}>مەسروفات</h1>
         <div className="row f3"style={{color:"#000C66"}} onChange={AddProChange}>

              <div className="col-md-2 text-right">
         <div className="form-group">
              <label className="font-weight-bold">$ کۆی مەسروف </label>
              <NumberFormat displayType='input' thousandSeparator={true} value={Ms_koymasruf$===0?'':Ms_koymasruf$} className="form-control text-right font-weight-bold" />
            </div>
         </div>

              <div className="col-md-2 text-right">
         <div className="form-group">
              <label className="font-weight-bold">ID کۆی مەسروف </label>
              <NumberFormat displayType='input' thousandSeparator={true} value={Ms_koymasrufID===0?'':Ms_koymasrufID} className="form-control text-right font-weight-bold" />
            </div>
        </div>

              <div className="col-md-2 px-2 mt4">
             <div className='row'>
                  <div className='col-md-4'>
               {edit !== null ?
                <button onClick={clear_edit} className="btn btn-danger"><i className="fas fa-window-close"></i></button>:
                <button onClick={(e)=>Ms_setWakilEmpty(e)} className="btn btn-primary"><i className="fas fa-file"></i></button>}
               </div>
                  <div className='col-md-4'>
                {role === 'admin'?
                (Ms_show ===false ?
                  <button onClick={(e)=>Ms_showrasid(e,L_user._id)} className="btn btn-primary"style={{backgroundColor:"orange"}}><i className="fas fa-eye"></i></button>:
                  <button onClick={Ms_showrasid} className="btn btn-primary" style={{backgroundColor:"orange"}}><i className="fas fa-eye-slash"></i></button>):null
                 }
               </div>
                  <div className='col-md-4'>
              {edit !== null ?
              <button onClick={(e)=>Ms_updtae_Wakil(e,edit)} className="btn btn-primary"style={{backgroundColor:"navy"}}><i className="far fa-edit"></i></button>:
              <button onClick={Ms_onSaveWakel} className="btn btn-success"><i className="far fa-save"></i></button>}
               </div>
             </div>
          </div>
          
              <div className="col-sm-2 text-right">
                    <label className='font-weight-bold '>جۆری دراو</label>
                    <select className="form-control text-right font-weight-bold selectpicker pull-right" name="Ms_draw" value={Ms_draw===null?'':Ms_draw} onChange={AddProChange} 
                    onKeyPress={event=>event.key==='Enter'?Ms_tofirstInput(event):null}>
                          <option className='ml5' >..جۆری دراو</option>
                           <option>دۆلار</option>
                           <option>دینار</option>
                        </select>
                    </div>

              <div className="col-md-2 text-right px-2 ">
            <div className="form-group">
              <label className="font-weight-bold">کۆی پارە</label>
              <NumberFormat displayType='input' thousandSeparator={true} value={Ms_koypara===0?'':Ms_koypara} name="Ms_koypara" onKeyPress={event=>event.key==='Enter'?toanotherInput(event):null} onChange={AddProChange} className="form-control text-right font-weight-bold" />
            </div>
          </div>   

              <div className="col-md-2 text-right px-2 ">
            <div className="form-group">
              <label className="font-weight-bold">شوێن/کەس</label>
              <input type="text" value={Ms_naw} name="Ms_naw" onKeyPress={event=>event.key==='Enter'?toanotherInput(event):null} onChange={AddProChange}   className="form-control text-right font-weight-bold" />
              </div>
          </div>



        
          </div>
  </div>
  </form>
  <div className='row'>
          <div className='col-md-12'>
    <FormMasruf/>
    </div>
  </div>

    </>) 
}}
  </ProductConsumer>
  </>

          )
    }

export default MasrufSecond
