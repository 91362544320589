import React from "react";
import { Link } from "react-router-dom";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
const Header = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const { RMS_from, RMS_to, RMS_getPrint, RMS_peshandan, AddProChange } =
          value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning ">
                <button onClick={RMS_getPrint} className="btn btn-warning ">
                  <Link to="/print-masruf" className="text-dark">
                    Print
                  </Link>
                </button>
              </div>
              <div className="col-md-6 mt1 font-weight-bold   form-group text-warning tc">
                <h5>ڕاپۆرتی مەسروفات</h5>
              </div>
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning"></div>
            </div>
            {/* 2 */}
            <div
              className="row  tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-4 mt4 font-weight-bold  pointer form-group text-warning">
                {" "}
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    RMS_peshandan(e);
                  }}
                >
                  پیشاندان
                </button>
              </div>
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-warning ">
                :بۆ بەرواری{" "}
                <span className="text-warning">
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RMS_to"
                    value={RMS_to}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                :لە بەرواری
                <span className="text-warning">
                  {" "}
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RMS_from"
                    value={RMS_from}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
