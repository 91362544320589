import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const Footer = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {} = value;
          return <></>;
        }}
      </ProductConsumer>
    </>
  );
};

export default Footer;
