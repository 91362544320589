import React from "react"
import {ProductConsumer} from '../../../Context/AddProWakel'
import NumberFormat from 'react-number-format'

class FormMasruf extends React.Component {

    render() {
        return (
        
            <>
            <ProductConsumer >
                
                {value=>{
                    const {Ms_data,Ms_clickOnDeleteWakel,Ms_show}=value
                        const wakil=Ms_data.map((user,i)=>{
                            return (
                            <tr key={i} className=' f4'>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.joripara}</td>
                                <td  className="fk"style={{border:"2px solid #f0ad4e"}}>{Ms_show===true ?<NumberFormat value={(user.koypara)} displayType={'text'} thousandSeparator={true}  className='f4'/>:"*********"}</td>
                                <td style={{border:"2px solid #f0ad4e"}} className="fk">{user.shwen}</td>
                                <td style={{border:"2px solid #f0ad4e"}} className="pt-1 pb-0 tc">
                                <button onClick={()=>Ms_clickOnDeleteWakel(user._id)} className="btn btn-danger btn-sm "><i className="fas fa-trash fa-sm" aria-hidden="true"></i></button>
                                </td>
                                
                            </tr>
                            )
                            
            
        })
    
        return (
           
            <div className='tableFixHead'>
                
               <table id="example" className="table table-striped table-bordered text-right "  >
            <thead>
                <tr className='text-warning f4'style={{backgroundColor:"#2C3539"}}>
                    <th style={{border:"2px solid #f0ad4e"}}> جۆری دراو</th>
                    <th style={{border:"2px solid #f0ad4e"}}>کۆی پارە</th>
                    <th style={{border:"2px solid #f0ad4e"}}>شوێن/کەس</th>
                    <th style={{border:"2px solid #f0ad4e"}} className='tc'>X</th>
    
                </tr>
            </thead>
            <tbody>
            {wakil}
            </tbody>
        </table> 
        </div>
        )
            }}
            </ProductConsumer>
            
            </>
        )
    }
}
export default FormMasruf