import React from "react";
import ProductRaport from "./ProductRaport";

const ProductRaportAll = () => {
  return (
    <>
      <div style={{ backgroundColor: "#2C3539" }}>
        <ProductRaport />
      </div>
    </>
  );
};

export default ProductRaportAll;
