import React from 'react'
import {ProductConsumer} from '../../../Context/AddProWakel'
import NumberFormat  from 'react-number-format'

const SearchMandub = () => {
  return (
    <>
    <ProductConsumer>
    {value=>{
        const {MM_note,AddProChange,MM_getParaDataBySearchMoney,MM_qarzeKonDolar,MM_qarzeKonID,
              MM_getWakilDataBySearchMoney,MM_zhmara,MM_joredraw,MM_isChecked,MM_getIsChecked,
              Ap_kasakan,MM_nawebarez,MM_peshandaniQarzyPeshw
        } = value
        const wakilakn=Ap_kasakan.filter(user=>{
          return user.Name.trim().startsWith(MM_nawebarez)
         }).map((item,index)=>{
         return <option className="fk" key={index}>{item.Name}</option>
         })
        return(
        <>
            <div className="card card-primary text-warning font-weight-bold"style={{backgroundColor:"#2C3539"}}>
              <div className="card-header border-bottom border-warning"style={{backgroundColor:"#2C3539"}}>
                <h3 className="card-title tc "> گەران بە دوای </h3>
              </div>

              <form>
                <div className="card-body text-right">
                  <div className="form-group">
                    <label className='font-weight-bold f4'>:پسوولە</label>
                    <input onKeyPress={event=>event.key==='Enter'?MM_getParaDataBySearchMoney(event):null} type="text" onChange={AddProChange} value={MM_zhmara} name="MM_zhmara" className="form-control text-right font-weight-bold" placeholder="...گەڕان"/>
                  </div>
                  <div className="form-group">
                    <label className='font-weight-bold f4'>: معمیل </label>
                    <select className="form-control text-right font-weight-bold selectpicker pull-right " onChange={AddProChange} name='MM_nawebarez' value={MM_nawebarez} onKeyPress={event=>event.key==='Enter'?MM_getWakilDataBySearchMoney(event):null}  >
                        <option className='ml5' >Search</option>
                           {wakilakn}
                    </select>
                  </div>

                  <div className="form-group">
                    <label className='font-weight-bold f4'>:تێبینی </label>
                    <input type="text" onChange={AddProChange} value={MM_note} name="MM_note" className="form-control text-right font-weight-bold"  placeholder="...تێبینی"/>
                  </div>

                </div>
                <div className="col-md-12 text-right ml5">
                  <table id="non">
                    <thead>
                      <tr>
                      <th className='text-right pb-2 text-warning f4'><input onChange={MM_getIsChecked} checked={MM_isChecked===true?true:false} value={MM_isChecked} type='checkbox' className='mr3'/>:پیشاندانی ڕەسیدی پێشوو</th>
                      </tr>
                    </thead>
                    <tbody>
                    {MM_isChecked === true ?
                    <>
                    <tr>
                    <td  className='text-right'> 
                      <label className='font-weight-bold text-warning f4'>: ڕەسیدی کۆن</label>
                      <NumberFormat value={MM_joredraw === 'دۆلار'? MM_qarzeKonDolar :MM_qarzeKonID}   displayType={'text'} className="form-control text-right font-weight-bold" thousandSeparator={true} prefix={MM_joredraw===null?'':(MM_joredraw === 'دۆلار' ? '$' :'ID')} />
                      
                    </td>
                    </tr>
                    <tr>
                    <td className='text-right '>
                      <label className='font-weight-bold text-warning f4'>:ڕەسیدی ئێستا</label>
                      <NumberFormat value={MM_peshandaniQarzyPeshw()} displayType={'text'} className="form-control text-right font-weight-bold" thousandSeparator={true} prefix={MM_joredraw===null?'':(MM_joredraw === 'دۆلار' ? '$' :'ID')} />
                    
                    </td>
                    </tr>
                    </>
                    :
                    null}
                    </tbody>
                  </table>
                </div>
                <div className="card-footer"style={{backgroundColor:"#2C3539"}}>

                </div>
              </form>
            </div>
            </>
                    )

                  }}
  
              </ProductConsumer>

    </>)
}

export default SearchMandub
