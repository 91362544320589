import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScrollTable from "./ScrollTable";
import FooterOfRent from "./FooterOfRent";
import FillDetailRent from "./FillDetailRent";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";

const TableRent = () => {
  return (
    <>
      {isMobile ? (
        <AllMob />
      ) : (
        <div style={{ backgroundColor: "#2C3539" }}>
          <Navbar />
          <FillDetailRent />

          <br></br>
          <div className="bg-white">
            <ScrollTable />
          </div>
          <br></br>
          {/* wasl para */}
          <FooterOfRent />
        </div>
      )}
      {/* end wasl u para */}
    </>
  );
};

export default TableRent;
