import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const FooterOfKren = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            KM_waslKrdn,
            KM_total,
            KM_paraDan$,
            KM_joripara,
            G_rasidWakildolar,
            G_rasidWakildinar,
            G_rasidWakildolarNew,
            G_frosht,
          } = value;
          return (
            <>
              {KM_joripara === "دۆلار" ? (
                <>
                  <div className="row fk">
                    <div className="div-col-5 ">
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={KM_total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={KM_paraDan$ === "" ? 0 : KM_paraDan$}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          className=" border-0"
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={KM_paraDan$ !== "" ? KM_waslKrdn() : KM_total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </h3>
                      <hr />
                    </div>

                    <div className="div-col-4">
                      <h3 className=" ml5 mb3">:$ کۆی گشتی</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:$ پارەی دراو</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:$ پارەی نەدراو</h3>
                      <hr />
                    </div>
                    <div className="col-5">
                      <div className="row">
                        <div className="div-col-6 ">
                          {G_frosht === "فرۆشتنی معمیل" ? (
                            <>
                              <h3>
                                <NumberFormat
                                  value={G_rasidWakildinar}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              </h3>
                              <hr />
                              <h3>
                                <NumberFormat
                                  value={G_rasidWakildolar}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </h3>
                              <hr />
                              <h3>
                                <NumberFormat
                                  value={G_rasidWakildolarNew}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </h3>
                              <hr />
                            </>
                          ) : null}
                        </div>

                        <div className="div-col-4">
                          {G_frosht === "فرۆشتنی معمیل" ? (
                            <>
                              <h3 className="ml5">:ID قەرزی کۆن</h3>
                              <hr />
                              <h3 className="ml5">:$ قەرزی کۆن</h3>
                              <hr />
                              <h3 className="ml5"> :$ قەرزی نوێ</h3>
                              <hr />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfKren;
