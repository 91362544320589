import React from "react";
import { Link } from "react-router-dom";
import { ProductConsumer } from "../../../Context/AddProWakel";
const FillDetailQarzRastawxo = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            QR_from,
            QR_to,
            QR_name,
            AddProChange,
            PD_getPrint,
            QR_filterQarz,
            QR_hamwqarzakan,
          } = value;
          return (
            <>
              <div className="row" style={{ backgroundColor: "#2C3539" }}>
                <div className="col-lg-2  col-md-4 form-inline">
                  <div className="row form-inline">
                    <div className="col-md-6 form-inline">
                      <p
                        className="font-weight-bold bg-white  f3"
                        style={{ color: "#000" }}
                      ></p>
                      <span style={{ color: "#2C3539" }}>:</span>
                      <p
                        className="font-weight-bold bg-white  f4"
                        style={{ color: "#000" }}
                      >
                        {QR_from}
                      </p>
                    </div>
                    <div className='col-lg-6 col-md-6 form-inline"'>
                      <label className=" font-weight-bold f3 text-warning">
                        :بۆ بەرواری{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 form-inline">
                  <div className="row form-inline">
                    <div className="col-md-6 form-inline">
                      <p
                        className="font-weight-bold bg-white  f3"
                        style={{ color: "#000" }}
                      ></p>
                      <span style={{ color: "#2C3539" }}>:</span>
                      <p
                        className="font-weight-bold bg-white  f4"
                        style={{ color: "#000" }}
                      >
                        {QR_to}
                      </p>
                    </div>
                    <div className='col-lg-6 col-md-6 form-inline"'>
                      <label className=" font-weight-bold f3 text-warning">
                        :لە بەرواری{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-4 mt3">
                  <div className="row">
                    <div className="col-lg-11 ">
                      <div className="row">
                        <div className="col-md-8">
                          <input
                            type="text"
                            name="QR_name"
                            value={QR_name}
                            onChange={AddProChange}
                            aria-label="Large"
                            className="form-control"
                            placeholder="گەڕان"
                          />
                        </div>

                        <div className="col-md-2 " onClick={QR_filterQarz}>
                          <label className="btn btn-info">قەرزەکان</label>
                        </div>
                        <div className="col-md-2" onClick={QR_hamwqarzakan}>
                          <label className="btn btn-success">هەموو</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-12">
                      <Link to="/print-rastawxo-qarz" onClick={PD_getPrint}>
                        <button className="btn btn-warning">Print</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FillDetailQarzRastawxo;
