import React from 'react'
import ScroollCin from './ScroollCin'
import {ProductConsumer} from '../../../Context/AddProWakel'
import Time from 'react-time-format'

const ScroolTableCin = () => {
    return (
        
        <>
        <ProductConsumer >
            
            {value=>{
                const {RC_data,RC_clickOnDelete,RC_dis}=value
                    const RoboFrinds=RC_data.map((user,i)=>{
                        return (
                        <tr key={i} className='f4'>
                            <td className='fk' style={{border:"2px solid #f0ad4e"}}>{user.darzan}</td>
                            <td className='fk' style={{border:"2px solid #f0ad4e"}}>{user.karton}</td>
                            <td className='fk' style={{border:"2px solid #f0ad4e"}}>{user.wasl}</td>
                            <td className='fk' style={{border:"2px solid #f0ad4e"}}><Time value={user.date} format="YYYY-MM-DD"/></td>
                            <td style={{border:"2px solid #f0ad4e"}}  ><button disabled={RC_dis === true?true:null} onClick={()=>RC_clickOnDelete(user._id)}   className="btn btn-danger "><i className="fa fa-minus" aria-hidden="true"></i></button></td>
                        </tr>
                        )
                        
        
    })

    return (
       
        <ScroollCin>
            
           <table id="example" className="table table-hover table-bordered text-right "  >
        <thead>
            <tr className='text-warning f4' style={{backgroundColor:"#2C3539"}}>
                <th style={{border:"2px solid #f0ad4e"}}>دانە</th>
                <th style={{border:"2px solid #f0ad4e"}}>کارتۆن</th>
                <th style={{border:"2px solid #f0ad4e"}}>ژ.پسوولە</th>
                <th style={{border:"2px solid #f0ad4e"}}>بەروار</th>
                <th style={{border:"2px solid #f0ad4e"}}>X</th>

            </tr>
        </thead>
        <tbody>
        {RoboFrinds}
        </tbody>
    </table> 
    </ScroollCin>
    )
        }}
        </ProductConsumer>
        
        </>
    )
}

export default ScroolTableCin
