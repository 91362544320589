import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScrollTableKoga from "./ScroollTableKoga";
import FooterOfKoga from "./FooterOfKoga";
import FillDetailKoga from "./FillDetailKoga";

const AllKoga = () => {
  return (
    <>
      <Navbar />
      <div style={{ backgroundColor: "#2C3539" }}>
        <FillDetailKoga />
        <br></br>
        <div className="bg-white">
          <ScrollTableKoga />
        </div>
        <br></br>
        {/* wasl para */}
        <FooterOfKoga />
      </div>
      {/* end wasl u para */}
    </>
  );
};

export default AllKoga;
