import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { Q_data, Q_wasl, Q_rastawxo, D_gotowaslrentsearch } = value;
            const wakelRaport = Q_data.map((user, i) => {
              return (
                <tr key={i} className=" ">
                  <td className="" style={{ border: "1px solid #f0ad4e" }}>
                    {Number(user.qazanj).toFixed(2)}
                  </td>
                  <td className="" style={{ border: "1px solid #f0ad4e" }}>
                    {user.naw}
                  </td>
                  {Q_wasl === true ? (
                    <td className="" style={{ border: "1px solid #f0ad4e" }}>
                      {user.wakelcode}
                    </td>
                  ) : (
                    ""
                  )}
                  <td className="" style={{ border: "1px solid #f0ad4e" }}>
                    <Time format="YYYY-MM-DD" value={user.barwar} />
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid #f0ad4e" }}
                    onClick={
                      Q_wasl === true || Q_rastawxo === true
                        ? (e) => D_gotowaslrentsearch(e, user.code)
                        : null
                    }
                  >
                    {Q_wasl === true || Q_rastawxo === true ? (
                      <>
                        <Link to="/rent">{user.code}</Link>
                      </>
                    ) : (
                      user.code
                    )}
                  </td>
                </tr>
              );
            });

            return (
              <div className="tableFixHead">
                <table
                  id="example"
                  className="table table-hover table-bordered text-right "
                >
                  <thead>
                    <tr
                      className="text-warning "
                      style={{
                        border: "1px solid #f0ad4e",
                        backgroundColor: "#2e3541 ",
                      }}
                    >
                      <th style={{ border: "1px solid #f0ad4e" }}>قازانج</th>
                      <th style={{ border: "1px solid #f0ad4e" }}>ناو</th>
                      {Q_wasl === true ? (
                        <th style={{ border: "1px solid #f0ad4e" }}>ژ.وەکیل</th>
                      ) : (
                        ""
                      )}
                      <th style={{ border: "1px solid #f0ad4e" }}>بەروار</th>
                      <th style={{ border: "1px solid #f0ad4e" }}>
                        {Q_wasl === true || Q_rastawxo === true
                          ? "ژ.پسوولە"
                          : "کۆد"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{wakelRaport}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
