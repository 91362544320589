import React from "react";
import NavbarRozhana from "./NavbarRozhana";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableRozhana from "./ScroollTableRozhana";
import FooterOfRozhana from "./FooterOfRozhana";
import { ProductConsumer } from "../../../Context/AddProWakel";
import { isMobile } from "react-device-detect";
import AllMob from "./Mob/AllMob";
const AllRozhana = (props) => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {} = value;
          return (
            <>
              <div>
                <Navbar />
                {isMobile ? (
                  <AllMob />
                ) : (
                  <>
                    <div className="row">
                        <div className="col-md-10 mt5">
                        <ScroollTableRozhana />
                      </div>
                        <div className="col-md-2">
                        <NavbarRozhana />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                        <div className="col-md-12">
                        <FooterOfRozhana />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default AllRozhana;
