import React from "react";
import SearchParaGorenawa from "./SearchParaGorenawa";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Time from "react-time-format";

const FormParaGorenawa = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            PG_zhmara,
            PG_openUpdate,
            PG_search_wasl,
            PG_barwar,
            PG_del_dis,
            PM_parayesta$,
            PM_parayestaID,
            onChangeKriniDolar,
            PG_parayesta$,
            PG_parayestaID,
            PG_onSaveMoney,
            PG_getParaDataBySearchMoney,
            PG_breParabaID,
            PG_getPrint,
            PG_createNewMony,
            PG_toggler,
            role,
            PG_show,
            PG_showrasid,
            PG_nrxeKrine$,
            PG_breKrini$,
            onChangebreParaDanbaID,
            PG_save,
            PG_nawewargr,
            PG_open,
            L_user,
            PG_wasl,
            PG_updtate_para,
            PG_pageminsone,
            PG_pageplusone,
            PG_inputtogler,
          } = value;
          const renderdate = (kat) => {
            const monthname = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ];
            const date = new Date(kat);

            return `${date.getDate()}/${
              monthname[date.getMonth()]
            }/${date.getFullYear()}`;
          };
          return (
            <>
              <div className="row ">
                <div className="col-md-1"></div>
                <div className="col-md-12 col-lg-4 ">
                  {/* Search take money */}
                  <SearchParaGorenawa />
                </div>
                <div className="col-md-1"></div>
                {/* Form take moneyy */}
                <div className="col-lg-6 text-warning col-md-12 ">
                  <div
                    className="card card-warning "
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <div
                      className="card-header p-1 border-bottom border-warning"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <h2 className="card-title tc">پارەگۆڕینەوە </h2>
                    </div>

                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-3 text-right mt2">
                            <button
                              disabled={PG_del_dis === true ? true : null}
                              onClick={PG_inputtogler}
                              className="btn btn-info mt4"
                            >
                              <i className="far fa-calendar-alt"></i>
                            </button>
                          </div>

                          <div
                            className={`${
                              PG_toggler === true
                              ? "col-sm-6 text-right"
                              : "col-sm-3 text-right"
                            }`}
                          >
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بەروار
                              </label>
                              {PG_toggler === false ? (
                                <input
                                  type="text"
                                  name="PG_barwar"
                                  value={
                                    PG_search_wasl === true || PG_save === true
                                      ? renderdate(PG_barwar)
                                      : renderdate(Date.now())
                                  }
                                  className="form-control text-right font-weight-bold"
                                />
                              ) : (
                                <input
                                  type="datetime-local"
                                  name="PG_barwar"
                                  value={PG_barwar}
                                  onChange={AddProChange}
                                  className="form-control text-right font-weight-bold tc btn btn-info"
                                  style={{ margin: "0px 8px" }}
                                />
                              )}
                            </div>
                          </div>
                          {PG_toggler === false ? (
                            <div className="col-sm-3 text-right">
                              <div className="form-group">
                                <label className="font-weight-bold f4">
                                  کات
                                </label>
                                {PG_search_wasl === true || PG_save === true ? (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={PG_barwar}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                ) : (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={Date.now()}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : null}

                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژمارە
                              </label>
                              <input
                                type="text"
                                name="PG_zhmara"
                                disabled={PG_del_dis === true ? true : null}
                                value={PG_zhmara}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? PG_getParaDataBySearchMoney(event)
                                    : null
                                }
                                onChange={AddProChange}
                                className={`form-control text-right font-weight-bold ${
                                  PG_search_wasl === true || PG_save === true
                                    ? "bg-danger text-white"
                                    : ""
                                }`}
                              />
                            </div>
                          </div>
                          <div className="col-md-2 text-right"></div>
                          <div className="col-md-1 text-right mt-5">
                            {role === "admin" ? (
                              PG_show === false ? (
                                <button
                                  onClick={(e) => PG_showrasid(e, L_user._id)}
                                  className="btn btn-primary"
                                  style={{ backgroundColor: "orange" }}
                                >
                                  <i className="fas fa-eye"></i>
                                </button>
                              ) : (
                                <button
                                  onClick={PG_showrasid}
                                  className="btn btn-primary"
                                  style={{ backgroundColor: "orange" }}
                                >
                                  <i className="fas fa-eye-slash"></i>
                                </button>
                              )
                            ) : null}
                          </div>
                          <div className="col-md-1 text-right"></div>

                          <div className="col-sm-4 text-right p-2 border-bottom border-warning">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ID ڕەسیدی ئێستا بە{" "}
                              </label>
                              <NumberFormat
                                value={PM_parayestaID}
                                displayType={"text"}
                                className="form-control f4 text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-danger"
                                thousandSeparator={true}
                                prefix={"ID"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 text-right p-2 border-bottom border-warning">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                $ ڕەسیدی ئێستا بە{" "}
                              </label>
                              <NumberFormat
                                value={PM_parayesta$}
                                displayType={"text"}
                                className="form-control f4 text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-success"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 text-right "></div>
                          <div className="col-sm-5 text-right mt-3">
                            <NumberFormat
                              displayType="input"
                              thousandSeparator={true}
                              disabled={PG_del_dis === true ? true : null}
                              name="PG_breParabaID"
                              className="form-control text-right font-weight-bold border-warning text-white bg-danger"
                              onChange={onChangebreParaDanbaID}
                              value={PG_breParabaID === 0 ? "" : PG_breParabaID}
                              style={{ padding: "20px", fontSize: "20px" }}
                            />
                          </div>

                          <div className="col-sm-4 text-right mt-3">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                : بڕی پارە بە دینار
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-3 text-right "></div>
                          <div className="col-sm-5 text-right mt-3">
                            <NumberFormat
                              displayType="input"
                              thousandSeparator={true}
                              disabled={PG_del_dis === true ? true : null}
                              name="PG_nrxeKrine$"
                              className="form-control text-right font-weight-bold"
                              onChange={onChangeKriniDolar}
                              value={PG_nrxeKrine$ === 0 ? "" : PG_nrxeKrine$}
                              style={{ padding: "20px", fontSize: "20px" }}
                            />
                          </div>

                          <div className="col-sm-4 text-right mt-3">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                : نرخی کڕینی دۆلار
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-3 text-right "></div>
                          <div className="col-sm-5 text-right mt-3">
                            <NumberFormat
                              displayType="text"
                              thousandSeparator={true}
                              disabled={PG_del_dis === true ? true : null}
                              className="border-warning text-white bg-success form-control text-right font-weight-bold"
                              value={
                                PG_breKrini$ === 0
                                  ? ""
                                  : Number(PG_breKrini$).toFixed(2)
                              }
                              style={{ fontSize: "20px" }}
                            />
                          </div>

                          <div className="col-sm-4 text-right mt-3">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                : بڕی کڕینی دۆلار
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-3 text-right"></div>

                          <div className="col-sm-5 text-right mt-3">
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                PG_nawewargr === "حاجی ڕەقیب"
                                  ? "bg-success text-white"
                                  : `${
                                      PG_nawewargr === "حاجی رەمەزان"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              disabled={PG_del_dis === true ? true : null}
                              onChange={AddProChange}
                              name="PG_nawewargr"
                              value={PG_nawewargr !== null ? PG_nawewargr : ""}
                            >
                              <option className="ml5">ناوی وەرگر</option>
                              <option>حاجی ڕەقیب</option>
                              <option>حاجی رەمەزان</option>
                            </select>
                          </div>

                          <div className="col-md-4 text-right mt-3">
                            <label className="font-weight-bold f4">
                              : ناوی وەرگر
                            </label>
                          </div>
                          {/* <div className="col-md-1 text-right"></div> */}
                          <div className="col-sm-8 mt4">
                            <button
                              className="btn btn-dark p3 px-4"
                              style={{
                                backgroundColor: "#00695c",
                                border: "1px solid #00695c",
                              }}
                              disabled={PG_open === true ? true : null}
                              onClick={PG_pageminsone}
                            >
                              <i className="fas fa-caret-left f4"></i>
                            </button>
                            <button
                              className="btn btn-dark p3 px-4 ml3"
                              style={{
                                backgroundColor: "#00695c",
                                border: "1px solid #00695c",
                              }}
                              disabled={PG_open === true ? true : null}
                              onClick={PG_pageplusone}
                            >
                              <i className="fas fa-caret-right f4"></i>
                            </button>
                            {PG_search_wasl === true ? (
                              <>
                                {PG_open === null ? (
                                  <button
                                    onClick={(e) =>
                                      PG_openUpdate(L_user._id, e)
                                    }
                                    className="btn btn-md btn-warning px-4 ml3"
                                    style={{
                                      backgroundColor: "silver",
                                      border: "1px solid silver",
                                    }}
                                  >
                                    <i className="fas fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    onClick={(e) =>
                                      PG_updtate_para(
                                        e,
                                        PG_wasl,
                                        PG_wasl.wakilcode
                                      )
                                    }
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{ backgroundColor: "navy" }}
                                  >
                                    <i className="fas fa-edit "></i>
                                  </button>
                                )}
                                {/* <Link to='/print-paradarhenan'>
                        <button onClick={PG_getPrint} type="button" className="btn btn-md btn-primary px-4 ml3" style={{backgroundColor:"purple",border:"1px solid purple"}}>
                        <i className="fas fa-print fa-lg"></i>
                       </button>
                       </Link> */}
                              </>
                            ) : PG_save === null ? (
                              <button
                                type="button"
                                onClick={PG_onSaveMoney}
                                className="btn btn-md btn-success px-4 ml3"
                              >
                                <i className="far fa-save fa-lg"></i>
                              </button>
                            ) : null}
                            <button
                              type="button"
                              onClick={PG_createNewMony}
                              disabled={PG_open === true ? true : null}
                              className="btn btn-md btn-primary px-4 ml3"
                            >
                              <i className="far fa-file fa-lg"></i>
                            </button>
                            {/* <Link to='/print-paradarhenan'> */}
                            {PG_save === true ? (
                              <>
                                {/* <button onClick={PG_getPrint} type="button" className="btn btn-md btn-primary px-4 ml3"style={{backgroundColor:"purple",border:"1px solid purple"}}>
                            <i className="fas fa-print fa-lg"></i>
                           </button> */}
                                {PG_open === null ? (
                                  <button
                                    onClick={(e) =>
                                      PG_openUpdate(L_user._id, e)
                                    }
                                    className="btn btn-md btn-warning px-4 ml3"
                                    style={{
                                      backgroundColor: "silver",
                                      border: "1px solid silver",
                                    }}
                                  >
                                    <i class="fas fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    onClick={(e) => PG_updtate_para(e, PG_wasl)}
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{ backgroundColor: "navy" }}
                                  >
                                    <i className="fas fa-edit "></i>
                                  </button>
                                )}
                              </>
                            ) : null}
                            {/* </Link> */}
                            <br /> <br />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default FormParaGorenawa;
