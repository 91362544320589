import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const FooterOfRozhana = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            d_paraynadrawID,
            d_paraynadraw$,
            d_wargrtnawayQarzID,
            d_wargrtnawayQarz$,
            d_paraywargerawID,
            d_paraywargeraw$,
            d_froshrawID,
            d_froahraw$,
            d_rahelID,
            d_rahel$,
            d_yassinID,
            d_yassin$,
          } = value;
          return (
            <>
              <br />
              <div className="row">
                <div className="col-md-12 ">
                  <div className="row">
                    <div className="col-md-2">
                      <h4 className="text-warning tc">پارەی نەدراو</h4>
                    </div>

                    <div className="col-md-2">
                      <h4 className="text-warning">وەرگرتنەوەی قەرز</h4>
                    </div>

                    <div className="col-md-2">
                      <h4 className="text-warning tc">پارەی وەرگیراو</h4>
                    </div>

                    <div className="col-md-2">
                      <h4 className="text-warning tc">فرۆشراوەکان</h4>
                    </div>

                    <div className="col-md-2">
                      <h4 className="text-warning tc">حاجی رەمەزان</h4>
                    </div>

                    <div className="col-md-2">
                      <h4 className="text-warning tc">‌‌‌‌‌‌حاجی ڕەقیب</h4>
                    </div>
                  </div>
                </div>
              </div>
              {/* second row */}
              <div className="row">
                <div className="col-md-12">
                  <div className="row mb2">
                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_paraynadraw$}
                          prefix="$"
                        />
                      </h3>
                    </div>

                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_wargrtnawayQarz$}
                          prefix="$"
                        />
                      </h3>
                    </div>

                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_paraywargeraw$}
                          prefix="$"
                        />
                      </h3>
                    </div>

                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_froahraw$}
                          prefix="$"
                        />
                      </h3>
                    </div>

                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_yassin$}
                          prefix="$"
                        />
                      </h3>
                    </div>

                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_rahel$}
                          prefix="$"
                        />
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              {/* third row */}
              <div className="row">
                <div className="col-md-12">
                  <div className="row ">
                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_paraynadrawID}
                          prefix="ID"
                        />
                      </h3>
                    </div>

                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_wargrtnawayQarzID}
                          prefix="ID"
                        />
                      </h3>
                    </div>

                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_paraywargerawID}
                          prefix="ID"
                        />
                      </h3>
                    </div>

                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_froshrawID}
                          prefix="ID"
                        />
                      </h3>
                    </div>

                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_yassinID}
                          prefix="ID"
                        />
                      </h3>
                    </div>

                    <div className="col-md-2">
                      <h3 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="bg-white f4 font-weight-bold   border-0"
                          thousandSeparator={true}
                          value={d_rahelID}
                          prefix="ID"
                        />
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfRozhana;
