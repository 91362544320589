import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              K_datatable,
              K_gotowaslrentsearch,
              k_navbar_tomarkrdn,
              K_yaksanbasfr,
              K_zyattrlasfr,
              K_kamtrlasfr,
              K_gotowaslGaranawasearch,
            } = value;
            const raportyRozhana = K_datatable.map((user, i) => {
              return (
                <>
                  {k_navbar_tomarkrdn === true ? (
                    <tr key={i} className=" ">
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.froshtn}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.froshtndinar}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.froshtndolar}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.krin}
                      </td>
                      <td
                        style={{ border: "1px solid #f0ad4e" }}
                        className={` ${
                          user.joripara === "دۆلار" ? "dark-green" : "dark-red"
                        }`}
                      >
                        {user.joripara}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        <Time
                          value={user.barwar}
                          format="YYYY-MM-DD"
                          style={{ color: "black" }}
                        />
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.jwlla}
                      </td>
                    </tr>
                  ) : (
                    <tr
                      key={i}
                      className=" "
                      style={{
                        backgroundColor: `${
                          K_yaksanbasfr === true
                            ? "#5bc0de"
                            : K_kamtrlasfr === true
                            ? "#f78b8b"
                            : K_zyattrlasfr === true
                            ? "#7ee6ab"
                            : ""
                        }`,
                      }}
                    >
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.froshtn}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.froshtndinar}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.krin}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.froshtndolar}
                      </td>
                      <td
                        style={{ border: "1px solid #f0ad4e" }}
                        className={` ${
                          user.joripara === "دۆلار" ? "dark-green" : "dark-red"
                        }`}
                      >
                        {user.joripara}
                      </td>
                      <td
                        className=""
                        style={{
                          border: "1px solid #f0ad4e",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          user.jwlla === "فرۆشتن"
                            ? K_gotowaslrentsearch(e, user.numberofwasl)
                            : K_gotowaslGaranawasearch(e, user.numberofwasl);
                        }}
                      >
                        <Link
                          to={`${
                            user.jwlla === "فرۆشتن" ? "/rent" : "/back-koga"
                          }`}
                        >
                          {user.numberofwasl}
                        </Link>
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.naw}
                      </td>
                      <td
                        className=""
                        style={{
                          border: "1px solid #f0ad4e",
                          backgroundColor: `${
                            user.jor === "فرۆشتنی ڕاستەوخۆ" ? "red" : ""
                          }`,
                        }}
                      >
                        {user.code}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        <Time
                          value={user.barwar}
                          format="YYYY-MM-DD"
                          style={{ color: "black" }}
                        />
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.jwlla}
                      </td>
                    </tr>
                  )}
                </>
              );
            });

            return (
              <div class="tableFixHead">
                <table
                  id="example"
                  className="table table-bordered text-right table-hover "
                >
                  <thead>
                    {k_navbar_tomarkrdn === true ? (
                      <tr
                        className="text-warning "
                        style={{ backgroundColor: "#2e3541 " }}
                      >
                        <th style={{ border: "2px solid #f0ad4e" }}>دانە</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>ID نرخ</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>$ نرخ</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>$$$نرخ</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>دراو</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>بەروار</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>جوولە</th>
                      </tr>
                    ) : (
                      <tr
                        className="text-warning "
                        style={{ backgroundColor: "#2e3541 " }}
                      >
                        <th style={{ border: "2px solid #f0ad4e" }}>دانە</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>IDنرخ</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>$نرخ</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>فرۆشتن</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>دراو</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>پسوولە</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>
                          ناوی کڕیار
                        </th>
                        <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>بەروار</th>
                        <th style={{ border: "2px solid #f0ad4e" }}>جوولە</th>
                      </tr>
                    )}
                  </thead>
                  <tbody className="table table-bordered text-right table-hover ">
                    {raportyRozhana}
                  </tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
