import React from 'react'
import Time from 'react-time-format'
import { ProductConsumer } from '../../../Context/AddProWakel'
const FillDetailParayNagarawa = () => {
    
    return (<>
    
        <ProductConsumer>
            {value=>{
              const {AddProChange,RP_zhmara,RP_to,RP_from,RP_naw} = value
          return(<>
               <div className="row" style={{backgroundColor:"#2C3539"}}>

                  <div className="col-md-3 form-inline">
                <div className="row form-inline">
                          <div className="col-md-6 form-inline">
                    <p className="font-weight-bold bg-white  f4"style={{color:"#000"}}></p><span style={{color:"#2C3539"}}>:</span>
                    <p className="font-weight-bold bg-white  f4"style={{color:"#000"}}>{RP_to}</p>   
                </div>
                          <div className='col-md-6 form-inline"'>
                    <label className=" font-weight-bold f3 text-warning">:بۆ بەرواری </label>
                </div>
                </div>
                </div>

                  <div className="col-md-3 form-inline">
                <div className="row form-inline">
                          <div className="col-md-6 form-inline">
                    <p className="font-weight-bold bg-white  f4"style={{color:"#000"}}></p><span style={{color:"#2C3539"}}>:</span>
                    <p className="font-weight-bold bg-white  f4"style={{color:"#000"}}>{RP_from}</p>   
                </div>
                          <div className='col-md-6 form-inline"'>
                    <label className=" font-weight-bold f3 text-warning">:لە بەرواری </label>
                </div>
                </div>
                </div>

                
                  <div className="col-md-4  mt3">
                <div className="row mt1">
                          <div className="col-md-6">
                <input type="search" className="text-right" onChange={AddProChange} aria-label="Large" name="RP_naw" value={RP_naw} className="form-control" />   
                </div>
                          <div className="col-md-3">
                <input type="search" className="text-right" onChange={AddProChange} aria-label="Large" name="RP_zhmara" value={RP_zhmara} className="form-control" />   
                </div> 
                          <div className="col-md-3">
                <label  className="f3 font-weight-bold text-warning">گەڕان  </label>   
                 </div>
                 </div>  
                </div>
                  <div className="col-md-2 mt3">
                        <h3 className="font-weight-bold text-warning mt1"> پارەی نەگەڕاوە</h3>
                </div>


                </div>
                
                </>) 
        }}
  </ProductConsumer>
  </>

          )
    }

export default FillDetailParayNagarawa
