import React from "react";
import { Link } from "react-router-dom";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
const Header = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const { AddProChange, RPM_from, RPM_to, RPM_getPrint, RPM_peshandan } =
          value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning ">
                <Link to="/print-paraydarhenraw">
                  <button className="btn btn-warning" onClick={RPM_getPrint}>
                    چاپکردن
                  </button>
                </Link>
              </div>
              <div className="col-md-9 mt1 font-weight-bold   form-group text-warning tc">
                <h4 className=""> ڕاپۆرتی پارەی دەرهێنراو</h4>
              </div>
            </div>

            <div
              className="row text-right pt2"
              style={{ backgroundColor: "#2e3541 " }}
            >
              <div className="col-md-2 mt3 text-warning font-weight-bold  pointer form-group">
                {" "}
                <button className="btn btn-info" onClick={RPM_peshandan}>
                  پیشاندان
                </button>
              </div>
              <div className="col-md-5 text-warning font-weight-bold  pointer form-group">
                :بۆ بەرواری{" "}
                <span className="text-warning">
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RPM_to"
                    value={RPM_to}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
              <div className="col-md-5 text-warning font-weight-bold  pointer form-group">
                :لە بەرواری
                <span className="text-warning">
                  {" "}
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RPM_from"
                    value={RPM_from}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
