import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableMandub from "./ScroollTableMandub";
import FillDetailMandub from "./FillDetailMandub";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";
const AllMandub = () => {
  return (
    <div>
      <Navbar />
      {isMobile ? (
        <AllMob />
      ) : (
        <div style={{ marginTop: "50px" }}>
          <div className="row" style={{ backgroundColor: "#2e3541 " }}>
              <div className="col-md-12 ">
              <FillDetailMandub />
            </div>
          </div>
          <div className="row">
              <div className="col-md-12 ">
              <ScroollTableMandub />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllMandub;
