import React from 'react'
import { ProductConsumer } from '../../../Context/AddProWakel'
import NumberFormat from 'react-number-format'
const FooterOfParayDarhenraw = () => {
    return (<>
        <ProductConsumer>
            {value=>{
              const {RPM_para$,RPM_paraID} = value
          return(<>
                {/* second row */}
                <div className="row pt2">
                  <div className="col-lg-10 col-md-12">
              <div className="row ">
                          <div className="col-lg-3 col-md-4">
                <h3 className="bg-white tc mt2"><NumberFormat displayType='text' thousandSeparator={true} value={RPM_para$} /></h3>
            </div>
                          <div className="col-lg-2 col-md-4">
                <h3 className="text-warning tc  mt1">پارە بە دۆلار</h3>
            </div>
                          <div className="col-lg-2 col-md-4"></div>
                          <div className="col-lg-3 col-md-4">
                <h3 className="bg-white tc mt2"><NumberFormat displayType='text' thousandSeparator={true} value={RPM_paraID} /></h3>
            </div>
                          <div className="col-lg-2 col-md-4 mt1">
                <h3 className="text-warning tc ">پارە بە دینار</h3>
            </div>
            </div>
            </div>
            </div>
            <br/>
            {/* third row */}
            <br/>
            </>) 
        }}
  </ProductConsumer>
  </>

          )
    }


export default FooterOfParayDarhenraw
