import React from "react";
import SearchTakeMoney from "./SearchTakeMoney";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Time from "react-time-format";
import { isMobile } from "react-device-detect";
import "../TableForRent/Search.css";

const FormTakeMoney = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            P_zhmara,
            P_joripara,
            P_zh_barez,
            P_openUpdate,
            bripara,
            P_search_wasl,
            P_barwar,
            P_del_dis,
            P_nawe_barez,
            P_qarzeKonDolar,
            P_qarzeKonID,
            onSaveMoney,
            getParaDataBySearchMoney,
            setSearchMonyWakilEmpty,
            P_brePara,
            P_dashkan,
            P_kamkrdnawayNrx,
            P_getPrint,
            getWakilDataBySearchMoney,
            createNewMony,
            P_togler,
            P_error,
            P_ClearError,
            P_save,
            P_wargr,
            P_open,
            L_user,
            P_wasl,
            updtate_para,
            pageminsone,
            pageplusone,
            P_inputtogler,
            P_hideLoader,
            P_showLoader,
            P_loading,
            toanotherInput,
            W_getwakelakan,
            P_suggestion,
            P_suggestionSelected,
            P_Search_Rent_Handle,
          } = value;
          const renderdate = (kat) => {
            const monthname = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ];
            const date = new Date(kat);

            return `${date.getDate()}/${
              monthname[date.getMonth()]
            }/${date.getFullYear()}`;
          };
          return (
            <>
              <div className="row ">
                <div className="col-md-1"></div>
                {isMobile ? (
                  ""
                ) : (
                    <div className="col-md-12 col-lg-4 ">
                    {/* Search take money */}
                    {isMobile ? "" : <SearchTakeMoney />}
                  </div>
                )}
                <div className="col-md-1"></div>
                {/* Form take moneyy */}
                <div className="col-lg-6 text-warning col-md-12 ">
                  <div
                    className="card card-warning "
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <div
                      className="card-header p-1 border-bottom border-warning"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <h2 className="card-title tc">پارە وەرگرتن</h2>
                    </div>

                    <div className="card-body">
                      <div className="b--black text-right">
                        {P_error !== null && (
                          <button
                            className="btn btn-success text-right"
                            type="button"
                          >
                            {P_error.msg ? P_error.msg : P_error.error[0].msg}
                            <span
                              className="ml-2  border-black"
                              onClick={() => P_ClearError()}
                            >
                              X
                            </span>
                          </button>
                        )}
                      </div>
                      <form>
                        <div className="row">
                          <div className="col-md-3 text-right mt2">
                            <button
                              disabled={P_del_dis === true ? true : null}
                              onClick={P_inputtogler}
                              className="btn btn-info mt4"
                            >
                              <i className="far fa-calendar-alt"></i>
                            </button>
                          </div>

                          <div
                            className={`${
                              P_togler === true
                              ? "col-sm-6 text-right"
                              : "col-sm-3 text-right"
                            }`}
                          >
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بەروار
                              </label>
                              {P_togler === false ? (
                                <input
                                  type="text"
                                  name="P_barwar"
                                  value={
                                    P_search_wasl === true || P_save === true
                                      ? renderdate(P_barwar)
                                      : renderdate(Date.now())
                                  }
                                  className="form-control text-right font-weight-bold"
                                />
                              ) : (
                                <input
                                  type="datetime-local"
                                  name="P_barwar"
                                  value={P_barwar}
                                  onChange={AddProChange}
                                  className="form-control text-right font-weight-bold tc btn btn-info"
                                  style={{ margin: "0px 8px" }}
                                />
                              )}
                            </div>
                          </div>
                          {P_togler === false ? (
                            <div className="col-sm-3 text-right">
                              <div className="form-group">
                                <label className="font-weight-bold f4">
                                  کات
                                </label>
                                {P_search_wasl === true || P_save === true ? (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={P_barwar}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                ) : (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={Date.now()}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : null}

                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژمارە
                              </label>
                              <input
                                type="text"
                                name="P_zhmara"
                                disabled={P_del_dis === true ? true : null}
                                value={P_zhmara}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? getParaDataBySearchMoney(event)
                                    : null
                                }
                                onChange={AddProChange}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                            <div className=" text-right ">
                              <label className="form-check-label font-weight-bold f3">
                                معمیل
                              </label>
                            </div>
                            <hr />
                          </div>

                          {isMobile ? (
                            <>
                              {" "}
                              <div className="col-md-3 text-right">
                                <div className="form-group">
                                  <label className="font-weight-bold ">
                                    ژ.بەڕێز
                                  </label>
                                  <input
                                    type="text"
                                    name="P_zh_barez"
                                    onClick={setSearchMonyWakilEmpty}
                                    disabled={P_del_dis === true ? true : null}
                                    onChange={AddProChange}
                                    onKeyPress={(event) =>
                                      event.key === "Enter"
                                        ? getWakilDataBySearchMoney(event)
                                        : null
                                    }
                                    autoComplete="off"
                                    value={P_zh_barez === 0 ? "" : P_zh_barez}
                                    className="form-control text-right font-weight-bold"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6 text-right">
                                <div className="form-group3">
                                  <label className="font-weight-bold ">
                                    ناوی بەرێز
                                  </label>
                                  <input
                                    type="text"
                                    name="P_nawe_barez"
                                    disabled={P_del_dis === true ? true : null}
                                    onChange={P_Search_Rent_Handle}
                                    value={P_nawe_barez}
                                    className="form-control text-right font-weight-bold"
                                    autoComplete="off"
                                  />
                                  {P_suggestion.length !== 0 && (
                                    <>
                                      <ul
                                        style={{
                                          overflowY: "scroll",
                                          border: "1px solid white",
                                          height: "250px",
                                          scrollMarginBottom: "30px",
                                        }}
                                        className="list-group"
                                      >
                                        {P_suggestion.map((item, idx) => (
                                          <li
                                            className="list-group-item"
                                            key={idx}
                                            onClick={() =>
                                              P_suggestionSelected(item._id)
                                            }
                                          >
                                            {item.Name}
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {" "}
                                <div className="col-md-3 text-right mt4">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                    <div className="form-group ">
                                      <button
                                        disabled={
                                          P_del_dis === true ? true : null
                                        }
                                        onClick={setSearchMonyWakilEmpty}
                                        className="btn btn-dark"
                                      >
                                        <i className="fas fa-search-plus"></i>
                                      </button>
                                    </div>
                                  </div>
                                    <div className="col-md-4"></div>
                                </div>
                              </div>
                                <div className="col-sm-6 text-right">
                                <div className="form-group3">
                                  <label className="font-weight-bold ">
                                    ناوی بەرێز
                                  </label>
                                  <input
                                    type="text"
                                    name="P_nawe_barez"
                                    disabled={P_del_dis === true ? true : null}
                                    onChange={P_Search_Rent_Handle}
                                    value={P_nawe_barez}
                                    className="form-control text-right font-weight-bold"
                                    autoComplete="off"
                                  />
                                  {P_suggestion.length !== 0 && (
                                    <>
                                      <ul
                                        style={{
                                          overflowY: "scroll",
                                          border: "1px solid white",
                                          height: "250px",
                                          scrollMarginBottom: "30px",
                                        }}
                                        className="list-group"
                                      >
                                        {P_suggestion.map((item, idx) => (
                                          <li
                                            className="list-group-item"
                                            key={idx}
                                            onClick={() =>
                                              P_suggestionSelected(item._id)
                                            }
                                          >
                                            {item.Name}
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  )}
                                </div>
                              </div>
                                <div className="col-md-3 text-right">
                                <div className="form-group">
                                  <label className="font-weight-bold ">
                                    ژ.بەڕێز
                                  </label>
                                  <input
                                    type="text"
                                    name="P_zh_barez"
                                    onClick={setSearchMonyWakilEmpty}
                                    disabled={P_del_dis === true ? true : null}
                                    onChange={AddProChange}
                                    onKeyPress={(event) =>
                                      event.key === "Enter"
                                        ? getWakilDataBySearchMoney(event)
                                        : null
                                    }
                                    autoComplete="off"
                                    value={P_zh_barez === 0 ? "" : P_zh_barez}
                                    className="form-control text-right font-weight-bold"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                          <div className="col-md-4 text-right"></div>
                          <div className="col-sm-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ID قەرزی کۆن بە{" "}
                              </label>
                              <NumberFormat
                                value={P_qarzeKonID}
                                displayType={"text"}
                                className="form-control text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"ID"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                $ قەرزی کۆن بە{" "}
                              </label>
                              <NumberFormat
                                value={P_qarzeKonDolar}
                                displayType={"text"}
                                className="form-control text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 text-right">
                            <label className="font-weight-bold f4">
                              ناوی وەرگر
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                P_wargr === "‌‌‌‌‌‌حاجی ڕەقیب"
                                  ? "bg-success text-white"
                                  : `${
                                      P_wargr === "حاجی رەمەزان"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              disabled={P_del_dis === true ? true : null}
                              onChange={AddProChange}
                              name="P_wargr"
                              value={P_wargr !== null ? P_wargr : ""}
                            >
                              <option className="ml5">...ناوی وەرگر</option>
                              <option>‌‌‌‌‌‌حاجی ڕەقیب</option>
                              <option>حاجی رەمەزان</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right">
                            <label className="font-weight-bold f4">
                              جۆری دراو
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right  ${
                                P_joripara === "دۆلار"
                                  ? "bg-success text-white"
                                  : `${
                                      P_joripara === "دینار"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              disabled={P_del_dis === true ? true : null}
                              onChange={AddProChange}
                              name="P_joripara"
                              value={P_joripara !== null ? P_joripara : ""}
                            >
                              <option className="ml5">..جۆری دراو</option>
                              <option>دۆلار</option>
                              <option>دینار</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بڕی پارە
                              </label>
                              <NumberFormat
                                displayType="input"
                                thousandSeparator={true}
                                name="P_brePara"
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? toanotherInput(event)
                                    : null
                                }
                                onChange={AddProChange}
                                disabled={P_del_dis === true ? true : null}
                                value={P_brePara === 0 ? "" : P_brePara}
                                className="form-control text-right font-weight-bold bg-info text-white"
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-1 text-right"></div> */}
                          {isMobile ? (
                            <>
                              <div className="col-md-4 text-right">
                                <div className="form-group">
                                  <label className="font-weight-bold f4">
                                    داشکاندن
                                  </label>
                                  <input
                                    type="text"
                                    name="P_dashkan"
                                    onChange={AddProChange}
                                    disabled={P_del_dis === true ? true : null}
                                    value={P_dashkan}
                                    className="form-control text-right font-weight-bold"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4 text-right"></div>
                              <div className="col-md-4 text-right"></div>

                              <div className="col-sm-4 text-right">
                                <div className="form-group">
                                  <label className="font-weight-bold f4">
                                    پارە پاشی داشکاندن
                                  </label>
                                  <NumberFormat
                                    value={
                                      P_joripara === null
                                        ? 0
                                        : P_dashkan > 0
                                        ? P_kamkrdnawayNrx()
                                        : bripara()
                                    }
                                    disabled={P_del_dis === true ? true : null}
                                    displayType={"text"}
                                    className="form-control text-right font-weight-bold p-2"
                                    thousandSeparator={true}
                                    prefix={
                                      P_joripara === null
                                        ? ""
                                        : P_joripara === "دۆلار"
                                        ? "$"
                                        : "ID"
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-sm-8 mt4">
                                <button
                                  className="btn btn-dark p3 px-4"
                                  style={{
                                    backgroundColor: "#00695c",
                                    border: "1px solid #00695c",
                                  }}
                                  disabled={P_open === true ? true : null}
                                  onClick={pageminsone}
                                >
                                  <i className="fas fa-caret-left f4"></i>
                                </button>
                                <button
                                  className="btn btn-dark p3 px-4 ml3"
                                  style={{
                                    backgroundColor: "#00695c",
                                    border: "1px solid #00695c",
                                  }}
                                  disabled={P_open === true ? true : null}
                                  onClick={pageplusone}
                                >
                                  <i className="fas fa-caret-right f4"></i>
                                </button>
                                {P_search_wasl === true ? (
                                  <>
                                    {P_open === null ? (
                                      <button
                                        onClick={(e) =>
                                          P_openUpdate(
                                            P_zh_barez,
                                            e,
                                            P_wasl._id
                                          )
                                        }
                                        className="btn btn-md btn-warning px-4 ml3"
                                        style={{
                                          backgroundColor: "silver",
                                          border: "1px solid silver",
                                        }}
                                      >
                                        <i className="fas fa-lock"></i>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={(e) =>
                                          updtate_para(e, P_wasl, P_zh_barez)
                                        }
                                        className="btn btn-md btn-primary px-4 ml3"
                                        style={{ backgroundColor: "navy" }}
                                      >
                                        <i className="fas fa-edit "></i>
                                      </button>
                                    )}
                                    <Link to="/print-takemoney">
                                      <button
                                        onClick={P_getPrint}
                                        type="button"
                                        className="btn btn-md btn-primary px-4 ml3"
                                        style={{
                                          backgroundColor: "purple",
                                          border: "1px solid purple",
                                        }}
                                      >
                                        <i className="fas fa-print fa-lg"></i>
                                      </button>
                                    </Link>
                                  </>
                                ) : P_save === null ? (
                                  <>
                                    {P_loading ? (
                                      <button
                                        type="button"
                                        onDoubleClick={P_hideLoader}
                                        className="btn btn-md btn-success px-4 ml3"
                                      >
                                        <i class="fas fa-spinner animate__animated animate__rotateIn animate__infinite"></i>
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          P_showLoader(e);
                                          onSaveMoney(e);
                                        }}
                                        className="btn btn-md btn-success px-4 ml3"
                                      >
                                        <i className="far fa-save fa-lg"></i>
                                      </button>
                                    )}
                                  </>
                                ) : null}
                                <button
                                  type="button"
                                  onClick={createNewMony}
                                  disabled={P_open === true ? true : null}
                                  className="btn btn-md btn-primary px-4 ml3"
                                >
                                  <i className="far fa-file fa-lg"></i>
                                </button>
                                <Link to="/print-takemoney">
                                  {P_save === true ? (
                                    <>
                                      <button
                                        onClick={P_getPrint}
                                        type="button"
                                        className="btn btn-md btn-primary px-4 ml3"
                                        style={{
                                          backgroundColor: "purple",
                                          border: "1px solid purple",
                                        }}
                                      >
                                        <i className="fas fa-print fa-lg"></i>
                                      </button>
                                      {P_open === null ? (
                                        <button
                                          onClick={(e) =>
                                            P_openUpdate(
                                              P_zh_barez,
                                              e,
                                              P_wasl._id
                                            )
                                          }
                                          className="btn btn-md btn-warning px-4 ml3"
                                          style={{
                                            backgroundColor: "silver",
                                            border: "1px solid silver",
                                          }}
                                        >
                                          <i class="fas fa-lock"></i>
                                        </button>
                                      ) : (
                                        <button
                                          onClick={(e) =>
                                            updtate_para(e, P_wasl, P_zh_barez)
                                          }
                                          className="btn btn-md btn-primary px-4 ml3"
                                          style={{ backgroundColor: "navy" }}
                                        >
                                          <i className="fas fa-edit "></i>
                                        </button>
                                      )}
                                    </>
                                  ) : null}
                                </Link>
                              </div>
                            </>
                          ) : (
                            <>
                                <div className="col-sm-8 mt4">
                                <button
                                  className="btn btn-dark p3 px-4"
                                  style={{
                                    backgroundColor: "#00695c",
                                    border: "1px solid #00695c",
                                  }}
                                  disabled={P_open === true ? true : null}
                                  onClick={pageminsone}
                                >
                                  <i className="fas fa-caret-left f4"></i>
                                </button>
                                <button
                                  className="btn btn-dark p3 px-4 ml3"
                                  style={{
                                    backgroundColor: "#00695c",
                                    border: "1px solid #00695c",
                                  }}
                                  disabled={P_open === true ? true : null}
                                  onClick={pageplusone}
                                >
                                  <i className="fas fa-caret-right f4"></i>
                                </button>
                                {P_search_wasl === true ? (
                                  <>
                                    {P_open === null ? (
                                      <button
                                        onClick={(e) =>
                                          P_openUpdate(
                                            P_zh_barez,
                                            e,
                                            P_wasl._id
                                          )
                                        }
                                        className="btn btn-md btn-warning px-4 ml3"
                                        style={{
                                          backgroundColor: "silver",
                                          border: "1px solid silver",
                                        }}
                                      >
                                        <i className="fas fa-lock"></i>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={(e) =>
                                          updtate_para(e, P_wasl, P_zh_barez)
                                        }
                                        className="btn btn-md btn-primary px-4 ml3"
                                        style={{ backgroundColor: "navy" }}
                                      >
                                        <i className="fas fa-edit "></i>
                                      </button>
                                    )}
                                    <Link to="/print-takemoney">
                                      <button
                                        onClick={P_getPrint}
                                        type="button"
                                        className="btn btn-md btn-primary px-4 ml3"
                                        style={{
                                          backgroundColor: "purple",
                                          border: "1px solid purple",
                                        }}
                                      >
                                        <i className="fas fa-print fa-lg"></i>
                                      </button>
                                    </Link>
                                  </>
                                ) : P_save === null ? (
                                  <>
                                    {P_loading ? (
                                      <button
                                        type="button"
                                        onDoubleClick={P_hideLoader}
                                        className="btn btn-md btn-success px-4 ml3"
                                      >
                                        <i class="fas fa-spinner animate__animated animate__rotateIn animate__infinite"></i>
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          P_showLoader(e);
                                          onSaveMoney(e);
                                        }}
                                        className="btn btn-md btn-success px-4 ml3"
                                      >
                                        <i className="far fa-save fa-lg"></i>
                                      </button>
                                    )}
                                  </>
                                ) : null}
                                <button
                                  type="button"
                                  onClick={createNewMony}
                                  disabled={P_open === true ? true : null}
                                  className="btn btn-md btn-primary px-4 ml3"
                                >
                                  <i className="far fa-file fa-lg"></i>
                                </button>
                                <Link to="/print-takemoney">
                                  {P_save === true ? (
                                    <>
                                      <button
                                        onClick={P_getPrint}
                                        type="button"
                                        className="btn btn-md btn-primary px-4 ml3"
                                        style={{
                                          backgroundColor: "purple",
                                          border: "1px solid purple",
                                        }}
                                      >
                                        <i className="fas fa-print fa-lg"></i>
                                      </button>
                                      {P_open === null ? (
                                        <button
                                          onClick={(e) =>
                                            P_openUpdate(
                                              P_zh_barez,
                                              e,
                                              P_wasl._id
                                            )
                                          }
                                          className="btn btn-md btn-warning px-4 ml3"
                                          style={{
                                            backgroundColor: "silver",
                                            border: "1px solid silver",
                                          }}
                                        >
                                          <i class="fas fa-lock"></i>
                                        </button>
                                      ) : (
                                        <button
                                          onClick={(e) =>
                                            updtate_para(e, P_wasl, P_zh_barez)
                                          }
                                          className="btn btn-md btn-primary px-4 ml3"
                                          style={{ backgroundColor: "navy" }}
                                        >
                                          <i className="fas fa-edit "></i>
                                        </button>
                                      )}
                                    </>
                                  ) : null}
                                </Link>
                              </div>
                                <div className="col-md-4 text-right">
                                <div className="form-group">
                                  <label className="font-weight-bold f4">
                                    داشکاندن
                                  </label>
                                  <input
                                    type="text"
                                    name="P_dashkan"
                                    onChange={AddProChange}
                                    disabled={P_del_dis === true ? true : null}
                                    value={P_dashkan}
                                    className="form-control text-right font-weight-bold"
                                  />
                                </div>
                              </div>
                                <div className="col-sm-4 text-right"></div>
                                <div className="col-md-4 text-right"></div>

                                <div className="col-sm-4 text-right">
                                <div className="form-group">
                                  <label className="font-weight-bold f4">
                                    پارە پاشی داشکاندن
                                  </label>
                                  <NumberFormat
                                    value={
                                      P_joripara === null
                                        ? 0
                                        : P_dashkan > 0
                                        ? P_kamkrdnawayNrx()
                                        : bripara()
                                    }
                                    disabled={P_del_dis === true ? true : null}
                                    displayType={"text"}
                                    className="form-control text-right font-weight-bold p-2"
                                    thousandSeparator={true}
                                    prefix={
                                      P_joripara === null
                                        ? ""
                                        : P_joripara === "دۆلار"
                                        ? "$"
                                        : "ID"
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default FormTakeMoney;
