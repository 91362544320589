import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              d_dataRozhana,
              D_gotowaslrentsearch,
              D_gotowaslparasearch,
              D_gotowaslGaranawasearch,
            } = value;
            const raportyRozhana = d_dataRozhana.map((user, i) => {
              return (
                <tr key={i} className=" f4 fk">
                  <td
                    className={` fk ${
                      user.jorefroshtn === "فرۆشتنی ڕاستەوخۆ" ? "bg-danger" : ""
                    }`}
                    style={{ border: "2px solid #f0ad4e" }}
                  >
                    {user.zh_wakil}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {user.nawi_wakil}
                  </td>
                  <td
                    className="fk"
                    style={{ border: "2px solid #f0ad4e" }}
                    className="bg-danger text-white"
                  >
                    <NumberFormat
                      value={user.paraymawa}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    className="fk"
                    style={{ border: "2px solid #f0ad4e" }}
                    className="bg-success text-white"
                  >
                    <NumberFormat
                      value={user.paraydraw}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={user.kmkrdnawa}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    className="fk"
                    style={{ border: "2px solid #f0ad4e" }}
                    className={`${
                      user.joripara === "دۆلار" ? "dark-green" : "dark-red"
                    }`}
                  >
                    {user.joripara}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={user.koywasl}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    className="fk"
                    style={{
                      border: "2px solid #f0ad4e",
                      color: `${
                        user.jwlla === "گەڕانەوەی قەرز"
                          ? " #0000b3"
                          : user.jwlla === "گەڕانەوەی کاڵا"
                          ? " #cc6600"
                          : ""
                      }`,
                    }}
                  >
                    {user.jwlla}
                  </td>
                  <td style={{ border: "2px solid #f0ad4e" }}>
                    <Time
                      value={user.barwar}
                      format="YYYY-MM-DD"
                      style={{ color: "black" }}
                    />
                  </td>

                  <td
                    className="fk"
                    style={{ border: "2px solid #f0ad4e", cursor: "pointer" }}
                    onClick={(e) => {
                      user.jwlla === "فرۆشتن"
                        ? D_gotowaslrentsearch(e, user.code)
                        : user.jwlla === "گەڕانەوەی قەرز"
                        ? D_gotowaslparasearch(e, user.code)
                        : D_gotowaslGaranawasearch(e, user.code);
                    }}
                  >
                    <Link
                      to={`${
                        user.jwlla === "فرۆشتن"
                          ? "/rent"
                          : user.jwlla === "گەڕانەوەی قەرز"
                          ? "/take-money"
                          : "/back-koga"
                      }`}
                    >
                      {user.code}
                    </Link>
                  </td>
                </tr>
              );
            });

            return (
              <div class="tableFixHead">
                <table
                  id="example"
                  className="table table-bordered text-right table-hover "
                >
                  <thead>
                    <tr
                      className="text-warning f4"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>وکیل</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ناوی معمیل
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        پارەی ماوە{" "}
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        پارەی دراو
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>داشکان</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>دراو</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        کۆی پسوولە
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>جوولە</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>بەروار</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>پسولە</th>
                    </tr>
                  </thead>
                  <tbody className="table table-bordered text-right table-hover ">
                    {raportyRozhana}
                  </tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
