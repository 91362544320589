import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { multiply } from "lodash";

class FormPrintArz extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { HK_all3arzKala, checkedValues } = value;
            let array =
              checkedValues.length !== 0 ? checkedValues : HK_all3arzKala;
            const wakil = array.map((user, i) => {
              return (
                <>
                  <tr key={i} className="f4">
                    <td
                      className="f4 fk"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                      }}
                    >
                      <NumberFormat
                        value={Number(user.saleDolar3).toFixed(2)}
                        thousandSeparator={true}
                        displayType="text"
                      />
                    </td>

                    <td
                      className="f4 fk"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                      }}
                    >
                      {user.nameofproduct}
                    </td>
                    <td
                      className="f4 fk"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                      }}
                    >
                      {user.barcode}
                    </td>
                  </tr>
                </>
              );
            });

            return (
              <table id="example" className="table table-striped  text-right ">
                <thead>
                  <tr
                    className="text-black f4"
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <th
                      className="f4 fk"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                      }}
                    >
                      نرخ
                    </th>
                    <th
                      className="f4 fk"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                      }}
                    >
                      ناوەی کاڵا
                    </th>
                    <th
                      className="f4 fk"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                      }}
                    >
                      کۆد
                    </th>
                  </tr>
                </thead>
                <tbody>{wakil}</tbody>
              </table>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default FormPrintArz;
