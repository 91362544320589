import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import Footer from "./Footer";
import Body from "./Body";
import Header from "./Header";
import Navbar from "../../../../Navbar/Navbar";
const AllMob = (props) => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {} = value;
          return (
            <>
              <div>
                <Navbar />
                <div className="mt2">
                  <Header />
                </div>
                <div className="row">
                  <Body />
                </div>
                <div className="row" style={{ backgroundColor: "#2e3541 " }}>
                  <Footer />
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default AllMob;
