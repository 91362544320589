import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import Time from "react-time-format";
import swal from "sweetalert";
class FilldetailRemtMob extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              AddProChange,
              KM_karton,
              KM_search_wakil,
              KM_darzan,
              KM_naweSht,
              KM_rezha,
              KM_DarzanChange,
              KM_CartonChange,
              KM_tofirstInputINRent,
              KM_createNewPage,
              KM_mob,
              KM_nawe_barez,
              KM_zh_wasl,
              KM_zh_wasl_katy,
              KM_anotherInputWakil,
              KM_open,
              KM_getWaslDataBySearchRent,
              KM_getkoygahty,
              KM_getProductDataBySearchRent,
              KM_wasl,
              KM_search,
              KM_del_dis,
              KM_save,
              KM_togler,
              KM_saveDetail,
              KM_joripara,
              KM_wargr,
              KM_nrx_dolar,
              KM_nrx_dinarr,
              KM_nrx_dolar3,
              KM_nrx_dolar2,
              KM_rasidKarton,
              KM_search_wasl,
              KM_date,
              KM_qazanj,
              KM_kren,
              KM_nrxkaton,
              KM_setSearchEmptyRentWakil,
              KM_setSaletSearchEmty,
              Ap_kasakan,
              KM_zh_barez,
              toanotherInput,
              KM_inputtogler,
              KM_suggestions,
              KM_suggestionSelected,
              KM_Search_Koga_Handle,
              KM_dataTable,
            } = value;

            const wakilakan = Ap_kasakan.filter((user) => {
              return user.Name.includes(KM_nawe_barez);
            }).map((item, index) => {
              return <option key={index}>{item.Name}</option>;
            });
            return (
              <>
                <div style={{ marginTop: "3.1em" }}>
                  <form>
                    <div
                      className="row text-warning font-weight-bold "
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <div className="col-md-12 text-right">
                        <div className="row">
                          <div className="col-md-10">
                            <div className="form-group">
                              <label>:کەس</label>
                              <input
                                type="text"
                                value={KM_zh_barez > 0 ? KM_zh_barez : ""}
                                disabled={
                                  KM_search_wakil === true ||
                                  KM_del_dis === true
                                    ? true
                                    : null
                                }
                                name="KM_zh_barez"
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? KM_anotherInputWakil(event)
                                    : null
                                }
                                onChange={AddProChange}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>
                          <div className="col-md-1 mr-3">
                            <button
                              onClick={KM_setSearchEmptyRentWakil}
                              disabled={KM_del_dis === true ? true : null}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              className="btn btn-dark mt4"
                            >
                              <i
                                onClick={KM_setSearchEmptyRentWakil}
                                className="fas fa-search-plus"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 text-right">
                        <div className="form-group">
                          <label>:بەڕێز</label>
                          <input
                            type="text"
                            value={KM_nawe_barez}
                            name="KM_nawe_barez"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KM_anotherInputWakil(event)
                                : null
                            }
                            disabled={
                              KM_search_wakil === true || KM_del_dis === true
                                ? true
                                : null
                            }
                            onChange={KM_zh_barez < 0 ? "" : AddProChange}
                            className={`form-control text-right font-weight-bold `}
                          />
                        </div>
                      </div>
                      <div className="col-md-2 text-right">
                        <div className="form-group">
                          <label>:تەلەفۆن</label>
                          <input
                            type="number"
                            value={KM_mob}
                            disabled={
                              KM_search_wakil === true || KM_del_dis === true
                                ? true
                                : null
                            }
                            name="KM_mob"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KM_anotherInputWakil(event)
                                : null
                            }
                            onChange={AddProChange}
                            className={`form-control text-right font-weight-bold `}
                          />
                        </div>
                        <div className="col-md-2 text-right ">
                          <div className="form-group">
                            <label>:گەڕان</label>
                            <select
                              disabled={KM_del_dis === true ? true : null}
                              className={`form-control  text-right font-weight-bold selectpicker pull-right `}
                              onChange={AddProChange}
                              name="KM_nawe_barez"
                              value={KM_nawe_barez}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? KM_anotherInputWakil(event)
                                  : null
                              }
                            >
                              <option className="ml5">گەڕان</option>
                              {wakilakan}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 form-inline text-right">
                        <div className="col-md-7 ">
                          <label>:ناوی وەرگر</label>
                          <select
                            disabled={KM_del_dis === true ? true : null}
                            className={`form-control text-right font-weight-bold selectpicker pull-right ${
                              KM_wargr === "حاجی رەقیب"
                                ? "bg-success text-white"
                                : `${
                                    KM_wargr === "حاجی رەمەزان"
                                      ? "bg-danger text-white"
                                      : ""
                                  }`
                            }`}
                            onChange={AddProChange}
                            name="KM_wargr"
                            value={KM_wargr !== null ? KM_wargr : ""}
                          >
                            <option className="ml5">ناوی وەرگر</option>
                            <option>حاجی رەقیب</option>
                            <option>حاجی رەمەزان</option>
                          </select>
                        </div>
                        <div className=" col-md-5">
                          <label>:جۆری دراو</label>
                          <select
                            className={`form-control text-right font-weight-bold selectpicker pull-right ${
                              KM_joripara === "دۆلار"
                                ? "bg-success text-white"
                                : `${
                                    KM_joripara === "دینار"
                                      ? "bg-danger text-white"
                                      : ""
                                  }`
                            }`}
                            onChange={AddProChange}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KM_anotherInputWakil(event)
                                : null
                            }
                            name="KM_joripara"
                            value={KM_joripara !== null ? KM_joripara : ""}
                          >
                            <option
                              className="ml5"
                              disabled={KM_dataTable.length !== 0 ? true : null}
                            >
                              جۆری دراو
                            </option>
                            <option
                              disabled={KM_dataTable.length !== 0 ? true : null}
                            >
                              دۆلار
                            </option>
                            <option
                              disabled={KM_dataTable.length !== 0 ? true : null}
                            >
                              دینار
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row text-roght">
                    <div className="col-md-12 mt3">
                      <div className="row">
                        <div className="col-md-2">
                          <button
                            onClick={KM_inputtogler}
                            disabled={KM_del_dis === true ? true : null}
                            className="btn btn-info"
                          >
                            <i className="far fa-calendar-alt"></i>
                          </button>
                        </div>
                        <div
                          className={`${
                            KM_togler === true ? "col-md-10" : "col-md-7"
                          } pt2  tc`}
                        >
                          {KM_togler === false ? (
                            <>
                              {KM_search_wasl === true || KM_save === true ? (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={KM_wasl.date}
                                    format="YYYY-MM-DD"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              ) : (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={Date.now()}
                                    format="YYYY-MM-DD"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              )}
                            </>
                          ) : (
                            <input
                              type="datetime-local"
                              name="KM_date"
                              value={KM_date}
                              onChange={AddProChange}
                              className="form-control text-right font-weight-bold tc btn btn-info"
                              style={{ margin: "0px 8px" }}
                            />
                          )}
                        </div>
                        {KM_togler === true ? null : (
                          <div className="col-md-3">
                            <label className="text-warning font-weight-bold ">
                              :بەروار{" "}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-right">
                      <div className="row">
                        <div className="col-md-3 pt2  tc"></div>
                        <div className="col-md-6 pt2  tc">
                          {KM_togler === true ? null : (
                            <>
                              {KM_search_wasl === true ? (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={KM_wasl.date}
                                    format="hh:mm:ss"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              ) : (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={Date.now()}
                                    format="hh:mm:ss"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-md-1">
                          <label className="text-warning font-weight-bold ">
                            :کات{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <div className="row">
                        <div className="col-md-3 pt2  tc">
                          {" "}
                          <h4 className="font-weight-bold  bg-success text-white ">
                            {KM_zh_wasl_katy}
                          </h4>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="number"
                            onChange={AddProChange}
                            disabled={
                              KM_open === true || KM_dataTable.length !== 0
                                ? true
                                : null
                            }
                            value={KM_zh_wasl}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KM_getWaslDataBySearchRent(event)
                                : null
                            }
                            name="KM_zh_wasl"
                            className="form-control text-right font-weight-bold px-0 bg-danger text-white"
                          />
                        </div>
                        <div className="col-md-2">
                          <label className="text-warning font-weight-bold ">
                            :پسوولە{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ border: "1px solid #f0ad4e" }} />
                <form>
                  <div
                    className="row text-warning font-weight-bold"
                    style={{ backgroundColor: "#2e3541 " }}
                  >
                    {" "}
                    <div className="col-md-6 text-right">
                      <div className="form-group">
                        <label>ناوی شت</label>
                        <input
                          type="text"
                          disabled={KM_del_dis === true ? true : null}
                          value={KM_naweSht}
                          name="KM_naweSht"
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          className="form-control text-right font-weight-bold bg-info text-white"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 text-right px-2">
                      <div className="form-group">
                        <label>کۆد\ kd</label>
                        <input
                          type="text"
                          onClick={KM_setSaletSearchEmty}
                          disabled={KM_del_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? KM_getProductDataBySearchRent(event)
                              : null
                          }
                          value={KM_zh_barez}
                          name="KM_zh_barez"
                          onChange={AddProChange}
                          className="form-control text-right font-weight-bold tc pr-4"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 text-right px-2">
                      <div className="form-group">
                        <label>نرخ</label>
                        <input
                          type="text"
                          disabled={KM_del_dis === true ? true : null}
                          value={KM_kren}
                          name="KM_kren"
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 text-right px-2">
                      <div className="form-group">
                        <label>دانە</label>
                        {/*  */}
                        <input
                          type="text"
                          disabled={KM_del_dis === true ? true : null}
                          onClick={KM_DarzanChange}
                          // onKeyPress={KM_CartonDarzanSfr}
                          onChange={(e) => KM_DarzanChange(e)}
                          value={KM_darzan === 0 ? "" : KM_darzan}
                          name="KM_darzan"
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-md-6 text-right px-2 mt4">
                      <button
                        onClick={(e) => {
                          KM_createNewPage(e);
                        }}
                        disabled={
                          KM_dataTable.length !== 0 || KM_open === true
                            ? true
                            : null
                        }
                        className={`btn ${
                          KM_open === true || KM_dataTable.length !== 0
                            ? "btn-danger"
                            : "btn-primary"
                        } mr2`}
                      >
                        <i className="far fa-file fa-lg"></i>
                      </button>
                      <button
                        onClick={(e) => KM_saveDetail(e, KM_wasl)}
                        className="btn btn-success"
                      >
                        <i className="far fa-save"></i>
                      </button>
                    </div>
                    <div className="col-md-6 text-right ">
                      <div className="form-group ">
                        {KM_joripara === "دۆلار" ? (
                          <>
                            <label>$ کۆی گشتی </label>
                            <input
                              type="number"
                              disabled={KM_del_dis === true ? true : null}
                              value={
                                KM_karton > 0 || KM_darzan > 0
                                  ? KM_getkoygahty(KM_darzan, KM_karton)
                                  : 0
                              }
                              // onKeyPress={(event) =>
                              //   event.key === "Enter"
                              //     ? saveDetail(event, KM_wasl)
                              //     : null
                              // }
                              name="KM_koygshty$"
                              className="form-control text-right font-weight-bold tc"
                            />
                          </>
                        ) : (
                          <>
                            <label>ID کۆی گشتی </label>
                            <input
                              type="number"
                              disabled={KM_del_dis === true ? true : null}
                              value={
                                KM_karton > 0 || KM_darzan > 0
                                  ? KM_getkoygahty(KM_darzan, KM_karton)
                                  : 0
                              }
                              name="KM_koygshtyID"
                              className="form-control text-right font-weight-bold tc"
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}

export default FilldetailRemtMob;
