import React from 'react'
import './../RaportyRozhana/NavbarRozhana.css'
import {ProductConsumer} from '../../../Context/AddProWakel'
import { Link } from "react-router-dom";
const NavbarOfRaportWakel = () => {
  return (
    <ProductConsumer>
    {value=>{
        const {RW_rozhanafroshtn,RW_rozhanaparawagrtn,RW_jwllakan,RW_hamw,RW_froshtn,RW_parawargrtn,RW_wakilGaranawa,RW_garanawa,getPrint} = value
  
      return ( 
        <div>

<div className="sidebar text-warning  font-weight-bold f4" style={{backgroundColor:"#2C3539"}} >
      <center>
        <h4 className="text-white pr2">جووڵەی ڕۆژانە</h4>
      </center>
      <td style={{backgroundColor:`${RW_hamw===true?"#63120f":''}`}}><span className={`font-weight-bold  pointer`} onClick={RW_jwllakan} style={{paddingLeft:"50px"}} >هەموو جووڵەکان</span></td>
      <td style={{backgroundColor:`${RW_froshtn===true?"#63120f":''}`}}><span className={`font-weight-bold  pointer`}  onClick={RW_rozhanafroshtn} style={{paddingLeft:"80px"}} > فرۆشتن</span></td>
      <td style={{backgroundColor:`${RW_parawargrtn===true?"#63120f":''}`}}><span className={`font-weight-bold  pointer`}  onClick={RW_rozhanaparawagrtn} style={{paddingLeft:"65px"}} >پارەوەرگرتن</span></td>
      <td style={{backgroundColor:`${RW_garanawa===true?"#63120f":''}`}}><span className={`font-weight-bold  pointer`} style={{paddingLeft:"50px"}} onClick={RW_wakilGaranawa} >گەڕانەوە لە معمیل</span></td>
      <Link to="/print-raportywakel">
      <td onClick={getPrint}><span className={`font-weight-bold  pointer text-warning`} style={{paddingLeft:"80px"}}  >چاپکردن</span></td>
      </Link>
    </div>



                           
        </div>
 )

}}
    
</ProductConsumer>
                

)
}
export default NavbarOfRaportWakel
