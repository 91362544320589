import React from 'react'
import Navbar from '../Navbar'
import PutUser from './PutUser'
import FillAddUser from './FillAddUser'

const AddUser = () => {
    return (
        <>

        <Navbar/>
        <div className='mt6'></div>
        <div className="row">
          <div className="col-md-1"></div>
          <FillAddUser/>

          {/* <div className='col-md-1'></div> */}
          <div className='col-md-12 col-md-12 col-lg-6'>
              <PutUser/>
            </div>
          </div>
        </>
    )
}

export default AddUser
