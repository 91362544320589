import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const Footer = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { RT_para$, RT_paraID, RT_koyparadanID, RT_koyparadan$ } =
            value;
          return (
            <>
              <br />
              <div className="row mb4">
                <div className="col-md-12 ">
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="text-warning tc">کۆی پارەدان</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          value={Number(RT_koyparadan$).toFixed(2)}
                          className="  font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={Number(RT_koyparadanID).toFixed(2)}
                          prefix="ID"
                        />
                      </h6>
                    </div>

                    {/* <div className="col-md-6">
                      <h6 className="text-warning">مافی کۆمپانا</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="  font-weight-bold  "
                          thousandSeparator={true}
                          value={Number(RT_koyparadarhinan$).toFixed(2)}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={Number(RT_koyparadarhinanID).toFixed(2)}
                          prefix="ID"
                        />
                      </h6>
                    </div> */}

                    <div className="col-md-6">
                      <h6 className="text-warning tc">
                        {" "}
                        {RT_para$ >= 0 && RT_paraID >= 0
                          ? "قەرزی ئێمە"
                          : "قەرزی کۆمپانیا"}
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="  font-weight-bold  "
                          thousandSeparator={true}
                          value={Number(RT_para$).toFixed(2)}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={Number(RT_paraID).toFixed(2)}
                          prefix="ID"
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default Footer;
