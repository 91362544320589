import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import Time from "react-time-format";

const DetailOfKren = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            KM_mob,
            KM_nawe_barez,
            KM_zh_barez,
            KM_zh_wasl,
            KM_joripara,
            KM_wargr,
            KM_wasl,
            KM_search_wasl,
          } = value;
          return (
            <>
              <form
                className="border border-black "
                style={{ borderTop: "1px solid black" }}
              >
                <div className="detail">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-4">
                      <h3> پسوولەی کڕینی کاڵا </h3>
                    </div>
                    <div className="col-4"></div>
                  </div>
                  <div className="row text-dark font-weight-bold f4 mt-2 border border-black">
                    <div className="col-3 text-right form-inline mt-2">
                      {KM_search_wasl === true ? (
                        <p>
                          <Time
                            value={KM_wasl.date}
                            format="YYYY-MM-DD"
                            style={{ color: "black" }}
                          />
                        </p>
                      ) : (
                        <p>
                          <Time
                            value={Date.now()}
                            format="YYYY-MM-DD"
                            style={{ color: "black" }}
                          />
                        </p>
                      )}
                    </div>
                    <div className="col-3 text-right form-inline">
                      <div className="form-group">
                        <h5 className=" f4 mt-2" style={{ color: "black" }}>
                          {KM_mob}
                        </h5>
                        <label
                          className="font-weight-bold f4 ml3"
                          style={{ color: "black" }}
                        >
                          :تەلەفۆن
                        </label>
                      </div>
                    </div>

                    <div className="col-3 text-right  form-inline ">
                      <div className="form-group">
                        <h5 className=" ml5 f4 mt-2" style={{ color: "black" }}>
                          {KM_nawe_barez}
                        </h5>
                        <label
                          className="font-weight-bold f4 ml1"
                          style={{ color: "black" }}
                        >
                          :بەڕێز
                        </label>
                      </div>
                    </div>

                    <div className="col-3 text-right form-inline">
                      <div className="form-group ">
                        <h5 className=" ml5 f4 mt-2" style={{ color: "black" }}>
                          {KM_zh_barez}
                        </h5>
                        <label
                          className="font-weight-bold f4 ml3"
                          style={{ color: "black" }}
                        >
                          :بەڕێز ژمارە
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* row 2 */}
                <hr style={{ border: "0.3px solid black" }} />
                <div className="row text-dark font-weight-bold f4  mr-4 border border-black">
                  <div className="col-3 text-right form-inline mb-2">
                    {KM_search_wasl === true ? (
                      <p>
                        <Time
                          value={KM_wasl.date}
                          format="hh:mm:ss"
                          style={{ color: "black" }}
                        />
                      </p>
                    ) : (
                      <p>
                        <Time
                          value={Date.now()}
                          format="hh:mm:ss"
                          style={{ color: "black" }}
                        />
                      </p>
                    )}
                  </div>
                  <div className="col-3 text-right form-inline ">
                    <div className="form-group ml3">
                      <h5 className=" f4" style={{ color: "black" }}>
                        {KM_wargr === "‌‌‌‌‌‌حاجی ڕەقیب" ? "‌‌‌‌‌‌حاجی ڕەقیب" : KM_wargr === "حاجی رەمەزان" ? "حاجی سعید" : ""}
                      </h5>
                      <label
                        className="font-weight-bold mb-2 f4 ml3"
                        style={{ color: "black" }}
                      >
                        : ناوی وەرگر
                      </label>
                    </div>
                  </div>

                  <div className="col-3 text-right  form-inline">
                    <div className="form-group ">
                      <h5 className=" ml5 f4" style={{ color: "black" }}>
                        {KM_joripara}
                      </h5>
                      <label
                        className="font-weight-bold mb-2 f4 ml1 "
                        style={{ color: "black" }}
                      >
                        : جۆری دراو
                      </label>
                    </div>
                  </div>
                  <div className="col-3 text-right form-inline">
                    <div className="form-group ">
                      <h5 className=" ml5 f4" style={{ color: "black" }}>
                        {KM_zh_wasl}
                      </h5>
                      <label
                        className="font-weight-bold mb-2 f4 ml3"
                        style={{ color: "black" }}
                      >
                        : ژمارەی پسوولە
                      </label>
                    </div>
                  </div>
                </div>

                <div></div>
              </form>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default DetailOfKren;
