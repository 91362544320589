import React from "react";
import KasKashf7esab from "./KasKashf7esab";
import rahel from "./../../../img/rahel1.jpg";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
const InputKashfKas = () => {
 return (
  <>
   <ProductConsumer>
    {(value) => {
     const { KT_from, KT_to, KT_zhmaraybarez, KT_nawe_barez ,KT_Koy_Dolar,KT_Koy_Dinar} = value;
     return (
      <>
       <img src={rahel} alt="rahel" />
       <div className="row mb2">
                             <div className="col-4 text-right form-inline "></div>
                             <div className="col-4 text-right form-inline ">
         <div className="form-group ">
          <label className="font-weight-bold f3 ml5">ڕاپۆرتی کەشفی حیسابی کەس</label>
         </div>
        </div>
                             <div className="col-4 text-right form-inline"></div>
       </div>

       <div
        className="row text-dark font-weight-bold f3 mx-4"
        style={{ border: "2px solid black" }}
       >
                             <div className="col-3 text-right  form-inline ">
         <div className="form-group">
          <h5 className="font-weight-bold mt1 ml5 ">{KT_to}</h5>
          <label className="font-weight-bold mb-2 ml1">: بۆ بەرواری</label>
         </div>
        </div>

                             <div className="col-3 text-right  form-inline ">
         <div className="form-group">
          <h5 className="font-weight-bold mt1 ml5  ">{KT_from}</h5>
          <label className="font-weight-bold mb-2  ml1">: لە بەرواری </label>
         </div>
        </div>
                             {/* <div className="col-1 text-right  form-inline"></div> */}

                             <div className="col-3 text-right  form-inline ">
         <div className="form-group ">
          <h6 className=" mt2 ml5  fk">({KT_nawe_barez})</h6>
          <label className=" f4 fk ">:بەڕێز</label>
         </div>
        </div>

                             <div className="col-3 text-right form-inline">
         <div className="form-group ml6">
          <h6 className="mt2  fk">{KT_zhmaraybarez}</h6>
          <label className="font-weight-bold f4 ml2 fk">:ژمارە</label>
         </div>
        </div>
       </div>

       <KasKashf7esab />
       {/* first row */}
       <div className="row">
                 <div className="col-12">
         <div className="row ">
                         <div className="col-3">
           <h3 className="bg-white tc mt-2">
            <NumberFormat
             value={Number(KT_Koy_Dolar).toFixed(2)}
             className=" font-weight-bold  tc "
             displayType={"text"}
             prefix={"$"}
             thousandSeparator={true}
            />
           </h3>
          </div>
                         <div className="col-3">
           <h3 className=" tc  mt-2 ">ڕەسید بە دۆلار</h3>
          </div>

                         <div className="col-3 ">
           <h3 className="bg-white tc mt-2">
            <NumberFormat
         value={Number(KT_Koy_Dinar).toFixed(2)}
             className=" font-weight-bold  tc "
             displayType={"text"}
             prefix={"ID"}
             thousandSeparator={true}
            />
           </h3>
          </div>
                         <div className="col-3">
           <h3 className=" tc  mt-2">ڕەسید بە دینار</h3>
          </div>
         </div>
        </div>
       </div>
      </>
     );
    }}
   </ProductConsumer>
  </>
 );
};

export default InputKashfKas;
