import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";

const FooterSummery = () => {
    return (
        <>
            <ProductConsumer>
                {(value) => {
                    const { PS_koyrasidkarton, PS_koyrasidparakan } = value;
                    return (
                        <>
                            {/* second row */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row ">
                                        <div className="col-lg-2 col-md-6"></div>
                                        <div className="col-lg-4 col-md-6 py-3"></div>

                                        <div className="col-lg-2 col-md-6"></div>
                                        <div className="col-lg-4 col-md-6 py-3"></div>
                                    </div>
                                </div>
                            </div>

                            {/* third row */}
                            <div className="row">
                                <div className="col-lg10 col-md-12"></div>
                            </div>
                        </>
                    );
                }}
            </ProductConsumer>
        </>
    );
};

export default FooterSummery;
