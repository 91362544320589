import React from 'react'
import './../RaportyRozhana/NavbarRozhana.css'
import {ProductConsumer} from '../../../Context/AddProWakel'
import { Link } from 'react-router-dom'
const NavbarRozhana = () => {
  return (
    <ProductConsumer>
    {value=>{
        const {D_rozhanafroshtn,D_rozhanaparawagrtn,D_jwllakan,d_hamw,d_froshtn,d_parawargrtn,D_rozhanaGaranawa,d_garanawa,getPrint} = value
  
      return ( 
        <table>

    <tbody className="sidebar text-warning  font-weight-bold f4 " style={{backgroundColor:"#2C3539"}}>
      <center>
        <h4 className="text-danger pr2">جووڵەی ڕۆژانە</h4>
      </center>
      <td style={{backgroundColor:`${d_hamw===true?"#63120f":''}`}}><span className={`font-weight-bold  pointer`} onClick={D_jwllakan} style={{paddingLeft:"50px"}} >هەموو جووڵەکان</span></td>
      <td style={{backgroundColor:`${d_froshtn===true?"#63120f":''}`}}><span className={`font-weight-bold  pointer`}  onClick={D_rozhanafroshtn} style={{paddingLeft:"80px"}} > فرۆشتن</span></td>
      <td style={{backgroundColor:`${d_parawargrtn===true?"#63120f":''}`}}><span className={`font-weight-bold  pointer`}  onClick={D_rozhanaparawagrtn} style={{paddingLeft:"65px"}} >پارەوەرگرتن</span></td>
      <td style={{backgroundColor:`${d_garanawa===true?"#63120f":''}`}}><span className={`font-weight-bold  pointer`} onClick={D_rozhanaGaranawa} style={{paddingLeft:"50px"}} >گەڕانەوە لە معمیل</span></td>
     <Link to="/print-raportyRozhana">
      <td ><span className={`font-weight-bold  pointer text-warning`} onClick={getPrint} style={{paddingLeft:"80px"}} >چاپکردن</span></td>
    </Link>
    </tbody>



                           
        </table>
 )

}}
    
</ProductConsumer>
                

)
}

export default NavbarRozhana
