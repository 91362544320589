import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import _ from "lodash";
const FooterOfKalaRaport = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            K_rasedekartonfroshtn,
            K_rasedeKarton,
            K_rasedekartonKren,
            K_rasedekartongarnawa,
            K_numberOfDarzan,
            K_rasedeKartonBroken,
          } = value;
          return (
            <>
              <br />
              <div className="row ">
                <div className="col-lg-12 col-md-12">
                  <div className="row ">
                    <div className="col-lg-2 col-md-3">
                      <h3 className="bg-white tc">{K_rasedekartonfroshtn}</h3>
                    </div>
                    <div className="col-lg-1 col-md-3">
                      <h3 className="text-warning">فرۆشتن</h3>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <h3 className="bg-white tc">{K_rasedekartonKren}</h3>
                    </div>
                    <div className="col-lg-1 col-md-3">
                      <h3 className="text-warning">کڕین</h3>
                    </div>
                    {/* kalay garawa */}
                    <div className="col-lg-2 col-md-3">
                      <h3 className="bg-white tc">{K_rasedekartongarnawa}</h3>
                    </div>
                    <div className="col-lg-1 col-md-3">
                      <h3 className="text-warning"> گەڕاوە</h3>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <h3 className="bg-white tc">{K_rasedeKartonBroken}</h3>
                    </div>
                    <div className="col-lg-1 col-md-3">
                      <h3 className="text-warning">عاتڵ </h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* second row */}
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="row ">
                    <div className="col-lg-2 col-md-4">
                      <h3 className="bg-white text-success tc mt2">
                        {Math.round(K_rasedeKarton)}
                      </h3>
                    </div>
                    <div className="col-lg-2 tc pt-2 col-md-4 bg-white">
                      <h3
                        className="text-black  font-weight-bold "
                        style={{ fontSize: "20px" }}
                      >
                        ڕەسیدی مەتر|دانە
                      </h3>
                    </div>

                    <div className="col-lg-2 col-md-4">
                      <h3 className="bg-white text-success tc mt2">
                        {Number(
                          _.divide(K_rasedeKarton, K_numberOfDarzan)
                        ).toFixed(2)}
                      </h3>
                    </div>
                    <div className="col-lg-2 tc pt-2 col-md-4 bg-white">
                      <h3
                        className="text-black  font-weight-bold "
                        style={{ fontSize: "20px" }}
                      >
                        ڕەسیدی لەفە|کارتۆن
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              {/* third row */}
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfKalaRaport;
