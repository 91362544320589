import React from "react";
import SearchParawargrtn from "./SearchParawargrtn";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Time from "react-time-format";

const FormParawargrtn = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            PW_zhamara,
            PW_joredraw,
            PW_openUpdate,
            PW_search_wasl,
            PW_barwar,
            PW_del_dis,
            PW_parayesta$,
            PW_parayestaID,
            PW_onSaveMoney,
            PW_getParaDataBySearchMoney,
            role,
            PW_show,
            PW_showrasid,
            PW_brepara,
            PW_getPrint,
            PW_createNewMony,
            PW_toggler,
            toanotherInput,
            PW_nawikas,
            PW_save,
            PW_nawewargr,
            PW_open,
            L_user,
            PW_wasl,
            PW_updtate_para,
            PW_pageminsone,
            PW_pageplusone,
            PW_inputtogler,
          } = value;
          const renderdate = (kat) => {
            const monthname = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ];
            const date = new Date(kat);

            return `${date.getDate()}/${
              monthname[date.getMonth()]
            }/${date.getFullYear()}`;
          };
          return (
            <>
              <div className="row ">
                <div className="col-md-1"></div>
                <div className="col-md-12 col-lg-4 ">
                  {/* Search take money */}
                  <SearchParawargrtn />
                </div>
                <div className="col-md-1"></div>
                {/* Form take moneyy */}
                <div className="col-lg-6 text-warning col-md-12 ">
                  <div
                    className="card card-warning "
                    style={{ backgroundColor: "#2e3541 " }}
                  >
                    <div
                      className="card-header p-1 border-bottom border-warning"
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <h2 className="card-title tc">پارە گەڕاندنەوە</h2>
                    </div>

                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-3 text-right mt2">
                            <button
                              disabled={PW_del_dis === true ? true : null}
                              onClick={PW_inputtogler}
                              className="btn btn-info mt4"
                            >
                              <i className="far fa-calendar-alt"></i>
                            </button>
                          </div>

                          <div
                            className={`${
                              PW_toggler === true
                              ? "col-sm-6 text-right"
                              : "col-sm-3 text-right"
                            }`}
                          >
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بەروار
                              </label>
                              {PW_toggler === false ? (
                                <input
                                  type="text"
                                  name="PW_barwar"
                                  value={
                                    PW_search_wasl === true || PW_save === true
                                      ? renderdate(PW_barwar)
                                      : renderdate(Date.now())
                                  }
                                  className="form-control text-right font-weight-bold"
                                />
                              ) : (
                                <input
                                  type="datetime-local"
                                  name="PW_barwar"
                                  value={PW_barwar}
                                  onChange={AddProChange}
                                  className="form-control text-right font-weight-bold tc btn btn-info"
                                  style={{ margin: "0px 8px" }}
                                />
                              )}
                            </div>
                          </div>
                          {PW_toggler === false ? (
                            <div className="col-sm-3 text-right">
                              <div className="form-group">
                                <label className="font-weight-bold f4">
                                  کات
                                </label>
                                {PW_search_wasl === true || PW_save === true ? (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={PW_barwar}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                ) : (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={Date.now()}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : null}

                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژمارە
                              </label>
                              <input
                                type="text"
                                name="PW_zhamara"
                                disabled={PW_del_dis === true ? true : null}
                                value={PW_zhamara}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? PW_getParaDataBySearchMoney(event)
                                    : null
                                }
                                onChange={AddProChange}
                                className={`form-control text-right font-weight-bold ${
                                  PW_search_wasl === true || PW_save === true
                                    ? "bg-danger text-white"
                                    : ""
                                }`}
                              />
                            </div>

                            <hr />
                          </div>

                          <div className="col-md-2 text-right"></div>
                          <div className="col-md-1 text-right mt-5">
                            {role === "admin" ? (
                              PW_show === false ? (
                                <button
                                  onClick={(e) => PW_showrasid(e, L_user._id)}
                                  className="btn btn-primary"
                                  style={{ backgroundColor: "orange" }}
                                >
                                  <i className="fas fa-eye"></i>
                                </button>
                              ) : (
                                <button
                                  onClick={PW_showrasid}
                                  className="btn btn-primary"
                                  style={{ backgroundColor: "orange" }}
                                >
                                  <i className="fas fa-eye-slash"></i>
                                </button>
                              )
                            ) : null}
                          </div>
                          <div className="col-md-1 text-right"></div>

                          <div className="col-sm-4 text-right  p-2 border-bottom border-warning">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ID ڕەسیدی ئێستا بە{" "}
                              </label>
                              {PW_show === false ? (
                                <NumberFormat
                                  value={"0"}
                                  displayType={"text"}
                                  className="form-control  text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-danger"
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              ) : (
                                <NumberFormat
                                  value={PW_parayestaID}
                                  displayType={"text"}
                                  className="form-control  text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-danger"
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              )}
                            </div>
                          </div>

                          <div className="col-md-4 text-right p-2 border-bottom border-warning">
                            <div className="form-group">
                              <label className="font-weight-bold   ">
                                $ ڕەسیدی ئێستا بە{" "}
                              </label>
                              {PW_show === false ? (
                                <NumberFormat
                                  value={"0"}
                                  displayType={"text"}
                                  className="form-control  text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-success"
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              ) : (
                                <NumberFormat
                                  value={PW_parayesta$}
                                  displayType={"text"}
                                  className="form-control  text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-success"
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              )}
                            </div>
                          </div>

                          <div className="col-sm-7 text-right"></div>

                          <div className="col-sm-5 text-right mt-3">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ناوی کەسی پارە گەڕاندنەوە{" "}
                              </label>
                              <input
                                type="text"
                                name="PW_nawikas"
                                onChange={AddProChange}
                                disabled={PW_del_dis === true ? true : null}
                                value={PW_nawikas}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 text-right ">
                            <label className="font-weight-bold f4">
                              ناوی وەرگر
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                PW_nawewargr === "هاوکار"
                                  ? "bg-success text-white"
                                  : `${
                                      PW_nawewargr === "کارمەند"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              disabled={PW_del_dis === true ? true : null}
                              onChange={AddProChange}
                              name="PW_nawewargr"
                              value={PW_nawewargr !== null ? PW_nawewargr : ""}
                            >
                              <option className="ml5">...ناوی وەرگر</option>
                              <option>هاوکار</option>
                              <option>کارمەند</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right ">
                            <label className="font-weight-bold f4">
                              جۆری دراو
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                PW_joredraw === "دۆلار"
                                  ? "bg-success text-white"
                                  : `${
                                      PW_joredraw === "دینار"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              disabled={PW_del_dis === true ? true : null}
                              onChange={AddProChange}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              name="PW_joredraw"
                              value={PW_joredraw !== null ? PW_joredraw : ""}
                            >
                              <option className="ml5">..جۆری دراو</option>
                              <option>دۆلار</option>
                              <option>دینار</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right ">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بڕی پارە
                              </label>
                              <NumberFormat
                                displayType="input"
                                thousandSeparator={true}
                                name="PW_brepara"
                                onChange={AddProChange}
                                disabled={PW_del_dis === true ? true : null}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? toanotherInput(event)
                                    : null
                                }
                                value={PW_brepara === 0 ? "" : PW_brepara}
                                className="form-control text-right font-weight-bold bg-info text-white"
                              />
                            </div>
                          </div>

                          {/* <div className="col-md-1 text-right"></div> */}
                          <div className="col-sm-8 mt4">
                            <button
                              className="btn btn-dark p3 px-4"
                              style={{
                                backgroundColor: "#00695c",
                                border: "1px solid #00695c",
                              }}
                              disabled={PW_open === true ? true : null}
                              onClick={PW_pageminsone}
                            >
                              <i className="fas fa-caret-left f4"></i>
                            </button>
                            <button
                              className="btn btn-dark p3 px-4 ml3"
                              style={{
                                backgroundColor: "#00695c",
                                border: "1px solid #00695c",
                              }}
                              disabled={PW_open === true ? true : null}
                              onClick={PW_pageplusone}
                            >
                              <i className="fas fa-caret-right f4"></i>
                            </button>
                            {PW_search_wasl === true ? (
                              <>
                                {PW_open === null ? (
                                  <button
                                    onClick={(e) =>
                                      PW_openUpdate(L_user._id, e)
                                    }
                                    className="btn btn-md btn-warning px-4 ml3"
                                    style={{
                                      backgroundColor: "silver",
                                      border: "1px solid silver",
                                    }}
                                  >
                                    <i className="fas fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    onClick={(e) =>
                                      PW_updtate_para(
                                        e,
                                        PW_wasl,
                                        PW_wasl.wakilcode
                                      )
                                    }
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{ backgroundColor: "navy" }}
                                  >
                                    <i className="fas fa-edit "></i>
                                  </button>
                                )}
                                <Link to="/print-parawargrtn">
                                  <button
                                    onClick={PW_getPrint}
                                    type="button"
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{
                                      backgroundColor: "purple",
                                      border: "1px solid purple",
                                    }}
                                  >
                                    <i className="fas fa-print fa-lg"></i>
                                  </button>
                                </Link>
                              </>
                            ) : PW_save === null ? (
                              <button
                                type="button"
                                onClick={PW_onSaveMoney}
                                className="btn btn-md btn-success px-4 ml3"
                              >
                                <i className="far fa-save fa-lg"></i>
                              </button>
                            ) : null}
                            <button
                              type="button"
                              onClick={PW_createNewMony}
                              disabled={PW_open === true ? true : null}
                              className="btn btn-md btn-primary px-4 ml3"
                            >
                              <i className="far fa-file fa-lg"></i>
                            </button>
                            <Link to="/print-parawargrtn">
                              {PW_save === true ? (
                                <>
                                  <button
                                    onClick={PW_getPrint}
                                    type="button"
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{
                                      backgroundColor: "purple",
                                      border: "1px solid purple",
                                    }}
                                  >
                                    <i className="fas fa-print fa-lg"></i>
                                  </button>
                                  {PW_open === null ? (
                                    <button
                                      onClick={(e) =>
                                        PW_openUpdate(L_user._id, e)
                                      }
                                      className="btn btn-md btn-warning px-4 ml3"
                                      style={{
                                        backgroundColor: "silver",
                                        border: "1px solid silver",
                                      }}
                                    >
                                      <i class="fas fa-lock"></i>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={(e) =>
                                        PW_updtate_para(e, PW_wasl)
                                      }
                                      className="btn btn-md btn-primary px-4 ml3"
                                      style={{ backgroundColor: "navy" }}
                                    >
                                      <i className="fas fa-edit "></i>
                                    </button>
                                  )}
                                </>
                              ) : null}
                            </Link>
                            <br /> <br /> <br /> <br />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default FormParawargrtn;
