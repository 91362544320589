import React from 'react'

const ScroollCin = (props) => {
    return (
        <div style={{overflowY:'scroll', border:'1px solid white', height:'450px',scrollMarginBottom:"30px"}}>

        {props.children}

         </div>
    )
}

export default ScroollCin
