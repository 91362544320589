import React from "react";
import FormPrintMasruf from "./FormPrintMasruf";
import rahel from "./../../../img/rahel1.jpg";
import { ProductConsumer } from "../../../Context/AddProWakel";

const InputPrintMasruf = () => {
 return (
  <>
   <ProductConsumer>
    {(value) => {
     const { JM_barwar } = value;
     return (
      <>
       <img src={rahel} alt="rahel" />
       <div className="row mb2">
           <div className="col-4 text-right form-inline "></div>
           <div className="col-4 text-right form-inline ">
         <div className="form-group ">
          <label className="font-weight-bold f3 ml5">ڕاپۆرتی کۆگا</label>
         </div>
        </div>
           <div className="col-4 text-right form-inline"></div>
       </div>

       <div
        className="row text-dark font-weight-bold f3 mx-4"
        style={{ border: "2px solid black" }}
       >
           <div className="col-5 text-right  form-inline ">
         <div className="form-group">
          <h5 className="font-weight-bold mt1 ml5 ">{JM_barwar}</h5>
          <label className="font-weight-bold mb-2 ml1">: بەرواری</label>
         </div>
        </div>

           <div className="col-5 text-right  form-inline ">
         {/* <div className="form-group">
                    <h5 className="font-weight-bold mt1 ml5  ">{RMS_from}</h5>
                      <label className="font-weight-bold mb-2  ml1">: لە بەرواری </label>
                      </div> */}
        </div>
       </div>

       <div className="mt4"></div>
       <FormPrintMasruf />
      </>
     );
    }}
   </ProductConsumer>
  </>
 );
};

export default InputPrintMasruf;
