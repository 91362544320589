import React from 'react'
import rahel from './../../../img/rahel1.jpg'
import FillParawargrtnPrint from './FillParawargrtnPrint'
const PrintAllParawargrtn = () => {
    return (
        <>
                    <img src={rahel}  alt='rahel'/>
                    <FillParawargrtnPrint/>
        </>
    )
}

export default PrintAllParawargrtn
