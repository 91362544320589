import React from "react";
import FormPrintWakel from "./FormPrintWakel";
import rahel from "./../../../img/rahel1.jpg";
const RaportWakil = () => {
  return (
    <>
      <img src={rahel} alt="rahel" />
      <div className="mt4"></div>
      <FormPrintWakel />
    </>
  );
};

export default RaportWakil;
