import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import Time from "react-time-format";

class FillDetailKren extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              AddProChange,
              BN_karton,
              BN_search_wakil,
              BN_darzan,
              BN_naweSht,
              BN_rezha,
              BN_DarzanChange,
              BN_CartonChange,
              BN_tofirstInputINRent,
              BN_createNewPage,
              BN_mob,
              BN_nawe_barez,
              BN_zh_wasl,
              BN_zh_wasl_katy,
              BN_anotherInputWakil,
              BN_open,
              BN_getWaslDataBySearchRent,
              BN_getkoygahty,
              BN_getProductDataBySearchRent,
              BN_wasl,
              BN_search,
              BN_del_dis,
              BN_save,
              BN_togler,
              BN_saveDetail,
              BN_joripara,
              BN_wargr,
              BN_nrx_dolar,
              BN_nrx_dinarr,
              BN_nrx_dolar3,
              BN_nrx_dolar2,
              BN_rasidKarton,
              BN_search_wasl,
              BN_date,
              BN_qazanj,
              BN_kren,
              BN_nrxkaton,
              BN_setSearchEmptyRentWakil,
              BN_setSaletSearchEmty,
              Ap_kasakan,
              BN_zh_barez,
              toanotherInput,
              BN_inputtogler,
              BN_suggestions,
              BN_suggestionSelected,
              BN_Search_Koga_Handle,
              BN_dataTable,
            } = value;
            const renderSuggestions = () => {
              if (BN_suggestions.length === 0) {
                return null;
              }
              return (
                <div className="srchList_rent">
                  <ul className="uly_rent">
                    {BN_suggestions.map((item) => (
                      <li onClick={() => BN_suggestionSelected(item._id)}>
                        {item.nameofproduct}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            };
            return (
              <>
                <div style={{ marginTop: "3.2em" }}>
                  <div className="row" style={{ paddingTop: "50px" }}>
                    <div className="col-md-3">
                      <div className="row">
                        <div
                          className={`${
                            BN_togler === true ? "col-md-10" : "col-md-7"
                          } pt2  tc`}
                        >
                          {BN_togler === false ? (
                            <>
                              {BN_search_wasl === true || BN_save === true ? (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={BN_date}
                                    format="YYYY-MM-DD"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              ) : (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={Date.now()}
                                    format="YYYY-MM-DD"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              )}
                            </>
                          ) : (
                            <input
                              type="datetime-local"
                              name="BN_date"
                              value={BN_date}
                              onChange={AddProChange}
                              className="form-control text-right font-weight-bold tc btn btn-info"
                              style={{ margin: "0px 8px" }}
                            />
                          )}
                        </div>
                        {BN_togler === true ? null : (
                          <div className="col-md-3">
                            <label className="text-warning font-weight-bold f4">
                              :بەروار{" "}
                            </label>
                          </div>
                        )}
                        <div className="col-md-2">
                          <button
                            onClick={BN_inputtogler}
                            disabled={BN_del_dis === true ? true : null}
                            className="btn btn-info"
                          >
                            <i class="far fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row">
                        <div className="col-md-6 pt2  tc">
                          {BN_togler === true ? null : (
                            <>
                              {BN_search_wasl === true ? (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={BN_date}
                                    format="hh:mm:ss"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              ) : (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={Date.now()}
                                    format="hh:mm:ss"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="text-warning font-weight-bold f4">
                            :کات{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <h4 className="text-warning animate__animated animate__heartBeat animate__repeat-3">
                        کاڵای تێکچوو
                      </h4>
                    </div>
                    <div className="col-md-2 ">
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="number"
                            onChange={AddProChange}
                            value={BN_zh_wasl}
                            disabled={BN_open === true ? true : null}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? BN_getWaslDataBySearchRent(event)
                                : null
                            }
                            name="BN_zh_wasl"
                            className="form-control text-right font-weight-bold px-0"
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="text-warning font-weight-bold f4">
                            :پسوولە{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1 form-inline">
                      <h4 className=" text-dark font-weight-bold bg-white">
                        {BN_zh_wasl_katy}
                      </h4>
                    </div>
                  </div>
                </div>
                <form>
                  <div
                    className="row text-warning font-weight-bold"
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <div className="col-md-1 text-right"></div>
                    <div className="col-md-1 text-right px-2 mt4">
                      {BN_save !== null || BN_search_wasl !== null ? (
                        <button
                          onClick={BN_createNewPage}
                          className="btn btn-primary mr2"
                        >
                          <i className="far fa-file fa-lg"></i>
                        </button>
                      ) : (
                        <button
                          onClick={BN_createNewPage}
                          disabled={BN_dataTable.length !== 0 ? true : null}
                          className="btn btn-primary mr2"
                        >
                          <i className="far fa-file fa-lg"></i>
                        </button>
                      )}
                      <button
                        onClick={(e) => BN_saveDetail(e)}
                        className="btn btn-success"
                      >
                        <i className="far fa-save"></i>
                      </button>
                    </div>

                    <div className="col-md-2 text-right ">
                      <div className="form-group ">
                        <>
                          <label>$ کۆی گشتی </label>
                          <input
                            type="number"
                            disabled={BN_del_dis === true ? true : null}
                            value={
                              BN_karton > 0 || BN_darzan > 0
                                ? BN_getkoygahty(BN_darzan, BN_karton)
                                : 0
                            }
                            onChange={AddProChange}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? BN_saveDetail(event)
                                : null
                            }
                            name="BN_koygshty$"
                            className="form-control text-right font-weight-bold tc"
                          />
                        </>
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>ڕێژە</label>
                        <input
                          type="number"
                          disabled={BN_del_dis === true ? true : null}
                          value={BN_rezha}
                          name="BN_rezha"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? BN_tofirstInputINRent(event)
                              : null
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>نرخ</label>
                        <input
                          type="text"
                          disabled={BN_del_dis === true ? true : null}
                          value={BN_kren}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          name="BN_kren"
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>دانە</label>
                        <input
                          type="text"
                          disabled={BN_del_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={(e) => BN_DarzanChange(e)}
                          value={BN_darzan === 0 ? "" : BN_darzan}
                          name="BN_darzan"
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>کارتۆن</label>
                        <input
                          type="text"
                          disabled={BN_del_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={BN_CartonChange}
                          value={BN_karton === 0 ? "" : BN_karton}
                          name="BN_karton"
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 text-right">
                      <div className="form-group">
                        <label>ناوی شتەکان</label>
                        <input
                          autocomplete="off"
                          type="text"
                          disabled={BN_del_dis === true ? true : null}
                          value={BN_naweSht}
                          onChange={BN_Search_Koga_Handle}
                          onClick={BN_setSaletSearchEmty}
                          name="BN_naweSht"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? BN_getProductDataBySearchRent(event)
                              : null
                          }
                          className="form-control text-right font-weight-bold bg-success text-black"
                        />
                        <div className="justify-content-md-center text-center">
                          {renderSuggestions()}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="row">
                        <div className="col-md-8 mt4 ">
                          <button
                            onClick={BN_setSaletSearchEmty}
                            className="btn btn-dark"
                          >
                            <i className="fas fa-search-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}

export default FillDetailKren;
