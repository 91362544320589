import React from 'react'
import rahel from './../../../img/rahel1.jpg'
import FillParadanPrint from './FillParadanPrint'
const PrintAllParadan = () => {
    return (
        <>
                    <img src={rahel}  alt='rahel'/>
                    <FillParadanPrint/>
        </>
    )
}

export default PrintAllParadan
