import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import Time from "react-time-format";

class FillDetailKoga extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              G_frosht,
              AddProChange,
              G_karton,
              G_darzan,
              G_nrx,
              G_naweSht,
              G_rezha,
              G_DarzanChange,
              G_CartonChange,
              G_tofirstInputINRent,
              G_createNewPage,
              G_mob,
              G_nawe_barez,
              G_zh_barez,
              G_zh_wasl,
              G_zh_wasl_katy,
              G_anotherInputWakil,
              G_open,
              G_joridolar,
              G_getWaslDataBySearchRent,
              G_getkoygahty,
              G_getProductDataBySearchRent,
              G_wasl,
              G_search_wakil,
              G_search,
              G_del_dis,
              G_save,
              G_togler,
              G_saveDetail,
              G_joripara,
              G_wargr,
              G_nrx_dolar,
              G_nrx_dinarr,
              G_nrx_dolar3,
              G_nrx_dolar2,
              G_rasidKarton,
              G_search_wasl,
              G_date,
              G_qazanj,
              G_kren,
              G_nrxkaton,
              G_setSearchEmptyRentWakil,
              G_setSaletSearchEmty,
              W_wakelakan,
              toanotherInput,
              G_nrxchange,
              G_inputtogler,
              G_suggestions,
              G_suggestionSelected,
              Search_Koga_Handle,
              W_getwakelakan,
            } = value;

            const wakilakan = W_wakelakan.filter((user) => {
              return user.Name.includes(G_nawe_barez);
            }).map((item, index) => {
              if (G_frosht === "فرۆشتنی ڕاستەوخۆ") {
                return null;
              } else {
                return <option key={index}>{item.Name}</option>;
              }
            });
            const renderSuggestions = () => {
              if (G_suggestions.length === 0) {
                return null;
              }
              return (
                <div className="srchList_rent">
                  <ul className="uly_rent">
                    {G_suggestions.map((item) => (
                      <li onClick={() => G_suggestionSelected(item._id)}>
                        {item.nameofproduct}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            };
            return (
              <>
                <div style={{ marginTop: "3.5em" }}>
                  <form>
                    <div
                      className="row text-warning font-weight-bold f4"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <div className="col-md-3 form-inline">
                        <div className="col-md-7">
                          <label>:ناوی وەرگر</label>
                          <select
                            disabled={G_del_dis === true ? true : null}
                            className={`form-control text-right font-weight-bold selectpicker pull-right ${
                              G_wargr === "‌‌‌‌‌‌حاجی ڕەقیب"
                                ? "bg-success text-white"
                                : `${
                                    G_wargr === "حاجی رەمەزان"
                                      ? "bg-danger text-white"
                                      : ""
                                  }`
                            } `}
                            onChange={AddProChange}
                            name="G_wargr"
                            value={G_wargr !== null ? G_wargr : ""}
                          >
                            <option className="ml5">ناوی وەرگر</option>
                            <option>‌‌‌‌‌‌حاجی ڕەقیب</option>
                            <option>حاجی رەمەزان</option>
                          </select>
                        </div>
                        <div className=" col-md-5">
                          <label>:جۆری دراو</label>
                          <select
                            className={`form-control text-right font-weight-bold selectpicker pull-right ${
                              G_joripara === "دۆلار"
                                ? "bg-success text-white"
                                : `${
                                    G_joripara === "دینار"
                                      ? "bg-danger text-white"
                                      : ""
                                  }`
                            } `}
                            onChange={AddProChange}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? G_anotherInputWakil(event)
                                : null
                            }
                            name="G_joripara"
                            value={G_joripara !== null ? G_joripara : ""}
                          >
                            <option
                              className="ml5"
                              disabled={G_joripara !== null ? true : null}
                            >
                              جۆری دراو
                            </option>
                            <option
                              disabled={G_joripara === "دینار" ? true : null}
                            >
                              دۆلار
                            </option>
                            <option
                              disabled={G_joripara === "دۆلار" ? true : null}
                            >
                              دینار
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 text-right ">
                        <div className="form-group">
                          <label>:تەلەفۆن</label>
                          <input
                            type="number"
                            disabled={G_del_dis === true ? true : null}
                            value={G_mob}
                            name="G_mob"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? G_anotherInputWakil(event)
                                : null
                            }
                            onChange={AddProChange}
                            className="form-control text-right font-weight-bold"
                          />
                        </div>
                      </div>
                      <div className="col-md-2 text-right">
                        <div className="form-group">
                          <label>:گەڕان</label>
                          <select
                            disabled={G_del_dis === true ? true : null}
                            className="form-control text-right font-weight-bold selectpicker pull-right "
                            onChange={AddProChange}
                            name="G_nawe_barez"
                            value={G_nawe_barez}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? G_anotherInputWakil(event)
                                : null
                            }
                          >
                            <option className="ml5">گەڕان</option>
                            {wakilakan}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 text-right">
                        <div className="form-group">
                          <label>:بەڕێز</label>
                          <input
                            type="text"
                            disabled={G_del_dis === true ? true : null}
                            value={G_nawe_barez}
                            name="G_nawe_barez"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? G_anotherInputWakil(event)
                                : null
                            }
                            onChange={W_getwakelakan}
                            className="form-control text-right font-weight-bold"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 text-right">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>:معمیل</label>
                              <input
                                type="text"
                                value={G_zh_barez > 0 ? G_zh_barez : ""}
                                disabled={
                                  G_search_wakil === true ||
                                  G_frosht === "فرۆشتنی ڕاستەوخۆ" ||
                                  G_frosht === null ||
                                  G_del_dis === true
                                    ? true
                                    : null
                                }
                                name="G_zh_barez"
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? G_anotherInputWakil(event)
                                    : null
                                }
                                onChange={AddProChange}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <button
                              onClick={G_setSearchEmptyRentWakil}
                              disabled={G_del_dis === true ? true : null}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              className="btn btn-dark mt4"
                            >
                              <i
                                onClick={G_setSearchEmptyRentWakil}
                                className="fas fa-search-plus"
                              ></i>
                            </button>
                          </div>
                          <div className="col-md-7">
                            <select
                              disabled={G_del_dis === true ? true : null}
                              className={`form-control text-right font-weight-bold selectpicker pull-right mt4 ml3 ${
                                G_frosht === "فرۆشتنی معمیل"
                                  ? "bg-success text-white"
                                  : `${
                                      G_frosht === "فرۆشتنی ڕاستەوخۆ"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              }`}
                              onChange={AddProChange}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              name="G_frosht"
                              value={G_frosht !== null ? G_frosht : ""}
                            >
                              <option className="ml5">جۆری فرۆشتن</option>
                              <option> فرۆشتنی معمیل</option>
                              <option> فرۆشتنی ڕاستەوخۆ</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="row">
                        <div
                          className={`${
                            G_togler === true ? "col-md-10" : "col-md-7"
                          } pt2  tc`}
                        >
                          {G_togler === false ? (
                            <>
                              {G_search_wasl === true || G_save === true ? (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={G_wasl.date}
                                    format="YYYY-MM-DD"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              ) : (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={Date.now()}
                                    format="YYYY-MM-DD"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              )}
                            </>
                          ) : (
                            <input
                              type="datetime-local"
                              name="G_date"
                              value={G_date}
                              onChange={AddProChange}
                              className="form-control text-right font-weight-bold tc btn btn-info"
                              style={{ margin: "0px 8px" }}
                            />
                          )}
                        </div>
                        {G_togler === true ? null : (
                          <div className="col-md-3">
                            <label className="text-warning font-weight-bold f4">
                              :بەروار{" "}
                            </label>
                          </div>
                        )}
                        <div className="col-md-2">
                          <button
                            onClick={G_inputtogler}
                            disabled={G_del_dis === true ? true : null}
                            className="btn btn-info"
                          >
                            <i class="far fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row">
                        <div className="col-md-6 pt2  tc">
                          {G_togler === true ? null : (
                            <>
                              {G_search_wasl === true ? (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={G_wasl.date}
                                    format="hh:mm:ss"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              ) : (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={Date.now()}
                                    format="hh:mm:ss"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="text-warning font-weight-bold f4">
                            :کات{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <h4 className="text-warning animate__animated animate__heartBeat animate__repeat-3">
                        گەڕانەوەی کاڵا
                      </h4>
                    </div>
                    <div className="col-md-2 ">
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="number"
                            onChange={AddProChange}
                            value={G_zh_wasl}
                            disabled={G_open === true ? true : null}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? G_getWaslDataBySearchRent(event)
                                : null
                            }
                            name="G_zh_wasl"
                            className="form-control text-right font-weight-bold px-0"
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="text-warning font-weight-bold f4">
                            :پسوولە{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1 form-inline">
                      <h4 className=" text-dark font-weight-bold bg-white">
                        {G_zh_wasl_katy}
                      </h4>
                    </div>
                  </div>
                </div>

                {/* <br/> */}
                {G_search === true ? (
                  <div className="row text-warning font-weight-bold ">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="qazanj"
                      >
                        Q$ {G_qazanj}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="kren"
                      >
                        K$ {G_kren}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="nrxyakkarton"
                      >
                        KK$ {G_nrxkaton}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="rasid"
                      >
                        R {Number(G_rasidKarton).toFixed(2)}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="frosthndolar"
                      >
                        $ {G_nrx_dolar}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="frosthndinar"
                      >
                        $2- {G_nrx_dolar2}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="niwkarton"
                      >
                        $3- {G_nrx_dolar3}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="Rkmtrladadrzan"
                      >{`ID ${G_nrx_dinarr}`}</h6>
                    </div>
                  </div>
                ) : null}
                <form>
                  <div
                    className="row text-warning font-weight-bold"
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <div className="col-md-1 text-right px-2 mt4">
                      <button
                        onClick={G_createNewPage}
                        disabled={G_open === true ? true : null}
                        className="btn btn-primary mr2"
                      >
                        <i className="far fa-file fa-lg"></i>
                      </button>
                      <button
                        onClick={(e) => G_saveDetail(e)}
                        className="btn btn-success"
                      >
                        <i className="far fa-save"></i>
                      </button>
                    </div>

                    <div className="col-md-2 text-right ">
                      <div className="form-group ">
                        {G_joripara === "دۆلار" ? (
                          <>
                            <label>$ کۆی گشتی </label>
                            <input
                              type="number"
                              disabled={G_del_dis === true ? true : null}
                              value={
                                G_karton > 0 || G_darzan > 0
                                  ? G_getkoygahty(G_darzan, G_karton)
                                  : 0
                              }
                              onChange={AddProChange}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? G_saveDetail(event)
                                  : null
                              }
                              name="G_koygshty$"
                              className="form-control text-right font-weight-bold tc"
                            />
                          </>
                        ) : (
                          <>
                            <label>ID کۆی گشتی </label>
                            <input
                              type="number"
                              disabled={G_del_dis === true ? true : null}
                              value={
                                G_karton > 0 || G_darzan > 0
                                  ? G_getkoygahty(G_darzan, G_karton)
                                  : 0
                              }
                              onChange={AddProChange}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? G_saveDetail(event)
                                  : null
                              }
                              name="G_koygshtyID"
                              className="form-control text-right font-weight-bold tc"
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>ڕێژە</label>
                        <input
                          type="number"
                          disabled={G_del_dis === true ? true : null}
                          value={G_rezha}
                          name="G_rezha"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? G_tofirstInputINRent(event)
                              : null
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>نرخ</label>
                        <input
                          type="text"
                          disabled={G_del_dis === true ? true : null}
                          value={G_nrx}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          name="G_nrx"
                          onChange={G_nrxchange}
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>دانە</label>
                        <input
                          type="text"
                          disabled={G_del_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={(e) => G_DarzanChange(e)}
                          value={G_darzan === 0 ? "" : G_darzan}
                          name="G_darzan"
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>کارتۆن</label>
                        <input
                          type="text"
                          disabled={G_del_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={G_CartonChange}
                          value={G_karton === 0 ? "" : G_karton}
                          name="G_karton"
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 text-right">
                      <div className="form-group">
                        <label>ناوی شتەکان</label>
                        <input
                          autocomplete="off"
                          type="text"
                          disabled={G_del_dis === true ? true : null}
                          value={G_naweSht}
                          onChange={Search_Koga_Handle}
                          onClick={G_setSaletSearchEmty}
                          name="G_naweSht"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? G_getProductDataBySearchRent(event)
                              : null
                          }
                          className="form-control text-right font-weight-bold bg-info text-white"
                        />
                        <div className="justify-content-md-center text-center">
                          {renderSuggestions()}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2 text-right px-2">
                      <div className="row">
                        <div className="col-md-8">
                          <label>نرخی دۆلار</label>
                          <select
                            className="form-control text-right font-weight-bold selectpicker pull-right "
                            disabled={G_joripara === "دینار" ? true : null}
                            onChange={AddProChange}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? toanotherInput(event)
                                : null
                            }
                            name="G_joridolar"
                            value={G_joridolar !== null ? G_joridolar : ""}
                          >
                            <option
                              className="ml5"
                              disabled={G_joridolar !== null ? true : null}
                            >
                              $$$$
                            </option>
                            <option
                              disabled={
                                G_joridolar === "2 دۆلار" ||
                                G_joridolar === "3 دۆلار"
                                  ? true
                                  : null
                              }
                            >
                              1 دۆلار
                            </option>
                            <option
                              disabled={
                                G_joridolar === "1 دۆلار" ||
                                G_joridolar === "3 دۆلار"
                                  ? true
                                  : null
                              }
                            >
                              2 دۆلار
                            </option>
                            <option
                              disabled={
                                G_joridolar === "1 دۆلار" ||
                                G_joridolar === "2 دۆلار"
                                  ? true
                                  : null
                              }
                            >
                              3 دۆلار
                            </option>
                          </select>
                        </div>
                        <div className="col-md-4 mt4 ">
                          <button
                            onClick={G_setSaletSearchEmty}
                            className="btn btn-dark"
                          >
                            <i className="fas fa-search-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}

export default FillDetailKoga;
