import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
const FooterOfQazanj = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            Q_qazanjysafi,
            Q_koyQazanj,
            Q_dashkandolar,
            Q_dashkandinar,
            Q_ganawa,
          } = value;
          return (
            <>
              {/* second row */}
              <div className="row pt1 f4 tc">
                <div className="col-lg-2 col-sm-6"></div>
                <div
                  className="col-lg-2 col-sm-6"
                  style={{ border: "1.5px solid white" }}
                >
                  <span className="text-white">{Q_dashkandolar} :</span> :{" "}
                  <span className="text-warning">$داشکان</span>
                </div>
                <div
                  className="col-lg-2 col-sm-6"
                  style={{ border: "1.5px solid white" }}
                >
                  <span className="text-white">{Q_dashkandinar} :</span> :{" "}
                  <span className="text-warning">ID داشکان</span>
                </div>{" "}
                <div
                  className="col-lg-2 col-sm-6"
                  style={{ border: "1.5px solid white" }}
                >
                  <span className="text-white">{Q_ganawa} :</span>{" "}
                  <span className="text-warning">قازانجی گەڕاوە</span>
                </div>
                <div
                  className="col-lg-2 col-sm-6"
                  style={{ border: "1.5px solid white" }}
                >
                  <span className="text-white">{Q_koyQazanj} :</span>{" "}
                  <span className="text-warning">قازانجی فرۆشتن</span>
                </div>
                <div className="col-lg-2 col-sm-6"></div>
              </div>
              <br />
              {/* third row */}
              <div className="row pt1 f4 tc">
                <div className="col-lg-4 col-sm-6"></div>
                <div
                  className="col-lg-4 col-sm-6"
                  style={{ border: "1.5px solid white" }}
                >
                  <span className="text-white">{Q_qazanjysafi} :</span> :{" "}
                  <span className="text-warning">قازانجی سافی</span>
                </div>
                <div className="col-lg-4 col-sm-6"></div>
              </div>
              <br />
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfQazanj;
