import React from "react";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const {
          AddProChange,
          RP_zhmara,
          RP_to,
          RP_from,
          RP_naw,
          RP_getPrint,
          RP_ParayNahatu,
        } = value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning ">
                {" "}
                <Link to="/print-paraynagarawa">
                  <button className="btn btn-warning" onClick={RP_getPrint}>
                    چاپکردن
                  </button>
                </Link>
              </div>
              <div className="col-md-6 mt1 font-weight-bold   form-group text-warning tc">
                <h5>ڕاپۆرتی پارەی نەگەڕاوە</h5>
              </div>
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning"></div>
            </div>
            {/* //// */}
            <div
              className="row  tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-6 mt1 font-weight-bold   form-group text-warning tc">
                <input
                  type="search"
                  onChange={AddProChange}
                  aria-label="Large"
                  name="RP_naw"
                  value={RP_naw}
                  className="form-control"
                />
              </div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                <input
                  type="search"
                  onChange={AddProChange}
                  aria-label="Large"
                  name="RP_zhmara"
                  value={RP_zhmara}
                  className="form-control"
                />
              </div>
              <div className="col-md-2 mt1 font-weight-bold  pointer form-group text-warning">
                <label className="text-warning  font-weight-bold">گەڕان</label>
              </div>
            </div>
            <div
              className="row text-right pt2"
              style={{ backgroundColor: "#2e3541 " }}
            >
              <div className="col-md-2 mt3 text-warning font-weight-bold  pointer form-group">
                {" "}
                <button className="btn btn-info" onClick={RP_ParayNahatu}>
                  پیشاندان
                </button>
              </div>
              <div className="col-md-5 text-warning font-weight-bold  pointer form-group">
                :بۆ بەرواری{" "}
                <span className="text-warning">
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RP_to"
                    value={RP_to}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
              <div className="col-md-5 text-warning font-weight-bold  pointer form-group">
                :لە بەرواری
                <span className="text-warning">
                  {" "}
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RP_from"
                    value={RP_from}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
