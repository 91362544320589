import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import Time from "react-time-format";

const DetailOfRent = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            R_mob,
            R_nawe_barez,
            R_zh_barez,
            R_zh_wasl,
            R_joripara,
            R_wargr,
            R_wasl,
            R_search_wasl,
            R_date,
          } = value;
          return (
            <>
              <form
                className="border border-black "
                style={{ borderTop: "1px solid black" }}
              >
                <div className="detail">
                  <div className="row text-dark font-weight-bold f4 mt-2 border border-black">
                    <div className="col-3 text-right form-inline mt-2">
                      {R_search_wasl === true ? (
                        <p>
                          <Time
                            value={R_date}
                            format="YYYY-MM-DD"
                            style={{ color: "black" }}
                          />
                        </p>
                      ) : (
                        <p>
                          <Time
                            value={R_date}
                            format="YYYY-MM-DD"
                            style={{ color: "black" }}
                          />
                        </p>
                      )}
                    </div>
                    <div className="col-3 text-right form-inline">
                      <div className="form-group">
                        <h5 className=" f4 mt-2" style={{ color: "black" }}>
                          {R_mob}
                        </h5>
                        <label
                          className="font-weight-bold f4 ml3"
                          style={{ color: "black" }}
                        >
                          :تەلەفۆن
                        </label>
                      </div>
                    </div>

                    <div className="col-3 text-right  form-inline ">
                      <div className="form-group">
                        <h5 className=" ml5 f4 mt-2" style={{ color: "black" }}>
                          {R_nawe_barez}
                        </h5>
                        <label
                          className="font-weight-bold f4 ml1"
                          style={{ color: "black" }}
                        >
                          :بەڕێز
                        </label>
                      </div>
                    </div>

                    <div className="col-3 text-right form-inline">
                      <div className="form-group ">
                        <h5 className=" ml5 f4 mt-2" style={{ color: "black" }}>
                          {R_zh_barez}
                        </h5>
                        <label
                          className="font-weight-bold f4 ml3"
                          style={{ color: "black" }}
                        >
                          :بەڕێز ژمارە
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* row 2 */}
                <hr style={{ border: "0.3px solid black" }} />
                <div className="row text-dark font-weight-bold f4  mr-4 border border-black">
                  <div className="col-3 text-right form-inline mb-2">
                    {R_search_wasl === true ? (
                      <p>
                        <Time
                          value={R_date}
                          format="hh:mm:ss"
                          style={{ color: "black" }}
                        />
                      </p>
                    ) : (
                      <p>
                        <Time
                          value={R_date}
                          format="hh:mm:ss"
                          style={{ color: "black" }}
                        />
                      </p>
                    )}
                  </div>
                  <div className="col-3 text-right form-inline ">
                    <div className="form-group ml3">
                      <h5 className=" f4" style={{ color: "black" }}>
                        {R_wargr==="‌‌‌‌‌‌حاجی ڕەقیب"?"‌‌‌‌‌‌حاجی ڕەقیب":R_wargr==="حاجی رەمەزان"?"حاجی سعید":""}
                      </h5>
                      <label
                        className="font-weight-bold mb-2 f4 ml3"
                        style={{ color: "black" }}
                      >
                        : ناوی وەرگر
                      </label>
                    </div>
                  </div>

                  <div className="col-3 text-right  form-inline">
                    <div className="form-group ">
                      <h5 className=" ml5 f4" style={{ color: "black" }}>
                        {R_joripara}
                      </h5>
                      <label
                        className="font-weight-bold mb-2 f4 ml1 "
                        style={{ color: "black" }}
                      >
                        : جۆری دراو
                      </label>
                    </div>
                  </div>
                  <div className="col-3 text-right form-inline">
                    <div className="form-group ">
                      <h5 className=" ml5 f4" style={{ color: "black" }}>
                        {R_zh_wasl}
                      </h5>
                      <label
                        className="font-weight-bold mb-2 f4 ml3"
                        style={{ color: "black" }}
                      >
                        : ژمارەی پسوولە
                      </label>
                    </div>
                  </div>
                </div>

                <div></div>
              </form>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default DetailOfRent;
