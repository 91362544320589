import React from "react";
import NumberFormat from "react-number-format";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import Time from "react-time-format";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { RMS_data } = value;
            const qarzeWakel = RMS_data.map((user, i) => {
              return (
                <tr key={i} className=" ">
                  <td
                    style={{ border: "2px solid #f0ad4e" }}
                    className={` ${
                      user.joripara === "دۆلار" ? "text-success" : "text-danger"
                    }`}
                  >
                    {user.joripara}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={user.koypara}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.shwen}
                  </td>
                  <td
                    className=""
                    style={{ border: "2px solid #f0ad4e", cursor: "pointer" }}
                  >
                    <Time
                      value={user.createdAt}
                      format="YYYY-MM-DD"
                      style={{ color: "black" }}
                    />
                  </td>
                </tr>
              );
            });

            return (
              <div className="JM">
                <table
                  id="example"
                  className="table table-hover table-bordered text-right"
                >
                  <thead>
                    <tr
                      className="text-warning "
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>دراو</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>کۆی پارە</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>شوێن/کەس</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>بەروار</th>
                    </tr>
                  </thead>
                  <tbody>{qarzeWakel}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
