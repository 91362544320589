import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

class ScroollTableKogaRaport extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              JM_dataRaportyKoga,
              JM_code,
              JM_name,
              JM_yaksanbasfr,
              JM_zyattrlasfr,
              JM_clickOnDelete,
            } = value;
            const wakelRaport = JM_dataRaportyKoga.filter((data) => {
              if (JM_name !== "") {
                return data.nameofproduct
                  .toLowerCase()
                  .includes(JM_name.toLowerCase());
              } else if (JM_code !== "") {
                return data.code.toString().includes(JM_code);
              } else {
                return data;
              }
            }).map((user, i) => {
              return (
                <tr
                  key={i}
                  className="f4"
                  style={{
                    backgroundColor: `${
                      JM_yaksanbasfr === true
                        ? "#f0ad4e"
                        : JM_zyattrlasfr === true
                        ? "#90bb90"
                        : ""
                    }`,
                  }}
                >
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={Number(user.rasidKarton * user.price).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {user.numberOfDarzan}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {user.price}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {Number(Math.round(user.rasidKarton)).toFixed(2)}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {Number(user.rasidKarton / user.numberOfDarzan).toFixed(2)}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {user.nameofproduct}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {user.barcode}
                  </td>
                  <td style={{ border: "2px solid #f0ad4e" }}>
                    <button
                      onClick={() => JM_clickOnDelete(user._id)}
                      className="btn btn-danger "
                    >
                      <i className="fa fa-minus" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              );
            });

            return (
              <div className="JM">
                <table
                  id="example"
                  className="table table-hover table-bordered text-right "
                >
                  <thead>
                    <tr
                      className="text-warning f4"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        $ کۆی گشتی{" "}
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>ڕێژە</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>$ كڕین</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>دانە</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        كارتۆنی ماوە
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ناوەی کاڵا
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>X</th>
                    </tr>
                  </thead>
                  <tbody>{wakelRaport}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default ScroollTableKogaRaport;
