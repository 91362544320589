import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const SearchTakeMoney = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            P_note,
            AddProChange,
            getParaDataBySearchMoney,
            P_qarzeKonDolar,
            P_qarzeKonID,
            P_joripara,
            P_brePara,
            P_zhmara,
            P_getIsChecked,
            P_isChecked,
            P_dashkan,
            P_kamkrdnawayNrx,
            bripara,
            W_wakelakan,
            P_nawe_barez,
            dolartodinar
          } = value;
          const wakilakn = W_wakelakan.filter((user) => {
            return user.Name.trim().startsWith(P_nawe_barez);
          }).map((item, index) => {
            return (
              <option className="fk" key={index}>
                {item.Name}
              </option>
            );
          });
          return (
            <>
              <div
                className="card card-primary text-warning font-weight-bold"
                style={{ backgroundColor: "#2C3539" }}
              >
                <div
                  className="card-header border-bottom border-warning"
                  style={{ backgroundColor: "#2C3539" }}
                >
                  <h3 className="card-title tc "> گەران بە دوای </h3>
                </div>

                <form>
                  <div className="card-body text-right">
                    <div className="form-group">
                      <label className="font-weight-bold f4">:پسوولە</label>
                      <input
                        onKeyPress={(event) =>
                          event.key === "Enter"
                            ? getParaDataBySearchMoney(event)
                            : null
                        }
                        type="text"
                        onChange={AddProChange}
                        value={P_zhmara}
                        name="P_zhmara"
                        className="form-control text-right font-weight-bold"
                        placeholder="...گەڕان"
                      />
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold f4">: قەرز بە دینار </label>
                      <NumberFormat
                        displayType="input"
                        thousandSeparator={true}
                        name="P_brePara"
                        value={dolartodinar(parseFloat(
                          P_brePara.toString().replace(/,/g, "")
                        ),)}
                        className="form-control text-right font-weight-bold bg-info text-white"
                      />
                    </div>

                    <div className="form-group">
                      <label className="font-weight-bold f4">:تێبینی </label>
                      <input
                        type="text"
                        onChange={AddProChange}
                        value={P_note}
                        name="P_note"
                        className="form-control text-right font-weight-bold"
                        placeholder="...تێبینی"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-right ml5">
                    <table id="non">
                      <thead>
                        <tr>
                          <th className="text-right pb-2 text-warning f4">
                            <input
                              onChange={P_getIsChecked}
                              checked={P_isChecked === true ? true : false}
                              value={P_isChecked}
                              type="checkbox"
                              className="mr3"
                            />
                            :پیشاندانی قەرزی پێشوو
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {P_isChecked === true ? (
                          <>
                            <tr>
                              <td className="text-right">
                                <label className="font-weight-bold text-warning f4">
                                  : قەرزی کۆن
                                </label>
                                <NumberFormat
                                  value={
                                    P_joripara === "دۆلار"
                                      ? P_qarzeKonDolar
                                      : P_qarzeKonID
                                  }
                                  displayType={"text"}
                                  className="form-control text-right font-weight-bold"
                                  thousandSeparator={true}
                                  prefix={
                                    P_joripara === null
                                      ? ""
                                      : P_joripara === "دۆلار"
                                      ? "$"
                                      : "ID"
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="text-right ">
                                <label className="font-weight-bold text-warning f4">
                                  :قەرزی ئێستا
                                </label>
                                <NumberFormat
                                  value={
                                    P_joripara === null
                                      ? 0
                                      : P_dashkan > 0
                                      ? P_kamkrdnawayNrx()
                                      : bripara()
                                  }
                                  displayType={"text"}
                                  className="form-control text-right font-weight-bold"
                                  thousandSeparator={true}
                                  prefix={
                                    P_joripara === null
                                      ? ""
                                      : P_joripara === "دۆلار"
                                      ? "$"
                                      : "ID"
                                  }
                                />
                              </td>
                            </tr>
                          </>
                        ) : null}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className="card-footer"
                    style={{ backgroundColor: "#2C3539" }}
                  ></div>
                </form>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default SearchTakeMoney;
