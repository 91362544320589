import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import DetailOfRent from "./DetailOfRent";
import rahel from "../../../img/rahel1.jpg";
import "./rent.css";

const TableScrolePrint = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { R_dataTable, R_joripara, R_kartonakan, R_koywaznkarton } =
            value;
          const dataRentPrint = R_dataTable.map((user, i) => {
            return (
              <tr
                key={i}
                className="f4"
                style={{
                  borderBottom: "2px solid black",
                  borderTop: "2px solid black",
                  borderRight: "2px solid black",
                  borderLeft: "2px solid black",
                }}
              >
                <td
                  style={{
                    borderBottom: "2px solid black",
                    borderTop: "2px solid black",
                    borderRight: "2px solid black",
                    borderLeft: "2px solid black",
                    fontSize: "1em",
                    color: "black",
                    padding: "10px 0px",
                  }}
                  className="fk"
                >
                  {R_joripara === "دۆلار" ? (
                    <NumberFormat
                      value={user.kodolar}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  ) : (
                    <NumberFormat
                      value={user.kodinar}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}
                </td>
                <td
                  style={{
                    borderBottom: "2px solid black",
                    borderTop: "2px solid black",
                    borderRight: "2px solid black",
                    borderLeft: "2px solid black",
                    fontSize: "1em",
                    color: "black",
                    padding: "10px 0px",
                  }}
                  className="fk"
                >
                  {Number(user.reja * user.nrx).toFixed(2)}
                </td>
                <td
                  style={{
                    borderBottom: "2px solid black",
                    borderTop: "2px solid black",
                    borderRight: "2px solid black",
                    borderLeft: "2px solid black",
                    fontSize: "1em",
                    color: "black",
                    padding: "10px 0px",
                  }}
                  className="fk"
                >
                  {user.nrx}
                </td>
                <td
                  style={{
                    borderBottom: "2px solid black",
                    borderTop: "2px solid black",
                    borderRight: "2px solid black",
                    borderLeft: "2px solid black",
                    fontSize: "1em",
                    color: "black",
                    padding: "10px 0px",
                  }}
                  className="fk"
                >
                  {user.darzan}
                </td>
                <td
                  style={{
                    borderBottom: "2px solid black",
                    borderTop: "2px solid black",
                    borderRight: "2px solid black",
                    borderLeft: "2px solid black",
                    fontSize: "1em",
                    color: "black",
                    padding: "10px 0px",
                  }}
                  className="fk"
                >
                  {user.carton}
                </td>
                <td
                  style={{
                    borderBottom: "2px solid black",
                    borderTop: "2px solid black",
                    borderRight: "2px solid black",
                    borderLeft: "2px solid black",
                    fontSize: "1em",
                    color: "black",
                    padding: "10px 0px",
                  }}
                  className="fk"
                >
                  {user.nawisht}
                </td>
                <td
                  style={{
                    borderBottom: "2px solid black",
                    borderTop: "2px solid black",
                    borderRight: "2px solid black",
                    borderLeft: "2px solid black",
                    fontSize: "1em",
                    color: "black",
                    padding: "10px 0px",
                  }}
                  className="fk"
                >
                  {user.raqam}
                </td>
                <td
                  style={{
                    borderBottom: "2px solid black",
                    borderTop: "2px solid black",
                    borderRight: "2px solid black",
                    borderLeft: "2px solid black",
                    fontSize: "1em",
                    color: "black",
                    padding: "10px 0px",
                  }}
                  className="fk"
                >
                  {i + 1}
                </td>
              </tr>
            );
          });

          return (
            <>
              <table
                id="example"
                className="tc black-90 py-3 f2-l fk"
                media="print"
              >
                <thead>
                  <tr
                    className="text-dark f4"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                    }}
                  >
                    <th colSpan="8">
                      <img src={rahel} alt="rahel" />
                      {/* <hr style={{border:"0.1px solid black"}}/> */}
                      <DetailOfRent />
                    </th>
                  </tr>

                  <tr
                    className="text-black-50 f4 fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                    }}
                  >
                    <th
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                        padding: "15px 0px",
                      }}
                    >
                      {R_joripara === "دۆلار" ? " کۆی گشتی" : " کۆی گشتی"}
                    </th>
                    <th
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                        padding: "15px 0px",
                      }}
                    >
                      نرخ لفە/پاکەت
                    </th>
                    <th
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                        padding: "15px 0px",
                      }}
                    >
                      نرخ مەتر/دانە
                    </th>
                    <th
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                        padding: "15px 0px",
                      }}
                    >
                      دەرزن
                    </th>
                    <th
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                        padding: "15px 0px",
                      }}
                    >
                      کارتۆن
                    </th>
                    <th
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                        padding: "15px 0px",
                      }}
                    >
                      ناوی شتەکان
                    </th>
                    <th
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                        padding: "15px 0px",
                      }}
                    >
                      کۆد
                    </th>
                    <th
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                        padding: "15px 0px",
                      }}
                    >
                      ژمارە
                    </th>
                  </tr>
                </thead>
                <tbody
                  media="printe"
                  style={{ borderBottom: "1px solid black" }}
                >
                  {dataRentPrint}
                </tbody>
                <tfoot>
                  <tr className="text-black">
                    <th colSpan="4" className="f3">
                      {" "}
                    </th>
                    <th
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                      }}
                      className="f3"
                    >
                      {R_kartonakan}
                    </th>
                    <th colSpan="2" className="f3">
                      {" "}
                      {`${R_dataTable.length}`} : کۆی کاڵاکان
                    </th>

                    <th>
                      <div className="divFooter"></div>
                    </th>
                  </tr>
                </tfoot>
              </table>
              <br />
              <br />
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default TableScrolePrint;
