import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const Footer = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { QW_rasiddolar, QW_rasiddinar } = value;
          return (
            <>
              <br />
              <div className="row mb4">
                <div className="col-md-12 ">
                  <div className="row">
                    <div className="col-md-12 tc">
                      <h6 className="text-warning tc">قەرز بە دۆلار</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          value={Number(QW_rasiddolar).toFixed(2)}
                          className="  font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </h6>
                    </div>

                    <div className="col-md-12 tc">
                      <h6 className="text-warning tc">قەرز بە دینار</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="  font-weight-bold  "
                          thousandSeparator={true}
                          value={Number(QW_rasiddinar).toFixed(2)}
                          prefix="$"
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default Footer;
