import React from "react";
import { Link } from "react-router-dom";
import "./../Navbar/Navbar.css";
import { ProductConsumer } from "../Context/AddProWakel";
import { ButtonContainer } from "./../Components/ButtonContainerSe";
import broken from "../img/lightbulb.png";
import { isMobile } from "react-device-detect";

const Navbar = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const {
          navdolar,
          DolarChange,
          logoutUser,
          disabled_navdolar,
          toggleNavdolar,
          R_zh_wasl_katy,
          D_OpenModal,
          L_user,
          JM_OpenModal,
          K_OpenModal,
          HK_pshandan,
          getWakiltableData,
          getUsertableData,
          IO_OpenModal, PS_OpenModal
        } = value;
        return (
          <nav className="navbar navbar-expand-lg fixed-top">
            <Link to="/">
              <span className="navbar-brand">
                <i className="fas fa-home mx-2 fa-sm text-white"></i>
                <span className="mx-2 text-white">ماڵەوە</span>
              </span>
            </Link>

            <button
              className="navbar-toggler p-0"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>

            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  {/* <Link
                    to="/add-user"
                    className="text-white"
                    onClick={getUsertableData}
                  >
                    <span className="nav-link">
                      <i className="fas fa-user text-white"></i>
                      <label className="text-white my-0 ml-2 f5">
                        زیادکردن{" "}
                      </label>
                    </span>
                  </Link> */}
                </li>

                <li className="nav-item ml2">
                  <input
                    type="text"
                    maxLength="6"
                    value={navdolar}
                    disabled={disabled_navdolar ? false : true}
                    className="form-control text-right"
                    placeholder="...نرخی دۆلار"
                    onChange={DolarChange}
                  />
                </li>
                <li className="nav-item" onClick={toggleNavdolar}>
                  <span className="nav-link">
                    <i className="fas fa-dollar-sign text-white"></i>
                    <label className="text-white my-0 ml-2 f5">
                      نرخی دۆلار
                    </label>
                  </span>
                </li>

                <li className="nav-item dropdown">
                  <span className="badge badge-pill float-right badge-success"></span>
                </li>
              </ul>
              <ul className={`${isMobile ? "d-inline" : ""} navbar-nav mr5`}>
                {/* 3arze kala */}
                {isMobile ? (
                  ""
                ) : (
                  <>
                    <Link to="/All-Koga-3arz">
                      <span
                        className="navbar-brand"
                        onClick={(e) => HK_pshandan(e, L_user._id)}
                      >
                        <i
                          class="fab fa-elementor fa-lg"
                          style={{ color: "#24baf0" }}
                        ></i>
                      </span>
                    </Link>
                    <Link to="/broken">
                      <span className="navbar-brand" style={{ height: "50px" }}>
                        <i class="fas fa-lg fa-heart-broken"></i>
                      </span>
                    </Link>
                  </>
                )}
                <span
                  className="navbar-brand"
                  onClick={(e) => IO_OpenModal(e, L_user._id)}
                >
                  <i
                    className="fas fa-file fa-lg"
                    style={{ color: "#699669" }}
                  ></i>
                </span>
                <Link to="/add-product">
                  <span className="navbar-brand">
                    <i
                      className="fas fa-shopping-cart fa-lg"
                      style={{ color: "#fcb25f" }}
                    ></i>
                  </span>
                </Link>

                <Link to={`/rent?page=${R_zh_wasl_katy}`}>
                  <span className="navbar-brand">
                    <i
                      className="fas fa-cart-plus fa-lg"
                      style={{ color: "#d5fe77" }}
                    ></i>
                  </span>
                </Link>

                <Link to="/add-wakel" onClick={getWakiltableData}>
                  <span className="navbar-brand">
                    <i className="fas fa-users fa-lg"></i>
                  </span>
                </Link>

                <Link to="/take-money">
                  <span className="navbar-brand">
                    <i
                      className="fas fa-cash-register fa-lg"
                      style={{ color: "#7f8aa1" }}
                    ></i>
                  </span>
                </Link>

                {/* raporty rozhana */}

                <span
                  className="navbar-brand"
                  onClick={(e) => D_OpenModal(e, L_user._id)}
                >
                  <i
                    className="fas fa-calendar-day fa-lg"
                    style={{ color: "#24bbc0" }}
                  ></i>
                </span>
                <span
                  className="navbar-brand"
                  onClick={(e) => PS_OpenModal(e, L_user._id)}
                >
                  <i
                    className="fas fa-list-alt fa-lg"
                    style={{ color: "#24bbc0" }}
                  ></i>
                </span>

                {/* raporty jarde maxzan */}
                <span
                  className="navbar-brand"
                  onClick={(e) => JM_OpenModal(e, L_user._id)}
                >
                  <i
                    className="fas fa-store-alt fa-lg"
                    style={{ color: " #E56717" }}
                  ></i>
                </span>

                {/* raporty kalla */}
                <span
                  className="navbar-brand"
                  onClick={(e) => K_OpenModal(e, L_user._id)}
                >
                  <i
                    className="fas fa-shopping-bag fa-lg"
                    style={{ color: "#7f992b" }}
                  ></i>
                </span>

                {/* masrufat */}
                <Link to="/masrufat">
                  <span className="navbar-brand">
                    <i
                      className="fas fa-file-invoice-dollar fa-lg"
                      style={{ color: "#f0e7d3" }}
                    ></i>
                  </span>
                </Link>

                {/* setting */}
                <Link to="/setting">
                  <span className="navbar-brand">
                    <i
                      className="fas fa-cog fa-lg"
                      style={{ color: "#f0e7d3" }}
                    ></i>
                  </span>
                </Link>
                {/* Old Rasid */}
                {isMobile ? (
                  ""
                ) : (
                  <Link to="/old-rasid">
                    <span className="navbar-brand">
                      <i
                        className="fas fa-hand-holding-usd"
                        style={{ color: "#B2A6FF" }}
                      ></i>
                    </span>
                  </Link>
                )}
              </ul>

              <Link to="/login" onClick={logoutUser}>
                <ButtonContainer>
                  <span className="mr-3">چوونەدەرەوە</span>
                </ButtonContainer>
              </Link>
            </div>
          </nav>
        );
      }}
    </ProductConsumer>
  );
};

export default Navbar;
