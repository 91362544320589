import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableParadan from "./ScroollTableParadan";
import FooterOfParadan from "./FooterOfParadan";
import FillDetailParadan from "./FillDetailParadan";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";
const AllParadan = () => {
  return (
    <div>
      <Navbar />
      {isMobile ? (
        <AllMob />
      ) : (
        <div style={{ marginTop: "50px" }}>
          <div className="row" style={{ backgroundColor: "#2e3541 " }}>
              <div className="col-md-12 ">
              <FillDetailParadan />
            </div>
          </div>
          <div className="row">
              <div className="col-md-12 ">
              <ScroollTableParadan />
            </div>
          </div>
          <div className="row " style={{ backgroundColor: "#2e3541 " }}>
              <div className="col-md-12 col-md-12">
              <FooterOfParadan />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllParadan;
