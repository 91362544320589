import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
const ScrollTable = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { G_DeleteSail, G_dataTable, G_del_dis, G_joripara, G_wasl } =
            value;
          const Rent = G_dataTable.map((user, i) => {
            return (
              <tr key={i} className="">
                <td className="" style={{ border: "2px solid #f0ad4e" }}>
                  {G_joripara === "دۆلار" ? (
                    <NumberFormat
                      value={user.kodolar}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  ) : (
                    <NumberFormat
                      value={user.kodinar}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}
                </td>
                <td className="" style={{ border: "2px solid #f0ad4e" }}>
                  {user.nrx}
                </td>
                <td className="" style={{ border: "2px solid #f0ad4e" }}>
                  {user.darzan}
                </td>
                <td
                  className=""
                  style={{ border: "2px solid #f0ad4e", direction: "rtl" }}
                >
                  {user.nawisht}
                </td>
                <td className="" style={{ border: "2px solid #f0ad4e" }}>
                  {user.raqam}
                </td>
                <td style={{ border: "2px solid #f0ad4e" }}>
                  <button
                    onClick={() => G_DeleteSail(user._id, user, G_wasl)}
                    disabled={G_del_dis === true ? true : null}
                    className="btn btn-danger btn-sm"
                  >
                    <i className="fa fa-minus" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            );
          });

          return (
            <div className="RR">
              <table id="example" className="py4 tc m-auto">
                <thead>
                  <tr
                    className="text-warning "
                    style={{
                      border: "2px solid #f0ad4e",
                      backgroundColor: "#2e3541 ",
                    }}
                  >
                    <th style={{ border: "2px solid #f0ad4e" }}>
                      {G_joripara === "دۆلار" ? "$ کۆ" : "ID کۆ"}
                    </th>
                    <th style={{ border: "2px solid #f0ad4e" }}>نرخ</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>دانە</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>ناوی</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>KD</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>x</th>
                  </tr>
                </thead>
                <tbody>{Rent}</tbody>
              </table>
            </div>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default ScrollTable;
