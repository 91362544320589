import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const FooterOfRaportKoga = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { JM_koyrasidkarton, JM_koyrasidparakan } = value;
          return (
            <>
              {/* second row */}
              <div className="row">
                <div className="col-md-12">
                  <div className="row ">
                    <div className="col-lg-2 col-md-6">
                      <h4 className="bg-white tc mt3 py-2">
                        <NumberFormat
                          value={JM_koyrasidkarton}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 py-3">
                      <h4 className="text-warning tc font-weight-bold ">
                        ڕەسیدی کۆتایی هەموو کارتۆنەکان
                      </h4>
                    </div>

                    <div className="col-lg-2 col-md-6">
                      <h4 className="bg-white tc mt3 py-2">
                        <NumberFormat
                          value={JM_koyrasidparakan}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 py-3">
                      <h4 className="text-warning tc font-weight-bold ">
                        {" "}
                        $ڕەسیدی کۆتایی هەموو پارەکان
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              {/* third row */}
              <div className="row">
                <div className="col-lg10 col-md-12"></div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfRaportKoga;
