import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const Footer = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            d_paraynadrawID,
            d_paraynadraw$,
            d_wargrtnawayQarzID,
            d_wargrtnawayQarz$,
            d_paraywargerawID,
            d_paraywargeraw$,
            d_froshrawID,
            d_froahraw$,
            d_rahelID,
            d_rahel$,
            d_yassinID,
            d_yassin$,
          } = value;
          return (
            <>
              <br />
              <div className="row mb4">
                <div className="col-md-12 ">
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="text-warning tc">پارەی نەدراو</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="  font-weight-bold  "
                          thousandSeparator={true}
                          value={d_paraynadraw$}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={d_paraynadrawID}
                          prefix="ID"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning">وەرگرتنەوەی قەرز</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="  font-weight-bold  "
                          thousandSeparator={true}
                          value={d_wargrtnawayQarz$}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={d_wargrtnawayQarzID}
                          prefix="ID"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning tc">پارەی وەرگیراو</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="  font-weight-bold  "
                          thousandSeparator={true}
                          value={d_paraywargeraw$}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={d_paraywargerawID}
                          prefix="ID"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning tc">فرۆشراوەکان</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="  font-weight-bold  "
                          thousandSeparator={true}
                          value={d_froahraw$}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={d_froshrawID}
                          prefix="ID"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning tc">حاجی رەمەزان</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={d_yassin$}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={d_yassinID}
                          prefix="ID"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning tc">حاجی رەقیب</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={d_rahel$}
                          prefix="$"
                        />
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          displayType="text"
                          className="font-weight-bold  "
                          thousandSeparator={true}
                          value={d_rahelID}
                          prefix="ID"
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default Footer;
