import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const Footer = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            Q_qazanjysafi,
            Q_koyQazanj,
            Q_dashkandolar,
            Q_dashkandinar,
            Q_ganawa,
          } = value;
          return (
            <>
              <br />
              <div className="row mb4">
                <div className="col-md-12 ">
                  <div className="row">
                    <div className="col-md-6 tc">
                      <h6 className="text-warning tc">قازانجی فرۆشتن</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          value={Number(Q_koyQazanj).toFixed(2)}
                          className="  font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6 tc">
                      <h6 className="text-warning tc">قازانجی گەڕاوە</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          value={Number(Q_ganawa).toFixed(2)}
                          className="  font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6 tc">
                      <h6 className="text-warning tc">ID داشکان</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          value={Number(Q_dashkandinar).toFixed(2)}
                          className="  font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </h6>
                    </div>

                    <div className="col-md-6 tc">
                      <h6 className="text-warning tc">$داشکان</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          value={Number(Q_dashkandolar).toFixed(2)}
                          className="  font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </h6>
                    </div>
                    <div className="col-md-12 tc">
                      <h6 className="text-warning tc">قازانجی سافی</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          value={Number(Q_qazanjysafi).toFixed(2)}
                          className="  font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix="$"
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default Footer;
