import React from "react";
import ScroolTableAddPro from "./ScroolTableAddPro";
import { ProductConsumer } from "../../../Context/AddProWakel";

const AddProductSecond = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            edit,
            AddProChange,
            DarzanChange,
            darzan,
            updtae_product,
            Add_rasidkarton,
            del_dis,
            karton,
            onSaveTable,
            prodactdata,
            clear_edit,
            CartonChange,
            productpageminsone,
            productpageplusone,
          } = value;
          return (
            <>
              <div className="mt5">
                <h1
                  className="tc border p-2 mx-5 border-warning  text-warning"
                  style={{ backgroundColor: "#2C3539" }}
                >
                  کاتی گەیشتنی شتەکان
                </h1>
                <div className="row  f4" style={{ color: "#000C66" }}>
                  <div className="col-md-1 text-right px-2 mt4">
                    {edit === null ? (
                      <button
                        onClick={() => onSaveTable(prodactdata)}
                        className="btn btn-success"
                      >
                        <i className="far fa-save"></i>
                      </button>
                    ) : (
                      <button
                        onClick={() => updtae_product(edit)}
                        className="btn btn-primary"
                      >
                        <i className="far fa-edit"></i>
                      </button>
                    )}
                  </div>
                  <div className="col-md-2 text-right px-1">
                    <div className="form-group">
                      <label className="font-weight-bold">
                        ڕەسیدی مەتر-دانە
                      </label>
                      <input
                        type="number"
                        // onChange={AddProChange}
                        value={Math.round(Add_rasidkarton)}
                        name="Add_rasidkarton"
                        className="form-control text-right font-weight-bold"
                      />
                    </div>
                  </div>
                  <div className="col-md-2 text-right px-1 ">
                    <div className="form-group">
                      <label className="font-weight-bold">دانە</label>
                      <input
                        type="text"
                        disabled={del_dis === true ? true : null}
                        onChange={(e) => DarzanChange(e)}
                        onKeyPress={(event) =>
                          event.key === "Enter" ? onSaveTable(event) : null
                        }
                        value={darzan === 0 ? "" : darzan}
                        name="darzan"
                        className="form-control text-right font-weight-bold"
                      />
                    </div>
                  </div>

                  <div className="col-md-2 text-right px-1 ">
                    <div className="form-group">
                      <label className="font-weight-bold">کارتۆن</label>
                      <input
                        type="text"
                        disabled={del_dis === true ? true : null}
                        onChange={(e) => CartonChange(e)}
                        onKeyPress={(event) =>
                          event.key === "Enter" ? onSaveTable(event) : null
                        }
                        value={karton === 0 ? "" : karton}
                        name="karton"
                        className="form-control text-right font-weight-bold"
                      />
                    </div>
                  </div>
                  <div className="col-md-1 px-2 mt4">
                    {edit !== null ? (
                      <button onClick={clear_edit} className="btn btn-primary">
                        <i className="fas fa-window-close"></i>
                      </button>
                    ) : null}
                  </div>
                  <div className="col-lg-2 col-md-4 px-2 mt4">
                    <button
                      className="btn btn-dark p3 px-4"
                      style={{
                        backgroundColor: "#00695c",
                        border: "1px solid #00695c",
                      }}
                      onClick={productpageminsone}
                    >
                      <i className="fas fa-caret-left f4"></i>
                    </button>
                    <button
                      className="btn btn-dark p3 px-4 ml3"
                      style={{
                        backgroundColor: "#00695c",
                        border: "1px solid #00695c",
                      }}
                      onClick={productpageplusone}
                    >
                      <i className="fas fa-caret-right f4"></i>
                    </button>
                  </div>
                </div>
              </div>
              <ScroolTableAddPro />
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default AddProductSecond;
