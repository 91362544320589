import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { RMM_data, RMM_getParaDataBySearchMoney, MM_jorfilter } =
              value;
            const qarzeWakel = RMM_data.filter((el) => {
              if (MM_jorfilter === "$") {
                return el.joripara === "دۆلار";
              } else if (MM_jorfilter === "ID") {
                return el.joripara === "دینار";
              } else if (MM_jorfilter === "R") {
                return el.joripara === "ڕیمێمبی";
              } else {
                return el;
              }
            }).map((user, i) => {
              return (
                <tr key={i} className=" ">
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.note}
                  </td>
                  <td
                    style={{ border: "2px solid #f0ad4e" }}
                    className={` ${
                      user.joripara === "دۆلار" ? "text-success" : "text-danger"
                    }`}
                  >
                    {user.joripara}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={
                        user.joripara === "دۆلار"
                          ? user.paraywargirawdolar
                          : user.paraywargirawdinar
                      }
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    className=" text-danger"
                    style={{ border: "2px solid #f0ad4e" }}
                  >
                    {user.zhmaraaywasl}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.nawishwen}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.shwencode}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <Time
                      value={user.date}
                      format="YYYY-MM-DD"
                      style={{ color: "black" }}
                    />
                  </td>
                  <td
                    className=""
                    style={{ border: "2px solid #f0ad4e", cursor: "pointer" }}
                    onClick={(e) =>
                      RMM_getParaDataBySearchMoney(e, user.zhmara)
                    }
                  >
                    <Link to="/Mandub">{user.zhmara}</Link>
                  </td>
                </tr>
              );
            });

            return (
              <div className="JM">
                <table
                  id="example"
                  className="table table-hover table-bordered text-right"
                >
                  <thead>
                    <tr
                      className="text-warning "
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>تێبینی</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>دراو</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        کۆی پسوولە
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        و.کۆمپانیا
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ناوی بەڕێز
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>بەروار</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>ژمارە</th>
                    </tr>
                  </thead>
                  <tbody>{qarzeWakel}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
