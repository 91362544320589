import React from "react";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import { Link } from "react-router-dom";
const Header = () => {
  var today = new Date();
  var kat =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  return (
    <ProductConsumer>
      {(value) => {
        const {
          Q_from,
          Q_to,
          AddProChange,
          Q_getwasl,
          Q_wasl,
          Q_rastawxo,
          Q_kala,
          Q_wakel,
          Q_getrastawxo,
          Q_getwakel,
          Q_getkala,
        } = value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-warning "></div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                <h5>ڕاپۆرتی قازانج</h5>
              </div>
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-warning"></div>
            </div>
            <div
              className="row  tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-4 mt4 font-weight-bold  pointer form-group text-warning">
                {" "}
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    Q_getwasl(e);
                  }}
                >
                  پیشاندان
                </button>
              </div>
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-warning ">
                :بۆ بەرواری{" "}
                <span className="text-warning">
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="Q_to"
                    value={Q_to}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                :لە بەرواری
                <span className="text-warning">
                  {" "}
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="Q_from"
                    value={Q_from}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
            </div>

            <div
              className="row text-right pt2"
              style={{ backgroundColor: "#2e3541 " }}
            >
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${Q_kala === true ? "4px solid white" : ""}`,
                }}
                onClick={Q_getkala}
              >
                <button className="btn btn-info">بەگوێرەی کاڵا</button>
              </div>
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    Q_rastawxo === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={Q_getrastawxo}
              >
                <button className="btn btn-info">بەگوێرەی ڕاستەوخۆ</button>
              </div>
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${Q_wasl === true ? "4px solid white" : ""}`,
                }}
                onClick={Q_getwasl}
              >
                <button className="btn btn-info">بەگوێرەی پسوولە</button>
              </div>
              <div
                className="col-md-6 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${Q_wakel === true ? "4px solid white" : ""}`,
                }}
                onClick={Q_getwakel}
              >
                <button className="btn btn-info"> بەگوێرەی وەکیل</button>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
