import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import Time from "react-time-format";

class FillDetailKren extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              AddProChange,
              KM_karton,
              KM_search_wakil,
              KM_darzan,
              KM_naweSht,
              KM_rezha,
              KM_DarzanChange,
              KM_CartonChange,
              KM_tofirstInputINRent,
              KM_createNewPage,
              KM_mob,
              KM_nawe_barez,
              KM_zh_wasl,
              KM_zh_wasl_katy,
              KM_anotherInputWakil,
              KM_open,
              KM_getWaslDataBySearchRent,
              KM_getkoygahty,
              KM_getProductDataBySearchRent,
              KM_wasl,
              KM_search,
              KM_del_dis,
              KM_save,
              KM_togler,
              KM_saveDetail,
              KM_joripara,
              KM_wargr,
              KM_nrx_dolar,
              KM_nrx_dinarr,
              KM_nrx_dolar3,
              KM_nrx_dolar2,
              KM_rasidKarton,
              KM_search_wasl,
              KM_date,
              KM_qazanj,
              KM_kren,
              KM_nrxkaton,
              KM_setSearchEmptyRentWakil,
              KM_setSaletSearchEmty,
              Ap_kasakan,
              KM_zh_barez,
              toanotherInput,
              KM_inputtogler,
              KM_suggestions,
              KM_suggestionSelected,
              KM_Search_Koga_Handle,
              KM_dataTable,
            } = value;

            const wakilakan = Ap_kasakan.filter((user) => {
              return user.Name.includes(KM_nawe_barez);
            }).map((item, index) => {
              return <option key={index}>{item.Name}</option>;
            });
            const renderSuggestions = () => {
              if (KM_suggestions.length === 0) {
                return null;
              }
              return (
                <div className="srchList_rent">
                  <ul className="uly_rent">
                    {KM_suggestions.map((item) => (
                      <li onClick={() => KM_suggestionSelected(item._id)}>
                        {item.nameofproduct}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            };
            return (
              <>
                <div style={{ marginTop: "3.5em" }}>
                  <form>
                    <div
                      className="row text-warning font-weight-bold f4"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <div className="col-md-3 form-inline">
                        <div className="col-md-7">
                          <label>:ناوی وەرگر</label>
                          <select
                            disabled={KM_del_dis === true ? true : null}
                            className={`form-control text-right font-weight-bold selectpicker pull-right ${
                              KM_wargr === "‌‌‌‌‌‌حاجی ڕەقیب"
                                ? "bg-success text-white"
                                : `${
                                    KM_wargr === "حاجی رەمەزان"
                                      ? "bg-danger text-white"
                                      : ""
                                  }`
                            } `}
                            onChange={AddProChange}
                            name="KM_wargr"
                            value={KM_wargr !== null ? KM_wargr : ""}
                          >
                            <option className="ml5">ناوی وەرگر</option>
                            <option>‌‌‌‌‌‌حاجی ڕەقیب</option>
                            <option>حاجی رەمەزان</option>
                          </select>
                        </div>
                        <div className=" col-md-5">
                          <label>:جۆری دراو</label>
                          <select
                            className={`form-control text-right font-weight-bold selectpicker pull-right ${
                              KM_joripara === "دۆلار"
                                ? "bg-success text-white"
                                : `${
                                    KM_joripara === "دینار"
                                      ? "bg-danger text-white"
                                      : ""
                                  }`
                            } `}
                            onChange={AddProChange}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KM_anotherInputWakil(event)
                                : null
                            }
                            name="KM_joripara"
                            value={KM_joripara === "دۆلار"}
                          >
                            <option
                              className="ml5"
                              disabled={KM_joripara !== null ? true : null}
                            >
                              جۆری دراو
                            </option>
                            <option selected value="دۆلار">
                              دۆلار
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 text-right ">
                        <div className="form-group">
                          <label>:تەلەفۆن</label>
                          <input
                            type="number"
                            disabled={KM_del_dis === true ? true : null}
                            value={KM_mob}
                            name="KM_mob"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KM_anotherInputWakil(event)
                                : null
                            }
                            onChange={AddProChange}
                            className="form-control text-right font-weight-bold"
                          />
                        </div>
                      </div>
                      <div className="col-md-2 text-right">
                        <div className="form-group">
                          <label>:گەڕان</label>
                          <select
                            disabled={KM_del_dis === true ? true : null}
                            className="form-control text-right font-weight-bold selectpicker pull-right "
                            onChange={AddProChange}
                            name="KM_nawe_barez"
                            value={KM_nawe_barez}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KM_anotherInputWakil(event)
                                : null
                            }
                          >
                            <option className="ml5">گەڕان</option>
                            {wakilakan}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 text-right">
                        <div className="form-group">
                          <label>:بەڕێز</label>
                          <input
                            type="text"
                            disabled={KM_del_dis === true ? true : null}
                            value={KM_nawe_barez}
                            name="KM_nawe_barez"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KM_anotherInputWakil(event)
                                : null
                            }
                            onChange={AddProChange}
                            className="form-control text-right font-weight-bold"
                          />
                        </div>
                      </div>
                      <div className="col-md-2 text-right">
                        <div className="row">
                          <div className="col-md-7">
                            <div className="form-group">
                              <label>:معمیل</label>
                              <input
                                type="text"
                                disabled={
                                  KM_del_dis === true ||
                                  KM_search_wakil === true
                                    ? true
                                    : null
                                }
                                value={KM_zh_barez > 0 ? KM_zh_barez : ""}
                                name="KM_zh_barez"
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? KM_anotherInputWakil(event)
                                    : null
                                }
                                onChange={AddProChange}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <button
                              onClick={KM_setSearchEmptyRentWakil}
                              disabled={KM_del_dis === true ? true : null}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              className="btn btn-dark mt4"
                            >
                              <i
                                onClick={KM_setSearchEmptyRentWakil}
                                className="fas fa-search-plus"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="row">
                        <div
                          className={`${
                            KM_togler === true ? "col-md-10" : "col-md-7"
                          } pt2  tc`}
                        >
                          {KM_togler === false ? (
                            <>
                              {KM_search_wasl === true || KM_save === true ? (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={KM_wasl.date}
                                    format="YYYY-MM-DD"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              ) : (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={Date.now()}
                                    format="YYYY-MM-DD"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              )}
                            </>
                          ) : (
                            <input
                              type="datetime-local"
                              name="KM_date"
                              value={KM_date}
                              onChange={AddProChange}
                              className="form-control text-right font-weight-bold tc btn btn-info"
                              style={{ margin: "0px 8px" }}
                            />
                          )}
                        </div>
                        {KM_togler === true ? null : (
                          <div className="col-md-3">
                            <label className="text-warning font-weight-bold f4">
                              :بەروار{" "}
                            </label>
                          </div>
                        )}
                        <div className="col-md-2">
                          <button
                            onClick={KM_inputtogler}
                            disabled={KM_del_dis === true ? true : null}
                            className="btn btn-info"
                          >
                            <i class="far fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row">
                        <div className="col-md-6 pt2  tc">
                          {KM_togler === true ? null : (
                            <>
                              {KM_search_wasl === true ? (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={KM_wasl.date}
                                    format="hh:mm:ss"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              ) : (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={Date.now()}
                                    format="hh:mm:ss"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="text-warning font-weight-bold f4">
                            :کات{" "}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <h4 className="text-warning animate__animated animate__heartBeat animate__repeat-3">
                        کڕینی کاڵا
                      </h4>
                    </div>
                    <div className="col-md-2 ">
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="number"
                            onChange={AddProChange}
                            value={KM_zh_wasl}
                            disabled={KM_open === true ? true : null}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KM_getWaslDataBySearchRent(event)
                                : null
                            }
                            name="KM_zh_wasl"
                            className="form-control text-right font-weight-bold px-0"
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="text-warning font-weight-bold f4">
                            :پسوولە{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1 form-inline">
                      <h4 className=" text-dark font-weight-bold bg-white">
                        {KM_zh_wasl_katy}
                      </h4>
                    </div>
                  </div>
                </div>

                {/* <br/> */}
                {KM_search === true ? (
                  <div className="row text-warning font-weight-bold ">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="qazanj"
                      >
                        Q$ {KM_qazanj}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="kren"
                      >
                        K$ {KM_kren}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="nrxyakkarton"
                      >
                        KK$ {KM_nrxkaton}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="rasid"
                      >
                        R {Number(KM_rasidKarton).toFixed(2)}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="frosthndolar"
                      >
                        $ {KM_nrx_dolar}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="frosthndinar"
                      >
                        $2- {KM_nrx_dolar2}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="niwkarton"
                      >
                        $3- {KM_nrx_dolar3}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="Rkmtrladadrzan"
                      >{`ID ${KM_nrx_dinarr}`}</h6>
                    </div>
                  </div>
                ) : null}
                <form>
                  <div
                    className="row text-warning font-weight-bold"
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <div className="col-md-1 text-right"></div>
                    <div className="col-md-1 text-right px-2 mt4">
                      {KM_save !== null || KM_search_wasl !== null ? (
                        <button
                          onClick={KM_createNewPage}
                          className="btn btn-primary mr2"
                        >
                          <i className="far fa-file fa-lg"></i>
                        </button>
                      ) : (
                        <button
                          onClick={KM_createNewPage}
                          disabled={KM_dataTable.length !== 0 ? true : null}
                          className="btn btn-primary mr2"
                        >
                          <i className="far fa-file fa-lg"></i>
                        </button>
                      )}
                      <button
                        onClick={(e) => KM_saveDetail(e)}
                        className="btn btn-success"
                      >
                        <i className="far fa-save"></i>
                      </button>
                    </div>

                    <div className="col-md-2 text-right ">
                      <div className="form-group ">
                        {KM_joripara === "دۆلار" ? (
                          <>
                            <label>$ کۆی گشتی </label>
                            <input
                              type="number"
                              disabled={KM_del_dis === true ? true : null}
                              value={
                                KM_karton > 0 || KM_darzan > 0
                                  ? KM_getkoygahty(KM_darzan, KM_karton)
                                  : 0
                              }
                              onChange={AddProChange}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? KM_saveDetail(event)
                                  : null
                              }
                              name="KM_koygshty$"
                              className="form-control text-right font-weight-bold tc"
                            />
                          </>
                        ) : (
                          <>
                            <label>ID کۆی گشتی </label>
                            <input
                              type="number"
                              disabled={KM_del_dis === true ? true : null}
                              value={
                                KM_karton > 0 || KM_darzan > 0
                                  ? KM_getkoygahty(KM_darzan, KM_karton)
                                  : 0
                              }
                              onChange={AddProChange}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? KM_saveDetail(event)
                                  : null
                              }
                              name="G_koygshtyID"
                              onChange={AddProChange}
                              className="form-control text-right font-weight-bold tc"
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>ڕێژە</label>
                        <input
                          type="number"
                          disabled={KM_del_dis === true ? true : null}
                          value={KM_rezha}
                          name="KM_rezha"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? KM_tofirstInputINRent(event)
                              : null
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>نرخ</label>
                        <input
                          type="text"
                          disabled={KM_del_dis === true ? true : null}
                          value={KM_kren}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          name="KM_kren"
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>دانە</label>
                        <input
                          type="text"
                          disabled={KM_del_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={(e) => KM_DarzanChange(e)}
                          value={KM_darzan === 0 ? "" : KM_darzan}
                          name="KM_darzan"
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="form-group">
                        <label>کارتۆن</label>
                        <input
                          type="text"
                          disabled={KM_del_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={KM_CartonChange}
                          value={KM_karton === 0 ? "" : KM_karton}
                          name="KM_karton"
                          className="form-control text-right font-weight-bold pr-4"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 text-right">
                      <div className="form-group">
                        <label>ناوی شتەکان</label>
                        <input
                          autocomplete="off"
                          type="text"
                          disabled={KM_del_dis === true ? true : null}
                          value={KM_naweSht}
                          onChange={KM_Search_Koga_Handle}
                          onClick={KM_setSaletSearchEmty}
                          name="KM_naweSht"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? KM_getProductDataBySearchRent(event)
                              : null
                          }
                          className="form-control text-right font-weight-bold bg-warning text-black"
                        />
                        <div className="justify-content-md-center text-center">
                          {renderSuggestions()}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-1 text-right px-2">
                      <div className="row">
                        <div className="col-md-8 mt4 ">
                          <button
                            onClick={KM_setSaletSearchEmty}
                            className="btn btn-dark"
                          >
                            <i className="fas fa-search-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}

export default FillDetailKren;
