import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableParayNagarawa from "./ScroollTableParayNagarawa";
import FillDetailParayNagarawa from "./FillDetailParayNagarawa";
import { ProductConsumer } from "../../../Context/AddProWakel";
import { Link } from "react-router-dom";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";
const AllParayNagarawa = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { RP_getPrint } = value;
          return (
            <>
              <div>
                <Navbar />
                {isMobile ? (
                  <AllMob />
                ) : (
                  <div style={{ marginTop: "50px" }}>
                    <div
                      className="row"
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                        <div className="col-md-12 ">
                        <FillDetailParayNagarawa />
                      </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 ">
                        <ScroollTableParayNagarawa />
                      </div>
                    </div>
                    <div
                      className="row "
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <div
                          className="col-lg-12 col-md-12"
                        style={{ backgroundColor: "#2e3541 " }}
                      >
                        <br />
                        <button
                          onClick={RP_getPrint}
                          className="btn btn-warning btn-lg"
                        >
                          <Link to="/print-paraynagarawa">Print</Link>
                        </button>
                        <br />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default AllParayNagarawa;
