import React from 'react'
import {ProductConsumer} from '../../Context/AddProWakel'
const FillAddUser = () => {
  return (
    <>
    <ProductConsumer>
    {value=>{
        const {AddProChange,A_bakarhenar,A_zh_mobile,onSaveAddUser,
               radiochange,A_password,clear_edit,updtae_User,edit,A_newPassword
              } = value
        return(
          <>
            <div className="col-md-12 col-md-10 col-lg-5">
                <div className="card card-primary">
              <div className="card-header"style={{backgroundColor:"#2C3539"}}>
                <h4 className="card-title tc text-warning font-weight-bold">زیادکردنی بەکارهینەر</h4>
              </div>

              <form style={{color:"#2C3539"}}>
                <div className="card-body text-right">
                  <div className="form-group">
                    <label className='font-weight-bold f4'>:ناوی بەکارهێنەر</label>
                    <input type="text" name="A_bakarhenar" value={A_bakarhenar}   onChange={AddProChange} className="form-control text-right font-weight-bold"  placeholder="...تۆمار کردن"/>
                  </div>
                  <div className="form-group">
                   {edit === null ?
                    <>
                    <label className='font-weight-bold f4'>:وشەی نهێنی</label>
                    <input type="password" value={A_password} name='A_password' onChange={AddProChange} className="form-control text-right font-weight-bold"  placeholder="...تۆمار کردن"/>
                    </>
                    :
                    <>
                     <label className='font-weight-bold f4'>:وشەی نهێنی کۆن</label>
                    <input type="password" value={A_password} name='A_password' onChange={AddProChange} className="form-control text-right font-weight-bold"  placeholder="...تۆمار کردن"/>
                    <label className='font-weight-bold f4'>: وشەی نهێنی نوێ</label>
                    <input type="password" value={A_newPassword} name='A_newPassword' onChange={AddProChange} className="form-control text-right font-weight-bold"  placeholder="...تۆمار کردن"/>
                  </>
                    }
                  </div>

                  <div className="form-group">
                    <label className='font-weight-bold f4'>:ژ.مۆبایەل</label>
                    <input type="text" name="A_zh_mobile" value={A_zh_mobile===0?'':A_zh_mobile}  onChange={AddProChange} className="form-control text-right font-weight-bold"  placeholder="...تۆمار کردن"/>
                  </div>
                    <div className="col-md-6 text-right font-weight-bold f4 form-check-inline ml-5" onChange={radiochange} >
                    <div className="form-check">
                      <input  className="form-check-input" type="radio" value='6' name="role"/>
                      <label className="form-check-label">User</label>
                    </div>
                    <div className="form-check ml5">
                      <input className="form-check-input" type="radio"  value='7' name="role" />
                      <label className="form-check-label">Admin</label>
                    </div>
                    </div>
                </div>


                <div className="card-footer" style={{backgroundColor:"#2C3539"}}>
                  {edit === null ?
                  <button type="button" onClick={onSaveAddUser} className="btn btn-warning fk">تۆمار کردن</button>:
                  <>
                  <button type="button" onClick={(e)=>updtae_User(e,edit)} className="btn btn-primary">گۆڕین</button>
                  <button type="button" onClick={clear_edit} className="btn btn-primary ml3"><i className="fas fa-window-close "></i></button>
                  </>
                  }
                </div>
              </form>
            </div>
            </div>
            </>
                    )

                  }}
  
              </ProductConsumer>

    </>)
}
export default FillAddUser
