import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import "./Search.css";
class OutSale extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              R_dataTable,
              AddProChange,
              R_darzan,
              R_nrx,
              R_naweSht,
              createNewPage,
              R_open,
              R_wasl,
              R_del_dis,
              R_table,
              R_saveDetail,
              R_kren,
              toanotherInput,
              R_nrxchange,
              R_darzanChangeSfr,
            } = value;
            return (
              <>
                <form>
                  <div
                    className="row text-warning font-weight-bold"
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <div className="col-3 text-right px-2"></div>
                    <div className="col-1 text-right px-2 mt4">
                      <button
                        onClick={createNewPage}
                        disabled={
                          R_table === true || R_open === true ? true : null
                        }
                        className={`btn ${R_open === true || R_table === true
                          ? "btn-danger"
                          : "btn-primary"
                          } mr2`}
                      >
                        <i className="far fa-file fa-lg"></i>
                      </button>
                      <button
                        onClick={(e) => R_saveDetail(e, R_wasl)}
                        className="btn btn-success"
                      >
                        <i className="far fa-save"></i>
                      </button>
                    </div>

                    <div className="col-1 text-right px-2">
                      <div className="form-group">
                        <label>نرخ</label>
                        <input
                          type="text"
                          disabled={R_del_dis === true ? true : null}
                          value={R_nrx}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? R_saveDetail(event) : null
                          }
                          name="R_nrx"
                          onChange={R_nrxchange}
                          className="form-control text-right font-weight-bold pr-4"
                          style={{ padding: "20px 0px" }}
                        />
                      </div>
                    </div>
                    <div className="col-1 text-right px-2">
                      <div className="form-group">
                        <label>کڕین</label>
                        <input
                          type="text"
                          disabled={R_del_dis === true ? true : null}
                          value={R_kren}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          name="R_kren"
                          onChange={AddProChange}
                          className="form-control text-right font-weight-bold pr-4"
                          style={{ padding: "20px 0px" }}
                        />
                      </div>
                    </div>
                    <div className="col-1 text-right px-2">
                      <div className="form-group">
                        <label>مەتر/دانە</label>
                        <input
                          type="text"
                          disabled={R_del_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={AddProChange}
                          onClick={R_darzanChangeSfr}
                          value={R_darzan === 0 ? "" : R_darzan}
                          name="R_darzan"
                          className="form-control text-right font-weight-bold pr-4"
                          style={{ padding: "20px 5px" }}
                        />
                      </div>
                    </div>

                    <div className="col-3 text-right">
                      <div className="form-group">
                        <label>ناوی شتەکان</label>
                        <input
                          type="text"
                          disabled={R_del_dis === true ? true : null}
                          value={R_naweSht}
                          onChange={AddProChange}
                          name="R_naweSht"
                          autocomplete="off"
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          className="form-control text-right font-weight-bold"
                          style={{ padding: "20px 5px" }}
                        />
                      </div>
                    </div>
                    <div className="col-1 text-right px-2">
                      <span
                        style={{ fontSize: "20px" }}
                        className="text-warning"
                      >
                        #{R_dataTable.length !== 0 ? R_dataTable.length : ""}
                      </span>
                    </div>
                  </div>
                </form>
              </>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}

export default OutSale;
