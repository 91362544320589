import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import Time from "react-time-format";
import "./Search.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import OutSale from "./OutSale";
class FillDetailRent extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              R_frosth,
              R_dataTable,
              AddProChange,
              R_karton,
              R_darzan,
              R_nrx,
              R_zhmara,
              R_naweSht,
              R_rezha,
              DarzanChange,
              R_CartonChange,
              tofirstInputINRent,
              R_hanldeKeydown,
              R_hanldeKeyup,
              createNewPage,
              R_date,
              R_mob,
              R_nawe_barez,
              R_zh_barez,
              R_zh_wasl,
              R_zh_wasl_katy,
              R_anotherInputWakil,
              inputtogler,
              R_open,
              getWaslDataBySearchRent,
              R_getkoygahty,
              getProductDataBySearchRent,
              R_wasl,
              R_search_wakil,
              R_search,
              R_del_dis,
              R_togler,
              R_table,
              saveDetail,
              R_joripara,
              R_wargr,
              R_nrx_dolar,
              R_nrx_dinarr,
              R_nrx_dolar2,
              R_nrx_dolar3,
              R_rasidKarton,
              R_search_wasl,
              R_save,
              R_qazanj,
              R_kren,
              R_nrxkaton,
              setSearchEmptyRentWakil,
              setSaletSearchEmty,
              W_wakelakan,
              toanotherInput,
              R_nrxchange,
              R_darzankartontoanotherInput,
              R_joridolar,
              R_suggestionSelected,
              R_handleListKeydown,
              R_suggestions,
              Search_Rent_Handle,
              R_darzanChangeSfr,
              R_cursor,
              W_getwakelakan,
              R_Nrx_J_T_check,
              OnChangeSwitch,
            } = value;

            const wakilakan = W_wakelakan.filter((user) => {
              return user.Name.toString().startsWith(R_nawe_barez);
            }).map((item, index) => {
              if (R_frosth === "فرۆشتنی ڕاستەوخۆ") {
                return null;
              } else {
                return (
                  <option key={index} className="fk">
                    {item.Name}
                  </option>
                );
              }
            });

            return (
              <>
                <div style={{ marginTop: "3.5em" }}>
                  <form>
                    <div
                      className="row text-warning font-weight-bold f4"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <div className="col-md-3 form-inline">
                        <div className="col-md-7">
                          <label>:ناوی وەرگر</label>
                          <select
                            disabled={R_del_dis === true ? true : null}
                            className={`form-control text-right font-weight-bold selectpicker pull-right ${
                              R_wargr === "‌‌‌‌‌‌حاجی ڕەقیب"
                                ? "bg-success text-white"
                                : `${
                                    R_wargr === "حاجی رەمەزان"
                                      ? "bg-danger text-white"
                                      : ""
                                  }`
                            }`}
                            onChange={AddProChange}
                            name="R_wargr"
                            value={R_wargr !== null ? R_wargr : ""}
                          >
                            <option className="ml5">ناوی وەرگر</option>
                            <option>‌‌‌‌‌‌حاجی ڕەقیب</option>
                            <option>حاجی رەمەزان</option>
                          </select>
                        </div>
                        <div className=" col-md-5">
                          <label>:جۆری دراو</label>
                          <select
                            className={`form-control text-right font-weight-bold selectpicker pull-right ${
                              R_joripara === "دۆلار"
                                ? "bg-success text-white"
                                : `${
                                    R_joripara === "دینار"
                                      ? "bg-danger text-white"
                                      : ""
                                  }`
                            }`}
                            onChange={AddProChange}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? R_anotherInputWakil(event)
                                : null
                            }
                            name="R_joripara"
                            value={R_joripara !== null ? R_joripara : ""}
                          >
                            <option
                              className="ml5"
                              disabled={R_joripara !== null ? true : null}
                            >
                              جۆری دراو
                            </option>
                            <option
                              disabled={R_joripara === "دینار" ? true : null}
                            >
                              دۆلار
                            </option>
                            <option
                              disabled={R_joripara === "دۆلار" ? true : null}
                            >
                              دینار
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 text-right ">
                        <div className="form-group">
                          <label>:تەلەفۆن</label>
                          <input
                            type="number"
                            disabled={R_del_dis === true ? true : null}
                            value={R_mob}
                            name="R_mob"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? R_anotherInputWakil(event)
                                : null
                            }
                            onChange={AddProChange}
                            className="form-control text-right font-weight-bold"
                          />
                        </div>
                      </div>
                      <div className="col-md-2 text-right">
                        <div className="form-group">
                          <label>:گەڕان</label>
                          <select
                            disabled={R_del_dis === true ? true : null}
                            className="form-control  text-right font-weight-bold selectpicker pull-right "
                            onChange={AddProChange}
                            name="R_nawe_barez"
                            value={R_nawe_barez}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? R_anotherInputWakil(event)
                                : null
                            }
                          >
                            <option className="ml5">گەڕان</option>
                            {wakilakan}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 text-right">
                        <div className="form-group">
                          <label>:بەڕێز</label>
                          <input
                            type="text"
                            disabled={R_del_dis === true ? true : null}
                            value={R_nawe_barez}
                            name="R_nawe_barez"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? R_anotherInputWakil(event)
                                : null
                            }
                            onChange={W_getwakelakan}
                            className="form-control text-right font-weight-bold"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 text-right">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>:معمیل</label>
                              <input
                                type="text"
                                value={R_zh_barez > 0 ? R_zh_barez : ""}
                                disabled={
                                  R_search_wakil === true ||
                                  R_frosth === "فرۆشتنی ڕاستەوخۆ" ||
                                  R_frosth === null ||
                                  R_del_dis === true
                                    ? true
                                    : null
                                }
                                name="R_zh_barez"
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? R_anotherInputWakil(event)
                                    : null
                                }
                                onChange={AddProChange}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>
                          <div className="col-md-1 mr-3">
                            <button
                              onClick={setSearchEmptyRentWakil}
                              disabled={R_del_dis === true ? true : null}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              className="btn btn-dark mt4"
                            >
                              <i
                                onClick={setSearchEmptyRentWakil}
                                className="fas fa-search-plus"
                              ></i>
                            </button>
                          </div>
                          <div className="col-md-6">
                            <select
                              disabled={R_del_dis === true ? true : null}
                              className={`form-control text-right font-weight-bold selectpicker pull-right mt4 ml3 ${
                                R_frosth === "فرۆشتنی معمیل"
                                  ? "bg-success text-white"
                                  : `${
                                      R_frosth === "فرۆشتنی ڕاستەوخۆ"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              }`}
                              onChange={AddProChange}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              name="R_frosth"
                              value={R_frosth !== null ? R_frosth : ""}
                            >
                              <option className="ml5">جۆری فرۆشتن</option>
                              <option> فرۆشتنی معمیل</option>
                              <option> فرۆشتنی ڕاستەوخۆ</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="row">
                        <div
                          className={`${
                            R_togler === true ? "col-md-10" : "col-md-7"
                          } pt2  tc`}
                        >
                          {R_togler === false ? (
                            <>
                              {R_search_wasl === true || R_save === true ? (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={R_wasl.date}
                                    format="YYYY-MM-DD"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              ) : (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={Date.now()}
                                    format="YYYY-MM-DD"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              )}
                            </>
                          ) : (
                            <input
                              type="datetime-local"
                              name="R_date"
                              value={R_date}
                              onChange={AddProChange}
                              className="form-control text-right font-weight-bold tc btn btn-info"
                              style={{ margin: "0px 8px" }}
                            />
                          )}
                        </div>
                        {R_togler === true ? null : (
                          <div className="col-md-3">
                            <label className="text-warning font-weight-bold f4">
                              :بەروار{" "}
                            </label>
                          </div>
                        )}
                        <div className="col-md-2">
                          <button
                            onClick={inputtogler}
                            disabled={R_del_dis === true ? true : null}
                            className="btn btn-info"
                          >
                            <i className="far fa-calendar-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="row">
                        <div className="col-md-6 pt2  tc">
                          {R_togler === true ? null : (
                            <>
                              {R_search_wasl === true ? (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={R_wasl.date}
                                    format="hh:mm:ss"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              ) : (
                                <p className="font-weight-bold bg-white">
                                  <Time
                                    value={Date.now()}
                                    format="hh:mm:ss"
                                    style={{ color: "black" }}
                                  />
                                </p>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="text-warning font-weight-bold f4">
                            :کات{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <BootstrapSwitchButton
                        checked={R_Nrx_J_T_check}
                        onlabel="دوکان"
                        offlabel="خارجی"
                        onChange={OnChangeSwitch}
                        style="w-50 mx-3"
                      />
                    </div>
                    <div className="col-md-2 ">
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="number"
                            onChange={AddProChange}
                            disabled={
                              R_open === true || R_table === true ? true : null
                            }
                            value={R_zh_wasl}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? getWaslDataBySearchRent(event)
                                : null
                            }
                            name="R_zh_wasl"
                            className="form-control text-right font-weight-bold px-0"
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="text-warning font-weight-bold f4">
                            :پسوولە{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1 form-inline">
                      <h4 className=" text-dark font-weight-bold bg-white">
                        {R_zh_wasl_katy}
                      </h4>
                    </div>
                  </div>
                </div>

                {R_search === true ? (
                  <div className="row text-warning font-weight-bold ">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="qazanj"
                      >
                        Q$ {R_qazanj}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="kren"
                      >
                        K$ {R_kren}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="nrxyakkarton"
                      >
                        KK$ {R_nrxkaton}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="rasid"
                      >
                        R {R_rasidKarton}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="frosthndolar"
                      >
                        $1- {R_nrx_dolar}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="niwkarton"
                      >
                        $2- {R_nrx_dolar2}
                      </h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="Rkmtrladadrzan"
                      >{`$3- ${R_nrx_dolar3}`}</h6>
                    </div>
                    <div className="col-sm-1">
                      <h6
                        className="border b--black bg-white text-dark"
                        name="frosthndinar"
                      >
                        ID {R_nrx_dinarr}
                      </h6>
                    </div>
                  </div>
                ) : null}
                {R_Nrx_J_T_check ? (
                  <form>
                    <div
                      className="row text-warning font-weight-bold"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <div className="col-md-1 text-right px-2 mt4">
                        <button
                          onClick={createNewPage}
                          disabled={
                            R_table === true || R_open === true ? true : null
                          }
                          className={`btn ${R_open === true || R_table === true
                            ? "btn-danger"
                            : "btn-primary"
                            } mr2`}
                        >
                          <i className="far fa-file fa-lg"></i>
                        </button>
                        <button
                          onClick={(e) => saveDetail(e, R_wasl)}
                          className="btn btn-success"
                        >
                          <i className="far fa-save"></i>
                        </button>
                      </div>

                      <div className="col-md-1 text-right ">
                        <div className="form-group ">
                          {R_joripara === "دۆلار" ? (
                            <>
                              <label>$ کۆی گشتی </label>
                              <input
                                type="number"
                                disabled={R_del_dis === true ? true : null}
                                value={
                                  R_karton > 0 || R_darzan > 0
                                    ? R_getkoygahty(R_darzan, R_karton)
                                    : 0
                                }
                                onChange={AddProChange}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? saveDetail(event, R_wasl)
                                    : null
                                }
                                name="R_koygshty$"
                                className="form-control text-right font-weight-bold tc"
                              />
                            </>
                          ) : (
                            <>
                              <label>ID کۆی گشتی </label>
                              <input
                                type="number"
                                disabled={R_del_dis === true ? true : null}
                                value={
                                  R_karton > 0 || R_darzan > 0
                                    ? R_getkoygahty(R_darzan, R_karton)
                                    : 0
                                }
                                onChange={AddProChange}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? saveDetail(event, R_wasl)
                                    : null
                                }
                                name="R_koygshtyID"
                                className="form-control text-right font-weight-bold tc"
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-md-1 text-right px-2">
                        <div className="form-group">
                          <label>ڕێژە</label>
                          <input
                            type="number"
                            disabled={R_del_dis === true ? true : null}
                            value={R_rezha}
                            name="R_rezha"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? tofirstInputINRent(event)
                                : null
                            }
                            className="form-control text-right font-weight-bold"
                          />
                        </div>
                      </div>

                      <div className="col-md-1 text-right px-2">
                        <div className="form-group">
                          <label>نرخ</label>
                          <input
                            type="text"
                            disabled={R_del_dis === true ? true : null}
                            value={R_nrx}
                            onKeyPress={(event) =>
                              event.key === "Enter" ? toanotherInput(event) : null
                            }
                            name="R_nrx"
                            onChange={R_nrxchange}
                            className="form-control text-right font-weight-bold pr-4"
                          />
                        </div>
                      </div>

                      <div className="col-md-1 text-right px-2">
                        <div className="form-group">
                          <label>مەتر/دانە</label>
                          <input
                            type="text"
                            disabled={R_del_dis === true ? true : null}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? R_darzankartontoanotherInput(event)
                                : null
                            }
                            onChange={(e) => DarzanChange(e)}
                            onClick={R_darzanChangeSfr}
                            value={R_darzan === 0 ? "" : R_darzan}
                            name="R_darzan"
                            className="form-control text-right font-weight-bold pr-4"
                          />
                        </div>
                      </div>

                      <div className="col-md-1 text-right px-2">
                        <div className="form-group">
                          <label>لەفە/کارتۆن</label>
                          <input
                            type="text"
                            disabled={R_del_dis === true ? true : null}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? R_darzankartontoanotherInput(event)
                                : null
                            }
                            onChange={R_CartonChange}
                            value={R_karton === 0 ? "" : R_karton}
                            onClick={R_darzanChangeSfr}
                            name="R_karton"
                            className="form-control text-right font-weight-bold pr-4"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 text-right">
                        <div className="form-group1">
                          <label>ناوی شتەکان</label>
                          <input
                            type="text"
                            disabled={R_del_dis === true ? true : null}
                            value={R_naweSht}
                            onChange={Search_Rent_Handle}
                            onClick={setSaletSearchEmty}
                            name="R_naweSht"
                            autocomplete="off"
                            onKeyUp={R_hanldeKeyup}
                            onKeyDown={R_hanldeKeydown}
                            onKeyPress={(event) =>
                              event.key === "Enter" ? toanotherInput(event) : null
                            }
                            className="form-control text-right font-weight-bold"
                          />
                          {R_suggestions.length !== 0 && (
                            <>
                              <ul className="list-group">
                                {R_suggestions.map((item, idx) => (
                                  <li
                                    className={
                                      R_cursor === idx
                                        ? "active_search list-group-item"
                                        : "list-group-item"
                                    }
                                    key={idx}
                                    onClick={() => R_suggestionSelected(item._id)}
                                    onKeyDown={(evt) =>
                                      R_handleListKeydown(evt, item.code)
                                    }
                                  >
                                    {item.nameofproduct}
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-md-1 text-right">
                        <div className="form-group">
                          <label>کۆد</label>
                          <input
                            type="text"
                            disabled={R_del_dis === true ? true : null}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? getProductDataBySearchRent(event)
                                : null
                            }
                            value={R_zhmara}
                            onClick={setSaletSearchEmty}
                            onChange={AddProChange}
                            name="R_zhmara"
                            className="form-control text-right font-weight-bold pr-4"
                          />
                        </div>
                      </div>
                      <div className="col-md-2 text-right px-2">
                        <div className="row">
                          <div className="col-md-8">
                            <label>نرخی دۆلار</label>
                            <select
                              className="form-control text-right font-weight-bold selectpicker pull-right "
                              disabled={R_joripara === "دینار" ? true : null}
                              onChange={AddProChange}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              name="R_joridolar"
                              value={R_joridolar !== null ? R_joridolar : ""}
                            >
                              <option
                                className="ml5"
                                disabled={R_joridolar !== null ? true : null}
                              >
                                $$$$
                              </option>
                              <option
                                disabled={
                                  R_joridolar === "2 دۆلار" ||
                                    R_joridolar === "3 دۆلار"
                                    ? true
                                    : null
                                }
                              >
                                1 دۆلار
                              </option>
                              <option
                                disabled={
                                  R_joridolar === "1 دۆلار" ||
                                    R_joridolar === "3 دۆلار"
                                    ? true
                                    : null
                                }
                              >
                                2 دۆلار
                              </option>
                              <option
                                disabled={
                                  R_joridolar === "1 دۆلار" ||
                                    R_joridolar === "2 دۆلار"
                                    ? true
                                    : null
                                }
                              >
                                3 دۆلار
                              </option>
                            </select>
                          </div>
                          <div className="text-warning col-md-2 display-3 mt-4 fk">
                            <span
                              style={{ fontSize: "20px" }}
                              className="text-warning"
                            >
                              #
                              {R_dataTable.length !== 0 ? R_dataTable.length : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                ) : (
                  <OutSale />
                )}

              </>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}

export default FillDetailRent;
