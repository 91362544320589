import React from "react";
import FooterOfKren from "./FooterOfKren";
import TableScroollKren from "./TableScroollKren";

const TableAllKren = () => {
  return (
    <body>
      <TableScroollKren />
      <br />
      <br />
      <FooterOfKren />
      <footer className="content-block text-right btn btn-black f4"></footer>
    </body>
  );
};

export default TableAllKren;
