import React from "react";
import Navbar from "../../../Navbar/Navbar";
import FormParadan from "./FormParadan";

const Paradan = () => {
  return (
    <>
      <Navbar />
      <div className="mt5"></div>
      <FormParadan />
    </>
  );
};

export default Paradan;
