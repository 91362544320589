import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import FormMob from "./FormMob";
import { Link } from "react-router-dom";

const WakilMob = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            onSaveWakel,
            W_nawneshan,
            getPrint,
            W_show,
            W_tofirstInput,
            W_showrasid,
            W_mob_Wakel,
            W_naw,
            W_code,
            clear_edit,
            edit,
            updtae_Wakil,
            W_error,
            W_ClearError,
            setWakilEmpty,
            role,
            L_user,
            getWakilDataBySearchWakil,
          } = value;
          return (
            <>
              <form>
                <div className="mt5">
                  <h1
                    className="tc border p-3  border-warning text-warning"
                    style={{ backgroundColor: "#2e3541 " }}
                  >
                    تۆمارکردنی ناوی وەکیلەکان
                  </h1>
                  <div className="b--black text-right">
                    {W_error !== null && (
                      <button
                        className="btn btn-danger text-right"
                        type="button"
                      >
                        {W_error.msg ? W_error.msg : W_error.error[0].msg}
                        <span
                          className="ml-2  border-black"
                          onClick={() => W_ClearError()}
                        >
                          X
                        </span>
                      </button>
                    )}
                  </div>
                  <div
                    className="row "
                    style={{ color: "#000C66" }}
                    onChange={AddProChange}
                  >
                    <div className="col-md-2 text-right  px-2 ">
                      <div className="form-group">
                        <label className="font-weight-bold">کۆد</label>
                        <input
                          type="number"
                          value={W_code === 0 ? "" : W_code}
                          name="W_code"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? getWakilDataBySearchWakil(event)
                              : null
                          }
                          onChange={AddProChange}
                          className="form-control  text-right font-weight-bold"
                        />
                      </div>
                    </div>
                    <div className="col-md-2 text-right px-2 ">
                      <div className="form-group">
                        <label className="font-weight-bold">ناو</label>
                        <input
                          type="text"
                          value={W_naw}
                          name="W_naw"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? getWakilDataBySearchWakil(event)
                              : null
                          }
                          onChange={AddProChange}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-md-2 text-right px-2 ">
                      <div className="form-group">
                        <label className="font-weight-bold">مۆبایل</label>
                        <input
                          type="number"
                          value={W_mob_Wakel > 0 ? W_mob_Wakel : ""}
                          name="W_mob_Wakel"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? getWakilDataBySearchWakil(event)
                              : null
                          }
                          onChange={AddProChange}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>
                    <div className="col-md-2 text-right px-2 ">
                      <div className="form-group">
                        <label className="font-weight-bold">ناونیشان</label>
                        <input
                          type="text"
                          value={W_nawneshan}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? W_tofirstInput(event) : null
                          }
                          name="W_nawneshan"
                          onChange={AddProChange}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-md-12 px-2 mt2 mb2">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-2">
                          {role === "admin" ? (
                            W_show === false ? (
                              <button
                                onClick={(e) => W_showrasid(e, L_user._id)}
                                className="btn btn-primary"
                                style={{ backgroundColor: "orange" }}
                              >
                                <i className="fas fa-eye"></i>
                              </button>
                            ) : (
                              <button
                                onClick={W_showrasid}
                                className="btn btn-primary"
                                style={{ backgroundColor: "orange" }}
                              >
                                <i className="fas fa-eye-slash"></i>
                              </button>
                            )
                          ) : null}
                        </div>
                        <div className="col-md-2">
                          <Link to="/print-wakel">
                            <button
                              onClick={getPrint}
                              className="btn btn-primary"
                              style={{ backgroundColor: "#9900ff" }}
                            >
                              <i className="fas fa-print"></i>
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-2">
                          {edit !== null ? (
                            <button
                              onClick={clear_edit}
                              className="btn btn-danger"
                            >
                              <i className="fas fa-window-close"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) => setWakilEmpty(e)}
                              className="btn btn-primary"
                            >
                              <i className="fas fa-file"></i>
                            </button>
                          )}
                        </div>
                        <div className="col-md-2">
                          {edit !== null ? (
                            <button
                              onClick={(e) => updtae_Wakil(e, edit)}
                              className="btn btn-primary"
                              style={{ backgroundColor: "navy" }}
                            >
                              <i className="far fa-edit"></i>
                            </button>
                          ) : (
                            <button
                              onClick={onSaveWakel}
                              className="btn btn-success"
                            >
                              <i className="far fa-save"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-md-12">
                  <FormMob />
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default WakilMob;
