import React from "react";
import SearchParadan from "./SearchParadan";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Time from "react-time-format";

const FormParadan = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            PD_zhamara,
            PD_joredraw,
            PD_zhmaraybarez,
            PD_openUpdate,
            PD_search_wasl,
            Pd_barwar,
            PD_del_dis,
            PD_wasleShareka,
            PD_nawebarez,
            PD_parayesta$,
            PD_parayestaID,
            PD_onSaveMoney,
            PD_getParaDataBySearchMoney,
            PD_setSearchMonyWakilEmpty,
            PD_brepara,
            PD_getPrint,
            PD_getkasDataBySearchMoney,
            PD_createNewMony,
            PD_toggler,
            toanotherInput,
            PD_qardekonID,
            PD_qardekon$,
            PD_save,
            PD_nawewargr,
            PD_open,
            L_user,
            PD_wasl,
            PD_updtate_para,
            PD_pageminsone,
            PD_pageplusone,
            PD_inputtogler,
          } = value;
          const renderdate = (kat) => {
            const monthname = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ];
            const date = new Date(kat);

            return `${date.getDate()}/${
              monthname[date.getMonth()]
            }/${date.getFullYear()}`;
          };
          return (
            <>
              <div className="row ">
                <div className="col-md-1"></div>
                <div className="col-md-12 col-lg-4 ">
                  {/* Search take money */}
                  <SearchParadan />
                </div>
                <div className="col-md-1"></div>
                {/* Form take moneyy */}
                <div className="col-lg-6 text-warning col-md-12 ">
                  <div
                    className="card card-warning "
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <div
                      className="card-header p-1 border-bottom border-warning"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <h2 className="card-title tc">پارە دان</h2>
                    </div>

                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-3 text-right mt2">
                            <button
                              disabled={PD_del_dis === true ? true : null}
                              onClick={PD_inputtogler}
                              className="btn btn-info mt4"
                            >
                              <i className="far fa-calendar-alt"></i>
                            </button>
                          </div>

                          <div
                            className={`${
                              PD_toggler === true
                              ? "col-sm-6 text-right"
                              : "col-sm-3 text-right"
                            }`}
                          >
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بەروار
                              </label>
                              {PD_toggler === false ? (
                                <input
                                  type="text"
                                  name="Pd_barwar"
                                  value={
                                    PD_search_wasl === true || PD_save === true
                                      ? renderdate(Pd_barwar)
                                      : renderdate(Date.now())
                                  }
                                  className="form-control text-right font-weight-bold"
                                />
                              ) : (
                                <input
                                  type="datetime-local"
                                  name="Pd_barwar"
                                  value={Pd_barwar}
                                  onChange={AddProChange}
                                  className="form-control text-right font-weight-bold tc btn btn-info"
                                  style={{ margin: "0px 8px" }}
                                />
                              )}
                            </div>
                          </div>
                          {PD_toggler === false ? (
                            <div className="col-sm-3 text-right">
                              <div className="form-group">
                                <label className="font-weight-bold f4">
                                  کات
                                </label>
                                {PD_search_wasl === true || PD_save === true ? (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={Pd_barwar}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                ) : (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={Date.now()}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : null}

                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژمارە
                              </label>
                              <input
                                type="text"
                                name="PD_zhamara"
                                disabled={PD_del_dis === true ? true : null}
                                value={PD_zhamara}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? PD_getParaDataBySearchMoney(event)
                                    : null
                                }
                                onChange={AddProChange}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>

                          <div className="col-sm-4 text-right"></div>

                          <div className="col-sm-4 text-right p-2 border-bottom border-warning">
                            <div className="form-group ">
                              <label className="font-weight-bold f4">
                                ID ڕەسیدی ئێستا بە{" "}
                              </label>
                              <NumberFormat
                                value={PD_parayestaID}
                                displayType={"text"}
                                className="form-control f4 text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-danger"
                                thousandSeparator={true}
                                prefix={"ID"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 text-right p-2 border-bottom border-warning">
                            <div className="form-group">
                              <label className="font-weight-bold f4 ">
                                $ ڕەسیدی ئێستا بە{" "}
                              </label>
                              <NumberFormat
                                value={PD_parayesta$}
                                displayType={"text"}
                                className="form-control f4 text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-success"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>

                          <br />
                          <br />
                          <br />
                          <br />

                          <div className="col-md-3 text-right ">
                            <div className="row">
                              <div className="col-md-4"></div>
                              <div className="col-md-4">
                                <div className="form-group ">
                                  <button
                                    disabled={PD_del_dis === true ? true : null}
                                    onClick={PD_setSearchMonyWakilEmpty}
                                    className="btn btn-dark"
                                  >
                                    <i className="fas fa-search-plus"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                          </div>

                          <div className="col-sm-6 text-right mt-2">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ناوی بەرێز
                              </label>
                              <input
                                type="text"
                                name="PD_nawebarez"
                                disabled={PD_del_dis === true ? true : null}
                                onChange={AddProChange}
                                value={PD_nawebarez}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>

                          <div className="col-md-3 text-right mt-2">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژ.بەڕێز
                              </label>
                              <input
                                type="text"
                                name="PD_zhmaraybarez"
                                onClick={PD_setSearchMonyWakilEmpty}
                                disabled={PD_del_dis === true ? true : null}
                                onChange={AddProChange}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? PD_getkasDataBySearchMoney(event)
                                    : null
                                }
                                value={
                                  PD_zhmaraybarez === 0 ? "" : PD_zhmaraybarez
                                }
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژمارەی پسوولە
                              </label>
                              <input
                                type="text"
                                name="PD_wasleShareka"
                                disabled={PD_del_dis === true ? true : null}
                                onChange={AddProChange}
                                value={
                                  PD_wasleShareka === 0 ? "" : PD_wasleShareka
                                }
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>

                          <div className="col-sm-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ID قەرزی ئەستۆ بە{" "}
                              </label>
                              <NumberFormat
                                value={PD_qardekonID}
                                displayType={"text"}
                                className="form-control text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"ID"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                $ قەرزی ئەستۆ بە{" "}
                              </label>
                              <NumberFormat
                                value={PD_qardekon$}
                                displayType={"text"}
                                className="form-control text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 text-right">
                            <label className="font-weight-bold f4">
                              ناوی وەرگر
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                PD_nawewargr === "‌‌‌‌‌‌حاجی ڕەقیب"
                                  ? "bg-success text-white"
                                  : `${
                                      PD_nawewargr === "حاجی رەمەزان"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              disabled={PD_del_dis === true ? true : null}
                              onChange={AddProChange}
                              name="PD_nawewargr"
                              value={PD_nawewargr !== null ? PD_nawewargr : ""}
                            >
                              <option className="ml5">...ناوی وەرگر</option>
                              <option>‌‌‌‌‌‌حاجی ڕەقیب</option>
                              <option>حاجی رەمەزان</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right">
                            <label className="font-weight-bold f4">
                              جۆری دراو
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                PD_joredraw === "دۆلار"
                                  ? "bg-success text-white"
                                  : `${
                                      PD_joredraw === "دینار"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              disabled={PD_del_dis === true ? true : null}
                              onChange={AddProChange}
                              name="PD_joredraw"
                              value={PD_joredraw !== null ? PD_joredraw : ""}
                            >
                              <option className="ml5">..جۆری دراو</option>
                              <option>دۆلار</option>
                              <option>دینار</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بڕی پارەدان
                              </label>
                              <NumberFormat
                                displayType="input"
                                thousandSeparator={true}
                                name="PD_brepara"
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? toanotherInput(event)
                                    : null
                                }
                                onChange={AddProChange}
                                disabled={PD_del_dis === true ? true : null}
                                value={PD_brepara === 0 ? "" : PD_brepara}
                                className="form-control text-right font-weight-bold bg-info text-white"
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-1 text-right"></div> */}
                          <div className="col-sm-8 ">
                            <button
                              className="btn btn-dark p3 px-4"
                              style={{
                                backgroundColor: "#00695c",
                                border: "1px solid #00695c",
                              }}
                              disabled={PD_open === true ? true : null}
                              onClick={PD_pageminsone}
                            >
                              <i className="fas fa-caret-left f4"></i>
                            </button>
                            <button
                              className="btn btn-dark p3 px-4 ml3"
                              style={{
                                backgroundColor: "#00695c",
                                border: "1px solid #00695c",
                              }}
                              disabled={PD_open === true ? true : null}
                              onClick={PD_pageplusone}
                            >
                              <i className="fas fa-caret-right f4"></i>
                            </button>
                            {PD_search_wasl === true ? (
                              <>
                                {PD_open === null ? (
                                  <button
                                    onClick={(e) =>
                                      PD_openUpdate(L_user._id, e)
                                    }
                                    className="btn btn-md btn-warning px-4 ml3"
                                    style={{
                                      backgroundColor: "silver",
                                      border: "1px solid silver",
                                    }}
                                  >
                                    <i className="fas fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    onClick={(e) =>
                                      PD_updtate_para(
                                        e,
                                        PD_wasl,
                                        PD_wasl.kascode
                                      )
                                    }
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{ backgroundColor: "navy" }}
                                  >
                                    <i className="fas fa-edit "></i>
                                  </button>
                                )}
                                <Link to="/print-paradan">
                                  <button
                                    onClick={PD_getPrint}
                                    type="button"
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{
                                      backgroundColor: "purple",
                                      border: "1px solid purple",
                                    }}
                                  >
                                    <i className="fas fa-print fa-lg"></i>
                                  </button>
                                </Link>
                              </>
                            ) : PD_save === null ? (
                              <button
                                type="button"
                                onClick={PD_onSaveMoney}
                                className="btn btn-md btn-success px-4 ml3"
                              >
                                <i className="far fa-save fa-lg"></i>
                              </button>
                            ) : null}
                            <button
                              type="button"
                              onClick={PD_createNewMony}
                              disabled={PD_open === true ? true : null}
                              className="btn btn-md btn-primary px-4 ml3"
                            >
                              <i className="far fa-file fa-lg"></i>
                            </button>
                            <Link to="/print-paradan">
                              {PD_save === true ? (
                                <>
                                  <button
                                    onClick={PD_getPrint}
                                    type="button"
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{
                                      backgroundColor: "purple",
                                      border: "1px solid purple",
                                    }}
                                  >
                                    <i className="fas fa-print fa-lg"></i>
                                  </button>
                                  {PD_open === null ? (
                                    <button
                                      onClick={(e) =>
                                        PD_openUpdate(L_user._id, e, PD_wasl)
                                      }
                                      className="btn btn-md btn-warning px-4 ml3"
                                      style={{
                                        backgroundColor: "silver",
                                        border: "1px solid silver",
                                      }}
                                    >
                                      <i class="fas fa-lock"></i>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={(e) =>
                                        PD_updtate_para(
                                          e,
                                          PD_wasl,
                                          PD_wasl.kascode
                                        )
                                      }
                                      className="btn btn-md btn-primary px-4 ml3"
                                      style={{ backgroundColor: "navy" }}
                                    >
                                      <i className="fas fa-edit "></i>
                                    </button>
                                  )}
                                </>
                              ) : null}
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default FormParadan;
