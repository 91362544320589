import React from 'react'
import Navbar from '../../../Navbar/Navbar'
import FormMandub from './FormMandub'

const Mandub = () => {
    return (
        <>
            <Navbar/>
            <div className="mt5"></div>
            <FormMandub/>
            
            
        </>
    )
}

export default Mandub
