import React from "react";
import Navbar from "../../../Navbar/Navbar";
import PeopleSecond from "./PeopleSecond";
import WakilMob from "./Mob/WakilMob";
import { isMobile } from "react-device-detect";

const InputPeople = () => {
  return (
    <>
      <Navbar />
      <div className="mt5"></div>
      {isMobile ? <WakilMob /> : <PeopleSecond />}
    </>
  );
};

export default InputPeople;
