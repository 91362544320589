import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";

class FormPrintRaportyRozhana extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { d_dataRozhana } = value;

            const wakil = d_dataRozhana.map((user, i) => {
              return (
                <tr
                  key={i}
                  className="f4 fk"
                  style={{
                    borderBottom: "2px solid black",
                    borderTop: "2px solid black",
                    borderRight: "2px solid black",
                    borderLeft: "2px solid black",
                  }}
                >
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1em",
                      color: "black",
                    }}
                  >
                    {user.joripara}
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1em",
                      color: "black",
                    }}
                  >
                    {user.koywasl}
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1em",
                      color: "black",
                    }}
                  >
                    {user.jwlla}
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1em",
                      color: "black",
                    }}
                  >
                    {user.zh_wakil}
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1em",
                      color: "black",
                    }}
                  >
                    {user.nawi_wakil}
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1em",
                      color: "black",
                    }}
                  >
                    <Time
                      value={user.barwar}
                      format="YYYY-MM-DD"
                      style={{ color: "black" }}
                    />
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1em",
                      color: "black",
                    }}
                  >
                    {user.code}
                  </td>
                </tr>
              );
            });

            return (
              <table id="example" className="table table-striped  text-right ">
                <thead>
                  <tr
                    className="text-black f3 fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                    }}
                  >
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      دراو
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      کۆی پسوولە
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      جووڵە
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      کۆد
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      ناو
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      بەروار
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      ژ.پسوولە
                    </th>
                  </tr>
                </thead>
                <tbody>{wakil}</tbody>
              </table>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default FormPrintRaportyRozhana;
