import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const AddproductCinRight = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const {
          AddProChange,
          RC_zhmarayKala,
          RC_zhmaaryDukan,
          RC_nrxeNavR,
          RC_nrxNavM,
          RC_dabash,
          RC_rezhaynawKarton,
          RC_nrxRRR,
          tofirstInput,
          RC_koygshtyR,
          RC_koygshty$,
          RC_drezhe,
          RC_pane,
          RC_barze,
          RC_koymatr$,
          RC_nrxekotayeKala$,
          RC_kren,
          RC_froshtn,
          RC_qazanj,
          toanotherInput,
          RC_zhmaaryPswla,
          RC_getnavbarRemembe,
          RC_getnavbarMatr,
          RC_R_disaebl,
          tofirstInpuKalla,
          RC_newChina,
          RC_search,
          RC_wasl,
          RC_update,
          RC_M_desaebl,
          RRRchange,
          RC_barzichnage,
          RC_saveToTable,
          tofirstInpuMatr,
          RC_search_wasl,
          RC_froshtnChange,
          RC_updateNawKalla,
          RC_newpage,
          RC_kalladata,
          RC_open,
          L_user,
          RC_openchina,
          RC_save,
          RC_updatekala,
          RC_matrremembychnage,
          RC_kreneakcartoon,
          RC_froshtneakKarton,
          RC_dis,
          RC_froshtnID,
          RC_kamtrlaDadarzan,
          RC_kamtrlaNewkarton,
          RC_nawikalla,
          RC_setInputEmpty,
          RC_qazanjchange,
          RC_suggestionSelected,
          Search_Raporty_cin_Handle,
          RC_suggestions,
        } = value;
        const renderSuggestions = () => {
          if (RC_suggestions.length === 0) {
            return null;
          }
          return (
            <div className="srchList">
              <ul className="uly">
                {RC_suggestions.map((item) => (
                  <li onClick={() => RC_suggestionSelected(item._id,item.nameofproduct)}>
                    {item.nameofproduct}
                  </li>
                ))}
              </ul>
            </div>
          );
        };
        return (
          <div className=" col-md-12 col-md-12  col-lg-7 ">
            <div
              className="card card-warning"
              style={{ backgroundColor: "#2C3539" }}
            >
              <div
                className="card-header p-1"
                style={{ backgroundColor: "#2C3539" }}
              >
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-4">
                    {" "}
                    <h3 className="card-title tc text-warning">کڕینی کاڵا</h3>
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-4 mt-2">
                    <input
                      type="text"
                      onChange={Search_Raporty_cin_Handle}
                      name="RC_nawikalla"
                      disabled={RC_dis === true ? true : null}
                      value={RC_nawikalla === 0 ? "" : RC_nawikalla}
                      className="form-control text-right font-weight-bold"
                      placeholder="ناوی کاڵا"
                    />
                    <div className="justify-content-md-center text-center">
                      {renderSuggestions()}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body text-warning f4 border border-warning">
                <form onChange={AddProChange}>
                  <div className="row">
                    <div className="col-sm-3 text-right ">
                      <div className="row ">
                        <div className="col-md-8 ">
                          <label className="font-weight-bold">M نرخی </label>
                          <input
                            type="text"
                            name="RC_nrxNavM"
                            disabled={RC_dis === true ? true : null}
                            onKeyPress={(event) =>
                              event.key === "Enter" ? tofirstInput(event) : null
                            }
                            onChange={RC_matrremembychnage}
                            disabled={RC_M_desaebl === true ? true : null}
                            value={RC_nrxNavM === 0 ? "" : RC_nrxNavM}
                            className="form-control text-right font-weight-bold"
                          />
                        </div>
                        <div className="col-md-4 mt2">
                          <label className="font-weight-bold"> </label>
                          <button
                            onClick={RC_getnavbarMatr}
                            disabled={RC_dis === true ? true : null}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? toanotherInput(event)
                                : null
                            }
                            className="btn btn-danger"
                          >
                            {RC_M_desaebl === true ? (
                              <i class="fas fa-times-circle"></i>
                            ) : (
                              <i class="fas fa-check"></i>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3 text-right ">
                      <div className="row ">
                        <div className="col-md-8 ">
                          <label className="font-weight-bold">R نرخی </label>
                          <input
                            type="text"
                            name="RC_nrxeNavR"
                            disabled={RC_dis === true ? true : null}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? toanotherInput(event)
                                : null
                            }
                            onChange={RC_matrremembychnage}
                            disabled={RC_R_disaebl === true ? true : null}
                            value={RC_nrxeNavR === 0 ? "" : RC_nrxeNavR}
                            className="form-control text-right font-weight-bold"
                          />
                        </div>
                        <div className="col-md-4 mt2">
                          <label className="font-weight-bold"> </label>
                          <button
                            onClick={RC_getnavbarRemembe}
                            disabled={RC_dis === true ? true : null}
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? toanotherInput(event)
                                : null
                            }
                            className="btn btn-danger"
                          >
                            {RC_R_disaebl === true ? (
                              <i class="fas fa-times-circle"></i>
                            ) : (
                              <i class="fas fa-check"></i>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">ژ.پسوولە</label>
                        <input
                          type="text"
                          name="RC_zhmaaryPswla"
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={AddProChange}
                          value={RC_zhmaaryPswla === 0 ? "" : RC_zhmaaryPswla}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">ژ.دوکان</label>
                        <input
                          type="text"
                          name="RC_zhmaaryDukan"
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={AddProChange}
                          value={RC_zhmaaryDukan === 0 ? "" : RC_zhmaaryDukan}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    {/* <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className='font-weight-bold'>ژ.کاڵا</label>
                        <input type="text" name='RC_zhmarayKala' disabled={RC_dis === true?true:null} onKeyPress={event=>event.key==='Enter'?RC_search(event):null} onChange={AddProChange}  value={RC_zhmarayKala===0?'':RC_zhmarayKala}  className="form-control text-right font-weight-bold" />
                      </div>
                    </div> */}
                  </div>
                  {/* second Row */}
                  <div className="row">
                    <div className="col-sm-3 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">$ کۆی گشتی </label>
                        <input
                          type="text"
                          name="RC_koygshty$"
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? tofirstInpuMatr(event)
                              : null
                          }
                          value={RC_koygshty$ === 0 ? "" : RC_koygshty$}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-3 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">R کۆی گشتی </label>
                        <input
                          type="text"
                          name="RC_koygshtyR"
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          value={RC_koygshtyR === 0 ? "" : RC_koygshtyR}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">RRR نرخی </label>
                        <input
                          type="text"
                          name="RC_nrxRRR"
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={RRRchange}
                          value={RC_nrxRRR === 0 ? "" : RC_nrxRRR}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">دابەش</label>
                        <input
                          type="text"
                          name="RC_dabash"
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={AddProChange}
                          value={RC_dabash === 0 ? "" : RC_dabash}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">ڕ.ناو کارتۆن</label>
                        <input
                          type="text"
                          name="RC_rezhaynawKarton"
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          onChange={AddProChange}
                          value={
                            RC_rezhaynawKarton === 0 ? "" : RC_rezhaynawKarton
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Third Row */}
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-5"></div>
                    <div className="col-md-3">
                      <h3 className=" border-bottom border-warning text-right">
                        درێژی مەتر
                      </h3>
                    </div>
                  </div>

                  {/* ford Row */}
                  <div className="row">
                    <div className="col-sm-1 text-right mt4">
                      <button
                        onClick={RC_saveToTable}
                        disabled={RC_dis === true ? true : null}
                        className="btn btn-warning mt1"
                      >
                        <i className="far fa-save fa-lg"></i>
                      </button>
                    </div>

                    <div className="col-sm-3 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">
                          $ نرخی کۆتای کاڵا{" "}
                        </label>
                        <input
                          type="text"
                          name="RC_nrxekotayeKala$"
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? tofirstInpuKalla(event)
                              : null
                          }
                          value={
                            RC_nrxekotayeKala$ === 0 ? "" : RC_nrxekotayeKala$
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">$ کۆی مەتر </label>
                        <input
                          type="text"
                          name="RC_koymatr$"
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          value={RC_koymatr$ === 0 ? "" : RC_koymatr$}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">بەرزی</label>
                        <input
                          type="text"
                          name="RC_barze"
                          disabled={RC_dis === true ? true : null}
                          onChange={RC_barzichnage}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          value={RC_barze === 0 ? "" : RC_barze}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">پانی</label>
                        <input
                          type="text"
                          name="RC_pane"
                          disabled={RC_dis === true ? true : null}
                          onChange={AddProChange}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          value={RC_pane === 0 ? "" : RC_pane}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">درێژی</label>
                        <input
                          type="text"
                          name="RC_drezhe"
                          disabled={RC_dis === true ? true : null}
                          onChange={AddProChange}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          value={RC_drezhe === 0 ? "" : RC_drezhe}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>
                  </div>

                  {/* five row */}

                  <div className="row mt3">
                    <div className="col-md-4"></div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <h3 className=" border-bottom border-warning text-right">
                        $ گۆڕینی نرخی كاڵا
                      </h3>
                    </div>
                  </div>

                  {/* six Row */}
                  <div className="row">
                    <div className="col-sm-2 text-right mt4">
                      {RC_save === true ? (
                        <>
                          {RC_updatekala === true ? (
                            <button className="btn btn-light mt1">
                              <i className="fas fa-check-double animate__animated animate__heartBeat animate__repeat-2"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) =>
                                RC_updateNawKalla(e, RC_kalladata._id)
                              }
                              className="btn btn-light mt1"
                            >
                              <i className="far fa-edit fa-lg"></i>
                            </button>
                          )}
                        </>
                      ) : null}
                    </div>
                    {/* dfghjk */}
                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">$3 فرۆشتن</label>
                        <NumberFormat
                          displayType="input"
                          thousandSeparator={true}
                          name="RC_kamtrlaNewkarton"
                          onChange={AddProChange}
                          disabled={RC_dis === true ? true : null}
                          value={
                            RC_kamtrlaNewkarton === 0 ? "" : RC_kamtrlaNewkarton
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">$2 فرۆشتن</label>
                        <NumberFormat
                          displayType="input"
                          thousandSeparator={true}
                          name="RC_kamtrlaDadarzan"
                          onChange={AddProChange}
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          value={
                            RC_kamtrlaDadarzan === 0 ? "" : RC_kamtrlaDadarzan
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">ID فرۆشتن</label>
                        <NumberFormat
                          displayType="input"
                          thousandSeparator={true}
                          name="RC_froshtnID"
                          onChange={AddProChange}
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          value={RC_froshtnID === 0 ? "" : RC_froshtnID}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">$1 فرۆشتن</label>
                        <input
                          type="text"
                          name="RC_froshtn"
                          onChange={RC_froshtnChange}
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          value={RC_froshtn === 0 ? "" : RC_froshtn}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">$ کڕین</label>
                        <input
                          type="text"
                          onClick={RC_setInputEmpty}
                          name="RC_kren"
                          onChange={AddProChange}
                          disabled={RC_dis === true ? true : null}
                          onKeyPress={(event) =>
                            event.key === "Enter" ? toanotherInput(event) : null
                          }
                          value={RC_kren === 0 ? "" : RC_kren}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2 text-right"></div>

                    <div className="col-sm-1 text-right mt4">
                      {RC_search_wasl === true ? (
                        <>
                          {RC_open === true ? (
                            <button
                              onClick={(e) => RC_update(e, RC_wasl._id)}
                              className="btn btn-success mt1"
                            >
                              <i className="far fa-edit fa-lg"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) => RC_openchina(e, L_user._id)}
                              className="btn btn-success mt1"
                            >
                              <i className="fas fa-lock"></i>
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {RC_save === false ? (
                            <button
                              onClick={RC_newChina}
                              className="btn btn-success mt1"
                            >
                              <i className="far fa-save fa-lg"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) => RC_openchina(e, L_user._id)}
                              className="btn btn-success mt1"
                            >
                              <i className="fas fa-lock"></i>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                    <div className="col-sm-1 text-right mt4">
                      <button
                        onClick={RC_newpage}
                        className="btn btn-primary mt1"
                      >
                        <i className="fas fa-file fa-lg"></i>
                      </button>
                    </div>

                    <div className="col-sm-2 text-right"></div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">قازانج</label>
                        <input
                          type="text"
                          name="RC_qazanj"
                          onChange={RC_qazanjchange}
                          disabled={RC_dis === true ? true : null}
                          value={RC_qazanj === 0 ? "" : RC_qazanj}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">فرۆشتن 1ك</label>
                        <input
                          type="text"
                          name="RC_froshtneakKarton"
                          disabled={RC_dis === true ? true : null}
                          value={
                            RC_froshtneakKarton === 0 ? "" : RC_froshtneakKarton
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">کڕین 1ك</label>
                        <input
                          type="text"
                          name="RC_kreneakcartoon"
                          disabled={RC_dis === true ? true : null}
                          value={
                            RC_kreneakcartoon === 0 ? "" : RC_kreneakcartoon
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6 text-right"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }}
    </ProductConsumer>
  );
};

export default AddproductCinRight;
