import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableQarzeRastawxo from "./ScroollTableQarzeRastawxo";
import FooterOfQarzRastawxo from "./FooterOfQarzRastawxo";
import FillDetailQarzRastawxo from "./FillDetailQarzRastawxo";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";
const AllQarzRastawxo = () => {
  return (
    <div>
      <Navbar />
      {isMobile ? (
        <AllMob />
      ) : (
        <>
          <div style={{ marginTop: "50px" }}>
            <div className="row" style={{ backgroundColor: "#2e3541 " }}>
                <div className="col-md-12 ">
                <FillDetailQarzRastawxo />
              </div>
            </div>
            <div className="row">
                <div className="col-md-12 ">
                <ScroollTableQarzeRastawxo />
              </div>
            </div>
            <div className="row " style={{ backgroundColor: "#2e3541 " }}>
                <div className="col-md-12 col-md-12">
                <FooterOfQarzRastawxo />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AllQarzRastawxo;
