import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";
class ScroollTableParaGorenawa extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { RPG_data, RPG_getParaDataBySearchMoney } = value;
            const ParaGorenawaRaport = RPG_data.map((user, i) => {
              return (
                <tr key={i} className="f4 fk">
                  <td
                    className={`fk ${
                      user.nawiwargr === "ڕەهیل جاسم"
                        ? "dark-green"
                        : "dark-red"
                    }`}
                    style={{ border: "2px solid #f0ad4e" }}
                  >
                    {user.nawiwargr}
                  </td>
                  <td
                    className="fk bg-success text-white"
                    style={{ border: "2px solid #f0ad4e" }}
                  >
                    <NumberFormat
                      value={Number(user.brikrinidolar).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={Number(user.nrxikrinidolar).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </td>
                  <td
                    className="fk bg-danger text-white"
                    style={{ border: "2px solid #f0ad4e" }}
                  >
                    <NumberFormat
                      value={Number(user.briparabadinar).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <Time
                      value={user.date}
                      format="YYYY-MM-DD"
                      style={{ color: "black" }}
                    />
                  </td>
                  <td
                    className="fk"
                    style={{ border: "2px solid #f0ad4e" }}
                    onClick={(e) =>
                      RPG_getParaDataBySearchMoney(e, user.zhmara)
                    }
                  >
                    <Link to="/change-money">{user.zhmara}</Link>
                  </td>
                </tr>
              );
            });

            return (
              <div className="JM ">
                <table
                  id="example"
                  className="table table-hover table-bordered text-right "
                >
                  <thead>
                    <tr
                      className="text-warning f4"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ناوی وەرگر
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        $ بڕی پارە بە{" "}
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        $ نرخی کڕینی{" "}
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ID بڕی پارە بە{" "}
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>بەروار</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>ژ.پسوولە</th>
                    </tr>
                  </thead>
                  <tbody>{ParaGorenawaRaport}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default ScroollTableParaGorenawa;
