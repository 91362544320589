import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
const ScrollTableKoga = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { G_DeleteSail, G_dataTable, G_del_dis, G_joripara, G_wasl } =
            value;
          const Rent = G_dataTable.map((user, i) => {
            return (
              <tr key={i} className="f4 fk">
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {G_joripara === "دۆلار" ? (
                    <NumberFormat
                      value={user.kodolar}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  ) : (
                    <NumberFormat
                      value={user.kodinar}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.reja}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.nrx}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.darzan}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.carton}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.nawisht}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.raqam}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  <button
                    onClick={() => G_DeleteSail(user._id, user, i, G_wasl)}
                    disabled={G_del_dis === true ? true : null}
                    className="btn btn-danger "
                  >
                    <i className="fa fa-minus" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            );
          });

          return (
            <div className="RR">
              <table
                id="example"
                className="table table-striped table-bordered  tc m-auto"
              >
                <thead>
                  <tr
                    className="text-warning f4"
                    style={{
                      border: "2px solid #f0ad4e",
                      backgroundColor: "#2C3539",
                    }}
                  >
                    <th style={{ border: "2px solid #f0ad4e" }}>
                      {G_joripara === "دۆلار" ? "$ کۆی گشتی" : "ID کۆی گشتی"}
                    </th>
                    <th style={{ border: "2px solid #f0ad4e" }}>ڕێژە</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>نرخ</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>دانە</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>کارتۆن</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>ناوی شتەکان</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>x</th>
                  </tr>
                </thead>
                <tbody>{Rent}</tbody>
              </table>
            </div>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default ScrollTableKoga;
