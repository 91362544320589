import React from "react";
import { Link } from "react-router-dom";
import Time from "react-time-format";
import { ProductConsumer } from "../../../Context/AddProWakel";
const FillDetailKogaRaport = () => {
 return (
  <>
   <ProductConsumer>
    {(value) => {
     const {
      AddProChange,
      JM_name,
      RKP_getPrint,
      JM_yaksanbasfrfn,
      JM_zyatrlasfrfn,
      JM_barwar,
      JM_pshandan,
     } = value;
     return (
      <>
       <div className="row" style={{ backgroundColor: "#2C3539" }}>
                             <div className="col-lg-3 col-md-5 form-inline">
         <div className="row form-inline">
                         <div className="col-md-7 form-inline">
           <p className="font-weight-bold bg-white text-black f4">
            <Time format="YYYY-MM-DD" />
           </p>
           <span style={{ color: "#2C3539" }}>:</span>
           <p className="font-weight-bold bg-white text-black f4">
            {JM_barwar}
           </p>
          </div>
                         <div className='col-md-5 form-inline"'>
           <label className="text-warning font-weight-bold f4">
            : بەرواری{" "}
           </label>
          </div>
         </div>
        </div>
                             <div className="col-lg-5 col-md-7  mt3">
         <div className="row">
                         <div className="col-md-6">
           <input
            type="text"
            name="JM_name"
            value={JM_name}
            onChange={AddProChange}
            aria-label="Large"
            className="form-control"
           />
          </div>
                         <div className="col-md-3">
           <label className="text-warning f4 font-weight-bold">گەڕان</label>
          </div>
         </div>
        </div>
                             <div className="col-lg-4 col-md-6 mt3">
         <div className="row">
                                             <div className="col-lg-3 col-md-3">
           <Link to="/print-Koga">
            <button className="btn  btn-secondary ml3" onClick={RKP_getPrint}>
             چاپکردن
            </button>
           </Link>
          </div>
                                             <div className="col-lg-3 col-md-3 ">
           <button className="btn  btn-success " onClick={JM_zyatrlasfrfn}>
            زیاتر لە 0
           </button>
          </div>
                                             <div className="col-lg-3 col-md-3">
           <button className="btn  btn-warning" onClick={JM_yaksanbasfrfn}>
            یەکسان بە 0
           </button>
          </div>
                                             <div className="col-lg-3 col-md-3">
           <button className="btn  btn-info ml3" onClick={JM_pshandan}>
            هەر هەمویان
           </button>
          </div>
         </div>
        </div>
       </div>
      </>
     );
    }}
   </ProductConsumer>
  </>
 );
};

export default FillDetailKogaRaport;
