import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
class Footer extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              onSaveRent,
              AddProChange,
              R_totalID,
              R_waslKrdn,
              R_waslKrdnID,
              R_total,
              R_getIsChecked,
              dolartodinar,
              getPrint,
              R_kartonakan,
              R_search_wasl,
              R_koywaznkarton,
              R_del_dis,
              L_user,
              R_WakilQard_isChecked,
              R_isChecked,
              R_joripara,
              R_dashkan,
              R_kamkrdnawayNrx$,
              R_paraDanID,
              R_kateQarz,
              R_table,
              loading,
              R_save,
              R_wasl,
              updateSale,
              getPrintPasswordRent,
              R_open,
              openUpdate,
              R_pageminsone,
              R_pageplusone,
              R_paraDan$,
              R_kamkrdnawayNrxID,
              R_dashkanID,
              dinartodolar,
              R_kreysayara,
              R_kreykrekar,
              showLoader,
              hideLoader,
              R_zh_barez,
            } = value;
            return (
              <>
                <div className="row">
                  {R_joripara === "دۆلار" ? (
                    <>
                      <div className="col-md-12">
                        <div className=" col-md-10">
                          <table className="table " id="yusif">
                            <thead>
                              <tr
                                className={`${
                                  R_save === true || R_search_wasl === true
                                    ? "bg-white"
                                    : ""
                                }`}
                              >
                                <td className="text-center bg-white">
                                  <NumberFormat
                                    value={R_total}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    className=""
                                  />
                                </td>
                                <th
                                  className="text-danger  bg-white "
                                  style={{ width: "45%" }}
                                >
                                  :کۆی گشتی
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center">
                                  <input
                                    type="number"
                                    disabled={R_del_dis === true ? true : null}
                                    name="R_dashkan"
                                    onChange={AddProChange}
                                    value={
                                      R_dashkan === "" ||
                                      R_dashkan === null ||
                                      R_dashkan === 0 ||
                                      R_dashkan === undefined
                                        ? ""
                                        : R_dashkan
                                    }
                                    style={{ padding: "8px 4px" }}
                                    className={` border-0  ${
                                      R_save === true || R_search_wasl === true
                                        ? "bg-white"
                                        : ""
                                    }`}
                                  />
                                </td>
                                <th className="text-warning ">:کەمکردنەوە</th>
                              </tr>
                              <tr>
                                <td className="text-center text-white">
                                  {R_dashkan !== 0 ? (
                                    <NumberFormat
                                      value={R_kamkrdnawayNrx$()}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      className=""
                                    />
                                  ) : (
                                    <NumberFormat
                                      value={R_total}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      className=""
                                    />
                                  )}
                                </td>
                                <th className="text-warning ">$پارەی ماوە</th>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  <NumberFormat
                                    displayType={"input"}
                                    thousandSeparator={true}
                                    disabled={R_del_dis === true ? true : null}
                                    name="R_paraDan$"
                                    onChange={AddProChange}
                                    value={R_paraDan$}
                                    style={{
                                      padding: "8px 4px",
                                      backgroundColor: `${
                                        R_save === true ||
                                        R_search_wasl === true
                                          ? "white"
                                          : ""
                                      }`,
                                    }}
                                    className={` border-0 `}
                                  />
                                </td>
                                <th className="text-warning  ">:$پارەی دراو</th>
                              </tr>
                              <tr>
                                <td className="text-center bg-white">
                                  {R_paraDan$ !== 0 && R_paraDan$ !== "" ? (
                                    <NumberFormat
                                      value={R_waslKrdn()}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      className=""
                                    />
                                  ) : (
                                    <NumberFormat
                                      value={R_kamkrdnawayNrx$()}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      className=""
                                    />
                                  )}
                                </td>
                                <th className="text-danger bg-white  ">
                                  پارەی نەدراو
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {/* start ID */}
                  {R_joripara === "دینار" ? (
                    <>
                      <div className="col-md-12">
                        <div className=" col-md-10">
                          <table className="table" id="yusif">
                            <thead>
                              <tr
                                className={`${
                                  R_save === true || R_search_wasl === true
                                    ? "bg-white"
                                    : ""
                                }`}
                              >
                                <td className="text-center bg-white">
                                  <NumberFormat
                                    value={R_totalID}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"ID"}
                                    className=""
                                  />
                                </td>
                                <th
                                  className="text-danger bg-white  "
                                  style={{ width: "45%" }}
                                >
                                  : کۆی گشتی
                                </th>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  <input
                                    type="number"
                                    disabled={R_del_dis === true ? true : null}
                                    name="R_dashkanID"
                                    onChange={AddProChange}
                                    value={
                                      R_dashkan === 0 ||
                                      R_dashkan === null ||
                                      R_dashkan === undefined ||
                                      R_dashkan === ""
                                        ? ""
                                        : R_dashkan
                                    }
                                    style={{
                                      padding: "8px 4px",
                                      backgroundColor: `${
                                        R_save === true ||
                                        R_search_wasl === true
                                          ? "white"
                                          : ""
                                      }`,
                                    }}
                                    className={` border-0 `}
                                  />
                                </td>
                                <th className="text-warning ">:کەمکردنەوە</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-center text-white">
                                  {R_dashkanID !== 0 ? (
                                    <NumberFormat
                                      value={R_kamkrdnawayNrxID()}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"ID"}
                                      className=""
                                    />
                                  ) : (
                                    <NumberFormat
                                      value={R_totalID}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"ID"}
                                      className=""
                                    />
                                  )}
                                </td>
                                <th className="text-warning ">پارەی ماوە</th>
                              </tr>
                              <tr>
                                <td className="text-center">
                                  <NumberFormat
                                    disabled={R_del_dis === true ? true : null}
                                    name="R_paraDanID"
                                    onChange={AddProChange}
                                    value={R_paraDanID}
                                    style={{ padding: "8px 4px" }}
                                    className={`border-0  ${
                                      R_save === true || R_search_wasl === true
                                        ? "bg-white"
                                        : ""
                                    }`}
                                    displayType={"input"}
                                    thousandSeparator={true}
                                  />
                                </td>
                                <th className="text-warning ">:پارەی دراو</th>
                              </tr>
                              <tr>
                                <td className="text-center bg-white">
                                  {R_paraDanID !== 0 && R_paraDanID !== "" ? (
                                    <NumberFormat
                                      value={R_waslKrdnID()}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"ID"}
                                      className=""
                                    />
                                  ) : (
                                    <NumberFormat
                                      value={R_kamkrdnawayNrxID()}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={"ID"}
                                      className=""
                                    />
                                  )}
                                </td>
                                <th className="text-danger bg-white  ">
                                  : پارەی نەدراو
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="row mt5 mb5 tc">
                    <div className="col-md-6 row">
                      <div className="col-md-6">
                        <button
                          className="btn btn-dark   px-4"
                          disabled={
                            R_open === true || R_table === true ? true : null
                          }
                          onClick={R_pageminsone}
                          style={{
                            backgroundColor: "#00695c",
                            border: "1px solid #00695c",
                          }}
                        >
                          <i className="fas fa-caret-left "></i>
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          className="btn btn-dark col-md-6  px-4 "
                          disabled={
                            R_open === true || R_table === true ? true : null
                          }
                          onClick={R_pageplusone}
                          style={{
                            backgroundColor: "#00695c",
                            border: "1px solid #00695c",
                          }}
                        >
                          <i className="fas fa-caret-right "></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      {R_search_wasl !== null ? (
                        <>
                          <Link to="/print-rent">
                            <button
                              type="button"
                              disabled={R_open === true ? true : null}
                              onClick={(e) => {
                                getPrintPasswordRent(R_zh_barez, e);
                              }}
                              className="btn btn-info mr1"
                              style={{
                                backgroundColor: "#9900ff",
                                border: "1px solid #9900ff",
                              }}
                            >
                              <i className="fas fa-print fa-lg"></i>
                            </button>
                          </Link>
                          {R_open === null ? (
                            <button
                              onClick={(e) =>
                                openUpdate(
                                  R_zh_barez,
                                  L_user._id,
                                  e,
                                  R_wasl._id
                                )
                              }
                              className="btn btn-warning "
                              style={{
                                backgroundColor: "silver",
                                border: "1px solid silver",
                              }}
                            >
                              <i className="fas fa-lock"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) =>
                                updateSale(R_zh_barez, R_wasl._id, e)
                              }
                              className="btn btn-primary "
                              style={{ backgroundColor: "navy" }}
                            >
                              <i className="fas fa-edit "></i>
                            </button>
                          )}
                        </>
                      ) : R_save === true ? (
                        <>
                          {R_open === null ? (
                            <button
                              onClick={(e) =>
                                openUpdate(
                                  R_zh_barez,
                                  L_user._id,
                                  e,
                                  R_wasl._id
                                )
                              }
                              className="btn btn-warning "
                              style={{
                                backgroundColor: "silver",
                                border: "1px solid silver",
                              }}
                            >
                              <i className="fas fa-lock"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) =>
                                updateSale(R_zh_barez, R_wasl._id, e)
                              }
                              className="btn btn-primary "
                              style={{ backgroundColor: "navy" }}
                            >
                              <i className="fas fa-edit "></i>
                            </button>
                          )}
                          <Link to="/print-rent">
                            <button
                              type="button"
                              onClick={(e) => {
                                getPrint(R_zh_barez, e);
                              }}
                              className="btn btn-info"
                              style={{
                                backgroundColor: "#9900ff",
                                border: "1px solid #9900ff",
                              }}
                            >
                              <i className="fas fa-print fa-lg"></i>
                            </button>
                          </Link>
                        </>
                      ) : (
                        <>
                          {loading ? (
                            <button
                              type="button"
                              onDoubleClick={hideLoader}
                              className="btn btn-success "
                            >
                              <i class="fas fa-spinner animate__animated animate__rotateIn animate__infinite"></i>
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={(e) => {
                                showLoader(e);
                                onSaveRent(e);
                              }}
                              className="btn btn-success"
                            >
                              <i className="far fa-save fa-lg"></i>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}

export default Footer;
