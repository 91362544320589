import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Navbar from "../../../Navbar/Navbar";

const FormParadan = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            SaveOldRasid,
            OR_joriDraw,
            OR_zhmarayBarez,
            OpenOldRasid,
            OR_del_dis,
            OR_nawiBarez,
            OR_briQarz,
            NewOldRasid,
            toanotherInput,
            OR_save,
            OR_open,
            SearchWakilOldRasid,
          } = value;

          return (
            <>
              <Navbar />

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

              <div className="row ">
                <div className="col-md-3"></div>
                {/* Form take moneyy */}
                <div className="col-lg-6 text-warning col-md-12 ">
                  <div
                    className="card card-warning "
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <div
                      className="card-header p-1 border-bottom border-warning"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <h2 className="card-title tc">
                        داخیلکردنی قەرزی سەر دەفتەر
                      </h2>
                    </div>

                    <div className="card-body">
                      <form>
                        <div className="row">
                          <br />
                          <br />
                          <br />
                          <br />

                          <div className="col-md-3 text-right ">
                            <div className="row">
                              <div className="col-md-4"></div>
                              <div className="col-md-4 mt-5">
                                <div className="form-group ">
                                  <button
                                    disabled={OR_del_dis === true ? true : null}
                                    onClick={NewOldRasid}
                                    className="btn btn-dark"
                                  >
                                    <i className="fas fa-search-plus"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                          </div>

                          <div className="col-sm-6 text-right mt-2">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ناوی بەرێز
                              </label>
                              <input
                                type="text"
                                name="OR_nawiBarez"
                                disabled={OR_del_dis === true ? true : null}
                                onChange={AddProChange}
                                value={OR_nawiBarez}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>

                          <div className="col-md-3 text-right mt-2">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژ.بەڕێز
                              </label>
                              <input
                                type="text"
                                name="OR_zhmarayBarez"
                                onClick={NewOldRasid}
                                disabled={OR_del_dis === true ? true : null}
                                onChange={AddProChange}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? SearchWakilOldRasid(
                                        event,
                                        OR_zhmarayBarez
                                      )
                                    : null
                                }
                                value={
                                  OR_zhmarayBarez === 0 ? "" : OR_zhmarayBarez
                                }
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 text-right"></div>
                          <div className="col-sm-4 text-right">
                            <label className="font-weight-bold f4">
                              جۆری دراو
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                OR_joriDraw === "دۆلار"
                                  ? "bg-success text-white"
                                  : `${
                                      OR_joriDraw === "دینار"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              disabled={OR_del_dis === true ? true : null}
                              onChange={AddProChange}
                              name="OR_joriDraw"
                              value={OR_joriDraw !== null ? OR_joriDraw : ""}
                            >
                              <option className="ml5">..جۆری دراو</option>
                              <option>دۆلار</option>
                              <option>دینار</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بڕی قەرز
                              </label>
                              <NumberFormat
                                displayType="input"
                                thousandSeparator={true}
                                name="OR_briQarz"
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? toanotherInput(event)
                                    : null
                                }
                                onChange={AddProChange}
                                disabled={OR_del_dis === true ? true : null}
                                value={OR_briQarz === 0 ? "" : OR_briQarz}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-1 text-right"></div> */}
                          {OR_save === null ? (
                            <>
                              <button
                                type="button"
                                onClick={SaveOldRasid}
                                className="btn btn-md btn-success px-4 ml3"
                              >
                                <i className="far fa-save fa-lg"></i>
                              </button>
                              <button
                                type="button"
                                onClick={NewOldRasid}
                                disabled={OR_open === true ? true : null}
                                className="btn btn-md btn-primary px-4 ml3"
                              >
                                <i className="far fa-file fa-lg"></i>
                              </button>
                            </>
                          ) : (
                            <>
                              {/* <button
                                onClick={(e) => OpenOldRasid( e)}
                                className="btn btn-md btn-warning px-4 ml3"
                                style={{
                                  backgroundColor: "silver",
                                  border: "1px solid silver",
                                }}
                              >
                                <i className="fas fa-lock"></i>
                              </button> */}
                              <button
                                type="button"
                                onClick={NewOldRasid}
                                disabled={OR_open === true ? true : null}
                                className="btn btn-md btn-primary px-4 ml3"
                              >
                                <i className="far fa-file fa-lg"></i>
                              </button>
                            </>
                          )}
                          {/* <div className="col-sm-8 ">



                            <button
                              onClick={(e) =>
                                PD_updtate_para(e, PD_wasl, PD_wasl.kascode)
                              }
                              className="btn btn-md btn-primary px-4 ml3"
                              style={{ backgroundColor: "navy" }}
                            >
                              <i className="fas fa-edit "></i>
                            </button>
                          </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default FormParadan;
