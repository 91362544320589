import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const Footer = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            K_rasedekartonfroshtn,
            K_rasedeKarton,
            K_rasedekartonKren,
            K_rasedekartongarnawa,
          } = value;
          return (
            <>
              <br />
              <div className="row mb4">
                <div className="col-md-12 ">
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="text-warning tc">فرۆشتن</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        {K_rasedekartonfroshtn}
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning">کڕین</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        {K_rasedekartonKren}
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning tc">کاڵای گەڕاوە</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        {K_rasedekartongarnawa}
                      </h6>
                    </div>

                    <div className="col-md-6">
                      <h6 className="text-warning tc"> ڕەسیدی دانە</h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        {K_rasedeKarton}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default Footer;
