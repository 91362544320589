import React from "react";
import { storeProducts } from "./../Data";
import Product from "./Product";

import { ProductConsumer } from "../Context/AddProWakel";
import Navbar from "../Navbar/Navbar";
class ProductList extends React.Component {
  constructor() {
    super();
    this.state = {
      products: storeProducts,
    };
  }
  render() {
    return (
      <>
        <Navbar />
        <div style={{ backgroundColor: "#2C3539" }}>
          <div className="py-5">
            <div className="container">
              <div className="row">
                <ProductConsumer>
                  {(value) => {
                    return value.products.map((product) => {
                      return <Product key={product.id} product={product} />;
                    });
                  }}
                </ProductConsumer>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ProductList;
