import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const AddproductRightSide = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const {
          AddProChange,
          nrxeFroshtnDenar,
          nrxeFroshtnDolar,
          onSave,
          del_dis,
          openUpdateproduct,
          getProductDataBySearch,
          prodactdata,
          search,
          zhmara,
          naweSht,
          barcode,
          open,
          getProductDataBySearchName,
          updtae_Right_product,
          ok,
          close_update,
          error,
          ClearError,
          qazanj,
          zhmarayKarton,
          kren,
          nrxeFroshtnDolar2,
          nrxeFroshtnDolar3,
          setProductSearchEmty,
          productdata,
          Add_toanotherInput,
          wazn_karton,
          newPage,
          ProducthideLoader,
          ProductLoading,
          ProductshowLoader,
          suggestions,
          suggestionSelected,
          Search_Product_Handle,
        } = value;
        const renderSuggestions = () => {
          if (suggestions.length === 0) {
            return null;
          }
          return (
            <div className="srchList">
              <ul className="uly">
                {suggestions.map((item) => (
                  <li onClick={() => suggestionSelected(item._id)}>
                    {item.nameofproduct}
                  </li>
                ))}
              </ul>
            </div>
          );
        };
        return (
          <div className=" col-md-12 col-md-12  col-lg-4 ">
            <div
              className="card card-warning"
              style={{ backgroundColor: "#2C3539" }}
            >
              <div
                className="card-header p-1"
                style={{ backgroundColor: "#2C3539" }}
              >
                <h3 className="card-title tc text-warning">زیادکردنی کاڵا</h3>
              </div>

              <div className="card-body text-warning f4 border border-warning">
                <form onChange={AddProChange}>
                  <div className="row">
                    <div className="col-sm-6 text-right">
                      <div className="form-group">
                        <button
                          onClick={(e) => newPage(e)}
                          className="btn btn-primary  mr4 "
                        >
                          <i className="fas fa-file fa-lg"></i>
                        </button>
                        <button
                          onClick={setProductSearchEmty}
                          className="btn btn-dark  mr5"
                        >
                          <i className="fas fa-search-plus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-6 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">کۆد </label>
                        <input
                          type="text"
                          name="barcode"
                          disabled={del_dis === true ? true : null}
                          onChange={AddProChange}
                          value={barcode}
                          onKeyPress={(event) =>
                            event.key === "Enter" || event.key === 9
                              ? getProductDataBySearch(event)
                              : null
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 text-right"></div>
                    <div className="col-sm-6 text-right"></div>
                    <div className="col-md-12 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">ناوی شتەکان</label>
                        <input
                          type="text"
                          name="naweSht"
                          disabled={del_dis === true ? true : null}
                          value={naweSht}
                          onChange={Search_Product_Handle}
                          autocomplete="off"
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? getProductDataBySearchName(event)
                              : null
                          }
                          className="form-control text-right font-weight-bold"
                        />
                        <br />
                        <div className="justify-content-md-center text-center">
                          {renderSuggestions()}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold"> 1 ك $$$$</label>
                        <input
                          value={qazanj}
                          name="qazanj"
                          type="number"
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">$$$</label>
                        <input
                          type="text"
                          name="kren"
                          disabled={del_dis === true ? true : null}
                          onChange={AddProChange}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Add_toanotherInput(event)
                              : null
                          }
                          value={kren === 0 ? "" : kren}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">
                          2 $ نرخی فرۆشتن
                        </label>
                        <NumberFormat
                          displayType="input"
                          thousandSeparator={true}
                          name="nrxeFroshtnDolar2"
                          disabled={del_dis === true ? true : null}
                          onChange={AddProChange}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Add_toanotherInput(event)
                              : null
                          }
                          value={
                            nrxeFroshtnDolar2 === 0 ? "" : nrxeFroshtnDolar2
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">
                          {" "}
                          1 $ نرخی فرۆشتن{" "}
                        </label>
                        <input
                          type="number"
                          name="nrxeFroshtnDolar"
                          disabled={del_dis === true ? true : null}
                          onChange={AddProChange}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Add_toanotherInput(event)
                              : null
                          }
                          value={nrxeFroshtnDolar === 0 ? "" : nrxeFroshtnDolar}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">
                          ID نرخی فرۆشتن{" "}
                        </label>
                        <NumberFormat
                          displayType="input"
                          thousandSeparator={true}
                          name="nrxeFroshtnDenar"
                          disabled={del_dis === true ? true : null}
                          onChange={AddProChange}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Add_toanotherInput(event)
                              : null
                          }
                          value={nrxeFroshtnDenar === 0 ? "" : nrxeFroshtnDenar}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">
                          {" "}
                          3 $ نرخی فرۆشتن
                        </label>
                        <NumberFormat
                          displayType="input"
                          thousandSeparator={true}
                          name="nrxeFroshtnDolar3"
                          disabled={del_dis === true ? true : null}
                          onChange={AddProChange}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Add_toanotherInput(event)
                              : null
                          }
                          value={
                            nrxeFroshtnDolar3 === 0 ? "" : nrxeFroshtnDolar3
                          }
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">
                          ژمارە لە کارتۆن
                        </label>
                        <input
                          type="text"
                          name="zhmarayKarton"
                          disabled={del_dis === true ? true : null}
                          onChange={AddProChange}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Add_toanotherInput(event)
                              : null
                          }
                          value={zhmarayKarton === 0 ? "" : zhmarayKarton}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 text-right">
                      <div className="form-group">
                        <label className="font-weight-bold">وەزن کارتۆن</label>
                        <input
                          type="number"
                          name="wazn_karton"
                          disabled={del_dis === true ? true : null}
                          onChange={AddProChange}
                          onKeyPress={(event) =>
                            event.key === "Enter"
                              ? Add_toanotherInput(event)
                              : null
                          }
                          value={wazn_karton === 0 ? "" : wazn_karton}
                          className="form-control text-right font-weight-bold"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 text-right mt-4">
                      {search === null ? (
                        <>
                          {ok === null ? (
                            <>
                              {ProductLoading ? (
                                <button
                                  type="button"
                                  onDoubleClick={ProducthideLoader}
                                  className="btn btn-md  btn-success px-4 ml3"
                                >
                                  <i class="fas fa-spinner animate__animated animate__rotateIn animate__infinite"></i>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    ProductshowLoader(e);
                                    onSave(e);
                                  }}
                                  className="btn btn-md  btn-success px-4 ml3"
                                >
                                  <i className="far fa-save fa-lg"></i>
                                </button>
                              )}
                            </>
                          ) : (
                            <button
                              onDoubleClick={close_update}
                              type="button"
                              className="btn btn-md  btn-success px-4 ml3"
                            >
                              <i className="fas fa-check-double animate__animated animate__heartBeat animate__repeat-2"></i>
                            </button>
                          )}
                        </>
                      ) : ok === null ? (
                        <>
                          {open === null ? (
                            <button
                              onClick={(e) => openUpdateproduct(e)}
                              type="button"
                              className="btn btn-md btn-warning px-4 ml3"
                              style={{
                                backgroundColor: "silver",
                                border: "1px solid silver",
                              }}
                            >
                              <i class="fas fa-lock"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) =>
                                updtae_Right_product(e, prodactdata)
                              }
                              type="button"
                              className="btn btn-md btn-primary px-4 ml3"
                              style={{ backgroundColor: "navy" }}
                            >
                              <i className="fas fa-edit fa-lg"></i>
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {open === null ? (
                            <button
                              onClick={(e) => openUpdateproduct(e)}
                              type="button"
                              className="btn btn-md btn-warning px-4 ml3"
                              style={{
                                backgroundColor: "silver",
                                border: "1px solid silver",
                              }}
                            >
                              <i class="fas fa-lock"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) =>
                                updtae_Right_product(e, prodactdata)
                              }
                              type="button"
                              className="btn btn-md btn-primary px-4 ml3"
                              style={{ backgroundColor: "navy" }}
                            >
                              <i className="fas fa-edit fa-lg"></i>
                            </button>
                          )}
                          <button
                            onDoubleClick={close_update}
                            type="button"
                            className="btn btn-md  btn-success px-4 ml3"
                          >
                            <i className="fas fa-check-double animate__animated animate__heartBeat animate__repeat-2"></i>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }}
    </ProductConsumer>
  );
};

export default AddproductRightSide;
