import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

class ScroollTableKoga3arz extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              HK_all3arzKala,
              HK_code,
              HK_name,
              selectRow,
              checkedValues,
            } = value;
            const wakelRaport = HK_all3arzKala.filter((data) => {
              if (HK_name !== "") {
                return data.nameofproduct
                  .toLowerCase()
                  .startsWith(HK_name.toLowerCase());
              } else if (HK_code !== "") {
                return data.barcode.toString().startsWith(HK_code);
              } else {
                return data;
              }
            }).map((user, i) => {
              return (
                <tr key={i} className="f4">
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {Number(user.rasidKarton).toFixed(2)}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {Number(user.saleDolar3).toFixed(2)}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {Number(user.saleDolar2).toFixed(2)}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {Number(user.saleDolar).toFixed(2)}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {Number(user.price).toFixed(2)}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {user.nameofproduct}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {user.barcode}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    <input
                      type="checkbox"
                      autocomplete="off"
                      onClick={(e) => selectRow(e, user)}
                      style={{ width: "20px", height: "20px" }}
                      defaultChecked={checkedValues.includes(user)}
                    />{" "}
                  </td>
                </tr>
              );
            });

            return (
              <div className="JM">
                <table
                  id="example"
                  className="table table-hover table-bordered text-right "
                >
                  <thead>
                    <tr
                      className="text-warning f4"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        كارتۆنی ماوە
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>دۆلار 3</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>دۆلار 2</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>دۆلار 1</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>نرخی کڕین</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ناوەی کاڵا
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>X</th>
                    </tr>
                  </thead>
                  <tbody>{wakelRaport}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default ScroollTableKoga3arz;
