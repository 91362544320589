import React from "react";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import { Link } from "react-router-dom";
const Header = () => {
  var today = new Date();
  var kat =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  return (
    <ProductConsumer>
      {(value) => {
        const { RPW_from, RPW_to, RPW_getPrint, AddProChange, RPW_peshandan } =
          value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning ">
                {" "}
                <button onClick={RPW_getPrint} className="btn btn-warning">
                  <Link to="/print-garanawa" className="text-dark">
                    Print
                  </Link>
                </button>
              </div>
              <div className="col-md-9 mt1 font-weight-bold   form-group text-warning tc">
                <h5> ڕاپۆرتی پارەگەڕاندنەوە</h5>
              </div>
            </div>
            {/* //// */}
            <div
              className="row  tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-4 mt4 font-weight-bold  pointer form-group text-warning">
                {" "}
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    RPW_peshandan(e);
                  }}
                >
                  پیشاندان
                </button>
              </div>
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-warning ">
                :بۆ بەرواری{" "}
                <span className="text-warning">
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RPW_to"
                    value={RPW_to}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                :لە بەرواری
                <span className="text-warning">
                  {" "}
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RPW_from"
                    value={RPW_from}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
