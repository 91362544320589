import React from "react";
import Scroll from "./Scroll";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
const ScrollTable = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { DeleteSail, R_dataTable, R_joripara, R_del_dis, R_wasl } =
            value;
          const Rent = R_dataTable.map((user, i) => {
            return (
              <tr key={i} className="f4">
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {R_joripara === "دۆلار" ? (
                    <NumberFormat
                      value={user.kodolar}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  ) : (
                    <NumberFormat
                      value={user.kodinar}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {Number(user.reja * user.nrx).toFixed(2)}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.nrx}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.darzan}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.carton}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.nawisht}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.raqam}
                </td>
                <td style={{ border: "2px solid #f0ad4e" }}>
                  <button
                    onClick={() => DeleteSail(user._id, user, R_wasl, i)}
                    disabled={R_del_dis === true ? true : null}
                    className="btn btn-danger "
                  >
                    <i className="fa fa-minus" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            );
          });

          return (
            <div className="RR">
              <table
                id="example"
                className="table table-striped table-bordered  tc m-auto"
              >
                <thead>
                  <tr
                    className="text-warning f4"
                    style={{
                      border: "2px solid #f0ad4e",
                      backgroundColor: "#2C3539",
                    }}
                  >
                    <th style={{ border: "2px solid #f0ad4e" }}>
                      {R_joripara === "دۆلار" ? "$ کۆی گشتی" : "ID کۆی گشتی"}
                    </th>
                    <th style={{ border: "2px solid #f0ad4e" }}>نرخ ک.ن</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>نرخ</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>دانە</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>کارتۆن</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>ناوی شتەکان</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>x</th>
                  </tr>
                </thead>
                <tbody>{Rent}</tbody>
              </table>
            </div>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default ScrollTable;
