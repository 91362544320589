import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Navbar from "../../../Navbar/Navbar";
import _ from "lodash";
import { Link } from "react-router-dom";

const IncomOut = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            P_getPrint,
            IO_totaldolar,
            JM_koyrasidparakan,
            IO_totaldinar,
            IO_dashakandolar,
            IO_dashakandinar,
            IO_paraywargirawdolar_p,
            IO_paraywargirawdinar_p,
            IO_masrwf_D,
            IO_masrwf_IQD,
            IO_rejaDolar,
            IO_rejaDinar,
            IO_qarziAsto_D,
            IO_qarziAsto_IQ,
            IO_totalgaranawadolar,
            IO_totalgaranwadinar,
            IO_from,
            IO_to,
            dinartodolar,
            IO_daxiladinar,
            IO_daxiladolar,
            IO_paradandolar,
            IO_paradandinar,
          } = value;
          let qasa = _.add(
            Number(
              _.subtract(Number(IO_paraywargirawdolar_p), Number(IO_masrwf_D))
            ),
            Number(
              dinartodolar(
                _.subtract(
                  Number(IO_paraywargirawdinar_p),
                  Number(IO_masrwf_IQD)
                )
              )
            )
          );
          let paradandinarTodolar = dinartodolar(Number(IO_paradandinar));
          let totalParadan = _.add(
            Number(IO_paradandolar),
            Number(paradandinarTodolar)
          );
          let paradan = _.subtract(qasa, totalParadan);

          ///// hsabat 3am
          let TotalQarz = _.add(
            Number(IO_rejaDolar),
            Number(dinartodolar(IO_rejaDinar))
          );
          let totalRastawxo = _.add(
            Number(IO_daxiladolar),
            Number(dinartodolar(IO_daxiladinar))
          );
          let otalSum = _.add(
            _.add(totalRastawxo, TotalQarz),
            _.add(Number(JM_koyrasidparakan), Number(paradan))
          );
          let qazriAsto = _.add(
            Number(IO_qarziAsto_D),
            Number(dinartodolar(IO_qarziAsto_IQ))
          );
          let total = _.subtract(otalSum, qazriAsto);
          return (
            <>
              <Navbar />
              <br />
              <br />
              <br />
              <div className="row ">
                {/* Form take moneyy */}
                <div className="col-lg-12 text-black col-md-12 ">
                  <div
                    className="card card-warning "
                    style={{ backgroundColor: "#dadad0" }}
                  >
                    <div
                      className="card-header p-1 border-bottom border-warning"
                      style={{ backgroundColor: "#dadad0" }}
                    >
                      <h2 className="card-title tc">
                        حساباتی عام لە{" "}
                        <span className="text-black">{IO_from}</span> بۆ{" "}
                        <span className="text-black">{IO_to}</span>
                      </h2>
                    </div>

                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی گەڕانەوە
                              </label>
                              <NumberFormat
                                value={IO_totalgaranawadolar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </div>
                          </div>

                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                ID کۆی گەڕانەوە
                              </label>
                              <NumberFormat
                                value={IO_totalgaranwadinar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix="IQD"
                              />
                            </div>
                          </div>

                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی فرؤشتن
                              </label>
                              <NumberFormat
                                value={IO_totaldolar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>

                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                ID کۆی فرؤشتن
                              </label>
                              <NumberFormat
                                value={IO_totaldinar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix="IQD"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <br />
                          <br />
                          <br />
                          <br />

                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی قەرز
                              </label>
                              <NumberFormat
                                value={IO_rejaDolar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                IQD کۆی قەرز
                              </label>
                              <NumberFormat
                                value={IO_rejaDinar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"IQD"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی پارەی وەرگیراو
                              </label>
                              <NumberFormat
                                value={IO_paraywargirawdolar_p}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                IQD کۆی پارەی وەرگیراوە
                              </label>
                              <NumberFormat
                                value={IO_paraywargirawdinar_p}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"IQD"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <br />
                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی دانەوەی قەرز
                              </label>
                              <NumberFormat
                                value={IO_paradandolar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                IQD کۆی دانەوەی قەرز
                              </label>
                              <NumberFormat
                                value={IO_paradandinar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"IQD"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی ڕاستەوخۆ
                              </label>
                              <NumberFormat
                                value={IO_daxiladolar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                IQD کۆی ڕاستەوخۆ
                              </label>
                              <NumberFormat
                                value={IO_daxiladinar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"IQD"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <br />
                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی گشتی داشکان
                              </label>
                              <NumberFormat
                                value={IO_dashakandolar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix="$"
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                IQD کۆی داشکان
                              </label>
                              <NumberFormat
                                value={IO_dashakandinar}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"IQD"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی قەرزی ئەستۆ
                              </label>
                              <NumberFormat
                                value={IO_qarziAsto_D}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                IQD کۆی قەرزی ئەستۆ
                              </label>
                              <NumberFormat
                                value={IO_qarziAsto_IQ}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"IQD"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ داهاتی گشتی
                              </label>
                              <NumberFormat
                                value={Number(total).toFixed(2)}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی رەسیدی مەخزەن
                              </label>
                              <NumberFormat
                                value={JM_koyrasidparakan}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>

                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی مەسروفات
                              </label>
                              <NumberFormat
                                value={IO_masrwf_D}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                IQD کۆی مەسروفات
                              </label>
                              <NumberFormat
                                value={IO_masrwf_IQD}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix="IQD"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <br />
                          <div className="col-md-3 text-right mt-5 text-center">
                            <Link to="/print-hesabatKarga">
                              <button
                                className="btn btn-warning"
                                onClick={P_getPrint}
                              >
                                Print
                              </button>
                            </Link>
                          </div>
                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ هەموو پارەی قاسە
                              </label>
                              <NumberFormat
                                value={Number(paradan).toFixed(2)}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>

                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                $ کۆی پارەی قاسە
                              </label>
                              <NumberFormat
                                value={Number(
                                  _.subtract(
                                    IO_paraywargirawdolar_p,
                                    IO_masrwf_D
                                  )
                                ).toFixed(2)}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold  ">
                                IQD کۆی پارەی قاسە
                              </label>
                              <NumberFormat
                                value={Number(
                                  _.subtract(
                                    IO_paraywargirawdinar_p,
                                    IO_masrwf_IQD
                                  )
                                ).toFixed(2)}
                                displayType={"text"}
                                className="form-control   text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix="IQD"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default IncomOut;
