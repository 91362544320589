import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              RW_dataWakelRaport,
              RW_gotowaslrentsearch,
              RW_gotowaslparasearch,
              RW_gotowaslGaranawasearch,
              RW_hsabat,
            } = value;
            const wakelRaport = RW_dataWakelRaport.sort(
              (a, b) =>
                new Date(...a.barwar.split("/").reverse()) -
                new Date(...b.barwar.split("/").reverse())
            ).map((user, i) => {
              return (
                <>
                  {RW_hsabat ? (
                    <tr key={i} className="  ">
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        <NumberFormat
                          value={Number(user.kodolar).toFixed(2)}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        <NumberFormat
                          value={user.nrx}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.darzan}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        {user.type}
                      </td>
                      <td
                        className=""
                        style={{
                          border: "2px solid #f0ad4e",
                        }}
                      >
                        {user.jwlla}
                      </td>
                      <td
                        className=""
                        style={{
                          border: "2px solid #f0ad4e",
                          cursor: "pointer",
                        }}
                      >
                        {user.nawisht}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        <Time
                          value={user.barwar}
                          format="YYYY-MM-DD"
                          style={{ color: "warning" }}
                        />
                      </td>
                    </tr>
                  ) : (
                    <tr key={i} className="  ">
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        <NumberFormat
                          value={
                            Number.isInteger(Number(user.paraymawa))
                              ? Number(user.paraymawa)
                              : Number(user.paraymawa).toFixed(2)
                          }
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        <NumberFormat
                          value={Number(user.paraydraw).toFixed(2)}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        <NumberFormat
                          value={user.kmkrdnawa}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </td>
                      <td
                        style={{ border: "1px solid #f0ad4e" }}
                        className={` ${
                          user.joripara === "دۆلار" ? "dark-green" : "dark-red"
                        }`}
                      >
                        {user.joripara}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        <NumberFormat
                          value={
                            Number.isInteger(Number(user.koywasl))
                              ? Number(user.koywasl)
                              : Number(user.koywasl).toFixed(2)
                          }
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </td>
                      <td
                        className=""
                        style={{
                          border: "2px solid #f0ad4e",
                          color: `${
                            user.jwlla === "گەڕانەوەی قەرز"
                              ? " #0000b3"
                              : user.jwlla === "گەڕانەوەی کاڵا"
                              ? " #cc6600"
                              : user.jwlla === "قەرزی سەر دەفتەر"
                              ? "#ff0000"
                              : ""
                          }`,
                        }}
                      >
                        {user.jwlla}
                      </td>
                      <td className="" style={{ border: "1px solid #f0ad4e" }}>
                        <Time
                          value={user.barwar}
                          format="YYYY-MM-DD"
                          style={{ color: "warning" }}
                        />
                      </td>

                      <td
                        className=""
                        style={{
                          border: "2px solid #f0ad4e",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          user.jwlla === "فرۆشتن"
                            ? RW_gotowaslrentsearch(e, user.code)
                            : user.jwlla === "گەڕانەوەی قەرز"
                            ? RW_gotowaslparasearch(e, user.code)
                            : RW_gotowaslGaranawasearch(e, user.code);
                        }}
                      >
                        <Link
                          to={`${
                            user.jwlla === "فرۆشتن"
                              ? "/rent"
                              : user.jwlla === "گەڕانەوەی قەرز"
                              ? "/take-money"
                              : "/back-koga"
                          }`}
                        >
                          {user.code}
                        </Link>
                      </td>
                    </tr>
                  )}
                </>
              );
            });

            return (
              <div className="tableFixHead ">
                <table
                  id="example"
                  className="table  table-bordered text-right table-hover"
                >
                  <thead>
                    {RW_hsabat ? (
                      <tr
                        className="text-warning  "
                        style={{ backgroundColor: "#dadad0" }}
                      >
                        <th style={{ border: "1px solid #f0ad4e" }}>
                          کۆی گشتی
                        </th>
                        <th style={{ border: "1px solid #f0ad4e" }}>نرخ</th>
                        <th style={{ border: "1px solid #f0ad4e" }}>دانە</th>
                        <th style={{ border: "1px solid #f0ad4e" }}>جۆر</th>
                        <th style={{ border: "1px solid #f0ad4e" }}>جوولە</th>
                        <th style={{ border: "1px solid #f0ad4e" }}>
                          ناوی کاڵا
                        </th>
                        <th style={{ border: "1px solid #f0ad4e" }}>بەروار</th>
                      </tr>
                    ) : (
                      <tr
                        className="text-warning  "
                        style={{ backgroundColor: "#dadad0" }}
                      >
                        <th style={{ border: "1px solid #f0ad4e" }}>
                          پارەی ماوە
                        </th>
                        <th style={{ border: "1px solid #f0ad4e" }}>
                          پارەی دراو
                        </th>
                        <th style={{ border: "1px solid #f0ad4e" }}>داشکان</th>
                        <th style={{ border: "1px solid #f0ad4e" }}>دراو</th>
                        <th style={{ border: "1px solid #f0ad4e" }}>
                          کۆی پسوولە
                        </th>
                        <th style={{ border: "1px solid #f0ad4e" }}>جوولە</th>
                        <th style={{ border: "1px solid #f0ad4e" }}>بەروار</th>
                        <th style={{ border: "1px solid #f0ad4e" }}>ژمارە</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>{wakelRaport}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
