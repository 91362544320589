import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableRaportWakel from "./ScroollTableRaportWakel";
import NavbarOfRaportWakel from "./NavbarOfRaportWakel";
import FooterOfRaportWakel from "./FooterOfRaportWakel";
import Time from "react-time-format";
import { ProductConsumer } from "../../../Context/AddProWakel";
import { Link } from "react-router-dom";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";

const AllWakelRaport = () => {
  var today = new Date();
  var kat =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  return (
    <ProductConsumer>
      {(value) => {
        const {
          RW_zh_barez,
          RW_nawibrez,
          AddProChange,
          RW_from,
          RW_to,
          exportToCSV,
          RW_dataWakelRaport,
          getPrint,
          RW_jwalakan,
        } = value;

        return (
          <div>
            <Navbar />
            {isMobile ? (
              <AllMob />
            ) : (
              <>
                <div
                  className="row"
                  style={{ backgroundColor: "#2e3541 ", marginTop: "60px" }}
                >
                    <div className="col-md-1 text-white font-weight-bold mt3 tc">
                    {" "}
                    <button
                      className="btn btn-info"
                      onClick={(e) => {
                        RW_jwalakan(e);
                      }}
                    >
                      پیشاندان
                    </button>
                  </div>
                    <div className="col-md-3 text-white font-weight-bold mt3 tc">
                    <span className="text-warning">
                      <input
                        type="date"
                        onChange={AddProChange}
                        name="RW_to"
                        value={RW_to}
                        className="selectpicker btn btn-info"
                      />
                    </span>
                    :بۆ بەرواری{" "}
                  </div>
                    <div className="col-md-3 text-white font-weight-bold mt3 tc">
                    <span className="text-warning">
                      {" "}
                      <input
                        type="date"
                        onChange={AddProChange}
                        name="RW_from"
                        value={RW_from}
                        className="selectpicker btn btn-info"
                      />
                    </span>
                    :لە بەرواری
                  </div>
                    <div className="col-md-5" style={{ paddingTop: "25px" }}>
                    <div className="row">
                        <div className="col-md-2">
                        <h4 className="fk text-black font-weight-bold  bg-white tc py-2">
                          {RW_zh_barez}
                        </h4>
                      </div>
                        <div className="col-md-3">
                        <h4 className="fk text-black font-weight-bold  bg-white tc py-2">
                          {RW_nawibrez}
                        </h4>
                      </div>
                        <div className="col-md-2">
                        <span
                          className={`font-weight-bold  pointer text-warning`}
                          style={{ paddingLeft: "80px" }}
                        >
                          <button
                            className="btn btn-warning"
                            onClick={(e) =>
                              exportToCSV(RW_dataWakelRaport, `${kat}-وەکیل`)
                            }
                          >
                            دابەزاندن
                          </button>
                        </span>
                      </div>
                        <div className="col-md-2">
                        <span
                          className={`font-weight-bold  pointer text-warning`}
                          style={{ paddingLeft: "80px" }}
                        >
                          <Link to="/print-raportywakel">
                            <button
                              className="btn btn-warning"
                              onClick={(e) => getPrint(e)}
                            >
                              چاپکردن
                            </button>
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                    <div className="col-lg-10 col-md-10">
                    <ScroollTableRaportWakel />
                  </div>
                    <div className="col-lg-2 col-md-2">
                    <NavbarOfRaportWakel />
                  </div>
                </div>
                <div className="row" style={{ backgroundColor: "#2e3541 " }}>
                    <div className="col-lg-12 col-md-12">
                    <FooterOfRaportWakel />
                  </div>
                </div>
              </>
            )}
          </div>
        );
      }}
    </ProductConsumer>
  );
};
export default AllWakelRaport;
