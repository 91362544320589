import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableKogaRaport from "./ScroollTableKogaRaport";
import FooterOfRaportKoga from "./FooterOfRaportKoga";
import FillDetailKogaRaport from "./FillDetailKogaRaport";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";
const AllKogaRaport = () => {
  return (
    <div>
      <Navbar />
      {isMobile ? (
        <AllMob />
      ) : (
        <div style={{ marginTop: "50px" }}>
          <div className="row" style={{ backgroundColor: "#2e3541 " }}>
              <div className="col-md-12 ">
              <FillDetailKogaRaport />
            </div>
          </div>
          <div className="row">
              <div className="col-md-12 ">
              <ScroollTableKogaRaport />
            </div>
          </div>
          <div className="row" style={{ backgroundColor: "#2e3541 " }}>
              <div className="col-lg-12 col-md-12">
              <FooterOfRaportKoga />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllKogaRaport;
