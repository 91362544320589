import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableKren from "./ScroollTableKren";
import FooterOfKren from "./FooterOfKren";
import FillDetailKren from "./FillDetailKren";
import FilldetailRemtMob from "./Mob/FilldetailRemtMob";
import FooterMob from "./Mob/FooterMob";
import ScrollTableMob from "./Mob/ScrollTableMob";
import { isMobile } from "react-device-detect";

const AllKren = () => {
  return (
    <>
      <Navbar />
      <div style={{ backgroundColor: "#2e3541 " }}>
        {isMobile ? <FilldetailRemtMob /> : <FillDetailKren />}

        <br></br>
        <div className="bg-white">
          {isMobile ? <ScrollTableMob /> : <ScroollTableKren />}
        </div>
        <br></br>
        {/* wasl para */}
        {isMobile ? <FooterMob /> : <FooterOfKren />}
      </div>
      {/* end wasl u para */}
    </>
  );
};

export default AllKren;
