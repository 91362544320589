import React from "react";
import MasrufatList from "./MasrufatList";

const MasrufatAll = () => {
  return (
    <>
      <div style={{ backgroundColor: "#2C3539" }}>
        <MasrufatList />
      </div>
    </>
  );
};

export default MasrufatAll;
