import React, { Component } from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import styled from "styled-components";
import { ButtonContainer } from "../../ButtonContainer";
import { Link } from "react-router-dom";

class ModelRaportKoga extends Component {
  render() {
    return (
      <ProductConsumer>
        {(value) => {
          const {
            JM_modalOpen,
            JM_CloseModal,
            JM_pshandan,
            JM_barwar,
            AddProChange,
          } = value;

          if (!JM_modalOpen) {
            return null;
          } else {
            return (
              <ModalContainer>
                <div className="container">
                  <div className="row">
                    <div
                      id="modal"
                      className="col-lg-8 col-sm-12 mx-auto col-md-10 col-lg-6 text-center text-capitalize p-5"
                    >
                      <div className="row">
                        <div className="col-md-12 mb2">
                          <h3>ڕاپۆرتی کۆگا</h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 ">
                          <div className="form-group">
                            <label className="text-danger f3 font-weight-bold">
                              بەرواری
                            </label>
                            <br />
                            <input
                              type="date"
                              name="JM_barwar"
                              value={JM_barwar === "" ? Date.now() : JM_barwar}
                              onChange={AddProChange}
                              className="selectpicker btn btn-dark f4"
                            />
                          </div>
                        </div>
                        <div className="col-md-3"></div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <Link to="/Product-Raport">
                            <button
                              className="btn btn-success"
                              onClick={() => JM_CloseModal()}
                            >
                              چوونەدەرەوە
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/Raport-kogajard">
                            <button
                              className="btn btn-info px-4"
                              onClick={() => {
                                JM_pshandan();
                              }}
                            >
                              پیشاندان
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalContainer>
            );
          }
        }}
      </ProductConsumer>
    );
  }
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  #modal {
    background: #f3e29f;
  }
`;

export default ModelRaportKoga;
