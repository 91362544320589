import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class Kashf7isab extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { KF_datatable, KF_gotowaslrentsearch } = value;

            const wakil = KF_datatable.map((user, i) => {
              return (
                <tr key={i} className="fk f4" style={{ fontSize: "10px" }}>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1.6em",
                      color: "black",
                    }}
                  >
                    <NumberFormat
                      value={user.total}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1.6em",
                      color: "black",
                    }}
                  >
                    <NumberFormat
                      value={user.darzan}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1.6em",
                      color: "black",
                    }}
                  >
                    <NumberFormat
                      value={user.carton}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1.6em",
                      color: "black",
                    }}
                  >
                    {user.joridraw}
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1.6em",
                      color: "black",
                    }}
                  >
                    <NumberFormat
                      value={user.nrx}
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1.6em",
                      color: "black",
                    }}
                  >
                    {user.name}
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1.6em",
                      color: "black",
                    }}
                  >
                    {user.jwlla}
                  </td>
                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1.6em",
                      color: "black",
                    }}
                  >
                    <Time
                      value={user.date}
                      format="YYYY-MM-DD"
                      style={{ color: "black" }}
                    />
                  </td>

                  <td
                    className="fk"
                    style={{
                      borderBottom: "2px solid black",
                      borderTop: "2px solid black",
                      borderRight: "2px solid black",
                      borderLeft: "2px solid black",
                      fontSize: "1.6em",
                      color: "black",
                    }}
                  >
                    {user.code}
                  </td>
                </tr>
              );
            });

            return (
              <table id="example" className="table table-striped  text-right ">
                <thead>
                  <tr
                    className="text-warning f4"
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      کۆی گشتی
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      مەتر/دەرزەن
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      لەفە/کارتۆن
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      دراو
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      نرخ
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      ناوی کاڵا
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      جوڵە
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      بەروار
                    </th>
                    <th
                      className="f3"
                      style={{
                        borderBottom: "2px solid black",
                        borderTop: "2px solid black",
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        color: "black",
                        backgroundColor: "gray",
                      }}
                    >
                      ژ.پسولە
                    </th>
                  </tr>
                </thead>
                <tbody>{wakil}</tbody>
              </table>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Kashf7isab;
