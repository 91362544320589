import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScoollTableKashfKas from "./ScoollTableKashfKas";
import Time from "react-time-format";
import { ProductConsumer } from "../../../Context/AddProWakel";
import FooterKashfKas from "./FooterKashfKas";
import { isMobile } from "react-device-detect";
import AllMob from "./Mob/AllMob";

const AllKashfKas = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const { KT_zhmaraybarez, KT_nawe_barez, KT_mob, KT_from, KT_to } =
          value;

        return (
          <>
            {isMobile ? (
              <AllMob />
            ) : (
              <div>
                <Navbar />
                <div className="row" style={{ backgroundColor: "#2C3539" }}>
                  <div
                      className="col-lg-10 col-md-12"
                    style={{ marginTop: "60px" }}
                  >
                    <div className="row">
                        <div className="col-md-3">
                        <div className="row">
                            {/* <div className="col-md-3"></div> */}
                            <div className="col-md-6  tc form-inline">
                            <p
                              className="font-weight-bold bg-white  f4"
                              style={{ color: "#000" }}
                            >
                              <Time format="YYYY-MM-DD" />
                            </p>
                            <span style={{ color: "#2C3539" }}>:</span>
                            <p
                              className="font-weight-bold bg-white  f4"
                              style={{ color: "#000" }}
                            >
                              {KT_to}
                            </p>
                          </div>
                            <div className="col-md-6 form-inline">
                            <label className="text-warning font-weight-bold f4">
                              :بۆ بەرواری{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                        <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-6 tc form-inline">
                            <p
                              className="font-weight-bold bg-white f4"
                              style={{ color: "#000" }}
                            >
                              <Time format="YYYY-MM-DD" />
                            </p>
                            <span style={{ color: "#2C3539" }}>:</span>
                            <p
                              className="font-weight-bold bg-white f4"
                              style={{ color: "#000" }}
                            >
                              {KT_from}
                            </p>
                          </div>
                            <div className="col-md-6 form-inline">
                            <label className="text-warning font-weight-bold f4">
                              : لە بەرواری{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                        <div className="col-md-6" style={{ paddingTop: "25px" }}>
                        <div className="row">
                            <div className="col-md-2">
                            <h4 className="fk text-black font-weight-bold f4 bg-white tc py-2">
                              {KT_zhmaraybarez}
                            </h4>
                          </div>
                            <div className="col-md-5">
                            <h4 className="fk text-black font-weight-bold f4 bg-white tc py-2">
                              {KT_mob}
                            </h4>
                          </div>
                            <div className="col-md-5">
                            <h4 className="fk text-black font-weight-bold f4 bg-white tc py-2">
                              {KT_nawe_barez}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                    <div className="col-md-12 ">
                    <ScoollTableKashfKas />
                  </div>
                </div>
                <div className="row" style={{ backgroundColor: "#2C3539" }}>
                    <div className="col-lg-12 col-md-12">
                    <FooterKashfKas />
                  </div>
                </div>
              </div>
            )}
          </>
        );
      }}
    </ProductConsumer>
  );
};
export default AllKashfKas;
