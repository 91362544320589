import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {ProductConsumer} from '../Context/AddProWakel'

import PropTypes from 'prop-types'
class Product extends React.Component{
    render(){
        const {id,title,img} =this.props.product
        return(
            <>
                <ProductWraper className='col-9 mx-auto col--6 col-lg-3 my-3'>
            <div className='card '>
              <ProductConsumer>
               
                {(value)=>(
                     
                <div className='img-container p-5' >
                <Link to={`${id===1?`/rent?page=${value.R_zh_wasl_katy}`:(id===2?'/add-product':(id===3?"/Raport-qarzeWakel":(id===4?'/take-money':(id===8?'/masrufat':(id===5?'/back-koga':(id===7?"/Product-Raport":"/"))))))}`}>
                <img src={img} onClick={id===6?()=>value.Q_OpenModal(value.L_user._id):(id===3?(e)=>value.QW_qazrywakil(e,value.L_user._id):"")} alt='product' className='card-img-top '/> 
                </Link>
             </div>
                )

                }
               
                </ProductConsumer>          
                {/* footer of cart */}
                <div className='card-footer d-flex justify-content-center h2'style={{color:"#000C66"}}>
                   <p className='align-self-center mb4 '>{title}</p>

                </div>
            </div>
        </ProductWraper>
      
       </> )
    }
}

 Product.propTypes= {
    product:PropTypes.shape({
        id:PropTypes.number,
        img:PropTypes.string,
        title:PropTypes.string,
        price:PropTypes.number,
        inCart:PropTypes.bool
    }).isRequired
}

const ProductWraper=styled.div`
.card {
    border-color:transparent;
    transition:all 1s linear;
}

.card-footer{
    background:transparent;
    border-top:transparent;
    transition:all 1s linear;
}
&:hover{
.card{
    border:0.04rem solid rgba(0,0,0,0.2);
    box-shadow:2px 2px 5px 0px rgba(0,0,0,0.2)
}

.card-footer {
    background:rgba()
}
}
.img-container{
    position:relative;
    overflow:hidden;
}
.card-img-top{
    transition:all 1s linear
}
.img-container:hover .card-img-top {
    transform:scale(1.2);
}
.card-btn{
    position:absolute;
    bottom:0;
    right:0;
    padding:0.2rem 0.4rem;
    background: var(--lightBlue);
    border:none;
    color:var(--mainWhite);
    font-size:1.4rem;
    border-radius:.5rem 0 0 0;
    transform:translate(100%,100%);
    transition:all 1s linear;
}
.img-container:hover .card-btn  {
    transform:translate(0, 0);
}  
.card-btn:hover{
    color:var(--mainBlue);
    cursor:pointer;
}
`

export default Product;