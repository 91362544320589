import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { QW_dataWakelQarz, QW_zhmara, Qw_naw } = value;
            const qarzeWakel = QW_dataWakelQarz.filter((data) => {
              if (Qw_naw !== "") {
                return data.Name.toLowerCase().startsWith(Qw_naw.toLowerCase());
              } else if (QW_zhmara !== "") {
                return data.code.toString().startsWith(QW_zhmara);
              } else {
                return data;
              }
            }).map((user, i) => {
              return (
                <tr key={i} className=" ">
                  <td
                    className={` bg-${
                      Number(user.rejaDinar) !== 0 ? "green" : ""
                    }`}
                    style={{ border: "2px solid #f0ad4e" }}
                  >
                    <NumberFormat
                      value={Number(user.rejaDinar).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </td>
                  <td
                    style={{ border: "2px solid #f0ad4e" }}
                    className={` bg-${
                      Number(user.rejaDolar) !== 0 ? "red" : ""
                    }`}
                  >
                    <NumberFormat
                      value={Number(user.rejaDolar).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.phone}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.Name}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.code}
                  </td>
                </tr>
              );
            });

            return (
              <div className="JM ">
                <table
                  id="example"
                  className="table table-hover table-bordered text-right"
                >
                  <thead>
                    <tr
                      className="text-warning "
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ID ڕەسید بە
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        $ ڕەسید بە{" "}
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>تەلەفۆن</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ناوەی وەکیل
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                    </tr>
                  </thead>
                  <tbody>{qarzeWakel}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
