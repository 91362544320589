import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScrollTableKalaRaport from "./ScrollTableKalaRaport";
import NavbarOfRaportKala from "./NavbarOfRaportKala";
import FooterOfKalaRaport from "./FooterOfKalaRaport";
import { ProductConsumer } from "../../../Context/AddProWakel";
import Time from "react-time-format";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";
const AllKalaRaport = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            K_from,
            K_to,
            K_naweKala,
            K_zhamara,
            K_grantr,
            K_harzantr,
            K_hamannrx,
          } = value;
          return (
            <>
              <div>
                <Navbar />
                {isMobile ? (
                  <>
                    <AllMob />
                  </>
                ) : (
                  <>
                    <div
                      className="row"
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <div
                          className="col-lg-10 col-md-12"
                        style={{ marginTop: "20px" }}
                      >
                        <div className="row">
                          {/* start */}
                            <div className="col-lg-4 col-md-6">
                            <div className="row form-inline">
                                {/* <div className="col-md-3"></div> */}
                                <div className="col-md-7 tc ">
                                <p
                                  className="font-weight-bold bg-white  "
                                  style={{ color: "#000" }}
                                >
                                  <Time />
                                </p>
                                <span
                                  style={{ color: "#2e3541 " }}
                                  className="text-warning"
                                >
                                  :
                                </span>
                                <p
                                  className="font-weight-bold bg-white  "
                                  style={{ color: "#000" }}
                                >
                                  {K_to}
                                </p>
                              </div>
                                <div className="col-md-5 mt3">
                                <label className="font-weight-bold  text-warning">
                                  :بۆ بەرواری{" "}
                                </label>
                              </div>
                            </div>
                          </div>
                          {/*end  */}
                            <div className="col-lg-4 col-md-6">
                            <div className="row form-inline">
                                <div className="col-md-7 tc">
                                <p
                                  className="font-weight-bold bg-white  "
                                  style={{ color: "#000" }}
                                >
                                  <Time />
                                </p>
                                <span style={{ color: "#2e3541 " }}>:</span>
                                <p
                                  className="font-weight-bold bg-white  "
                                  style={{ color: "#000" }}
                                >
                                  {K_from}
                                </p>
                              </div>
                                <div className="col-md-5 mt3">
                                <label className=" font-weight-bold  text-warning">
                                  : لە بەرواری{" "}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* second row */}

                    <div
                      className="row"
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                        <div className="col-lg-6 col-md-6">
                        <div className="row">
                            <div className="col-lg-3 col-md-1"></div>
                            <div className="col-lg-5 col-md-7">
                            <h4
                              className=" font-weight-bold f3 bg-white tc"
                              style={{ color: "#000" }}
                            >
                              {K_naweKala}
                            </h4>
                          </div>
                            <div className="col-lg-2 col-md-4">
                            <h4
                              className=" font-weight-bold f3 bg-white tc"
                              style={{ color: "#000" }}
                            >
                              {K_zhamara}
                            </h4>
                          </div>
                        </div>
                      </div>
                        <div className="col-lg-4 col-md-6">
                        <div className="row">
                            <div className="col-lg-3 col-md-4">
                            <button
                              className="btn  btn-success"
                              onClick={K_grantr}
                            >
                              گرانتر
                            </button>
                          </div>
                            <div className="col-lg-3 col-md-4">
                            <button
                              className="btn  btn-danger"
                              onClick={K_harzantr}
                            >
                              هەرزانتر
                            </button>
                          </div>
                            <div className="col-lg-3 col-md-4">
                            <button
                              className="btn btn-info"
                              onClick={K_hamannrx}
                            >
                              نرخی خۆی
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                        <div className="col-lg-10 col-md-10">
                        <ScrollTableKalaRaport />
                      </div>
                        <div className="col-lg-2 col-md-2">
                        <NavbarOfRaportKala />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                        <div className="col-lg-12 col-md-12">
                        <FooterOfKalaRaport />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default AllKalaRaport;
