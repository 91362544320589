import React from 'react'
import {ProductConsumer} from '../../../Context/AddProWakel'
import NumberFormat  from 'react-number-format'

const SearchParaGorenawa = () => {
  return (
    <>
    <ProductConsumer>
    {value=>{
        const {PG_note,AddProChange,PG_getParaDataBySearchMoney,PG_zhmara
    } = value

        return(
        <>
            <div className="card card-primary text-warning font-weight-bold"style={{backgroundColor:"#2C3539"}}>
              <div className="card-header border-bottom border-warning"style={{backgroundColor:"#2C3539"}}>
                <h3 className="card-title tc "> گەران بە دوای </h3>
              </div>

              <form>
                <div className="card-body text-right">
                  <div className="form-group">
                    <label className='font-weight-bold f4'>:پسوولە</label>
                    <input onKeyPress={event=>event.key==='Enter'?PG_getParaDataBySearchMoney(event):null} type="text" onChange={AddProChange} value={PG_zhmara} name="PG_zhmara" className="form-control text-right font-weight-bold" placeholder="...گەڕان"/>
                  </div>
                  <br/>

                  <div className="form-group">
                    <label className='font-weight-bold f4'>:تێبینی </label>
                    <input type="text" onChange={AddProChange} value={PG_note} name="PG_note" className="form-control text-right font-weight-bold"  placeholder="...تێبینی"/>
                  </div>

                </div>
                <div className="col-md-12 text-right ml5">
      </div>
                <div className="card-footer"style={{backgroundColor:"#2C3539"}}>
                <br/><br/>
                </div>
              </form>
            </div>
            </>
                    )

                  }}
  
              </ProductConsumer>

    </>)
}

export default SearchParaGorenawa
