import React from 'react'
import rahel from './../../../img/rahel1.jpg'
import FillParadarhenanPrint from './FillParadarhenanPrint'
const PrintAllParadarhenan = () => {
    return (
        <>
                    <img src={rahel}  alt='rahel'/>
                    <FillParadarhenanPrint/>
        </>
    )
}

export default PrintAllParadarhenan
