import React from "react";
import { Link } from "react-router-dom";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
const Header = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const { KT_getPrint, KT_nawe_barez } = value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning ">
                <Link to="/Print_kas_kashf_hesab">
                  <button className="btn btn-warning" onClick={KT_getPrint}>
                    چاپکردن
                  </button>
                </Link>
              </div>
              <div className="col-md-9 mt1 font-weight-bold   form-group text-warning tc">
                <h4 className="">کەشف حساب</h4>
                <h6 className="text-white">{KT_nawe_barez}</h6>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
