import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableKoga3arz from "./ScroollTableKoga3arz";
import FooterKoga3arz from "./FooterKoga3arz";
import FillDetailKoga3arz from "./FillDetailKoga3arz";
const AllKoga3arz = () => {
  return (
    <div>
      <Navbar />
      <div style={{ marginTop: "50px" }}>
        <div className="row" style={{ backgroundColor: "#2C3539" }}>
          <div className="col-md-12 ">
            <FillDetailKoga3arz />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <ScroollTableKoga3arz/>
          </div>
        </div>
        <div className="row" style={{ backgroundColor: "#2C3539" }}>
          <div className="col-lg-12 col-md-12">
            <FooterKoga3arz />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllKoga3arz;
