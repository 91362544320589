import React from 'react'
import Navbar from '../../../Navbar/Navbar'
import AddproductCinRight from './AddProductCinRight'
import AddProductCinLeft from './AddProductCinLeft'

const AddProductCin = () => {
    return (
        <>
        <Navbar/>
    <div className='mt-5'></div>
      <div className='row'>
                <div className='col-md-12 col-lg-5 '>
              <AddProductCinLeft/>
            </div>
             <AddproductCinRight/>
      </div>
            </>
    )
}

export default AddProductCin
