import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableMasrufat from "./ScroollTableMasrufat";
import FooterOfMasrufat from "./FooterOfMasrufat";
import FillDetailMasrufat from "./FillDetailMasrufat";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";
const AllMasrufat = () => {
  return (
    <div>
      <Navbar />
      {isMobile ? (
        <AllMob />
      ) : (
        <div style={{ marginTop: "50px" }}>
          <div className="row" style={{ backgroundColor: "#2e3541 " }}>
              <div className="col-md-12 ">
              <FillDetailMasrufat />
            </div>
          </div>
          <div className="row">
              <div className="col-md-12 ">
              <ScroollTableMasrufat />
            </div>
          </div>
          <div className="row " style={{ backgroundColor: "#2e3541 " }}>
              <div className="col-md-12 col-md-12">
              <FooterOfMasrufat />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllMasrufat;
