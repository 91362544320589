import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Navbar from "../../../Navbar/Navbar";

const KoyQarziAsto = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { QA_qarzdolar, QA_qarzdinar } = value;

          return (
            <>
              <Navbar />

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

              <div className="row ">
                <div className="col-md-3"></div>
                {/* Form take moneyy */}
                <div className="col-lg-6 text-warning col-md-12 ">
                  <div
                    className="card card-warning "
                    style={{ backgroundColor: "#2e3541 " }}
                  >
                    <div
                      className="card-header p-1 border-bottom border-warning"
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <h2 className="card-title tc">كۆی قەرزی ئەستۆ</h2>
                    </div>

                    <div className="card-body">
                      <form>
                        <div className="row">
                          <br />
                          <br />
                          <br />
                          <br />

                          <div className="col-sm-6 text-right mt-2">
                            <div className="form-group">
                              <label className="font-weight-bold ">
                                ID قەرزی ئەستۆ بە
                              </label>
                              <NumberFormat
                                value={Number(QA_qarzdinar).toFixed(2)}
                                displayType={"text"}
                                className="form-control  text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"ID "}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 text-right mt-2">
                            <div className="form-group">
                              <label className="font-weight-bold ">
                                $ قەرزی ئەستۆ بە
                              </label>
                              <NumberFormat
                                value={Number(QA_qarzdolar).toFixed(2)}
                                displayType={"text"}
                                className="form-control  text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$ "}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 text-right"></div>
                          <div className="col-sm-4 text-right"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default KoyQarziAsto;
