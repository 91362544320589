import React from "react";
import { Link } from "react-router-dom";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
const Header = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const {
          AddProChange,
          RPD_from,
          RPD_to,
          RPD_getPrint,
          RPD_filterPara,
          RPD_peshandan,
        } = value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning ">
                <Link to="/print-paradan-raport">
                  <button className="btn btn-warning" onClick={RPD_getPrint}>
                    چاپکردن
                  </button>
                </Link>
              </div>
              <div className="col-md-9 mt1 font-weight-bold   form-group text-warning tc">
                <h4 className=""> ڕاپۆرتی پارەدان</h4>
              </div>
            </div>

            <div
              className="row text-right pt2"
              style={{ backgroundColor: "#2e3541 " }}
            >
              <div className="col-md-2 mt4 text-warning font-weight-bold  pointer form-group">
                {" "}
                <button className="btn btn-info" onClick={RPD_peshandan}>
                  پیشاندان
                </button>
              </div>
              <div className="col-md-5 text-warning font-weight-bold  pointer form-group">
                :بۆ بەرواری{" "}
                <span className="text-warning">
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RPD_to"
                    value={RPD_to}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
              <div className="col-md-5 text-warning font-weight-bold  pointer form-group">
                :لە بەرواری
                <span className="text-warning">
                  {" "}
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RPD_from"
                    value={RPD_from}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
              <div className="col-md-4 text-warning font-weight-bold  pointer form-group">
                <button
                  className="btn  btn-success "
                  onClick={() => RPD_filterPara("$")}
                >
                  $
                </button>
              </div>
              <div className="col-md-4 text-warning font-weight-bold  pointer form-group">
                <button
                  className="btn  btn-warning"
                  onClick={() => RPD_filterPara("ID")}
                >
                  ID
                </button>
              </div>
              <div className="col-md-4 text-warning font-weight-bold  pointer form-group">
                <button
                  className="btn  btn-info ml3"
                  onClick={() => RPD_filterPara("A")}
                >
                  A
                </button>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
