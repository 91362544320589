import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "tachyons";
import "animate.css";
import ProductList from "./Components/ProductList";
import TableRent from "./Components/tables/TableForRent/TableRent";
import AddProduct from "./Components/tables/Addproduct/AddProduct";
import InputWakel from "./Components/tables/addWakel/InputWakel";
import TakeMoney from "./Components/tables/TakeMoney/TakeMoney";
import AddUser from "./Navbar/Adduser/AddUser";
import Setting from "./Components/tables/Setting/Setting";
import { ProductConsumer } from "./Context/AddProWakel";
import Login from "./Components/Form/Login";
import TableRentAllPrint from "./Components/printTables/PrintRent/TableRentAllPrint";
import InputPrintWakel from "./Components/printTables/PrintWakel/InputPrintWakel";
import PrintAllTakeMoney from "./Components/printTables/TakeMoneyPrint/PrintAllTakeMoney";
import AllKoga from "./Components/tables/GaranawaBoKoga/AllKoha";
import TableAllprintgaranawa from "./Components/printTables/printGaranawa/TableAllprintgaranawa";
import AllQazanj from "./Components/tables/Qazanj/AllQazanj";
import ModelQazanj from "./Components/tables/Qazanj/ModelQazanj";
import ProductRaportAll from "./Components/ProductRaportAll";
import ModelRaportKoga from "./Components/tables/RaportyKoga/ModelRaportKoga";
import ModelRozhana from "./Components/tables/RaportyRozhana/ModelRozhana";
import ModelWakel from "./Components/tables/Raportywakel/ModelWakel";
import ModelKashfWakel from "./Components/tables/Kashf7isab/ModelKashfWakel";
import AllRozhana from "./Components/tables/RaportyRozhana/AllRozhana";
import AllWakelRaport from "./Components/tables/Raportywakel/AllWakelRaport";
import AlKashflWakelRaport from "./Components/tables/Kashf7isab/AlKashflWakelRaport";
import AllQarzWakel from "./Components/tables/RaportyQarzeWakel/AllQarzWakel";
import AllKogaRaport from "./Components/tables/RaportyKoga/AllKogaRaport";
import ModelKalaRaport from "./Components/tables/RaportyKala/ModelKalaRaport";
import AllKalaRaport from "./Components/tables/RaportyKala/AllKalaRaport";
import AllQarzRastawxo from "./Components/tables/RaportyQarzeRastawxo/AllQarzRastawxo";
import ModalQarzRastawxo from "./Components/tables/RaportyQarzeRastawxo/ModalQarzRastawxo";
import AddProductCin from "./Components/tables/RaportyCin/AddProductCin";
import InputRaportyWakel from "./Components/printTables/PrintRaportyWakel/InputRaportyWakel";
import InputRaportyRozhana from "./Components/printTables/PrintRaportyRozhana.js/InputRaportyRozhana";
import AllKalayNafroshraw from "./Components/tables/RaportykalayNafroshraw/Allkalaynafroshraw";
import ModelKalayNafroshraw from "./Components/tables/RaportykalayNafroshraw/ModelKalayNafroshraw";
import AllParayNagarawa from "./Components/tables/RaportyParayNagarawa/AllParayNagarawa";
import ModelParayNagarawa from "./Components/tables/RaportyParayNagarawa/ModelParayNagarawa";
import MasrufatAll from "./Components/MasrufatAll";
import Paradan from "./Components/tables/Paradan/Paradan";
import InputMasruf from "./Components/tables/msruf/InputMasruf";
import InputPeople from "./Components/tables/AddPeople/InputPeople";
import Paradarhenan from "./Components/tables/paradarhenan/Paradarhenan";
import Mandub from "./Components/tables/Mandub/Mandub";
import AllParadan from "./Components/tables/RaportyParadan/AllParadan";
import AllParayDarhenraw from "./Components/tables/RaportyParayDarhenraw/AllParayDarhenraw";
import AllMasrufat from "./Components/tables/raportymasrufat/AllMasrufat";
import ModelOfParadan from "./Components/tables/RaportyParadan/ModelOfParadan";
import ModelOfParayDarhenraw from "./Components/tables/RaportyParayDarhenraw/ModelOfParayDarhenraw";
import ModelOfMasrufat from "./Components/tables/raportymasrufat/ModelOfMasrufat";
import AllKasRaport from "./Components/tables/RaportyKasakan/AllKasRaport";
import ModelKas from "./Components/tables/RaportyKasakan/ModelKas";
import AllMandub from "./Components/tables/RaportyMandub/AllMandub";
import ModelOfMandub from "./Components/tables/RaportyMandub/ModelOfMandub";
import Parawargrtn from "./Components/tables/Parawargrtn/Parawargrtn";
import AllParawargrtnawakan from "./Components/tables/raportyParawargrtnawa/AllParawargrtnawakan";
import ModelOfParawargrtnawakan from "./Components/tables/raportyParawargrtnawa/ModelOfParawargrtnawakan";
import PrintAllMandub from "./Components/printTables/PrintMandub/PrintAllMandub";
import PrintAllParadan from "./Components/printTables/printParadan/PrintAllParadan";
import PrintAllParadarhenan from "./Components/printTables/PrintParadarhenan/PrintAllParadarhenan";
import PrintAllParawargrtn from "./Components/printTables/Parawargrtn/PrintAllParawargrtn";
import FormzyadkrdniRasid from "./Components/tables/AddWakilOldRasid/FormzyadkrdniRasid";
import QarziAstoModel from "./Components/tables/KoyQarziAsto/QarziAstoModel";
import KoyQarziAsto from "./Components/tables/KoyQarziAsto/KoyQarziAsto";
import ModelQarziWakil from "./Components/tables/RaportyQarzeWakel/ModelQarziWakil";
import ParaGorenawa from "./Components/tables/ParaGorenawa/ParaGorenawa";
import AllParaGorenawa from "./Components/tables/RaportyParaGorinawa/AllParaGorenawa";
import ModelParaGorenawa from "./Components/tables/RaportyParaGorinawa/ModelParaGorenawa";
import Inputkashf from "./Components/printTables/PrintKashf7isab/Inputkashf";
import InputPrintMasruf from "./Components/printTables/RaportyKoga/InputPrintMasruf";
import ModelKoga3arz from "./Components/tables/Raporty3arz/ModelKoga3arz";
import AllKoga3arz from "./Components/tables/Raporty3arz/AllKoga3arz";
import AllKren from "./Components/tables/Kren/AllKren";
import AllKashfKas from "./Components/tables/Kashf7esabKasakan/AllKashfKas";
import ModelKashfKas from "./Components/tables/Kashf7esabKasakan/ModelKashfKas";
import InputKashfKas from "./Components/printTables/PrintKasakan7esab/InputKashfKas";
import TableAllKren from "./Components/printTables/PrintKrenMawad/TableAllKren";
import AllBroken from "./Components/tables/Broken/AllBroken";
import IncomOut from "./Components/tables/TotalInOut/IncomOut";
import TotalnOut from "./Components/tables/TotalInOut/TotalnOut";
import InOut from "./Components/printTables/TotalInOtPrint/InOut";
import RaportWakil from "./Components/printTables/PrintWakilQarzRaport/RaportWakil";
import RaportRastawxo from "./Components/printTables/Rastawxo/RaportRastawxo";
import InputPrintArz from "./Components/printTables/Raporty3arz/InputPrintArz";
import AllKogaSummery from "./Components/tables/ProductSummery/AllKogaSummery";
import ModelSummery from "./Components/tables/ProductSummery/ModelSummery";

function App() {
  return (
    <ProductConsumer>
      {(value) => {
        const { isAuthUser } = value;

        return (
          <Router>
            <div className="App">
              {localStorage.token ? (
                <Redirect from="/login" to="/" />
              ) : (
                <Redirect from="/" to="/login" />
              )}
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/qarziasto" component={KoyQarziAsto} />
                <Route exact path="/broken" component={AllBroken} />
                <Route exact path="/" component={ProductList} />
                <Route
                  exact
                  path="/Product-Raport"
                  component={ProductRaportAll}
                />
                <Route exact path="/old-rasid" component={FormzyadkrdniRasid} />
                <Route exact path="/Kren-Mawad" component={AllKren} />
                <Route exact path="/change-money" component={ParaGorenawa} />
                <Route exact path="/print-3arz" component={InputPrintArz} />
                <Route exact path="/productSummery" component={AllKogaSummery} />
                <Route
                  exact
                  path="/raporty-change"
                  component={AllParaGorenawa}
                />
                <Route exact path="/rent" component={TableRent} />
                <Route exact path="/add-product" component={AddProduct} />
                <Route exact path="/add-wakel" component={InputWakel} />
                <Route exact path="/take-money" component={TakeMoney} />
                <Route exact path="/add-user" component={AddUser} />
                <Route exact path="/setting" component={Setting} />
                <Route exact path="/print-rent" component={TableRentAllPrint} />
                <Route
                  exact
                  path="/print-garanawa"
                  component={TableAllprintgaranawa}
                />
                <Route exact path="/print-wakel" component={InputPrintWakel} />
                <Route exact path="/print-wakel-qarz" component={RaportWakil} />
                <Route
                  exact
                  path="/print-rastawxo-qarz"
                  component={RaportRastawxo}
                />
                <Route
                  exact
                  path="/print-takemoney"
                  component={PrintAllTakeMoney}
                />
                <Route exact path="/back-koga" component={AllKoga} />
                <Route exact path="/Raport-qazanj" component={AllQazanj} />
                <Route exact path="/Raport-rozhana" component={AllRozhana} />
                <Route exact path="/Raport-wakel" component={AllWakelRaport} />
                <Route
                  exact
                  path="/Raport-wakel-kashf"
                  component={AlKashflWakelRaport}
                />
                <Route exact path="/print-kashf" component={Inputkashf} />
                <Route
                  exact
                  path="/Raport-qarzeWakel"
                  component={AllQarzWakel}
                />
                <Route
                  exact
                  path="/Raport-kogajard"
                  component={AllKogaRaport}
                />
                <Route exact path="/Raport-kala" component={AllKalaRaport} />
                <Route
                  exact
                  path="/Raport-qarzrastawxo"
                  component={AllQarzRastawxo}
                />
                <Route exact path="/Raport-china" component={AddProductCin} />
                <Route
                  exact
                  path="/print-raportywakel"
                  component={InputRaportyWakel}
                />
                <Route
                  exact
                  path="/print-raportyRozhana"
                  component={InputRaportyRozhana}
                />
                <Route
                  exact
                  path="/raporty-kalaynafroshraw"
                  component={AllKalayNafroshraw}
                />
                <Route
                  exact
                  path="/raporty-paraynadraw"
                  component={AllParayNagarawa}
                />
                <Route exact path="/masrufat" component={MasrufatAll} />
                <Route exact path="/Paradan" component={Paradan} />
                <Route exact path="/Paradarhenan" component={Paradarhenan} />
                <Route exact path="/input-masruf" component={InputMasruf} />
                <Route exact path="/input-people" component={InputPeople} />
                <Route exact path="/Mandub" component={Mandub} />
                <Route exact path="/raporty-paradan" component={AllParadan} />
                <Route
                  exact
                  path="/raporty-paraydarhenraw"
                  component={AllParayDarhenraw}
                />
                <Route exact path="/raporty-masrufat" component={AllMasrufat} />
                <Route exact path="/raporty-kasakan" component={AllKasRaport} />
                <Route exact path="/raporty-mandubakan" component={AllMandub} />
                <Route exact path="/parawargrtn" component={Parawargrtn} />
                <Route
                  exact
                  path="/raporty-parawargrtn"
                  component={AllParawargrtnawakan}
                />
                <Route exact path="/print-mandub" component={PrintAllMandub} />
                <Route
                  exact
                  path="/print-paradan"
                  component={PrintAllParadan}
                />
                <Route
                  exact
                  path="/print-paradarhenan"
                  component={PrintAllParadarhenan}
                />
                <Route
                  exact
                  path="/print-parawargrtn"
                  component={PrintAllParawargrtn}
                />
                <Route exact path="/print-Koga" component={InputPrintMasruf} />
                <Route exact path="/All-Koga-3arz" component={AllKoga3arz} />
                <Route exact path="/All-Kashf-Kas" component={AllKashfKas} />
                <Route
                  exact
                  path="/Print_kas_kashf_hesab"
                  component={InputKashfKas}
                />
                <Route exact path="/Print_kren" component={TableAllKren} />
                <Route exact path="/IncomeOutCome" component={IncomOut} />
                <Route exact path="/print-hesabatKarga" component={InOut} />
              </Switch>
            </div>
            <ModelOfParawargrtnawakan />
            <ModelOfMandub />
            <ModelQazanj />
            <ModelRozhana />
            <ModelWakel />
            <ModelKashfWakel />
            <ModelRaportKoga />
            <ModelKalaRaport />
            <ModalQarzRastawxo />
            <ModelKalayNafroshraw />
            <ModelParayNagarawa />
            <ModelOfParadan />
            <ModelOfParayDarhenraw />
            <ModelOfMasrufat />
            <ModelKas />
            <QarziAstoModel />
            <ModelQarziWakil />
            <ModelParaGorenawa />
            <ModelKoga3arz />
            <TotalnOut />
            <ModelKashfKas />
            <ModelSummery />
          </Router>
        );
      }}
    </ProductConsumer>
  );
}

export default App;
