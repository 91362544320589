import React, { Component } from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import styled from "styled-components";
import { Link } from "react-router-dom";

class QarziAstoModel extends Component {
  render() {
    return (
      <ProductConsumer>
        {(value) => {
          const { QA_modalOpen, QA_CloseModal, KoyqarziAssto } = value;

          if (!QA_modalOpen) {
            return null;
          } else {
            return (
              <ModalContainer>
                <div className="container">
                  <div className="row">
                    <div
                      id="modal"
                      className="col-lg-8 col-sm-12 mx-auto col-md-10 col-lg-7 text-center text-capitalize p-5"
                    >
                      <div className="row">
                        <div className="col-md-12 mb2">
                          <h3>پیشاندانی قەرزی ئەستۆ</h3>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <Link to="/Product-Raport">
                            <button
                              className="btn btn-success"
                              onClick={() => QA_CloseModal()}
                            >
                              چوونەدەرەوە
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/qarziasto">
                            <button
                              className="btn btn-info"
                              onClick={(e) => {
                                KoyqarziAssto(e);
                              }}
                            >
                              هەموو قەرزەکان
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalContainer>
            );
          }
        }}
      </ProductConsumer>
    );
  }
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  #modal {
    background: #f3e29f;
  }
`;

export default QarziAstoModel;
