import React from "react";
import styled from "styled-components";
import { ProductConsumer } from "../Context/AddProWakel";
import paradan from "./../img/paradan.jpg";
import darhenan from "./../img/darhenan.png";
import setting from "./../img/setting.jpg";
import wakel from "./../img/wakel.png";
import mandub from "./../img/mandub.png";
import wasl from "./../img/wasl.png";
import Navbar from "../Navbar/Navbar";
import change from "./../img/change.png";
import buy from "./../img/images.png";
import { Link } from "react-router-dom";
const MasrufatList = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { Ms_getWakiltableData, Ap_getWakiltableData } = value;
          return (
            <>
              <Navbar />
              <div
                className="row "
                style={{ backgroundColor: "#2C3539" }}
              ></div>
              <div
                className="row mt2"
                style={{ backgroundColor: "#2C3539" }}
              ></div>
              <div
                className="row mt3"
                style={{ backgroundColor: "#2C3539" }}
              ></div>
              <div
                className="container mt4"
                style={{ backgroundColor: "#2C3539" }}
              >
                <ProductWraper
                  className="row"
                  style={{ backgroundColor: "#2C3539" }}
                >
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/Paradarhenan">
                          <img
                            src={darhenan}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2 "
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">پارە دەرهینان</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/input-masruf" onClick={Ms_getWakiltableData}>
                          <img
                            src={setting}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2 "
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">مەسروف</p>
                      </div>
                    </div>
                  </div>

                  {/* bas */}
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/Paradan">
                          <img
                            src={paradan}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2"
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">پارەدان</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/input-people" onClick={Ap_getWakiltableData}>
                          <img
                            src={wakel}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2 "
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">کەسەکان</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/parawargrtn">
                          <img
                            src={wasl}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2 "
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">
                          پارە گەڕاندنەوە
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* change $ to ID */}
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/change-money">
                          <img
                            src={change}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2 "
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">پارەگۆڕینەوە</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/Mandub">
                          <img
                            src={mandub}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2 "
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">کۆمپانیاکان</p>
                      </div>
                    </div>
                  </div>
                  {/* Kreni Mawadi Ma3mel */}
                  <div className="col-9 mx-auto col--6 col-lg-3 my-3 pointer">
                    <div className="card ">
                      <div className="img-container p-5">
                        <Link to="/Kren-Mawad">
                          <img
                            src={buy}
                            alt="product"
                            className="card-img-top "
                          />
                        </Link>
                      </div>
                      <div
                        className="card-footer d-flex justify-content-center  h2 "
                        style={{ color: "#2C3539" }}
                      >
                        <p className="align-self-center mb4 ">کڕین</p>
                      </div>
                    </div>
                  </div>
                </ProductWraper>
              </div>
              <div
                className="row mt4"
                style={{ backgroundColor: "#2C3539" }}
              ></div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

const ProductWraper = styled.div`
  .card {
    border-color: transparent;
    transition: all 1s linear;
  }

  .card-footer {
    background: transparent;
    border-top: transparent;
    transition: all 1s linear;
  }
  &:hover {
    .card {
      border: 0.04rem solid rgba(0, 0, 0, 0.2);
      box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
    }

    .card-footer {
      background: rgba();
    }
  }
  .img-container {
    position: relative;
    overflow: hidden;
  }
  .card-img-top {
    transition: all 1s linear;
  }
  .img-container:hover .card-img-top {
    transform: scale(1.2);
  }
  .card-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.2rem 0.4rem;
    background: var(--lightBlue);
    border: none;
    color: var(--mainWhite);
    font-size: 1.4rem;
    border-radius: 0.5rem 0 0 0;
    transform: translate(100%, 100%);
    transition: all 1s linear;
  }
  .img-container:hover .card-btn {
    transform: translate(0, 0);
  }
  .card-btn:hover {
    color: var(--mainBlue);
    cursor: pointer;
  }
`;

export default MasrufatList;
