import React, { Component } from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import styled from "styled-components";
import { Link } from "react-router-dom";

class ModelKas extends Component {
  render() {
    return (
      <ProductConsumer>
        {(value) => {
          const {
            RT_openmodel,
            RT_CloseModal,
            RT_serchwakil,
            RT_Paradanakan,
            AddProChange,
            RT_zhmaraybarez,
            RT_nawibrez,
            RT_from,
            RT_to,
            RT_setstInputEmpty,
          } = value;

          if (!RT_openmodel) {
            return null;
          } else {
            return (
              <ModalContainer>
                <div className="container">
                  <div className="row">
                    <div
                      id="modal"
                      className="col-lg-8 col-sm-12 mx-auto col-md-10 col-lg-6 text-center text-capitalize p-5"
                    >
                      <div className="row">
                        <div className="col-md-12 mb2">
                          <h3>ڕاپۆرتی کەسەکان</h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            onChange={AddProChange}
                            name="RT_nawibrez"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? RT_serchwakil(event)
                                : null
                            }
                            value={RT_nawibrez}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            onChange={AddProChange}
                            onClick={RT_setstInputEmpty}
                            name="RT_zhmaraybarez"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? RT_serchwakil(event)
                                : null
                            }
                            value={RT_zhmaraybarez === 0 ? "" : RT_zhmaraybarez}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-3">
                          <label className="text-danger  font-weight-bold">
                            کەس
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-danger  font-weight-bold">
                              هەتاوەکو
                            </label>
                            <br />
                            <input
                              type="date"
                              onChange={AddProChange}
                              name="RT_to"
                              value={RT_to}
                              className="selectpicker btn btn-dark"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-danger  font-weight-bold">
                              لە
                            </label>
                            <br />
                            <input
                              type="date"
                              onChange={AddProChange}
                              name="RT_from"
                              value={RT_from}
                              className="selectpicker btn btn-dark"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <Link to="/Product-Raport">
                            <button
                              className="btn btn-success"
                              onClick={() => RT_CloseModal()}
                            >
                              چوونەدەرەوە
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/raporty-kasakan">
                            <button
                              className="btn btn-info"
                              onClick={() =>
                                RT_Paradanakan(RT_from, RT_to, RT_zhmaraybarez)
                              }
                            >
                              هەموو جووڵەکان
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalContainer>
            );
          }
        }}
      </ProductConsumer>
    );
  }
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  #modal {
    background: #f3e29f;
  }
`;

export default ModelKas;
