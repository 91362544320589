import React from "react";
import { storeProducts } from "../Data";
import setToken from "./../util/setToken";
import instance from "../util/Instance";
import errorAduio from "./../img/error.mp4";
import warningAdiuo from "./../img/Bleep.mp3";
import notifyAudio from "./../img/notify.wav";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";

const ProductContext = React.createContext();

class AddProWakel extends React.Component {
  constructor() {
    super();
    let curr = new Date();
    curr.setDate(curr.getDate());
    let today = curr.toISOString().substr(0, 10);
    this.state = {
      // disabled navdolar
      disabled_navdolar: false,
      // end
      /// loading state
      loading: false,
      P_loading: false,
      ProductLoading: false,
      R_Nrx_J_T_check: true,
      G_loading: false,
      // print
      print: false,
      // end
      ///draw
      dinar: false,
      dolar: false,
      //////user
      isAuthUser: null,
      L_error: null,
      L_name: "",
      L_password: "",
      role: "",
      L_user: null,
      //////end
      products: [],
      koygshty: 0,
      data: [],
      productdata: [],
      rezha: 0,
      nrx: 0,
      darzan: 0,
      karton: 0,
      barwar: "",
      nawneshan: "",
      raseddolar: "",
      raseddenar: "",
      mob: "",
      naw: "",
      code: "",
      navdolar: 0,
      zhmara: "",
      zhmaraySht: "",
      barcode: "",
      naweSht: "",
      kren: 0,
      nrxeFroshtnDolar: 0,
      nrxeFroshtnDenar: 0,
      nrxeFroshtnDolar3: 0,
      nrxeFroshtnDolar2: 0,
      nrxeDarzan: 0,
      zhmarayKarton: 0,
      qazanj: 0,
      prodactdata: "",
      search: null,
      edit: null,
      ok: null,
      error: null,
      Add_rasidkarton: 0,
      wazn_karton: 0,
      del_dis: null,
      open: null,
      page: 0,
      QR_name: "",
      /// Rent Context && States
      R_dataRent: [],
      R_productId: "",
      R_header: null,
      R_dataTable: [],
      R_zh_wasl: 0,
      R_zh_barez: 0,
      R_nawe_barez: "",
      R_mob: 0,
      R_zhmara: "",
      R_naweSht: "",
      R_karton: 0,
      R_darzan: 0,
      R_nrx: 0,
      R_rezha: 0,
      R_koygshty$: 0,
      R_koygshtyID: 0,
      R_total: 0,
      R_totalID: 0,
      R_dashkan: 0,
      R_dashkanID: 0,
      R_mawa$: 0,
      R_paraDan$: "",
      R_paraDanID: "",
      R_error: null,
      R_frosth: null,
      R_joripara: null,
      R_wargr: "",
      R_search: null,
      R_search_wasl: null,
      R_search_wakil: null,
      R_nrx_dolar: 0,
      R_nrx_dinarr: 0,
      R_nrx_dolar2: 0,
      R_nrx_dolar3: 0,
      R_save: null,
      R_rasidKarton: 0,
      R_qazanj: 0,
      R_kren: 0,
      R_nrxkaton: 0,
      R_rasidWakildolar: 0,
      R_rasidWakildinar: 0,
      R_rasidWakildolarNew: 0,
      R_rasidWakildinarNew: 0,
      R_kartonakan: 0,
      R_kreysayara: 0,
      R_kreykrekar: 0,
      R_nwe: null,
      R_isChecked: false,
      R_WakilQard_isChecked: false,
      R_wasl: null,
      R_pass: null,
      R_ko: 0,
      R_koywaznkarton: 0,
      R_zh_wasl_katy: 0,
      R_up_wasl: null,
      R_paraywasl: 0,
      R_NO_page: 0,
      R_del_dis: null,
      R_open: null,
      R_up_froshtn: null,
      R_date: "",
      R_togler: false,
      R_page: 0,
      R_qazanjywasl: 0,
      R_waznkarton: 0,
      R_table: false,
      R_joridolar: null,
      R_kateQarz: "",
      R_cursor: 0,
      R_note: "",
      // end statets
      // wakel States
      W_dataWakel: [],
      W_nawneshan: "",
      W_raseddenar: 0,
      W_raseddolar: 0,
      W_mob_Wakel: 0,
      W_naw: "",
      W_code: 0,
      W_error: null,
      W_show: false,
      W_wakelakan: [],
      // end states wakel

      // Take Money states
      P_zhmara: 0,
      P_barwar: "",
      P_zh_barez: 0,
      P_nawe_barez: "",
      P_qarzeKonDolar: 0,
      P_qarzeKonID: 0,
      P_brePara: 0,
      P_dashkan: 0,
      P_paraPashDashkan: 0,
      P_error: null,
      P_note: "",
      P_isChecked: false,
      P_joripara: "دۆلار",
      P_wargr: null,
      P_save: null,
      P_search_wasl: null,
      P_up_wasl: null,
      P_paraywasl: 0,
      P_del_dis: null,
      P_open: null,
      P_wasl: null,
      P_page: 0,
      P_togler: false,
      P_suggestion: [],

      // end of Take Money states

      // Add User States
      A_addUserData: [],
      A_bakarhenar: "",
      A_zh_mobile: 0,
      A_role: "",
      A_password: "",
      A_newPassword: "",
      A_error: null,
      // Enf Of  Add User States

      // Update admin password States
      U_currentpasword: "",
      U_newPassword: "",
      U_newPassword2: "",
      U_error: null,
      U_save: false,
      U_show: false,
      // Enf Of  Update admin password  States

      // states of garawanwa bo koga
      G_togler: false,
      G_productId: "",
      G_karton: 0,
      G_darzan: 0,
      G_nrx: 0,
      G_naweSht: "",
      G_rezha: 0,
      G_zhmara: "",
      G_mob: 0,
      G_nawe_barez: "",
      G_zh_barez: 0,
      G_zh_wasl: 0,
      G_zh_wasl_katy: 0,
      G_wasl: null,
      G_search_wakil: null,
      G_search: null,
      G_del_dis: null,
      G_joripara: "دۆلار",
      G_wargr: null,
      G_nrx_dolar: 0,
      G_nrx_dolar2: 0,
      G_nrx_dolar3: 0,
      G_joridolar: null,
      G_nrx_dinarr: 0,
      G_rasidKarton: 0,
      G_qazanj: 0,
      G_kren: 0,
      G_nrxkaton: 0,
      G_dataTable: [],
      G_totalID: 0,
      G_total: 0,
      G_kartonakan: 0,
      G_search_wasl: null,
      G_waznkarton: 0,
      G_WakilQard_isChecked: null,
      G_save: null,
      G_open: null,
      G_paraDan$: "",
      G_paraDanID: "",
      G_dashkanID: 0,
      G_dashkan: 0,
      G_kreysayara: 0,
      G_kreykrekar: 0,
      G_paraywasl: 0,
      G_page: 0,
      G_qazanjywasl: 0,
      G_date: "",
      G_koywaznkarton: 0,
      G_pass: null,
      G_table: false,
      G_rasidWakildolar: 0,
      G_rasidWakildinar: 0,
      G_rasidWakildolarNew: 0,
      G_rasidWakildinarNew: 0,
      //end of states of garawanwa bo koga

      // states of raporty qazanj
      Q_modalOpen: false,
      Q_to: today,
      Q_from: today,
      Q_data: [],
      Q_koyQazanj: 0,
      Q_qazanjysafi: 0,
      Q_dashkandolar: 0,
      Q_dashkandinar: 0,
      Q_ganawa: 0,
      Q_kala: false,
      Q_wakel: false,
      Q_wasl: false,
      Q_rastawxo: false,
      //end of states of raporty qazanj

      //state of Raporty Rozhana
      d_from: today,
      d_to: today,
      d_zhmara: 0,
      d_barwar: "",
      d_jula: "",
      d_koyPsula: 0,
      d_jorydraw: "",
      d_lexoshbun: 0,
      d_paraydrwa: 0,
      d_mawa: 0,
      d_naweWakel: "",
      d_zhmaraWakel: 0,
      d_dataRozhana: [],
      d_paraynadrawID: 0,
      d_paraynadraw$: 0,
      d_wargrtnawayQarzID: 0,
      d_wargrtnawayQarz$: 0,
      d_paraywargerawID: 0,
      d_paraywargeraw$: 0,
      d_froshrawID: 0,
      d_froahraw$: 0,
      d_rahelID: 0,
      d_rahel$: 0,
      d_yassinID: 0,
      d_yassin$: 0,
      d_modalOpen: false,
      d_incart: false,
      d_hamw: false,
      d_froshtn: false,
      d_parawargrtn: false,
      d_garanawa: false,
      // end of state of Raporty Rozhana

      // states of Raporty Wakel
      RW_dataWakelRaport: [],
      RW_modalOpen: false,
      RW_zh_barez: 0,
      RW_nawibrez: "",
      RW_mob: "",
      RW_from: today,
      RW_to: today,
      RW_zhmara: 0,
      RW_barwar: "",
      RW_jula: "",
      RW_koyPsula: 0,
      RW_jorepara: "",
      RW_lexoshbun: 0,
      RW_paraydraw: 0,
      RW_mawa: 0,
      RW_rasedyKotaye$: 0,
      RW_rasedyKotayeID: 0,
      RW_koyfroshtndolar: 0,
      RW_koyfroshtndianr: 0,
      RW_hamw: false,
      RW_froshtn: false,
      RW_parawargrtn: false,
      RW_garanawa: false,
      //end of  states of Raporty Wakel

      // states of qarze wakel
      QW_dataWakelQarz: [],
      QW_zhmara: "",
      Qw_naw: "",
      Qw_barwar: "",
      QW_rasiddinar: 0,
      QW_rasiddolar: 0,
      QW_modalOpen: false,
      checkedValues: [],
      //end of states of qarze wakel

      // states of Jarde Maxzan
      JM_dataRaportyKoga: [],
      JM_allproducts: [],
      JM_naw: "",
      JM_zhmara: 0,
      JM_barwar: today,
      JM_footer_rasedyhamukartonakan: 0,
      JM_modalOpen: false,
      JM_code: "",
      JM_name: "",
      JM_koyrasidkarton: 0,
      JM_koyrasidparakan: 0,
      JM_yaksanbasfr: false,
      JM_zyattrlasfr: false,
      //end of states of Jarde Maxzan

      // states of raporty kala
      K_datatable: [],
      K_modalOpen: false,
      K_barwar: "",
      K_zhamara: "",
      K_naweKala: "",
      K_kallaDetail: null,
      k_navbar_tomarkrdn: false,
      k_navbar_froshtn: false,
      k_navbar_garanawa: false,
      K_from: today,
      K_to: today,
      K_rasedeKarton: 0,
      K_rasedeKartonBroken: 0,
      K_rasedekartonfroshtn: 0,
      K_rasedekartongarnawa: 0,
      K_rasedekartonKren: 0,
      K_numberOfDarzan: 0,
      K_datafilter: [],
      K_yaksanbasfr: false,
      K_zyattrlasfr: false,
      K_kamtrlasfr: false,
      // end of states of raporty kala

      /////states  qarzy rastawxo
      QR_data: [],
      QR_zhmara: "",
      QR_naw: "",
      QR_barwar: "",
      QR_rasiddinar: 0,
      QR_rasiddolar: 0,
      QR_openmodel: false,
      QR_from: today,
      QR_checkedValues: [],
      QR_to: today,
      /////end qarzy rastawxo

      // states of Raporty Chin
      RC_data: [],
      RC_kalladata: [],
      RC_zhmarayKala: 0,
      RC_zhmaaryDukan: 0,
      RC_zhmaaryPswla: 0,
      RC_nrxeNavR: 0,
      RC_nrxNavM: 0,
      RC_dabash: 0,
      RC_rezhaynawKarton: 0,
      RC_nrxRRR: 0,
      RC_koygshtyR: 0,
      RC_koygshty$: 0,
      RC_drezhe: 0,
      RC_pane: 0,
      RC_barze: 0,
      RC_koymatr$: 0,
      RC_nrxekotayeKala$: 0,
      RC_kren: 0,
      RC_froshtn: 0,
      RC_qazanj: 0,
      RC_R_disaebl: true,
      RC_M_desaebl: true,
      RC_search_wasl: null,
      RC_wasl: null,
      RC_open: false,
      RC_save: false,
      RC_dis: false,
      RC_updatekala: false,
      RC_kreneakcartoon: 0,
      RC_froshtneakcartoon: 0,
      RC_froshtnID: 0,
      RC_kamtrlaDadarzan: 0,
      RC_kamtrlaNewkarton: 0,
      RC_nawikalla: "",
      RC_suggestions: [],
      // end of states of Raporty Chin

      // states of Raporty Kalay Nafroshraw
      KN_openmodel: false,
      KN_data: [],
      KN_from: today,
      KN_to: today,

      // states of Raporty Paray Nagaranawa
      RP_dataPara: [],
      RP_zhmara: "",
      RP_naw: "",
      RP_from: today,
      RP_to: today,
      RP_openmodel: false,

      //end of states of Raporty Paray Nagaranawa

      // states of add prople
      Ap_data: [],
      Ap_kasakan: [],
      Ap_code: 0,
      Ap_naw: "",
      Ap_mob: 0,
      Ap_jor: null,
      Ap_show: false,
      Ap_paradolar: 0,
      Ap_paradinar: 0,
      // end of states of add prople

      // states of masrufat
      Ms_data: [],
      Ms_naw: "",
      Ms_koypara: 0,
      Ms_draw: null,
      Ms_koymasruf$: 0,
      Ms_koymasrufID: 0,
      Ms_show: false,

      // states of paradan
      PD_zhamara: 0,
      Pd_barwar: "",
      PD_parayesta$: 0,
      PD_parayestaID: 0,
      PD_nawebarez: "",
      PD_zhmaraybarez: 0,
      PD_brepara: 0,
      PD_joredraw: "دۆلار",
      PD_nawewargr: "",
      PD_page: 0,
      PD_del_dis: null,
      PD_open: null,
      PD_toggler: false,
      PD_note: "",
      PD_search_wasl: null,
      PD_save: null,
      PD_wasl: null,
      PD_qardekon$: 0,
      PD_qardekonID: 0,
      PD_isChecked: false,
      PD_wasleShareka: 0,
      // end of states of paradan
      //Total IN And Out
      IO_openmodel: false,
      IO_from: today,
      IO_to: today,
      IO_totaldolar: 0,
      IO_totaldinar: 0,
      IO_dashakandolar: 0,
      IO_dashakandinar: 0,
      IO_paraywargirawdolar_p: 0,
      IO_paraywargirawdinar_p: 0,
      IO_masrwf_D: 0,
      IO_masrwf_IQD: 0,
      IO_rejaDolar: 0,
      IO_rejaDinar: 0,
      IO_qarziAsto_D: 0,
      IO_qarziAsto_IQ: 0,
      IO_totalgaranawadolar: 0,
      IO_totalgaranwadinar: 0,
      IO_daxiladinar: 0,
      IO_daxiladolar: 0,
      IO_paradandolar: 0,
      IO_paradandinar: 0,
      // states of para darhenan
      PM_zhamara: 0,
      PM_barwar: "",
      PM_parayesta$: 0,
      PM_parayestaID: 0,
      PM_brepara: 0,
      PM_joredraw: "دۆلار",
      PM_nawewargr: "",
      PM_page: 0,
      PM_del_dis: null,
      PM_open: null,
      PM_toggler: false,
      PM_note: "",
      PM_search_wasl: null,
      PM_save: null,
      PM_wasl: null,
      PM_nawikas: "",

      // end of states of para darhenan

      // states of paray mandubakan
      MM_zhamara: 0,
      MM_barwar: "",
      MM_nawebarez: "",
      MM_zhmaraybarez: 0,
      MM_qarzeKonDolar: 0,
      MM_qarzeKonID: 0,
      MM_brepara: 0,
      MM_joredraw: "دۆلار",
      MM_nawewargr: "",
      MM_page: 0,
      MM_del_dis: null,
      MM_open: null,
      MM_toggler: false,
      MM_note: "",
      MM_search_wasl: null,
      MM_save: null,
      MM_wasl: null,
      MM_wasleShareka: 0,
      MM_isChecked: false,

      // end of states of paray mandubakan

      /// states of raporty Paradan
      RPD_data: [],
      RPD_openmodel: false,
      RPD_from: today,
      RPD_to: today,
      RPD_para$: 0,
      RPD_paraID: 0,
      /// end of  states of raporty Paradan

      // states of raporty paradarhenraw
      RPM_data: [],
      RPM_openmodel: false,
      RPM_from: today,
      RPM_to: today,
      RPM_para$: 0,
      RPM_paraID: 0,
      // end of states of raporty paradarhenraw

      // states of raporty masrufat
      RMS_data: [],
      RMS_openmodel: false,
      RMS_from: today,
      RMS_to: today,
      RMS_para$: 0,
      RMS_paraID: 0,
      // end of states of raporty masrufat

      // states of raporty tak kasakan
      RT_data: [],
      RT_openmodel: false,
      RT_from: today,
      RT_to: today,
      RT_para$: 0,
      RT_paraID: 0,
      RT_nawibrez: "",
      RT_zhmaraybarez: "",
      RT_mob: 0,
      RT_mandub: false,
      RT_paradan: false,
      RT_koyparadan$: 0,
      RT_koyparadanID: 0,

      /// states of raporty mandub
      RMM_data: [],
      RMM_openmodel: false,
      RMM_from: today,
      RMM_to: today,
      /// end of  states of raporty mandub

      // states of para wargrtn
      PW_zhamara: 0,
      PW_barwar: "",
      PW_parayesta$: 0,
      PW_parayestaID: 0,
      PW_brepara: 0,
      PW_joredraw: "دۆلار",
      PW_nawewargr: "",
      PW_page: 0,
      PW_del_dis: null,
      PW_open: null,
      PW_toggler: false,
      PW_note: "",
      PW_search_wasl: null,
      PW_save: null,
      PW_wasl: null,
      PW_nawikas: "",
      // end of states of para wargrtn

      // states of raporty parawargrtn
      RPW_data: [],
      RPW_openmodel: false,
      RPW_from: today,
      RPW_to: today,
      RPW_para$: 0,
      RPW_paraID: 0,
      // end of states of raporty parawargrtn

      // states of Search By name
      R_suggestions: [],
      suggestions: [],
      G_suggestions: [],
      L_Search_lq: "",
      G_search_lq: "",
      K_suggestions: [],

      //// states of Old Rasid
      OR_zhmarayBarez: "",
      OR_nawiBarez: "",
      OR_briQarz: 0,
      OR_joriDraw: "دۆلار",
      OR_open: null,
      OR_save: null,
      OR_del_dis: null,

      /// QarziAsto State
      QA_modalOpen: false,
      QA_qarzdinar: 0,
      QA_qarzdolar: 0,

      //

      // states of ParaGorenawa
      PG_zhmara: 0,
      PG_search_wasl: null,
      PG_barwar: "",
      PG_del_dis: null,
      PG_parayesta$: 0,
      PG_parayestaID: 0,
      PG_toggler: false,
      PG_breParabaID: 0,
      PG_nrxeKrine$: 0,
      PG_breKrini$: 0,
      PG_save: null,
      PG_nawewargr: "",
      PG_open: null,
      PG_wasl: null,
      PG_show: false,
      PG_note: "",

      // states of Raporty Para Gorenawa
      RPG_openmodel: false,
      RPG_data: [],
      RPG_from: today,
      RPG_to: today,

      ////raporty kashf 7isab
      KF_datatable: [],
      KF_from: today,
      KF_to: today,
      KF_openmodel: "",
      KF_zhmaraybarez: "",
      KF_nawe_barez: "",
      KF_mob: "",
      KF_Koy_Dinar: 0,
      KF_Koy_Dolar: 0,

      // states of 3arze kala
      HK_all3arzKala: [],
      HK_all3arzKalaPrint: [],
      HK_naw: "",
      HK_zhmara: 0,
      HK_modalOpen: false,
      HK_code: "",
      HK_name: "",
      checkedValues: [],
      //end of states of 3arze kala
      PS_all3arzKala: [],
      PS_all3arzKalaPrint: [],
      PS_naw: "",
      PS_zhmara: 0,
      PS_modalOpen: false,
      PS_code: "",
      PS_name: "",
      checkedValues: [],
      PS_to: today,
      PS_from: today,
      // states of Kreni mawad
      KM_togler: false,
      KM_productId: "",
      KM_karton: 0,
      KM_darzan: 0,
      KM_nrx: 0,
      KM_naweSht: "",
      KM_rezha: 0,
      KM_zhmara: "",
      KM_mob: 0,
      KM_nawe_barez: "",
      KM_zh_barez: 0,
      KM_zh_wasl: 0,
      KM_zh_wasl_katy: 0,
      KM_wasl: null,
      KM_search_wakil: null,
      KM_search: null,
      KM_del_dis: null,
      KM_joripara: "دۆلار",
      KM_wargr: null,
      KM_nrx_dolar: 0,
      KM_nrx_dolar2: 0,
      KM_nrx_dolar3: 0,
      KM_nrx_dinarr: 0,
      KM_rasidKarton: 0,
      KM_qazanj: 0,
      KM_kren: 0,
      KM_nrxkaton: 0,
      KM_dataTable: [],
      KM_totalID: 0,
      KM_total: 0,
      KM_kartonakan: 0,
      KM_search_wasl: null,
      KM_waznkarton: 0,
      KM_WakilQard_isChecked: null,
      KM_save: null,
      KM_open: null,
      KM_paraDan$: "",
      KM_paraDanID: "",
      KM_dashkanID: 0,
      KM_dashkan: 0,
      KM_kreysayara: 0,
      KM_kreykrekar: 0,
      KM_paraywasl: 0,
      KM_page: 0,
      KM_qazanjywasl: 0,
      KM_date: "",
      KM_koywaznkarton: 0,
      KM_pass: null,
      KM_table: false,
      KM_suggestions: [],
      //end of states of Kreni mawad
      // states of Kreni mawad
      BN_togler: false,
      BN_productId: "",
      BN_karton: 0,
      BN_darzan: 0,
      BN_nrx: 0,
      BN_naweSht: "",
      BN_rezha: 0,
      BN_zhmara: "",
      BN_mob: 0,
      BN_nawe_barez: "",
      BN_zh_barez: 0,
      BN_zh_wasl: 0,
      BN_zh_wasl_katy: 0,
      BN_wasl: null,
      BN_search_wakil: null,
      BN_search: null,
      BN_del_dis: null,
      BN_joripara: "دۆلار",
      BN_wargr: null,
      BN_nrx_dolar: 0,
      BN_nrx_dolar2: 0,
      BN_nrx_dolar3: 0,
      BN_nrx_dinarr: 0,
      BN_rasidKarton: 0,
      BN_qazanj: 0,
      BN_kren: 0,
      BN_nrxkaton: 0,
      BN_dataTable: [],
      BN_totalID: 0,
      BN_total: 0,
      BN_kartonakan: 0,
      BN_search_wasl: null,
      BN_waznkarton: 0,
      BN_WakilQard_isChecked: null,
      BN_save: null,
      BN_open: null,
      BN_paraDan$: "",
      BN_paraDanID: "",
      BN_dashkanID: 0,
      BN_dashkan: 0,
      BN_kreysayara: 0,
      BN_kreykrekar: 0,
      BN_paraywasl: 0,
      BN_page: 0,
      BN_qazanjywasl: 0,
      BN_date: "",
      BN_koywaznkarton: 0,
      BN_pass: null,
      BN_table: false,
      BN_suggestions: [],
      //end of states of Kreni mawad

      ////raporty kashf 7isab e Kasakan
      KT_datatable: [],
      KT_from: today,
      KT_to: today,
      KT_openmodel: "",
      KT_zhmaraybarez: "",
      KT_nawe_barez: "",
      KT_mob: "",
      KT_Koy_Dinar: 0,
      KT_Koy_Dolar: 0,
    };
  }
  OnChangeSwitch = (checked) => {
    this.setState({
      R_Nrx_J_T_check: checked,
    });
  };
  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter(
          (item, j) => i._id !== item
        ),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };
  HK_addtoPrint = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        HK_all3arzKalaPrint: this.state.HK_all3arzKalaPrint.filter(
          (item, j) => i !== item
        ),
      });
    } else {
      this.state.HK_all3arzKalaPrint.push(i);
      this.setState({
        HK_all3arzKalaPrint: this.state.HK_all3arzKalaPrint,
      });
    }
  };
  // toogle navdolar
  toggleNavdolar = () => {
    localStorage.setItem("nav", this.state.navdolar);
    this.setState({
      disabled_navdolar: !this.state.disabled_navdolar,
    });
  };
  //end

  ///7sabaty 3am
  IO_OpenModal = async (e, userid) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            IO_openmodel: !this.state.IO_openmodel,
            IO_totaldolar: 0,
            IO_totaldinar: 0,
            IO_dashakandolar: 0,
            IO_dashakandinar: 0,
            IO_paraywargirawdolar_p: 0,
            IO_paraywargirawdinar_p: 0,
            IO_masrwf_D: 0,
            IO_masrwf_IQD: 0,
            IO_rejaDolar: 0,
            IO_rejaDinar: 0,
            IO_qarziAsto_D: 0,
            IO_qarziAsto_IQ: 0,
            IO_totalgaranawadolar: 0,
            IO_totalgaranwadinar: 0,
            IO_daxiladinar: 0,
            IO_daxiladolar: 0,
            IO_paradandolar: 0,
            IO_paradandinar: 0,
          };
        });
      }
    } catch {}
  };

  IO_CloseModal = () => {
    this.setState(() => {
      return { IO_openmodel: false };
    });
  };

  IO_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/report?from=${this.state.IO_from}&to=${this.state.IO_to}`,
        config
      );
      let data = res.data.data;

      this.setState({
        IO_totaldolar: data.froshtnTotalDolar,
        IO_totaldinar: data.froshtnTotalDinar,
        IO_dashakandolar: data.froshtndashkanDolar,
        IO_dashakandinar: data.froshtndashkaninar,
        IO_paraywargirawdolar_p: data.parawargrtnDolar,
        IO_paraywargirawdinar_p: data.parawargrtnDinar,
        IO_masrwf_D: data.masrwfdolar,
        IO_masrwf_IQD: data.masrwfdinar,
        IO_rejaDolar: data.qarzDolar,
        IO_rejaDinar: data.qarzDinar,
        IO_qarziAsto_D: data.qarziastoDolar,
        IO_qarziAsto_IQ: data.qarziastoDinar,
        IO_totalgaranawadolar: data.garanawaTotalDolar,
        IO_totalgaranwadinar: data.garanawaTotalDinar,
        IO_daxiladinar: data.daxiladinar,
        IO_daxiladolar: data.daxiladolar,
        IO_paradandolar: data.paradandolar,
        IO_paradandinar: data.paradandinar,
      });
      this.JM_jardyrasidparakan();
      this.IO_CloseModal();
    } catch (error) {
      swal({
        title: "ببورە",
        text: "ببوورە هەڵەیەک ڕویدا!؟",
        icon: "error",
      });
    }
  };
  ///////input pass
  toanotherInput = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index - 1].focus();
  };
  tofirstInput = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 11].focus();
  };

  tofirstInpuMatr = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 10].focus();
  };

  tofirstInpuKalla = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 9].focus();
    this.RC_saveToTable(e);
  };

  Add_toanotherInput = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 1].focus();
  };
  tofirstInputINRent = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 5].focus();
    this.saveDetail(e, this.state.R_wasl);
  };

  //////  user
  loginuser = async (e) => {
    e.preventDefault();
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const data = {
        name: this.state.L_name,
        password: this.state.L_password,
      };
      const res = await instance.post("/api/v1/login", data, config);
      localStorage.setItem("token", res.data.token);
      window.location.reload(true);
      this.setState({
        isAuthUser: true,
        L_error: null,
        L_name: "",
        L_password: "",
      });
      this.L_ClearError();
    } catch (error) {
      this.setState({
        L_error: error.response.data,
      });
    }
  };
  L_setError = (err) => {
    this.setState({ L_error: err });
  };

  L_ClearError = () => {
    this.setState({ L_error: null });
  };

  getUserIlgoin = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(`/api/v1/user`, config);
      this.setState(
        {
          isAuthUser: true,
          role: res.data.user.role,
          L_user: res.data.user,
        },
        () => {
          if (this.state.isAuthUser === true) {
            // this.W_getwakelakan();
            this.getParakan();
            this.getWaslakan();
            this.getNameOfData();
            this.getWakiltableData();
            // this.getUsertableData();
            this.G_getWaslakan();
            this.Ap_getWakiltableData();
            this.Ap_getkasakan();
            // this.Ms_getWakiltableData();
            this.PD_getParakan();
            this.PM_getParakan();
            this.MM_getParakan();
            this.PB_gegtparaybardast();
            this.PW_getParakan();
            this.getNotfication();
            this.KM_getWaslakan();
            this.BN_getWaslakan();
          }
        }
      );
    } catch (error) {
      this.setState({ User_error: { msg: "User IsNotAdmin" } });
    }
  };
  logoutUser = () => {
    localStorage.removeItem("token");
    this.setState({ isAuthUser: null });
  };
  //// end of user

  setStateEmpty = () => {
    this.setState({
      rezha: 0,
      nrx: 0,
      darzan: 0,
      karton: 0,
      barwar: "",
      zhmara: "",
      zhmaraySht: "",
      barcode: "",
      naweSht: "",
      kren: 0,
      nrxeFroshtnDolar: 0,
      nrxeFroshtnDenar: 0,
      nrxeFroshtnDolar3: 0,
      nrxeFroshtnDolar2: 0,
      zhmarayKarton: 0,
      nrxeDarzan: 0,
      qazanj: 0,
      search: null,
      edit: null,
      koygshty: 0,
      prodactdata: null,
      ok: null,
      data: [],
      Add_rasidkarton: 0,
      wazn_karton: 0,
      del_dis: null,
      open: null,
      suggestions: [],
    });
  };
  newPage = (e) => {
    e.preventDefault();
    this.setStateEmpty();
    this.ClearError();
  };

  ////notfication function
  getNotfication = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(`/api/v1/getNotfication`, config);
      let data = res.data.qard;
      if (data.length !== 0) {
        let sawt = new Audio(notifyAudio);
        sawt.play();
        data.map((el) => {
          return toast.warn(
            `ئەمڕۆ کاتی هێنانەوەی قەرزی بەڕیز
          ${el.wakil}ە`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        });
      } else {
        return;
      }
    } catch (error) {
      this.setState({ User_error: { msg: "User IsNotAdmin" } });
    }
  };

  componentDidMount() {
    if (localStorage.getItem("nav")) {
      this.setState({
        navdolar: localStorage.getItem("nav"),
      });
    }
    if (localStorage.getItem("rm")) {
      this.setState({
        RC_nrxeNavR: localStorage.getItem("rm"),
      });
    }
    if (localStorage.getItem("mm")) {
      this.setState({
        RC_nrxNavM: localStorage.getItem("mm"),
      });
    }
    if (localStorage.getItem("item")) {
      this.setState(
        {
          R_dataTable: JSON.parse(localStorage.getItem("item")),
        },
        () => {
          if (this.state.R_dataTable.length !== 0) {
            this.setState({
              R_table: true,
            });
          }
        }
      );
    }

    if (localStorage.getItem("gitem")) {
      this.setState(
        {
          G_dataTable: JSON.parse(localStorage.getItem("gitem")),
        },
        () => {
          if (this.state.G_dataTable.length !== 0) {
            this.setState({
              G_table: true,
            });
          }
        }
      );
    }
    if (localStorage.getItem("kitem")) {
      this.setState(
        {
          KM_dataTable: JSON.parse(localStorage.getItem("kitem")),
        },
        () => {
          if (this.state.KM_dataTable.length !== 0) {
            this.setState({
              KM_table: true,
            });
          }
        }
      );
    }

    if (localStorage.getItem("saledata")) {
      this.R_onopenandrefresh(JSON.parse(localStorage.getItem("saledata")));
    }
    if (localStorage.getItem("garnawa")) {
      this.G_onopenandrefresh(JSON.parse(localStorage.getItem("garnawa")));
    }
    if (localStorage.getItem("kren")) {
      this.KM_onopenandrefresh(JSON.parse(localStorage.getItem("kren")));
    }
    if (localStorage.getItem("paradata")) {
      this.P_onopenUpdateAndRefresh(
        JSON.parse(localStorage.getItem("paradata"))
      );
    }

    this.getUserIlgoin();
    this.setProduct();
  }
  ////qarzi kon w nwe la print
  getNewRaidForwakil = async (NOW, e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (this.state.R_frosth === "فرۆشتنی معمیل") {
        const res = await instance.get(
          `/api/v1/searchwakil?code=${NOW}&&Name=&&phone=`,
          config
        );
        this.setState({
          R_rasidWakildolarNew: res.data.wakils.rejaDolar.toFixed(2),
          R_rasidWakildinarNew: res.data.wakils.rejaDinar.toFixed(2),
        });
      }
    } catch (error) {
      alert("okey");
    }
  };
  getPrint = (NOW) => {
    window.setTimeout("window.print()", 2000);

    this.getNewRaidForwakil(NOW);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.edit !== this.state.edit) {
      if (this.state.edit !== null) {
        this.setState({
          ...this.state,
          karton: this.state.edit.numberOfCarton,
          darzan: this.state.edit.numberOfAllDarzans,
          koygshty: this.state.edit.total,
          W_mob_Wakel: this.state.edit.phone,
          W_naw: this.state.edit.Name,
          W_nawneshan: this.state.edit.adress,
          W_raseddolar: this.state.edit.rejaDolar,
          W_raseddenar: this.state.edit.rejaDinar,
          W_code: this.state.edit.code,
          A_zh_mobile: this.state.edit.phone,
          A_bakarhenar: this.state.edit.name,
          A_role: this.state.edit.role,
          Ap_code: this.state.edit.code,
          Ap_naw: this.state.edit.Name,
          Ap_mob: this.state.edit.phone,
          Ap_jor: this.state.edit.jor,
          Ms_draw: this.state.edit.joripara,
          Ms_koypara: this.state.edit.koypara,
          Ms_naw: this.state.edit.shwen,
        });
      } else {
        this.setState({
          ...this.state,
          karton: 0,
          darzan: 0,
          koygshty: 0,
          W_mob_Wakel: 0,
          W_naw: "",
          W_nawneshan: "",
          W_raseddolar: 0,
          W_raseddenar: 0,
          W_code: "",
          A_zh_mobile: 0,
          A_bakarhenar: "",
          A_role: "",
          Ap_code: 0,
          Ap_naw: "",
          Ap_mob: 0,
          Ap_jor: null,
          Ms_draw: null,
          Ms_koypara: 0,
          Ms_naw: "",
        });
      }
    }
  }

  setProduct = () => {
    let tempProduct = [];
    storeProducts.forEach((item) => {
      const singleItem = { ...item };
      tempProduct = [...tempProduct, singleItem];
    });

    this.setState(() => {
      return {
        products: tempProduct,
      };
    });
  };

  /////// delete table product
  clickOnDelete = async (user, id) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    this.setState(
      {
        data: this.state.data.filter((r) => r._id !== id),
      },
      () => {
        this.setState({
          Add_rasidkarton: Number(
            this.state.Add_rasidkarton - user.numberOfDarzan
          ).toFixed(2),
        });
      }
    );
    await instance.delete(`/api/v1/deleteProductTable/${id}`, config);
  };

  DolarChange = (e) => {
    this.setState({
      navdolar: e.target.value,
    });
  };
  ////// qzanj
  getQazanj = () => {
    const nrxeFroshtnDolar = this.state.nrxeFroshtnDolar;
    const kren = this.state.kren;
    const zhmarayKarton = this.state.zhmarayKarton;
    const qazanj = (nrxeFroshtnDolar - kren) * zhmarayKarton;
    return qazanj.toFixed(2);
  };

  //////carton darzan change
  DarzanChange = (e) => {
    this.setState(
      {
        darzan: e.target.value,
        R_darzan: e.target.value,
      },
      () => {
        this.setState({
          karton: this.calculateDarzan(),
          R_karton: this.R_calculateDarzan(),
          koygshty: this.getkoygahty(
            Number(this.state.darzan),
            Number(this.state.karton)
          ),
          R_nrx: this.nrxRent(
            Number(this.state.R_darzan),
            Number(this.state.R_karton)
          ),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        [e.target.name]: "0.",
      });
    }
    this.ClearError();
  };
  CartonChange = (e) => {
    this.setState(
      {
        karton: e.target.value,
      },
      () => {
        this.setState({
          darzan: this.calculateCarton(),
          koygshty: this.getkoygahty(
            Number(this.state.darzan),
            Number(this.state.karton)
          ),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        karton: "0.",
      });
    }
    this.ClearError();
  };
  /////////////////////////////
  ///////handle chnage  product
  AddProChange = (e) => {
    this.setState(
      {
        ...this.state,
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.zhmarayKarton > 0) {
          this.setState({
            qazanj: this.getQazanj(),
          });
        }
        this.R_getALLkoygshtyID();
        this.R_getALLkoygshty();
      }
    );

    if (e.target.value === ".") {
      this.setState({
        [e.target.name]: "0.",
      });
    }

    this.ClearError();
    this.W_ClearError();
    this.R_ClearError();
    this.U_ClearError();
    this.P_ClearError();
  };

  dolartodinar = (nrx) => {
    const dolar = this.state.navdolar;
    const dinar = (nrx / 100) * dolar;

    return Number(dinar).toFixed(2);
  };
  dinartodolar = (nrx) => {
    const dolar = this.state.navdolar / 100;
    const dinar = nrx / dolar;
    return Number(dinar).toFixed(2);
  };
  ///// calculate
  calculateCarton = () => {
    const zhmarayKarton = this.state.zhmarayKarton;
    const carton = Number(this.state.karton);
    const darzan = carton * zhmarayKarton;

    return Number(darzan).toFixed(2);
  };
  calculateDarzan = () => {
    const zhmarayKarton = this.state.zhmarayKarton;
    const darzan = Number(this.state.darzan);
    const karton = darzan / zhmarayKarton;
    return Number(karton).toFixed(2);
  };

  getkoygahty = (darzan, karton) => {
    if (Number(this.state.karton) > 0) {
      const kren = this.state.kren;
      let koygshty = darzan * kren;

      return koygshty.toFixed(2);
    } else if (darzan > 0) {
      const kren = this.state.kren;
      let koygshty = darzan * kren;

      return Number(koygshty).toFixed(2);
    }
  };

  ////product data
  getNameOfData = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get("/api/v1/getProducts", config);

    this.setState({
      productdata: res.data.products,
      page: res.data.products.length + 1,
    });
  };
  ////// setError
  setError = (err) => {
    this.setState({
      error: err,
    });
  };
  ClearError = () => {
    this.setState({
      error: null,
    });
  };
  ////// search product
  getProductDataBySearch = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (this.state.barcode === "") {
        alert("بەبێ ژمارەی کاڵا ناتوانی بگەڕێی");
      } else {
        const res = await instance.get(
          `/api/v1/searchtoproductByBarcodeName?name=${this.state.barcode}`,
          config
        );
        const data = res.data.product;
        await instance.get(`/api/v1/ChakrdniRasidYakMawad/${data._id}`, config);
        if (data === null) {
          this.setState({
            error: { msg: "ببورە ئەم کاڵایە بونی نیە" },
            prodactdata: [],
            zhmaraySht: "",
            zhmara: "",
            naweSht: "",
            barcode: "",
            kren: 0,
            nrxeFroshtnDolar: 0,
            nrxeFroshtnDenar: 0,
            nrxeFroshtnDolar3: 0,
            nrxeFroshtnDolar2: 0,
            zhmarayKarton: 0,
            qazanj: 0,
            Add_rasidkarton: 0,
            data: [],
          });
        } else {
          this.setState(() => {
            return {
              page: Number(data.barcode),
              prodactdata: data,
              search: true,
              zhmara: data.code,
              naweSht: data.nameofproduct,
              barcode: data.barcode,
              kren: data.price,
              nrxeFroshtnDolar: data.saleDolar,
              nrxeFroshtnDenar: data.saleDinar,
              nrxeFroshtnDolar3: data.saleDolar3,
              nrxeFroshtnDolar2: data.saleDolar2,
              zhmarayKarton: data.numberOfDarzan,
              qazanj: data.qazanj,
              Add_rasidkarton: Number(data.rasidKarton).toFixed(2),
              data: data.table,
              wazn_karton: data.weight,
              del_dis: true,
            };
          });
        }
      }
    } catch (error) {
      this.setError({ msg: "ببورە ئەم کاڵایە بونی نیە" });
    }
  };
  getProductDataBySearchName = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/searchtoproduct?code=&nameofproduct=${this.state.naweSht}`,
        config
      );

      const data = res.data.product;
      await instance.get(`/api/v1/ChakrdniRasidYakMawad/${data._id}`, config);
      if (data === null) {
        alert("ببورە ئەم کاڵایە بونی نیە دەتوانی زیادی بکەی");
      } else {
        this.setState(() => {
          return {
            page: Number(data.barcode),
            prodactdata: data,
            search: true,
            zhmara: data.code,
            naweSht: data.nameofproduct,
            barcode: data.barcode,
            kren: data.price,
            nrxeFroshtnDolar: data.saleDolar,
            nrxeFroshtnDenar: data.saleDinar,
            nrxeFroshtnDolar3: data.saleDolar3,
            nrxeFroshtnDolar2: data.saleDolar2,
            zhmarayKarton: data.numberOfDarzan,
            qazanj: data.qazanj,
            Add_rasidkarton: Number(data.rasidKarton).toFixed(2),
            data: data.table,
            wazn_karton: data.weight,
            del_dis: true,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم کاڵایە بونی نیە");
    }
  };
  setProductSearchEmty = (e) => {
    e.preventDefault();
    this.setState({
      prodactdata: [],
      zhmara: "",
      naweSht: "",
      barcode: 0,
      kren: 0,
      nrxeFroshtnDolar: 0,
      nrxeFroshtnDenar: 0,
      nrxeFroshtnDolar3: 0,
      nrxeFroshtnDolar2: 0,
      zhmarayKarton: 0,
      qazanj: 0,
      Add_rasidkarton: 0,
      data: [],
      wazn_karton: 0,
    });
  };
  //////product pagination
  productpageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.page + 1;
      const res = await instance.get(
        `/api/v1/ppage?page=${page}&limit=1`,
        config
      );

      const data = res.data.pros;
      if (data.status === false) {
        alert("ببورە ئەم کاڵایە بونی نیە");
      } else {
        this.setState(() => {
          return {
            page: Number(data.barcode),
            prodactdata: data,
            search: true,
            zhmara: data.code,
            naweSht: data.nameofproduct,
            barcode: data.barcode,
            kren: data.price,
            nrxeFroshtnDolar: data.saleDolar,
            nrxeFroshtnDenar: data.saleDinar,
            nrxeFroshtnDolar3: data.saleDolar3,
            nrxeFroshtnDolar2: data.saleDolar2,
            zhmarayKarton: data.numberOfDarzan,
            qazanj: data.qazanj,
            Add_rasidkarton: Number(data.rasidKarton).toFixed(2),
            data: data.table,
            wazn_karton: data.weight,
            del_dis: true,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم کاڵایە بونی نیە");
    }
  };
  productpageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = Number(this.state.page) - 1;

      const res = await instance.get(
        `/api/v1/ppage?page=${page}&limit=1`,
        config
      );

      const data = res.data.pros;
      if (data.status === false) {
        alert("ببورە ئەم کاڵایە بونی نیە");
      } else {
        this.setState(() => {
          return {
            page: Number(data.barcode),
            prodactdata: data,
            search: true,
            zhmara: data.code,
            naweSht: data.nameofproduct,
            barcode: data.barcode,
            kren: data.price,
            nrxeFroshtnDolar: data.saleDolar,
            nrxeFroshtnDenar: data.saleDinar,
            nrxeFroshtnDolar3: data.saleDolar3,
            nrxeFroshtnDolar2: data.saleDolar2,
            zhmarayKarton: data.numberOfDarzan,
            qazanj: data.qazanj,
            Add_rasidkarton: Number(data.rasidKarton).toFixed(2),
            data: data.table,
            wazn_karton: data.weight,
            del_dis: true,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم کاڵایە بونی نیە");
    }
  };

  //////////save table product
  onSaveTable = async () => {
    if (
      this.state.karton === 0 ||
      this.state.darzan === 0 ||
      this.state.zhmarayKarton === 0 ||
      this.state.kren === 0 ||
      this.state.nrxeFroshtnDenar === 0 ||
      this.state.nrxeFroshtnDolar === 0 ||
      this.state.nrxeFroshtnDolar2 === 0 ||
      this.state.nrxeFroshtnDolar3 === 0
      // this.state.wazn_karton===0
    ) {
      this.setState({ error: { msg: "تکایە خانەکان داخیل بکە" } });
    } else {
      let Table = this.state.data;
      Table.push({
        _id: Math.random(),
        price: this.state.kren,
        numberOfDarzan: Number(this.state.darzan),
        numberOfCarton: Number(this.state.karton),
        numberOfAllDarzans: this.state.zhmarayKarton,
        total: this.getkoygahty(
          Number(this.state.darzan),
          Number(this.state.karton)
        ),
        date: Date.now(),
      });
      if (this.state.data.length === 0) {
        this.setState({ Add_rasidkarton: 0 });
      } else {
        this.setState({
          Add_rasidkarton:
            Number(this.state.Add_rasidkarton) + Number(this.state.darzan),
        });
      }
      this.setState({
        data: Table,
        darzan: 0,
        karton: 0,
      });
    }
  };
  getRasidKarton = () => {
    let subTotal = 0;
    console.log("first", this.state.Add_rasidkarton);
    this.state.data.map(
      (item) =>
        (subTotal +=
          Number(item.numberOfDarzan) + Number(this.state.Add_rasidkarton))
    );
    const cartTotals = Number(subTotal).toFixed(2);
    this.setState({
      Add_rasidkarton: cartTotals,
    });
  };
  ///// loading product
  ProducthideLoader = () => {
    this.setState({ ProductLoading: false });
  };

  ProductshowLoader = () => {
    this.setState({ ProductLoading: true });
  };
  ////////////////save product
  onSave = async () => {
    if (
      this.state.zhmarayKarton === 0 ||
      this.state.nawisht === "" ||
      this.state.kren === 0 ||
      this.state.nrxeFroshtnDenar === 0 ||
      this.state.nrxeFroshtnDolar === 0 ||
      this.state.nrxeFroshtnDolar2 === 0 ||
      this.state.nrxeFroshtnDolar3 === 0
    ) {
      this.setState({ error: { msg: "تکایە خانەکان داخیل بکە" } });
    } else {
      if (localStorage.token) {
        setToken(localStorage.token);
      }
      const config = {
        "Content-Type": "application/json",
      };

      try {
        let data;
        data = {
          code: this.state.zhmara,
          productCode: this.state.zhmaraySht,
          barcode: this.state.barcode,
          nameofproduct: this.state.naweSht,
          price: this.state.kren,
          saleDinar: parseFloat(
            this.state.nrxeFroshtnDenar.toString().replace(/,/g, "")
          ),
          saleDolar: this.state.nrxeFroshtnDolar,
          saleDolar3: parseFloat(
            this.state.nrxeFroshtnDolar3.toString().replace(/,/g, "")
          ),
          saleDolar2: parseFloat(
            this.state.nrxeFroshtnDolar2.toString().replace(/,/g, "")
          ),
          numberOfDarzan: this.state.zhmarayKarton,
          qazanj: this.state.qazanj,
          table: this.state.data,
          rasidKarton:
            this.state.data.length === 0 ? 0 : this.state.Add_rasidkarton,
          weight: this.state.wazn_karton,
        };
        const res = await instance.post("/api/v1/newProduct", data, config);
        this.ProductshowLoader();
        if (res) {
          this.ProducthideLoader();
        }
        this.setState({
          ok: true,
          del_dis: true,
        });
      } catch (error) {
        alert("تکایە خانەکان پڕبکەوە");
      }
    }
  };

  /////edit product
  edit_product = (data) => {
    this.setState({
      ...this.state,
      edit: data,
    });
  };

  clear_edit = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      edit: null,
    });
  };
  close_update = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      ok: null,
    });
    this.setStateEmpty();
  };
  /////// open to update product by password
  openUpdateproduct = async (e) => {
    e.preventDefault();
    this.setState({
      del_dis: null,
      open: true,
    });
  };
  //////////////////update table product
  updtae_product = async (prodactdata) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    let data;
    data = {
      table: this.state.data,
    };
    const res = await instance.put(
      `/api/v1/updateProduct/${prodactdata._id}`,
      data,
      config
    );

    this.setState({
      data: this.state.data.map((pro) =>
        pro._id === res.data.update._id ? res.data.update : pro
      ),
    });
    this.clear_edit();
  };
  //////////////////////////////update right side
  updtae_Right_product = async (e, prodactdata) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    let data;
    data = {
      code: this.state.zhmara,
      productCode: this.state.zhmaraySht,
      barcode: this.state.barcode,
      nameofproduct: this.state.naweSht,
      price: this.state.kren,
      saleDinar: parseFloat(
        this.state.nrxeFroshtnDenar.toString().replace(/,/g, "")
      ),
      saleDolar: this.state.nrxeFroshtnDolar,
      saleDolar3: parseFloat(
        this.state.nrxeFroshtnDolar3.toString().replace(/,/g, "")
      ),
      saleDolar2: parseFloat(
        this.state.nrxeFroshtnDolar2.toString().replace(/,/g, "")
      ),
      numberOfDarzan: this.state.zhmarayKarton,
      qazanj: this.state.qazanj,
      table: this.state.data,
      rasidKarton:
        this.state.data.length === 0 ? 0 : this.state.Add_rasidkarton,
      weight: this.state.wazn_karton,
    };
    const res = await instance.put(
      `/api/v1/updateProduct/${prodactdata._id}`,
      data,
      config
    );

    this.setState({
      data: this.state.data.map((pro) =>
        pro._id === res.data.update._id ? res.data.update : pro
      ),
      prodactdata: res.data.update,
      ok: true,
      del_dis: true,
      open: null,
      page: Number(res.data.update.barcode),
    });
    this.clear_edit(e);
  };
  ///// refresh
  refreshPage = () => {
    window.location.reload(false);
  };

  // Rent Context Functions()

  // Rent Delete Mithod

  DeleteSail = async (id, table, wasl, index) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    this.setState(
      {
        R_dataTable: this.state.R_dataTable.filter((r, i) => i !== index),
      },
      async () => {
        this.R_koyqazanjywasll();
        if (this.state.R_joripara === "دۆلار") {
          this.R_getALLkoygshty();
        } else if (this.state.R_joripara === "دینار") {
          this.R_getALLkoygshtyID();
        }
        //// agar ql remove bka yan waslli kon remove bka
        if (this.state.R_open !== true) {
          localStorage.setItem("item", JSON.stringify(this.state.R_dataTable));
        } else if (this.state.R_open === true) {
          let hamwi = {
            _id: wasl._id,
            numberofwasl: this.state.R_zh_wasl,
            numberOfWakil:
              this.state.R_frosth === "فرۆشتنی معمیل"
                ? Number(this.state.R_zh_barez)
                : "",
            wakil: this.state.R_nawe_barez,
            phone: this.state.R_mob,
            whossale: this.state.R_wargr,
            joripara: this.state.R_joripara,
            froshtn: this.state.R_frosth,
            saleTable: this.state.R_dataTable,
            koalldinar:
              this.state.R_joripara === "دینار" ? this.state.R_totalID : 0,
            koalldolar:
              this.state.R_joripara === "دۆلار" ? this.state.R_total : 0,
            dashkandolar:
              this.state.R_joripara === "دۆلار" && this.state.R_dashkan !== null
                ? this.state.R_dashkan
                : 0,
            dashkandinar:
              this.state.R_joripara === "دینار" &&
              this.state.R_dashkanID !== null
                ? this.state.R_dashkanID
                : 0,
            paraydrawdolar:
              this.state.R_joripara === "دۆلار" &&
              this.state.R_paraDan$ !== "" &&
              this.state.R_paraDan$ !== null
                ? parseFloat(this.state.R_paraDan$.toString().replace(/,/g, ""))
                : 0,
            paraydrawdinar:
              this.state.R_joripara === "دینار" &&
              this.state.R_paraDanID !== "" &&
              this.state.R_paraDanID !== null
                ? parseFloat(
                    this.state.R_paraDanID.toString().replace(/,/g, "")
                  )
                : 0,
            qarzinwedolar:
              this.state.R_joripara === "دۆلار"
                ? this.state.R_paraDan$ !== ""
                  ? this.R_waslKrdn()
                  : this.R_kamkrdnawayNrx$()
                : 0,
            qarzinwedinar:
              this.state.R_joripara === "دینار"
                ? this.state.R_paraDanID !== ""
                  ? this.R_waslKrdnID()
                  : this.R_kamkrdnawayNrxID()
                : 0,
            kreysayra: this.state.R_kreysayara,
            kreykrekar: this.state.R_kreykrekar,
            koykartonkan: this.state.R_kartonakan,
            waznakan: this.state.R_koywaznkarton,
            date: this.state.R_date,
            joridolar: this.state.R_joridolar,
          };
          localStorage.setItem("saledata", JSON.stringify(hamwi));
        }

        //// agar mawad daxil kra natwani new bka
        if (this.state.R_dataTable.length === 0) {
          localStorage.removeItem("item");
          this.setState({
            R_table: false,
          });
        }
        this.R_getkartonakan();
        this.R_waznkartonakan();
        this.R_koyqazanjywasll();
      }
    );
  };

  ////to open update
  openUpdate = async (NOW, userid, e, id) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const { value: text } = await Swal.fire({
      input: "password",
      inputLabel: "Password",
      showLoaderOnConfirm: true,
      inputPlaceholder: "وشەی نهێنی داخیل بکە...",
      inputAttributes: {
        "aria-label": "وشەی نهێنی داخیل بکە",
      },
      showCancelButton: true,
    });
    const data = {
      password: text,
    };
    const pass = await instance.post(
      `api/v1/openSale/${id}?id=${userid}`,
      data,
      config
    );

    if (pass.data.status === false) {
      alert("وشەی نهێنی هەڵەیە");
    } else if (pass.data.status === true) {
      this.R_koyqazanjywasll();
      let hamwi = {
        _id: id,
        numberofwasl: this.state.R_zh_wasl,
        numberOfWakil:
          this.state.R_frosth === "فرۆشتنی معمیل"
            ? Number(this.state.R_zh_barez)
            : "",
        wakil: this.state.R_nawe_barez,
        phone: this.state.R_mob,
        whossale: this.state.R_wargr,
        joripara: this.state.R_joripara,
        froshtn: this.state.R_frosth,
        saleTable: this.state.R_dataTable,
        koalldinar:
          this.state.R_joripara === "دینار" ? this.state.R_totalID : 0,
        koalldolar: this.state.R_joripara === "دۆلار" ? this.state.R_total : 0,
        dashkandolar:
          this.state.R_joripara === "دۆلار" && this.state.R_dashkan !== null
            ? this.state.R_dashkan
            : 0,
        dashkandinar:
          this.state.R_joripara === "دینار" && this.state.R_dashkanID !== null
            ? this.state.R_dashkanID
            : 0,
        paraydrawdolar:
          this.state.R_joripara === "دۆلار" &&
          this.state.R_paraDan$ !== "" &&
          this.state.R_paraDan$ !== null
            ? parseFloat(this.state.R_paraDan$.toString().replace(/,/g, ""))
            : 0,
        paraydrawdinar:
          this.state.R_joripara === "دینار" &&
          this.state.R_paraDanID !== "" &&
          this.state.R_paraDanID !== null
            ? parseFloat(this.state.R_paraDanID.toString().replace(/,/g, ""))
            : 0,
        qarzinwedolar:
          this.state.R_joripara === "دۆلار"
            ? this.state.R_paraDan$ !== ""
              ? this.R_waslKrdn()
              : this.R_kamkrdnawayNrx$()
            : 0,
        qarzinwedinar:
          this.state.R_joripara === "دینار"
            ? this.state.R_paraDanID !== ""
              ? this.R_waslKrdnID()
              : this.R_kamkrdnawayNrxID()
            : 0,
        kreysayra: this.state.R_kreysayara,
        kreykrekar: this.state.R_kreykrekar,
        koykartonkan: this.state.R_kartonakan,
        waznakan: this.state.R_koywaznkarton,
        date: this.state.R_date,
        joridolar: this.state.R_joridolar,
      };
      localStorage.setItem("saledata", JSON.stringify(hamwi));
      this.setState(
        {
          R_del_dis: null,
          R_open: true,
          R_suggestions: [],
        },
        async () => {}
      );
      this.R_koyqazanjywasll();
    }
  };

  /// on refresh and open
  R_onopenandrefresh = (data) => {
    this.R_koyqazanjywasll();
    this.setState(
      {
        R_wasl: data,
        R_search_wasl: true,
        R_zh_wasl: data.numberofwasl,
        R_nawe_barez: data.wakil,
        R_mob: data.phone,
        R_zh_barez: data.numberOfWakil,
        R_total: data.koalldolar,
        R_totalID: data.koalldinar,
        R_dashkan: data.dashkandolar,
        R_dashkanID: data.dashkandinar,
        R_dataTable: data.saleTable,
        R_joripara: data.joripara,
        R_frosth: data.froshtn,
        R_wargr: data.whossale,
        R_paraDanID: data.paraydrawdinar,
        R_paraDan$: data.paraydrawdolar,
        R_kartonakan: data.koykartonkan,
        R_kreysayara: data.kreysayra,
        R_kreykrekar: data.kreykrekar,
        R_koywaznkarton: data.waznakan,
        R_paraywasl:
          data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
        R_del_dis: null,
        R_up_froshtn: data.froshtn,
        R_date: data.date,
        R_togler: false,
        R_open: true,
        R_joridolar: data.joridolar,
        R_note: data.note
      },
      () => {
        if (this.state.R_joripara === "دۆلار") {
          this.R_getALLkoygshty();
        } else if (this.state.R_joripara === "دینار") {
          this.R_getALLkoygshtyID();
        }
      }
    );
  };
  //////update sale
  updateSale = async (NOW, id, e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    try {
      if (this.state.R_nawe_barez === "" || this.state.R_date === "") {
        alert("هەڵە هەیە لە خانەکان");
      } else if (
        this.state.R_frosth === "فرۆشتنی معمیل" &&
        (this.state.R_zh_barez === 0 || this.state.R_zh_barez === "")
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        this.R_koyqazanjywasll();
        let hamwi = {
          numberOfWakil:
            this.state.R_frosth === "فرۆشتنی معمیل"
              ? Number(this.state.R_zh_barez)
              : "",
          wakil: this.state.R_nawe_barez,
          phone: this.state.R_mob,
          whossale: this.state.R_wargr,
          joripara: this.state.R_joripara,
          froshtn: this.state.R_frosth,
          saleTable: this.state.R_dataTable,
          koalldinar:
            this.state.R_joripara === "دینار" ? this.state.R_totalID : 0,
          koalldolar:
            this.state.R_joripara === "دۆلار" ? this.state.R_total : 0,
          dashkandolar:
            this.state.R_joripara === "دۆلار" && this.state.R_dashkan !== null
              ? this.state.R_dashkan
              : 0,
          dashkandinar:
            this.state.R_joripara === "دینار" && this.state.R_dashkanID !== null
              ? this.state.R_dashkanID
              : 0,
          paraymawadolar:
            this.state.R_joripara === "دۆلار"
              ? this.state.R_dashkan !== 0
                ? this.R_kamkrdnawayNrx$()
                : this.state.R_total
              : 0,
          paraymawadinar:
            this.state.R_joripara === "دینار"
              ? this.state.R_dashkanID !== 0
                ? this.R_kamkrdnawayNrxID()
                : this.state.R_totalID
              : 0,
          paraydrawdolar:
            this.state.R_joripara === "دۆلار" &&
            this.state.R_paraDan$ !== "" &&
            this.state.R_paraDan$ !== null
              ? parseFloat(this.state.R_paraDan$.toString().replace(/,/g, ""))
              : 0,
          paraydrawdinar:
            this.state.R_joripara === "دینار" &&
            this.state.R_paraDanID !== "" &&
            this.state.R_paraDanID !== null
              ? parseFloat(this.state.R_paraDanID.toString().replace(/,/g, ""))
              : 0,
          qarzinwedolar:
            this.state.R_joripara === "دۆلار"
              ? this.state.R_paraDan$ !== ""
                ? this.R_waslKrdn()
                : this.R_kamkrdnawayNrx$()
              : 0,
          qarzinwedinar:
            this.state.R_joripara === "دینار"
              ? this.state.R_paraDanID !== ""
                ? this.R_waslKrdnID()
                : this.R_kamkrdnawayNrxID()
              : 0,
          kreysayra: this.state.R_kreysayara,
          kreykrekar: this.state.R_kreykrekar,
          koykartonkan: this.state.R_kartonakan,
          waznakan: this.state.R_koywaznkarton,
          date: this.state.R_date === "" ? Date.now : this.state.R_date,
          jwlla: "فرۆشتن",
          joridolar: this.state.R_joridolar,
          dateQazrz: this.state.R_kateQarz,
          qarzikonwakildolar: this.state.R_rasidWakildolar,
          qarzikonwakildinar: this.state.R_rasidWakildinar,
          note: this.state.R_note
        };
        const res = await instance.put(
          `/api/v1/updateSaleAll/${id}`,
          hamwi,
          config
        );
        localStorage.removeItem("saledata");
        localStorage.removeItem("item");
        this.setState(
          {
            R_up_wasl: true,
            R_del_dis: true,
            R_open: null,
            R_wasl: res.data.update,
            R_paraywasl:
              res.data.update.joripara === "دۆلار"
                ? res.data.update.qarzinwedolar
                : res.data.update.qarzinwedinar,
            R_togler: false,
            R_table: false,
            R_date: res.data.update.date,
            R_suggestions: [],
          },
          async () => {}
        );
      }
    } catch (error) {
      alert("هەڵە هەیە لە خانەکان");
    }
  };

  ///// nrx change
  R_nrxchange = (e) => {
    this.setState(
      {
        R_nrx: e.target.value,
      },
      () => {
        let sawt = new Audio(warningAdiuo);
        sawt.play();
        if (this.state.R_joripara === "دۆلار") {
          let qazanj = _.subtract(
            Number(this.state.R_nrx),
            Number(this.state.R_kren)
          );
          let result = Number(
            _.multiply(qazanj, Number(this.state.R_nrx))
          ).toFixed(2);
          this.setState({
            R_qazanj: result,
          });
        } else if (this.state.R_joripara === "دینار") {
          this.setState({
            R_qazanj: (
              (this.dinartodolar(Number(this.state.R_nrx)) -
                this.state.R_kren) *
              this.state.R_rezha
            ).toFixed(2),
          });
        }
      }
    );
  };
  /////get print with password in Rent
  getPrintPasswordRent = (NOW) => {
    if (this.state.R_dataTable.length === 0) {
      alert("بەبێ کاڵا ناتوانی پسوولە چاپ بکەی");
    } else {
      const p = prompt("وشەی نهێنی داخیل بکە!");
      if (p === "55") {
        this.setState({ R_pass: true }, () => {
          // if (this.state.R_date <= "2021-07-9T00:00:00Z") {
          //   this.setState({
          //     R_rasidWakildinar:0,
          //     R_rasidWakildolar:0,
          //     R_rasidWakildinarNew:0,
          //     R_rasidWakildolarNew:0
          //   })
          //  // this.getNewRaidForwakil(NOW);
          // }
          window.setTimeout("window.print()", 2000);
        });
      }
    }
  };
  ////Rent Clear Error
  R_ClearError = () => {
    this.setState({ R_error: null });
  };
  // Rent Find Number Of Carton Or darzan

  R_DarzanChange = (e) => {
    this.setState(
      {
        R_darzan: e.target.value,
      },
      () => {
        this.setState({
          R_karton: this.R_calculateDarzan(),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        [e.target.name]: "0.",
      });
    }
    this.R_ClearError();
  };
  R_CartonChange = (e) => {
    this.setState(
      {
        R_karton: e.target.value,
      },
      () => {
        this.setState({
          R_darzan: this.R_calculateCarton(),
          R_nrx: this.nrxRent(
            Number(this.state.R_darzan),
            Number(this.state.R_karton)
          ),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        [e.target.name]: "0.",
      });
    }
    this.R_ClearError();
  };
  R_calculateCarton = () => {
    const zhmarayKarton = this.state.R_rezha;
    const carton = Number(this.state.R_karton);
    const darzan = carton * zhmarayKarton;

    return darzan.toFixed(4);
  };
  R_calculateDarzan = () => {
    const zhmarayKarton = this.state.R_rezha;
    const darzan = Number(this.state.R_darzan);
    const karton = darzan / zhmarayKarton;
    return karton.toFixed(4);
  };
  // Rent OnSaveRent()
  setRentSaveSale = () => {
    this.setState({
      R_karton: 0,
      R_darzan: 0,
      R_nrx: 0,
      R_naweSht: "",
      R_rezha: 0,
      R_koygshty$: 0,
      R_koygshtyID: 0,
      R_zhmara: "",
      R_nrx_dolar: 0,
      R_nrx_dinarr: 0,
      R_nrx_dolar2: 0,
      R_nrx_dolar3: 0,
      R_search: null,
      R_rasidKarton: 0,
      R_qazanj: 0,
      R_kren: 0,
      R_nrxkaton: 0,
    });
  };
  ////// new page
  createNewPage = (e) => {
    e.preventDefault();
    this.setState(
      {
        R_nawe_barez: "",
        R_mob: 0,
        R_zh_barez: 0,
        R_total: 0,
        R_totalID: 0,
        R_dashkan: 0,
        R_dashkanID: 0,
        R_dataTable: [],
        R_search: null,
        dinar: false,
        dolar: false,
        R_search_wasl: null,
        R_kartonakan: 0,
        R_nwe: true,
        R_joripara: null,
        R_zhmara: "",
        R_naweSht: "",
        R_karton: 0,
        R_darzan: 0,
        R_kreykrekar: 0,
        R_kreysayara: 0,
        R_save: null,
        R_nrx: 0,
        R_waznkarton: 0,
        R_koywaznkarton: 0,
        R_paraDan$: "",
        R_paraDanID: "",
        R_WakilQard_isChecked: false,
        R_isChecked: false,
        R_search_wakil: null,
        R_zh_wasl: 0,
        R_wargr: null,
        R_frosth: null,
        R_rezha: 0,
        R_rasidKarton: 0,
        R_up_wasl: null,
        R_del_dis: null,
        R_paraywasl: 0,
        R_open: null,
        R_wasl: null,
        R_togler: false,
        R_date: "",
        R_rasidWakildolar: 0,
        R_rasidWakildolarNew: 0,
        R_rasidWakildinarNew: 0,
        R_rasidWakildinar: 0,
        R_joridolar: null,
        R_kateQarz: "",
        R_suggestions: [],
        suggestions: [],
      },
      () => {
        this.getWaslakan();
      }
    );
    this.R_ClearError();
  };

  R_getIsChecked = (e) => {
    this.setState({
      R_isChecked: !this.state.R_isChecked,
    });
  };
  R_WakilQard_getIsChecked = (e) => {
    this.setState({
      R_WakilQard_isChecked: !this.state.R_WakilQard_isChecked,
    });
  };
  R_darzankartontoanotherInput = (e) => {
    if (
      this.state.R_rasidKarton <= 0 ||
      Number(this.state.R_karton) > this.state.R_rasidKarton
    ) {
      alert("!رەسیدی پێویست نیە");
    }
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index - 1].focus();
  };
  ///////save befor
  R_saveDetail = async (e, wasl) => {
    e.preventDefault();
    if (this.state.R_darzan === "") {
      alert("!تکایە خانەکان پڕ بکەرەوە");
    } else {
      let qazanj =
        this.state.R_joripara === "دۆلار"
          ? _.multiply(
            _.subtract(Number(this.state.R_nrx), Number(this.state.R_kren)),
            this.state.R_darzan
          )
          : this.dinartodolar(
            _.multiply(
              _.subtract(
                Number(this.state.R_nrx),
                this.dolartodinar(Number(this.state.R_kren))
              ),
              this.state.R_darzan
            )
          );
      let table = [
        {
          raqam: "",
          nawisht: this.state.R_naweSht,
          carton: 0,
          darzan: Number(this.state.R_darzan),
          nrx: Number(this.state.R_nrx),
          reja: 0,
          kren: this.state.R_kren,
          kodolar:
            this.state.R_joripara === "دۆلار"
              ? _.multiply(
                Number(this.state.R_darzan),
                Number(this.state.R_nrx)
              )
              : 0,
          kodinar:
            this.state.R_joripara === "دینار"
              ? _.multiply(
                Number(this.state.R_darzan),
                Number(this.state.R_nrx)
              )
              : 0,
          wazn: 0,
          qazanj: qazanj,
        },
        ...this.state.R_dataTable,
      ];
      this.setState(
        {
          R_dataTable: table,
          R_karton: 0,
          R_darzan: 0,
          R_nrx: 0,
          R_naweSht: "",
          R_rezha: 0,
          R_koygshty$: 0,
          R_koygshtyID: 0,
          R_zhmara: "",
          R_nrx_dolar: 0,
          R_nrx_dinarr: 0,
          R_nrx_dolar2: 0,
          R_nrx_dolar3: 0,
          R_search: null,
          R_rasidKarton: 0,
          R_qazanj: 0,
          R_kren: 0,
          R_nrxkaton: 0,
          R_table: true,
        },
        () => {
          if (this.state.R_joripara === "دۆلار") {
            this.R_getALLkoygshty();
          } else if (this.state.R_joripara === "دینار") {
            this.R_getALLkoygshtyID();
          }
          this.R_getkartonakan();
          this.R_waznkartonakan();
          this.R_koyqazanjywasll();
        }
      );
    }
  };
  saveDetail = async (e, wasl) => {
    e.preventDefault();
    if (this.state.R_karton === 0 || this.state.R_joripara === null) {
      alert("!تکایە خانەکان پڕ بکەرەوە");
    } else {
      if (this.state.R_karton === "" || this.state.R_darzan === "") {
        alert("!تکایە خانەکان پڕ بکەرەوە");
      } else {
        if (localStorage.token) {
          setToken(localStorage.token);
        }
        const config = {
          "Content-Type": "application/json",
        };
        let Table = this.state.R_dataTable;
        let qazanj = _.multiply(
          _.subtract(Number(this.state.R_nrx), Number(this.state.R_kren)),
          this.state.R_darzan
        );

        let KrinIQD = _.multiply(
          Number(this.state.R_kren),
          _.divide(Number(this.state.navdolar), 100)
        );
        let qazanjIQD = this.dinartodolar(
          _.subtract(Number(this.state.R_nrx), Number(KrinIQD))
        );

        Table.unshift({
          _id: this.state.R_productId,
          raqam: this.state.R_zhmara,
          nawisht: this.state.R_naweSht,
          carton: Number(this.state.R_karton),
          darzan: Number(this.state.R_darzan),
          nrx: Number(this.state.R_nrx),
          reja: this.state.R_rezha,
          kren: this.state.R_kren,
          kodolar:
            this.state.R_joripara === "دۆلار"
              ? this.R_getkoygahty(this.state.R_darzan, this.state.R_karton)
              : 0,
          kodinar:
            this.state.R_joripara === "دینار"
              ? this.R_getkoygahty(this.state.R_darzan, this.state.R_karton)
              : 0,
          wazn: this.state.R_waznkarton,
          qazanj: this.state.R_joripara === "دۆلار" ? qazanj : qazanjIQD,
        });
        this.setState(
          {
            R_dataTable: Table,
            R_karton: 0,
            R_darzan: 0,
            R_nrx: 0,
            R_naweSht: "",
            R_rezha: 0,
            R_koygshty$: 0,
            R_koygshtyID: 0,
            R_zhmara: "",
            R_nrx_dolar: 0,
            R_nrx_dinarr: 0,
            R_nrx_dolar2: 0,
            R_nrx_dolar3: 0,
            R_search: null,
            R_rasidKarton: 0,
            R_qazanj: 0,
            R_kren: 0,
            R_nrxkaton: 0,
            R_table: true,
          },
          () => {
            if (this.state.R_joripara === "دۆلار") {
              this.R_getALLkoygshty();
            } else if (this.state.R_joripara === "دینار") {
              this.R_getALLkoygshtyID();
            }
            this.R_getkartonakan();
            this.R_waznkartonakan();
            this.R_koyqazanjywasll();

            if (this.state.R_open !== true) {
              localStorage.setItem(
                "item",
                JSON.stringify(this.state.R_dataTable)
              );
            } else if (this.state.R_open === true) {
              let hamwi = {
                _id: wasl._id,
                numberofwasl: this.state.R_zh_wasl,
                numberOfWakil:
                  this.state.R_frosth === "فرۆشتنی معمیل"
                    ? Number(this.state.R_zh_barez)
                    : "",
                wakil: this.state.R_nawe_barez,
                phone: this.state.R_mob,
                whossale: this.state.R_wargr,
                joripara: this.state.R_joripara,
                froshtn: this.state.R_frosth,
                saleTable: this.state.R_dataTable,
                koalldinar:
                  this.state.R_joripara === "دینار" ? this.state.R_totalID : 0,
                koalldolar:
                  this.state.R_joripara === "دۆلار" ? this.state.R_total : 0,
                dashkandolar:
                  this.state.R_joripara === "دۆلار" &&
                  this.state.R_dashkan !== null
                    ? this.state.R_dashkan
                    : 0,
                dashkandinar:
                  this.state.R_joripara === "دینار" &&
                  this.state.R_dashkanID !== null
                    ? this.state.R_dashkanID
                    : 0,
                paraydrawdolar:
                  this.state.R_joripara === "دۆلار" &&
                  this.state.R_paraDan$ !== "" &&
                  this.state.R_paraDan$ !== null
                    ? parseFloat(
                        this.state.R_paraDan$.toString().replace(/,/g, "")
                      )
                    : 0,
                paraydrawdinar:
                  this.state.R_joripara === "دینار" &&
                  this.state.R_paraDanID !== "" &&
                  this.state.R_paraDanID !== null
                    ? parseFloat(
                        this.state.R_paraDanID.toString().replace(/,/g, "")
                      )
                    : 0,
                qarzinwedolar:
                  this.state.R_joripara === "دۆلار"
                    ? this.state.R_paraDan$ !== ""
                      ? this.R_waslKrdn()
                      : this.R_kamkrdnawayNrx$()
                    : 0,
                qarzinwedinar:
                  this.state.R_joripara === "دینار"
                    ? this.state.R_paraDanID !== ""
                      ? this.R_waslKrdnID()
                      : this.R_kamkrdnawayNrxID()
                    : 0,
                kreysayra: this.state.R_kreysayara,
                kreykrekar: this.state.R_kreykrekar,
                koykartonkan: this.state.R_kartonakan,
                waznakan: this.state.R_koywaznkarton,
                date: this.state.R_date,
              };
              localStorage.setItem("saledata", JSON.stringify(hamwi));
            }
          }
        );

        this.R_ClearError();
      }
    }
  };
  //// loading function
  hideLoader = () => {
    this.setState({ loading: false });
  };

  showLoader = () => {
    this.setState({ loading: true });
  };
  ////// save tabel
  onSaveRent = async (e) => {
    e.preventDefault();
    this.R_koyqazanjywasll();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (
        this.state.R_dataTable.length === 0
      ) {
        this.setState({ loading: false });
        alert("تکایە کاڵا داخیل بکە ");
        const sawtError = new Audio(errorAduio);
        sawtError.play();
        return false;
      } else if (this.state.R_nawe_barez.toString().length === 0) {
        this.setState({ loading: false });
        alert("تکایە ناوی بەڕێز پڕبکەوە");
        const sawtError = new Audio(errorAduio);
        sawtError.play();
      } else if (
        this.state.R_frosth === "" ||
        this.state.R_wargr.toString().length === 0 ||
        this.state.R_wargr === "ناوی وەرگر"
      ) {
        this.setState({ loading: false });
        alert("تکایە خانەکان پڕبکەوە");
        const sawtError = new Audio(errorAduio);
        sawtError.play();
        return false;
      } else if (
        this.state.R_frosth === "فرۆشتنی معمیل" &&
        (this.state.R_zh_barez === 0 || this.state.R_zh_barez === "")
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        this.R_koyqazanjywasll();
        let data;
        data = {
          numberOfWakil:
            this.state.R_frosth === "فرۆشتنی معمیل"
              ? Number(this.state.R_zh_barez)
              : 0,
          wakil: this.state.R_nawe_barez,
          phone: this.state.R_mob,
          whossale: this.state.R_wargr,
          joripara: this.state.R_joripara,
          froshtn: this.state.R_frosth,
          saleTable: this.state.R_dataTable,
          koalldinar:
            this.state.R_joripara === "دینار" ? this.state.R_totalID : 0,
          koalldolar:
            this.state.R_joripara === "دۆلار" ? this.state.R_total : 0,
          dashkandolar:
            this.state.R_joripara === "دۆلار" && this.state.R_dashkan !== null
              ? this.state.R_dashkan
              : 0,
          dashkandinar:
            this.state.R_joripara === "دینار" && this.state.R_dashkanID !== null
              ? this.state.R_dashkanID
              : 0,
          paraymawadolar:
            this.state.R_joripara === "دۆلار"
              ? this.state.R_dashkan !== 0
                ? this.R_kamkrdnawayNrx$()
                : this.state.R_total
              : 0,
          paraymawadinar:
            this.state.R_joripara === "دینار"
              ? this.state.R_dashkanID !== 0
                ? this.R_kamkrdnawayNrxID()
                : this.state.R_totalID
              : 0,
          paraydrawdolar:
            this.state.R_joripara === "دۆلار" &&
            this.state.R_paraDan$ !== "" &&
            this.state.R_paraDan$ !== null
              ? parseFloat(this.state.R_paraDan$.replace(/,/g, ""))
              : 0,
          paraydrawdinar:
            this.state.R_joripara === "دینار" &&
            this.state.R_paraDanID !== "" &&
            this.state.R_paraDanID !== null
              ? parseFloat(this.state.R_paraDanID.replace(/,/g, ""))
              : 0,
          qarzinwedolar:
            this.state.R_joripara === "دۆلار"
              ? this.state.R_paraDan$ !== ""
                ? this.R_waslKrdn()
                : this.R_kamkrdnawayNrx$()
              : 0,
          qarzinwedinar:
            this.state.R_joripara === "دینار"
              ? this.state.R_paraDanID !== ""
                ? this.R_waslKrdnID()
                : this.R_kamkrdnawayNrxID()
              : 0,
          kreysayra: this.state.R_kreysayara,
          kreykrekar: this.state.R_kreykrekar,
          koykartonkan: this.state.R_kartonakan,
          waznakan: this.state.R_koywaznkarton,
          date: this.state.R_date === "" ? Date.now : this.state.R_date,
          jwlla: "فرۆشتن",
          joridolar: this.state.R_joridolar,
          dateQazrz: this.state.R_kateQarz,
          qarzikonwakildolar: this.state.R_rasidWakildolar,
          qarzikonwakildinar: this.state.R_rasidWakildinar,
          DolarPrice: this.state.navdolar,
          note: this.state.R_note
        };
        const draw = this.state.R_joripara === "دینار" ? "dinar" : "dolar";
        const res = await instance.post(
          `/api/v1/newSale?opr=plus&joridraw=${draw}`,
          data,
          config
        );

        localStorage.removeItem("item");
        this.showLoader();

        this.setState(
          {
            R_save: true,
            R_zh_wasl: res.data.saleproduct.numberofwasl,
            W_raseddenar: 0,
            W_raseddolar: 0,
            R_wasl: res.data.saleproduct,
            R_paraywasl:
              res.data.saleproduct.joripara === "دۆلار"
                ? res.data.saleproduct.qarzinwedolar
                : res.data.saleproduct.qarzinwedinar,
            R_del_dis: true,
            R_up_froshtn: res.data.saleproduct.froshtn,
            R_date: res.data.saleproduct.date,
            R_togler: false,
            R_table: false,
            loading: false,
          },
          async () => {
            this.hideLoader();
            this.getWaslakan();
          }
        );
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  ///// get Rent Data
  getRenttableData = async (table) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/getSaleTable/${table._id}`, config);

    this.setState({
      R_dataRent: res.data.sales.saleTable,
    });
  };
  getWaslakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/getSaleakan`, config);

    this.setState({
      R_zh_wasl_katy: res.data.sales + 1,
      R_page: res.data.sales + 1,
    });
  };
  setRnew = () => {
    this.setState({ R_nwe: null });
  };
  ////// radio change
  radiochange = (e) => {
    const value = e.target.value;

    if (value === "6") {
      this.setState({
        A_role: "user",
      });
    } else if (value === "7") {
      this.setState({
        A_role: "admin",
      });
    }
  };
  // NrxeRent ba Deare Krdne Darzan
  nrxRent = (darzan, carton) => {
    let nrx = Number(this.state.R_nrx);

    if (this.state.R_joripara === "دینار") {
      nrx = this.state.R_nrx_dinarr;
      return Number(nrx);
    } else if (this.state.R_joripara === "دۆلار") {
      if (this.state.R_joridolar === "1 دۆلار") {
        nrx = this.state.R_nrx_dolar;
        return Number(nrx);
      } else if (this.state.R_joridolar === "2 دۆلار") {
        nrx = this.state.R_nrx_dolar2;
        return Number(nrx);
      } else if (this.state.R_joridolar === "3 دۆلار") {
        nrx = this.state.R_nrx_dolar3;
        return Number(nrx);
      }
    }
  };
  // KoyGshty Rent ba DOLAR$$$
  R_getkoygahty = (darzan, karton) => {
    if (karton !== 0) {
      const nrx = Number(this.state.R_nrx);
      let koygshty = Number(darzan) * nrx;

      return Number(koygshty.toFixed(2));
    } else {
      const nrx = Number(this.state.R_nrx);
      let koygshty = Number(darzan) * nrx;

      return Number(koygshty.toFixed(2));
    }
  };
  ///////saerch  in Table Rent
  // {**************saerch***************}
  ////// search product
  getProductDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    this.toanotherInput(e);

    const res = await instance.get(
      `/api/v1/searchtoproductByBarcodeName?name=${this.state.R_zhmara}`,
      config
    );

    const data = res.data.product;
    if (data === null) {
      alert("ببورە ئەم کاڵایە بونی نیە");
    } else {
      this.setState(
        () => {
          return {
            R_search: true,
            R_productId: data._id,
            R_naweSht: data.nameofproduct,
            R_rezha: data.numberOfDarzan,
            R_nrx_dolar: data.saleDolar,
            R_nrx_dinarr: data.saleDinar,
            R_nrx:
              this.state.R_joripara === "دینار"
                ? data.saleDinar
                : data.saleDolar,
            R_nrx_dolar2: data.saleDolar2,
            R_nrx_dolar3: data.saleDolar3,
            R_rasidKarton: data.rasidKarton,
            R_qazanj:
              this.state.R_joripara === "دینار"
                ? (
                    (this.dinartodolar(data.saleDinar) - data.price) *
                    data.numberOfDarzan
                  ).toFixed(2)
                : data.qazanj,
            R_kren: data.price,
            R_nrxkaton: (data.price * data.numberOfDarzan).toFixed(2),
            R_waznkarton: data.weight,
            R_karton: "",
            R_darzan: "",
            R_zhmara: data.barcode,
            R_suggestions: [],
          };
        },
        () => {
          this.setState(
            {
              R_nrx: this.nrxRent(),
            },
            () => {
              if (this.state.R_joripara === "دۆلار") {
                this.setState({
                  R_qazanj: (
                    (Number(this.state.R_nrx) - this.state.R_kren) *
                    this.state.R_rezha
                  ).toFixed(2),
                });
              } else if (this.state.R_joripara === "دینار") {
                this.setState({
                  R_qazanj: (
                    (this.dinartodolar(Number(this.state.R_nrx)) -
                      this.state.R_kren) *
                    this.state.R_rezha
                  ).toFixed(2),
                });
              }
            }
          );
        }
      );
    }
  };
  ////// search Wakil
  ///to another input in wakil search
  R_anotherInputWakil = (e) => {
    e.preventDefault();
    if (
      this.state.R_frosth === "فرۆشتنی ڕاستەوخۆ" ||
      this.state.R_frosth === null
    ) {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index - 1].focus();
    } else {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index - 1].focus();
      this.getWakilDataBySearchRent(e);
    }
  };
  getWakilDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (
      this.state.R_frosth === "فرۆشتنی ڕاستەوخۆ" ||
      this.state.R_frosth === null
    ) {
      alert("ببورە جۆری فرۆشتن معمیل نیە");
    } else if (this.state.R_zh_barez === "") {
      alert("تکایە خانەکان پڕبکەوە");
    } else {
      const res = await instance.get(
        `/api/v1/searchwakil?code=${
          Number(this.state.R_zh_barez) === 0
            ? ""
            : Number(this.state.R_zh_barez)
        }&&Name=${this.state.R_nawe_barez}&&phone=${this.state.R_mob}`,
        config
      );

      const data = res.data.wakils;
      if (data === null) {
        alert("ببورە ئەم بەڕێزە بونی نیە");
        this.setState({
          R_zh_barez: 0,
          R_nawe_barez: "",
          R_mob: 0,
          R_rasidWakildolar: 0,
          R_rasidWakildinar: 0,
        });
      } else {
        this.setState(() => {
          return {
            R_search_wakil: true,
            R_zh_barez: data.code,
            R_nawe_barez: data.Name,
            R_mob: data.phone,
            R_rasidWakildolar: data.rejaDolar.toFixed(2),
            R_rasidWakildinar: data.rejaDinar.toFixed(2),
          };
        });
      }
    }
  };

  setSearchEmptyRentWakil = (e) => {
    e.preventDefault();
    this.setState({
      R_search_wakil: null,
      R_zh_barez: 0,
      R_nawe_barez: "",
      R_mob: 0,
      R_rasidWakildolar: 0,
      R_rasidWakildinar: 0,
    });
  };

  setSaletSearchEmty = (e) => {
    e.preventDefault();
    this.setState({
      R_zhmara: "",
      R_naweSht: "",
      R_karton: 0,
      R_darzan: 0,
      R_search: null,
      R_rezha: 0,
      R_nrx: 0,
      R_suggestions: [],
    });
  };
  ////datetogler
  inputtogler = () => {
    this.setState({
      R_togler: !this.state.R_togler,
      R_date: "",
    });
  };
  /////// saerch wasl
  getWaslDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchwasll?numberofwasl=${Number(this.state.R_zh_wasl)}`,
      config
    );

    const data = res.data.pswla;
    if (data === null) {
      alert("ببورە ئەم پسولەیە بونی نیە");
    } else {
      this.setState(() => {
        return {
          R_wasl: data,
          R_search_wasl: true,
          R_zh_wasl: data.numberofwasl,
          R_nawe_barez: data.wakil,
          R_mob: data.phone,
          R_zh_barez: data.numberOfWakil,
          R_total: data.koalldolar,
          R_totalID: data.koalldinar,
          R_dashkan: data.dashkandolar,
          R_dashkanID: data.dashkandinar,
          R_dataTable: data.saleTable,
          R_joripara: data.joripara,
          R_frosth: data.froshtn,
          R_wargr: data.whossale,
          R_paraDanID: data.paraydrawdinar,
          R_paraDan$: data.paraydrawdolar,
          R_kartonakan: data.koykartonkan,
          R_kreysayara: data.kreysayra,
          R_kreykrekar: data.kreykrekar,
          R_koywaznkarton: data.waznakan,
          R_paraywasl:
            data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
          R_del_dis: true,
          R_up_froshtn: data.froshtn,
          R_date: data.date,
          R_togler: false,
          R_open: null,
          R_joridolar: data.joridolar,
          R_kateQarz: data.dateQazrz,
          R_rasidWakildinarNew:
            Number(data.qarzikonwakildinar) + Number(data.qarzinwedinar),
          R_rasidWakildolarNew:
            Number(data.qarzikonwakildolar) + Number(data.qarzinwedolar),
          R_rasidWakildinar: Number(data.qarzikonwakildinar),
          R_rasidWakildolar: Number(data.qarzikonwakildolar),
          R_note: data.note
        };
      });
    }
  };
  //////Rent pagination
  R_pageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.R_page + 1;
      const res = await instance.get(`/api/v1/salepage?page=${page}&limit=1`);
      const data = res.data.sales;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            R_page: res.data.page,
            R_wasl: data,
            R_search_wasl: true,
            R_zh_wasl: data.numberofwasl,
            R_nawe_barez: data.wakil,
            R_mob: data.phone,
            R_zh_barez: data.numberOfWakil,
            R_total: data.koalldolar,
            R_totalID: data.koalldinar,
            R_dashkan: data.dashkandolar,
            R_dashkanID: data.dashkandinar,
            R_dataTable: data.saleTable,
            R_joripara: data.joripara,
            R_frosth: data.froshtn,
            R_wargr: data.whossale,
            R_paraDanID: data.paraydrawdinar,
            R_paraDan$: data.paraydrawdolar,
            R_kartonakan: data.koykartonkan,
            R_kreysayara: data.kreysayra,
            R_kreykrekar: data.kreykrekar,
            R_koywaznkarton: data.waznakan,
            R_paraywasl:
              data.joripara === "دۆلار"
                ? data.qarzinwedolar
                : data.qarzinwedinar,
            R_del_dis: true,
            R_up_froshtn: data.froshtn,
            R_date: data.date,
            R_togler: false,
            R_WakilQard_isChecked: false,
            R_isChecked: false,
            R_joridolar: data.joridolar,
            R_kateQarz: data.dateQazrz !== "" ? data.dateQazrz : "",
            R_rasidWakildinarNew:
              Number(data.qarzikonwakildinar) + Number(data.qarzinwedinar),
            R_rasidWakildolarNew:
              Number(data.qarzikonwakildolar) + Number(data.qarzinwedolar),
            R_rasidWakildinar: Number(data.qarzikonwakildinar),
            R_rasidWakildolar: Number(data.qarzikonwakildolar),
            R_note: data.note
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };
  R_pageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.R_page - 1;
      const res = await instance.get(`/api/v1/salepage?page=${page}&limit=1`);
      const data = res.data.sales;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            R_page: res.data.page,
            R_wasl: data,
            R_search_wasl: true,
            R_zh_wasl: data.numberofwasl,
            R_nawe_barez: data.wakil,
            R_mob: data.phone,
            R_zh_barez: data.numberOfWakil,
            R_total: data.koalldolar,
            R_totalID: data.koalldinar,
            R_dashkan: data.dashkandolar,
            R_dashkanID: data.dashkandinar,
            R_dataTable: data.saleTable,
            R_joripara: data.joripara,
            R_frosth: data.froshtn,
            R_wargr: data.whossale,
            R_paraDanID: data.paraydrawdinar,
            R_paraDan$: data.paraydrawdolar,
            R_kartonakan: data.koykartonkan,
            R_kreysayara: data.kreysayra,
            R_kreykrekar: data.kreykrekar,
            R_koywaznkarton: data.waznakan,
            R_paraywasl:
              data.joripara === "دۆلار"
                ? data.qarzinwedolar
                : data.qarzinwedinar,
            R_del_dis: true,
            R_up_froshtn: data.froshtn,
            R_date: data.date,
            R_togler: false,
            R_WakilQard_isChecked: false,
            R_isChecked: false,
            R_joridolar: data.joridolar,
            R_kateQarz: data.dateQazrz !== "" ? data.dateQazrz : "",
            R_rasidWakildinarNew: data.qarzikonwakildinar + data.qarzinwedinar,
            R_rasidWakildolarNew: data.qarzikonwakildolar + data.qarzinwedolar,
            R_rasidWakildinar: data.qarzikonwakildinar,
            R_rasidWakildolar: data.qarzikonwakildolar,
            R_note: data.note
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };
  // {*****************************}
  ////// koygshty
  R_getkartonakan = () => {
    let subTotal = 0;
    this.state.R_dataTable.map((item) => (subTotal += Number(item.carton)));
    const cartTotals = Number(subTotal.toFixed(4));
    this.setState(() => {
      return {
        R_kartonakan: cartTotals,
      };
    });
  };
  R_koyqazanjywasll = () => {
    let subTotal = 0;
    let arr = [];
    this.state.R_dataTable.map((el) => (arr = [...arr, el.qazanj]));
    const sum = arr.reduce((accumulator, current) => {
      return accumulator + current;
    }, subTotal);

    this.setState(
      {
        R_qazanjywasl: Number(sum).toFixed(2),
      },
      () => {}
    );
  };
  R_waznkartonakan = () => {
    let subTotal = 0;
    this.state.R_dataTable.map(
      (item) => (subTotal += Number(item.wazn) * Number(item.carton))
    );
    const wazns = Number(subTotal.toFixed(2));

    this.setState(() => {
      return {
        R_koywaznkarton: wazns,
      };
    });
  };
  R_getALLkoygshty = () => {
    if (this.state.R_kreykrekar > 0 || this.state.R_kreysayara > 0) {
      let subTotal = 0;
      let koykre =
        Number(this.state.R_kreysayara) + Number(this.state.R_kreykrekar);
      this.state.R_dataTable.map((item) => (subTotal += item.kodolar));
      const cartTotals = Number(subTotal.toFixed(2));
      this.setState(() => {
        return {
          R_total: cartTotals + koykre,
        };
      });
    } else {
      let subTotal = 0;
      this.state.R_dataTable.map((item) => (subTotal += item.kodolar));
      const cartTotals = Number(subTotal.toFixed(2));
      this.setState(() => {
        return {
          R_total: cartTotals,
        };
      });
    }
  };

  R_getALLkoygshtyID = () => {
    if (this.state.R_kreykrekar > 0 || this.state.R_kreysayara > 0) {
      let subTotal = 0;
      let koykre =
        Number(this.state.R_kreysayara) + Number(this.state.R_kreykrekar);
      this.state.R_dataTable.map((item) => (subTotal += Number(item.kodinar)));
      const cartTotals = Number(subTotal.toFixed(2));
      this.setState(() => {
        return {
          R_totalID: cartTotals + koykre,
        };
      });
    } else {
      let subTotal = 0;
      this.state.R_dataTable.map((item) => (subTotal += Number(item.kodinar)));
      const cartTotals = Number(subTotal.toFixed(2));
      this.setState(() => {
        return {
          R_totalID: cartTotals,
        };
      });
    }
  };

  R_kamkrdnawayNrx$ = () => {
    const dashkan = this.state.R_total - this.state.R_dashkan;

    return dashkan.toFixed(2);
  };

  R_kamkrdnawayNrxID = () => {
    const dashkanID = this.state.R_totalID - this.state.R_dashkanID;
    return dashkanID.toFixed(2);
  };

  R_waslKrdn = () => {
    if (this.state.R_dashkan === 0 || this.state.R_dashkan === "") {
      const wasl =
        this.state.R_total -
        parseFloat(this.state.R_paraDan$.toString().replace(/,/g, ""));
      return wasl.toFixed(2);
    } else if (this.state.R_dashkan !== 0 && this.state.R_dashkan !== "") {
      const wasl =
        this.R_kamkrdnawayNrx$() -
        parseFloat(this.state.R_paraDan$.toString().replace(/,/g, ""));
      return wasl.toFixed(2);
    }
  };

  R_waslKrdnID = () => {
    if (this.state.R_dashkanID === 0 || this.state.R_dashkanID === "") {
      const wasl =
        this.state.R_totalID -
        parseFloat(this.state.R_paraDanID.toString().replace(/,/g, ""));
      return wasl.toFixed(2);
    } else if (this.state.R_dashkanID !== 0 && this.state.R_dashkanID !== "") {
      const wasl =
        this.R_kamkrdnawayNrxID() -
        parseFloat(this.state.R_paraDanID.toString().replace(/,/g, ""));
      return wasl.toFixed(2);
    }
  };

  // {*****************************}
  // Wakel Context Functions
  setWakilEmpty = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      W_mob_Wakel: 0,
      W_naw: "",
      W_nawneshan: "",
      W_raseddolar: 0,
      W_raseddenar: 0,
      W_code: 0,
    });
    this.W_ClearError();
  };
  W_tofirstInput = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 3].focus();
    this.onSaveWakel(e);
  };
  onSaveWakel = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    try {
      if (
        this.state.W_code === 0 ||
        this.state.W_mob_Wakel === 0 ||
        this.state.W_naw === ""
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      }
      let data;
      data = {
        code: this.state.W_code,
        Name: this.state.W_naw,
        phone: this.state.W_mob_Wakel,
        rejaDolar: 0,
        rejaDinar: 0,
        adress: this.state.W_nawneshan,
      };
      const res = await instance.post("/api/v1/newWakil", data, config);

      this.setState({
        W_dataWakel: [...this.state.W_dataWakel, res.data.newWakil],
      });
      this.W_ClearError();
      this.setWakilEmpty(e);
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };
  ///// Wakil Clear Error
  W_ClearError = () => {
    this.setState({ W_error: null });
  };
  // delete wakel
  clickOnDeleteWakel = async (id) => {
    this.setState({
      W_dataWakel: this.state.W_dataWakel.filter((r) => r._id !== id),
    });
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    await instance.delete(`/api/v1/deleteWakil/${id}`, config);
  };
  ////// wakil tabel data
  getWakiltableData = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/getWakilakan`, config);

    this.setState({
      W_dataWakel: res.data.wakilakan,
    });
  };
  /////// rasid dolar
  getRasidDolar = async (NOW) => {
    let data;
    if (this.state.R_paraDan$ !== "") {
      data = {
        rejaDolar:
          Number(this.state.R_rasidWakildolar) + Number(this.R_waslKrdn()),
      };
    } else if (this.state.R_paraDan$ === "") {
      data = {
        rejaDolar:
          Number(this.state.R_rasidWakildolar) +
          Number(this.R_kamkrdnawayNrx$()),
      };
    }

    this.updateRasid(NOW, data);
  };
  ////
  getRasiddinar = async (NOW) => {
    let data;
    if (this.state.R_paraDanID !== "") {
      data = {
        rejaDinar:
          Number(this.state.R_rasidWakildinar) + Number(this.R_waslKrdnID()),
      };
    } else if (this.state.R_paraDanID === "") {
      data = {
        rejaDinar:
          Number(this.state.R_rasidWakildinar) +
          Number(this.R_kamkrdnawayNrxID()),
      };
    }
    this.updateRasid(NOW, data);
  };

  updateRasid = async (NOW, data) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (this.state.R_joripara === "دۆلار") {
        const update = await instance.put(
          `/api/v1/rejadolar?code=${NOW}`,
          data,
          config
        );

        this.setState({
          W_raseddolar: update.data.dolar.rejaDolar,
        });
      } else if (this.state.R_joripara === "دینار") {
        const update = await instance.put(
          `/api/v1/rejadinar?code=${NOW}`,
          data,
          config
        );

        this.setState({
          W_raseddenar: update.data.dinar.rejaDinar,
        });
      }
    } catch (error) {
      if (this.state.R_frosth === "فرۆشتنی معمیل") {
        alert("کێشە هەیە لەژمارەی ئەم معمیلە");
      }
    }
  };
  ////// update
  updtae_Wakil = async (e, wakil) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    let data;
    data = {
      code: this.state.W_code,
      Name: this.state.W_naw,
      phone: this.state.W_mob_Wakel,
      adress: this.state.W_nawneshan,
    };
    const res = await instance.put(
      `/api/v1/updateWakil/${wakil._id}`,
      data,
      config
    );

    this.setState({
      W_dataWakel: this.state.W_dataWakel.map((wakil) =>
        wakil._id === res.data.update._id ? res.data.update : wakil
      ),
    });
    this.clear_edit(e);
  };
  //// get wakilakna
  W_getwakelakan = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/SearchNameWakil?name=${e.target.value}`,
      config
    );

    this.setState({
      W_wakelakan: res.data.wakils,
    });
  };
  //// show rasid
  W_showrasid = async (e, userid) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    if (this.state.W_show === false) {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else {
        this.setState({ W_show: !this.state.W_show });
      }
    } else {
      this.setState({ W_show: !this.state.W_show });
    }
  };
  getWakilDataBySearchWakil = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    this.toanotherInput(e);
    const res = await instance.get(
      `/api/v1/searchwakil?code=${Number(this.state.W_code)}&&Name=${
        this.state.W_naw
      }&&phone=${Number(this.state.W_mob_Wakel)}`,
      config
    );

    const data = res.data.wakils;
    if (data === null) {
    } else {
      alert("ئەم داخیل کردنە دووبارەیە");
    }
  };
  // end of Wakel Context Functions

  // {*****************************}
  // Context && function of Add User
  onSaveAddUser = async (e) => {
    // if(this.state.A_bakarhenar==='' || this.state.A_zh_mobile===0 ){
    //     return false
    // }
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let data;
      data = {
        name: this.state.A_bakarhenar,
        password: this.state.A_password,
        role: this.state.A_role,
        phone: this.state.A_zh_mobile,
      };
      const res = await instance.post("/api/v1/createuser", data, config);

      this.setState({
        A_addUserData: [...this.state.A_addUserData, res.data.newUser],
      });
      this.setState({
        A_bakarhenar: "",
        A_password: "",
        A_role: "",
      });
    } catch (error) {
      this.setState({
        A_error: { msg: "تکایە خانەکان پڕبکەوە" },
      });
    }
  };
  ////// User tabel data
  getUsertableData = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/getusers`, config);

    this.setState({
      A_addUserData: res.data.users,
    });
  };
  clickOnDeleteAddUser = async (id) => {
    this.setState({
      A_addUserData: this.state.A_addUserData.filter((r) => r._id !== id),
    });
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    await instance.delete(`/api/v1/deleteUserById/${id}`, config);
  };
  updtae_User = async (e, user) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    let data;
    data = {
      name: this.state.A_bakarhenar,
      role: this.state.A_role,
      phone: this.state.A_zh_mobile,
    };
    const res = await instance.put(
      `/api/v1/updateUserById/${user._id}`,
      data,
      config
    );

    this.setState({
      A_addUserData: this.state.A_addUserData.map((user) =>
        user._id === res.data.update._id ? res.data.update : user
      ),
    });

    data = {
      passwordCurrent: this.state.A_password,
      password: this.state.A_newPassword,
    };
    await instance.patch(
      `/api/v1/updateUserPassword/${user._id}`,
      data,
      config
    );

    this.clear_edit(e);
  };

  // end of Context && function of Add User

  // {*****************************}
  ////update password
  onUpdatePassword = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (this.state.U_newPassword !== this.state.U_newPassword2) {
        alert("وشەی نهێنی دوەم هەڵەیە");
      } else {
        const data = {
          passwordCurrent: this.state.U_currentpasword,
          password: this.state.U_newPassword,
        };
        await instance.patch(`/api/v1/updateUserPassword`, data, config);
        this.setState({
          U_save: true,
          U_newPassword: "",
          U_newPassword2: "",
          U_currentpasword: "",
        });
      }
    } catch (error) {
      alert("وشەی نهێنی کۆن هەڵەیە");
    }
  };

  U_ClearError = () => {
    this.setState({ U_error: null });
  };

  ////// show password function

  showpassword = () => {
    this.setState({ U_show: !this.state.U_show });
  };

  //// setUsave
  labrdnisave = () => {
    this.setState({ U_save: false });
  };
  //////////// end

  ///////take money functions

  ////open para to update
  ////open para to update
  P_getPrint = () => {
    window.setTimeout("window.print()", 2000);
  };

  P_openUpdate = async (NOW, e, id) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    this.setState(
      {
        P_del_dis: null,
        P_open: true,
      },
      async () => {
        ////// refresh krdnawa
        let datarefresh;
        datarefresh = {
          _id: id,
          zhmara: this.state.P_zhmara,
          wakilcode: this.state.P_zh_barez,
          namewakil: this.state.P_nawe_barez,
          paraywargirawdolar:
            this.state.P_joripara === "دۆلار"
              ? parseFloat(this.state.P_brePara.toString().replace(/,/g, ""))
              : 0,
          paraywargirawdinar:
            this.state.P_joripara === "دینار"
              ? parseFloat(this.state.P_brePara.toString().replace(/,/g, ""))
              : 0,
          kmkrdnawa: this.state.P_dashkan,
          paradwaykmkrdndolar:
            this.state.P_joripara === "دۆلار"
              ? this.state.P_dashkan > 0
                ? this.P_kamkrdnawayNrx()
                : this.bripara()
              : 0,
          paradwaykmkrdndinar:
            this.state.P_joripara === "دینار"
              ? this.state.P_dashkan > 0
                ? this.P_kamkrdnawayNrx()
                : this.bripara()
              : 0,
          nawiwargr: this.state.P_wargr,
          joripara: this.state.P_joripara,
          note: this.state.P_note,
          rasiddolar: this.state.P_qarzeKonDolar,
          rasiddinar: this.state.P_qarzeKonID,
          date: this.state.P_barwar,
        };
        localStorage.setItem("paradata", JSON.stringify(datarefresh));

        ///// chaktrdni rasid
        let draw = this.state.P_joripara === "دۆلار" ? "dolar" : "dinar";
        const update = await instance.put(
          `/api/v1/updaitwakilrasid?code=${Number(NOW)}`,
          config
        );

        this.setState({
          W_raseddolar:
            this.state.P_joripara === "دۆلار"
              ? parseFloat(this.state.P_brePara.toString().replace(/,/g, "")) +
                Number(update.data.wakil.rejaDolar)
              : Number(update.data.wakil.rejaDolar),
          W_raseddenar:
            this.state.P_joripara === "دینار"
              ? parseFloat(this.state.P_brePara.toString().replace(/,/g, "")) +
                Number(update.data.wakil.rejaDinar)
              : Number(update.data.wakil.rejaDinar),
          P_qarzeKonDolar:
            this.state.P_joripara === "دۆلار"
              ? parseFloat(this.state.P_brePara.toString().replace(/,/g, "")) +
                Number(update.data.wakil.rejaDolar)
              : Number(update.data.wakil.rejaDolar),
          P_qarzeKonID:
            this.state.P_joripara === "دینار"
              ? parseFloat(this.state.P_brePara.toString().replace(/,/g, "")) +
                Number(update.data.wakil.rejaDinar)
              : Number(update.data.wakil.rejaDinar),
        });

        /////kmkrdnaway paray bardast
        let bardastdata;
        const respb = await instance.get(`/api/v1/paraybardasakan`, config);
        if (this.state.P_joripara === "دۆلار") {
          bardastdata = {
            paraybardastdolar:
              Number(respb.data.paraybardastakan.paraybardastdolar) -
              parseFloat(this.state.P_brePara.toString().replace(/,/g, "")),
          };
        } else if (this.state.P_joripara === "دینار") {
          bardastdata = {
            paraybardastdinar:
              Number(respb.data.paraybardastakan.paraybardastdinar) -
              parseFloat(this.state.P_brePara.toString().replace(/,/g, "")),
          };
        }
        await instance.put(
          `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
          bardastdata,
          config
        );
      }
    );
  };
  //// open update and refresh

  P_onopenUpdateAndRefresh = (data) => {
    this.setState({
      P_search_wasl: true,
      P_zhmara: data.zhmara,
      P_nawe_barez: data.namewakil,
      P_barwar: data.date,
      P_zh_barez: data.wakilcode,
      P_paraPashDashkan:
        data.joripara === "دینار"
          ? data.paradwaykmkrdndinar
          : data.paradwaykmkrdndolar,
      P_dashkan: data.kmkrdnawa,
      P_brePara:
        data.joripara === "دینار"
          ? data.paraywargirawdinar
          : data.paraywargirawdolar,
      P_joripara: data.joripara,
      P_wargr: data.nawiwargr,
      P_note: data.note,
      P_qarzeKonDolar: data.rasiddolar,
      P_qarzeKonID: data.rasiddinar,
      P_del_dis: null,
      P_paraywasl: data.paradwaykmkrdn,
      P_wasl: data,
      P_togler: false,
      P_open: true,
    });
  };
  /// barwar toogle para watrgrtn

  P_inputtogler = (e) => {
    e.preventDefault();
    this.setState({
      P_togler: !this.state.P_togler,
      P_barwar: "",
    });
  };
  ///// loading para
  P_hideLoader = () => {
    this.setState({ P_loading: false });
  };

  P_showLoader = () => {
    this.setState({ P_loading: true });
  };
  P_Serach_ByName = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/SearchNameWakil?name=${this.state.P_nawe_barez}`,
      config
    );
    if (this.state.P_nawe_barez === "" || this.state.P_nawe_barez === null) {
      this.setState({
        P_suggestion: [],
      });
    }
    this.setState({
      P_suggestion: res.data.wakils,
    });
  };

  P_emptysuggections = () => {
    this.setState({
      P_suggestion: [],
    });
  };
  P_Search_Rent_Handle = (e) => {
    this.setState(
      {
        P_nawe_barez: e.target.value,
      },
      () => {
        if (
          !this.state.P_nawe_barez ||
          this.state.P_nawe_barez === "" ||
          this.state.P_nawe_barez === null
        ) {
          this.setState({ P_suggestion: [] });
        } else {
          this.P_Serach_ByName();
        }
      }
    );
  };
  P_suggestionSelected = async (id) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/getWakil/${id}`, config);
    const data = res.data.wakil;
    if (
      this.state.P_nawe_barez.toString().length === 0 ||
      this.state.P_nawe_barez === null
    ) {
      this.setState({
        P_suggestion: [],
      });
    }
    if (res.data.status) {
      this.setState(() => {
        return {
          P_search: true,
          P_zh_barez: data.code,
          P_nawe_barez: data.Name,
          P_qarzeKonDolar: Number(data.rejaDolar).toFixed(2),
          P_qarzeKonID: Number(data.rejaDinar).toFixed(2),
          P_suggestion: [],
        };
      });
    }
  };
  ////save wasl para
  onSaveMoney = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (
        this.state.P_nawe_barez === "" ||
        this.state.P_zh_barez === 0 ||
        this.state.P_wargr === null ||
        this.state.P_brePara === 0 ||
        this.state.P_joripara === null
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          wakilcode: this.state.P_zh_barez,
          namewakil: this.state.P_nawe_barez,
          paraywargirawdolar:
            this.state.P_joripara === "دۆلار"
              ? parseFloat(this.state.P_brePara.toString().replace(/,/g, ""))
              : 0,
          paraywargirawdinar:
            this.state.P_joripara === "دینار"
              ? parseFloat(this.state.P_brePara.toString().replace(/,/g, ""))
              : 0,
          kmkrdnawa: this.state.P_dashkan,
          paradwaykmkrdndolar:
            this.state.P_joripara === "دۆلار"
              ? this.state.P_dashkan > 0
                ? this.P_kamkrdnawayNrx()
                : this.bripara()
              : 0,
          paradwaykmkrdndinar:
            this.state.P_joripara === "دینار"
              ? this.state.P_dashkan > 0
                ? this.P_kamkrdnawayNrx()
                : this.bripara()
              : 0,
          nawiwargr: this.state.P_wargr,
          joripara: this.state.P_joripara,
          note: this.state.P_note,
          rasiddolar: this.state.P_qarzeKonDolar,
          rasiddinar: this.state.P_qarzeKonID,
          date: this.state.P_barwar === "" ? Date.now : this.state.P_barwar,
          jwlla: "پارەوەرگرتن",
        };
        const res = await instance.post("/api/v1/newPara", data, config);
        this.P_showLoader();

        if (res) {
          this.P_hideLoader();
        }
        let draw = this.state.P_joripara === "دۆلار" ? "dolar" : "dinar";
        const update = await instance.put(
          `/api/v1/updateparawakil?code=${
            this.state.P_zh_barez
          }&joridraw=${draw}&bripara=${
            this.state.P_dashkan > 0 ? this.P_kamkrdnawayNrx() : this.bripara()
          }`,
          config
        );
        this.setState(
          {
            P_save: true,
            P_zhmara: res.data.wargrtn.zhmara,
            P_barwar: res.data.wargrtn.date,
            P_del_dis: true,
            P_paraywasl: res.data.wargrtn.paradwaykmkrdn,
            P_wasl: res.data.wargrtn,
            P_togler: false,
            W_raseddolar: update.data.rasid.rejaDolar,
            W_raseddenar: update.data.rasid.rejaDinar,
          },
          async () => {
            let bardastdata;
            const respb = await instance.get(`/api/v1/paraybardasakan`, config);
            if (
              this.state.P_joripara === "دۆلار" &&
              this.state.P_brePara !== ""
            ) {
              bardastdata = {
                paraybardastdolar:
                  Number(respb.data.paraybardastakan.paraybardastdolar) +
                  parseFloat(this.state.P_brePara.toString().replace(/,/g, "")),
              };
            } else if (
              this.state.P_joripara === "دینار" &&
              this.state.P_brePara !== ""
            ) {
              bardastdata = {
                paraybardastdinar:
                  Number(respb.data.paraybardastakan.paraybardastdinar) +
                  parseFloat(this.state.P_brePara.toString().replace(/,/g, "")),
              };
            }
            await instance.put(
              `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
              bardastdata,
              config
            );
          }
        );
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };
  ///chakrdni wasli paradan
  chakrdnirasidParadan = async (NOW) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    let anjam;
    let data;
    if (this.state.P_joripara === "دۆلار") {
      if (this.state.P_dashkan > 0) {
        anjam = this.P_kamkrdnawayNrx();
      } else {
        anjam = this.bripara();
      }
      data = { rejaDolar: anjam };

      const update = await instance.put(
        `/api/v1/rejadolar?code=${NOW}`,
        data,
        config
      );

      this.setState({
        W_raseddolar: update.data.dolar.rejaDolar,
      });
    } else if (this.state.P_joripara === "دینار") {
      if (this.state.P_dashkan > 0) {
        anjam = this.P_kamkrdnawayNrx();
      } else {
        anjam = this.bripara();
      }
      data = { rejaDinar: anjam };

      const update = await instance.put(
        `/api/v1/rejadinar?code=${NOW}`,
        data,
        config
      );

      this.setState({
        W_raseddenar: update.data.dinar.rejaDinar,
      });
    }
  };
  ////// update Mony
  updtate_para = async (e, para, NOW) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    try {
      if (
        this.state.P_brePara === "" ||
        this.state.P_brePara === 0 ||
        this.state.P_nawe_barez === "" ||
        this.state.P_barwar === ""
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          wakilcode: this.state.P_zh_barez,
          namewakil: this.state.P_nawe_barez,
          paraywargirawdolar:
            this.state.P_joripara === "دۆلار"
              ? parseFloat(this.state.P_brePara.toString().replace(/,/g, ""))
              : 0,
          paraywargirawdinar:
            this.state.P_joripara === "دینار"
              ? parseFloat(this.state.P_brePara.toString().replace(/,/g, ""))
              : 0,
          kmkrdnawa: this.state.P_dashkan,
          paradwaykmkrdndolar:
            this.state.P_joripara === "دۆلار"
              ? this.state.P_dashkan > 0
                ? this.P_kamkrdnawayNrx()
                : this.bripara()
              : 0,
          paradwaykmkrdndinar:
            this.state.P_joripara === "دینار"
              ? this.state.P_dashkan > 0
                ? this.P_kamkrdnawayNrx()
                : this.bripara()
              : 0,
          nawiwargr: this.state.P_wargr,
          joripara: this.state.P_joripara,
          note: this.state.P_note,
          rasiddolar: this.state.P_qarzeKonDolar,
          rasiddinar: this.state.P_qarzeKonID,
          date: this.state.P_barwar,
        };
        await instance.put(`/api/v1/updatepara/${para._id}`, data, config);

        localStorage.removeItem("paradata");

        this.setState(
          {
            P_open: null,
            P_del_dis: true,
            P_togler: false,
          },
          async () => {
            let draw = this.state.P_joripara === "دۆلار" ? "dolar" : "dinar";
            const update = await instance.put(
              `/api/v1/updateparawakil?code=${
                this.state.P_zh_barez
              }&joridraw=${draw}&bripara=${
                this.state.P_dashkan > 0
                  ? this.P_kamkrdnawayNrx()
                  : this.bripara()
              }`,
              config
            );
            this.setState({
              W_raseddolar: update.data.rasid.rejaDolar,
              W_raseddenar: update.data.rasid.rejaDinar,
            });

            let bardastdata;
            const respb = await instance.get(`/api/v1/paraybardasakan`, config);
            if (this.state.P_joripara === "دۆلار") {
              bardastdata = {
                paraybardastdolar:
                  Number(respb.data.paraybardastakan.paraybardastdolar) +
                  parseFloat(this.state.P_brePara.toString().replace(/,/g, "")),
              };
            } else if (this.state.P_joripara === "دینار") {
              bardastdata = {
                paraybardastdinar:
                  Number(respb.data.paraybardastakan.paraybardastdinar) +
                  parseFloat(this.state.P_brePara.toString().replace(/,/g, "")),
              };
            }
            await instance.put(
              `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
              bardastdata,
              config
            );
          }
        );
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };
  ////search name of wakil
  getWakilDataBySearchMoney = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchwakil?code=${this.state.P_zh_barez}&&Name=${this.state.P_nawe_barez}`,
      config
    );

    const data = res.data.wakils;
    if (data === null) {
      alert("ببورە ئەم بەڕێزە بونی نیە");
    } else {
      this.setState(() => {
        return {
          P_zh_barez: data.code,
          P_nawe_barez: data.Name,
          P_qarzeKonDolar: data.rejaDolar.toFixed(2),
          P_qarzeKonID: data.rejaDinar.toFixed(2),
        };
      });
    }
  };

  getParaDataBySearchMoney = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/search?zhmara=${this.state.P_zhmara}`,
      config
    );

    const data = res.data.parakan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ P_zhmara: 0 });
    } else {
      this.setState(() => {
        return {
          P_search_wasl: true,
          P_zhmara: data.zhmara,
          P_nawe_barez: data.namewakil,
          P_barwar: data.date,
          P_zh_barez: data.wakilcode,
          P_paraPashDashkan:
            data.joripara === "دینار"
              ? data.paradwaykmkrdndinar
              : data.paradwaykmkrdndolar,
          P_dashkan: data.kmkrdnawa,
          P_brePara:
            data.joripara === "دینار"
              ? data.paraywargirawdinar
              : data.paraywargirawdolar,
          P_joripara: data.joripara,
          P_wargr: data.nawiwargr,
          P_note: data.note,
          P_qarzeKonDolar: data.rasiddolar,
          P_qarzeKonID: data.rasiddinar,
          P_del_dis: true,
          P_paraywasl: data.paradwaykmkrdn,
          P_wasl: data,
          P_togler: false,
        };
      });
    }
  };
  P_kamkrdnawayNrx = () => {
    if (this.state.P_joripara === "دۆلار") {
      const dashkan = this.bripara() - this.state.P_dashkan;
      return dashkan.toFixed(2);
    } else if (this.state.P_joripara === "دینار") {
      const dashkan = this.bripara() - this.state.P_dashkan;
      return dashkan.toFixed(2);
    }
  };
  bripara = () => {
    if (this.state.P_joripara === "دۆلار") {
      const para =
        this.state.P_qarzeKonDolar -
        parseFloat(this.state.P_brePara.toString().replace(/,/g, ""));
      return para.toFixed(2);
    } else if (this.state.P_joripara === "دینار") {
      const para =
        this.state.P_qarzeKonID -
        parseFloat(this.state.P_brePara.toString().replace(/,/g, ""));
      return para.toFixed(2);
    }
  };

  getParakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/parakan`, config);

    this.setState({
      P_zhmara: res.data.parakan + 1,
      P_page: res.data.parakan
    });
  };
  setSearchMonyWakilEmpty = (e) => {
    e.preventDefault();
    this.setState({
      P_zh_barez: 0,
      P_nawe_barez: "",
      P_qarzeKonDolar: 0,
      P_qarzeKonID: 0,
    });
  };
  createNewMony = () => {
    this.setState(
      {
        P_zh_barez: 0,
        P_nawe_barez: "",
        P_qarzeKonDolar: 0,
        P_qarzeKonID: 0,
        P_brePara: 0,
        P_dashkan: 0,
        P_paraPashDashkan: 0,
        P_error: null,
        P_note: "",
        P_search_wasl: null,
        P_save: null,
        P_joripara: null,
        P_wargr: null,
        P_isChecked: false,
        P_open: null,
        P_del_dis: null,
        P_page: this.state.P_zhmara,
        P_barwar: "",
      },
      () => {
        this.getParakan();
      }
    );
    this.P_ClearError();
  };

  P_getIsChecked = (e) => {
    let checked = e.target.checked;
    if (checked) {
      this.setState({ P_isChecked: true });
    } else {
      this.setState({ P_isChecked: false });
    }
  };
  P_ClearError = () => {
    this.setState({ P_error: null });
  };

  pageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.P_page + 1;
      const res = await instance.get(
        `/api/v1/page?page=${page}&limit=1`,
        config
      );

      const data = res.data.paras;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            P_search_wasl: true,
            P_zhmara: data.zhmara,
            P_page: res.data.page,
            P_nawe_barez: data.namewakil,
            P_barwar: data.date,
            P_zh_barez: data.wakilcode,
            P_paraPashDashkan:
              data.joripara === "دینار"
                ? data.paradwaykmkrdndinar
                : data.paradwaykmkrdndolar,
            P_dashkan: data.kmkrdnawa,
            P_brePara:
              data.joripara === "دینار"
                ? data.paraywargirawdinar
                : data.paraywargirawdolar,
            P_joripara: data.joripara,
            P_wargr: data.nawiwargr,
            P_note: data.note,
            P_qarzeKonDolar: data.rasiddolar,
            P_qarzeKonID: data.rasiddinar,
            P_del_dis: true,
            P_paraywasl: data.paradwaykmkrdn,
            P_wasl: data,
            P_save: null,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };
  pageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page;
      this.state.P_page <= 1 ? (page = 1) : (page = this.state.P_page - 1);
      const res = await instance.get(
        `/api/v1/page?page=${page}&limit=1`,
        config
      );

      const data = res.data.paras;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            P_search_wasl: true,
            P_zhmara: data.zhmara,
            P_page: res.data.page,
            P_nawe_barez: data.namewakil,
            P_barwar: data.date,
            P_zh_barez: data.wakilcode,
            P_paraPashDashkan:
              data.joripara === "دینار"
                ? data.paradwaykmkrdndinar
                : data.paradwaykmkrdndolar,
            P_dashkan: data.kmkrdnawa,
            P_brePara:
              data.joripara === "دینار"
                ? data.paraywargirawdinar
                : data.paraywargirawdolar,
            P_joripara: data.joripara,
            P_wargr: data.nawiwargr,
            P_note: data.note,
            P_qarzeKonDolar: data.rasiddolar,
            P_qarzeKonID: data.rasiddinar,
            P_del_dis: true,
            P_paraywasl: data.paradwaykmkrdn,
            P_wasl: data,
            P_save: null,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };
  /////end

  // garanawa bo koga function
  G_getWaslDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchgaranawa?numberofwasl=${Number(this.state.G_zh_wasl)}`,
      config
    );

    const data = res.data.pswla;
    if (data === null) {
      alert("ببورە ئەم پسولەیە بونی نیە");
    } else {
      this.setState(() => {
        return {
          G_wasl: data,
          G_search_wasl: true,
          G_zh_wasl: data.numberofwasl,
          G_nawe_barez: data.wakil,
          G_mob: data.phone,
          G_zh_barez: data.numberOfWakil,
          G_total: data.koalldolar,
          G_totalID: data.koalldinar,
          G_dashkan: data.dashkandolar,
          G_dashkanID: data.dashkandinar,
          G_dataTable: data.saleTable,
          G_joripara: data.joripara,
          G_frosht: data.froshtn,
          G_wargr: data.whossale,
          G_paraDanID: data.paraydrawdinar,
          G_paraDan$: data.paraydrawdolar,
          G_kartonakan: data.koykartonkan,
          G_kreysayara: data.kreysayra,
          G_kreykrekar: data.kreykrekar,
          G_koywaznkarton: data.waznakan,
          G_paraywasl:
            data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
          G_del_dis: true,
          G_up_froshtn: data.froshtn,
          G_togler: false,
          G_date: data.date,
          G_joridolar: data.joridolar,
          G_rasidWakildinarNew:
            Number(data.qarzikonwakildinar) - Number(data.paraymawadinar),
          G_rasidWakildolarNew:
            Number(data.qarzikonwakildolar) - Number(data.paraymawadolar),
          G_rasidWakildinar: Number(data.qarzikonwakildinar),
          G_rasidWakildolar: Number(data.qarzikonwakildolar),
        };
      });
    }
  };
  ///// loading product
  /// on refresh and open
  G_onopenandrefresh = (data) => {
    this.G_koyqazanjywasll();
    this.setState(
      {
        G_wasl: data,
        G_search_wasl: true,
        G_zh_wasl: data.numberofwasl,
        G_nawe_barez: data.wakil,
        G_mob: data.phone,
        G_zh_barez: data.numberOfWakil,
        G_total: data.koalldolar,
        G_totalID: data.koalldinar,
        G_dashkan: data.dashkandolar,
        G_dashkanID: data.dashkandinar,
        G_dataTable: data.saleTable,
        G_joripara: data.joripara,
        G_frosht: data.froshtn,
        G_wargr: data.whossale,
        G_paraDanID: data.paraydrawdinar,
        G_paraDan$: data.paraydrawdolar,
        G_kartonakan: data.koykartonkan,
        G_kreysayara: data.kreysayra,
        G_kreykrekar: data.kreykrekar,
        G_koywaznkarton: data.waznakan,
        G_paraywasl:
          data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
        G_del_dis: null,
        G_up_froshtn: data.froshtn,
        G_date: data.date,
        G_togler: false,
        G_open: true,
        G_joridolar: data.joridolar,
      },
      () => {
        if (this.state.G_joripara === "دۆلار") {
          this.G_getALLkoygshty();
        } else if (this.state.G_joripara === "دینار") {
          this.G_getALLkoygshtyID();
        }
      }
    );
  };
  G_saveLoaclStorageWasl = (wasl) => {
    let data = {
      _id: wasl._id,
      numberOfWakil:
        this.state.G_frosht === "فرۆشتنی معمیل"
          ? Number(this.state.G_zh_barez)
          : "",
      wakil: this.state.G_nawe_barez,
      phone: this.state.G_mob,
      whossale: this.state.G_wargr,
      joripara: this.state.G_joripara,
      froshtn: this.state.G_frosht,
      saleTable: this.state.G_dataTable,
      koalldinar: this.state.G_joripara === "دینار" ? this.state.G_totalID : 0,
      koalldolar: this.state.G_joripara === "دۆلار" ? this.state.G_total : 0,
      paraydrawdolar:
        this.state.G_joripara === "دۆلار" &&
        this.state.G_paraDan$ !== "" &&
        this.state.G_paraDan$ !== null
          ? parseFloat(this.state.G_paraDan$.toString().replace(/,/g, ""))
          : 0,
      paraydrawdinar:
        this.state.G_joripara === "دینار" &&
        this.state.G_paraDanID !== "" &&
        this.state.G_paraDanID !== null
          ? parseFloat(this.state.G_paraDanID.toString().replace(/,/g, ""))
          : 0,
      qarzinwedolar:
        this.state.G_joripara === "دۆلار"
          ? this.state.G_paraDan$ !== ""
            ? this.G_waslKrdn()
            : this.state.G_total
          : 0,
      qarzinwedinar:
        this.state.G_joripara === "دینار"
          ? this.state.G_paraDanID !== ""
            ? this.G_waslKrdnID()
            : this.state.G_totalID
          : 0,
      koykartonkan: this.state.G_kartonakan,
      waznakan: this.state.G_koywaznkarton,
      qaznjygarawa: this.state.G_qazanjywasl,
      date: this.state.G_date === "" ? Date.now : this.state.G_date,
      joridolar: this.state.G_joridolar,
      qarzikonwakildolar: this.state.G_rasidWakildolar,
      qarzikonwakildinar: this.state.G_rasidWakildinar,
    };
    localStorage.setItem("garanawa", JSON.stringify(data));
  };
  G_hideLoader = () => {
    this.setState({ G_loading: false });
  };

  G_showLoader = () => {
    this.setState({ G_loading: true });
  };
  G_onSaveRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (!this.state.G_dataTable || this.state.G_kartonakan === 0) {
        alert("تکایە کاڵا داخیل بکە ");
        const sawtError = new Audio(errorAduio);
        sawtError.play();
        return false;
      } else if (this.state.G_nawe_barez === "") {
        alert("تکایە ناوی بەڕێز پڕبکەوە");
        const sawtError = new Audio(errorAduio);
        sawtError.play();
      } else if (this.state.G_frosht === "" || this.state.G_wargr === "") {
        alert("تکایە خانەکان پڕبکەوە");
        const sawtError = new Audio(errorAduio);
        sawtError.play();
      } else {
        let data;
        data = {
          numberOfWakil:
            this.state.G_frosht === "فرۆشتنی معمیل"
              ? Number(this.state.G_zh_barez)
              : "",
          wakil: this.state.G_nawe_barez,
          phone: this.state.G_mob,
          whossale: this.state.G_wargr,
          joripara: this.state.G_joripara,
          froshtn: this.state.G_frosht,
          saleTable: this.state.G_dataTable,
          koalldinar:
            this.state.G_joripara === "دینار" ? this.state.G_totalID : 0,
          koalldolar:
            this.state.G_joripara === "دۆلار" ? this.state.G_total : 0,
          paraydrawdolar:
            this.state.G_joripara === "دۆلار" &&
            this.state.G_paraDan$ !== "" &&
            this.state.G_paraDan$ !== null
              ? parseFloat(this.state.G_paraDan$.replace(/,/g, ""))
              : 0,
          paraydrawdinar:
            this.state.G_joripara === "دینار" &&
            this.state.G_paraDanID !== "" &&
            this.state.G_paraDanID !== null
              ? parseFloat(this.state.G_paraDanID.replace(/,/g, ""))
              : 0,
          qarzinwedolar:
            this.state.G_joripara === "دۆلار"
              ? this.state.G_paraDan$ !== ""
                ? this.G_waslKrdn()
                : this.state.G_total
              : 0,
          qarzinwedinar:
            this.state.G_joripara === "دینار"
              ? this.state.G_paraDanID !== ""
                ? this.G_waslKrdnID()
                : this.state.G_totalID
              : 0,
          koykartonkan: this.state.G_kartonakan,
          waznakan: this.state.G_koywaznkarton,
          qaznjygarawa: this.state.G_qazanjywasl,
          date: this.state.G_date === "" ? Date.now : this.state.G_date,
          joridolar: this.state.G_joridolar,
          qarzikonwakildolar: this.state.G_rasidWakildolar,
          qarzikonwakildinar: this.state.G_rasidWakildinar,
        };

        const draw = this.state.G_joripara === "دینار" ? "dinar" : "dolar";
        const res = await instance.post(
          `/api/v1/newwaslgaranawa?opr=-&joridraw=${draw}`,
          data,
          config
        );
        this.G_showLoader();
        if (res) {
          this.G_hideLoader();
        }

        localStorage.removeItem("garanawa");
        localStorage.removeItem("gitem");
        this.setState(
          {
            G_togler: false,
            G_save: true,
            G_zh_wasl: res.data.garanawaproduct.numberofwasl,
            W_raseddenar: 0,
            W_raseddolar: 0,
            G_wasl: res.data.garanawaproduct,
            G_paraywasl:
              res.data.garanawaproduct.joripara === "دۆلار"
                ? res.data.garanawaproduct.qarzinwedolar
                : res.data.garanawaproduct.qarzinwedinar,
            G_del_dis: true,
            G_up_froshtn: res.data.garanawaproduct.froshtn,
            G_date: res.data.garanawaproduct.date,
            G_loading: false,
          },
          async () => {
            this.getWaslakan();
            this.G_hideLoader();
          }
        );
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  G_koyqazanjywasll = () => {
    let subTotal = 0;
    this.state.G_dataTable.map(
      (item) => (subTotal += Number(item.qazanjykalla))
    );
    const cartTotals = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        G_qazanjywasl: cartTotals,
      };
    });
  };

  G_setSaletSearchEmty = (e) => {
    e.preventDefault();
    this.setState({
      G_zhmara: "",
      G_naweSht: "",
      G_karton: 0,
      G_darzan: 0,
      G_search: null,
      G_rezha: 0,
      G_nrx: 0,
      G_suggestions: [],
    });
  };

  G_calculateDarzan = () => {
    const zhmarayKarton = this.state.G_rezha;
    const darzan = Number(this.state.G_darzan);
    const karton = darzan / zhmarayKarton;
    return Number(karton).toFixed(4);
  };

  G_DarzanChange = (e) => {
    this.setState(
      {
        darzan: e.target.value,
        G_darzan: e.target.value,
      },
      () => {
        this.setState({
          karton: this.calculateDarzan(),
          G_karton: this.G_calculateDarzan(),
          koygshty: this.getkoygahty(this.state.darzan, this.state.karton),
          G_nrx: this.G_nrxRent(this.state.G_darzan, this.state.G_karton),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        [e.target.name]: "0.",
      });
    }
    this.ClearError();
  };

  G_getkartonakan = () => {
    let subTotal = 0;
    this.state.G_dataTable.map((item) => (subTotal += Number(item.carton)));
    const cartTotals = Number(subTotal.toFixed(4));
    this.setState(() => {
      return {
        G_kartonakan: cartTotals,
      };
    });
  };

  G_waznkartonakan = () => {
    let subTotal = 0;
    this.state.G_dataTable.map(
      (item) => (subTotal += Number(item.wazn) * Number(item.carton))
    );
    const wazns = Number(subTotal.toFixed(2));

    this.setState(() => {
      return {
        G_koywaznkarton: wazns,
      };
    });
  };

  G_DeleteSail = async (id, table, index) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    this.setState(
      {
        G_dataTable: this.state.G_dataTable.filter((r, i) => i !== index),
      },
      async () => {
        if (this.state.G_dataTable.length === 0) {
          localStorage.removeItem(
            "gitem",
            JSON.stringify(this.state.G_dataTable)
          );
          this.setState({
            R_table: false,
          });
        }
        if (this.state.G_open === true || this.state.G_open !== null) {
          this.G_saveLoaclStorageWasl(this.state.G_wasl);
        }
        if (this.state.G_dataTable.length !== 0 && this.state.G_open !== true) {
          localStorage.setItem("gitem", JSON.stringify(this.state.G_dataTable));
        }
        if (this.state.G_joripara === "دۆلار") {
          this.G_getALLkoygshty();
        } else if (this.state.G_joripara === "دینار") {
          this.G_getALLkoygshtyID();
        }
        this.G_getkartonakan();
        this.G_waznkartonakan();
        this.G_koyqazanjywasll();
      }
    );
  };

  G_openUpdate = async (NOW, userid, e, id) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const { value: text } = await Swal.fire({
      input: "password",
      inputLabel: "Password",
      showLoaderOnConfirm: true,
      inputPlaceholder: "وشەی نهێنی داخیل بکە...",
      inputAttributes: {
        "aria-label": "وشەی نهێنی داخیل بکە",
      },
      showCancelButton: true,
    });
    const data = {
      password: text,
    };
    const pass = await instance.post(
      `api/v1/openGaranwa/${id}?id=${userid}`,
      data,
      config
    );
    if (pass.data.status === false) {
      alert("وشەی نهێنی هەڵەیە");
    } else if (pass.data.status === true) {
      this.G_koyqazanjywasll();
      this.setState(
        {
          G_del_dis: null,
          G_open: true,
          G_suggestions: [],
        },
        async () => {
          this.G_saveLoaclStorageWasl(this.state.G_wasl);
        }
      );
      this.G_koyqazanjywasll();
    }
  };

  G_updateSale = async (NOW, id, e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (this.state.G_nawe_barez === "") {
        alert("هەڵە هەیە لە خانەکان");
      } else {
        this.G_koyqazanjywasll();
        let hamwi = {
          numberOfWakil:
            this.state.G_frosht === "فرۆشتنی معمیل"
              ? Number(this.state.G_zh_barez)
              : "",
          wakil: this.state.G_nawe_barez,
          phone: this.state.G_mob,
          whossale: this.state.G_wargr,
          joripara: this.state.G_joripara,
          froshtn: this.state.G_frosht,
          saleTable: this.state.G_dataTable,
          koalldinar:
            this.state.G_joripara === "دینار" ? this.state.G_totalID : 0,
          koalldolar:
            this.state.G_joripara === "دۆلار" ? this.state.G_total : 0,
          paraydrawdolar:
            this.state.G_joripara === "دۆلار" &&
            this.state.G_paraDan$ !== "" &&
            this.state.G_paraDan$ !== null
              ? parseFloat(this.state.G_paraDan$.toString().replace(/,/g, ""))
              : 0,
          paraydrawdinar:
            this.state.G_joripara === "دینار" &&
            this.state.G_paraDanID !== "" &&
            this.state.G_paraDanID !== null
              ? parseFloat(this.state.G_paraDanID.toString().replace(/,/g, ""))
              : 0,
          qarzinwedolar:
            this.state.G_joripara === "دۆلار"
              ? this.state.G_paraDan$ !== ""
                ? this.G_waslKrdn()
                : this.state.G_total
              : 0,
          qarzinwedinar:
            this.state.G_joripara === "دینار"
              ? this.state.G_paraDanID !== ""
                ? this.G_waslKrdnID()
                : this.state.G_totalID
              : 0,
          koykartonkan: this.state.G_kartonakan,
          waznakan: this.state.G_koywaznkarton,
          date: this.state.G_date,
          qaznjygarawa: this.state.G_qazanjywasl,
          joridolar: this.state.G_joridolar,
          qarzikonwakildolar: this.state.G_rasidWakildolar,
          qarzikonwakildinar: this.state.G_rasidWakildinar,
        };
        const res = await instance.put(
          `/api/v1/updateallgaranawa/${id}`,
          hamwi,
          config
        );
        localStorage.removeItem("garanawa");
        localStorage.removeItem("gitem");

        this.setState(
          {
            G_up_wasl: true,
            G_suggestions: [],
            G_del_dis: true,
            G_open: null,
            G_togler: false,
            G_wasl: res.data.update,
            G_paraywasl:
              res.data.update.joripara === "دۆلار"
                ? res.data.update.qarzinwedolar
                : res.data.update.qarzinwedinar,
          },
          async () => {}
        );
      }
    } catch (error) {
      alert("هەڵە هەیە لە خانەکان");
    }
  };
  G_inputtogler = () => {
    this.setState({
      G_togler: !this.state.G_togler,
      G_date: "",
    });
  };

  G_nrxchange = (e) => {
    this.setState(
      {
        G_nrx: e.target.value,
      },
      () => {
        let sawt = new Audio(warningAdiuo);
        sawt.play();
        if (this.state.G_joripara === "دۆلار") {
          this.setState({
            G_qazanj: (
              (Number(this.state.G_nrx) - this.state.G_kren) *
              this.state.G_rezha
            ).toFixed(2),
          });
        } else if (this.state.G_joripara === "دینار") {
          this.setState({
            G_qazanj: (
              (this.dinartodolar(Number(this.state.G_nrx)) -
                this.state.G_kren) *
              this.state.G_rezha
            ).toFixed(2),
          });
        }
      }
    );
  };

  G_getProductDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (this.state.G_naweSht !== "") {
      this.toanotherInput(e);
    }

    const res = await instance.get(
      `/api/v1/searchtoproductByBarcodeName?name=${this.state.G_naweSht}`,
      config
    );

    const data = res.data.product;
    if (data !== null) {
      this.setState(
        () => {
          return {
            G_search: true,
            G_productId: data._id,
            G_naweSht: data.nameofproduct,
            G_rezha: data.numberOfDarzan,
            G_nrx_dolar: data.saleDolar,
            G_nrx_dinarr: data.saleDinar,
            G_nrx:
              this.state.G_joripara === "دینار"
                ? data.saleDinar
                : data.saleDolar,
            G_nrx_dolar3: data.saleDolar3,
            G_nrx_dolar2: data.saleDolar2,
            G_rasidKarton: data.rasidKarton,
            G_qazanj:
              this.state.G_joripara === "دینار"
                ? (
                    (this.dinartodolar(data.saleDinar) - data.price) *
                    data.numberOfDarzan
                  ).toFixed(2)
                : data.qazanj,
            G_kren: data.price,
            G_nrxkaton: (data.price * data.numberOfDarzan).toFixed(2),
            G_waznkarton: data.weight,
            G_zhmara: data.barcode,
            G_suggestions: [],
          };
        },
        () => {
          this.setState(
            {
              G_nrx: this.G_nrxRent(),
            },
            () => {
              if (this.state.G_joripara === "دۆلار") {
                this.setState({
                  G_qazanj: (
                    (Number(this.state.G_nrx) - this.state.G_kren) *
                    this.state.G_rezha
                  ).toFixed(2),
                });
              } else if (this.state.G_joripara === "دینار") {
                this.setState({
                  G_qazanj: (
                    (this.dinartodolar(Number(this.state.G_nrx)) -
                      this.state.G_kren) *
                    this.state.G_rezha
                  ).toFixed(2),
                });
              }
            }
          );
        }
      );
    }
  };

  G_tofirstInputINRent = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 5].focus();
    this.G_saveDetail(e);
  };

  G_anotherInputWakil = (e) => {
    e.preventDefault();
    if (
      this.state.G_frosht === "فرۆشتنی ڕاستەوخۆ" ||
      this.state.G_frosht === null
    ) {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index - 1].focus();
    } else {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index - 1].focus();
      this.G_getWakilDataBySearchRent(e);
    }
  };

  G_getALLkoygshty = () => {
    let subTotal = 0;
    this.state.G_dataTable.map((item) => (subTotal += item.kodolar));
    const cartTotals = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        G_total: cartTotals,
      };
    });
  };

  G_waslKrdn = () => {
    const wasl =
      this.state.G_total -
      parseFloat(this.state.G_paraDan$.toString().replace(/,/g, ""));
    return wasl.toFixed(2);
  };

  G_waslKrdnID = () => {
    const wasl =
      this.state.G_totalID -
      parseFloat(this.state.G_paraDanID.toString().replace(/,/g, ""));
    return wasl.toFixed(2);
  };

  G_getALLkoygshtyID = () => {
    let subTotal = 0;
    this.state.G_dataTable.map((item) => (subTotal += Number(item.kodinar)));
    const cartTotals = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        G_totalID: cartTotals,
      };
    });
  };

  G_calculateCarton = () => {
    const zhmarayKarton = this.state.G_rezha;
    const carton = Number(this.state.G_karton);
    const darzan = carton * zhmarayKarton;
    return Number(darzan).toFixed(4);
  };

  G_nrxRent = (darzan, carton) => {
    let nrx = Number(this.state.G_nrx);

    if (this.state.G_joripara === "دینار") {
      nrx = this.state.G_nrx_dinarr;
      return Number(nrx);
    } else if (this.state.G_joripara === "دۆلار") {
      if (this.state.G_joridolar === "1 دۆلار") {
        nrx = this.state.G_nrx_dolar;
        return Number(nrx);
      } else if (this.state.G_joridolar === "2 دۆلار") {
        nrx = this.state.G_nrx_dolar2;
        return Number(nrx);
      } else if (this.state.G_joridolar === "3 دۆلار") {
        nrx = this.state.G_nrx_dolar3;
        return Number(nrx);
      }
    }
  };

  G_CartonChange = (e) => {
    this.setState(
      {
        G_karton: e.target.value,
      },
      () => {
        this.setState({
          G_darzan: this.G_calculateCarton(),
          G_nrx: this.G_nrxRent(this.state.G_darzan, this.state.G_karton),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        [e.target.name]: "0.",
      });
    }
  };

  G_saveDetail = async (e) => {
    e.preventDefault();
    if (this.state.G_karton === 0 || this.state.G_joripara === null) {
      alert("!تکایە خانەکان پڕ بکەرەوە");
    } else {
      if (localStorage.token) {
        setToken(localStorage.token);
      }
      const config = {
        "Content-Type": "application/json",
      };
      let Table = this.state.G_dataTable;
      let qazanj =
        this.state.G_joripara === "دۆلار"
          ? _.multiply(
              _.subtract(Number(this.state.G_nrx), Number(this.state.G_kren)),
              this.state.G_darzan
            )
          : this.dinartodolar(
              _.multiply(
                _.subtract(
                  Number(this.state.G_nrx),
                  this.dolartodinar(Number(this.state.G_kren))
                ),
                this.state.G_darzan
              )
            );
      Table.unshift({
        _id: this.state.G_productId,
        raqam: this.state.G_zhmara,
        nawisht: this.state.G_naweSht,
        carton: Number(this.state.G_karton),
        darzan: Number(this.state.G_darzan),
        nrx: Number(this.state.G_nrx),
        kren: Number(this.state.G_kren),
        reja: this.state.G_rezha,
        kodolar:
          this.state.G_joripara === "دۆلار"
            ? this.G_getkoygahty(this.state.G_darzan, this.state.G_karton)
            : 0,
        kodinar:
          this.state.G_joripara === "دینار"
            ? this.G_getkoygahty(this.state.G_darzan, this.state.G_karton)
            : 0,
        wazn: this.state.G_waznkarton,
        qazanjykalla: qazanj,
      });

      this.setState(
        {
          G_dataTable: Table,
          G_karton: 0,
          G_darzan: 0,
          G_nrx: 0,
          G_naweSht: "",
          G_rezha: 0,
          G_koygshty$: 0,
          G_koygshtyID: 0,
          G_zhmara: "",
          G_nrx_dolar: 0,
          G_nrx_dinarr: 0,
          G_nrx_dolar3: 0,
          G_nrx_dolar2: 0,
          G_search: null,
          G_rasidKarton: 0,
          G_qazanj: 0,
          G_kren: 0,
          G_nrxkaton: 0,
        },
        () => {
          if (this.state.G_open === true || this.state.G_open !== null) {
            this.G_saveLoaclStorageWasl(this.state.G_wasl);
          }
          if (
            this.state.G_dataTable.length !== 0 &&
            this.state.G_open !== true
          ) {
            localStorage.setItem(
              "gitem",
              JSON.stringify(this.state.G_dataTable)
            );
          }
          if (this.state.G_joripara === "دۆلار") {
            this.G_getALLkoygshty();
          } else if (this.state.G_joripara === "دینار") {
            this.G_getALLkoygshtyID();
          }
          this.G_getkartonakan();
          this.G_waznkartonakan();
          this.G_koyqazanjywasll();
        }
      );
    }
  };

  G_getkoygahty = (darzan, karton) => {
    if (karton !== 0) {
      const nrx = Number(this.state.G_nrx);
      let koygshty = darzan * nrx;

      return Number(koygshty.toFixed(2));
    } else {
      const nrx = Number(this.state.G_nrx);
      let koygshty = darzan * nrx;

      return Number(koygshty.toFixed(2));
    }
  };

  G_getWakilDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (
      this.state.G_frosht === "فرۆشتنی ڕاستەوخۆ" ||
      this.state.G_frosht === null
    ) {
      alert("ببورە جۆری فرۆشتن معمیل نیە");
    } else {
      const res = await instance.get(
        `/api/v1/searchwakil?code=${Number(this.state.G_zh_barez)}&&Name=${
          this.state.G_nawe_barez
        }&&phone=${this.state.G_mob}`,
        config
      );

      const data = res.data.wakils;
      if (data === null) {
        alert("ببورە ئەم بەڕێزە بونی نیە");
        this.setState({
          G_zh_barez: 0,
          G_nawe_barez: "",
          G_mob: 0,
          G_rasidWakildolar: 0,
          G_rasidWakildinar: 0,
        });
      } else {
        this.setState(() => {
          return {
            G_search_wakil: true,
            G_zh_barez: data.code,
            G_nawe_barez: data.Name,
            G_mob: data.phone,
            G_rasidWakildolar: Number(data.rejaDolar),
            G_rasidWakildinar: Number(data.rejaDinar),
          };
        });
      }
    }
  };
  G_getWaslakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/getGaranawakan`, config);

    this.setState({
      G_zh_wasl_katy: res.data.Garanawas + 1,
      G_page: res.data.Garanawas + 1,
    });
  };

  G_setSearchEmptyRentWakil = (e) => {
    e.preventDefault();
    this.setState({
      G_search_wakil: null,
      G_zh_barez: 0,
      G_nawe_barez: "",
      G_mob: 0,
      G_rasidWakildolar: 0,
      G_rasidWakildinar: 0,
    });
  };

  G_createNewPage = (e) => {
    e.preventDefault();
    this.setState(
      {
        G_nawe_barez: "",
        G_mob: 0,
        G_zh_barez: 0,
        G_total: 0,
        G_totalID: 0,
        G_dashkan: "",
        G_dashkanID: "",
        G_dataTable: [],
        G_search: null,
        dinar: false,
        dolar: false,
        G_search_wasl: null,
        G_kartonakan: 0,
        G_nwe: true,
        G_joripara: null,
        G_zhmara: "",
        G_naweSht: "",
        G_karton: 0,
        G_darzan: 0,
        G_kreykrekar: 0,
        G_kreysayara: 0,
        G_save: null,
        G_nrx: 0,
        G_waznkarton: 0,
        G_paraDan$: "",
        G_paraDanID: "",
        G_WakilQard_isChecked: false,
        G_isChecked: null,
        G_search_wakil: null,
        G_zh_wasl: 0,
        G_wargr: null,
        G_frosht: null,
        G_rezha: 0,
        G_rasidKarton: 0,
        G_up_wasl: null,
        G_del_dis: null,
        G_paraywasl: 0,
        G_open: null,
        G_wasl: null,
        G_date: "",
        G_koywaznkarton: 0,
        G_joridolar: null,
        G_rasidWakildinarNew: 0,
        G_rasidWakildolarNew: 0,
        G_rasidWakildinar: 0,
        G_rasidWakildolar: 0,
      },
      () => {
        this.G_getWaslakan();
      }
    );
    this.R_ClearError();
  };

  G_pageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.G_page + 1;
      const res = await instance.get(
        `/api/v1/ganaranawapage?page=${page}&limit=1`,
        config
      );

      const data = res.data.Garanawas;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            G_page: page,
            G_wasl: data,
            G_search_wasl: true,
            G_zh_wasl: data.numberofwasl,
            G_nawe_barez: data.wakil,
            G_mob: data.phone,
            G_zh_barez: data.numberOfWakil,
            G_total: data.koalldolar,
            G_totalID: data.koalldinar,
            G_dashkan: data.dashkandolar,
            G_dashkanID: data.dashkandinar,
            G_dataTable: data.saleTable,
            G_joripara: data.joripara,
            G_frosht: data.froshtn,
            G_wargr: data.whossale,
            G_paraDanID: data.paraydrawdinar,
            G_paraDan$: data.paraydrawdolar,
            G_kartonakan: data.koykartonkan,
            G_kreysayara: data.kreysayra,
            G_kreykrekar: data.kreykrekar,
            G_koywaznkarton: data.waznakan,
            G_paraywasl:
              data.joripara === "دۆلار"
                ? data.qarzinwedolar
                : data.qarzinwedinar,
            G_del_dis: true,
            G_up_froshtn: data.froshtn,
            G_togler: false,
            G_date: data.date,
            G_joridolar: data.joridolar,
            G_rasidWakildinarNew:
              Number(data.qarzikonwakildinar) - Number(data.paraymawadinar),
            G_rasidWakildolarNew:
              Number(data.qarzikonwakildolar) - Number(data.paraymawadolar),
            G_rasidWakildinar: Number(data.qarzikonwakildinar),
            G_rasidWakildolar: Number(data.qarzikonwakildolar),
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  G_pageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page;
      this.state.G_page <= 1 ? (page = 1) : (page = this.state.G_page - 1);
      const res = await instance.get(
        `/api/v1/ganaranawapage?page=${page}&limit=1`,
        config
      );

      const data = res.data.Garanawas;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            G_page: page,
            G_wasl: data,
            G_search_wasl: true,
            G_zh_wasl: data.numberofwasl,
            G_nawe_barez: data.wakil,
            G_mob: data.phone,
            G_zh_barez: data.numberOfWakil,
            G_total: data.koalldolar,
            G_totalID: data.koalldinar,
            G_dashkan: data.dashkandolar,
            G_dashkanID: data.dashkandinar,
            G_dataTable: data.saleTable,
            G_joripara: data.joripara,
            G_frosht: data.froshtn,
            G_wargr: data.whossale,
            G_paraDanID: data.paraydrawdinar,
            G_paraDan$: data.paraydrawdolar,
            G_kartonakan: data.koykartonkan,
            G_kreysayara: data.kreysayra,
            G_kreykrekar: data.kreykrekar,
            G_koywaznkarton: data.waznakan,
            G_paraywasl:
              data.joripara === "دۆلار"
                ? data.qarzinwedolar
                : data.qarzinwedinar,
            G_del_dis: true,
            G_up_froshtn: data.froshtn,
            G_togler: false,
            G_date: data.date,
            G_joridolar: data.joridolar,
            G_rasidWakildinarNew:
              Number(data.qarzikonwakildinar) - Number(data.paraymawadinar),
            G_rasidWakildolarNew:
              Number(data.qarzikonwakildolar) - Number(data.paraymawadolar),
            G_rasidWakildinar: Number(data.qarzikonwakildinar),
            G_rasidWakildolar: Number(data.qarzikonwakildolar),
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };
  G_getNewRaidForwakil = async (NOW, e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (this.state.G_frosht === "فرۆشتنی معمیل") {
        const res = await instance.get(
          `/api/v1/searchwakil?code=${NOW}&&Name=&&phone=`,
          config
        );
        this.setState({
          G_rasidWakildolarNew: Number(res.data.wakils.rejaDolar).toFixed(2),
          G_rasidWakildinarNew: Number(res.data.wakils.rejaDinar).toFixed(2),
        });
      }
    } catch (error) {
      alert("okey");
    }
  };

  G_getPrint = (NOW) => {
    window.setTimeout("window.print()", 2000);

    this.G_getNewRaidForwakil(NOW);
  };
  G_getPrintPasswordRent = (NOW) => {
    if (this.state.G_kartonakan === 0) {
      alert("بەبێ کاڵا ناتوانی پسوولە چاپ بکەی");
    } else {
      const p = prompt("وشەی نهێنی داخیل بکە!");
      if (p === "55") {
        this.setState({ G_pass: true }, () => {
          this.G_getPrint(NOW);
        });
      }
    }
  };

  //end of garanawa bo koga

  // functions of raporty Qazanj
  Q_getfooter = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/qazanjykalla?from=${this.state.Q_from}&to=${this.state.Q_to}&jwlla=pswla&nrxdolar=${this.state.navdolar}`,
        config
      );
      let datasale = res.data.salefooter;
      let datagaranawa = res.data.garanawafooter;
      this.setState(
        {
          Q_koyQazanj:
            datasale.length !== 0
              ? Number(datasale[0].qazanjywasl).toFixed(2)
              : 0,
          Q_dashkandolar:
            datasale.length !== 0
              ? Number(datasale[0].dashkandolar).toFixed(2)
              : 0,
          Q_dashkandinar:
            datasale.length !== 0
              ? Number(datasale[0].dashkandinar).toFixed(2)
              : 0,
          Q_ganawa:
            datagaranawa.length !== 0
              ? Number(datagaranawa[0].qaznjygarawa).toFixed(2)
              : 0,
        },
        () => {
          let qz = _.subtract(this.state.Q_koyQazanj, this.state.Q_ganawa);
          let dashkan = this.state.Q_dashkandolar;
          let safi = Number(_.subtract(qz, dashkan)).toFixed(2);
          this.setState({
            Q_qazanjysafi: safi,
          });
        }
      );
    } catch (error) {
      alert("هیج پسولەیەک لەم بەروارە نیە");
    }
  };

  Q_getkala = () => {
    this.setState(
      {
        Q_kala: !this.state.Q_kala,
        Q_wasl: false,
        Q_rastawxo: false,
        Q_wakel: false,
        Q_data: [],
      },
      () => {
        this.Q_peshandan();
      }
    );
  };

  Q_getwakel = () => {
    this.setState(
      {
        Q_wakel: !this.state.Q_wakel,
        Q_wasl: false,
        Q_rastawxo: false,
        Q_kala: false,
        Q_data: [],
      },
      () => {
        this.Q_peshandan();
      }
    );
  };

  Q_getwasl = () => {
    this.setState(
      {
        Q_wasl: !this.state.Q_wasl,
        Q_wakel: false,
        Q_rastawxo: false,
        Q_kala: false,
        Q_data: [],
      },
      () => {
        this.Q_peshandan();
      }
    );
  };

  Q_getrastawxo = () => {
    this.setState(
      {
        Q_rastawxo: !this.state.Q_rastawxo,
        Q_wasl: false,
        Q_wakel: false,
        Q_kala: false,
        Q_data: [],
      },
      () => {
        this.Q_peshandan();
      }
    );
  };

  Q_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (this.state.Q_from === "" || this.state.Q_to === "") {
      alert("بەروار داخل بکە");
    } else if (this.state.navdolar === "") {
      alert("تکایە نرخی دۆلار داخل بکە");
    } else {
      let res;
      if (this.state.Q_wasl === true) {
        res = await instance.get(
          `/api/v1/qazanjykalla?from=${this.state.Q_from}&to=${this.state.Q_to}&jwlla=pswla&nrxdolar=${this.state.navdolar}`,
          config
        );
      } else if (this.state.Q_wakel === true) {
        res = await instance.get(
          `/api/v1/qazanjykalla?from=${this.state.Q_from}&to=${this.state.Q_to}&jwlla=wakil&nrxdolar=${this.state.navdolar}`,
          config
        );
      } else if (this.state.Q_rastawxo === true) {
        res = await instance.get(
          `/api/v1/qazanjykalla?from=${this.state.Q_from}&to=${this.state.Q_to}&jwlla=rastawxo&nrxdolar=${this.state.navdolar}`,
          config
        );
      } else if (this.state.Q_kala === true) {
        res = await instance.get(
          `/api/v1/qazanjykalla?from=${this.state.Q_from}&to=${this.state.Q_to}&jwlla=kalla&nrxdolar=${this.state.navdolar}`,
          config
        );
      } else {
        ///deafult
        res = await instance.get(
          `/api/v1/qazanjykalla?from=${this.state.Q_from}&to=${this.state.Q_to}&jwlla=pswla&nrxdolar=${this.state.navdolar}`,
          config
        );
      }

      this.setState({
        Q_data: res.data.wasl,
      });
      this.Q_getfooter();
      this.Q_CloseModal();
    }
  };

  Q_OpenModal = async (userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            Q_modalOpen: !this.state.Q_modalOpen,
            Q_data: [],
            Q_koyQazanj: 0,
            Q_qazanjysafi: 0,
            Q_dashkandolar: 0,
            Q_dashkandinar: 0,
            Q_ganawa: 0,
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  Q_CloseModal = () => {
    this.setState(() => {
      return { Q_modalOpen: false };
    });
  };

  // functions of Raporty Rozhana

  D_OpenModal = async (e, userid) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            d_modalOpen: !this.state.d_modalOpen,
            d_dataRozhana: [],
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  D_CloseModal = () => {
    this.setState(() => {
      return { d_modalOpen: false };
    });
  };

  // end
  /////////daily raport
  D_hameJwllakan = async () => {
    this.D_CloseModal();
    this.D_rozhanafooter();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const table = this.state.d_dataRozhana;
    const res = await instance.get(
      `/api/v1/waslkandata?from=${this.state.d_from}&to=${this.state.d_to}`,
      config
    );
    const data = res.data.pswla;

    data.forEach((data) => {
      table.push({
        zh_wakil: data.numberOfWakil,
        nawi_wakil: data.wakil,
        paraymawa:
          data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
        paraydraw:
          data.joripara === "دۆلار" ? data.paraydrawdolar : data.paraydrawdinar,
        kmkrdnawa:
          data.joripara === "دۆلار" ? data.dashkandolar : data.dashkandinar,
        joripara: data.joripara,
        koywasl: data.joripara === "دۆلار" ? data.koalldolar : data.koalldinar,
        jwlla: "فرۆشتن",
        barwar: data.date,
        code: data.numberofwasl,
        jorefroshtn: data.froshtn,
      });
    });
    const para = await instance.get(
      `/api/v1/parakandata?from=${this.state.d_from}&to=${this.state.d_to}`,
      config
    );
    const paras = para.data.paras;

    paras.forEach((paras) => {
      table.push({
        zh_wakil: paras.wakilcode,
        nawi_wakil: paras.namewakil,
        paraymawa:
          paras.joripara === "دۆلار"
            ? paras.paradwaykmkrdndolar
            : paras.paradwaykmkrdndinar,
        paraydraw:
          paras.joripara === "دۆلار"
            ? paras.paraywargirawdolar
            : paras.paraywargirawdinar,
        kmkrdnawa:
          paras.joripara === "دۆلار" ? paras.kmkrdnawa : paras.kmkrdnawa,
        joripara: paras.joripara,
        koywasl:
          paras.joripara === "دۆلار" ? paras.rasiddolar : paras.rasiddinar,
        jwlla: "گەڕانەوەی قەرز",
        barwar: paras.date,
        code: paras.zhmara,
      });
    });

    const garanawa = await instance.get(
      `/api/v1/garanwadata?from=${this.state.d_from}&to=${this.state.d_to}`,
      config
    );
    const garanawakan = garanawa.data.pswla;

    garanawakan.forEach((gar) => {
      table.push({
        zh_wakil: gar.numberOfWakil,
        nawi_wakil: gar.wakil,
        paraymawa:
          gar.joripara === "دۆلار" ? gar.qarzinwedolar : gar.qarzinwedinar,
        paraydraw:
          gar.joripara === "دۆلار" ? gar.paraydrawdolar : gar.paraydrawdinar,
        kmkrdnawa: 0,
        joripara: gar.joripara,
        koywasl: gar.joripara === "دۆلار" ? gar.koalldolar : gar.koalldinar,
        jwlla: "گەڕانەوەی کاڵا",
        barwar: gar.date,
        code: gar.numberofwasl,
      });
    });

    if (this.state.d_froshtn === true) {
      this.setState({
        d_dataRozhana: table.filter((data) => data.jwlla === "فرۆشتن"),
      });
    } else if (this.state.d_parawargrtn === true) {
      this.setState({
        d_dataRozhana: table.filter((data) => data.jwlla === "گەڕانەوەی قەرز"),
      });
    } else if (this.state.d_hamw === true) {
      this.setState({
        d_dataRozhana: table,
      });
    } else if (this.state.d_garanawa === true) {
      this.setState({
        d_dataRozhana: table.filter((data) => data.jwlla === "گەڕانەوەی کاڵا"),
      });
    }
  };

  D_rozhanafroshtn = async () => {
    this.setState(
      {
        d_froshtn: true,
        d_parawargrtn: false,
        d_hamw: false,
        d_dataRozhana: [],
        d_garanawa: false,
      },
      () => {
        this.D_hameJwllakan();
      }
    );
  };

  D_rozhanaGaranawa = async () => {
    this.setState(
      {
        d_garanawa: true,
        d_parawargrtn: false,
        d_froshtn: false,
        d_hamw: false,
        d_dataRozhana: [],
      },
      () => {
        this.D_hameJwllakan();
      }
    );
  };

  D_rozhanafooter = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      /////raport la bashi froshtn
      const res = await instance.get(
        `/api/v1/waslkan?from=${this.state.d_from}&to=${this.state.d_to}`,
        config
      );
      const data = res.data.pswla[0];
      ////rahel la froshtn
      const rahel = await instance.get(
        `/api/v1/rahel?from=${this.state.d_from}&to=${this.state.d_to}`,
        config
      );
      const raheldata = rahel.data.pswla[0];
      ////yassin la froshtn
      const yassin = await instance.get(
        `/api/v1/yassin?from=${this.state.d_from}&to=${this.state.d_to}`,
        config
      );
      const yassindata = yassin.data.pswla[0];
      ////parawargtn raport
      const para = await instance.get(
        `/api/v1/para?from=${this.state.d_from}&to=${this.state.d_to}`,
        config
      );
      const paradata = para.data.paras[0];
      ////rahel la parawargtn
      const prahel = await instance.get(
        `/api/v1/prahel?from=${this.state.d_from}&to=${this.state.d_to}`,
        config
      );
      const praheldata = prahel.data.pswla[0];
      ////yassin la parawargtn
      const pyassin = await instance.get(
        `/api/v1/pyassin?from=${this.state.d_from}&to=${this.state.d_to}`,
        config
      );
      const pyassindata = pyassin.data.pswla[0];

      const raheldolarsum = (
        (raheldata === undefined ? 0 : raheldata.totaldolar) +
        (praheldata === undefined ? 0 : praheldata.paraygarawadolar)
      ).toFixed(2);
      const raheldinarsum = (
        (raheldata === undefined ? 0 : raheldata.totaldinar) +
        (praheldata === undefined ? 0 : praheldata.paraygarawadinar)
      ).toFixed(2);
      const yassindolarsum = (
        (yassindata === undefined ? 0 : yassindata.totaldolar) +
        (pyassindata === undefined ? 0 : pyassindata.paraygarawadolar)
      ).toFixed(2);
      const yassindinarsum = (
        (yassindata === undefined ? 0 : yassindata.totaldinar) +
        (pyassindata === undefined ? 0 : pyassindata.paraygarawadinar)
      ).toFixed(2);

      this.setState({
        d_paraynadraw$: data === undefined ? 0 : data.qarzdolar.toFixed(2),
        d_paraynadrawID: data === undefined ? 0 : data.qarzdinar.toFixed(2),
        d_paraywargeraw$:
          data === undefined ? 0 : data.paraywargirawdolar.toFixed(2),
        d_paraywargerawID:
          data === undefined ? 0 : data.paraywargirawdinar.toFixed(2),
        d_froahraw$: data === undefined ? 0 : data.totaldolar.toFixed(2),
        d_froshrawID: data === undefined ? 0 : data.totaldinar.toFixed(2),
        d_rahel$: raheldolarsum,
        d_rahelID: raheldinarsum,
        d_yassin$: yassindolarsum,
        d_yassinID: yassindinarsum,
        d_wargrtnawayQarz$:
          paradata === undefined ? 0 : paradata.paraygarawadolar.toFixed(2),
        d_wargrtnawayQarzID:
          paradata === undefined ? 0 : paradata.paraygarawadinar.toFixed(2),
      });
    } catch (error) {
      alert("ببووە ڕێکەوتەکە هەڵەیە");
    }
  };

  D_jwllakan = () => {
    this.setState(
      {
        d_froshtn: false,
        d_parawargrtn: false,
        d_hamw: true,
        d_dataRozhana: [],
        d_garanawa: false,
      },
      () => {
        this.D_hameJwllakan();
      }
    );
  };

  D_rozhanaparawagrtn = async () => {
    this.setState(
      {
        d_parawargrtn: true,
        d_froshtn: false,
        d_hamw: false,
        d_dataRozhana: [],
        d_garanawa: false,
      },
      () => {
        this.D_hameJwllakan();
      }
    );
  };

  ////// Go to RENT

  D_gotowaslrentsearch = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchwasll?numberofwasl=${Number(zhwasl)}`,
      config
    );

    const data = res.data.pswla;
    this.setState(
      () => {
        return {
          R_wasl: data,
          R_search_wasl: true,
          R_zh_wasl: data.numberofwasl,
          R_nawe_barez: data.wakil,
          R_mob: data.phone,
          R_zh_barez: data.numberOfWakil,
          R_total: data.koalldolar,
          R_totalID: data.koalldinar,
          R_dashkan: data.dashkandolar,
          R_dashkanID: data.dashkandinar,
          R_dataTable: data.saleTable,
          R_joripara: data.joripara,
          R_frosth: data.froshtn,
          R_wargr: data.whossale,
          R_paraDanID: data.paraydrawdinar,
          R_paraDan$: data.paraydrawdolar,
          R_kartonakan: data.koykartonkan,
          R_kreysayara: data.kreysayra,
          R_kreykrekar: data.kreykrekar,
          R_koywaznkarton: data.waznakan,
          R_paraywasl:
            data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
          R_del_dis: true,
          R_up_froshtn: data.froshtn,
          R_date: data.date,
          R_togler: false,
          R_open: null,
          R_kateQarz: data.dateQazrz,
          R_rasidWakildinarNew:
            Number(data.qarzikonwakildinar) + Number(data.qarzinwedinar),
          R_rasidWakildolarNew:
            Number(data.qarzikonwakildolar) + Number(data.qarzinwedolar),
          R_rasidWakildinar: data.qarzikonwakildinar,
          R_rasidWakildolar: data.qarzikonwakildolar,
          R_note: data.note
        };
      },
      () => {
        this.R_ClearError();
        if (this.state.R_frosth === "فرۆشتنی معمیل") {
          this.getWakilDataBySearchRent(e);
        }
      }
    );
  };

  D_gotowaslparasearch = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/search?zhmara=${zhwasl}`, config);

    const data = res.data.parakan;
    this.setState(() => {
      return {
        P_search_wasl: true,
        P_zhmara: data.zhmara,
        P_nawe_barez: data.namewakil,
        P_barwar: data.date,
        P_zh_barez: data.wakilcode,
        P_paraPashDashkan:
          data.joripara === "دینار"
            ? data.paradwaykmkrdndinar
            : data.paradwaykmkrdndolar,
        P_dashkan: data.kmkrdnawa,
        P_brePara:
          data.joripara === "دینار"
            ? data.paraywargirawdinar
            : data.paraywargirawdolar,
        P_joripara: data.joripara,
        P_wargr: data.nawiwargr,
        P_note: data.note,
        P_qarzeKonDolar: data.rasiddolar,
        P_qarzeKonID: data.rasiddinar,
        P_del_dis: true,
        P_paraywasl: data.paradwaykmkrdn,
        P_wasl: data,
        P_togler: false,
      };
    });
  };

  D_gotowaslGaranawasearch = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchgaranawa?numberofwasl=${Number(zhwasl)}`,
      config
    );

    const data = res.data.pswla;
    if (data === null) {
      alert("ببورە ئەم پسولەیە بونی نیە");
    } else {
      this.setState(
        () => {
          return {
            G_wasl: data,
            G_search_wasl: true,
            G_zh_wasl: data.numberofwasl,
            G_nawe_barez: data.wakil,
            G_mob: data.phone,
            G_zh_barez: data.numberOfWakil,
            G_total: data.koalldolar,
            G_totalID: data.koalldinar,
            G_dashkan: data.dashkandolar,
            G_dashkanID: data.dashkandinar,
            G_dataTable: data.saleTable,
            G_joripara: data.joripara,
            G_frosht: data.froshtn,
            G_wargr: data.whossale,
            G_paraDanID: data.paraydrawdinar,
            G_paraDan$: data.paraydrawdolar,
            G_kartonakan: data.koykartonkan,
            G_kreysayara: data.kreysayra,
            G_kreykrekar: data.kreykrekar,
            G_koywaznkarton: data.waznakan,
            G_paraywasl:
              data.joripara === "دۆلار"
                ? data.qarzinwedolar
                : data.qarzinwedinar,
            G_del_dis: true,
            G_up_froshtn: data.froshtn,
            G_togler: false,
            G_date: data.date,
            G_rasidWakildinarNew:
              Number(data.qarzikonwakildinar) - Number(data.paraymawadinar),
            G_rasidWakildolarNew:
              Number(data.qarzikonwakildolar) - Number(data.paraymawadolar),
            G_rasidWakildinar: Number(data.qarzikonwakildinar),
            G_rasidWakildolar: Number(data.qarzikonwakildolar),
          };
        },
        () => {
          this.R_ClearError();
          if (this.state.G_frosht === "فرۆشتنی معمیل") {
            this.G_getWakilDataBySearchRent(e);
          }
        }
      );
    }
  };

  // functions of Raporty Wakel
  RW_OpenModal = async (e, userid) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            RW_modalOpen: !this.state.RW_modalOpen,
            RW_dataWakelRaport: [],
            RW_koyfroshtndianr: 0,
            RW_koyfroshtndolar: 0,
            RW_nawibrez: "",
            RW_zh_barez: "",
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RW_CloseModal = () => {
    this.setState(() => {
      return { RW_modalOpen: false };
    });
  };

  Rw_serchwakil = async (e) => {
    e.preventDefault(e);
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    //    this.toanotherInput(e)
    const res = await instance.get(
      `/api/v1/searchwakil?code=${Number(this.state.RW_zh_barez)}`,
      config
    );

    const data = res.data.wakils;
    if (data === null) {
      alert("ببورە ئەم بەڕێزە بونی نیە");
    } else {
      this.setState(() => {
        return {
          RW_zh_barez: data.code,
          RW_nawibrez: data.Name,
          RW_mob: data.phone,
          RW_rasedyKotaye$: data.rejaDolar.toFixed(2),
          RW_rasedyKotayeID: data.rejaDinar.toFixed(2),
          //  R_rasidWakildolar:data.rejaDolar,
          //  R_rasidWakildinar:data.rejaDinar
        };
      });
    }
  };

  RW_setstInputEmpty = () => {
    this.setState(() => {
      return {
        RW_zh_barez: 0,
        RW_nawibrez: "",
      };
    });
  };
  /////// pshandani wkil raport
  RW_jwalakan = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    try {
      this.RW_CloseModal();
      const config = {
        "Content-Type": "application/json",
      };
      const table = this.state.RW_dataWakelRaport;
      const res = await instance.get(
        `/api/v1/wakilsale?from=${this.state.RW_from}&to=${this.state.RW_to}&numberOfWakil=${this.state.RW_zh_barez}`,
        config
      );
      const data = res.data.pswla;

      data.forEach((data) => {
        table.push({
          paraymawa:
            data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
          paraydraw:
            data.joripara === "دۆلار"
              ? data.paraydrawdolar
              : data.paraydrawdinar,
          kmkrdnawa:
            data.joripara === "دۆلار" ? data.dashkandolar : data.dashkandinar,
          joripara: data.joripara,
          koywasl:
            data.joripara === "دۆلار" ? data.koalldolar : data.koalldinar,
          jwlla: "فرۆشتن",
          barwar: data.date,
          code: data.numberofwasl,
        });
      });
      const para = await instance.get(
        `/api/v1/wakilparadata?from=${this.state.RW_from}&to=${this.state.RW_to}&code=${this.state.RW_zh_barez}`,
        config
      );
      const paras = para.data.pswla;

      paras.forEach((paras) => {
        table.push({
          paraymawa:
            paras.joripara === "دۆلار"
              ? paras.paradwaykmkrdndolar
              : paras.paradwaykmkrdndinar,
          paraydraw:
            paras.joripara === "دۆلار"
              ? paras.paraywargirawdolar
              : paras.paraywargirawdinar,
          kmkrdnawa:
            paras.joripara === "دۆلار" ? paras.kmkrdnawa : paras.kmkrdnawa,
          joripara: paras.joripara,
          koywasl:
            paras.joripara === "دۆلار" ? paras.rasiddolar : paras.rasiddinar,
          jwlla: "گەڕانەوەی قەرز",
          barwar: paras.date,
          code: paras.zhmara,
        });
      });
      const garanawa = await instance.get(
        `/api/v1/wakilgaranawa?from=${this.state.RW_from}&to=${this.state.RW_to}&numberOfWakil=${this.state.RW_zh_barez}`,
        config
      );
      const datagaranawa = garanawa.data.pswla;

      datagaranawa.forEach((data) => {
        table.push({
          paraymawa:
            data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
          paraydraw:
            data.joripara === "دۆلار"
              ? data.paraydrawdolar
              : data.paraydrawdinar,
          kmkrdnawa: 0,
          joripara: data.joripara,
          koywasl:
            data.joripara === "دۆلار" ? data.koalldolar : data.koalldinar,
          jwlla: "گەڕانەوەی کاڵا",
          barwar: data.date,
          code: data.numberofwasl,
        });
      });

      const oldrasiddaftar = await instance.get(
        `/api/v1/getOldrasid/${this.state.RW_zh_barez}`,
        config
      );
      const dataoldrasid = oldrasiddaftar.data.rasid;

      dataoldrasid.forEach((data) => {
        table.push({
          paraymawa: "",
          paraydraw: "",
          kmkrdnawa: "",
          joripara: data.draw,
          koywasl: data.para,
          jwlla: "قەرزی سەر دەفتەر",
          barwar: data.createdAt,
          code: data.code,
        });
      });
      if (this.state.RW_froshtn === true) {
        this.setState({
          RW_dataWakelRaport: table.filter((data) => data.jwlla === "فرۆشتن"),
        });
      } else if (this.state.RW_parawargrtn === true) {
        this.setState({
          RW_dataWakelRaport: table.filter(
            (data) => data.jwlla === "گەڕانەوەی قەرز"
          ),
        });
      } else if (this.state.RW_hamw === true) {
        this.setState({
          RW_dataWakelRaport: table,
        });
      } else if (this.state.RW_garanawa === true) {
        this.setState({
          RW_dataWakelRaport: table.filter(
            (data) => data.jwlla === "گەڕانەوەی کاڵا"
          ),
        });
      }
      this.RW_fooetr(e);
    } catch (error) {
      alert("هیج جاڵاکیەکی نیە");
    }
  };

  RW_fooetr = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/wakilsaledata?from=${this.state.RW_from}&to=${this.state.RW_to}&numberOfWakil=${this.state.RW_zh_barez}`,
        config
      );

      const data = res.data.pswla[0];
      this.setState({
        RW_koyfroshtndolar: data.totaldolar.toFixed(2),
        RW_koyfroshtndianr: data.totaldinar.toFixed(2),
      });
    } catch (error) {
      // alert('تکایە خانەکان پربکەرەوە')
    }
  };

  RW_jwllakan = () => {
    this.setState(
      {
        RW_froshtn: false,
        RW_parawargrtn: false,
        RW_hamw: true,
        RW_garanawa: false,
        RW_dataWakelRaport: [],
      },
      () => {
        this.RW_jwalakan();
      }
    );
  };

  RW_rozhanafroshtn = async () => {
    this.setState(
      {
        RW_froshtn: true,
        RW_parawargrtn: false,
        RW_hamw: false,
        RW_garanawa: false,
        RW_dataWakelRaport: [],
      },
      () => {
        this.RW_jwalakan();
      }
    );
  };

  RW_rozhanaparawagrtn = async () => {
    this.setState(
      {
        RW_parawargrtn: true,
        RW_garanawa: false,
        RW_froshtn: false,
        RW_hamw: false,
        RW_dataWakelRaport: [],
      },
      () => {
        this.RW_jwalakan();
      }
    );
  };
  RW_wakilGaranawa = async () => {
    this.setState(
      {
        RW_garanawa: true,
        RW_parawargrtn: false,
        RW_froshtn: false,
        RW_hamw: false,
        RW_dataWakelRaport: [],
      },
      () => {
        this.RW_jwalakan();
      }
    );
  };
  /////saerch
  RW_gotowaslrentsearch = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchwasll?numberofwasl=${Number(zhwasl)}`,
      config
    );

    const data = res.data.pswla;
    this.setState(
      () => {
        return {
          R_wasl: data,
          R_search_wasl: true,
          R_zh_wasl: data.numberofwasl,
          R_nawe_barez: data.wakil,
          R_mob: data.phone,
          R_zh_barez: data.numberOfWakil,
          R_total: data.koalldolar,
          R_totalID: data.koalldinar,
          R_dashkan: data.dashkandolar,
          R_dashkanID: data.dashkandinar,
          R_dataTable: data.saleTable,
          R_joripara: data.joripara,
          R_frosth: data.froshtn,
          R_wargr: data.whossale,
          R_paraDanID: data.paraydrawdinar,
          R_paraDan$: data.paraydrawdolar,
          R_kartonakan: data.koykartonkan,
          R_kreysayara: data.kreysayra,
          R_kreykrekar: data.kreykrekar,
          R_koywaznkarton: data.waznakan,
          R_paraywasl:
            data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
          R_del_dis: true,
          R_up_froshtn: data.froshtn,
          R_date: data.date,
          R_togler: false,
          R_open: null,
          R_kateQarz: data.dateQazrz,
          R_rasidWakildinarNew:
            Number(data.qarzikonwakildinar) + Number(data.qarzinwedinar),
          R_rasidWakildolarNew:
            Number(data.qarzikonwakildolar) + Number(data.qarzinwedolar),
          R_rasidWakildinar: data.qarzikonwakildinar,
          R_rasidWakildolar: data.qarzikonwakildolar,
          R_note: data.note
        };
      },
      () => {
        if (this.state.R_frosth === "فرۆشتنی معمیل") {
          this.getWakilDataBySearchRent(e);
        }
      }
    );
  };

  RW_gotowaslparasearch = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/search?zhmara=${zhwasl}`, config);

    const data = res.data.parakan;
    this.setState(() => {
      return {
        P_search_wasl: true,
        P_zhmara: data.zhmara,
        P_nawe_barez: data.namewakil,
        P_barwar: data.date,
        P_zh_barez: data.wakilcode,
        P_paraPashDashkan:
          data.joripara === "دینار"
            ? data.paradwaykmkrdndinar
            : data.paradwaykmkrdndolar,
        P_dashkan: data.kmkrdnawa,
        P_brePara:
          data.joripara === "دینار"
            ? data.paraywargirawdinar
            : data.paraywargirawdolar,
        P_joripara: data.joripara,
        P_wargr: data.nawiwargr,
        P_note: data.note,
        P_qarzeKonDolar: data.rasiddolar,
        P_qarzeKonID: data.rasiddinar,
        P_del_dis: true,
        P_paraywasl: data.paradwaykmkrdn,
        P_wasl: data,
        P_togler: false,
      };
    });
  };
  RW_gotowaslGaranawasearch = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchgaranawa?numberofwasl=${Number(zhwasl)}`,
      config
    );

    const data = res.data.pswla;
    if (data === null) {
      alert("ببورە ئەم پسولەیە بونی نیە");
    } else {
      this.setState(
        () => {
          return {
            G_wasl: data,
            G_search_wasl: true,
            G_zh_wasl: data.numberofwasl,
            G_nawe_barez: data.wakil,
            G_mob: data.phone,
            G_zh_barez: data.numberOfWakil,
            G_total: data.koalldolar,
            G_totalID: data.koalldinar,
            G_dashkan: data.dashkandolar,
            G_dashkanID: data.dashkandinar,
            G_dataTable: data.saleTable,
            G_joripara: data.joripara,
            G_frosht: data.froshtn,
            G_wargr: data.whossale,
            G_paraDanID: data.paraydrawdinar,
            G_paraDan$: data.paraydrawdolar,
            G_kartonakan: data.koykartonkan,
            G_kreysayara: data.kreysayra,
            G_kreykrekar: data.kreykrekar,
            G_koywaznkarton: data.waznakan,
            G_paraywasl:
              data.joripara === "دۆلار"
                ? data.qarzinwedolar
                : data.qarzinwedinar,
            G_del_dis: true,
            G_up_froshtn: data.froshtn,
            G_togler: false,
            G_date: data.date,
            G_rasidWakildinarNew:
              Number(data.qarzikonwakildinar) - Number(data.paraymawadinar),
            G_rasidWakildolarNew:
              Number(data.qarzikonwakildolar) - Number(data.paraymawadolar),
            G_rasidWakildinar: Number(data.qarzikonwakildinar),
            G_rasidWakildolar: Number(data.qarzikonwakildolar),
          };
        },
        () => {
          if (this.state.G_frosht === "فرۆشتنی معمیل") {
            this.G_getWakilDataBySearchRent(e);
          }
        }
      );
    }
  };

  // functions of Qarze Wakel
  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };
  QR_filterQarz = () => {
    this.setState({
      QR_data: this.state.QR_data.filter(
        (el, i) => el.qarzinwedolar !== 0 || el.qarzinwedinar !== 0
      ),
    });
  };
  QR_selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        QR_checkedValues: this.state.QR_checkedValues.filter(
          (item, j) => i !== item
        ),
      });
    } else {
      this.state.QR_checkedValues.push(i);
      this.setState({
        QR_checkedValues: this.state.QR_checkedValues,
      });
    }
  };
  QW_filterQarz = () => {
    this.setState({
      QW_dataWakelQarz: this.state.QW_dataWakelQarz.filter(
        (el, i) => el.rejaDolar !== 0 || el.rejaDinar !== 0
      ),
    });
  };
  QW_qazrywakil = async (e, userid) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
        this.setState({
          QW_dataWakelQarz: [],
          QW_koyqarzakan: 0,
          QW_koyqarzakanID: 0,
          QW_rasiddinar: 0,
          QW_rasiddolar: 0,
        });
      } else if (pass.data.status === true) {
        try {
          const res = await instance.get("/api/v1/getWakilakan", config);
          this.setState({
            QW_dataWakelQarz: res.data.wakilakan,
            QW_modalOpen: true,
          });

          this.QW_koyqarzakan();
          this.QW_koyqarzakanID();
        } catch (error) {
          alert("هەڵە هەیە لە بەروار");
        }
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  QW_CloseModal = () => {
    this.setState(() => {
      return { QW_modalOpen: false };
    });
  };

  QW_koyqarzakan = () => {
    let subTotal = 0;
    this.state.QW_dataWakelQarz.map(
      (item) => (subTotal += Number(item.rejaDolar))
    );
    const kartons = Number(subTotal.toFixed(2));

    this.setState(() => {
      return {
        QW_rasiddolar: kartons,
      };
    });
  };

  QW_koyqarzakanID = () => {
    let subTotal = 0;
    this.state.QW_dataWakelQarz.map(
      (item) => (subTotal += Number(item.rejaDinar))
    );
    const kartons = Number(subTotal.toFixed(2));

    this.setState(() => {
      return {
        QW_rasiddinar: kartons,
      };
    });
  };

  // functions of Jarde Maxzan ean raporty Koga

  JM_jardyrasidkarton = () => {
    let subTotal = 0;
    this.state.JM_dataRaportyKoga.map(
      (item) => (subTotal += Number(item.rasidKarton))
    );
    const kartons = Number(subTotal).toFixed(2);

    this.setState(() => {
      return {
        JM_koyrasidkarton: kartons,
      };
    });
  };

  JM_jardyrasidparakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(`api/v1/totalParayMaxzan`, config);

    this.setState(() => {
      return {
        JM_koyrasidparakan: res.data.sum,
      };
    });
  };

  JM_yaksanbasfrfn = () => {
    this.setState(
      {
        JM_dataRaportyKoga: this.state.JM_allproducts,
        JM_yaksanbasfr: true,
        JM_zyattrlasfr: false,
      },
      () => {
        this.setState({
          JM_dataRaportyKoga: this.state.JM_dataRaportyKoga.filter(
            (el) => el.rasidKarton <= 0
          ),
        });
      }
    );
  };

  JM_zyatrlasfrfn = () => {
    this.setState(
      {
        JM_dataRaportyKoga: this.state.JM_allproducts,
        JM_zyattrlasfr: true,
        JM_yaksanbasfr: false,
      },
      () => {
        this.setState({
          JM_dataRaportyKoga: this.state.JM_dataRaportyKoga.filter(
            (el) => el.rasidKarton > 0
          ),
        });
      }
    );
  };

  JM_pshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get("/api/v1/getProducts", config);
      this.setState({
        JM_dataRaportyKoga: res.data.products,
        JM_allproducts: res.data.products,
        JM_zyattrlasfr: false,
        JM_yaksanbasfr: false,
      });
      this.JM_CloseModal();
      this.JM_jardyrasidkarton();
      this.JM_jardyrasidparakan();
    } catch (error) {
      alert("هەڵە هەیە لە بەروار");
    }
  };

  JM_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            JM_modalOpen: !this.state.JM_modalOpen,
            JM_dataRaportyKoga: [],
            JM_footer_rasedyhamukartonakan: 0,
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  JM_CloseModal = () => {
    this.setState(() => {
      return { JM_modalOpen: false };
    });
  };

  /////// delete product ;la raporty koga jardy maxzan
  JM_clickOnDelete = async (id) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (window.confirm("دڵنیای لە ڕەشکردنەوەی ئەم کاڵایە؟")) {
      this.setState(
        {
          JM_dataRaportyKoga: this.state.JM_dataRaportyKoga.filter(
            (r) => r._id !== id
          ),
        },
        () => {
          this.JM_jardyrasidkarton();
          this.JM_jardyrasidparakan();
        }
      );
      await instance.delete(`/api/v1/deleteProduct/${id}`, config);
    }
  };

  //functions of Raporty Kala (mawad)

  K_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            K_modalOpen: !this.state.K_modalOpen,
            K_datatable: [],
            K_datafilter: [],
            K_rasedekartonKren: 0,
            K_rasedekartonfroshtn: 0,
            K_rasedekartongarnawa: 0,
            K_rasedeKartonBroken: 0,
            K_numberOfDarzan: 0,
            K_rasedeKarton: 0,
            K_naweKala: "",
            K_zhamara: "",
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  K_CloseModal = () => {
    this.setState(() => {
      return { K_modalOpen: false };
    });
  };

  K_getGaranawa = () => {
    this.setState(
      {
        k_navbar_garanawa: true,
        k_navbar_froshtn: false,
        k_navbar_tomarkrdn: false,
        K_datatable: [],
      },
      () => {
        this.K_pshandan();
      }
    );
  };

  K_getFroshtn = () => {
    this.setState(
      {
        k_navbar_froshtn: true,
        k_navbar_tomarkrdn: false,
        k_navbar_garanawa: false,
        K_datatable: [],
      },
      () => {
        this.K_pshandan();
      }
    );
  };

  K_gettomarkrdn = (e) => {
    this.setState(
      {
        k_navbar_tomarkrdn: true,
        k_navbar_froshtn: false,
        k_navbar_garanawa: false,
        K_datatable: [],
      },
      () => {
        this.K_pshandan();
      }
    );
  };
  K_getproduct = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchtoproductByBarcodeName?name=${this.state.K_naweKala}`,
      config
    );
    const data = res.data.product;
    await instance.get(`/api/v1/ChakrdniRasidYakMawad/${data._id}`, config);
    if (data === null) {
      alert("ببورە ئەم کاڵایە بونی نیە");
    } else {
      this.setState({
        K_zhamara: data.code,
        K_naweKala: data.nameofproduct,
        K_kallaDetail: data._id,
        K_numberOfDarzan: data.numberOfDarzan,
        K_rasedeKartonBroken: data.rasidBroken,
        K_suggestions: [],
      });
    }
  };

  K_pshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      this.K_CloseModal();
      let dd = {
        id: this.state.K_kallaDetail,
        from: "2021-01-01",
        to: this.state.K_to,
      };

      const table = this.state.K_datatable;
      const res = await instance.post(`/api/v1/qazanjyharkallayak`, dd, config);
      const data = res.data.pswla;
      const froshtndata = res.data.mawad;
      await instance.get(
        `/api/v1/ChakrdniRasidYakMawad/${this.state.K_kallaDetail}`,
        config
      );
      const resgaranawa = await instance.post(
        `/api/v1/garanhayharkallayak`,
        dd,
        config
      );
      const datagaranawa = resgaranawa.data.pswla;
      const garanawadata = res.data.mawad;

      const kalla = await instance.get(
        `/api/v1/jardykalla?raqam=${this.state.K_kallaDetail}&from=2021-01-01&to=${this.state.K_to}`,
        config
      );
      const kalladata = kalla.data.mawad;
      const kkk = kalla.data.kalladate;
      const resb = await instance.post(`/api/v1/BuyRaport`, dd, config);
      const datab = resb.data.buy;
      let nrxkrin, nrxdolar, nrxdinar, rased;
      kalladata.forEach((el) => {
        nrxdinar = el.saleDinar;
        nrxdolar = el.saleDolar;
        nrxkrin = el.price;
        rased = el.rasidKarton;
      });
      kkk.forEach((data) => {
        table.push({
          jwlla: "کڕین",
          barwar: data.date,
          joripara: "دۆلار",
          froshtn: data.numberOfDarzan,
          carton: data.numberOfCarton,
          krin: nrxkrin,
          froshtndolar: nrxdolar,
          froshtndinar: nrxdinar,
        });
      });
      datab.forEach((el, i) => {
        table.push({
          jwlla: "کڕین",
          barwar: el.createdAt,
          joripara: el.joripara,
          froshtn: el.darzan,
          carton: el.carton,
          krin: nrxkrin,
          froshtndolar: nrxdolar,
          froshtndinar: nrxdinar,
        });
      });
      data.forEach((el) => {
        rased = Number(froshtndata.rasidKarton).toFixed(2);
        table.push({
          jwlla: "فرۆشتن",
          code: el.numberOfWakil,
          jor: el.froshtn,
          barwar: el.date,
          numberofwasl: el.numberofwasl,
          naw: el.wakil,
          joripara: el.joripara,
          froshtn: el.saleTable.darzan,
          carton: el.saleTable.carton,
          krin: froshtndata.saleDolar,
          froshtndolar: el.saleTable.nrx,
          froshtndinar: froshtndata.saleDinar,
        });
      });
      datagaranawa.forEach((el) => {
        table.push({
          jwlla: "گەڕانەوە",
          code: el.numberOfWakil,
          jor: el.froshtn,
          barwar: el.date,
          numberofwasl: el.numberofwasl,
          naw: el.wakil,
          joripara: el.joripara,
          froshtn: el.saleTable.darzan,
          carton: el.saleTable.carton,
          krin: garanawadata.saleDolar,
          froshtndolar: el.saleTable.nrx,
          froshtndinar: garanawadata.saleDinar,
        });
      });
      this.setState(
        {
          K_rasedeKarton: rased,
          K_datafilter: table.filter((el) => el.jwlla === "فرۆشتن"),
          K_kamtrlasfr: false,
          K_zyattrlasfr: false,
          K_yaksanbasfr: false,
        },
        () => {
          this.K_getrasedFroshtn();
          this.K_getrasedKren();
          this.K_getrasedGaranawa();
        }
      );
      if (this.state.k_navbar_froshtn === true) {
        this.setState({
          K_datatable: table.filter((el) => el.jwlla === "فرۆشتن"),
          K_datafilter: table.filter((el) => el.jwlla === "فرۆشتن"),
        });
      } else if (this.state.k_navbar_garanawa === true) {
        this.setState({
          K_datatable: table.filter((el) => el.jwlla === "گەڕانەوە"),
          K_datafilter: table.filter((el) => el.jwlla === "فرۆشتن"),
        });
      } else if (this.state.k_navbar_tomarkrdn === true) {
        this.setState({
          K_datatable: table.filter((el) => el.jwlla === "کڕین"),
          K_datafilter: table.filter((el) => el.jwlla === "فرۆشتن"),
        });
      }
    } catch (error) {
      alert("هەڵە هەیە لە بەروار");
    }
  };

  K_getrasedKren = () => {
    let subTotal = 0;
    this.state.K_datatable.filter((el) => el.jwlla === "کڕین").map(
      (item) => (subTotal += Number(item.froshtn))
    );
    const kartons = Number(subTotal.toFixed(2));

    this.setState(() => {
      return {
        K_rasedekartonKren: kartons,
      };
    });
  };

  K_getrasedFroshtn = () => {
    let subTotal = 0;
    this.state.K_datatable.filter((el) => el.jwlla === "فرۆشتن").map(
      (item) => (subTotal += Number(item.froshtn))
    );
    const kartons = Number(subTotal.toFixed(2));

    this.setState(() => {
      return {
        K_rasedekartonfroshtn: kartons,
      };
    });
  };
  K_getrasedGaranawa = () => {
    let subTotal = 0;
    this.state.K_datatable.filter((el) => el.jwlla === "گەڕانەوە").map(
      (item) => (subTotal += Number(item.froshtn))
    );
    const kartons = Number(subTotal.toFixed(2));

    this.setState(() => {
      return {
        K_rasedekartongarnawa: kartons,
      };
    });
  };
  K_grantr = () => {
    this.setState(
      {
        K_datatable: this.state.K_datafilter,
        K_yaksanbasfr: false,
        K_zyattrlasfr: true,
        K_kamtrlasfr: false,
      },
      () => {
        this.setState({
          K_datatable: this.state.K_datatable.filter((el) =>
            el.joripara === "دۆلار"
              ? el.froshtndolar > el.krin
              : el.froshtndolar > el.froshtndinar
          ),
        });
      }
    );
  };

  K_harzantr = () => {
    this.setState(
      {
        K_datatable: this.state.K_datafilter,
        K_yaksanbasfr: false,
        K_zyattrlasfr: false,
        K_kamtrlasfr: true,
      },
      () => {
        this.setState({
          K_datatable: this.state.K_datatable.filter((el) =>
            el.joripara === "دۆلار"
              ? el.froshtndolar < el.krin
              : el.froshtndolar < el.froshtndinar
          ),
        });
      }
    );
  };
  K_hamannrx = () => {
    this.setState(
      {
        K_datatable: this.state.K_datafilter,
        K_yaksanbasfr: true,
        K_zyattrlasfr: false,
        K_kamtrlasfr: false,
      },
      () => {
        this.setState({
          K_datatable: this.state.K_datatable.filter((el) =>
            el.joripara === "دۆلار"
              ? el.froshtndolar === el.krin
              : el.froshtndolar === el.froshtndinar
          ),
        });
      }
    );
  };

  K_gotowaslrentsearch = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchwasll?numberofwasl=${Number(zhwasl)}`,
      config
    );

    const data = res.data.pswla;
    this.setState(
      () => {
        return {
          R_wasl: data,
          R_search_wasl: true,
          R_zh_wasl: data.numberofwasl,
          R_nawe_barez: data.wakil,
          R_mob: data.phone,
          R_zh_barez: data.numberOfWakil,
          R_total: data.koalldolar,
          R_totalID: data.koalldinar,
          R_dashkan: data.dashkandolar,
          R_dashkanID: data.dashkandinar,
          R_dataTable: data.saleTable,
          R_joripara: data.joripara,
          R_frosth: data.froshtn,
          R_wargr: data.whossale,
          R_paraDanID: data.paraydrawdinar,
          R_paraDan$: data.paraydrawdolar,
          R_kartonakan: data.koykartonkan,
          R_kreysayara: data.kreysayra,
          R_kreykrekar: data.kreykrekar,
          R_koywaznkarton: data.waznakan,
          R_paraywasl:
            data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
          R_del_dis: true,
          R_up_froshtn: data.froshtn,
          R_date: data.date,
          R_togler: false,
          R_open: null,
          R_kateQarz: data.dateQazrz,
          R_rasidWakildinarNew:
            Number(data.qarzikonwakildinar) + Number(data.qarzinwedinar),
          R_rasidWakildolarNew:
            Number(data.qarzikonwakildolar) + Number(data.qarzinwedolar),
          R_rasidWakildinar: data.qarzikonwakildinar,
          R_rasidWakildolar: data.qarzikonwakildolar,
          R_note: data.note
        };
      },
      () => {
        if (this.state.R_frosth === "فرۆشتنی معمیل") {
          this.getWakilDataBySearchRent(e);
        }
      }
    );
  };

  K_gotowaslGaranawasearch = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchgaranawa?numberofwasl=${Number(zhwasl)}`,
      config
    );

    const data = res.data.pswla;
    if (data === null) {
      alert("ببورە ئەم پسولەیە بونی نیە");
    } else {
      this.setState(
        () => {
          return {
            G_wasl: data,
            G_search_wasl: true,
            G_zh_wasl: data.numberofwasl,
            G_nawe_barez: data.wakil,
            G_mob: data.phone,
            G_zh_barez: data.numberOfWakil,
            G_total: data.koalldolar,
            G_totalID: data.koalldinar,
            G_dashkan: data.dashkandolar,
            G_dashkanID: data.dashkandinar,
            G_dataTable: data.saleTable,
            G_joripara: data.joripara,
            G_frosht: data.froshtn,
            G_wargr: data.whossale,
            G_paraDanID: data.paraydrawdinar,
            G_paraDan$: data.paraydrawdolar,
            G_kartonakan: data.koykartonkan,
            G_kreysayara: data.kreysayra,
            G_kreykrekar: data.kreykrekar,
            G_koywaznkarton: data.waznakan,
            G_paraywasl:
              data.joripara === "دۆلار"
                ? data.qarzinwedolar
                : data.qarzinwedinar,
            G_del_dis: true,
            G_up_froshtn: data.froshtn,
            G_togler: false,
            G_date: data.date,
            G_rasidWakildinarNew:
              Number(data.qarzikonwakildinar) - Number(data.paraymawadinar),
            G_rasidWakildolarNew:
              Number(data.qarzikonwakildolar) - Number(data.paraymawadolar),
            G_rasidWakildinar: Number(data.qarzikonwakildinar),
            G_rasidWakildolar: Number(data.qarzikonwakildolar),
          };
        },
        () => {
          this.R_ClearError();
          if (this.state.G_frosht === "فرۆشتنی معمیل") {
            this.G_getWakilDataBySearchRent(e);
          }
        }
      );
    }
  };

  ///// end of raporty kalla

  ///// qarzy rastawxo functions
  QR_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            QR_openmodel: !this.state.QR_openmodel,
            QR_data: [],
            QR_rasiddinar: 0,
            QR_rasiddolar: 0,
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };
  QR_CloseModal = () => {
    this.setState(() => {
      return { QR_openmodel: false };
    });
  };

  QR_hamwqarzakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/waslrastawxo?from=${this.state.QR_from}&to=${this.state.QR_to}`,
        config
      );
      const footer = await instance.get(
        `/api/v1/qarzrastawxo?from=${this.state.QR_from}&to=${this.state.QR_to}`,
        config
      );
      this.setState(
        {
          QR_data: res.data.pswla,
        },
        () => {
          footer.data.pswla.forEach((el) => {
            this.setState({
              QR_rasiddolar: el.qarzdolar.toFixed(2),
              QR_rasiddinar: el.qarzdinar.toFixed(2),
            });
          });
        }
      );

      this.QR_CloseModal();
    } catch (error) {
      alert("هەڵە هەیە لە بەروار");
    }
  };

  QR_gotowaslrentsearch = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchwasll?numberofwasl=${Number(zhwasl)}`,
      config
    );

    const data = res.data.pswla;
    this.setState(
      () => {
        return {
          R_wasl: data,
          R_search_wasl: true,
          R_zh_wasl: data.numberofwasl,
          R_nawe_barez: data.wakil,
          R_mob: data.phone,
          R_zh_barez: data.numberOfWakil,
          R_total: data.koalldolar,
          R_totalID: data.koalldinar,
          R_dashkan: data.dashkandolar,
          R_dashkanID: data.dashkandinar,
          R_dataTable: data.saleTable,
          R_joripara: data.joripara,
          R_frosth: data.froshtn,
          R_wargr: data.whossale,
          R_paraDanID: data.paraydrawdinar,
          R_paraDan$: data.paraydrawdolar,
          R_kartonakan: data.koykartonkan,
          R_kreysayara: data.kreysayra,
          R_kreykrekar: data.kreykrekar,
          R_koywaznkarton: data.waznakan,
          R_paraywasl:
            data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
          R_del_dis: true,
          R_up_froshtn: data.froshtn,
          R_date: data.date,
          R_togler: false,
          R_open: null,
          R_kateQarz: data.dateQazrz,
          R_rasidWakildinarNew:
            Number(data.qarzikonwakildinar) + Number(data.qarzinwedinar),
          R_rasidWakildolarNew:
            Number(data.qarzikonwakildolar) + Number(data.qarzinwedolar),
          R_rasidWakildinar: data.qarzikonwakildinar,
          R_rasidWakildolar: data.qarzikonwakildolar,
          R_note: data.note
        };
      },
      () => {
        if (this.state.R_frosth === "فرۆشتنی معمیل") {
          this.getWakilDataBySearchRent(e);
        }
      }
    );
  };
  ///// end of qarzy rastawxo

  //functions of raporty chin

  RC_getnavbarRemembe = (e) => {
    e.preventDefault();
    if (this.state.RC_nrxeNavR !== "") {
      localStorage.setItem("rm", this.state.RC_nrxeNavR);
    }
    this.setState({ RC_R_disaebl: !this.state.RC_R_disaebl });
  };
  RC_getnavbarMatr = (e) => {
    e.preventDefault();
    if (this.state.RC_nrxNavM !== "") {
      localStorage.setItem("mm", this.state.RC_nrxNavM);
    }
    this.setState({ RC_M_desaebl: !this.state.RC_M_desaebl });
  };

  RC_getKOyGshtyRemembe = () => {
    let rezhaynawkarton = this.state.RC_rezhaynawKarton;
    let nrxeRemembe = this.state.RC_nrxRRR;
    let koygshtyremembe = rezhaynawkarton * nrxeRemembe;
    return koygshtyremembe.toFixed(2);
  };

  RC_getKOyGshty$ = () => {
    let koygshtyRemembe = this.RC_getKOyGshtyRemembe();
    let nrxenavRemembe = this.state.RC_nrxeNavR;
    let koygshty$ = koygshtyRemembe / nrxenavRemembe;
    return koygshty$.toFixed(2);
  };

  RRRchange = (e) => {
    this.setState(
      {
        RC_nrxRRR: e.target.value,
      },
      () => {
        this.setState({
          RC_koygshtyR: this.RC_getKOyGshtyRemembe(),
          RC_koygshty$: this.RC_getKOyGshty$(),
          RC_koymatr$: this.RC_getKOyGshtyMatr(),
          RC_nrxekotayeKala$: this.RC_nrxikotaykalla(),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        RC_nrxRRR: "0.",
      });
    }
  };

  RC_getKOyGshtyMatr = () => {
    let drezhi = this.state.RC_drezhe;
    let pani = this.state.RC_pane;
    let barzi = this.state.RC_barze;
    let nrximatr = this.state.RC_nrxNavM;

    let koygshtymatr = drezhi * pani * barzi * nrximatr;
    return koygshtymatr.toFixed(2);
  };

  RC_nrxikotaykalla = () => {
    let koymatr = this.RC_getKOyGshtyMatr();
    let kodolar = this.RC_getKOyGshty$();

    let nrxikotayykalla = Number(kodolar) + Number(koymatr);
    return nrxikotayykalla.toFixed(2);
  };

  RC_barzichnage = (e) => {
    this.setState(
      {
        RC_barze: e.target.value,
      },
      () => {
        this.setState({
          RC_koymatr$: this.RC_getKOyGshtyMatr(),
          RC_nrxekotayeKala$: this.RC_nrxikotaykalla(),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        RC_barze: "0.",
      });
    }
  };

  RC_nrxiyakdarzan = () => {
    let kotaykalla = this.state.RC_nrxekotayeKala$;
    let nawkarton = this.state.RC_rezhaynawKarton;
    let dabsh = this.state.RC_dabash;
    let darzan = kotaykalla / dabsh;

    return darzan.toFixed(2);
  };

  RC_saveToTable = (e) => {
    e.preventDefault();
    if (
      this.state.RC_nrxekotayeKala$ === "" ||
      this.state.RC_nrxekotayeKala$ === 0
    ) {
      alert("تکایە خانەکان پڕبکەوە");
    } else {
      const arr = this.state.RC_data;

      arr.push({
        _id: Math.random(),
        wasl: this.state.RC_zhmaaryPswla,
        karton: this.state.RC_nrxekotayeKala$,
        darzan: this.RC_nrxiyakdarzan(),
        date: Date.now(),
      });
      this.setState({
        RC_data: arr,
        RC_kren: this.RC_nrxiyakdarzan(),
        RC_kreneakcartoon: this.state.RC_nrxekotayeKala$,
      });
    }
  };

  RC_newChina = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (
      this.state.RC_nawikalla === 0 ||
      this.state.RC_zhmaaryDukan === 0 ||
      this.state.RC_zhmaaryPswla === 0
    ) {
      alert("تکایە خانەکان پربکەوە");
    } else {
      try {
        const data = {
          nameOfproduct: this.state.RC_nawikalla,
          dukan: this.state.RC_zhmaaryDukan,
          table: this.state.RC_data,
          dabash: this.state.RC_dabash,
          rezhanawkarton: this.state.RC_rezhaynawKarton,
          rrr: this.state.RC_nrxRRR,
          koygshtyR: this.state.RC_koygshtyR,
          koygshtyDolar: this.state.RC_koygshty$,
          drezhi: this.state.RC_drezhe,
          pane: this.state.RC_pane,
          barzi: this.state.RC_barze,
          koymatrdolar: this.state.RC_koymatr$,
          nrxikotayykalla: this.state.RC_nrxekotayeKala$,
          wasl: this.state.RC_zhmaaryPswla,
        };
        const res = await instance.post(`/api/v1/newchina`, data, config);
        this.setState({
          RC_wasl: res.data.newwasl,
          RC_open: false,
          RC_save: true,
          RC_dis: true,
          RC_updatekala: false,
        });
      } catch (error) {
        alert("هەڵە هەیە لە خانەکان");
      }
    }
  };

  RC_searcheKala = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const mawad = await instance.get(
        `/api/v1/searchtoproduct?code=${this.state.RC_zhmarayKala}`,
        config
      );

      this.setState({
        RC_nawikalla: mawad.data.product.nameofproduct,
        RC_froshtn: mawad.data.product.saleDolar,
        RC_qazanj: mawad.data.product.qazanj,
      });
    } catch (error) {
      alert("هەڵە هەیە لە خانەکان");
    }
  };

  RC_search = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    this.toanotherInput(e);
    this.RC_searcheKala();
    try {
      const res = await instance.get(
        `/api/v1/searchchina/${this.state.RC_zhmarayKala}`,
        config
      );
      const kalla = await instance.get(
        `/api/v1/searchtoproduct?code=${this.state.RC_zhmarayKala}`,
        config
      );
      const mawad = await instance.get(
        `/api/v1/searchtoproduct?code=${this.state.RC_zhmarayKala}`,
        config
      );

      const data = res.data.product;
      this.setState({
        RC_search_wasl: true,
        RC_wasl: data,
        RC_data: data.table,
        RC_zhmaaryDukan: data.dukan,
        RC_dabash: data.dabash,
        RC_rezhaynawKarton: data.rezhanawkarton,
        RC_nrxRRR: data.rrr,
        RC_koygshtyR: data.koygshtyR,
        RC_kalladata: kalla.data.product,
        RC_open: false,
        RC_save: false,
        RC_koygshty$: data.koygshtyDolar,
        RC_drezhe: data.drezhi,
        RC_barze: data.barzi,
        RC_pane: data.pane,
        RC_koymatr$: data.koymatrdolar,
        RC_nrxekotayeKala$: data.nrxikotayykalla,
        RC_updatekala: false,
        RC_zhmaaryPswla: data.wasl,
        RC_dis: true,
        RC_nawikalla: mawad.data.product.nameofproduct,
      });
    } catch (error) {
      alert("هەڵە هەیە لە خانەکان");
    }
  };

  RC_update = async (e, id) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (
      this.state.RC_nawikalla === 0 ||
      this.state.RC_zhmaaryDukan === 0 ||
      this.state.RC_zhmaaryPswla === 0
    ) {
      alert("تکایە خانەکان پربکەوە");
    } else {
      try {
        const data = {
          nameOfproduct: this.state.RC_nawikalla,
          dukan: this.state.RC_zhmaaryDukan,
          table: this.state.RC_data,
          dabash: this.state.RC_dabash,
          rezhanawkarton: this.state.RC_rezhaynawKarton,
          rrr: this.state.RC_nrxRRR,
          koygshtyR: this.state.RC_koygshtyR,
          koygshtyDolar: this.state.RC_koygshty$,
          drezhi: this.state.RC_drezhe,
          pane: this.state.RC_pane,
          barzi: this.state.RC_barze,
          koymatrdolar: this.state.RC_koymatr$,
          nrxikotayykalla: this.state.RC_nrxekotayeKala$,
          wasl: this.state.RC_zhmaaryPswla,
        };
        const res = await instance.put(
          `/api/v1/updatechina/${id}`,
          data,
          config
        );

        this.setState({
          RC_open: false,
          RC_wasl: res.data.update,
          RC_save: true,
          RC_dis: true,
        });
      } catch (error) {
        alert("هەڵە هەیە لە خانەکان");
      }
    }
  };

  RC_qazanjKalla = () => {
    let kren = this.state.RC_kren;
    let froshtn = this.state.RC_froshtn;
    let darzan = this.state.RC_dabash;
    let qazanj = (froshtn - kren) * darzan;

    return qazanj.toFixed(2);
  };
  RC_froshtneakKarton = () => {
    let froshtn = this.state.RC_froshtn;
    let darzan = this.state.RC_dabash;
    let eakcartoon = froshtn * darzan;

    return eakcartoon.toFixed(2);
  };
  RC_froshtnChange = (e) => {
    this.setState(
      {
        RC_froshtn: e.target.value,
      },
      () => {
        this.setState({
          RC_qazanj: this.RC_qazanjKalla(),
          RC_froshtneakKarton: this.RC_froshtneakKarton(),
        });
      }
    );

    if (e.target.value === ".") {
      this.setState({
        RC_barze: "0.",
      });
    }
  };

  RC_nrxdarzan = () => {
    const dabash = this.state.RC_rezhaynawKarton / this.state.RC_dabash;
    const nrxikrenkarton = this.state.RC_nrxekotayeKala$;
    const qazanj = this.state.RC_qazanj;

    const nrxidarzan = (Number(qazanj) + Number(nrxikrenkarton)) / dabash;
    return Number(nrxidarzan).toFixed(2);
  };

  RC_nrxkarton = () => {
    const nrxikrenkarton = this.state.RC_nrxekotayeKala$;
    const qazanj = this.state.RC_qazanj;

    const nrvxeakkarton = Number(qazanj) + Number(nrxikrenkarton);
    return Number(nrvxeakkarton).toFixed(2);
  };

  RC_qazanjchange = (e) => {
    this.setState(
      {
        RC_qazanj: e.target.value,
      },
      () => {
        this.setState({
          RC_froshtn: this.RC_nrxdarzan(),
          RC_froshtneakKarton: this.RC_nrxkarton(),
        });
      }
    );
  };

  RC_updateNawKalla = async (e, id) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const data = {
        price: this.state.RC_kren,
        saleDolar: this.state.RC_froshtn,
        qazanj: this.state.RC_qazanj,
        saleDinar: parseFloat(this.state.RC_froshtnID.replace(/,/g, "")),
        saleDolar3: this.state.RC_kamtrlaNewkarton,
        saleDolar2: this.state.RC_kamtrlaDadarzan,
      };
      const res = await instance.put(
        `/api/v1/updateProduct/${id}`,
        data,
        config
      );

      this.setState({
        RC_open: false,
        RC_updatekala: true,
      });
    } catch (error) {
      alert("هەڵە هەیە لە خانەکان");
    }
  };

  RC_openchina = async (e) => {
    e.preventDefault();

    this.setState({
      RC_open: true,
      RC_dis: false,
    });
  };

  RC_matrremembychnage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.setState({
          RC_qazanj: this.RC_qazanjKalla(),
          RC_koymatr$: this.RC_getKOyGshtyMatr(),
          RC_nrxekotayeKala$: this.RC_nrxikotaykalla(),
          RC_koygshtyR: this.RC_getKOyGshtyRemembe(),
          RC_koygshty$: this.RC_getKOyGshty$(),
        });
      }
    );
  };
  RC_newpage = (e) => {
    e.preventDefault();
    this.setState({
      RC_data: [],
      RC_nawikalla: "",
      RC_zhmarayKala: 0,
      RC_zhmaaryDukan: 0,
      RC_zhmaaryPswla: 0,
      RC_dabash: 0,
      RC_rezhaynawKarton: 0,
      RC_nrxRRR: 0,
      RC_koygshtyR: 0,
      RC_koygshty$: 0,
      RC_drezhe: 0,
      RC_pane: 0,
      RC_barze: 0,
      RC_koymatr$: 0,
      RC_nrxekotayeKala$: 0,
      RC_kren: 0,
      RC_froshtn: 0,
      RC_qazanj: 0,
      RC_R_disaebl: true,
      RC_M_desaebl: true,
      RC_search_wasl: null,
      RC_wasl: null,
      RC_open: false,
      RC_save: false,
      RC_kreneakcartoon: 0,
      RC_froshtneakKarton: 0,
      RC_dis: false,
      RC_froshtnID: 0,
      RC_kamtrlaDadarzan: 0,
      RC_kamtrlaNewkarton: 0,
    });
  };

  RC_clickOnDelete = async (id) => {
    this.setState({
      RC_data: this.state.RC_data.filter((r) => r._id !== id),
    });
  };
  RC_setInputEmpty = () => {
    this.setState({
      RC_kren: 0,
      RC_froshtn: 0,
      RC_froshtnID: 0,
      RC_froshtneakKarton: 0,
      RC_froshtneakcartoon: 0,
      RC_kamtrlaDadarzan: 0,
      RC_kamtrlaNewkarton: 0,
      RC_qazanj: 0,
      RC_kreneakcartoon: 0,
    });
  };

  // raporty kalay nafroshraw
  KN_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            KN_openmodel: !this.state.KN_openmodel,
            KN_data: [],
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  KN_CloseModal = () => {
    this.setState(() => {
      return { KN_openmodel: false };
    });
  };

  KN_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/kallaynafroshraw?from=${this.state.KN_from}&to=${this.state.KN_to}`,
        config
      );
      this.setState({
        KN_data: res.data.mawa,
      });
      this.KN_CloseModal();
    } catch (error) {
      alert("بەروار داخل بکە");
    }
  };

  // Raporty Paray Nagaranawa la Wakel

  RP_ParayNahatu = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/nagranawayqarz?from=${this.state.RP_from}&to=${this.state.RP_to}`,
        config
      );
      this.setState({
        RP_dataPara: res.data.nadraw,
      });
      this.RP_CloseModal();
    } catch (error) {
      alert("بەروار داخل بکە");
    }
  };

  RP_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            RP_openmodel: !this.state.RP_openmodel,
            RP_dataPara: [],
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RP_CloseModal = () => {
    this.setState(() => {
      return { RP_openmodel: false };
    });
  };

  // functions of Add People
  Ap_setWakilEmpty = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      Ap_mob: 0,
      Ap_naw: "",
      Ap_jor: null,
      Ap_code: 0,
    });
  };

  //// get wakilakna
  Ap_getkasakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/getpeopleakan`, config);

    this.setState({
      Ap_kasakan: res.data.peoples,
    });
  };

  Ap_tofirstInput = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 3].focus();
    this.Ap_onSaveWakel(e);
  };

  Ap_onSaveWakel = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    try {
      if (
        this.state.Ap_code === 0 ||
        this.state.Ap_mob === 0 ||
        this.state.Ap_naw === ""
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      }
      let data;
      data = {
        code: this.state.Ap_code,
        Name: this.state.Ap_naw,
        phone: this.state.Ap_mob,
        jor: this.state.Ap_jor,
        paradolar: 0,
        paradinar: 0,
      };
      const res = await instance.post("/api/v1/newaddpeople", data, config);

      this.setState({
        Ap_data: [...this.state.Ap_data, res.data.newp],
      });
      this.Ap_setWakilEmpty(e);
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  // delete wakel
  Ap_clickOnDeleteWakel = async (id) => {
    this.setState({
      Ap_data: this.state.Ap_data.filter((r) => r._id !== id),
    });
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    await instance.delete(`/api/v1/deletepeople/${id}`, config);
  };

  ////// wakil tabel data
  Ap_getWakiltableData = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/getpeopleakan`, config);

    this.setState({
      Ap_data: res.data.peoples,
    });
  };

  // update peoples

  Ap_updtae_Wakil = async (e, peo) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    let data;
    data = {
      code: this.state.Ap_code,
      Name: this.state.Ap_naw,
      phone: this.state.Ap_mob,
      jor: this.state.Ap_jor,
    };
    const res = await instance.put(
      `/api/v1/updatepeople/${peo._id}`,
      data,
      config
    );

    this.setState({
      Ap_data: this.state.Ap_data.map((people) =>
        people._id === res.data.update._id ? res.data.update : people
      ),
    });
    this.clear_edit(e);
  };

  Ap_getWakilDataBySearchWakil = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    this.toanotherInput(e);
    const res = await instance.get(
      `/api/v1/searchpeople?code=${Number(this.state.Ap_code)}&&Name=${
        this.state.Ap_naw
      }&&phone=${Number(this.state.Ap_mob)}`,
      config
    );

    const data = res.data.peoples;
    if (data === null) {
    } else {
      alert("ئەم داخیل کردنە دووبارەیە");
    }
  };

  //// show rasid
  Ap_showrasid = async (e, userid) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    if (this.state.Ap_show === false) {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else {
        this.setState({ Ap_show: !this.state.Ap_show });
      }
    } else {
      this.setState({ Ap_show: !this.state.Ap_show });
    }
  };

  // functions of Masrufat

  Ms_setWakilEmpty = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      Ms_naw: "",
      Ms_koypara: "",
      Ms_draw: null,
    });
  };

  Ms_tofirstInput = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 2].focus();
    this.Ms_onSaveWakel(e);
  };

  Ms_onSaveWakel = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    try {
      if (this.state.Ms_naw === "" || this.state.Ms_koypara === 0) {
        alert("تکایە خانەکان پڕبکەوە");
      }
      let data;
      data = {
        shwen: this.state.Ms_naw,
        koypara: parseFloat(this.state.Ms_koypara.toString().replace(/,/g, "")),
        joripara: this.state.Ms_draw,
      };
      const res = await instance.post("/api/v1/newMasrwfat", data, config);

      this.setState(
        {
          Ms_data: [...this.state.Ms_data, res.data.newm],
        },
        async () => {
          this.Ms_getkoymasrwfdinar();
          this.Ms_getkoymasrwfdolar();
          ///////

          let bardastdata;
          const respb = await instance.get(`/api/v1/paraybardasakan`, config);
          if (res.data.newm.joripara === "دۆلار") {
            bardastdata = {
              paraybardastdolar:
                Number(respb.data.paraybardastakan.paraybardastdolar) -
                res.data.newm.koypara,
            };
          } else if (res.data.newm.joripara === "دینار") {
            bardastdata = {
              paraybardastdinar:
                Number(respb.data.paraybardastakan.paraybardastdinar) -
                res.data.newm.koypara,
            };
          }
          await instance.put(
            `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
            bardastdata,
            config
          );

          /////////////
        }
      );
      this.Ms_setWakilEmpty(e);
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  Ms_getkoymasrwfdolar = () => {
    let subTotal = 0;
    this.state.Ms_data.map((item) => {
      if (item.joripara === "دۆلار") {
        subTotal += Number(item.koypara);
      }
    });
    const koyparakandolar = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        Ms_koymasruf$: koyparakandolar,
      };
    });
  };

  Ms_getkoymasrwfdinar = () => {
    let subTotal = 0;
    this.state.Ms_data.map((item) => {
      if (item.joripara === "دینار") {
        subTotal += Number(item.koypara);
      }
    });
    const koyparakandinar = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        Ms_koymasrufID: koyparakandinar,
      };
    });
  };

  // delete wakel
  Ms_clickOnDeleteWakel = async (id) => {
    this.setState(
      {
        Ms_data: this.state.Ms_data.filter((r) => r._id !== id),
      },
      async () => {
        this.Ms_getkoymasrwfdinar();
        this.Ms_getkoymasrwfdolar();
      }
    );
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.delete(`/api/v1/deletemasrwf/${id}`, config);
    ///////
    let bardastdata;
    const respb = await instance.get(`/api/v1/paraybardasakan`, config);
    if (res.data.dl.joripara === "دۆلار") {
      bardastdata = {
        paraybardastdolar:
          Number(respb.data.paraybardastakan.paraybardastdolar) +
          res.data.dl.koypara,
      };
    } else if (res.data.dl.joripara === "دینار") {
      bardastdata = {
        paraybardastdinar:
          Number(respb.data.paraybardastakan.paraybardastdinar) +
          res.data.dl.koypara,
      };
    }
    await instance.put(
      `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
      bardastdata,
      config
    );

    /////////////
  };

  ////// wakil tabel data
  Ms_getWakiltableData = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/getmasrwfakan`, config);

    this.setState({
      Ms_data: res.data.masrwfs,
    });
  };

  // update peoples

  Ms_updtae_Wakil = async (e, mas) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    let data;
    data = {
      shwen: this.state.Ms_naw,
      koypara: parseFloat(this.state.Ms_koypara.toString().replace(/,/g, "")),
      joripara: this.state.Ms_draw,
    };
    const res = await instance.put(
      `/api/v1/updatemasrwf/${mas._id}`,
      data,
      config
    );

    this.setState(
      {
        Ms_data: this.state.Ms_data.map((masrufat) =>
          masrufat._id === res.data.update._id ? res.data.update : masrufat
        ),
      },
      () => {
        this.Ms_getkoymasrwfdinar();
        this.Ms_getkoymasrwfdolar();
      }
    );
    this.clear_edit(e);
  };

  //// show rasid
  Ms_showrasid = async (e, userid) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    if (this.state.Ms_show === false) {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else {
        this.setState({ Ms_show: !this.state.Ms_show }, () => {
          this.Ms_getkoymasrwfdinar();
          this.Ms_getkoymasrwfdolar();
        });
      }
    } else {
      this.setState({
        Ms_show: !this.state.Ms_show,
        Ms_koymasruf$: 0,
        Ms_koymasrufID: 0,
      });
    }
  };

  ////functions of paradan
  PD_getPrint = () => {
    window.setTimeout("window.print()", 2000);
  };

  PD_openUpdate = async (userid, e, id) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const { value: text } = await Swal.fire({
      input: "password",
      inputLabel: "Password",
      showLoaderOnConfirm: true,
      inputPlaceholder: "وشەی نهێنی داخیل بکە...",
      inputAttributes: {
        "aria-label": "وشەی نهێنی داخیل بکە",
      },
      showCancelButton: true,
    });
    const data = {
      password: text,
    };
    console.log("id", id);
    const pass = await instance.post(
      `api/v1/openParadan/${this.state.PD_wasl._id}?id=${userid}`,
      data
    );

    if (pass.data.status === false) {
      alert("وشەی نهێنی هەڵەیە");
    } else if (pass.data.status === true) {
      this.setState({
        PD_del_dis: null,
        PD_open: true,
      });
    }
  };
  /// barwar toogle para watrgrtn

  PD_inputtogler = (e) => {
    e.preventDefault();
    this.setState({
      PD_toggler: !this.state.PD_toggler,
      Pd_barwar: "",
    });
  };
  ////save wasl para
  PD_onSaveMoney = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (
        this.state.PD_nawebarez === "" ||
        this.state.PD_wasleShareka === 0 ||
        this.state.PD_zhmaraybarez === 0 ||
        this.state.PD_nawewargr === null ||
        this.state.PD_brepara === 0 ||
        this.state.PD_joredraw === null
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          kascode: this.state.PD_zhmaraybarez,
          nawekas: this.state.PD_nawebarez,
          briparabadolar:
            this.state.PD_joredraw === "دۆلار"
              ? parseFloat(this.state.PD_brepara.toString().replace(/,/g, ""))
              : 0,
          briparabadinar:
            this.state.PD_joredraw === "دینار"
              ? parseFloat(this.state.PD_brepara.toString().replace(/,/g, ""))
              : 0,
          nawiwargr: this.state.PD_nawewargr,
          joripara: this.state.PD_joredraw,
          note: this.state.PD_note,
          date: this.state.Pd_barwar === "" ? Date.now : this.state.Pd_barwar,
          zhmaraaywasl: this.state.PD_wasleShareka,
          rasidkondolar: this.state.PD_qardekon$,
          rasiddkoninar: this.state.PD_qardekonID,
        };
        const res = await instance.post("/api/v1/newParadana", data, config);

        this.setState(
          {
            PD_save: true,
            PD_zhamara: res.data.paradan.zhmara,
            Pd_barwar: res.data.paradan.date,
            PD_del_dis: true,
            //    P_paraywasl:res.data.wargrtn.paradwaykmkrdn,
            PD_wasl: res.data.paradan,
            PD_toggler: false,
          },
          async () => {
            this.PB_gegtparaybardast();
          }
        );
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  ////// update Mony
  PD_updtate_para = async (e, para, NOW) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    try {
      if (
        this.state.PD_brepara === "" ||
        this.state.PD_brepara === 0 ||
        this.state.PD_nawebarez === "" ||
        this.state.Pd_barwar === "" ||
        this.state.PD_wasleShareka === 0 ||
        this.state.PD_wasleShareka === ""
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          kascode: this.state.PD_zhmaraybarez,
          nawekas: this.state.PD_nawebarez,
          briparabadolar:
            this.state.PD_joredraw === "دۆلار"
              ? parseFloat(this.state.PD_brepara.toString().replace(/,/g, ""))
              : 0,
          briparabadinar:
            this.state.PD_joredraw === "دینار"
              ? parseFloat(this.state.PD_brepara.toString().replace(/,/g, ""))
              : 0,
          nawiwargr: this.state.PD_nawewargr,
          joripara: this.state.PD_joredraw,
          note: this.state.PD_note,
          date: this.state.Pd_barwar === "" ? Date.now : this.state.Pd_barwar,
          zhmaraaywasl: this.state.PD_wasleShareka,
          rasidkondolar: this.state.PD_qardekon$,
          rasiddkoninar: this.state.PD_qardekonID,
        };
        const res = await instance.put(
          `/api/v1/updateparadan/${para._id}`,
          data,
          config
        );

        this.setState({
          PD_open: null,
          PD_del_dis: true,
          PD_toggler: false,
        });
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  ////search name of wakil
  PD_getkasDataBySearchMoney = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchpeople?code=${this.state.PD_zhmaraybarez}&&Name=${this.state.PD_nawebarez}`,
      config
    );

    const data = res.data.peoples;
    if (data === null) {
      alert("ببورە ئەم بەڕێزە بونی نیە");
    } else {
      this.setState(() => {
        return {
          PD_zhmaraybarez: data.code,
          PD_nawebarez: data.Name,
          PD_qardekon$: data.paradolar,
          PD_qardekonID: data.paradinar,
        };
      });
    }
  };

  PD_getParaDataBySearchMoney = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchpardan?zhmara=${this.state.PD_zhamara}`,
      config
    );

    const data = res.data.parakan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ PD_zhmaraybarez: 0 });
    } else {
      this.setState(() => {
        return {
          PD_search_wasl: true,
          PD_zhamara: data.zhmara,
          PD_nawebarez: data.nawekas,
          Pd_barwar: data.date,
          PD_zhmaraybarez: data.kascode,
          PD_brepara:
            data.joripara === "دینار"
              ? data.briparabadinar
              : data.briparabadolar,
          PD_joredraw: data.joripara,
          PD_nawewargr: data.nawiwargr,
          PD_note: data.note,
          PD_del_dis: true,
          PD_wasl: data,
          PD_toggler: false,
          PD_wasleShareka: data.zhmaraaywasl,
          PD_qardekon$: data.rasidkondolar,
          PD_qardekonID: data.rasiddkoninar,
        };
      });
    }
  };

  PD_peshandaniQarzyPeshw = () => {
    if (this.state.PD_joredraw == "دۆلار") {
      let paraykon = this.state.PD_qardekon$;
      let parayesta =
        Number(paraykon) -
        parseFloat(this.state.PD_brepara.toString().replace(/,/g, ""));
      return parayesta.toFixed(2);
    } else if (this.state.PD_joredraw == "دینار") {
      let paraykon = this.state.PD_qardekonID;
      let parayesta =
        Number(paraykon) -
        parseFloat(this.state.PD_brepara.toString().replace(/,/g, ""));
      return parayesta.toFixed(2);
    }
  };
  PD_getParakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/paradanakan`, config);

    this.setState({
      PD_zhamara: res.data.paradanakan.length + 1,
    });
  };

  PD_setSearchMonyWakilEmpty = (e) => {
    e.preventDefault();
    this.setState({
      PD_zhmaraybarez: 0,
      PD_nawebarez: "",
      PD_qardekon$: 0,
      PD_qardekonID: 0,
    });
  };

  PD_createNewMony = () => {
    this.setState(
      {
        PD_zhmaraybarez: 0,
        PD_nawebarez: "",
        PD_brepara: 0,
        PD_note: "",
        PD_search_wasl: null,
        PD_save: null,
        PD_joredraw: null,
        PD_nawewargr: null,
        PD_open: null,
        PD_del_dis: null,
        PD_page: this.state.PD_zhamara,
        PD_wasl: null,
        PD_qardekon$: 0,
        PD_qardekonID: 0,
        PD_isChecked: false,
        PD_wasleShareka: 0,
      },
      () => {
        this.PD_getParakan();
        this.PB_gegtparaybardast();
      }
    );
  };

  PD_getIsChecked = (e) => {
    let checked = e.target.checked;
    if (checked) {
      this.setState({ PD_isChecked: true });
    } else {
      this.setState({ PD_isChecked: false });
    }
  };

  PD_pageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.PD_zhamara + 1;
      const res = await instance.get(
        `/api/v1/pagepardan?page=${page}&limit=1`,
        config
      );

      const data = res.data.paras;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            PD_search_wasl: true,
            PD_zhamara: data.zhmara,
            PD_nawebarez: data.nawekas,
            Pd_barwar: data.date,
            PD_zhmaraybarez: data.kascode,
            PD_brepara:
              data.joripara === "دینار"
                ? data.briparabadinar
                : data.briparabadolar,
            PD_joredraw: data.joripara,
            PD_nawewargr: data.nawiwargr,
            PD_note: data.note,
            PD_del_dis: true,
            PD_wasl: data,
            PD_toggler: false,
            PD_wasleShareka: data.zhmaraaywasl,
            PD_qardekon$: data.rasidkondolar,
            PD_qardekonID: data.rasiddkoninar,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  PD_pageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page;
      this.state.PD_zhamara <= 1
        ? (page = 1)
        : (page = this.state.PD_zhamara - 1);
      const res = await instance.get(
        `/api/v1/pagepardan?page=${page}&limit=1`,
        config
      );

      const data = res.data.paras;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            PD_search_wasl: true,
            PD_zhamara: data.zhmara,
            PD_nawebarez: data.nawekas,
            Pd_barwar: data.date,
            PD_zhmaraybarez: data.kascode,
            PD_brepara:
              data.joripara === "دینار"
                ? data.briparabadinar
                : data.briparabadolar,
            PD_joredraw: data.joripara,
            PD_nawewargr: data.nawiwargr,
            PD_note: data.note,
            PD_del_dis: true,
            PD_wasleShareka: data.zhmaraaywasl,
            PD_wasl: data,
            PD_toggler: false,
            PD_qardekon$: data.rasidkondolar,
            PD_qardekonID: data.rasiddkoninar,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };
  /////end of functions para dan

  ////functions of paradarhenan
  PM_getPrint = () => {
    window.setTimeout("window.print()", 2000);
  };

  PM_openUpdate = async (userid, e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const { value: text } = await Swal.fire({
      input: "password",
      inputLabel: "Password",
      showLoaderOnConfirm: true,
      inputPlaceholder: "وشەی نهێنی داخیل بکە...",
      inputAttributes: {
        "aria-label": "وشەی نهێنی داخیل بکە",
      },
      showCancelButton: true,
    });
    const data = {
      password: text,
    };
    const pass = await instance.post(`api/v1/open?id=${userid}`, data, config);

    if (pass.data.status === false) {
      alert("وشەی نهێنی هەڵەیە");
    } else if (pass.data.status === true) {
      this.setState(
        {
          PM_del_dis: null,
          PM_open: true,
        },
        async () => {
          let bardastdata;
          const respb = await instance.get(`/api/v1/paraybardasakan`, config);
          if (this.state.PM_joredraw === "دۆلار") {
            bardastdata = {
              paraybardastdolar:
                Number(respb.data.paraybardastakan.paraybardastdolar) +
                parseFloat(this.state.PM_brepara.toString().replace(/,/g, "")),
            };
          } else if (this.state.PM_joredraw === "دینار") {
            bardastdata = {
              paraybardastdinar:
                Number(respb.data.paraybardastakan.paraybardastdinar) +
                parseFloat(this.state.PM_brepara.toString().replace(/,/g, "")),
            };
          }
          await instance.put(
            `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
            bardastdata,
            config
          );
        }
      );
    }
  };

  PM_inputtogler = (e) => {
    e.preventDefault();
    this.setState({
      PM_toggler: !this.state.PM_toggler,
      PM_barwar: "",
    });
  };

  PM_onSaveMoney = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (
        this.state.PM_nawewargr === null ||
        this.state.PM_brepara === 0 ||
        this.state.PM_joredraw === null ||
        this.state.PM_nawikas === ""
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          briparabadolar:
            this.state.PM_joredraw === "دۆلار"
              ? parseFloat(this.state.PM_brepara.toString().replace(/,/g, ""))
              : 0,
          briparabadinar:
            this.state.PM_joredraw === "دینار"
              ? parseFloat(this.state.PM_brepara.toString().replace(/,/g, ""))
              : 0,
          nawiwargr: this.state.PM_nawewargr,
          joripara: this.state.PM_joredraw,
          note: this.state.PM_note,
          date: this.state.PM_barwar === "" ? Date.now : this.state.PM_barwar,
          nawikas: this.state.PM_nawikas,
        };
        const res = await instance.post(
          "/api/v1/newParadarhenan",
          data,
          config
        );

        this.setState(
          {
            PM_save: true,
            PM_zhamara: res.data.paradarhenan.zhmara,
            PM_barwar: res.data.paradarhenan.date,
            PM_del_dis: true,
            //    P_paraywasl:res.data.wargrtn.paradwaykmkrdn,
            PM_wasl: res.data.paradarhenan,
            PM_toggler: false,
          },
          async () => {
            let bardastdata;
            const respb = await instance.get(`/api/v1/paraybardasakan`, config);
            if (this.state.PM_joredraw === "دۆلار") {
              bardastdata = {
                paraybardastdolar:
                  Number(respb.data.paraybardastakan.paraybardastdolar) -
                  parseFloat(
                    this.state.PM_brepara.toString().replace(/,/g, "")
                  ),
              };
            } else if (this.state.PM_joredraw === "دینار") {
              bardastdata = {
                paraybardastdinar:
                  Number(respb.data.paraybardastakan.paraybardastdinar) -
                  parseFloat(
                    this.state.PM_brepara.toString().replace(/,/g, "")
                  ),
              };
            }
            await instance.put(
              `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
              bardastdata,
              config
            );
          }
        );
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  ////// update Mony

  PM_updtate_para = async (e, para) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    try {
      if (
        this.state.PM_brepara === "" ||
        this.state.PM_brepara === 0 ||
        this.state.PM_barwar === "" ||
        this.state.PM_nawikas === ""
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          briparabadolar:
            this.state.PM_joredraw === "دۆلار"
              ? parseFloat(this.state.PM_brepara.toString().replace(/,/g, ""))
              : 0,
          briparabadinar:
            this.state.PM_joredraw === "دینار"
              ? parseFloat(this.state.PM_brepara.toString().replace(/,/g, ""))
              : 0,
          nawiwargr: this.state.PM_nawewargr,
          joripara: this.state.PM_joredraw,
          note: this.state.PM_note,
          date: this.state.PM_barwar === "" ? Date.now : this.state.PM_barwar,
          nawikas: this.state.PM_nawikas,
        };
        const res = await instance.put(
          `/api/v1/updateparadarhenan/${para._id}`,
          data,
          config
        );

        this.setState(
          {
            PM_open: null,
            PM_del_dis: true,
            PM_toggler: false,
          },
          async () => {
            let bardastdata;
            const respb = await instance.get(`/api/v1/paraybardasakan`, config);
            if (this.state.PM_joredraw === "دۆلار") {
              bardastdata = {
                paraybardastdolar:
                  Number(respb.data.paraybardastakan.paraybardastdolar) -
                  parseFloat(
                    this.state.PM_brepara.toString().replace(/,/g, "")
                  ),
              };
            } else if (this.state.PM_joredraw === "دینار") {
              bardastdata = {
                paraybardastdinar:
                  Number(respb.data.paraybardastakan.paraybardastdinar) -
                  parseFloat(
                    this.state.PM_brepara.toString().replace(/,/g, "")
                  ),
              };
            }
            await instance.put(
              `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
              bardastdata,
              config
            );
          }
        );
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  PM_getParaDataBySearchMoney = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchparadarhenan?zhmara=${this.state.PM_zhamara}`,
      config
    );

    const data = res.data.parakan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ PD_zhmaraybarez: 0 });
    } else {
      this.setState(() => {
        return {
          PM_search_wasl: true,
          PM_zhamara: data.zhmara,
          PM_barwar: data.date,
          PM_brepara:
            data.joripara === "دینار"
              ? data.briparabadinar
              : data.briparabadolar,
          PM_joredraw: data.joripara,
          PM_nawewargr: data.nawiwargr,
          PM_note: data.note,
          PM_del_dis: true,
          PM_nawikas: data.nawikas,
          PM_wasl: data,
          PM_toggler: false,
        };
      });
    }
  };

  PM_getParakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/paradarhenanakan`, config);

    this.setState({
      PM_zhamara: res.data.paradarhenanakan.length + 1,
    });
  };

  PM_createNewMony = () => {
    this.setState(
      {
        PM_brepara: 0,
        PM_note: "",
        PM_search_wasl: null,
        PM_save: null,
        PM_joredraw: null,
        PM_nawewargr: null,
        PM_open: null,
        PM_del_dis: null,
        PM_page: this.state.PM_zhamara,
        PM_wasl: null,
        PM_nawikas: "",
      },
      () => {
        this.PM_getParakan();
        this.PB_gegtparaybardast();
      }
    );
  };

  PM_pageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.PM_zhamara + 1;
      const res = await instance.get(
        `/api/v1/pageparadarhenan?page=${page}&limit=1`,
        config
      );

      const data = res.data.paras;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            PM_search_wasl: true,
            PM_zhamara: data.zhmara,
            PM_barwar: data.date,
            PM_brepara:
              data.joripara === "دینار"
                ? data.briparabadinar
                : data.briparabadolar,
            PM_joredraw: data.joripara,
            PM_nawewargr: data.nawiwargr,
            PM_note: data.note,
            PM_del_dis: true,
            PM_nawikas: data.nawikas,
            PM_wasl: data,
            PM_toggler: false,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  PM_pageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page;
      this.state.PM_zhamara <= 1
        ? (page = 1)
        : (page = this.state.PM_zhamara - 1);
      const res = await instance.get(
        `/api/v1/pageparadarhenan?page=${page}&limit=1`,
        config
      );

      const data = res.data.paras;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            PM_search_wasl: true,
            PM_zhamara: data.zhmara,
            PM_barwar: data.date,
            PM_brepara:
              data.joripara === "دینار"
                ? data.briparabadinar
                : data.briparabadolar,
            PM_joredraw: data.joripara,
            PM_nawewargr: data.nawiwargr,
            PM_note: data.note,
            PM_del_dis: true,
            PM_nawikas: data.nawikas,
            PM_wasl: data,
            PM_toggler: false,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };
  /////end of functions para darhenan

  ///////paray mandub functions

  MM_getPrint = () => {
    window.setTimeout("window.print()", 2000);
  };

  MM_openUpdate = async (userid, e, id) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const { value: text } = await Swal.fire({
      input: "password",
      inputLabel: "Password",
      showLoaderOnConfirm: true,
      inputPlaceholder: "وشەی نهێنی داخیل بکە...",
      inputAttributes: {
        "aria-label": "وشەی نهێنی داخیل بکە",
      },
      showCancelButton: true,
    });
    const data = {
      password: text,
    };
    const pass = await instance.post(
      `api/v1/openMandub/${id._id}?id=${userid}`,
      data
    );

    if (pass.data.status === false) {
      alert("وشەی نهێنی هەڵەیە");
    } else if (pass.data.status === true) {
      this.setState({
        MM_del_dis: null,
        MM_open: true,
      });
    }
  };
  /// barwar toogle paray mandub

  MM_inputtogler = (e) => {
    e.preventDefault();
    this.setState({
      MM_toggler: !this.state.MM_toggler,
      MM_barwar: "",
    });
  };

  ////save paray mandub
  MM_onSaveMoney = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (
        this.state.MM_nawebarez === "" ||
        this.state.MM_zhmaraybarez === 0 ||
        this.state.MM_wasleShareka === 0 ||
        this.state.MM_nawewargr === null ||
        this.state.MM_brepara === 0 ||
        this.state.MM_joredraw === null
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          shwencode: this.state.MM_zhmaraybarez,
          nawishwen: this.state.MM_nawebarez,
          paraywargirawdolar:
            this.state.MM_joredraw === "دۆلار"
              ? parseFloat(this.state.MM_brepara.toString().replace(/,/g, ""))
              : 0,
          paraywargirawdinar:
            this.state.MM_joredraw === "دینار"
              ? parseFloat(this.state.MM_brepara.toString().replace(/,/g, ""))
              : 0,
          nawiwargr: this.state.MM_nawewargr,
          joripara: this.state.MM_joredraw,
          note: this.state.MM_note,
          rasidkondolar: this.state.MM_qarzeKonDolar,
          rasiddkoninar: this.state.MM_qarzeKonID,
          date: this.state.MM_barwar === "" ? Date.now : this.state.MM_barwar,
          zhmaraaywasl: this.state.MM_wasleShareka,
        };
        const res = await instance.post("/api/v1/newMandwb", data, config);
        this.setState({
          MM_save: true,
          MM_zhamara: res.data.mandwb.zhmara,
          MM_barwar: res.data.mandwb.date,
          MM_del_dis: true,
          MM_wasl: res.data.mandwb,
          MM_toggler: false,
        });
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  ////// update paray mandub
  MM_updtate_para = async (e, para, NOW) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    try {
      if (
        this.state.MM_brepara === "" ||
        this.state.MM_brepara === 0 ||
        this.state.MM_nawebarez === "" ||
        this.state.MM_barwar === "" ||
        this.state.MM_wasleShareka === 0
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          shwencode: this.state.MM_zhmaraybarez,
          nawishwen: this.state.MM_nawebarez,
          paraywargirawdolar:
            this.state.MM_joredraw === "دۆلار"
              ? parseFloat(this.state.MM_brepara.toString().replace(/,/g, ""))
              : 0,
          paraywargirawdinar:
            this.state.MM_joredraw === "دینار"
              ? parseFloat(this.state.MM_brepara.toString().replace(/,/g, ""))
              : 0,
          nawiwargr: this.state.MM_nawewargr,
          joripara: this.state.MM_joredraw,
          note: this.state.MM_note,
          rasidkondolar: this.state.MM_qarzeKonDolar,
          rasiddkoninar: this.state.MM_qarzeKonID,
          date: this.state.MM_barwar === "" ? Date.now : this.state.MM_barwar,
          zhmaraaywasl: this.state.MM_wasleShareka,
        };
        const res = await instance.put(
          `/api/v1/updatemandwb/${para._id}`,
          data,
          config
        );

        this.setState({
          MM_open: null,
          MM_del_dis: true,
          MM_toggler: false,
          MM_wasl: res.data.update,
        });
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  ////search paray mandub
  MM_getWakilDataBySearchMoney = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchpeople?code=${this.state.MM_zhmaraybarez}&&Name=${this.state.MM_nawebarez}`,
      config
    );

    const data = res.data.peoples;
    if (data === null) {
      alert("ببورە ئەم بەڕێزە بونی نیە");
    } else {
      this.setState(() => {
        return {
          MM_zhmaraybarez: data.code,
          MM_nawebarez: data.Name,
          MM_qarzeKonDolar: data.paradolar.toFixed(2),
          MM_qarzeKonID: data.paradinar.toFixed(2),
        };
      });
    }
  };

  MM_peshandaniQarzyPeshw = () => {
    if (this.state.MM_joredraw == "دۆلار") {
      let paraykon = this.state.MM_qarzeKonDolar;
      let parayesta =
        Number(paraykon) +
        parseFloat(this.state.MM_brepara.toString().replace(/,/g, ""));
      return parayesta.toFixed(2);
    } else if (this.state.MM_joredraw == "دینار") {
      let paraykon = this.state.MM_qarzeKonID;
      let parayesta =
        Number(paraykon) +
        parseFloat(this.state.MM_brepara.toString().replace(/,/g, ""));
      return parayesta.toFixed(2);
    }
  };
  MM_getParaDataBySearchMoney = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchmandwb?zhmara=${this.state.MM_zhamara}`,
      config
    );

    const data = res.data.Mandwbkan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ MM_zhamara: 0 });
    } else {
      this.setState(() => {
        return {
          MM_search_wasl: true,
          MM_zhamara: data.zhmara,
          MM_nawebarez: data.nawishwen,
          MM_barwar: data.date,
          MM_zhmaraybarez: data.shwencode,
          MM_brepara:
            data.joripara === "دینار"
              ? data.paraywargirawdinar
              : data.paraywargirawdolar,
          MM_joredraw: data.joripara,
          MM_nawewargr: data.nawiwargr,
          MM_note: data.note,
          MM_qarzeKonDolar: data.rasidkondolar,
          MM_qarzeKonID: data.rasiddkoninar,
          MM_del_dis: true,
          MM_wasl: data,
          MM_toggler: false,
          MM_wasleShareka: data.zhmaraaywasl,
        };
      });
    }
  };

  MM_getParakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/mandwbakan`, config);

    this.setState({
      MM_zhamara: res.data.Mandwbkan.length + 1,
    });
  };

  MM_setSearchMonyWakilEmpty = (e) => {
    e.preventDefault();
    this.setState({
      MM_zhmaraybarez: 0,
      MM_nawebarez: "",
      MM_qarzeKonDolar: 0,
      MM_qarzeKonID: 0,
    });
  };

  MM_createNewMony = () => {
    this.setState(
      {
        MM_zhmaraybarez: 0,
        MM_nawebarez: "",
        MM_qarzeKonDolar: 0,
        MM_qarzeKonID: 0,
        MM_brepara: 0,
        MM_note: "",
        MM_search_wasl: null,
        MM_save: null,
        MM_joredraw: null,
        MM_nawewargr: null,
        MM_open: null,
        MM_del_dis: null,
        MM_page: this.state.MM_zhamara,
        MM_wasleShareka: 0,
        MM_isChecked: false,
      },
      () => {
        this.MM_getParakan();
      }
    );
  };

  MM_getIsChecked = (e) => {
    let checked = e.target.checked;
    if (checked) {
      this.setState({ MM_isChecked: true });
    } else {
      this.setState({ MM_isChecked: false });
    }
  };

  MM_pageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.MM_zhamara + 1;
      const res = await instance.get(
        `/api/v1/pagemandwb?page=${page}&limit=1`,
        config
      );

      const data = res.data.Mandwbs;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            MM_search_wasl: true,
            MM_zhamara: data.zhmara,
            MM_nawebarez: data.nawishwen,
            MM_barwar: data.date,
            MM_zhmaraybarez: data.shwencode,
            MM_brepara:
              data.joripara === "دینار"
                ? data.paraywargirawdinar
                : data.paraywargirawdolar,
            MM_joredraw: data.joripara,
            MM_nawewargr: data.nawiwargr,
            MM_note: data.note,
            MM_qarzeKonDolar: data.rasidkondolar,
            MM_qarzeKonID: data.rasiddkoninar,
            MM_del_dis: true,
            //  P_paraywasl:data.paradwaykmkrdn,
            MM_wasl: data,
            MM_toggler: false,
            MM_wasleShareka: data.zhmaraaywasl,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  MM_pageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page;
      this.state.MM_zhamara <= 1
        ? (page = 1)
        : (page = this.state.MM_zhamara - 1);
      const res = await instance.get(
        `/api/v1/pagemandwb?page=${page}&limit=1`,
        config
      );

      const data = res.data.Mandwbs;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            MM_search_wasl: true,
            MM_zhamara: data.zhmara,
            MM_nawebarez: data.nawishwen,
            MM_barwar: data.date,
            MM_zhmaraybarez: data.shwencode,
            MM_brepara:
              data.joripara === "دینار"
                ? data.paraywargirawdinar
                : data.paraywargirawdolar,
            MM_joredraw: data.joripara,
            MM_nawewargr: data.nawiwargr,
            MM_note: data.note,
            MM_qarzeKonDolar: data.rasidkondolar,
            MM_qarzeKonID: data.rasiddkoninar,
            MM_del_dis: true,
            //  P_paraywasl:data.paradwaykmkrdn,
            MM_wasl: data,
            MM_toggler: false,
            MM_wasleShareka: data.zhmaraaywasl,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };
  /////end of Mandubakan

  // Functions of raporty paradan
  RPD_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            RPD_openmodel: !this.state.RPD_openmodel,
            RPD_data: [],
            RPD_para$: 0,
            RPD_paraID: 0,
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RPD_CloseModal = () => {
    this.setState(() => {
      return { RPD_openmodel: false };
    });
  };

  RPD_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/paradanakandata?from=${this.state.RPD_from}&to=${this.state.RPD_to}`,
        config
      );
      const footer = await instance.get(
        `/api/v1/paradanakanfromto?from=${this.state.RPD_from}&to=${this.state.RPD_to}`,
        config
      );
      footer.data.paradanakan.forEach((data) => {
        this.setState({
          RPD_para$: data.briparabadolar,
          RPD_paraID: data.briparabadinar,
        });
      });
      this.setState({
        RPD_data: res.data.paradanakan,
      });
      this.RPD_CloseModal();
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RPD_getParaDataBySearchMoney = async (e, zhmara) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchpardan?zhmara=${zhmara}`,
      config
    );

    const data = res.data.parakan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ PD_zhmaraybarez: 0 });
    } else {
      this.setState(() => {
        return {
          PD_search_wasl: true,
          PD_zhamara: data.zhmara,
          PD_nawebarez: data.nawekas,
          Pd_barwar: data.date,
          PD_zhmaraybarez: data.kascode,
          PD_brepara:
            data.joripara === "دینار"
              ? data.briparabadinar
              : data.briparabadolar,
          PD_joredraw: data.joripara,
          PD_nawewargr: data.nawiwargr,
          PD_note: data.note,
          PD_del_dis: true,
          PD_wasleShareka: data.zhmaraaywasl,
          PD_wasl: data,
          PD_toggler: false,
        };
      });
    }
  };

  // Functions of raporty paradan
  RPM_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            RPM_openmodel: !this.state.RPM_openmodel,
            RPM_data: [],
            RPM_para$: 0,
            RPM_paraID: 0,
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RPM_CloseModal = () => {
    this.setState(() => {
      return { RPM_openmodel: false };
    });
  };

  RPM_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/paradarhinanakndata?from=${this.state.RPM_from}&to=${this.state.RPM_to}`,
        config
      );
      const footer = await instance.get(
        `/api/v1/paradarhinan?from=${this.state.RPM_from}&to=${this.state.RPM_to}`,
        config
      );
      footer.data.paradarhinanakan.forEach((data) => {
        this.setState({
          RPM_para$: data.briparabadolar,
          RPM_paraID: data.briparabadinar,
        });
      });
      this.setState({
        RPM_data: res.data.paradarhenanakan,
      });
      this.RPM_CloseModal();
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RPM_getParaDataBySearchMoney = async (e, zhmara) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchparadarhenan?zhmara=${zhmara}`,
      config
    );

    const data = res.data.parakan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ PD_zhmaraybarez: 0 });
    } else {
      this.setState(() => {
        return {
          PM_search_wasl: true,
          PM_zhamara: data.zhmara,
          PM_barwar: data.date,
          PM_brepara:
            data.joripara === "دینار"
              ? data.briparabadinar
              : data.briparabadolar,
          PM_joredraw: data.joripara,
          PM_nawewargr: data.nawiwargr,
          PM_note: data.note,
          PM_del_dis: true,
          //  P_paraywasl:data.paradwaykmkrdn,
          PM_wasl: data,
          PM_toggler: false,
        };
      });
    }
  };
  //end of Functions of raporty paradan

  // functions of raporty masrufat
  RMS_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            RMS_openmodel: !this.state.RMS_openmodel,
            RMS_data: [],
            RMS_para$: 0,
            RMS_paraID: 0,
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RMS_CloseModal = () => {
    this.setState(() => {
      return { RMS_openmodel: false };
    });
  };

  RMS_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/masrwffromto?from=${this.state.RMS_from}&to=${this.state.RMS_to}`,
        config
      );

      this.setState(
        {
          RMS_data: res.data.masrwf,
        },
        () => {
          this.Ms_getkoymasrwfdolar();
          this.Ms_getkoymasrwfdinar();
        }
      );
      this.RMS_CloseModal();
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };
  // end of functions of raporty masrufat

  // functions of Raporty tak kasakan
  RT_OpenModal = async (e, userid) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            RT_openmodel: !this.state.RT_openmodel,
            RT_data: [],
            // RW_koyfroshtndianr:0,
            // RW_koyfroshtndolar:0,
            RT_nawibrez: "",
            RT_zhmaraybarez: "",
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RT_CloseModal = () => {
    this.setState(() => {
      return { RT_openmodel: false };
    });
  };

  RT_serchwakil = async (e) => {
    e.preventDefault(e);
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchpeople?code=${Number(this.state.RT_zhmaraybarez)}`,
      config
    );

    const data = res.data.peoples;
    if (data === null) {
      alert("ببورە ئەم بەڕێزە بونی نیە");
    } else {
      this.setState(() => {
        return {
          RT_zhmaraybarez: data.code,
          RT_nawibrez: data.Name,
          RT_mob: data.phone,
          RT_para$: data.paradolar.toFixed(2),
          RT_paraID: data.paradinar.toFixed(2),
        };
      });
    }
  };

  RT_setstInputEmpty = () => {
    this.setState(() => {
      return {
        RT_zhmaraybarez: 0,
        RT_nawibrez: "",
      };
    });
  };
  /////// pshandani kas/shwen raport
  RT_peshandan = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    try {
      this.RT_CloseModal();
      const config = {
        "Content-Type": "application/json",
      };
      const table = this.state.RT_data;
      const res = await instance.get(
        `/api/v1/kasparadata?from=${this.state.RT_from}&to=${this.state.RT_to}&code=${this.state.RT_zhmaraybarez}`,
        config
      );
      const data = res.data.pswla;

      data.forEach((data) => {
        table.push({
          joripara: data.joripara,
          koywasl:
            data.joripara === "دۆلار"
              ? data.briparabadolar
              : data.briparabadinar,
          jwlla: "پارەدان",
          barwar: data.date,
          code: data.zhmara,
          nawewargr: data.nawiwargr,
          wasl: data.zhmaraaywasl,
        });
      });
      const para = await instance.get(
        `/api/v1/shwenmandwbdata?from=${this.state.RT_from}&to=${this.state.RT_to}&code=${this.state.RT_zhmaraybarez}`,
        config
      );
      const paras = para.data.pswla;

      paras.forEach((paras) => {
        table.push({
          joripara: paras.joripara,
          koywasl:
            paras.joripara === "دۆلار"
              ? paras.paraywargirawdolar
              : paras.paraywargirawdinar,
          jwlla: "مەندوب",
          barwar: paras.date,
          code: paras.zhmara,
          nawewargr: paras.nawiwargr,
          wasl: paras.zhmaraaywasl,
        });
      });

      if (this.state.RT_paradan === true) {
        this.setState(
          {
            RT_data: table.filter((data) => data.jwlla === "پارەدان"),
          },
          () => {
            this.RT_koypardandolar(e);
            this.RT_koypardandinar(e);
          }
        );
      } else if (this.state.RT_mandub === true) {
        this.setState(
          {
            RT_data: table.filter((data) => data.jwlla === "مەندوب"),
          },
          () => {
            this.RT_koyKreninar(e);
            this.RT_koyKrenolar(e);
          }
        );
      }
    } catch (error) {
      alert("هیج جاڵاکیەکی نیە");
    }
  };
  ///// koy paray mandwb || koy kreen
  RT_koyKrenolar = () => {
    let subTotal = 0;
    this.state.RT_data.map((item) => {
      if (item.joripara === "دۆلار" && item.jwlla === "مەندوب") {
        subTotal += Number(item.koywasl);
      }
    });
    const koypardab = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        RT_koyparadan$: koypardab,
      };
    });
  };

  RT_koyKreninar = () => {
    let subTotal = 0;
    this.state.RT_data.map((item) => {
      if (item.joripara === "دینار" && item.jwlla === "مەندوب") {
        subTotal += Number(item.koywasl);
      }
    });
    const koypardab = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        RT_koyparadanID: koypardab,
      };
    });
  };
  //// koy paaradn
  RT_koypardandolar = () => {
    let subTotal = 0;
    this.state.RT_data.map((item) => {
      if (item.joripara === "دۆلار" && item.jwlla === "پارەدان") {
        subTotal += Number(item.koywasl);
      }
    });
    const koypardab = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        RT_koyparadan$: koypardab,
      };
    });
  };

  RT_koypardandinar = () => {
    let subTotal = 0;
    this.state.RT_data.map((item) => {
      if (item.joripara === "دینار" && item.jwlla === "پارەدان") {
        subTotal += Number(item.koywasl);
      }
    });
    const koypardab = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        RT_koyparadanID: koypardab,
      };
    });
  };
  RT_Paradanakan = async () => {
    this.setState(
      {
        RT_paradan: true,
        RT_mandub: false,
        RT_data: [],
      },
      () => {
        this.RT_peshandan();
      }
    );
  };

  RT_mandubakan = async () => {
    this.setState(
      {
        RT_mandub: true,
        RT_paradan: false,
        RT_data: [],
      },
      () => {
        this.RT_peshandan();
      }
    );
  };

  /////saerch
  RT_gotoMandub = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchmandwb?zhmara=${Number(zhwasl)}`,
      config
    );

    const data = res.data.Mandwbkan;
    this.setState(() => {
      return {
        MM_search_wasl: true,
        MM_zhamara: data.zhmara,
        MM_nawebarez: data.nawishwen,
        MM_barwar: data.date,
        MM_zhmaraybarez: data.shwencode,
        MM_brepara:
          data.joripara === "دینار"
            ? data.paraywargirawdinar
            : data.paraywargirawdolar,
        MM_joredraw: data.joripara,
        MM_nawewargr: data.nawiwargr,
        MM_note: data.note,
        MM_qarzeKonDolar: data.rasidkondolar,
        MM_qarzeKonID: data.rasiddkoninar,
        MM_del_dis: true,
        MM_wasl: data,
        MM_toggler: false,
        MM_wasleShareka: data.zhmaraaywasl,
      };
    });
  };

  RT_gotoParadanakan = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchpardan?zhmara=${zhwasl}`,
      config
    );

    const data = res.data.parakan;
    this.setState(() => {
      return {
        PD_search_wasl: true,
        PD_zhamara: data.zhmara,
        PD_nawebarez: data.nawekas,
        Pd_barwar: data.date,
        PD_zhmaraybarez: data.kascode,
        PD_brepara:
          data.joripara === "دینار" ? data.briparabadinar : data.briparabadolar,
        PD_joredraw: data.joripara,
        PD_nawewargr: data.nawiwargr,
        PD_note: data.note,
        PD_del_dis: true,
        PD_wasl: data,
        PD_toggler: false,
        PD_wasleShareka: data.zhmaraaywasl,
      };
    });
  };
  // end of functions of Raporty tak kasakan

  // functions Raporty Mandubakan

  RMM_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            RMM_openmodel: !this.state.RMM_openmodel,
            RMM_data: [],
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RMM_CloseModal = () => {
    this.setState(() => {
      return { RMM_openmodel: false };
    });
  };

  RMM_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/mandwbdata?from=${this.state.RMM_from}&to=${this.state.RMM_to}`,
        config
      );

      this.setState({
        RMM_data: res.data.Mandwbs,
      });
      this.RMM_CloseModal();
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RMM_getParaDataBySearchMoney = async (e, zhmara) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchmandwb?zhmara=${zhmara}`,
      config
    );

    const data = res.data.Mandwbkan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ MM_zhmaraybarez: 0 });
    } else {
      this.setState(() => {
        return {
          MM_search_wasl: true,
          MM_zhamara: data.zhmara,
          MM_nawebarez: data.nawishwen,
          MM_barwar: data.date,
          MM_zhmaraybarez: data.shwencode,
          MM_brepara:
            data.joripara === "دینار"
              ? data.paraywargirawdinar
              : data.paraywargirawdolar,
          MM_joredraw: data.joripara,
          MM_nawewargr: data.nawiwargr,
          MM_note: data.note,
          MM_qarzeKonDolar: data.rasidkondolar,
          MM_qarzeKonID: data.rasiddkoninar,
          MM_del_dis: true,
          MM_wasl: data,
          MM_toggler: false,
          MM_wasleShareka: data.zhmaraaywasl,
        };
      });
    }
  };
  // end of  functions Raporty Mandubakan

  ////functions of parawargrtn
  PW_getPrint = () => {
    window.setTimeout("window.print()", 2000);
  };

  PW_openUpdate = async (userid, e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const { value: text } = await Swal.fire({
      input: "password",
      inputLabel: "Password",
      showLoaderOnConfirm: true,
      inputPlaceholder: "وشەی نهێنی داخیل بکە...",
      inputAttributes: {
        "aria-label": "وشەی نهێنی داخیل بکە",
      },
      showCancelButton: true,
    });
    const data = {
      password: text,
    };
    const pass = await instance.post(`api/v1/open?id=${userid}`, data, config);

    if (pass.data.status === false) {
      alert("وشەی نهێنی هەڵەیە");
    } else if (pass.data.status === true) {
      this.setState(
        {
          PW_del_dis: null,
          PW_open: true,
        },
        async () => {
          let bardastdata;
          const respb = await instance.get(`/api/v1/paraybardasakan`, config);
          if (this.state.PW_joredraw === "دۆلار") {
            bardastdata = {
              paraybardastdolar:
                Number(respb.data.paraybardastakan.paraybardastdolar) -
                parseFloat(this.state.PW_brepara.toString().replace(/,/g, "")),
            };
          } else if (this.state.PW_joredraw === "دینار") {
            bardastdata = {
              paraybardastdinar:
                Number(respb.data.paraybardastakan.paraybardastdinar) -
                parseFloat(this.state.PW_brepara.toString().replace(/,/g, "")),
            };
          }
          await instance.put(
            `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
            bardastdata,
            config
          );
        }
      );
    }
  };

  PW_inputtogler = (e) => {
    e.preventDefault();
    this.setState({
      PW_toggler: !this.state.PW_toggler,
      PW_barwar: "",
    });
  };

  PW_onSaveMoney = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (
        this.state.PW_nawewargr === null ||
        this.state.PW_brepara === 0 ||
        this.state.PW_joredraw === null ||
        this.state.PW_nawikas === ""
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          briparabadolar:
            this.state.PW_joredraw === "دۆلار"
              ? parseFloat(this.state.PW_brepara.toString().replace(/,/g, ""))
              : 0,
          briparabadinar:
            this.state.PW_joredraw === "دینار"
              ? parseFloat(this.state.PW_brepara.toString().replace(/,/g, ""))
              : 0,
          nawiwargr: this.state.PW_nawewargr,
          joripara: this.state.PW_joredraw,
          note: this.state.PW_note,
          date: this.state.PW_barwar === "" ? Date.now : this.state.PW_barwar,
          nawikas: this.state.PW_nawikas,
        };
        const res = await instance.post("/api/v1/newparawargrtn", data, config);

        this.setState(
          {
            PW_save: true,
            PW_zhamara: res.data.Parawargtn.zhmara,
            PW_barwar: res.data.Parawargtn.date,
            PW_del_dis: true,
            PW_wasl: res.data.Parawargtn,
            PW_toggler: false,
          },
          async () => {
            let bardastdata;
            const respb = await instance.get(`/api/v1/paraybardasakan`, config);
            if (this.state.PW_joredraw === "دۆلار") {
              bardastdata = {
                paraybardastdolar:
                  Number(respb.data.paraybardastakan.paraybardastdolar) +
                  parseFloat(
                    this.state.PW_brepara.toString().replace(/,/g, "")
                  ),
              };
            } else if (this.state.PW_joredraw === "دینار") {
              bardastdata = {
                paraybardastdinar:
                  Number(respb.data.paraybardastakan.paraybardastdinar) +
                  parseFloat(
                    this.state.PW_brepara.toString().replace(/,/g, "")
                  ),
              };
            }
            await instance.put(
              `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
              bardastdata,
              config
            );
          }
        );
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  ////// update Mony

  PW_updtate_para = async (e, para) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    try {
      if (
        this.state.PW_brepara === "" ||
        this.state.PW_brepara === 0 ||
        this.state.PW_barwar === "" ||
        this.state.PW_nawikas === ""
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          briparabadolar:
            this.state.PW_joredraw === "دۆلار"
              ? parseFloat(this.state.PW_brepara.toString().replace(/,/g, ""))
              : 0,
          briparabadinar:
            this.state.PW_joredraw === "دینار"
              ? parseFloat(this.state.PW_brepara.toString().replace(/,/g, ""))
              : 0,
          nawiwargr: this.state.PW_nawewargr,
          joripara: this.state.PW_joredraw,
          note: this.state.PW_note,
          date: this.state.PW_barwar === "" ? Date.now : this.state.PW_barwar,
          nawikas: this.state.PW_nawikas,
        };
        await instance.put(
          `/api/v1/updateparawargrtn/${para._id}`,
          data,
          config
        );

        this.setState(
          {
            PW_open: null,
            PW_del_dis: true,
            PW_toggler: false,
          },
          async () => {
            let bardastdata;
            const respb = await instance.get(`/api/v1/paraybardasakan`, config);
            if (this.state.PW_joredraw === "دۆلار") {
              bardastdata = {
                paraybardastdolar:
                  Number(respb.data.paraybardastakan.paraybardastdolar) +
                  parseFloat(
                    this.state.PW_brepara.toString().replace(/,/g, "")
                  ),
              };
            } else if (this.state.PW_joredraw === "دینار") {
              bardastdata = {
                paraybardastdinar:
                  Number(respb.data.paraybardastakan.paraybardastdinar) +
                  parseFloat(
                    this.state.PW_brepara.toString().replace(/,/g, "")
                  ),
              };
            }
            await instance.put(
              `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
              bardastdata,
              config
            );
          }
        );
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  PW_getParaDataBySearchMoney = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchparawargrtn?zhmara=${this.state.PW_zhamara}`,
      config
    );

    const data = res.data.parakan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ PW_zhamara: 0 });
    } else {
      this.setState(() => {
        return {
          PW_search_wasl: true,
          PW_zhamara: data.zhmara,
          PW_barwar: data.date,
          PW_brepara:
            data.joripara === "دینار"
              ? data.briparabadinar
              : data.briparabadolar,
          PW_joredraw: data.joripara,
          PW_nawewargr: data.nawiwargr,
          PW_note: data.note,
          PW_del_dis: true,
          PW_wasl: data,
          PW_toggler: false,
          PW_nawikas: data.nawikas,
        };
      });
    }
  };

  PW_getParakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/parawargrtnakan`, config);

    if (res.data.Parawargtnakan) {
      this.setState({
        PW_zhamara: res.data.Parawargtnakan.length + 1,
      });
    }
  };

  PW_createNewMony = () => {
    this.setState(
      {
        PW_brepara: 0,
        PW_note: "",
        PW_search_wasl: null,
        PW_save: null,
        PW_joredraw: null,
        PW_nawewargr: null,
        PW_open: null,
        PW_del_dis: null,
        PW_page: this.state.PW_zhamara,
        PW_wasl: null,
        PW_nawikas: "",
      },
      () => {
        this.PW_getParakan();
        this.PB_gegtparaybardast();
      }
    );
  };

  PW_pageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.PW_zhamara + 1;
      const res = await instance.get(
        `/api/v1/pageparawargrtn?page=${page}&limit=1`,
        config
      );

      const data = res.data.paras;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            PW_search_wasl: true,
            PW_zhamara: data.zhmara,
            PW_barwar: data.date,
            PW_brepara:
              data.joripara === "دینار"
                ? data.briparabadinar
                : data.briparabadolar,
            PW_joredraw: data.joripara,
            PW_nawewargr: data.nawiwargr,
            PW_note: data.note,
            PW_del_dis: true,
            PW_wasl: data,
            PW_toggler: false,
            PW_nawikas: data.nawikas,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  PW_pageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page;
      this.state.PW_zhamara <= 1
        ? (page = 1)
        : (page = this.state.PW_zhamara - 1);
      const res = await instance.get(
        `/api/v1/pageparawargrtn?page=${page}&limit=1`,
        config
      );

      const data = res.data.paras;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            PW_search_wasl: true,
            PW_zhamara: data.zhmara,
            PW_barwar: data.date,
            PW_brepara:
              data.joripara === "دینار"
                ? data.briparabadinar
                : data.briparabadolar,
            PW_joredraw: data.joripara,
            PW_nawewargr: data.nawiwargr,
            PW_note: data.note,
            PW_del_dis: true,
            PW_wasl: data,
            PW_toggler: false,
            PW_nawikas: data.nawikas,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };
  /////end of functions para wargrtn

  // Functions of raporty para wargrtnawakan
  RPW_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            RPW_openmodel: !this.state.RPW_openmodel,
            RPW_data: [],
            RPW_para$: 0,
            RPW_paraID: 0,
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RPW_CloseModal = () => {
    this.setState(() => {
      return { RPW_openmodel: false };
    });
  };

  RPW_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/parawargrtnakanakndata?from=${this.state.RPW_from}&to=${this.state.RPW_to}`,
        config
      );
      const footer = await instance.get(
        `/api/v1/parawargrtnakanfromto?from=${this.state.RPW_from}&to=${this.state.RPW_to}`,
        config
      );
      footer.data.paradarhinanakan.forEach((data) => {
        this.setState({
          RPW_para$: data.briparabadolar,
          RPW_paraID: data.briparabadinar,
        });
      });
      this.setState({
        RPW_data: res.data.Parawargtnakan,
      });
      this.RPW_CloseModal();
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RPW_getParaDataBySearchMoney = async (e, zhmara) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchparawargrtn?zhmara=${zhmara}`,
      config
    );

    const data = res.data.parakan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ PW_zhmaraybarez: 0 });
    } else {
      this.setState(() => {
        return {
          PW_search_wasl: true,
          PW_zhamara: data.zhmara,
          PW_barwar: data.date,
          PW_brepara:
            data.joripara === "دینار"
              ? data.briparabadinar
              : data.briparabadolar,
          PW_joredraw: data.joripara,
          PW_nawewargr: data.nawiwargr,
          PW_note: data.note,
          PW_del_dis: true,
          PW_wasl: data,
          PW_toggler: false,
        };
      });
    }
  };
  // end of Functions of raporty para wargrtnawakan

  /// paraay qasa functions
  PB_gegtparaybardast = async () => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await instance.get(`/api/v1/paraybardasakan`, config);
      this.setState({
        PD_parayesta$: res.data.paraybardastakan.paraybardastdolar.toFixed(2),
        PD_parayestaID: res.data.paraybardastakan.paraybardastdinar.toFixed(2),
        PM_parayesta$: res.data.paraybardastakan.paraybardastdolar.toFixed(2),
        PM_parayestaID: res.data.paraybardastakan.paraybardastdinar.toFixed(2),
        PW_parayesta$: res.data.paraybardastakan.paraybardastdolar.toFixed(2),
        PW_parayestaID: res.data.paraybardastakan.paraybardastdinar.toFixed(2),
      });
    } catch (error) {
      alert("پارەی بەردەست نیە");
    }
  };
  ///end of  paraay qasa functions

  ///search by namne functions

  Serach_ByName = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchName?nameofproduct=${this.state.naweSht}`,
      config
    );
    this.setState({
      suggestions: res.data.search,
    });
  };

  R_darzanChangeSfr = () => {
    this.setState({
      R_darzan: "",
      R_karton: "",
    });
  };

  suggestionSelected = async (id) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(`/api/v1/searchtoproduct/${id}`, config);
      const data = res.data.product;
      this.setState(() => {
        return {
          prodactdata: data,
          search: true,
          naweSht: data.nameofproduct,
          kren: data.price,
          nrxeFroshtnDolar: data.saleDolar,
          nrxeFroshtnDenar: data.saleDinar,
          nrxeFroshtnDolar3: data.saleDolar3,
          nrxeFroshtnDolar2: data.saleDolar2,
          nrxeKamtrlanewCarton: data.saleDolar2,
          nrxeKamtrlaDaDarzan: data.saleDolar3,
          zhmarayKarton: data.numberOfDarzan,
          qazanj: data.qazanj,
          Add_rasidkarton: Number(data.rasidKarton).toFixed(2),
          data: data.table,
          wazn_karton: data.weight,
          del_dis: true,
          barcode: data.barcode,
          suggestions: [],
        };
      });
    } catch (error) {
      alert("هەڵەهەیە لە خانەکان");
    }
  };

  R_Serach_ByName = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchName?nameofproduct=${this.state.R_naweSht}`,
      config
    );
    if (this.state.R_naweSht === "" || this.state.R_naweSht === null) {
      this.setState({
        R_suggestions: [],
      });
    }
    this.setState({
      R_suggestions: res.data.search,
    });
  };

  R_hanldeKeyup = (e) => {
    if (e.keyCode === 27) {
      this.setState({ R_suggestions: [] });
      return false;
    }
  };
  R_hanldeKeydown = (e) => {
    const { R_cursor, R_suggestions } = this.state;
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && R_cursor > 0) {
      this.setState((prevState) => ({
        R_cursor: prevState.R_cursor - 1,
      }));
    } else if (e.keyCode === 40 && R_cursor < R_suggestions.length - 1) {
      this.setState((prevState) => ({
        R_cursor: prevState.R_cursor + 1,
      }));
    }
    // if (e.keyCode === 13) {
    //   e.preventDefault();
    //   let currentItem = R_suggestions[R_cursor];

    //   if (currentItem !== undefined) {
    //     this.R_suggestionSelected(currentItem._id);
    //   }
    // }
    if (e.keyCode === 46) {
      this.setState(() => {
        return {
          R_search: null,
          R_suggestions: [],
          R_productId: "",
          R_naweSht: "",
          R_rezha: 0,
          R_nrx_dolar: 0,
          R_nrx_dinarr: 0,
          R_nrx: 0,
          R_nrx_dolar2: 0,
          R_nrx_dolar3: 0,
          R_rasidKarton: 0,
          R_qazanj: 0,
          R_kren: 0,
          R_nrxkaton: 0,
          R_waznkarton: 0,
          R_karton: "",
          R_darzan: "",
          R_cursor: 0,
          R_note: "",
        };
      });
    }
  };
  R_handleListKeydown(evt) {}
  R_suggestionSelected = async (id) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/searchtoproduct/${id}`, config);
    const data = res.data.product;
    if (
      this.state.R_naweSht.toString().length === 0 ||
      this.state.R_naweSht === null
    ) {
      this.setState({
        R_suggestions: [],
      });
    }
    if (res.data.status) {
      this.setState(() => {
        return {
          R_search: true,
          R_cursor: 0,
          R_suggestions: [],
          R_productId: data._id,
          R_naweSht: data.nameofproduct,
          R_rezha: data.numberOfDarzan,
          R_nrx_dolar: data.saleDolar,
          R_nrx_dinarr: data.saleDinar,
          R_nrx:
            this.state.R_joripara === "دینار" ? data.saleDinar : data.saleDolar,
          R_nrx_dolar2: data.saleDolar2,
          R_nrx_dolar3: data.saleDolar3,
          R_rasidKarton: Number(data.rasidKarton).toFixed(2),
          R_qazanj:
            this.state.R_joripara === "دینار"
              ? (this.dinartodolar(data.saleDinar) - data.price).toFixed(2)
              : data.qazanj,
          R_kren: data.price,
          R_nrxkaton: (data.price * data.numberOfDarzan).toFixed(2),
          R_waznkarton: data.weight,
          R_zhmara: data.barcode,
          R_karton: "",
          R_darzan: "",
        };
      });
    }
  };

  Search_Product_Handle = (e) => {
    this.setState(
      {
        naweSht: e.target.value,
      },
      () => {
        if (
          !this.state.naweSht ||
          this.state.naweSht === "" ||
          this.state.naweSht === null
        ) {
          this.setState({ suggestions: [] });
        } else {
          this.Serach_ByName();
        }
      }
    );
  };

  Search_Rent_Handle = (e) => {
    this.setState(
      {
        R_naweSht: e.target.value,
      },
      () => {
        if (
          !this.state.R_naweSht ||
          this.state.R_naweSht === "" ||
          this.state.R_naweSht === null
        ) {
          this.setState({ R_suggestions: [] });
        } else {
          this.R_Serach_ByName();
        }
      }
    );
  };

  G_Serach_ByName = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchName?nameofproduct=${this.state.G_naweSht}`,
      config
    );
    if (this.state.G_naweSht === "" || this.state.G_naweSht === null) {
      this.setState({
        G_suggestions: [],
      });
    }
    this.setState({
      G_suggestions: res.data.search,
    });
  };

  G_suggestionSelected = async (id) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(`/api/v1/searchtoproduct/${id}`, config);
      if (this.state.G_naweSht === "" || this.state.G_naweSht === null) {
        this.setState({
          G_suggestions: [],
        });
      }
      const data = res.data.product;

      this.setState(() => {
        return {
          G_search: true,
          G_productId: data._id,
          G_naweSht: data.nameofproduct,
          G_rezha: data.numberOfDarzan,
          G_nrx_dolar: data.saleDolar,
          G_nrx_dinarr: data.saleDinar,
          G_nrx:
            this.state.G_joripara === "دینار" ? data.saleDinar : data.saleDolar,
          G_nrx_dolar2: data.saleDolar2,
          G_nrx_dolar3: data.saleDolar3,
          G_rasidKarton: data.rasidKarton,
          G_qazanj:
            this.state.G_joripara === "دینار"
              ? (this.dinartodolar(data.saleDinar) - data.price).toFixed(2)
              : data.qazanj,
          G_kren: data.price,
          G_nrxkaton: (data.price * data.numberOfDarzan).toFixed(2),
          G_waznkarton: data.weight,
          G_zhmara: data.barcode,
          G_suggestions: [],
        };
      });
    } catch (error) {
      alert("there is no patients");
    }
  };

  Search_Koga_Handle = (e) => {
    this.setState(
      {
        G_naweSht: e.target.value,
      },
      (e) => {
        if (
          !this.state.G_naweSht ||
          this.state.G_naweSht === "" ||
          this.state.G_naweSht === null
        ) {
          this.setState({ G_suggestions: [] });
        } else {
          this.G_Serach_ByName();
        }
      }
    );
  };

  K_Serach_ByName = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchName?nameofproduct=${this.state.K_naweKala}`,
      config
    );
    this.setState({
      K_suggestions: res.data.search,
    });
  };

  K_suggestionSelected = async (id) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(`/api/v1/searchtoproduct/${id}`, config);
      const data = res.data.product;
      this.setState(() => {
        return {
          K_naweKala: data.nameofproduct,
          K_kallaDetail: data._id,
          K_numberOfDarzan: data.numberOfDarzan,
          K_rasedeKartonBroken: data.rasidBroken,
          K_suggestions: [],
        };
      });
    } catch (error) {
      alert("there is no patients");
    }
  };

  Search_Jarde_Koga_Handle = (e) => {
    this.setState(
      {
        K_naweKala: e.target.value,
      },
      (e) => {
        if (
          !this.state.K_naweKala ||
          this.state.K_naweKala === "" ||
          this.state.K_naweKala === null
        ) {
          this.setState({ K_suggestions: [] });
        } else {
          this.K_Serach_ByName();
        }
      }
    );
  };

  RC_Serach_ByName = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchName?nameofproduct=${this.state.RC_nawikalla}`,
      config
    );
    this.setState({
      RC_suggestions: res.data.search,
    });
  };

  RC_suggestionSelected = async (id, nawikalla) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/searchtoproduct/${id}`, config);
    const data = res.data.product;

    const res2 = await instance.get(`/api/v1/searchchina/${nawikalla}`, config);
    const data2 = res2.data.product;

    if (data2 !== null) {
      this.setState(() => {
        return {
          RC_nawikalla: data.nameofproduct,
          RC_suggestions: [],
          RC_froshtn: data.saleDolar,
          RC_qazanj: data.qazanj,
          RC_kalladata: data,
          RC_search_wasl: true,
          RC_wasl: data2,
          RC_data: data2.table,
          RC_zhmaaryDukan: data2.dukan,
          RC_dabash: data2.dabash,
          RC_rezhaynawKarton: data2.rezhanawkarton,
          RC_nrxRRR: data2.rrr,
          RC_koygshtyR: data2.koygshtyR,
          RC_open: false,
          RC_save: false,
          RC_koygshty$: data2.koygshtyDolar,
          RC_drezhe: data2.drezhi,
          RC_barze: data2.barzi,
          RC_pane: data2.pane,
          RC_koymatr$: data2.koymatrdolar,
          RC_nrxekotayeKala$: data2.nrxikotayykalla,
          RC_updatekala: false,
          RC_zhmaaryPswla: data2.wasl,
          RC_dis: true,
        };
      });
    } else if (data2 == null) {
      this.setState(() => {
        return {
          RC_nawikalla: data.nameofproduct,
          RC_suggestions: [],
          RC_froshtn: data.saleDolar,
          RC_qazanj: data.qazanj,
          RC_kalladata: data,
        };
      });
    }
  };

  Search_Raporty_cin_Handle = (e) => {
    this.setState(
      {
        RC_nawikalla: e.target.value,
      },
      (e) => {
        if (
          !this.state.RC_nawikalla ||
          this.state.RC_nawikalla === "" ||
          this.state.RC_nawikalla === null
        ) {
          this.setState({ RC_suggestions: [] });
        } else {
          this.RC_Serach_ByName();
        }
      }
    );
  };

  ///Function Of Old Rasid

  SaveOldRasid = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const draw = this.state.OR_joriDraw == "دۆلار" ? "dolar" : "dinar";
      const briqarz = parseFloat(
        this.state.OR_briQarz.toString().replace(/,/g, "")
      );
      await instance.put(
        `/api/v1/updaitwakilrasid?code=${this.state.OR_zhmarayBarez}&joridraw=${draw}&opr=plus&bripara=${briqarz}`,
        config
      );

      const data = {
        code: this.state.OR_zhmarayBarez,
        naw: this.state.OR_nawiBarez,
        para: briqarz,
        draw: this.state.OR_joriDraw,
      };
      await instance.post(`/api/v1/newRasid`, data, config);

      this.setState({
        OR_save: true,
        OR_open: null,
        OR_del_dis: true,
      });
    } catch (error) {
      alert("هەڵە هەیە لە خانەکان");
    }
  };
  NewOldRasid = (e) => {
    e.preventDefault();
    this.setState({
      OR_briQarz: "",
      OR_joriDraw: "",
      OR_nawiBarez: "",
      OR_zhmarayBarez: "",
      OR_save: null,
      OR_open: null,
      OR_del_dis: null,
    });
  };
  OpenOldRasid = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const draw = this.state.OR_joriDraw == "دۆلار" ? "dolar" : "dinar";
      const briqarz = parseFloat(
        this.state.OR_briQarz.toString().replace(/,/g, "")
      );
      await instance.put(
        `/api/v1/updaitwakilrasid?code=${this.state.OR_zhmarayBarez}&joridraw=${draw}&opr=-&bripara=${briqarz}`,
        config
      );
      this.setState({
        OR_open: true,
        OR_del_dis: true,
      });
    } catch (error) {
      alert("هەڵە هەیە لە خانەکان");
    }
  };
  SearchWakilOldRasid = async (e, NOW) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/searchwakil?code=${NOW}&&Name=&&phone=`,
        config
      );
      this.setState({
        OR_nawiBarez: res.data.wakils.Name,
        OR_zhmarayBarez: res.data.wakils.code,
      });
    } catch (error) {
      alert("There is Now Wakil as this name");
    }
  };

  ///function OF Qarzi Asto

  QA_OpenModal = async (e, userid) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            QA_modalOpen: !this.state.QA_modalOpen,
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  KoyqarziAssto = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      this.QA_CloseModal();
      const res = await instance.get(`/api/v1/getkoyqarziasto`, config);
      this.setState({
        QA_qarzdinar: res.data.qarz[0].dinar,
        QA_qarzdolar: res.data.qarz[0].dolar,
      });
    } catch (error) {
      alert("There is Now Wakil as this name");
    }
  };

  QA_CloseModal = () => {
    this.setState(() => {
      return { QA_modalOpen: false };
    });
  };

  // Function of ParaGorenawa
  PG_getPrint = () => {
    window.setTimeout("window.print()", 2000);
  };

  PG_showrasid = async (e, userid) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    if (this.state.PG_show === false) {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else {
        this.setState({ PG_show: !this.state.PG_show }, () =>
          this.PB_gegtparaybardast()
        );
      }
    } else {
      this.setState({ PG_show: !this.state.PG_show });
    }
  };

  PG_openUpdate = async (userid, e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const { value: text } = await Swal.fire({
      input: "password",
      inputLabel: "Password",
      showLoaderOnConfirm: true,
      inputPlaceholder: "وشەی نهێنی داخیل بکە...",
      inputAttributes: {
        "aria-label": "وشەی نهێنی داخیل بکە",
      },
      showCancelButton: true,
    });
    const data = {
      password: text,
    };
    const pass = await instance.post(`api/v1/open?id=${userid}`, data, config);

    if (pass.data.status === false) {
      alert("وشەی نهێنی هەڵەیە");
    } else if (pass.data.status === true) {
      this.setState(
        {
          PG_del_dis: null,
          PG_open: true,
        },
        async () => {
          let bardastdata;
          const respb = await instance.get(`/api/v1/paraybardasakan`, config);
          bardastdata = {
            paraybardastdolar:
              Number(respb.data.paraybardastakan.paraybardastdolar) -
              parseFloat(this.state.PG_breKrini$.toString().replace(/,/g, "")),
            paraybardastdinar:
              Number(respb.data.paraybardastakan.paraybardastdinar) +
              parseFloat(
                this.state.PG_breParabaID.toString().replace(/,/g, "")
              ),
          };

          await instance.put(
            `/api/v1/updateparabardastakan/${respb.data.paraybardastakan._id}`,
            bardastdata,
            config
          );
        }
      );
    }
  };

  PG_inputtogler = (e) => {
    e.preventDefault();
    this.setState({
      PG_toggler: !this.state.PG_toggler,
      PG_barwar: "",
    });
  };

  PG_onSaveMoney = async (e) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      if (
        this.state.PG_nawewargr === null ||
        this.state.PG_breParabaID === 0 ||
        this.state.PG_nrxeKrine$ === 0 ||
        this.state.PG_breParabaID === "" ||
        this.state.PG_nrxeKrine$ === ""
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          briparabadinar: parseFloat(
            this.state.PG_breParabaID.toString().replace(/,/g, "")
          ),
          nrxikrinidolar: parseFloat(
            this.state.PG_nrxeKrine$.toString().replace(/,/g, "")
          ),
          brikrinidolar: parseFloat(
            this.state.PG_breKrini$.toString().replace(/,/g, "")
          ),
          nawiwargr: this.state.PG_nawewargr,
          note: this.state.PG_note,
          date: this.state.PG_barwar === "" ? Date.now : this.state.PG_barwar,
        };
        const res = await instance.post(
          "/api/v1/newParaGorinawa",
          data,
          config
        );

        this.setState({
          PG_save: true,
          PG_zhmara: res.data.paraGorinawa.zhmara,
          PG_barwar: res.data.paraGorinawa.date,
          PG_del_dis: true,
          //    P_paraywasl:res.data.wargrtn.paradwaykmkrdn,
          PG_wasl: res.data.paraGorinawa,
          PG_toggler: false,
        });
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  ////// update Mony

  PG_updtate_para = async (e, para) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    try {
      if (
        this.state.PG_nawewargr === null ||
        this.state.PG_breParabaID === 0 ||
        this.state.PG_nrxeKrine$ === 0 ||
        this.state.PG_breParabaID === "" ||
        this.state.PG_nrxeKrine$ === ""
      ) {
        alert("تکایە خانەکان پڕبکەوە");
      } else {
        let data;
        data = {
          briparabadinar: parseFloat(
            this.state.PG_breParabaID.toString().replace(/,/g, "")
          ),
          nrxikrinidolar: parseFloat(
            this.state.PG_nrxeKrine$.toString().replace(/,/g, "")
          ),
          brikrinidolar: parseFloat(
            this.state.PG_breKrini$.toString().replace(/,/g, "")
          ),
          nawiwargr: this.state.PG_nawewargr,
          note: this.state.PG_note,
          date: this.state.PG_barwar === "" ? Date.now : this.state.PG_barwar,
        };
        const res = await instance.put(
          `/api/v1/updateParaGorinawa/${para._id}`,
          data,
          config
        );

        this.setState({
          PG_open: null,
          PG_del_dis: true,
          PG_toggler: false,
        });
      }
    } catch (error) {
      alert("تکایە خانەکان پڕبکەوە");
    }
  };

  PG_getParaDataBySearchMoney = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchParaGorinawa?zhmara=${this.state.PG_zhmara}`,
      config
    );

    const data = res.data.parakan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ PD_zhmaraybarez: 0 });
    } else {
      this.setState(() => {
        return {
          PG_search_wasl: true,
          PG_zhmara: data.zhmara,
          PG_barwar: data.date,
          PG_breKrini$: data.brikrinidolar,
          PG_nrxeKrine$: data.nrxikrinidolar,
          PG_breParabaID: data.briparabadinar,
          PG_nawewargr: data.nawiwargr,
          PG_note: data.note,
          PG_del_dis: true,
          PG_wasl: data,
          PG_toggler: false,
        };
      });
    }
  };

  PG_getParakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/paragorinaewakn`, config);

    this.setState({
      PG_zhmara: res.data.paraGorinawa.length + 1,
    });
  };

  PG_createNewMony = () => {
    this.setState(
      {
        PM_brepara: 0,
        PG_note: "",
        PG_search_wasl: null,
        PG_save: null,
        PG_nawewargr: null,
        PG_open: null,
        PG_del_dis: null,
        // PM_page:this.state.PG_zhamara,
        PG_wasl: null,
        PG_barwar: "",
        PG_breParabaID: 0,
        PG_breKrini$: 0,
        PG_nrxeKrine$: 0,
      },
      () => {
        this.PG_getParakan();
        this.PB_gegtparaybardast();
      }
    );
  };

  PG_pageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.PG_zhmara + 1;
      const res = await instance.get(
        `/api/v1/pageParaGorinawa?page=${page}&limit=1`,
        config
      );

      const data = res.data.paras;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            PG_search_wasl: true,
            PG_zhmara: data.zhmara,
            PG_barwar: data.date,
            PG_breKrini$: data.brikrinidolar,
            PG_nrxeKrine$: data.nrxikrinidolar,
            PG_breParabaID: data.briparabadinar,
            PG_nawewargr: data.nawiwargr,
            PG_note: data.note,
            PG_del_dis: true,
            PG_wasl: data,
            PG_toggler: false,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  PG_pageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page;
      this.state.PG_zhmara <= 1
        ? (page = 1)
        : (page = this.state.PG_zhmara - 1);
      const res = await instance.get(
        `/api/v1/pageParaGorinawa?page=${page}&limit=1`,
        config
      );

      const data = res.data.paras;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            PG_search_wasl: true,
            PG_zhmara: data.zhmara,
            PG_barwar: data.date,
            PG_breKrini$: data.brikrinidolar,
            PG_nrxeKrine$: data.nrxikrinidolar,
            PG_breParabaID: data.briparabadinar,
            PG_nawewargr: data.nawiwargr,
            PG_note: data.note,
            PG_del_dis: true,
            PG_wasl: data,
            PG_toggler: false,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  OngetDolar = () => {
    const z =
      (parseFloat(this.state.PG_breParabaID.toString().replace(/,/g, "")) /
        parseFloat(this.state.PG_nrxeKrine$.toString().replace(/,/g, ""))) *
      100;

    return z;
  };

  onChangeKriniDolar = (e) => {
    this.setState({ PG_nrxeKrine$: e.target.value }, () => {
      this.setState({ PG_breKrini$: this.OngetDolar() });
    });
  };

  onChangebreParaDanbaID = (e) => {
    this.setState({ PG_breParabaID: e.target.value }, () => {
      this.setState({ PG_breKrini$: this.OngetDolar() });
    });
  };
  /////end of functions para Gorenawa

  // raporty ParaGorenawa
  RPG_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            RPG_openmodel: !this.state.RPG_openmodel,
            RPG_data: [],
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  RPG_CloseModal = () => {
    this.setState(() => {
      return { RPG_openmodel: false };
    });
  };

  RPG_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/paragorinawafromto?from=${this.state.RPG_from}&to=${this.state.RPG_to}`,
        config
      );

      this.setState({
        RPG_data: res.data.paragorinawakan,
      });
      this.RPG_CloseModal();
    } catch (error) {
      alert("بەروار داخل بکە");
    }
  };
  RPG_getParaDataBySearchMoney = async (e, zhmara) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchParaGorinawa?zhmara=${zhmara}`,
      config
    );

    const data = res.data.parakan;
    if (data === null) {
      alert("ببورە ئەم پسولە بونی نیە");
      this.setState({ PD_zhmaraybarez: 0 });
    } else {
      this.setState(() => {
        return {
          PG_search_wasl: true,
          PG_zhmara: data.zhmara,
          PG_barwar: data.date,
          PG_breKrini$: data.brikrinidolar,
          PG_nrxeKrine$: data.nrxikrinidolar,
          PG_breParabaID: data.briparabadinar,
          PG_nawewargr: data.nawiwargr,
          PG_note: data.note,
          PG_del_dis: true,
          PG_wasl: data,
          PG_toggler: false,
        };
      });
    }
  };

  R_toAnotherRow = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 5].focus();
  };

  G_toAnotherRow = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 5].focus();
  };

  R_toThirdRow = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 8].focus();
  };

  G_toThirdRow = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 8].focus();
  };

  R_toSavebyEnter = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index - 5].focus();
    this.saveDetail(e, this.state.R_wasl);
  };

  G_toSavebyEnter = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index - 5].focus();
    this.G_saveDetail(e, this.state.G_wasl);
  };

  RP_getPrint = () => {
    window.setTimeout("window.print()", 2000);
  };

  /**
  |--------------------------------------------------
  | Kashf 7isab
  |--------------------------------------------------
  */
  KF_getWakilDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchwakil?code=${this.state.KF_zhmaraybarez}&&Name=&&phone=`,
      config
    );

    const data = res.data.wakils;
    if (data === null) {
      alert("ببورە ئەم بەڕێزە بونی نیە");
      this.setState({
        KF_zhmaraybarez: 0,
        KF_nawe_barez: "",
      });
    } else {
      this.setState(() => {
        return {
          KF_zhmaraybarez: data.code,
          KF_nawe_barez: data.Name,
          KF_mob: data.phone,
          KF_Koy_Dinar: data.rejaDinar,
          KF_Koy_Dolar: data.rejaDolar,
        };
      });
    }
  };

  KF_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/kashf7isab?numberOfWakil=${this.state.KF_zhmaraybarez}&from=${this.state.KF_from}&to=${this.state.KF_to}`,
        config
      );
      const resg = await instance.get(
        `/api/v1/garanawaKashf7isab?numberOfWakil=${this.state.KF_zhmaraybarez}&from=${this.state.KF_from}&to=${this.state.KF_to}`,
        config
      );
      const resp = await instance.get(
        `/api/v1/kashf7isabpara?code=${this.state.KF_zhmaraybarez}&from=${this.state.KF_from}&to=${this.state.KF_to}`,
        config
      );
      let data = res.data.arr;
      let datag = resg.data.arr;
      let datap = resp.data.arr;
      this.setState({
        KF_datatable: [...data, ...datag, ...datap],
      });
      this.KF_CloseModal();
    } catch (error) {
      alert("There is No 7isab");
    }
  };
  KF_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            KF_openmodel: !this.state.KF_openmodel,
            KF_datatable: [],
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };
  KF_CloseModal = () => {
    this.setState(() => {
      return { KF_openmodel: false };
    });
  };
  KF_emprty = () => {
    this.setState(() => {
      return { KF_zhmaraybarez: "", KF_nawe_barez: "" };
    });
  };
  RKP_getPrint = () => {
    window.setTimeout("window.print()", 3000);
  };

  /// 3arze Kala Function
  HK_pshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get("/api/v1/getProducts", config);

      this.setState({
        HK_all3arzKala: res.data.products,
      });
      this.HK_CloseModal();
    } catch (error) {
      alert("هەڵە هەیە لە بەروار");
    }
  };

  HK_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            HK_modalOpen: !this.state.HK_modalOpen,
            HK_all3arzKala: [],
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  HK_CloseModal = () => {
    this.setState(() => {
      return { HK_modalOpen: false };
    });
  };

  /// 3arze Kala Function
  PS_pshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(`/api/v1/ProductSummeryReport?from=${this.state.PS_from}&to=${this.state.PS_to}`, config);

      this.setState({
        PS_all3arzKala: res.data.data,
      });
      this.PS_CloseModal();
    } catch (error) {
      alert("هەڵە هەیە لە بەروار");
    }
  };

  PS_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            PS_modalOpen: !this.state.PS_modalOpen,
            PS_all3arzKala: [],
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };

  PS_CloseModal = () => {
    this.setState(() => {
      return { PS_modalOpen: false };
    });
  };
  /**
   * Function of Buy Items
   */

  ///// loading product
  /// on refresh and open
  KM_onopenandrefresh = (data) => {
    this.setState(
      {
        KM_wasl: data,
        KM_search_wasl: true,
        KM_zh_wasl: data.numberofwasl,
        KM_nawe_barez: data.wakil,
        KM_mob: data.phone,
        KM_zh_barez: data.numberOfWakil,
        KM_total: data.koalldolar,
        KM_totalID: data.koalldinar,
        KM_dashkan: data.dashkandolar,
        KM_dashkanID: data.dashkandinar,
        KM_dataTable: data.saleTable,
        KM_joripara: data.joripara,
        KM_frosth: data.froshtn,
        KM_wargr: data.whossale,
        KM_paraDanID: data.paraydrawdinar,
        KM_paraDan$: data.paraydrawdolar,
        KM_kartonakan: data.koykartonkan,
        KM_kreysayara: data.kreysayra,
        KM_kreykrekar: data.kreykrekar,
        KM_koywaznkarton: data.waznakan,
        KM_paraywasl:
          data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
        KM_del_dis: null,
        KM_up_froshtn: data.froshtn,
        KM_date: data.date,
        KM_togler: false,
        KM_open: true,
        KM_joridolar: data.joridolar,
      },
      () => {
        this.KM_getALLkoygshty();
      }
    );
  };
  KM_saveLoaclStorageWasl = (wasl) => {
    let data = {
      // _id: wasl._id,
      numberOfWakil: Number(this.state.KM_zh_barez),
      wakil: this.state.KM_nawe_barez,
      phone: this.state.KM_mob,
      whossale: this.state.KM_wargr,
      joripara: this.state.KM_joripara,
      froshtn: this.state.KM_frosht,
      saleTable: this.state.KM_dataTable,
      koalldolar: this.state.KM_joripara === "دۆلار" ? this.state.KM_total : 0,
      paraydrawdolar:
        this.state.KM_joripara === "دۆلار" &&
        this.state.KM_paraDan$ !== "" &&
        this.state.KM_paraDan$ !== null
          ? parseFloat(this.state.KM_paraDan$.toString().replace(/,/g, ""))
          : 0,
      qarzinwedolar:
        this.state.KM_joripara === "دۆلار"
          ? this.state.KM_paraDan$ !== ""
            ? this.KM_waslKrdn()
            : this.state.KM_total
          : 0,
      koykartonkan: this.state.KM_kartonakan,
      date: this.state.KM_date === "" ? Date.now : this.state.KM_date,
    };
    localStorage.setItem("kren", JSON.stringify(data));
  };
  KM_hideLoader = () => {
    this.setState({ KM_loading: false });
  };

  KM_showLoader = () => {
    this.setState({ KM_loading: true });
  };
  KM_onSaveRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (!this.state.KM_dataTable) {
      alert("تکایە کاڵا داخیل بکە ");
      const sawtError = new Audio(errorAduio);
      sawtError.play();
      return false;
    } else if (this.state.KM_nawe_barez === "") {
      alert("تکایە ناوی بەڕێز پڕبکەوە");
      const sawtError = new Audio(errorAduio);
      sawtError.play();
    } else if (this.state.KM_wargr === "") {
      alert("تکایە خانەکان پڕبکەوە");
      const sawtError = new Audio(errorAduio);
      sawtError.play();
    } else {
      let data;
      data = {
        numberOfkas: Number(this.state.KM_zh_barez),
        name: this.state.KM_nawe_barez,
        phone: this.state.KM_mob,
        wargr: this.state.KM_wargr,
        joripara: this.state.KM_joripara,
        BuyTable: this.state.KM_dataTable,
        koalldolar:
          this.state.KM_joripara === "دۆلار" ? this.state.KM_total : 0,
        paraydraw:
          this.state.KM_joripara === "دۆلار" &&
          this.state.KM_paraDan$ !== "" &&
          this.state.KM_paraDan$ !== null
            ? parseFloat(this.state.KM_paraDan$.replace(/,/g, ""))
            : 0,
        paraynadraw:
          this.state.KM_joripara === "دۆلار"
            ? this.state.KM_paraDan$ !== ""
              ? this.KM_waslKrdn()
              : this.state.KM_total
            : 0,
        date: this.state.KM_date === "" ? Date.now : this.state.KM_date,
      };
      const res = await instance.post(`/api/v1/newBuy`, data, config);

      if (!res) {
        alert("error of new buy");
      }
      this.KM_showLoader();
      if (res) {
        this.KM_hideLoader();
      }

      localStorage.removeItem("kren");
      localStorage.removeItem("kitem");
      this.setState(
        {
          KM_togler: false,
          KM_save: true,
          KM_zh_wasl: res.data.newwasl.numberofwasl,
          KM_wasl: res.data.newwasl,
          KM_paraywasl: res.data.newwasl.qarzinwedolar,
          KM_del_dis: true,
          KM_date: res.data.newwasl.date,
          KM_loading: false,
        },
        () => {
          this.KM_getWaslakan();
          this.KM_hideLoader();
        }
      );
    }
  };
  KM_getWaslDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchBuy?numberofwasl=${Number(this.state.KM_zh_wasl)}`,
      config
    );

    const data = res.data.product;
    if (data === null) {
      alert("ببورە ئەم پسولەیە بونی نیە");
    } else {
      this.setState(
        () => {
          return {
            KM_wasl: data,
            KM_search_wasl: true,
            KM_zh_wasl: data.numberofwasl,
            KM_nawe_barez: data.name,
            KM_mob: data.phone,
            KM_zh_barez: data.numberOfkas,
            KM_total: data.koalldolar,
            KM_dataTable: data.BuyTable,
            KM_joripara: data.joripara,
            KM_wargr: data.wargr,
            KM_paraDan$: data.paraydraw,
            KM_paraywasl: data.paraynadraw,
            KM_del_dis: true,
            KM_togler: false,
            KM_date: data.date,
          };
        },
        () => {
          this.KM_getWakilDataBySearchRent(e);
        }
      );
    }
  };

  KM_setSaletSearchEmty = (e) => {
    e.preventDefault();
    this.setState({
      KM_zhmara: "",
      KM_naweSht: "",
      KM_karton: 0,
      KM_darzan: 0,
      KM_search: null,
      KM_rezha: 0,
      KM_nrx: 0,
      KM_suggestions: [],
    });
  };

  KM_calculateDarzan = () => {
    const zhmarayKarton = this.state.KM_rezha;
    const darzan = Number(this.state.KM_darzan);
    const karton = darzan / zhmarayKarton;
    return Number(karton).toFixed(4);
  };

  KM_DarzanChange = (e) => {
    this.setState(
      {
        darzan: e.target.value,
        KM_darzan: e.target.value,
      },
      () => {
        this.setState({
          karton: this.calculateDarzan(),
          KM_karton: this.KM_calculateDarzan(),
          koygshty: this.getkoygahty(this.state.darzan, this.state.karton),
          KM_nrx: this.KM_nrxRent(this.state.KM_darzan, this.state.KM_karton),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        [e.target.name]: "0.",
      });
    }
    this.ClearError();
  };

  KM_DeleteSail = async (id, table, index) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    this.setState(
      {
        KM_dataTable: this.state.KM_dataTable.filter((r, i) => i !== index),
      },
      async () => {
        if (this.state.KM_dataTable.length === 0) {
          localStorage.removeItem(
            "kitem",
            JSON.stringify(this.state.KM_dataTable)
          );
          this.setState({
            R_table: false,
          });
        }
        this.KM_saveLoaclStorageWasl(this.state.KM_wasl);
        if (this.state.KM_dataTable.length !== 0) {
          localStorage.setItem(
            "kitem",
            JSON.stringify(this.state.KM_dataTable)
          );
        }
        this.KM_getALLkoygshty();
      }
    );
  };

  KM_inputtogler = () => {
    this.setState({
      KM_togler: !this.state.KM_togler,
      KM_date: "",
    });
  };

  KM_getProductDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (this.state.KM_naweSht !== "") {
      this.toanotherInput(e);
    }

    const res = await instance.get(
      `/api/v1/searchtoproductByBarcodeName?name=${this.state.KM_naweSht}`,
      config
    );

    const data = res.data.product;
    if (data !== null) {
      this.setState(
        () => {
          return {
            KM_search: true,
            KM_productId: data._id,
            KM_naweSht: data.nameofproduct,
            KM_rezha: data.numberOfDarzan,
            KM_nrx_dolar: data.saleDolar,
            KM_nrx_dinarr: data.saleDinar,
            KM_nrx:
              this.state.KM_joripara === "دینار"
                ? data.saleDinar
                : data.saleDolar,
            KM_nrx_dolar3: data.saleDolar3,
            KM_nrx_dolar2: data.saleDolar2,
            KM_rasidKarton: data.rasidKarton,
            KM_qazanj:
              this.state.KM_joripara === "دینار"
                ? (
                    (this.dinartodolar(data.saleDinar) - data.price) *
                    data.numberOfDarzan
                  ).toFixed(2)
                : data.qazanj,
            KM_kren: data.price,
            KM_nrxkaton: (data.price * data.numberOfDarzan).toFixed(2),
            KM_waznkarton: data.weight,
            KM_zhmara: data.barcode,
            KM_suggestions: [],
          };
        },
        () => {
          this.setState({
            KM_nrx: this.KM_nrxRent(),
          });
        }
      );
    }
  };

  KM_tofirstInputINRent = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 4].focus();
    this.KM_saveDetail(e);
  };

  KM_anotherInputWakil = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index - 1].focus();
    this.KM_getWakilDataBySearchRent(e);
  };

  KM_getALLkoygshty = () => {
    let subTotal = 0;
    this.state.KM_dataTable.map((item) => (subTotal += item.kodolar));
    const cartTotals = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        KM_total: cartTotals,
      };
    });
  };

  KM_waslKrdn = () => {
    const wasl =
      this.state.KM_total -
      parseFloat(this.state.KM_paraDan$.toString().replace(/,/g, ""));
    return wasl.toFixed(2);
  };

  KM_calculateCarton = () => {
    const zhmarayKarton = this.state.KM_rezha;
    const carton = Number(this.state.KM_karton);
    const darzan = carton * zhmarayKarton;
    return Number(darzan).toFixed(4);
  };

  KM_nrxRent = () => {
    let nrx = Number(this.state.KM_nrx);
    return Number(nrx);
  };

  KM_CartonChange = (e) => {
    this.setState(
      {
        KM_karton: e.target.value,
      },
      () => {
        this.setState({
          KM_darzan: this.KM_calculateCarton(),
          KM_nrx: this.KM_nrxRent(this.state.KM_darzan, this.state.KM_karton),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        [e.target.name]: "0.",
      });
    }
  };

  KM_saveDetail = async (e) => {
    e.preventDefault();
    if (this.state.KM_karton === 0 || this.state.KM_joripara === null) {
      alert("!تکایە خانەکان پڕ بکەرەوە");
    } else {
      if (localStorage.token) {
        setToken(localStorage.token);
      }
      const config = {
        "Content-Type": "application/json",
      };
      let Table = this.state.KM_dataTable;
      Table.unshift({
        _id: this.state.KM_productId,
        raqam: this.state.KM_zhmara,
        nawisht: this.state.KM_naweSht,
        carton: Number(this.state.KM_karton),
        darzan: Number(this.state.KM_darzan),
        nrx: Number(this.state.KM_nrx),
        reja: this.state.KM_rezha,
        kodolar: this.KM_getkoygahty(
          this.state.KM_darzan,
          this.state.KM_karton
        ),
      });

      this.setState(
        {
          KM_dataTable: Table,
          KM_karton: 0,
          KM_darzan: 0,
          KM_nrx: 0,
          KM_naweSht: "",
          KM_rezha: 0,
          KM_koygshty$: 0,
          KM_zhmara: "",
          KM_search: null,
          KM_rasidKarton: 0,
          KM_kren: 0,
          KM_nrxkaton: 0,
        },
        () => {
          this.KM_saveLoaclStorageWasl(this.state.KM_wasl);
          if (this.state.KM_dataTable.length !== 0) {
            localStorage.setItem(
              "kitem",
              JSON.stringify(this.state.KM_dataTable)
            );
          }
          this.KM_getALLkoygshty();
        }
      );
    }
  };

  KM_getkoygahty = (darzan, karton) => {
    if (karton !== 0) {
      const nrx = Number(this.state.KM_kren);
      let koygshty = darzan * nrx;

      return Number(koygshty.toFixed(2));
    } else {
      const nrx = Number(this.state.KM_kren);
      let koygshty = darzan * nrx;

      return Number(koygshty.toFixed(2));
    }
  };

  KM_getWakilDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchpeople?code=${Number(this.state.KM_zh_barez)}&&Name=${
        this.state.KM_nawe_barez
      }`,
      config
    );

    const data = res.data.peoples;
    if (data === null) {
      alert("ببورە ئەم بەڕێزە بونی نیە");
      this.setState({
        KM_zh_barez: 0,
        KM_nawe_barez: "",
        KM_mob: 0,
        KM_rasidWakildolar: 0,
        KM_rasidWakildinar: 0,
      });
    } else {
      this.setState(() => {
        return {
          KM_search_wakil: true,
          KM_zh_barez: data.code,
          KM_nawe_barez: data.Name,
          KM_mob: data.phone,
          KM_rasidWakildolar: data.paradolar,
          KM_rasidWakildinar: data.paradinar,
        };
      });
    }
  };
  KM_getWaslakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/BuyWasls`, config);

    this.setState({
      KM_zh_wasl_katy: res.data.wasls + 1,
      KM_page: res.data.wasls + 1,
    });
  };

  KM_setSearchEmptyRentWakil = (e) => {
    e.preventDefault();
    this.setState({
      KM_search_wakil: null,
      KM_zh_barez: 0,
      KM_nawe_barez: "",
      KM_mob: 0,
      KM_rasidWakildolar: 0,
      KM_rasidWakildinar: 0,
    });
  };

  KM_createNewPage = (e) => {
    e.preventDefault();
    this.setState(
      {
        KM_nawe_barez: "",
        KM_mob: 0,
        KM_zh_barez: 0,
        KM_total: 0,
        KM_dataTable: [],
        KM_search: null,
        dinar: false,
        dolar: false,
        KM_search_wasl: null,
        KM_nwe: true,
        KM_joripara: null,
        KM_zhmara: "",
        KM_naweSht: "",
        KM_karton: 0,
        KM_darzan: 0,
        KM_save: null,
        KM_nrx: 0,
        KM_paraDan$: "",
        KM_search_wakil: null,
        KM_zh_wasl: 0,
        KM_wargr: null,
        KM_rezha: 0,
        KM_rasidKarton: 0,
        KM_up_wasl: null,
        KM_del_dis: null,
        KM_paraywasl: 0,
        KM_open: null,
        KM_wasl: null,
        KM_date: "",
      },
      () => {
        this.KM_getWaslakan();
      }
    );
    this.R_ClearError();
  };

  KM_pageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.KM_page + 1;
      const res = await instance.get(
        `/api/v1/BuyPage?page=${page}&limit=1`,
        config
      );

      const data = res.data.Buys;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            KM_page: page,
            KM_wasl: data,
            KM_search_wasl: true,
            KM_zh_wasl: data.numberofwasl,
            KM_nawe_barez: data.name,
            KM_mob: data.phone,
            KM_zh_barez: data.numberOfkas,
            KM_total: data.koalldolar,
            KM_dataTable: data.BuyTable,
            KM_joripara: data.joripara,
            KM_wargr: data.wargr,
            KM_paraDan$: data.paraydraw,
            KM_paraywasl: data.paraynadraw,
            KM_del_dis: true,
            KM_togler: false,
            KM_date: data.date,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  KM_pageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page;
      this.state.KM_page <= 1 ? (page = 1) : (page = this.state.KM_page - 1);
      const res = await instance.get(
        `/api/v1/BuyPage?page=${page}&limit=1`,
        config
      );

      const data = res.data.Buys;
      if (data.status === false) {
        alert("ببورە ئەم پسولە بونی نیە");
      } else {
        this.setState(() => {
          return {
            KM_page: page,
            KM_wasl: data,
            KM_search_wasl: true,
            KM_zh_wasl: data.numberofwasl,
            KM_nawe_barez: data.name,
            KM_mob: data.phone,
            KM_zh_barez: data.numberOfkas,
            KM_total: data.koalldolar,
            KM_dataTable: data.BuyTable,
            KM_joripara: data.joripara,
            KM_wargr: data.wargr,
            KM_paraDan$: data.paraydraw,
            KM_paraywasl: data.paraynadraw,
            KM_del_dis: true,
            KM_togler: false,
            KM_date: data.date,
          };
        });
      }
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  KM_getPrintPasswordRent = (NOW) => {
    const p = prompt("وشەی نهێنی داخیل بکە!");
    if (p === "55") {
      this.setState({ KM_pass: true }, () => {
        this.getPrint(NOW);
      });
    }
  };
  KM_Serach_ByName = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchName?nameofproduct=${this.state.KM_naweSht}`,
      config
    );
    if (this.state.KM_naweSht === "" || this.state.KM_naweSht === null) {
      this.setState({
        KM_suggestions: [],
      });
    }
    this.setState({
      KM_suggestions: res.data.search,
    });
  };

  KM_suggestionSelected = async (id) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(`/api/v1/searchtoproduct/${id}`, config);
      if (this.state.KM_naweSht === "" || this.state.KM_naweSht === null) {
        this.setState({
          KM_suggestions: [],
        });
      }
      const data = res.data.product;

      this.setState(() => {
        return {
          KM_search: true,
          KM_productId: data._id,
          KM_naweSht: data.nameofproduct,
          KM_rezha: data.numberOfDarzan,
          KM_nrx_dolar: data.saleDolar,
          KM_nrx_dinarr: data.saleDinar,
          KM_nrx:
            this.state.KM_joripara === "دینار"
              ? data.saleDinar
              : data.saleDolar,
          KM_nrx_dolar2: data.saleDolar2,
          KM_nrx_dolar3: data.saleDolar3,
          KM_rasidKarton: data.rasidKarton,
          KM_qazanj:
            this.state.KM_joripara === "دینار"
              ? (this.dinartodolar(data.saleDinar) - data.price).toFixed(2)
              : data.qazanj,
          KM_kren: data.price,
          KM_nrxkaton: (data.price * data.numberOfDarzan).toFixed(2),
          KM_waznkarton: data.weight,
          KM_zhmara: data.barcode,
          KM_suggestions: [],
        };
      });
    } catch (error) {
      alert("there is no patients");
    }
  };
  KM_Search_Koga_Handle = (e) => {
    this.setState(
      {
        KM_naweSht: e.target.value,
      },
      (e) => {
        if (
          !this.state.KM_naweSht ||
          this.state.KM_naweSht === "" ||
          this.state.KM_naweSht === null
        ) {
          this.setState({ KM_suggestions: [] });
        } else {
          this.KM_Serach_ByName();
        }
      }
    );
  };
  KM_GotoKren = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchBuy?numberofwasl=${Number(zhwasl)}`,
      config
    );

    const data = res.data.product;
    if (data === null) {
      alert("ببورە ئەم پسولەیە بونی نیە");
    } else {
      this.setState(
        () => {
          return {
            KM_wasl: data,
            KM_search_wasl: true,
            KM_zh_wasl: data.numberofwasl,
            KM_nawe_barez: data.name,
            KM_mob: data.phone,
            KM_zh_barez: data.numberOfkas,
            KM_total: data.koalldolar,
            KM_dataTable: data.BuyTable,
            KM_joripara: data.joripara,
            KM_wargr: data.wargr,
            KM_paraDan$: data.paraydraw,
            KM_paraywasl: data.paraynadraw,
            KM_del_dis: true,
            KM_togler: false,
            KM_date: data.date,
          };
        },
        () => {
          this.KM_getWakilDataBySearchRent(e);
        }
      );
    }
  };

  //end of garanawa bo koga

  /// on refresh and open
  BN_onopenandrefresh = (data) => {
    this.setState(
      {
        BN_wasl: data,
        BN_search_wasl: true,
        BN_zh_wasl: data.numberofwasl,
        BN_nawe_barez: data.wakil,
        BN_mob: data.phone,
        BN_zh_barez: data.numberOfWakil,
        BN_total: data.koalldolar,
        BN_totalID: data.koalldinar,
        BN_dashkan: data.dashkandolar,
        BN_dashkanID: data.dashkandinar,
        BN_dataTable: data.saleTable,
        BN_joripara: data.joripara,
        BN_frosth: data.froshtn,
        BN_wargr: data.whossale,
        BN_paraDanID: data.paraydrawdinar,
        BN_paraDan$: data.paraydrawdolar,
        BN_kartonakan: data.koykartonkan,
        BN_kreysayara: data.kreysayra,
        BN_kreykrekar: data.kreykrekar,
        BN_koywaznkarton: data.waznakan,
        BN_paraywasl:
          data.joripara === "دۆلار" ? data.qarzinwedolar : data.qarzinwedinar,
        BN_del_dis: null,
        BN_up_froshtn: data.froshtn,
        BN_date: data.date,
        BN_togler: false,
        BN_open: true,
        BN_joridolar: data.joridolar,
      },
      () => {
        this.BN_getALLkoygshty();
      }
    );
  };
  BN_saveLoaclStorageWasl = (wasl) => {
    let data = {
      // _id: wasl._id,
      numberOfWakil: Number(this.state.BN_zh_barez),
      wakil: this.state.BN_nawe_barez,
      phone: this.state.BN_mob,
      whossale: this.state.BN_wargr,
      joripara: this.state.BN_joripara,
      froshtn: this.state.BN_frosht,
      saleTable: this.state.BN_dataTable,
      koalldolar: this.state.BN_joripara === "دۆلار" ? this.state.BN_total : 0,
      paraydrawdolar:
        this.state.BN_joripara === "دۆلار" &&
        this.state.BN_paraDan$ !== "" &&
        this.state.BN_paraDan$ !== null
          ? parseFloat(this.state.BN_paraDan$.toString().replace(/,/g, ""))
          : 0,
      qarzinwedolar:
        this.state.BN_joripara === "دۆلار"
          ? this.state.BN_paraDan$ !== ""
            ? this.BN_waslKrdn()
            : this.state.BN_total
          : 0,
      koykartonkan: this.state.BN_kartonakan,
      date: this.state.BN_date === "" ? Date.now : this.state.BN_date,
    };
    localStorage.setItem("kren", JSON.stringify(data));
  };
  BN_hideLoader = () => {
    this.setState({ BN_loading: false });
  };

  BN_showLoader = () => {
    this.setState({ BN_loading: true });
  };
  BN_onSaveRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (this.state.BN_dataTable.length === 0) {
      alert("تکایە کاڵا داخیل بکە ");
      const sawtError = new Audio(errorAduio);
      sawtError.play();
      return false;
    } else {
      let data;
      data = {
        numberOfkas: Number(this.state.BN_zh_barez),
        saleTable: this.state.BN_dataTable,
        koalldolar:
          this.state.BN_joripara === "دۆلار" ? this.state.BN_total : 0,
        date: this.state.BN_date === "" ? Date.now : this.state.BN_date,
      };
      const res = await instance.post(`/api/v1/newBroken`, data, config);

      if (!res) {
        alert("error of new buy");
      }
      this.BN_showLoader();
      if (res) {
        this.BN_hideLoader();
      }

      localStorage.removeItem("kren");
      localStorage.removeItem("kitem");
      this.setState(
        {
          BN_togler: false,
          BN_save: true,
          BN_zh_wasl: res.data.newwasl.numberofwasl,
          BN_wasl: res.data.newwasl,
          BN_paraywasl: res.data.newwasl.qarzinwedolar,
          BN_del_dis: true,
          BN_date: res.data.newwasl.date,
          BN_loading: false,
        },
        () => {
          this.BN_getWaslakan();
          this.BN_hideLoader();
        }
      );
    }
  };
  BN_getWaslDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchBroken?numberofwasl=${Number(this.state.BN_zh_wasl)}`,
      config
    );

    const data = res.data.product;
    this.setState(
      () => {
        return {
          BN_wasl: data,
          BN_search_wasl: true,
          BN_zh_wasl: data.numberofwasl,
          BN_nawe_barez: data.name,
          BN_mob: data.phone,
          BN_zh_barez: data.numberOfkas,
          BN_total: data.koalldolar,
          BN_dataTable: data.saleTable,
          BN_del_dis: true,
          BN_togler: false,
          BN_date: data.createdAt,
        };
      },
      () => {
        this.BN_getWakilDataBySearchRent(e);
      }
    );
  };

  BN_setSaletSearchEmty = (e) => {
    e.preventDefault();
    this.setState({
      BN_zhmara: "",
      BN_naweSht: "",
      BN_karton: 0,
      BN_darzan: 0,
      BN_search: null,
      BN_rezha: 0,
      BN_nrx: 0,
      BN_suggestions: [],
    });
  };

  BN_calculateDarzan = () => {
    const zhmarayKarton = this.state.BN_rezha;
    const darzan = Number(this.state.BN_darzan);
    const karton = darzan / zhmarayKarton;
    return Number(karton).toFixed(4);
  };

  BN_DarzanChange = (e) => {
    this.setState(
      {
        darzan: e.target.value,
        BN_darzan: e.target.value,
      },
      () => {
        this.setState({
          karton: this.calculateDarzan(),
          BN_karton: this.BN_calculateDarzan(),
          koygshty: this.getkoygahty(this.state.darzan, this.state.karton),
          BN_nrx: this.BN_nrxRent(this.state.BN_darzan, this.state.BN_karton),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        [e.target.name]: "0.",
      });
    }
    this.ClearError();
  };

  BN_DeleteSail = async (id, table, index) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    this.setState(
      {
        BN_dataTable: this.state.BN_dataTable.filter((r, i) => i !== index),
      },
      async () => {
        if (this.state.BN_dataTable.length === 0) {
          localStorage.removeItem(
            "kitem",
            JSON.stringify(this.state.BN_dataTable)
          );
          this.setState({
            R_table: false,
          });
        }
        this.BN_getALLkoygshty();
      }
    );
  };

  BN_inputtogler = () => {
    this.setState({
      BN_togler: !this.state.BN_togler,
      BN_date: "",
    });
  };

  BN_getProductDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    if (this.state.BN_naweSht !== "") {
      this.toanotherInput(e);
    }

    const res = await instance.get(
      `/api/v1/searchtoproductByBarcodeName?name=${this.state.BN_naweSht}`,
      config
    );

    const data = res.data.product;
    if (data !== null) {
      this.setState(
        () => {
          return {
            BN_search: true,
            BN_productId: data._id,
            BN_naweSht: data.nameofproduct,
            BN_rezha: data.numberOfDarzan,
            BN_nrx_dolar: data.saleDolar,
            BN_nrx_dinarr: data.saleDinar,
            BN_nrx:
              this.state.BN_joripara === "دینار"
                ? data.saleDinar
                : data.saleDolar,
            BN_nrx_dolar3: data.saleDolar3,
            BN_nrx_dolar2: data.saleDolar2,
            BN_rasidKarton: data.rasidKarton,
            BN_qazanj:
              this.state.BN_joripara === "دینار"
                ? (
                    (this.dinartodolar(data.saleDinar) - data.price) *
                    data.numberOfDarzan
                  ).toFixed(2)
                : data.qazanj,
            BN_kren: data.price,
            BN_nrxkaton: (data.price * data.numberOfDarzan).toFixed(2),
            BN_waznkarton: data.weight,
            BN_zhmara: data.barcode,
            BN_suggestions: [],
          };
        },
        () => {
          this.setState({
            BN_nrx: this.BN_nrxRent(),
          });
        }
      );
    }
  };

  BN_tofirstInputINRent = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index + 4].focus();
    this.BN_saveDetail(e);
  };

  BN_anotherInputWakil = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const index = Array.prototype.indexOf.call(form, e.target);
    form.elements[index - 1].focus();
    this.BN_getWakilDataBySearchRent(e);
  };

  BN_getALLkoygshty = () => {
    let subTotal = 0;
    this.state.BN_dataTable.map((item) => (subTotal += item.kodolar));
    const cartTotals = Number(subTotal.toFixed(2));
    this.setState(() => {
      return {
        BN_total: cartTotals,
      };
    });
  };

  BN_waslKrdn = () => {
    const wasl =
      this.state.BN_total -
      parseFloat(this.state.BN_paraDan$.toString().replace(/,/g, ""));
    return wasl.toFixed(2);
  };

  BN_calculateCarton = () => {
    const zhmarayKarton = this.state.BN_rezha;
    const carton = Number(this.state.BN_karton);
    const darzan = carton * zhmarayKarton;
    return Number(darzan).toFixed(4);
  };

  BN_nrxRent = () => {
    let nrx = Number(this.state.BN_nrx);
    return Number(nrx);
  };

  BN_CartonChange = (e) => {
    this.setState(
      {
        BN_karton: e.target.value,
      },
      () => {
        this.setState({
          BN_darzan: this.BN_calculateCarton(),
          BN_nrx: this.BN_nrxRent(this.state.BN_darzan, this.state.BN_karton),
        });
      }
    );
    if (e.target.value === ".") {
      this.setState({
        [e.target.name]: "0.",
      });
    }
  };

  BN_saveDetail = async (e) => {
    e.preventDefault();
    if (this.state.BN_karton === 0) {
      alert("!تکایە خانەکان پڕ بکەرەوە");
    } else {
      if (localStorage.token) {
        setToken(localStorage.token);
      }
      let Table = this.state.BN_dataTable;
      Table.unshift({
        _id: this.state.BN_productId,
        raqam: this.state.BN_zhmara,
        nawisht: this.state.BN_naweSht,
        carton: Number(this.state.BN_karton),
        darzan: Number(this.state.BN_darzan),
        nrx: Number(this.state.BN_nrx),
        reja: this.state.BN_rezha,
        kodolar: this.BN_getkoygahty(
          this.state.BN_darzan,
          this.state.BN_karton
        ),
      });

      this.setState(
        {
          BN_dataTable: Table,
          BN_karton: 0,
          BN_darzan: 0,
          BN_nrx: 0,
          BN_naweSht: "",
          BN_rezha: 0,
          BN_koygshty$: 0,
          BN_zhmara: "",
          BN_search: null,
          BN_rasidKarton: 0,
          BN_kren: 0,
          BN_nrxkaton: 0,
        },
        () => {
          this.BN_getALLkoygshty();
        }
      );
    }
  };

  BN_getkoygahty = (darzan, karton) => {
    if (karton !== 0) {
      const nrx = Number(this.state.BN_kren);
      let koygshty = darzan * nrx;

      return Number(koygshty.toFixed(2));
    } else {
      const nrx = Number(this.state.BN_kren);
      let koygshty = darzan * nrx;

      return Number(koygshty.toFixed(2));
    }
  };

  BN_getWakilDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchpeople?code=${Number(this.state.BN_zh_barez)}&&Name=${
        this.state.BN_nawe_barez
      }`,
      config
    );

    const data = res.data.peoples;
    if (data === null) {
      alert("ببورە ئەم بەڕێزە بونی نیە");
      this.setState({
        BN_zh_barez: 0,
        BN_nawe_barez: "",
        BN_mob: 0,
        BN_rasidWakildolar: 0,
        BN_rasidWakildinar: 0,
      });
    } else {
      this.setState(() => {
        return {
          BN_search_wakil: true,
          BN_zh_barez: data.code,
          BN_nawe_barez: data.Name,
          BN_mob: data.phone,
          BN_rasidWakildolar: data.paradolar,
          BN_rasidWakildinar: data.paradinar,
        };
      });
    }
  };
  BN_getWaslakan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(`/api/v1/BrokenWasls`, config);

    this.setState({
      BN_zh_wasl_katy: res.data.wasls + 1,
      BN_page: res.data.wasls + 1,
    });
  };

  BN_setSearchEmptyRentWakil = (e) => {
    e.preventDefault();
    this.setState({
      BN_search_wakil: null,
      BN_zh_barez: 0,
      BN_nawe_barez: "",
      BN_mob: 0,
      BN_rasidWakildolar: 0,
      BN_rasidWakildinar: 0,
    });
  };

  BN_createNewPage = (e) => {
    e.preventDefault();
    this.setState(
      {
        BN_nawe_barez: "",
        BN_mob: 0,
        BN_zh_barez: 0,
        BN_total: 0,
        BN_dataTable: [],
        BN_search: null,
        dinar: false,
        dolar: false,
        BN_search_wasl: null,
        BN_nwe: true,
        BN_joripara: null,
        BN_zhmara: "",
        BN_naweSht: "",
        BN_karton: 0,
        BN_darzan: 0,
        BN_save: null,
        BN_nrx: 0,
        BN_paraDan$: "",
        BN_search_wakil: null,
        BN_zh_wasl: 0,
        BN_wargr: null,
        BN_rezha: 0,
        BN_rasidKarton: 0,
        BN_up_wasl: null,
        BN_del_dis: null,
        BN_paraywasl: 0,
        BN_open: null,
        BN_wasl: null,
        BN_date: "",
      },
      () => {
        this.BN_getWaslakan();
      }
    );
    this.R_ClearError();
  };

  BN_pageplusone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page = this.state.BN_page + 1;
      const res = await instance.get(
        `/api/v1/BrokenPage?page=${page}&limit=1`,
        config
      );

      const data = res.data.Brokens;
      this.setState(() => {
        return {
          BN_page: page,
          BN_wasl: data,
          BN_search_wasl: true,
          BN_zh_wasl: data.numberofwasl,
          BN_nawe_barez: data.name,
          BN_mob: data.phone,
          BN_zh_barez: data.numberOfkas,
          BN_total: data.koalldolar,
          BN_dataTable: data.saleTable,
          BN_joripara: data.joripara,
          BN_wargr: data.wargr,
          BN_paraDan$: data.paraydraw,
          BN_paraywasl: data.paraynadraw,
          BN_del_dis: true,
          BN_togler: false,
          BN_date: data.createdAt,
        };
      });
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  BN_pageminsone = async (e) => {
    e.preventDefault();
    const config = {
      "Content-Type": "application/json",
    };
    try {
      let page;
      this.state.BN_page <= 1 ? (page = 1) : (page = this.state.BN_page - 1);
      const res = await instance.get(
        `/api/v1/BrokenPage?page=${page}&limit=1`,
        config
      );

      const data = res.data.Brokens;
      this.setState(() => {
        return {
          BN_page: page,
          BN_wasl: data,
          BN_search_wasl: true,
          BN_zh_wasl: data.numberofwasl,
          BN_nawe_barez: data.name,
          BN_mob: data.phone,
          BN_zh_barez: data.numberOfkas,
          BN_total: data.koalldolar,
          BN_dataTable: data.saleTable,
          BN_del_dis: true,
          BN_togler: false,
          BN_date: data.createdAt,
        };
      });
    } catch (error) {
      alert("ببورە ئەم پسولە بونی نیە");
    }
  };

  BN_getPrintPasswordRent = (NOW) => {
    const p = prompt("وشەی نهێنی داخیل بکە!");
    if (p === "55") {
      this.setState({ BN_pass: true }, () => {
        this.getPrint(NOW);
      });
    }
  };
  BN_Serach_ByName = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchName?nameofproduct=${this.state.BN_naweSht}`,
      config
    );
    if (this.state.BN_naweSht === "" || this.state.BN_naweSht === null) {
      this.setState({
        BN_suggestions: [],
      });
    }
    this.setState({
      BN_suggestions: res.data.search,
    });
  };

  BN_suggestionSelected = async (id) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(`/api/v1/searchtoproduct/${id}`, config);
      if (this.state.BN_naweSht === "" || this.state.BN_naweSht === null) {
        this.setState({
          BN_suggestions: [],
        });
      }
      const data = res.data.product;

      this.setState(() => {
        return {
          BN_search: true,
          BN_productId: data._id,
          BN_naweSht: data.nameofproduct,
          BN_rezha: data.numberOfDarzan,
          BN_nrx_dolar: data.saleDolar,
          BN_nrx_dinarr: data.saleDinar,
          BN_nrx:
            this.state.BN_joripara === "دینار"
              ? data.saleDinar
              : data.saleDolar,
          BN_nrx_dolar2: data.saleDolar2,
          BN_nrx_dolar3: data.saleDolar3,
          BN_rasidKarton: data.rasidKarton,
          BN_qazanj:
            this.state.BN_joripara === "دینار"
              ? (this.dinartodolar(data.saleDinar) - data.price).toFixed(2)
              : data.qazanj,
          BN_kren: data.price,
          BN_nrxkaton: (data.price * data.numberOfDarzan).toFixed(2),
          BN_waznkarton: data.weight,
          BN_zhmara: data.barcode,
          BN_suggestions: [],
        };
      });
    } catch (error) {
      alert("there is no patients");
    }
  };
  BN_Search_Koga_Handle = (e) => {
    this.setState(
      {
        BN_naweSht: e.target.value,
      },
      (e) => {
        if (
          !this.state.BN_naweSht ||
          this.state.BN_naweSht === "" ||
          this.state.BN_naweSht === null
        ) {
          this.setState({ BN_suggestions: [] });
        } else {
          this.BN_Serach_ByName();
        }
      }
    );
  };
  BN_GotoKren = async (e, zhwasl) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };

    const res = await instance.get(
      `/api/v1/searchBroken?numberofwasl=${Number(zhwasl)}`,
      config
    );

    const data = res.data.product;
    if (data === null) {
      alert("ببورە ئەم پسولەیە بونی نیە");
    } else {
      this.setState(
        () => {
          return {
            BN_wasl: data,
            BN_search_wasl: true,
            BN_zh_wasl: data.numberofwasl,
            BN_nawe_barez: data.name,
            BN_mob: data.phone,
            BN_zh_barez: data.numberOfkas,
            BN_total: data.koalldolar,
            BN_dataTable: data.BuyTable,
            BN_joripara: data.joripara,
            BN_wargr: data.wargr,
            BN_paraDan$: data.paraydraw,
            BN_paraywasl: data.paraynadraw,
            BN_del_dis: true,
            BN_togler: false,
            BN_date: data.date,
          };
        },
        () => {
          this.BN_getWakilDataBySearchRent(e);
        }
      );
    }
  };

  //end of garanawa bo koga

  /**
|--------------------------------------------------
| Kashf 7isab Kasakn
|--------------------------------------------------
*/
  KT_getWakilDataBySearchRent = async (e) => {
    e.preventDefault();
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    const res = await instance.get(
      `/api/v1/searchpeople?code=${this.state.KT_zhmaraybarez}&&Name=&&phone=`,
      config
    );

    const data = res.data.peoples;
    if (data === null) {
      alert("ببورە ئەم بەڕێزە بونی نیە");
      this.setState({
        KT_zhmaraybarez: 0,
        KT_nawe_barez: "",
      });
    } else {
      this.setState(() => {
        return {
          KT_zhmaraybarez: data.code,
          KT_nawe_barez: data.Name,
          KT_mob: data.phone,
          KT_Koy_Dinar: data.paradinar,
          KT_Koy_Dolar: data.paradolar,
        };
      });
    }
  };

  KT_peshandan = async () => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const res = await instance.get(
        `/api/v1/kasKashf7isab/${this.state.KT_zhmaraybarez}?from=${this.state.KT_from}&to=${this.state.KT_to}`,
        config
      );
      let data = res.data.arr;
      this.setState({
        KT_datatable: data,
      });
      this.KT_CloseModal();
    } catch (error) {
      alert("There is No 7isab");
    }
  };
  KT_OpenModal = async (e, userid) => {
    if (localStorage.token) {
      setToken(localStorage.token);
    }
    const config = {
      "Content-Type": "application/json",
    };
    try {
      const { value: text } = await Swal.fire({
        input: "password",
        inputLabel: "Password",
        showLoaderOnConfirm: true,
        inputPlaceholder: "وشەی نهێنی داخیل بکە...",
        inputAttributes: {
          "aria-label": "وشەی نهێنی داخیل بکە",
        },
        showCancelButton: true,
      });
      const data = {
        password: text,
      };
      const pass = await instance.post(
        `api/v1/open?id=${userid}`,
        data,
        config
      );

      if (pass.data.status === false) {
        alert("وشەی نهێنی هەڵەیە");
      } else if (pass.data.status === true) {
        this.setState(() => {
          return {
            KT_openmodel: true,
            KT_datatable: [],
            KT_zhmaraybarez: "",
            KT_nawe_barez: "",
          };
        });
      }
    } catch (error) {
      alert("وشەی نهێنی هەڵەیە");
    }
  };
  KT_CloseModal = () => {
    this.setState(() => {
      return { KT_openmodel: false };
    });
  };
  KT_emprty = () => {
    this.setState(() => {
      return { KT_zhmaraybarez: "", KT_nawe_barez: "" };
    });
  };
  KT_getPrint = () => {
    window.setTimeout("window.print()", 3000);
  };

  render() {
    return (
      <>
        <ToastContainer></ToastContainer>
        <ProductContext.Provider
          value={{
            ...this.state,
            // Raporty Kashfe Taka kas
            KT_getWakilDataBySearchRent: this.KT_getWakilDataBySearchRent,
            KT_peshandan: this.KT_peshandan,
            KT_OpenModal: this.KT_OpenModal,
            KT_CloseModal: this.KT_CloseModal,
            KT_emprty: this.KT_emprty,
            KT_getPrint: this.KT_getPrint,
            // Krini mawad Function
            KM_hideLoader: this.KM_hideLoader,
            KM_showLoader: this.KM_showLoader,
            KM_DeleteSail: this.KM_DeleteSail,
            KM_pageplusone: this.KM_pageplusone,
            KM_onSaveRent: this.KM_onSaveRent,
            KM_pageminsone: this.KM_pageminsone,
            KM_getPrintPasswordRent: this.KM_getPrintPasswordRent,
            KM_waslKrdnID: this.KM_waslKrdnID,
            KM_waslKrdn: this.KM_waslKrdn,
            KM_getkoygahty: this.KM_getkoygahty,
            KM_Search_Koga_Handle: this.KM_Search_Koga_Handle,
            KM_suggestionSelected: this.KM_suggestionSelected,
            KM_nrxchange: this.KM_nrxchange,
            KM_inputtogler: this.KM_inputtogler,
            KM_setSaletSearchEmty: this.KM_setSaletSearchEmty,
            KM_setSearchEmptyRentWakil: this.KM_setSearchEmptyRentWakil,
            KM_saveDetail: this.KM_saveDetail,
            KM_getProductDataBySearchRent: this.KM_getProductDataBySearchRent,
            KM_getWaslDataBySearchRent: this.KM_getWaslDataBySearchRent,
            KM_anotherInputWakil: this.KM_anotherInputWakil,
            KM_createNewPage: this.KM_createNewPage,
            KM_tofirstInputINRent: this.KM_tofirstInputINRent,
            KM_CartonChange: this.KM_CartonChange,
            KM_DarzanChange: this.KM_DarzanChange,
            KM_GotoKren: this.KM_GotoKren,
            // Krini mawad Function
            BN_hideLoader: this.BN_hideLoader,
            BN_showLoader: this.BN_showLoader,
            BN_DeleteSail: this.BN_DeleteSail,
            BN_pageplusone: this.BN_pageplusone,
            BN_onSaveRent: this.BN_onSaveRent,
            BN_pageminsone: this.BN_pageminsone,
            BN_getPrintPasswordRent: this.BN_getPrintPasswordRent,
            BN_waslKrdnID: this.BN_waslKrdnID,
            BN_waslKrdn: this.BN_waslKrdn,
            BN_getkoygahty: this.BN_getkoygahty,
            BN_Search_Koga_Handle: this.BN_Search_Koga_Handle,
            BN_suggestionSelected: this.BN_suggestionSelected,
            BN_nrxchange: this.BN_nrxchange,
            BN_inputtogler: this.BN_inputtogler,
            BN_setSaletSearchEmty: this.BN_setSaletSearchEmty,
            BN_setSearchEmptyRentWakil: this.BN_setSearchEmptyRentWakil,
            BN_saveDetail: this.BN_saveDetail,
            BN_getProductDataBySearchRent: this.BN_getProductDataBySearchRent,
            BN_getWaslDataBySearchRent: this.BN_getWaslDataBySearchRent,
            BN_anotherInputWakil: this.BN_anotherInputWakil,
            BN_createNewPage: this.BN_createNewPage,
            BN_tofirstInputINRent: this.BN_tofirstInputINRent,
            BN_CartonChange: this.BN_CartonChange,
            BN_DarzanChange: this.BN_DarzanChange,
            BN_GotoKren: this.BN_GotoKren,
            //
            HK_pshandan: this.HK_pshandan,
            HK_OpenModal: this.HK_OpenModal,
            HK_CloseModal: this.HK_CloseModal,
            RKP_getPrint: this.RKP_getPrint,
            KF_CloseModal: this.KF_CloseModal,
            KF_emprty: this.KF_emprty,
            KF_OpenModal: this.KF_OpenModal,
            KF_peshandan: this.KF_peshandan,
            KF_getWakilDataBySearchRent: this.KF_getWakilDataBySearchRent,
            // Raporty ParaGorenawa
            // Raporty ParaGorenawa
            RPG_CloseModal: this.RPG_CloseModal,
            RPG_OpenModal: this.RPG_OpenModal,
            RPG_peshandan: this.RPG_peshandan,
            RPG_getParaDataBySearchMoney: this.RPG_getParaDataBySearchMoney,
            //ParaGorenawa Functions
            onChangebreParaDanbaID: this.onChangebreParaDanbaID,
            PG_pageminsone: this.PG_pageminsone,
            PG_pageplusone: this.PG_pageplusone,
            PG_inputtogler: this.PG_inputtogler,
            PG_openUpdate: this.PG_openUpdate,
            PG_showrasid: this.PG_showrasid,
            PG_updtate_para: this.PG_updtate_para,
            PG_getParaDataBySearchMoney: this.PG_getParaDataBySearchMoney,
            onChangeKriniDolar: this.onChangeKriniDolar,
            PG_onSaveMoney: this.PG_onSaveMoney,
            PG_getPrint: this.PG_getPrint,
            PG_createNewMony: this.PG_createNewMony,
            ///functions OF QARZI Asto
            QA_OpenModal: this.QA_OpenModal,
            KoyqarziAssto: this.KoyqarziAssto,
            QA_CloseModal: this.QA_CloseModal,
            ///search by name functions
            SearchWakilOldRasid: this.SearchWakilOldRasid,
            SaveOldRasid: this.SaveOldRasid,
            NewOldRasid: this.NewOldRasid,
            OpenOldRasid: this.OpenOldRasid,
            Search_Jarde_Koga_Handle: this.Search_Jarde_Koga_Handle,
            K_suggestionSelected: this.K_suggestionSelected,
            G_suggestionSelected: this.G_suggestionSelected,
            Search_Koga_Handle: this.Search_Koga_Handle,
            Search_Rent_Handle: this.Search_Rent_Handle,
            R_suggestionSelected: this.R_suggestionSelected,
            R_hanldeKeydown: this.R_hanldeKeydown,
            R_hanldeKeyup: this.R_hanldeKeyup,
            R_handleListKeydown: this.R_handleListKeydown,
            Search_Product_Handle: this.Search_Product_Handle,
            suggestionSelected: this.suggestionSelected,
            RC_Serach_ByName: this.RC_Serach_ByName,
            RC_suggestionSelected: this.RC_suggestionSelected,
            Search_Raporty_cin_Handle: this.Search_Raporty_cin_Handle,
            //// loading dunction
            ProducthideLoader: this.ProducthideLoader,
            ProductshowLoader: this.ProductshowLoader,
            P_hideLoader: this.P_hideLoader,
            P_showLoader: this.P_showLoader,
            hideLoader: this.hideLoader,
            showLoader: this.showLoader,
            G_showLoader: this.G_showLoader,
            G_hideLoader: this.G_hideLoader,
            // functions of raporty parawargrtnawakan
            RPW_getParaDataBySearchMoney: this.RPW_getParaDataBySearchMoney,
            RPW_peshandan: this.RPW_peshandan,
            RPW_OpenModal: this.RPW_OpenModal,
            RPW_CloseModal: this.RPW_CloseModal,
            ////functions of parawargrtn
            PW_onSaveMoney: this.PW_onSaveMoney,
            PW_getParaDataBySearchMoney: this.PW_getParaDataBySearchMoney,
            PW_pageminsone: this.PW_pageminsone,
            PW_pageplusone: this.PW_pageplusone,
            PW_createNewMony: this.PW_createNewMony,
            PW_getParakan: this.PW_getParakan,
            PW_updtate_para: this.PW_updtate_para,
            PW_inputtogler: this.PW_inputtogler,
            PW_openUpdate: this.PW_openUpdate,
            PW_getPrint: this.PW_getPrint,
            //Raporty Mandubakan
            RMM_getParaDataBySearchMoney: this.RMM_getParaDataBySearchMoney,
            RMM_peshandan: this.RMM_peshandan,
            RMM_CloseModal: this.RMM_CloseModal,
            RMM_OpenModal: this.RMM_OpenModal,
            // raporty tak kas
            RT_gotoParadanakan: this.RT_gotoParadanakan,
            RT_gotoMandub: this.RT_gotoMandub,
            RT_mandubakan: this.RT_mandubakan,
            RT_Paradanakan: this.RT_Paradanakan,
            RT_peshandan: this.RT_peshandan,
            RT_OpenModal: this.RT_OpenModal,
            RT_CloseModal: this.RT_CloseModal,
            RT_setstInputEmpty: this.RT_setstInputEmpty,
            RT_serchwakil: this.RT_serchwakil,
            // raporty masrufat
            RMS_OpenModal: this.RMS_OpenModal,
            RMS_CloseModal: this.RMS_CloseModal,
            RMS_peshandan: this.RMS_peshandan,
            // raporty paradarhenan
            RPM_OpenModal: this.RPM_OpenModal,
            RPM_CloseModal: this.RPM_CloseModal,
            RPM_peshandan: this.RPM_peshandan,
            RPM_getParaDataBySearchMoney: this.RPM_getParaDataBySearchMoney,
            // raporty paradan
            RPD_OpenModal: this.RPD_OpenModal,
            RPD_CloseModal: this.RPD_CloseModal,
            RPD_peshandan: this.RPD_peshandan,
            RPD_getParaDataBySearchMoney: this.RPD_getParaDataBySearchMoney,
            ////money mandubs
            MM_onSaveMoney: this.MM_onSaveMoney,
            MM_getWakilDataBySearchMoney: this.MM_getWakilDataBySearchMoney,
            MM_getParaDataBySearchMoney: this.MM_getParaDataBySearchMoney,
            MM_createNewMony: this.MM_createNewMony,
            MM_setSearchMonyWakilEmpty: this.MM_setSearchMonyWakilEmpty,
            MM_openUpdate: this.MM_openUpdate,
            MM_updtate_para: this.MM_updtate_para,
            MM_pageplusone: this.MM_pageplusone,
            MM_pageminsone: this.MM_pageminsone,
            MM_inputtogler: this.MM_inputtogler,
            MM_getPrint: this.MM_getPrint,
            MM_getIsChecked: this.MM_getIsChecked,
            MM_peshandaniQarzyPeshw: this.MM_peshandaniQarzyPeshw,

            ////functions of paradarhenan
            PM_onSaveMoney: this.PM_onSaveMoney,
            PM_getParaDataBySearchMoney: this.PM_getParaDataBySearchMoney,
            PM_pageminsone: this.PM_pageminsone,
            PM_pageplusone: this.PM_pageplusone,
            PM_createNewMony: this.PM_createNewMony,
            PM_getParakan: this.PM_getParakan,
            PM_updtate_para: this.PM_updtate_para,
            PM_inputtogler: this.PM_inputtogler,
            PM_openUpdate: this.PM_openUpdate,
            PM_getPrint: this.PM_getPrint,

            ////functions of pardan
            PD_onSaveMoney: this.PD_onSaveMoney,
            PD_getParaDataBySearchMoney: this.PD_getParaDataBySearchMoney,
            PD_pageminsone: this.PD_pageminsone,
            PD_pageplusone: this.PD_pageplusone,
            PD_createNewMony: this.PD_createNewMony,
            PD_setSearchMonyWakilEmpty: this.PD_setSearchMonyWakilEmpty,
            PD_getParakan: this.PD_getParakan,
            PD_getkasDataBySearchMoney: this.PD_getkasDataBySearchMoney,
            PD_updtate_para: this.PD_updtate_para,
            PD_inputtogler: this.PD_inputtogler,
            PD_openUpdate: this.PD_openUpdate,
            PD_getPrint: this.PD_getPrint,
            PD_getIsChecked: this.PD_getIsChecked,
            PD_peshandaniQarzyPeshw: this.PD_peshandaniQarzyPeshw,
            // functions of masrufat
            Ms_setWakilEmpty: this.Ms_setWakilEmpty,
            Ms_tofirstInput: this.Ms_tofirstInput,
            Ms_onSaveWakel: this.Ms_onSaveWakel,
            Ms_clickOnDeleteWakel: this.Ms_clickOnDeleteWakel,
            Ms_getWakiltableData: this.Ms_getWakiltableData,
            Ms_updtae_Wakil: this.Ms_updtae_Wakil,
            Ms_showrasid: this.Ms_showrasid,
            getWakiltableData: this.getWakiltableData,
            // functions of add people
            Ap_updtae_Wakil: this.Ap_updtae_Wakil,
            Ap_clickOnDeleteWakel: this.Ap_clickOnDeleteWakel,
            Ap_tofirstInput: this.Ap_tofirstInput,
            Ap_setWakilEmpty: this.Ap_setWakilEmpty,
            Ap_getWakilDataBySearchWakil: this.Ap_getWakilDataBySearchWakil,
            Ap_onSaveWakel: this.Ap_onSaveWakel,
            Ap_showrasid: this.Ap_showrasid,
            /////input pass
            toanotherInput: this.toanotherInput,
            Add_toanotherInput: this.Add_toanotherInput,
            //
            IO_OpenModal: this.IO_OpenModal,
            IO_CloseModal: this.IO_CloseModal,
            IO_peshandan: this.IO_peshandan,
            /////user
            L_ClearError: this.L_ClearError,
            loginuser: this.loginuser,
            ///////////////add ptoduct functions
            newPage: this.newPage,
            clickOnDelete: this.clickOnDelete,
            DolarChange: this.DolarChange,
            AddProChange: this.AddProChange,
            dolartodinar: this.dolartodinar,
            ClearError: this.ClearError,
            refreshPage: this.refreshPage,
            getkoygahty: this.getkoygahty,
            onSaveTable: this.onSaveTable,
            edit_product: this.edit_product,
            clear_edit: this.clear_edit,
            calculateCarton: this.calculateCarton,
            calculateDarzan: this.calculateDarzan,
            onSave: this.onSave,
            getProductDataBySearch: this.getProductDataBySearch,
            getProductDataBySearchName: this.getProductDataBySearchName,
            setStateEmpty: this.setStateEmpty,
            updtae_product: this.updtae_product,
            updtae_Right_product: this.updtae_Right_product,
            close_update: this.close_update,
            getNameOfData: this.getNameOfData,
            CartonChange: this.CartonChange,
            DarzanChange: this.DarzanChange,
            setProductSearchEmty: this.setProductSearchEmty,
            openUpdateproduct: this.openUpdateproduct,
            productpageplusone: this.productpageplusone,
            productpageminsone: this.productpageminsone,
            ////////////end of Add Product

            /////////////// REnt And Wakil
            DeleteSail: this.DeleteSail,
            onSaveRent: this.onSaveRent,
            R_zhmaraycarton: this.R_zhmaraycarton,
            R_zhmaraydarzan: this.R_zhmaraydarzan,
            nrxRent: this.nrxRent,
            R_getkoygahty: this.R_getkoygahty,
            R_kamkrdnawayNrx$: this.R_kamkrdnawayNrx$,
            R_waslKrdn: this.R_waslKrdn,
            dinartodolar: this.dinartodolar,
            R_kamkrdnawayNrxID: this.R_kamkrdnawayNrxID,
            R_waslKrdnID: this.R_waslKrdnID,
            onSaveWakel: this.onSaveWakel,
            clickOnDeleteWakel: this.clickOnDeleteWakel,
            getQazanj: this.getQazanj,
            radiochange: this.radiochange,
            updtae_Wakil: this.updtae_Wakil,
            W_ClearError: this.W_ClearError,
            setWakilEmpty: this.setWakilEmpty,
            R_DarzanChange: this.DarzanChange,
            R_CartonChange: this.R_CartonChange,
            getProductDataBySearchRent: this.getProductDataBySearchRent,
            getWakilDataBySearchRent: this.getWakilDataBySearchRent,
            createNewPage: this.createNewPage,
            getWaslDataBySearchRent: this.getWaslDataBySearchRent,
            R_ClearError: this.R_ClearError,
            saveDetail: this.saveDetail,
            W_showrasid: this.W_showrasid,
            setSearchEmptyRentWakil: this.setSearchEmptyRentWakil,
            setSaletSearchEmty: this.setSaletSearchEmty,
            R_getIsChecked: this.R_getIsChecked,
            R_WakilQard_getIsChecked: this.R_WakilQard_getIsChecked,
            W_getwakelakan: this.W_getwakelakan,
            updateSale: this.updateSale,
            getPrintPasswordRent: this.getPrintPasswordRent,
            R_nrxchange: this.R_nrxchange,
            tofirstInputINRent: this.tofirstInputINRent,
            R_anotherInputWakil: this.R_anotherInputWakil,
            openUpdate: this.openUpdate,
            getWakilDataBySearchWakil: this.getWakilDataBySearchWakil,
            inputtogler: this.inputtogler,
            R_pageminsone: this.R_pageminsone,
            R_pageplusone: this.R_pageplusone,
            W_tofirstInput: this.W_tofirstInput,
            R_darzankartontoanotherInput: this.R_darzankartontoanotherInput,
            getNewRaidForwakil: this.getNewRaidForwakil,
            R_darzanChangeSfr: this.R_darzanChangeSfr,
            R_saveDetail: this.R_saveDetail,
            //////////////// zyadkrdni user
            onSaveAddUser: this.onSaveAddUser,
            clickOnDeleteAddUser: this.clickOnDeleteAddUser,
            updtae_User: this.updtae_User,
            getUsertableData: this.getUsertableData,

            //// updatepassword
            onUpdatePassword: this.onUpdatePassword,
            U_ClearError: this.U_ClearError,
            showpassword: this.showpassword,
            labrdnisave: this.labrdnisave,

            ////money
            P_Serach_ByName: this.P_Serach_ByName,
            P_emptysuggections: this.P_emptysuggections,
            P_Search_Rent_Handle: this.P_Search_Rent_Handle,
            P_suggestionSelected: this.P_suggestionSelected,
            onSaveMoney: this.onSaveMoney,
            getWakilDataBySearchMoney: this.getWakilDataBySearchMoney,
            bripara: this.bripara,
            P_kamkrdnawayNrx: this.P_kamkrdnawayNrx,
            getParaDataBySearchMoney: this.getParaDataBySearchMoney,
            createNewMony: this.createNewMony,
            P_ClearError: this.P_ClearError,
            P_getIsChecked: this.P_getIsChecked,
            setSearchMonyWakilEmpty: this.setSearchMonyWakilEmpty,
            P_openUpdate: this.P_openUpdate,
            updtate_para: this.updtate_para,
            pageplusone: this.pageplusone,
            pageminsone: this.pageminsone,
            P_inputtogler: this.P_inputtogler,
            P_getPrint: this.P_getPrint,
            /// print functiond
            getPrint: this.getPrint,
            handleKeypress: this.handleKeypress,

            ////logout
            logoutUser: this.logoutUser,

            // navdolar toogle
            toggleNavdolar: this.toggleNavdolar,

            //garanawa bo koga functions
            G_createNewPage: this.G_createNewPage,
            G_setSearchEmptyRentWakil: this.G_setSearchEmptyRentWakil,
            G_getWaslDataBySearchRent: this.G_getWaslDataBySearchRent,
            G_getkoygahty: this.G_getkoygahty,
            G_getProductDataBySearchRent: this.G_getProductDataBySearchRent,
            G_saveDetail: this.G_saveDetail,
            G_setSaletSearchEmty: this.G_setSaletSearchEmty,
            G_nrxchange: this.G_nrxchange,
            G_tofirstInputINRent: this.G_tofirstInputINRent,
            G_anotherInputWakil: this.G_anotherInputWakil,
            G_CartonChange: this.G_CartonChange,
            G_DarzanChange: this.G_DarzanChange,
            G_DeleteSail: this.G_DeleteSail,
            G_waslKrdn: this.G_waslKrdn,
            G_waslKrdnID: this.G_waslKrdnID,
            G_onSaveRent: this.G_onSaveRent,
            G_openUpdate: this.G_openUpdate,
            G_updateSale: this.G_updateSale,
            G_pageminsone: this.G_pageminsone,
            G_pageplusone: this.G_pageplusone,
            G_inputtogler: this.G_inputtogler,
            G_getPrintPasswordRent: this.G_getPrintPasswordRent,
            G_getPrint: this.G_getPrint,
            G_getNewRaidForwakil: this.G_getNewRaidForwakil,
            // functions of raporty Qazanj
            Q_OpenModal: this.Q_OpenModal,
            Q_CloseModal: this.Q_CloseModal,
            Q_peshandan: this.Q_peshandan,
            Q_getwakel: this.Q_getwakel,
            Q_getrastawxo: this.Q_getrastawxo,
            Q_getkala: this.Q_getkala,
            Q_getwasl: this.Q_getwasl,

            // functions of Raporty Rozhana
            D_OpenModal: this.D_OpenModal,
            D_CloseModal: this.D_CloseModal,
            D_hameJwllakan: this.D_hameJwllakan,
            D_rozhanafooter: this.D_rozhanafooter,
            D_rozhanafroshtn: this.D_rozhanafroshtn,
            D_rozhanaparawagrtn: this.D_rozhanaparawagrtn,
            D_gotowaslrentsearch: this.D_gotowaslrentsearch,
            D_gotowaslparasearch: this.D_gotowaslparasearch,
            D_jwllakan: this.D_jwllakan,
            D_rozhanaGaranawa: this.D_rozhanaGaranawa,
            D_gotowaslGaranawasearch: this.D_gotowaslGaranawasearch,

            /// functions of Raporty Wakel
            RW_OpenModal: this.RW_OpenModal,
            RW_CloseModal: this.RW_CloseModal,
            Rw_serchwakil: this.Rw_serchwakil,
            RW_jwalakan: this.RW_jwalakan,
            RW_jwllakan: this.RW_jwllakan,
            RW_rozhanafroshtn: this.RW_rozhanafroshtn,
            RW_rozhanaparawagrtn: this.RW_rozhanaparawagrtn,
            RW_gotowaslparasearch: this.RW_gotowaslparasearch,
            RW_gotowaslrentsearch: this.RW_gotowaslrentsearch,
            RW_wakilGaranawa: this.RW_wakilGaranawa,
            RW_gotowaslGaranawasearch: this.RW_gotowaslGaranawasearch,
            RW_setstInputEmpty: this.RW_setstInputEmpty,
            OnChangeSwitch: this.OnChangeSwitch,
            /////rapoty qarzy wakil functions
            QW_qazrywakil: this.QW_qazrywakil,
            QW_CloseModal: this.QW_CloseModal,
            selectRow: this.selectRow,
            QR_selectRow: this.QR_selectRow,
            QR_filterQarz: this.QR_filterQarz,
            QW_filterQarz: this.QW_filterQarz,
            // functions Of Jarde Maxzan Raporty Koga
            JM_OpenModal: this.JM_OpenModal,
            JM_CloseModal: this.JM_CloseModal,
            JM_pshandan: this.JM_pshandan,
            JM_yaksanbasfrfn: this.JM_yaksanbasfrfn,
            JM_zyatrlasfrfn: this.JM_zyatrlasfrfn,
            JM_clickOnDelete: this.JM_clickOnDelete,

            // raporty kala
            K_getFroshtn: this.K_getFroshtn,
            K_gettomarkrdn: this.K_gettomarkrdn,
            K_getGaranawa: this.K_getGaranawa,
            K_OpenModal: this.K_OpenModal,
            K_CloseModal: this.K_CloseModal,
            K_getproduct: this.K_getproduct,
            K_pshandan: this.K_pshandan,
            K_grantr: this.K_grantr,
            K_harzantr: this.K_harzantr,
            K_hamannrx: this.K_hamannrx,
            K_gotowaslrentsearch: this.K_gotowaslrentsearch,
            K_gotowaslGaranawasearch: this.K_gotowaslGaranawasearch,

            ///raporty qarzy rastawxo functions
            QR_OpenModal: this.QR_OpenModal,
            QR_CloseModal: this.QR_CloseModal,
            QR_hamwqarzakan: this.QR_hamwqarzakan,
            QR_gotowaslrentsearch: this.QR_gotowaslrentsearch,

            ///end of qarzy rastawxo

            ////
            RC_getnavbarRemembe: this.RC_getnavbarRemembe,
            RC_getnavbarMatr: this.RC_getnavbarMatr,
            RRRchange: this.RRRchange,
            RC_barzichnage: this.RC_barzichnage,
            RC_saveToTable: this.RC_saveToTable,
            tofirstInput: this.tofirstInput,
            tofirstInpuMatr: this.tofirstInpuMatr,
            tofirstInpuKalla: this.tofirstInpuKalla,
            RC_newChina: this.RC_newChina,
            RC_search: this.RC_search,
            RC_update: this.RC_update,
            RC_updateNawKalla: this.RC_updateNawKalla,
            RC_froshtnChange: this.RC_froshtnChange,
            RC_openchina: this.RC_openchina,
            RC_newpage: this.RC_newpage,
            RC_matrremembychnage: this.RC_matrremembychnage,
            RC_clickOnDelete: this.RC_clickOnDelete,
            RC_setInputEmpty: this.RC_setInputEmpty,
            RC_qazanjchange: this.RC_qazanjchange,

            ///// function of kalay nafroshraw
            KN_CloseModal: this.KN_CloseModal,
            KN_OpenModal: this.KN_OpenModal,
            KN_peshandan: this.KN_peshandan,

            // Functions of Raporty Paray Nahatu
            RP_ParayNahatu: this.RP_ParayNahatu,
            RP_OpenModal: this.RP_OpenModal,
            RP_CloseModal: this.RP_CloseModal,
            HK_addtoPrint: this.HK_addtoPrint,
            selectRow: this.selectRow,
            PS_CloseModal: this.PS_CloseModal,
            PS_OpenModal: this.PS_OpenModal,
            PS_pshandan: this.PS_pshandan,
          }}
        >
          {this.props.children}
        </ProductContext.Provider>
      </>
    );
  }
}
const ProductConsumer = ProductContext.Consumer;

export { AddProWakel, ProductConsumer };
