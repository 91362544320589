import React from "react";
import { Link } from "react-router-dom";
import Time from "react-time-format";
import { ProductConsumer } from "../../../Context/AddProWakel";
const FillDetailQarzeWakel = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            QW_zhmara,
            Qw_naw,
            PD_getPrint,
            QW_filterQarz,
          } = value;
          return (
            <>
              <div className="row" style={{ backgroundColor: "#2C3539" }}>
                <div className="col-md-3 form-inline">
                  <div className="row form-inline">
                    <div className="col-md-7 form-inline">
                      <p
                        className="font-weight-bold bg-white  f3"
                        style={{ color: "#000" }}
                      >
                        <Time format="YYYY-MM-DD" />
                      </p>
                      <span style={{ color: "#2C3539" }}>:</span>
                      <p
                        className="font-weight-bold bg-white  f3"
                        style={{ color: "#000" }}
                      >
                        <Time value={Date.now()} format="YYYY-MM-DD" />
                      </p>
                    </div>
                    <div className='col-md-5 form-inline"'>
                      <label className=" font-weight-bold f3 text-warning">
                        : بەرواری{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-5  mt3">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="search"
                        onChange={AddProChange}
                        aria-label="Large"
                        name="Qw_naw"
                        value={Qw_naw}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="search"
                        onChange={AddProChange}
                        aria-label="Large"
                        name="QW_zhmara"
                        value={QW_zhmara}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-2 " onClick={QW_filterQarz}>
                      <label className="btn btn-info">قەرزەکان</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt3">
                  <div className="row">
                    <div className="col-md-4">
                      <Link to="/print-wakel-qarz" onClick={PD_getPrint}>
                        <button className="btn btn-warning">Print</button>
                      </Link>
                    </div>
                    <div className="col-md-8">
                      <h3 className="font-weight-bold text-warning">
                        ڕاپۆرتی معمیل
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FillDetailQarzeWakel;
