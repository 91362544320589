import React from "react";
import FormPrintRaportyWakel from "./FormPrintRaportyWakel";
import rahel from "./../../../img/rahel1.jpg";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
const InputRaportyWakel = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            RW_from,
            RW_to,
            RW_zh_barez,
            RW_nawibrez,
            RW_rasedyKotaye$,
            RW_koyfroshtndolar,
            RW_koyfroshtndianr,
            RW_rasedyKotayeID,
          } = value;
          return (
            <>
              <img src={rahel} alt="rahel" />
              <div className="row mb2">
                <div className="col-md-4 text-right form-inline "></div>
                <div className="col-md-4 text-right form-inline ">
                  <div className="form-group ">
                    <label className="font-weight-bold f3 ml5">
                      ڕاپۆرتی معمیل
                    </label>
                  </div>
                </div>
                <div className="col-md-4 text-right form-inline"></div>
              </div>

              <div
                className="row text-dark font-weight-bold f3 mx-4"
                style={{ border: "2px solid black" }}
              >
                <div className="col-md-3 text-right  form-inline ">
                  <div className="form-group">
                    <h5 className="font-weight-bold mt1 ml5 ">{RW_to}</h5>
                    <label className="font-weight-bold mb-2 ml1">
                      : بۆ بەرواری
                    </label>
                  </div>
                </div>

                <div className="col-md-3 text-right  form-inline ">
                  <div className="form-group">
                    <h5 className="font-weight-bold mt1 ml5  ">{RW_from}</h5>
                    <label className="font-weight-bold mb-2  ml1">
                      : لە بەرواری{" "}
                    </label>
                  </div>
                </div>
                {/* <div className="col-md-1 text-right  form-inline"></div> */}

                <div className="col-md-3 text-right  form-inline ">
                  <div className="form-group ">
                    <h6 className=" mt2 ml5  fk">({RW_nawibrez})</h6>
                    <label className=" f4 fk ">:بەڕێز</label>
                  </div>
                </div>

                <div className="col-md-3 text-right form-inline">
                  <div className="form-group ml6">
                    <h6 className="mt2  fk">{RW_zh_barez}</h6>
                    <label className="font-weight-bold f4 ml2 fk">:ژمارە</label>
                  </div>
                </div>
              </div>

              <div className="mt4"></div>
              <FormPrintRaportyWakel />
              {/* first row */}
              <div className="row">
                <div className="col-md-12">
                  <div className="row ">
                    <div className="col-md-2">
                      <h3 className="bg-white tc">
                        <NumberFormat
                          value={RW_koyfroshtndolar}
                          className="bg-white f4 font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h3>
                    </div>
                    <div className="col-md-3">
                      <h3 className="text-black tc ">$کۆی فرۆشتن</h3>
                    </div>

                    <div className="col-md-2 ">
                      <h3 className="bg-white tc">
                        <NumberFormat
                          value={RW_rasedyKotaye$}
                          className="bg-white f4 font-weight-bold  tc border-0"
                          displayType={"text"}
                          prefix={"$"}
                          thousandSeparator={true}
                        />
                      </h3>
                    </div>
                    <div className="col-md-3">
                      <h3 className="text-black tc">قەرزی کۆتایی</h3>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {/* second row */}

              <div className="row">
                <div className="col-md-12">
                  <div className="row ">
                    <div className="col-md-2 ">
                      <h3 className="bg-white tc">
                        <NumberFormat
                          value={RW_koyfroshtndianr}
                          className=" f4 font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h3>
                    </div>
                    <div className="col-md-3">
                      <h3 className="text-dark tc">IDکۆی فرۆشتن</h3>
                    </div>
                    <div className="col-md-2">
                      <h3 className="bg-white tc">
                        <NumberFormat
                          value={RW_rasedyKotayeID}
                          className="bg-white f4 font-weight-bold  tc border-0"
                          displayType={"text"}
                          prefix={"ID"}
                          thousandSeparator={true}
                        />
                      </h3>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default InputRaportyWakel;
