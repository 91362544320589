import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
const FooterOfKoga = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            KM_onSaveRent,
            AddProChange,
            KM_waslKrdn,
            KM_total,
            dolartodinar,
            getPrint,
            KM_search_wasl,
            KM_del_dis,
            KM_joripara,
            KM_save,
            KM_getPrintPasswordRent,
            G_open,
            KM_paraDan$,
            KM_pageminsone,
            KM_pageplusone,
            KM_showLoader,
            KM_hideLoader,
            KM_loading,
          } = value;
          return (
            <>
              <div className="row">
                {KM_joripara === "دۆلار" ? (
                  <>
                    <div className="col-md-4">
                      <div className=" col-md-10">
                        <table className="table" id="yusif">
                          <thead>
                            <tr
                              className={`${
                                KM_save === true || KM_search_wasl === true
                                  ? "bg-white"
                                  : ""
                              }`}
                            >
                              <td className="text-center bg-white">
                                <NumberFormat
                                  value={KM_total}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  className="f4"
                                />
                              </td>
                              <th
                                className="text-danger f4 bg-white"
                                style={{ width: "45%" }}
                              >
                                : کۆی گشتی
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center">
                                <NumberFormat
                                  displayType={"input"}
                                  thousandSeparator={true}
                                  disabled={KM_del_dis === true ? true : null}
                                  name="KM_paraDan$"
                                  onChange={AddProChange}
                                  value={KM_paraDan$}
                                  style={{ padding: "8px 4px" }}
                                  className={`border-0 f5 pl-4 ${
                                    KM_save === true || KM_search_wasl === true
                                      ? "bg-white"
                                      : ""
                                  }`}
                                />
                              </td>
                              <th className="text-warning f4">:$ پارەی دراو</th>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td className="text-center bg-white">
                                {KM_paraDan$ !== "" ? (
                                  <NumberFormat
                                    value={KM_waslKrdn()}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    className="f4"
                                  />
                                ) : (
                                  <NumberFormat
                                    value={KM_total}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    className="f4"
                                  />
                                )}
                              </td>
                              <th className="text-danger f4 bg-white">
                                :پارەی نەدراو
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <table className="table">
                        <thead>
                          <tr
                            className={`${
                              KM_save === true || KM_search_wasl === true
                                ? "bg-white"
                                : ""
                            }`}
                          >
                            <td className="f4 bg-white">
                              <NumberFormat
                                value={
                                  KM_paraDan$ !== ""
                                    ? dolartodinar(KM_waslKrdn())
                                    : dolartodinar(KM_total)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"ID"}
                              />
                            </td>
                            <th
                              style={{ width: "45%" }}
                              className="text-danger f4 bg-white"
                            >
                              :ID کۆی گشتی
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className=" justify-content-center col-md-1 mt-2">
                  <div className="btn-group ml4">
                    {KM_search_wasl !== null ? (
                      <>
                        <Link to="/Print_kren">
                          <button
                            type="button"
                            disabled={G_open === true ? true : null}
                            onClick={KM_getPrintPasswordRent}
                            className="btn btn-info mr1"
                            style={{
                              backgroundColor: "#9900ff",
                              border: "1px solid #9900ff",
                            }}
                          >
                            <i className="fas fa-print fa-lg"></i>
                          </button>
                        </Link>
                      </>
                    ) : KM_save === true ? (
                      <>
                        <Link to="/Print_kren">
                          <button
                            type="button"
                            onClick={getPrint}
                            className="btn btn-info"
                            style={{
                              backgroundColor: "#9900ff",
                              border: "1px solid #9900ff",
                            }}
                          >
                            <i className="fas fa-print fa-lg"></i>
                          </button>
                        </Link>
                      </>
                    ) : (
                      <>
                        {KM_loading ? (
                          <button
                            type="button"
                            onDoubleClick={KM_hideLoader}
                            className="btn btn-success "
                          >
                            <i class="fas fa-spinner animate__animated animate__rotateIn animate__infinite"></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={(e) => {
                              KM_showLoader(e);
                              KM_onSaveRent(e);
                            }}
                            className="btn btn-success"
                          >
                            <i className="far fa-save fa-lg"></i>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-dark p3 px-4"
                        onClick={KM_pageminsone}
                        disabled={G_open === true ? true : null}
                        style={{
                          backgroundColor: "#00695c",
                          border: "1px solid #00695c",
                        }}
                      >
                        <i class="fas fa-caret-left f4"></i>
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-dark p3 px-4 ml3"
                        onClick={KM_pageplusone}
                        disabled={G_open === true ? true : null}
                        style={{
                          backgroundColor: "#00695c",
                          border: "1px solid #00695c",
                        }}
                      >
                        <i class="fas fa-caret-right f4"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfKoga;
