import React from 'react'
import FooterOfgaranawa from './FooterOfgaranawa'
import TableScrolegaranawa from './TableScrolegaranawa'


const TableAllprintgaranawa = () => {
    return (

        <body>
            <TableScrolegaranawa />
            <br /><br />
            <FooterOfgaranawa />
            <footer className='content-block text-right btn btn-black f4'>

            </footer>
        </body>



    )
}

export default TableAllprintgaranawa
