import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { RP_dataPara, RP_zhmara, RP_naw } = value;
            const qarzeWakel = RP_dataPara.filter((data) => {
              if (RP_naw !== "") {
                return data.Name.toLowerCase().startsWith(RP_naw.toLowerCase());
              } else if (RP_zhmara !== "") {
                return data.code.toString().startsWith(RP_zhmara);
              } else {
                return data;
              }
            }).map((user, i) => {
              return (
                <tr key={i} className=" ">
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={user.rejaDinar.toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={user.rejaDolar.toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.phone}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.Name}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    {user.code}
                  </td>
                </tr>
              );
            });

            return (
              <table
                id="example"
                className="table table-hover table-bordered text-right JM"
              >
                <thead>
                  <tr
                    className="text-warning "
                    style={{ backgroundColor: "#2e3541 " }}
                  >
                    <th style={{ border: "2px solid #f0ad4e" }}>ID ڕەسید بە</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>$ ڕەسید بە </th>
                    <th style={{ border: "2px solid #f0ad4e" }}>تەلەفۆن</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>ناوەی وەکیل</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                  </tr>
                </thead>
                <tbody>{qarzeWakel}</tbody>
              </table>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
