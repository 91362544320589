import React from 'react'
import Navbar from '../../../Navbar/Navbar'
import ScroollTableParaGorenawa from './ScroollTableParaGorenawa'
import FillDetailParaGorenawa from './FillDetailParaGorenawa'
const AllParaGorenawa = () => {
    return (
        <div>
            <Navbar/>
            <div style={{marginTop:"50px"}} >
            <div className="row" style={{backgroundColor:"#2C3539"}}>
                    <div className="col-md-12 ">
                    <FillDetailParaGorenawa/>
                </div>
                </div>
            <div className="row">
                    <div className="col-md-12 ">
                    <ScroollTableParaGorenawa/>
                </div>
            </div>
            <div className='row ' style={{backgroundColor:"#2C3539"}}>
                    <div className="col-md-12 col-md-12" style={{ backgroundColor: "#2C3539" }}>
                    <br/> <br/> <br/> <br/>
                </div>
            </div>
        </div>
        </div>
    )
}

export default AllParaGorenawa
