import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

class FormPrintMasruf extends React.Component {
 render() {
  return (
   <>
    <ProductConsumer>
     {(value) => {
      const { JM_dataRaportyKoga } = value;

      const wakil = JM_dataRaportyKoga.map((user, i) => {
       return (
        <tr
         key={i}
         className="f4 fk"
         style={{
          borderBottom: "2px solid black",
          borderTop: "2px solid black",
          borderRight: "2px solid black",
          borderLeft: "2px solid black",
         }}
        >
         <td
          className="fk"
          style={{
           borderBottom: "2px solid black",
           borderTop: "2px solid black",
           borderRight: "2px solid black",
           borderLeft: "2px solid black",
           fontSize: "1.6em",
           color: "black",
          }}
         >
          <NumberFormat
           value={Number(
            user.rasidKarton * user.numberOfDarzan * user.price
           ).toFixed(2)}
           thousandSeparator={true}
           displayType="text"
          />
         </td>
         <td
          className="fk"
          style={{
           borderBottom: "2px solid black",
           borderTop: "2px solid black",
           borderRight: "2px solid black",
           borderLeft: "2px solid black",
           fontSize: "1.6em",
           color: "black",
          }}
         >
          {user.numberOfDarzan}
         </td>
         <td
          className="fk"
          style={{
           borderBottom: "2px solid black",
           borderTop: "2px solid black",
           borderRight: "2px solid black",
           borderLeft: "2px solid black",
           fontSize: "1.6em",
           color: "black",
          }}
         >
          {user.price}
         </td>
         <td
          className="fk"
          style={{
           borderBottom: "2px solid black",
           borderTop: "2px solid black",
           borderRight: "2px solid black",
           borderLeft: "2px solid black",
           fontSize: "1.6em",
           color: "black",
          }}
         >
          <NumberFormat
           value={Number(user.rasidKarton * user.numberOfDarzan).toFixed(2)}
           thousandSeparator={true}
           displayType="text"
          />
         </td>
         <td
          className="fk"
          style={{
           borderBottom: "2px solid black",
           borderTop: "2px solid black",
           borderRight: "2px solid black",
           borderLeft: "2px solid black",
           fontSize: "1.6em",
           color: "black",
          }}
         >
          <NumberFormat
           value={Number(user.rasidKarton).toFixed(2)}
           thousandSeparator={true}
           displayType="text"
          />
         </td>
         <td
          className="fk"
          style={{
           borderBottom: "2px solid black",
           borderTop: "2px solid black",
           borderRight: "2px solid black",
           borderLeft: "2px solid black",
           fontSize: "1.6em",
           color: "black",
          }}
         >
          {user.nameofproduct}
         </td>
         <td
          className="fk"
          style={{
           borderBottom: "2px solid black",
           borderTop: "2px solid black",
           borderRight: "2px solid black",
           borderLeft: "2px solid black",
           fontSize: "1.6em",
           color: "black",
          }}
         >
          {i + 1}
         </td>
        </tr>
       );
      });

      return (
       <table id="example" className="table table-striped  text-right ">
        <thead>
         <tr
          className="text-black f3 fk"
          style={{
           borderBottom: "2px solid black",
           borderTop: "2px solid black",
           borderRight: "2px solid black",
           borderLeft: "2px solid black",
          }}
         >
          <th
           className="f3"
           style={{
            borderBottom: "2px solid black",
            borderTop: "2px solid black",
            borderRight: "2px solid black",
            borderLeft: "2px solid black",
            color: "black",
            backgroundColor: "gray",
           }}
          >
           $ کۆی گشتی{" "}
          </th>
          <th
           className="f3"
           style={{
            borderBottom: "2px solid black",
            borderTop: "2px solid black",
            borderRight: "2px solid black",
            borderLeft: "2px solid black",
            color: "black",
            backgroundColor: "gray",
           }}
          >
           ڕێژە
          </th>
          <th
           className="f3"
           style={{
            borderBottom: "2px solid black",
            borderTop: "2px solid black",
            borderRight: "2px solid black",
            borderLeft: "2px solid black",
            color: "black",
            backgroundColor: "gray",
           }}
          >
           $ كڕین
          </th>
          <th
           className="f3"
           style={{
            borderBottom: "2px solid black",
            borderTop: "2px solid black",
            borderRight: "2px solid black",
            borderLeft: "2px solid black",
            color: "black",
            backgroundColor: "gray",
           }}
          >
           دانە
          </th>
          {/*  */}
          <th
           className="f3"
           style={{
            borderBottom: "2px solid black",
            borderTop: "2px solid black",
            borderRight: "2px solid black",
            borderLeft: "2px solid black",
            color: "black",
            backgroundColor: "gray",
           }}
          >
           كارتۆنی ماوە
          </th>
          <th
           className="f3"
           style={{
            borderBottom: "2px solid black",
            borderTop: "2px solid black",
            borderRight: "2px solid black",
            borderLeft: "2px solid black",
            color: "black",
            backgroundColor: "gray",
           }}
          >
           ناوی کاڵا
          </th>
          <th
           className="f3"
           style={{
            borderBottom: "2px solid black",
            borderTop: "2px solid black",
            borderRight: "2px solid black",
            borderLeft: "2px solid black",
            color: "black",
            backgroundColor: "gray",
           }}
          >
           ژمارە
          </th>
         </tr>
        </thead>
        <tbody>{wakil}</tbody>
       </table>
      );
     }}
    </ProductConsumer>
   </>
  );
 }
}
export default FormPrintMasruf;
