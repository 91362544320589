import React from 'react'
import { ProductConsumer } from '../../../Context/AddProWakel'
const FillDetailParadan = () => {
    
    return (<>
    
        <ProductConsumer>
            {value=>{
              const {RPD_from,RPD_to} = value
          return(<>
               <div className="row" style={{backgroundColor:"#2C3539"}}>
              <div className="col-lg-3  col-md-4 form-inline">
                <div className="row form-inline">
                  <div className="col-md-6 form-inline">
                    <p className="font-weight-bold bg-white  f3"style={{color:"#000"}}></p><span style={{color:"#2C3539"}}>:</span>
                    <p className="font-weight-bold bg-white  f4"style={{color:"#000"}}>{RPD_to}</p>   
                </div>
                  <div className='col-lg-6 col-md-6 form-inline"'>
                    <label className=" font-weight-bold f3 text-warning">:بۆ بەرواری </label>
                </div>
                </div>
                </div>
              <div className="col-lg-3 col-md-4 form-inline">
                <div className="row form-inline">
                  <div className="col-md-6 form-inline">
                    <p className="font-weight-bold bg-white  f3"style={{color:"#000"}}></p><span style={{color:"#2C3539"}}>:</span>
                    <p className="font-weight-bold bg-white  f4"style={{color:"#000"}}>{RPD_from}</p>   
                </div>
                  <div className='col-lg-6 col-md-6 form-inline"'>
                    <label className=" font-weight-bold f3 text-warning">:لە بەرواری </label>
                </div>
                </div>
                </div>
              <div className="col-lg-4 col-md-4 mt3">
                    <div className="row">
                  <div className="col-lg-2 ">
                        <h3></h3>
                        </div>
                  <div className="col-lg-8 col-md-12">
                        <h3 className="font-weight-bold text-warning">ڕاپۆرتی پارەدان</h3>
                        </div>
                    </div>
                </div>


                </div>
                
                </>) 
        }}
  </ProductConsumer>
  </>

          )
    }

export default FillDetailParadan
