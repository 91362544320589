import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
const FooterOfRent = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            onSaveRent,
            AddProChange,
            R_totalID,
            R_waslKrdn,
            R_waslKrdnID,
            R_total,
            R_getIsChecked,
            dolartodinar,
            getPrint,
            R_kartonakan,
            R_search_wasl,
            R_koywaznkarton,
            R_del_dis,
            L_user,
            R_WakilQard_isChecked,
            R_isChecked,
            R_joripara,
            R_dashkan,
            R_kamkrdnawayNrx$,
            R_paraDanID,
            R_kateQarz,
            R_table,
            loading,
            R_save,
            R_wasl,
            updateSale,
            getPrintPasswordRent,
            R_open,
            openUpdate,
            R_pageminsone,
            R_pageplusone,
            R_paraDan$,
            R_kamkrdnawayNrxID,
            R_dashkanID,
            dinartodolar,
            R_kreysayara,
            R_kreykrekar,
            showLoader,
            hideLoader,
            R_zh_barez,
            R_note
          } = value;
          return (
            <>
              <div className="row">
                {R_joripara === "دۆلار" ? (
                  <>
                    <div className="col-4">
                      <div className=" col-10">
                        <table className="table " id="yusif">
                          <thead>
                            <tr
                              className={`${
                                R_save === true || R_search_wasl === true
                                  ? "bg-white"
                                  : ""
                              }`}
                            >
                              <td className="text-center bg-white">
                                <NumberFormat
                                  value={R_total}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                  className="f4"
                                />
                              </td>
                              <th
                                className="text-danger f5 bg-white fk"
                                style={{ width: "45%" }}
                              >
                                :$ کۆی گشتی
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center">
                                <input
                                  type="number"
                                  disabled={R_del_dis === true ? true : null}
                                  name="R_dashkan"
                                  onChange={AddProChange}
                                  value={R_dashkan === 0 ? "" : R_dashkan}
                                  style={{ padding: "8px 4px" }}
                                  className={`f5 border-0 pl-4 ${
                                    R_save === true || R_search_wasl === true
                                      ? "bg-white"
                                      : ""
                                  }`}
                                />
                              </td>
                              <th className="text-warning f4">:کەمکردنەوە</th>
                            </tr>
                            <tr>
                              <td className="text-center text-white">
                                {R_dashkan !== 0 ? (
                                  <NumberFormat
                                    value={R_kamkrdnawayNrx$()}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    className="f4"
                                  />
                                ) : (
                                  <NumberFormat
                                    value={R_total}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    className="f4"
                                  />
                                )}
                              </td>
                              <th className="text-warning f4">:$ پارەی ماوە</th>
                            </tr>
                            <tr>
                              <td className="text-center">
                                <NumberFormat
                                  displayType={"input"}
                                  thousandSeparator={true}
                                  disabled={R_del_dis === true ? true : null}
                                  name="R_paraDan$"
                                  onChange={AddProChange}
                                  value={R_paraDan$}
                                  style={{
                                    padding: "8px 4px",
                                    backgroundColor: `${
                                      R_save === true || R_search_wasl === true
                                        ? "white"
                                        : ""
                                    }`,
                                  }}
                                  className={`f5 border-0 pl-4`}
                                />
                              </td>
                              <th className="text-warning f4 ">
                                :$ پارەی دراو
                              </th>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td className="text-center bg-white">
                                {R_paraDan$ !== 0 && R_paraDan$ !== "" ? (
                                  <NumberFormat
                                    value={R_waslKrdn()}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    className="f4"
                                  />
                                ) : (
                                  <NumberFormat
                                    value={R_kamkrdnawayNrx$()}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                    className="f4"
                                  />
                                )}
                              </td>
                              <th className="text-danger bg-white f5 fk">
                                :$ پارەی نەدراو
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="col-3">
                      <table className="table">
                        <thead>
                          <tr
                            className={`${
                              R_save === true || R_search_wasl === true
                                ? "bg-white"
                                : ""
                            }`}
                          >
                            <td className="f4 bg-white">
                              <NumberFormat
                                value={
                                  R_paraDan$ !== 0 && R_paraDan$ !== ""
                                    ? dolartodinar(R_waslKrdn())
                                    : dolartodinar(R_kamkrdnawayNrx$())
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"ID"}
                              />
                            </td>
                            <th
                              style={{ width: "45%" }}
                              className="text-danger bg-white f4"
                            >
                              :ID کۆی گشتی
                            </th>
                          </tr>
                        </thead>
                      </table>
                      <div className="col-5">
                        <table>
                          <thead>
                            <th className="text-right pb-2 text-warning">
                              <input
                                type="checkbox"
                                onChange={R_getIsChecked}
                                className="mr3 font-weight-bold "
                              />
                              : چاپ کردن{" "}
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-right ">
                                <label className="font-weight-bold text-warning">
                                  :کرێ سەیارە
                                </label>
                                <input
                                  type="number"
                                  className="text-right "
                                  disabled={R_del_dis === true ? true : null}
                                  value={R_kreysayara === 0 ? "" : R_kreysayara}
                                  name="R_kreysayara"
                                  onChange={AddProChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="text-right ">
                                <label className="font-weight-bold text-warning">
                                  :کرێ کرێکار
                                </label>
                                <input
                                  type="number"
                                  className="text-right "
                                  disabled={R_del_dis === true ? true : null}
                                  value={R_kreykrekar === 0 ? "" : R_kreykrekar}
                                  name="R_kreykrekar"
                                  onChange={AddProChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="text-right ">
                                <label className="font-weight-bold text-warning">
                                  :تێبینی
                                </label>
                                <input
                                  type="text"
                                  className="text-right "
                                  disabled={R_del_dis === true ? true : null}
                                  value={R_note}
                                  name="R_note"
                                  onChange={AddProChange}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {/* start ID */}
                {R_joripara === "دینار" ? (
                  <>
                    <div className="col-4">
                      <div className=" col-10">
                        <table className="table" id="yusif">
                          <thead>
                            <tr
                              className={`${
                                R_save === true || R_search_wasl === true
                                  ? "bg-white"
                                  : ""
                              }`}
                            >
                              <td className="text-center bg-white">
                                <NumberFormat
                                  value={R_totalID}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                  className="f4"
                                />
                              </td>
                              <th
                                className="text-danger bg-white f5 fk"
                                style={{ width: "45%" }}
                              >
                                : کۆی گشتی
                              </th>
                            </tr>
                            <tr>
                              <td className="text-center">
                                <input
                                  type="number"
                                  disabled={R_del_dis === true ? true : null}
                                  name="R_dashkanID"
                                  onChange={AddProChange}
                                  value={R_dashkanID === 0 ? "" : R_dashkanID}
                                  style={{
                                    padding: "8px 4px",
                                    backgroundColor: `${
                                      R_save === true || R_search_wasl === true
                                        ? "white"
                                        : ""
                                    }`,
                                  }}
                                  className={`f5 border-0 pl-4`}
                                />
                              </td>
                              <th className="text-warning f4">:کەمکردنەوە</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center text-white">
                                {R_dashkanID !== 0 ? (
                                  <NumberFormat
                                    value={R_kamkrdnawayNrxID()}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"ID"}
                                    className="f4"
                                  />
                                ) : (
                                  <NumberFormat
                                    value={R_totalID}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"ID"}
                                    className="f4"
                                  />
                                )}
                              </td>
                              <th className="text-warning f4">: پارەی ماوە</th>
                            </tr>
                            <tr>
                              <td className="text-center">
                                <NumberFormat
                                  disabled={R_del_dis === true ? true : null}
                                  name="R_paraDanID"
                                  onChange={AddProChange}
                                  value={R_paraDanID}
                                  style={{ padding: "8px 4px" }}
                                  className={`border-0 f5 pl-4 ${
                                    R_save === true || R_search_wasl === true
                                      ? "bg-white"
                                      : ""
                                  }`}
                                  displayType={"input"}
                                  thousandSeparator={true}
                                />
                              </td>
                              <th className="text-warning f4">: پارەی دراو</th>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td className="text-center bg-white">
                                {R_paraDanID !== 0 && R_paraDanID !== "" ? (
                                  <NumberFormat
                                    value={R_waslKrdnID()}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"ID"}
                                    className="f4"
                                  />
                                ) : (
                                  <NumberFormat
                                    value={R_kamkrdnawayNrxID()}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"ID"}
                                    className="f4"
                                  />
                                )}
                              </td>
                              <th className="text-danger bg-white f5 fk">
                                : پارەی نەدراو
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div className="col-3">
                      <table className="table">
                        <thead>
                          <tr
                            className={`${
                              R_save === true || R_search_wasl === true
                                ? "bg-white"
                                : ""
                            }`}
                          >
                            <td className="f4 text-center bg-white">
                              <NumberFormat
                                value={
                                  R_paraDanID !== 0 && R_paraDanID !== ""
                                    ? dinartodolar(R_waslKrdnID())
                                    : dinartodolar(R_kamkrdnawayNrxID())
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                            <th className="text-danger f4 text-right  bg-white">
                              :$ کۆی گشتی
                            </th>
                          </tr>
                        </thead>
                      </table>
                      <div className="col-5">
                        <table>
                          <thead>
                            <tr>
                              <th className="text-center pb-2 font-weight-bold text-warning">
                                <input
                                  type="checkbox"
                                  onChange={R_getIsChecked}
                                  checked={R_isChecked === false ? false : true}
                                  className="mr3 font-weight-bold "
                                />
                                : چاپ کردن{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-right ">
                                <label className="font-weight-bold text-warning">
                                  :کرێ سەیارە
                                </label>
                                <input
                                  type="number"
                                  className="text-right "
                                  disabled={R_del_dis === true ? true : null}
                                  value={R_kreysayara === 0 ? "" : R_kreysayara}
                                  name="R_kreysayara"
                                  onChange={AddProChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="text-right ">
                                <label className="font-weight-bold text-warning">
                                  :کرێ کرێکار
                                </label>
                                <input
                                  type="number"
                                  className="text-right "
                                  disabled={R_del_dis === true ? true : null}
                                  value={R_kreykrekar === 0 ? "" : R_kreykrekar}
                                  name="R_kreykrekar"
                                  onChange={AddProChange}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="text-right ">
                                <label className="font-weight-bold text-warning">
                                  :تێبینی
                                </label>
                                <input
                                  type="text"
                                  className="text-right "
                                  disabled={R_del_dis === true ? true : null}
                                  value={R_note}
                                  name="R_note"
                                  onChange={AddProChange}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-2 mt-3 ">
                  <div className="row">
                    <div className="col-6">
                      <h4 className=" border-0 text-right bg-white text-center">
                        {R_kartonakan}
                      </h4>
                    </div>
                    <div className="col-6">
                      <h4 className=" border-0 text-right bg-white text-center">{`${R_koywaznkarton} kg`}</h4>
                    </div>
                    <div className="row">
                      <div className="col-1"></div>
                      <div className="col-3">
                        <button
                          className="btn btn-dark p3 px-4"
                          disabled={
                            R_open === true || R_table === true ? true : null
                          }
                          onClick={R_pageminsone}
                          style={{
                            backgroundColor: "#00695c",
                            border: "1px solid #00695c",
                          }}
                        >
                          <i className="fas fa-caret-left f4"></i>
                        </button>
                      </div>
                      <div className="col-3">
                        <button
                          className="btn btn-dark p3 px-4 ml3"
                          disabled={
                            R_open === true || R_table === true ? true : null
                          }
                          onClick={R_pageplusone}
                          style={{
                            backgroundColor: "#00695c",
                            border: "1px solid #00695c",
                          }}
                        >
                          <i className="fas fa-caret-right f4"></i>
                        </button>
                      </div>
                      <div className="col-5"></div>
                    </div>
                  </div>
                </div>
                {/* <div className='col-1 mt-3 '>

      </div> */}
                <div className=" justify-content-center col-1 mt-2">
                  <div className=" row">
                    <div className="btn-group ml2 col-12">
                      {R_search_wasl !== null ? (
                        <>
                          <Link to="/print-rent">
                            <button
                              type="button"
                              disabled={R_open === true ? true : null}
                              onClick={(e) => {
                                getPrintPasswordRent(R_zh_barez, e);
                              }}
                              className="btn btn-info mr1"
                              style={{
                                backgroundColor: "#9900ff",
                                border: "1px solid #9900ff",
                              }}
                            >
                              <i className="fas fa-print fa-lg"></i>
                            </button>
                          </Link>
                          {R_open === null ? (
                            <button
                              onClick={(e) =>
                                openUpdate(
                                  R_zh_barez,
                                  L_user._id,
                                  e,
                                  R_wasl._id
                                )
                              }
                              className="btn btn-warning "
                              style={{
                                backgroundColor: "silver",
                                border: "1px solid silver",
                              }}
                            >
                              <i className="fas fa-lock"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) =>
                                updateSale(R_zh_barez, R_wasl._id, e)
                              }
                              className="btn btn-primary "
                              style={{ backgroundColor: "navy" }}
                            >
                              <i className="fas fa-edit "></i>
                            </button>
                          )}
                        </>
                      ) : R_save === true ? (
                        <>
                          {R_open === null ? (
                            <button
                              onClick={(e) =>
                                openUpdate(
                                  R_zh_barez,
                                  L_user._id,
                                  e,
                                  R_wasl._id
                                )
                              }
                              className="btn btn-warning "
                              style={{
                                backgroundColor: "silver",
                                border: "1px solid silver",
                              }}
                            >
                              <i className="fas fa-lock"></i>
                            </button>
                          ) : (
                            <button
                              onClick={(e) =>
                                updateSale(R_zh_barez, R_wasl._id, e)
                              }
                              className="btn btn-primary "
                              style={{ backgroundColor: "navy" }}
                            >
                              <i className="fas fa-edit "></i>
                            </button>
                          )}
                          <Link to="/print-rent">
                            <button
                              type="button"
                              onClick={(e) => {
                                getPrint(R_zh_barez, e);
                              }}
                              className="btn btn-info"
                              style={{
                                backgroundColor: "#9900ff",
                                border: "1px solid #9900ff",
                              }}
                            >
                              <i className="fas fa-print fa-lg"></i>
                            </button>
                          </Link>
                        </>
                      ) : (
                        <>
                          {loading ? (
                            <button
                              type="button"
                              onDoubleClick={hideLoader}
                              className="btn btn-success "
                            >
                              <i class="fas fa-spinner animate__animated animate__rotateIn animate__infinite"></i>
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={(e) => {
                                showLoader(e);
                                onSaveRent(e);
                              }}
                              className="btn btn-success"
                            >
                              <i className="far fa-save fa-lg"></i>
                            </button>
                          )}
                        </>
                      )}
                    </div>

                    <div className="px-5 mt-3 mr-5 bg-warning fk">
                      <NumberFormat
                        className="pl-1 py-5 f4"
                        value={` ${
                          R_joripara === "دۆلار" ? R_paraDan$ : R_paraDanID
                        }`}
                        prefix={R_joripara === "دۆلار" ? "$" : "ID"}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-2 mt-2">
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="date"
                        onChange={AddProChange}
                        name="R_kateQarz"
                        value={R_kateQarz}
                        className="selectpicker btn btn-warning f4"
                      />
                    </div>
                  </div>
                  <div className=" mt-4 ml-5 bg-danger text-white fk ">
                    {R_joripara === "دۆلار" ? (
                      <>
                        {R_paraDan$ !== 0 && R_paraDan$ !== "" ? (
                          <NumberFormat
                            value={R_waslKrdn()}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            className="f4"
                          />
                        ) : (
                          <NumberFormat
                            value={R_kamkrdnawayNrx$()}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            className="f4"
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {R_paraDanID !== 0 && R_paraDanID !== "" ? (
                          <NumberFormat
                            value={R_waslKrdnID()}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"ID"}
                            className="f4"
                          />
                        ) : (
                          <NumberFormat
                            value={R_kamkrdnawayNrxID()}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"ID"}
                            className="f4"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfRent;
