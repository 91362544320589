import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import Time from "react-time-format";
import { Link } from "react-router-dom";

class Body extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const { QR_data, QR_gotowaslrentsearch, QR_name } = value;
            const qarzeWakel = QR_data.filter((data) => {
              if (QR_name !== "") {
                return data.wakil.toLowerCase().includes(QR_name.toLowerCase());
              } else {
                return data;
              }
            }).map((user, i) => {
              return (
                <tr key={i} className=" ">
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={
                        user.joripara === "دۆلار"
                          ? user.qarzinwedolar
                          : user.qarzinwedinar
                      }
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={
                        user.joripara === "دۆلار"
                          ? user.paraydrawdolar
                          : user.paraydrawdinar
                      }
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={
                        user.joripara === "دۆلار"
                          ? user.dashkandolar
                          : user.dashkandinar
                      }
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    style={{ border: "2px solid #f0ad4e" }}
                    className={` ${
                      user.joripara === "دۆلار" ? "text-success" : "text-danger"
                    }`}
                  >
                    {user.joripara}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <NumberFormat
                      value={
                        user.joripara === "دۆلار"
                          ? user.koalldolar
                          : user.koalldinar
                      }
                      thousandSeparator={true}
                      displayType="text"
                    />
                  </td>
                  <td
                    style={{
                      border: "2px solid #f0ad4e",
                      color: `${
                        user.qarzinwedolar !== 0
                          ? "black"
                          : user.qarzinwedinar !== 0
                          ? "black"
                          : ""
                      }`,
                    }}
                    className={` bg-${
                      user.qarzinwedolar !== 0
                        ? "red"
                        : user.qarzinwedinar !== 0
                        ? "red"
                        : ""
                    }`}
                  >
                    {user.wakil}
                  </td>
                  <td className="" style={{ border: "2px solid #f0ad4e" }}>
                    <Time
                      value={user.date}
                      format="YYYY-MM-DD"
                      style={{ color: "black" }}
                    />
                  </td>
                  <td
                    className=""
                    style={{ border: "2px solid #f0ad4e", cursor: "pointer" }}
                    onClick={(e) => QR_gotowaslrentsearch(e, user.numberofwasl)}
                  >
                    <Link to="/rent">{user.numberofwasl}</Link>
                  </td>
                </tr>
              );
            });

            return (
              <div className="JM">
                <table
                  id="example"
                  className="table table-hover table-bordered text-right"
                >
                  <thead>
                    <tr
                      className="text-warning "
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        پارەی ماوە
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        پارەی دراو
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>داشکان</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>دراو</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        کۆی پسوولە
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ناوی بەڕێز
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>بەروار</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>ژمارە</th>
                    </tr>
                  </thead>
                  <tbody>{qarzeWakel}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default Body;
