import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
const ScroollTableKren = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            BN_DeleteSail,
            BN_dataTable,
            BN_del_dis,
            BN_joripara,
            BN_wasl,
          } = value;
          const Kren = BN_dataTable.map((user, i) => {
            return (
              <tr key={i} className="f4 fk">
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  <NumberFormat
                    value={user.kodolar}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.reja}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.nrx}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.darzan}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.carton}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.nawisht}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.raqam}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  <button
                    onClick={() => BN_DeleteSail(user._id, user, i, BN_wasl)}
                    disabled={BN_del_dis === true ? true : null}
                    className="btn btn-danger "
                  >
                    <i className="fa fa-minus" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            );
          });

          return (
            <div className="RR">
              <table
                id="example"
                className="table table-striped table-bordered  tc m-auto"
              >
                <thead>
                  <tr
                    className="text-warning f4"
                    style={{
                      border: "2px solid #f0ad4e",
                      backgroundColor: "#2C3539",
                    }}
                  >
                    <th style={{ border: "2px solid #f0ad4e" }}>
                      {BN_joripara === "دۆلار" ? "$ کۆی گشتی" : "ID کۆی گشتی"}
                    </th>
                    <th style={{ border: "2px solid #f0ad4e" }}>ڕێژە</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>نرخ</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>دانە</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>کارتۆن</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>ناوی شتەکان</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>x</th>
                  </tr>
                </thead>
                <tbody>{Kren}</tbody>
              </table>
            </div>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default ScroollTableKren;
