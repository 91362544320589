import React from "react";
import Scroll from "../TableForRent/Scroll";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const ScroolTableAddPro = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { clickOnDelete, data, edit_product, del_dis } = value;
          const renderdate = (kat) => {
            const monthname = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ];
            const date = new Date(kat);

            return `${date.getDate()}/${
              monthname[date.getMonth()]
            }/${date.getFullYear()}`;
          };
          const RoboFrinds = data.reverse().map((user, i) => {
            return (
              <tr key={i} className="f4">
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  <NumberFormat
                    value={user.total}
                    displayType="text"
                    thousandSeparator={true}
                    className="border-0"
                  />
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.numberOfAllDarzans}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.price}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.numberOfDarzan}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {user.numberOfCarton}
                </td>
                <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                  {renderdate(user.date)}
                </td>
                <td
                  className="pt-1 pb-0 pr-4"
                  style={{ border: "2px solid #f0ad4e" }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        onClick={() => clickOnDelete(user, user._id)}
                        disabled={del_dis === true ? true : null}
                        className="btn btn-danger btn-sm mr-3"
                      >
                        <i
                          className="fas fa-trash fa-sm"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        onClick={() => edit_product(user)}
                        disabled={del_dis === true ? true : null}
                        className="btn btn-primary btn-sm"
                        style={{ backgroundColor: "navy" }}
                      >
                        <i className="fas fa-edit" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            );
          });

          return (
            <Scroll>
              <table
                id="example"
                className="table table-striped table-bordered text-right "
              >
                <thead>
                  <tr
                    className="text-warning f4"
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <th style={{ border: "2px solid #f0ad4e" }}>کۆی گشتی</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>ڕێژە</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>نرخ</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>دانە</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>کارتۆن</th>
                    <th style={{ border: "2px solid #f0ad4e" }}>بەروار</th>
                    <th style={{ border: "2px solid #f0ad4e" }}></th>
                  </tr>
                </thead>
                <tbody>{RoboFrinds}</tbody>
              </table>
            </Scroll>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default ScroolTableAddPro;
