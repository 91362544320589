import React from "react";
import FormPrintMasruf from "./FormPrintArz";
import rahel from "./../../../img/rahel1.jpg";
import { ProductConsumer } from "../../../Context/AddProWakel";

const InputPrintMasruf = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { HK_draw } = value;
          return (
            <>
              <img src={rahel} alt="rahel" />
              <div className="row mb2">
                <div className="col-4 text-right form-inline "></div>
                <div className="col-4 text-right form-inline ">
                  <div className="form-group ">
                    <label className="font-weight-bold f3 ml5">
                      ڕاپۆرتی پیشاندان
                    </label>
                  </div>
                </div>
                <div className="col-4 text-black text-right form-inline font-weight-bold"></div>
              </div>

              <div
                className="row text-dark font-weight-bold f3 mx-4 tc"
                style={{ border: "2px solid black" }}
              ></div>

              <div className="mt4"></div>
              <FormPrintMasruf />
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default InputPrintMasruf;
