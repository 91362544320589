import React from "react";
import SearchMandub from "./SearchMandub";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Time from "react-time-format";

const FormMandub = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            MM_zhamara,
            MM_joredraw,
            MM_zhmaraybarez,
            MM_openUpdate,
            MM_search_wasl,
            MM_barwar,
            MM_del_dis,
            MM_nawebarez,
            MM_qarzeKonDolar,
            MM_qarzeKonID,
            MM_onSaveMoney,
            MM_getParaDataBySearchMoney,
            MM_setSearchMonyWakilEmpty,
            MM_brepara,
            MM_getPrint,
            MM_getWakilDataBySearchMoney,
            MM_createNewMony,
            MM_toggler,
            MM_wasleShareka,
            MM_save,
            MM_nawewargr,
            MM_open,
            L_user,
            MM_wasl,
            MM_updtate_para,
            MM_pageminsone,
            MM_pageplusone,
            MM_inputtogler,
          } = value;
          const renderdate = (kat) => {
            const monthname = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ];
            const date = new Date(kat);

            return `${date.getDate()}/${
              monthname[date.getMonth()]
            }/${date.getFullYear()}`;
          };
          return (
            <>
              <div className="row ">
                <div className="col-md-1"></div>
                <div className="col-md-12 col-lg-4 ">
                  {/* Search take money */}
                  <SearchMandub />
                </div>
                <div className="col-md-1"></div>
                {/* Form take moneyy */}
                <div className="col-lg-6 text-warning col-md-12 ">
                  <div
                    className="card card-warning "
                    style={{ backgroundColor: "#2C3539" }}
                  >
                    <div
                      className="card-header p-1 border-bottom border-warning"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <h2 className="card-title tc">پارەی کۆمپانیاکان</h2>
                    </div>

                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-3 text-right mt2">
                            <button
                              disabled={MM_del_dis === true ? true : null}
                              onClick={MM_inputtogler}
                              className="btn btn-info mt4"
                            >
                              <i className="far fa-calendar-alt"></i>
                            </button>
                          </div>

                          <div
                            className={`${
                              MM_toggler === true
                              ? "col-sm-6 text-right"
                              : "col-sm-3 text-right"
                            }`}
                          >
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بەروار
                              </label>
                              {MM_toggler === false ? (
                                <input
                                  type="text"
                                  name="MM_barwar"
                                  value={
                                    MM_search_wasl === true || MM_save === true
                                      ? renderdate(MM_barwar)
                                      : renderdate(Date.now())
                                  }
                                  className="form-control text-right font-weight-bold"
                                />
                              ) : (
                                <input
                                  type="datetime-local"
                                  name="MM_barwar"
                                  value={MM_barwar}
                                  onChange={AddProChange}
                                  className="form-control text-right font-weight-bold tc btn btn-info"
                                  style={{ margin: "0px 8px" }}
                                />
                              )}
                            </div>
                          </div>
                          {MM_toggler === false ? (
                            <div className="col-sm-3 text-right">
                              <div className="form-group">
                                <label className="font-weight-bold f4">
                                  کات
                                </label>
                                {MM_search_wasl === true || MM_save === true ? (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={MM_barwar}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                ) : (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={Date.now()}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : null}

                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژمارە
                              </label>
                              <input
                                type="text"
                                name="MM_zhamara"
                                disabled={MM_del_dis === true ? true : null}
                                value={MM_zhamara}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? MM_getParaDataBySearchMoney(event)
                                    : null
                                }
                                onChange={AddProChange}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                            <div className=" text-right ">
                              <label className="form-check-label font-weight-bold f3">
                                کۆمپانیاکان
                              </label>
                            </div>
                            <hr />
                          </div>
                          <div className="col-md-3 text-right mt4">
                            <div className="row">
                              <div className="col-md-4"></div>
                              <div className="col-md-4">
                                <div className="form-group ">
                                  <button
                                    disabled={MM_del_dis === true ? true : null}
                                    onClick={MM_setSearchMonyWakilEmpty}
                                    className="btn btn-dark"
                                  >
                                    <i className="fas fa-search-plus"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                          </div>
                          <div className="col-sm-6 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ناوی بەرێز
                              </label>
                              <input
                                type="text"
                                name="MM_nawebarez"
                                disabled={MM_del_dis === true ? true : null}
                                onChange={AddProChange}
                                value={MM_nawebarez}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>

                          <div className="col-md-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژ.بەڕێز
                              </label>
                              <input
                                type="text"
                                name="MM_zhmaraybarez"
                                onClick={MM_setSearchMonyWakilEmpty}
                                disabled={MM_del_dis === true ? true : null}
                                onChange={AddProChange}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? MM_getWakilDataBySearchMoney(event)
                                    : null
                                }
                                value={
                                  MM_zhmaraybarez === 0 ? "" : MM_zhmaraybarez
                                }
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژمارەی پسوولە
                              </label>
                              <input
                                type="text"
                                name="MM_wasleShareka"
                                disabled={MM_del_dis === true ? true : null}
                                onChange={AddProChange}
                                value={
                                  MM_wasleShareka === 0 ? "" : MM_wasleShareka
                                }
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>

                          <div className="col-sm-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ID قەرزی ئەستۆ بە{" "}
                              </label>
                              <NumberFormat
                                value={MM_qarzeKonID}
                                displayType={"text"}
                                className="form-control text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"ID"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                $ قەرزی ئەستۆ بە{" "}
                              </label>
                              <NumberFormat
                                value={MM_qarzeKonDolar}
                                displayType={"text"}
                                className="form-control text-right font-weight-bold p-2"
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 text-right">
                            <label className="font-weight-bold f4">
                              ناوی وەرگر
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                MM_nawewargr === "‌‌‌‌‌‌حاجی ڕەقیب"
                                  ? "bg-success text-white"
                                  : `${
                                      MM_nawewargr === "حاجی رەمەزان"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              disabled={MM_del_dis === true ? true : null}
                              onChange={AddProChange}
                              name="MM_nawewargr"
                              value={MM_nawewargr !== null ? MM_nawewargr : ""}
                            >
                              <option className="ml5">...ناوی وەرگر</option>
                              <option>‌‌‌‌‌‌حاجی ڕەقیب</option>
                              <option>حاجی رەمەزان</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right">
                            <label className="font-weight-bold f4">
                              جۆری دراو
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                MM_joredraw === "دۆلار"
                                  ? "bg-success text-white"
                                  : `${
                                      MM_joredraw === "دینار"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              disabled={MM_del_dis === true ? true : null}
                              onChange={AddProChange}
                              name="MM_joredraw"
                              value={MM_joredraw !== null ? MM_joredraw : ""}
                            >
                              <option className="ml5">..جۆری دراو</option>
                              <option>دۆلار</option>
                              <option>دینار</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بڕی پارە
                              </label>
                              <NumberFormat
                                displayType="input"
                                thousandSeparator={true}
                                name="MM_brepara"
                                onChange={AddProChange}
                                disabled={MM_del_dis === true ? true : null}
                                value={MM_brepara === 0 ? "" : MM_brepara}
                                className="form-control text-right font-weight-bold bg-info text-white"
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-1 text-right"></div> */}
                          <div className="col-sm-8 mt4">
                            <button
                              className="btn btn-dark p3 px-4"
                              style={{
                                backgroundColor: "#00695c",
                                border: "1px solid #00695c",
                              }}
                              disabled={MM_open === true ? true : null}
                              onClick={MM_pageminsone}
                            >
                              <i className="fas fa-caret-left f4"></i>
                            </button>
                            <button
                              className="btn btn-dark p3 px-4 ml3"
                              style={{
                                backgroundColor: "#00695c",
                                border: "1px solid #00695c",
                              }}
                              disabled={MM_open === true ? true : null}
                              onClick={MM_pageplusone}
                            >
                              <i className="fas fa-caret-right f4"></i>
                            </button>
                            {MM_search_wasl === true ? (
                              <>
                                {MM_open === null ? (
                                  <button
                                    onClick={(e) =>
                                      MM_openUpdate(L_user._id, e, MM_wasl)
                                    }
                                    className="btn btn-md btn-warning px-4 ml3"
                                    style={{
                                      backgroundColor: "silver",
                                      border: "1px solid silver",
                                    }}
                                  >
                                    <i className="fas fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    onClick={(e) =>
                                      MM_updtate_para(e, MM_wasl, MM_wasl)
                                    }
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{ backgroundColor: "navy" }}
                                  >
                                    <i className="fas fa-edit "></i>
                                  </button>
                                )}
                                <Link to="/print-mandub">
                                  <button
                                    onClick={MM_getPrint}
                                    type="button"
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{
                                      backgroundColor: "purple",
                                      border: "1px solid purple",
                                    }}
                                  >
                                    <i className="fas fa-print fa-lg"></i>
                                  </button>
                                </Link>
                              </>
                            ) : MM_save === null ? (
                              <button
                                type="button"
                                onClick={MM_onSaveMoney}
                                className="btn btn-md btn-success px-4 ml3"
                              >
                                <i className="far fa-save fa-lg"></i>
                              </button>
                            ) : null}
                            <button
                              type="button"
                              onClick={MM_createNewMony}
                              disabled={MM_open === true ? true : null}
                              className="btn btn-md btn-primary px-4 ml3"
                            >
                              <i className="far fa-file fa-lg"></i>
                            </button>
                            <Link to="/print-mandub">
                              {MM_save === true ? (
                                <>
                                  <button
                                    onClick={MM_getPrint}
                                    type="button"
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{
                                      backgroundColor: "purple",
                                      border: "1px solid purple",
                                    }}
                                  >
                                    <i className="fas fa-print fa-lg"></i>
                                  </button>
                                  {MM_open === null ? (
                                    <button
                                      onClick={(e) =>
                                        MM_openUpdate(L_user._id, e, MM_wasl)
                                      }
                                      className="btn btn-md btn-warning px-4 ml3"
                                      style={{
                                        backgroundColor: "silver",
                                        border: "1px solid silver",
                                      }}
                                    >
                                      <i class="fas fa-lock"></i>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={(e) =>
                                        MM_updtate_para(e, MM_wasl, MM_wasl)
                                      }
                                      className="btn btn-md btn-primary px-4 ml3"
                                      style={{ backgroundColor: "navy" }}
                                    >
                                      <i className="fas fa-edit "></i>
                                    </button>
                                  )}
                                </>
                              ) : null}
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default FormMandub;
