import React from 'react'
import rahel from './../../../img/rahel1.jpg'
import FillMandubPrint from './FillMandubPrint'
const PrintAllMandub = () => {
    return (
        <>
            <img src={rahel} alt='rahel' />
            <FillMandubPrint />
        </>
    )
}

export default PrintAllMandub
