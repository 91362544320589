import React from "react";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import { Link } from "react-router-dom";
const Header = () => {
  var today = new Date();
  var kat =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  return (
    <ProductConsumer>
      {(value) => {
        const {
          RT_zhmaraybarez,
          RT_nawibrez,
          RT_mob,
          RT_from,
          RT_to,
          RT_Paradanakan,
          AddProChange,
          RT_getPrint,
          RT_mandubakan,
          RT_paradan,
          RT_mandub,
          RT_Krenakan,
          RT_filter,
        } = value;

        return (
          <>
            <div
              className="row mt5 tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-warning ">
                {" "}
                <Link to="/print-kas">
                  <button
                    className="btn btn-warning"
                    onClick={(e) => RT_getPrint(e)}
                  >
                    چاپکردن
                  </button>
                </Link>
              </div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                <h5>ڕاپۆرتی کەس</h5>
              </div>
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-white">
                <label>
                  {RT_nawibrez}-{RT_zhmaraybarez}
                </label>
              </div>
            </div>
            <div
              className="row  tc text-right"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-4 mt4 font-weight-bold  pointer form-group text-warning">
                {" "}
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    RT_Paradanakan(e);
                  }}
                >
                  پیشاندان
                </button>
              </div>
              <div className="col-md-4 mt1 font-weight-bold  pointer form-group text-warning ">
                :بۆ بەرواری{" "}
                <span className="text-warning">
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RT_to"
                    value={RT_to}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
              <div className="col-md-4 mt1 font-weight-bold   form-group text-warning tc">
                :لە بەرواری
                <span className="text-warning">
                  {" "}
                  <input
                    type="date"
                    onChange={AddProChange}
                    name="RT_from"
                    value={RT_from}
                    className="selectpicker btn btn-success"
                  />
                </span>
              </div>
            </div>

            <div
              className="row text-right pt2"
              style={{ backgroundColor: "#2e3541 " }}
            >
              <div
                className="col-md-4 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    RT_paradan === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={RT_Paradanakan}
              >
                <button className="btn btn-info">پارەدان</button>
              </div>
              <div
                className="col-md-4 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    RT_mandub === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={RT_mandubakan}
              >
                <button className="btn btn-info"> مافی.کۆمپانیا</button>
              </div>
              <div
                className="col-md-4 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    RT_mandub === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={RT_Krenakan}
              >
                <button className="btn btn-info">کڕینی كاڵا</button>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
