import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
class FooterMob extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              KM_onSaveRent,
              AddProChange,
              KM_waslKrdn,
              KM_total,
              dolartodinar,
              getPrint,
              KM_search_wasl,
              KM_del_dis,
              KM_joripara,
              KM_save,
              KM_getPrintPasswordRent,
              KM_open,
              KM_paraDan$,
              KM_pageminsone,
              KM_pageplusone,
              KM_showLoader,
              KM_hideLoader,
              KM_loading,
            } = value;
            return (
              <>
                <div className="row">
                  <div className=" col-md-12">
                    <table className="table " id="yusif">
                      <thead>
                        <tr
                          className={`${
                            KM_save === true || KM_search_wasl === true
                              ? "bg-white"
                              : ""
                          }`}
                        >
                          <td className="text-center bg-white">
                            <NumberFormat
                              value={KM_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              className=""
                            />
                          </td>
                          <th
                            className="text-danger  bg-white"
                            style={{ width: "45%" }}
                          >
                            : کۆی گشتی
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">
                            <NumberFormat
                              displayType={"input"}
                              thousandSeparator={true}
                              disabled={KM_del_dis === true ? true : null}
                              name="KM_paraDan$"
                              onChange={AddProChange}
                              value={KM_paraDan$}
                              style={{ padding: "8px 4px" }}
                              className={`border-0  pl-4 ${
                                KM_save === true || KM_search_wasl === true
                                  ? "bg-white"
                                  : ""
                              }`}
                            />
                          </td>
                          <th className="text-warning ">:$ پارەی دراو</th>
                        </tr>
                        <tr>
                          <td className="text-center bg-white">
                            {KM_paraDan$ !== "" ? (
                              <NumberFormat
                                value={KM_waslKrdn()}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                className=""
                              />
                            ) : (
                              <NumberFormat
                                value={KM_total}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                                className=""
                              />
                            )}
                          </td>
                          <th className="text-danger  bg-white">
                            :پارەی نەدراو
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="row mt-3 tc">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <button
                            className="btn btn-dark p3 px-4"
                            onClick={KM_pageminsone}
                            style={{
                              backgroundColor: "#00695c",
                              border: "1px solid #00695c",
                            }}
                          >
                            <i class="fas fa-caret-left "></i>
                          </button>
                        </div>
                        <div className="col-md-6">
                          {" "}
                          <button
                            className="btn btn-dark p3 px-4 ml3"
                            onClick={KM_pageplusone}
                            style={{
                              backgroundColor: "#00695c",
                              border: "1px solid #00695c",
                            }}
                          >
                            <i class="fas fa-caret-right "></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb5">
                      {KM_search_wasl !== null ? (
                        <>
                          <Link to="/Print_kren">
                            <button
                              type="button"
                              onClick={KM_getPrintPasswordRent}
                              className="btn btn-info mr1"
                              style={{
                                backgroundColor: "#9900ff",
                                border: "1px solid #9900ff",
                              }}
                            >
                              <i className="fas fa-print fa-lg"></i>
                            </button>
                          </Link>
                        </>
                      ) : KM_save === true ? (
                        <>
                          <Link to="/Print_kren">
                            <button
                              type="button"
                              onClick={getPrint}
                              className="btn btn-info"
                              style={{
                                backgroundColor: "#9900ff",
                                border: "1px solid #9900ff",
                              }}
                            >
                              <i className="fas fa-print fa-lg"></i>
                            </button>
                          </Link>
                        </>
                      ) : (
                        <>
                          {KM_loading ? (
                            <button
                              type="button"
                              onDoubleClick={KM_hideLoader}
                              className="btn btn-success "
                            >
                              <i class="fas fa-spinner animate__animated animate__rotateIn animate__infinite"></i>
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={(e) => {
                                KM_showLoader(e);
                                KM_onSaveRent(e);
                              }}
                              className="btn btn-success"
                            >
                              <i className="far fa-save fa-lg"></i>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}

export default FooterMob;
