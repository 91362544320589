import React from "react";
import Navbar from "../../../Navbar/Navbar";
import AddproductRightSide from "./AddproductRightSide";
import AddProductLeftSide from "./AddProductLeftSide";
import { isMobile } from "react-device-detect";

const AddProduct = () => {
  return (
    <>
      <Navbar />
      <div className="mt-5"></div>
      <div className="row">
        {isMobile ? (
          <>
            <div className="col-md-12 col-lg-8 ">
              <AddproductRightSide />
            </div>
            <AddProductLeftSide />
          </>
        ) : (
          <>
              <div className="col-md-12 col-lg-8 ">
              <AddProductLeftSide />
            </div>
            <AddproductRightSide />
          </>
        )}
      </div>
    </>
  );
};

export default AddProduct;
