import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableKas from "./ScroollTableKas";
import NavbarOfKas from "./NavbarOfKas";
import FooterOfKas from "./FooterOfKas";
import Time from "react-time-format";
import { ProductConsumer } from "../../../Context/AddProWakel";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";

const AllKasRaport = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const { RT_zhmaraybarez, RT_nawibrez, RT_mob, RT_from, RT_to } = value;

        return (
          <div>
            <Navbar />
            {isMobile ? (
              <AllMob />
            ) : (
              <>
                <div className="row" style={{ backgroundColor: "#2e3541 " }}>
                  <div
                      className="col-lg-10 col-md-12"
                    style={{ marginTop: "60px" }}
                  >
                    <div className="row">
                        <div className="col-md-3">
                        <div className="row">
                            {/* <div className="col-md-3"></div> */}
                            <div className="col-md-6  tc form-inline">
                            <p
                              className="font-weight-bold bg-white  "
                              style={{ color: "#000" }}
                            >
                              <Time format="YYYY-MM-DD" />
                            </p>
                            <span style={{ color: "#2e3541 " }}>:</span>
                            <p
                              className="font-weight-bold bg-white  "
                              style={{ color: "#000" }}
                            >
                              {RT_to}
                            </p>
                          </div>
                            <div className="col-md-6 form-inline">
                            <label className="text-warning font-weight-bold ">
                              :بۆ بەرواری{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                        <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-6 tc form-inline">
                            <p
                              className="font-weight-bold bg-white "
                              style={{ color: "#000" }}
                            >
                              <Time format="YYYY-MM-DD" />
                            </p>
                            <span style={{ color: "#2e3541 " }}>:</span>
                            <p
                              className="font-weight-bold bg-white "
                              style={{ color: "#000" }}
                            >
                              {RT_from}
                            </p>
                          </div>
                            <div className="col-md-6 form-inline">
                            <label className="text-warning font-weight-bold ">
                              : لە بەرواری{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                        <div className="col-md-6" style={{ paddingTop: "25px" }}>
                        <div className="row">
                            <div className="col-md-2">
                            <h4 className="fk text-black font-weight-bold  bg-white tc py-2">
                              {RT_zhmaraybarez}
                            </h4>
                          </div>
                            <div className="col-md-5">
                            <h4 className="fk text-black font-weight-bold  bg-white tc py-2">
                              {RT_mob}
                            </h4>
                          </div>
                            <div className="col-md-5">
                            <h4 className="fk text-black font-weight-bold  bg-white tc py-2">
                              {RT_nawibrez}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ">
                    <div className="col-lg-10 col-md-10">
                    <ScroollTableKas />
                  </div>
                    <div className="col-lg-2 col-md-2">
                    <NavbarOfKas />
                  </div>
                </div>
                <div className="row" style={{ backgroundColor: "#2e3541 " }}>
                    <div className="col-lg-12 col-md-12">
                    <FooterOfKas />
                  </div>
                </div>
              </>
            )}
          </div>
        );
      }}
    </ProductConsumer>
  );
};
export default AllKasRaport;
