import React from "react"
import { ProductConsumer } from '../../../Context/AddProWakel'
import NumberFormat from 'react-number-format'
import Time from 'react-time-format'
import { Link } from "react-router-dom";

class ScroollTableMandub extends React.Component {

    render() {
        return (
        
            <>
            <ProductConsumer >
                
                {value=>{
                    const {RMM_data,RMM_getParaDataBySearchMoney}=value
                        const qarzeWakel=RMM_data.map((user,i)=>{
                            return (
                                <tr key={i} className='fk f4'>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.nawiwargr}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}} className={`${user.joripara==='دۆلار'?'text-success':'text-danger'}`}>{user.joripara}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}><NumberFormat value={user.joripara==='دۆلار'?user.paraywargirawdolar:user.paraywargirawdinar} thousandSeparator={true} displayType='text' /></td>
                                <td className="fk text-danger" style={{border:"2px solid #f0ad4e"}}>{user.zhmaraaywasl}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.nawishwen}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.shwencode}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}><Time value={user.date}  format="YYYY-MM-DD" style={{color:'black'}} /></td>
                                <td className="fk" style={{border:"2px solid #f0ad4e", cursor:"pointer"}} onClick={(e)=>RMM_getParaDataBySearchMoney(e,user.zhmara)}><Link to='/Mandub'>{user.zhmara}</Link></td>
                                
                            </tr>
                            )
                            
            
        })
    
        return (
           
            <div className="JM">
                
               <table id="example" className="table table-hover table-bordered text-right">
            <thead>
                <tr className='text-warning f4' style={{backgroundColor:"#2C3539"}}>
                    <th style={{border:"2px solid #f0ad4e"}}>ناوی وەرگر</th>
                    <th style={{border:"2px solid #f0ad4e"}}>دراو</th>
                    <th style={{border:"2px solid #f0ad4e"}}>کۆی پسوولە</th>
                    <th style={{border:"2px solid #f0ad4e"}}>و.کۆمپانیا</th>
                    <th style={{border:"2px solid #f0ad4e"}}>ناوی بەڕێز</th>
                    <th style={{border:"2px solid #f0ad4e"}}>کۆد</th>
                    <th style={{border:"2px solid #f0ad4e"}}>بەروار</th>
                    <th style={{border:"2px solid #f0ad4e"}}>ژمارە</th>
                </tr>
            </thead>
            <tbody>
            {qarzeWakel}
            </tbody>
        </table> 
        </div>
        )
            }}
            </ProductConsumer>
            
            </>
        )
    }
}
export default ScroollTableMandub