import React from "react";
import SearchParadarhenan from "./SearchParadarhenan";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Time from "react-time-format";

const FormParadarhenan = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            PM_zhamara,
            PM_joredraw,
            PM_openUpdate,
            PM_search_wasl,
            PM_barwar,
            PM_del_dis,
            PM_parayesta$,
            PM_parayestaID,
            PM_onSaveMoney,
            PM_getParaDataBySearchMoney,
            PM_brepara,
            PM_getPrint,
            PM_createNewMony,
            PM_toggler,
            toanotherInput,
            PM_nawikas,
            role,
            PM_show,
            PM_showrasid,
            PM_save,
            PM_nawewargr,
            PM_open,
            L_user,
            PM_wasl,
            PM_updtate_para,
            PM_pageminsone,
            PM_pageplusone,
            PM_inputtogler,
          } = value;
          const renderdate = (kat) => {
            const monthname = [
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
            ];
            const date = new Date(kat);

            return `${date.getDate()}/${
              monthname[date.getMonth()]
            }/${date.getFullYear()}`;
          };
          return (
            <>
              <div className="row ">
                <div className="col-md-1"></div>
                <div className="col-md-12 col-lg-4 ">
                  {/* Search take money */}
                  <SearchParadarhenan />
                </div>
                <div className="col-md-1"></div>
                {/* Form take moneyy */}
                <div className="col-lg-6 text-warning col-md-12 ">
                  <div
                    className="card card-warning "
                    style={{ backgroundColor: "#2e3541 " }}
                  >
                    <div
                      className="card-header p-1 border-bottom border-warning"
                      style={{ backgroundColor: "#2e3541 " }}
                    >
                      <h2 className="card-title tc">پارە دەرهینان</h2>
                    </div>

                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-md-3 text-right mt2">
                            <button
                              disabled={PM_del_dis === true ? true : null}
                              onClick={PM_inputtogler}
                              className="btn btn-info mt4"
                            >
                              <i className="far fa-calendar-alt"></i>
                            </button>
                          </div>

                          <div
                            className={`${
                              PM_toggler === true
                              ? "col-sm-6 text-right"
                              : "col-sm-3 text-right"
                            }`}
                          >
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بەروار
                              </label>
                              {PM_toggler === false ? (
                                <input
                                  type="text"
                                  name="PM_barwar"
                                  value={
                                    PM_search_wasl === true || PM_save === true
                                      ? renderdate(PM_barwar)
                                      : renderdate(Date.now())
                                  }
                                  className="form-control text-right font-weight-bold"
                                />
                              ) : (
                                <input
                                  type="datetime-local"
                                  name="PM_barwar"
                                  value={PM_barwar}
                                  onChange={AddProChange}
                                  className="form-control text-right font-weight-bold tc btn btn-info"
                                  style={{ margin: "0px 8px" }}
                                />
                              )}
                            </div>
                          </div>
                          {PM_toggler === false ? (
                            <div className="col-sm-3 text-right">
                              <div className="form-group">
                                <label className="font-weight-bold f4">
                                  کات
                                </label>
                                {PM_search_wasl === true || PM_save === true ? (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={PM_barwar}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                ) : (
                                  <p className="font-weight-bold bg-white p-2 border rounded tc">
                                    <Time
                                      value={Date.now()}
                                      format="hh:mm:ss"
                                      style={{ color: "black" }}
                                    />
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : null}

                          <div className="col-sm-3 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ژمارە
                              </label>
                              <input
                                type="text"
                                name="PM_zhamara"
                                disabled={PM_del_dis === true ? true : null}
                                value={PM_zhamara}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? PM_getParaDataBySearchMoney(event)
                                    : null
                                }
                                onChange={AddProChange}
                                className={`form-control text-right font-weight-bold ${
                                  PM_search_wasl === true || PM_save === true
                                    ? "bg-danger text-white"
                                    : ""
                                }`}
                              />
                            </div>
                          </div>
                          <div className="col-md-2 text-right"></div>
                          <div className="col-md-1 text-right mt-5">
                            {role === "admin" ? (
                              PM_show === false ? (
                                <button
                                  onClick={(e) => PM_showrasid(e, L_user._id)}
                                  className="btn btn-primary"
                                  style={{ backgroundColor: "orange" }}
                                >
                                  <i className="fas fa-eye"></i>
                                </button>
                              ) : (
                                <button
                                  onClick={PM_showrasid}
                                  className="btn btn-primary"
                                  style={{ backgroundColor: "orange" }}
                                >
                                  <i className="fas fa-eye-slash"></i>
                                </button>
                              )
                            ) : null}
                          </div>
                          <div className="col-md-1 text-right"></div>

                          <div className="col-sm-4 text-right p-2 border-bottom border-warning">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ID ڕەسیدی ئێستا بە{" "}
                              </label>
                              {PM_show === false ? (
                                <NumberFormat
                                  value={"0"}
                                  displayType={"text"}
                                  className="form-control  text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-danger"
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              ) : (
                                <NumberFormat
                                  value={PM_parayestaID}
                                  displayType={"text"}
                                  className="form-control  text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-danger"
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              )}
                            </div>
                          </div>

                          <div className="col-md-4 text-right p-2 border-bottom border-warning">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                $ ڕەسیدی ئێستا بە{" "}
                              </label>
                              {PM_show === false ? (
                                <NumberFormat
                                  value={"0"}
                                  displayType={"text"}
                                  className="form-control  text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-success"
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              ) : (
                                <NumberFormat
                                  value={PM_parayesta$}
                                  displayType={"text"}
                                  className="form-control  text-right font-weight-bold p-2 card-header p-1 border-bottom border-warning text-white bg-success"
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              )}
                            </div>
                          </div>

                          <div className="col-sm-7 text-right"></div>

                          <div className="col-sm-5 text-right mt-3">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                ناوی پارە بردن{" "}
                              </label>
                              <input
                                type="text"
                                name="PM_nawikas"
                                onChange={AddProChange}
                                disabled={PM_del_dis === true ? true : null}
                                value={PM_nawikas}
                                className="form-control text-right font-weight-bold"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 text-right">
                            <label className="font-weight-bold f4">
                              ناوی وەرگر
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                PM_nawewargr === "هاوکار"
                                  ? "bg-success text-white"
                                  : `${
                                      PM_nawewargr === "کارمەند"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              disabled={PM_del_dis === true ? true : null}
                              onChange={AddProChange}
                              name="PM_nawewargr"
                              value={PM_nawewargr !== null ? PM_nawewargr : ""}
                            >
                              <option className="ml5">...ناوی وەرگر</option>
                              <option>هاوکار</option>
                              <option>کارمەند</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right">
                            <label className="font-weight-bold f4">
                              جۆری دراو
                            </label>
                            <select
                              className={`form-control text-right font-weight-bold selectpicker pull-right ${
                                PM_joredraw === "دۆلار"
                                  ? "bg-success text-white"
                                  : `${
                                      PM_joredraw === "دینار"
                                        ? "bg-danger text-white"
                                        : ""
                                    }`
                              } `}
                              disabled={PM_del_dis === true ? true : null}
                              onChange={AddProChange}
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? toanotherInput(event)
                                  : null
                              }
                              name="PM_joredraw"
                              value={PM_joredraw !== null ? PM_joredraw : ""}
                            >
                              <option className="ml5">..جۆری دراو</option>
                              <option>دۆلار</option>
                              <option>دینار</option>
                            </select>
                          </div>
                          <div className="col-sm-4 text-right">
                            <div className="form-group">
                              <label className="font-weight-bold f4">
                                بڕی پارە
                              </label>
                              <NumberFormat
                                displayType="input"
                                thousandSeparator={true}
                                name="PM_brepara"
                                onChange={AddProChange}
                                disabled={PM_del_dis === true ? true : null}
                                onKeyPress={(event) =>
                                  event.key === "Enter"
                                    ? toanotherInput(event)
                                    : null
                                }
                                value={PM_brepara === 0 ? "" : PM_brepara}
                                className="form-control text-right font-weight-bold bg-info text-white"
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-1 text-right"></div> */}
                          <div className="col-sm-8 mt4">
                            <button
                              className="btn btn-dark p3 px-4"
                              style={{
                                backgroundColor: "#00695c",
                                border: "1px solid #00695c",
                              }}
                              disabled={PM_open === true ? true : null}
                              onClick={PM_pageminsone}
                            >
                              <i className="fas fa-caret-left f4"></i>
                            </button>
                            <button
                              className="btn btn-dark p3 px-4 ml3"
                              style={{
                                backgroundColor: "#00695c",
                                border: "1px solid #00695c",
                              }}
                              disabled={PM_open === true ? true : null}
                              onClick={PM_pageplusone}
                            >
                              <i className="fas fa-caret-right f4"></i>
                            </button>
                            {PM_search_wasl === true ? (
                              <>
                                {PM_open === null ? (
                                  <button
                                    onClick={(e) =>
                                      PM_openUpdate(L_user._id, e)
                                    }
                                    className="btn btn-md btn-warning px-4 ml3"
                                    style={{
                                      backgroundColor: "silver",
                                      border: "1px solid silver",
                                    }}
                                  >
                                    <i className="fas fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    onClick={(e) =>
                                      PM_updtate_para(
                                        e,
                                        PM_wasl,
                                        PM_wasl.wakilcode
                                      )
                                    }
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{ backgroundColor: "navy" }}
                                  >
                                    <i className="fas fa-edit "></i>
                                  </button>
                                )}
                                <Link to="/print-paradarhenan">
                                  <button
                                    onClick={PM_getPrint}
                                    type="button"
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{
                                      backgroundColor: "purple",
                                      border: "1px solid purple",
                                    }}
                                  >
                                    <i className="fas fa-print fa-lg"></i>
                                  </button>
                                </Link>
                              </>
                            ) : PM_save === null ? (
                              <button
                                type="button"
                                onClick={PM_onSaveMoney}
                                className="btn btn-md btn-success px-4 ml3"
                              >
                                <i className="far fa-save fa-lg"></i>
                              </button>
                            ) : null}
                            <button
                              type="button"
                              onClick={PM_createNewMony}
                              disabled={PM_open === true ? true : null}
                              className="btn btn-md btn-primary px-4 ml3"
                            >
                              <i className="far fa-file fa-lg"></i>
                            </button>
                            <Link to="/print-paradarhenan">
                              {PM_save === true ? (
                                <>
                                  <button
                                    onClick={PM_getPrint}
                                    type="button"
                                    className="btn btn-md btn-primary px-4 ml3"
                                    style={{
                                      backgroundColor: "purple",
                                      border: "1px solid purple",
                                    }}
                                  >
                                    <i className="fas fa-print fa-lg"></i>
                                  </button>
                                  {PM_open === null ? (
                                    <button
                                      onClick={(e) =>
                                        PM_openUpdate(L_user._id, e)
                                      }
                                      className="btn btn-md btn-warning px-4 ml3"
                                      style={{
                                        backgroundColor: "silver",
                                        border: "1px solid silver",
                                      }}
                                    >
                                      <i class="fas fa-lock"></i>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={(e) =>
                                        PM_updtate_para(e, PM_wasl)
                                      }
                                      className="btn btn-md btn-primary px-4 ml3"
                                      style={{ backgroundColor: "navy" }}
                                    >
                                      <i className="fas fa-edit "></i>
                                    </button>
                                  )}
                                </>
                              ) : null}
                            </Link>
                            <br /> <br /> <br /> <br />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default FormParadarhenan;
