import React from 'react'
import Navbar from '../../../Navbar/Navbar'
import MasrufSecond from './MasrufSecond'

const InputMasruf = () => {
    return (
        <>
                    <Navbar/>
                    <div className="mt5"></div>
                    
                   <MasrufSecond/>
        </>
    )
}

export default InputMasruf
