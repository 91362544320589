import React from "react";
import { ProductConsumer } from "../../Context/AddProWakel";
const PutUser = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { A_addUserData, clickOnDeleteAddUser, edit_product } = value;
          const AddUser = A_addUserData.map((user, i) => {
            return (
              <tr key={i} className="f4">
                <td style={{ border: "2px solid #f0ad4e" }}>
                  <button
                    onClick={() => edit_product(user)}
                    className="btn btn-primary  mr4"
                  >
                    <i className="fas fa-edit "></i>
                  </button>
                  <button
                    onClick={() => clickOnDeleteAddUser(user._id)}
                    className="btn btn-danger p1"
                  >
                    <i className="fa fa-minus" aria-hidden="true"></i>
                  </button>
                </td>
                <td style={{ border: "2px solid #f0ad4e" }}>{user.role}</td>
                <td style={{ border: "2px solid #f0ad4e" }}>{user.phone}</td>

                <td style={{ border: "2px solid #f0ad4e" }}>
                  <span>{user.name}</span>
                </td>
              </tr>
            );
          });

          return (
            <div className="card ">
              <div
                className="card-header"
                style={{ backgroundColor: "#2C3539" }}
              >
                <h4 className="card-title tc text-warning font-weight-bold">
                  ناوی بەکارهێنەر
                </h4>
              </div>

              <div className="card-body text-right">
                <table className="table table-bordered">
                  <thead>
                    <tr style={{ backgroundColor: "#2C3539" }}>
                      <th
                        className=" text-warning font-weight-bold f4"
                        style={{ border: "2px solid #f0ad4e" }}
                      >
                        هێما ئیشپێکردن
                      </th>
                      <th
                        className=" text-warning font-weight-bold f4"
                        style={{ border: "2px solid #f0ad4e" }}
                      >
                        ڕۆڵ
                      </th>
                      <th
                        className=" text-warning font-weight-bold f4"
                        style={{ border: "2px solid #f0ad4e" }}
                      >
                        ژ.مۆبایل
                      </th>
                      <th
                        className=" text-warning font-weight-bold f4"
                        style={{ border: "2px solid #f0ad4e" }}
                      >
                        ناوی بەکارهێنەر
                      </th>
                    </tr>
                  </thead>
                  <tbody>{AddUser}</tbody>
                </table>
              </div>
            </div>
          );
        }}
      </ProductConsumer>
    </>
  );
};
export default PutUser;
