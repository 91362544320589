import React from "react"
import {ProductConsumer} from '../../../Context/AddProWakel'
import NumberFormat from 'react-number-format'
import Time from 'react-time-format'

class FormPrintRaportyWakel extends React.Component {

    render() {
        return (
        
            <>
            <ProductConsumer >
                
                {value=>{
                    const {RW_dataWakelRaport}=value

                        const wakil=RW_dataWakelRaport.map((user,i)=>{
                            return (
                            <tr key={i} className="f4 fk" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black' }}>
                                <td className="fk" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black' ,fontSize:"1.3em",color:'black'}}><NumberFormat value={user.paraymawa} thousandSeparator={true} displayType='text' /></td>
                                <td className="fk" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black' ,fontSize:"1.3em",color:'black'}}><NumberFormat value={user.paraydraw} thousandSeparator={true} displayType='text' /></td>
                                <td className="fk" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black' ,fontSize:"1.3em",color:'black'}}><NumberFormat value={user.kmkrdnawa} thousandSeparator={true} displayType='text' /></td>
                               <td className="fk" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black' ,fontSize:"1.3em",color:'black'}}>{user.joripara}</td>
                                <td className="fk"style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black' ,fontSize:"1.3em",color:'black'}}><NumberFormat value={user.koywasl} thousandSeparator={true} displayType='text' /></td>
                                <td className="fk" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black' ,fontSize:"1.3em",color:'black'}}><Time value={user.barwar}  format="YYYY-MM-DD" style={{color:'black'}} /></td>
                                <td className="fk" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black' ,fontSize:"1.3em",color:'black'}}>{user.jwlla}</td>
                                <td className="fk" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black' ,fontSize:"1.3em",color:'black'}}>{user.code}</td>
                        
                            </tr>
                            )
                            
            
        })
    
        return (
           

            <table id="example" className="table table-striped  text-right "  >
            <thead>
                <tr className='text-black f3 fk' style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black'}}>
                    <th className="f3" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black',color:'black',backgroundColor:'gray'}}>پارەی ماوە</th>
                    <th className="f3" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black',color:'black',backgroundColor:'gray'}}>پارەی دراو</th>
                    <th className="f3" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black',color:'black',backgroundColor:'gray'}}>داشکان</th>
                    <th className="f3" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black',color:'black',backgroundColor:'gray'}} > دراو</th>
                    <th className="f3" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black',color:'black',backgroundColor:'gray'}} >کۆی پسوولە</th>
                    <th className="f3" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black',color:'black',backgroundColor:'gray'}} >بەروار</th>
                    <th className="f3" style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black',color:'black',backgroundColor:'gray'}} >جووڵە</th>
                    <th className="f3"style={{borderBottom:'2px solid black',borderTop:"2px solid black",borderRight:'2px solid black',borderLeft:'2px solid black',color:'black',backgroundColor:'gray'}} >ژ.پسوولە</th>
                    
    
                </tr>
            </thead>
            <tbody>
            {wakil}
            </tbody>
        </table> 

        )
            }}
            </ProductConsumer>
            
            </>
        )
    }
}
export default FormPrintRaportyWakel