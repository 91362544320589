import React from "react";
// import "../../RaportyRozhana/NavbarRozhana.css";
import { ProductConsumer } from "../../../../Context/AddProWakel";
const Header = () => {
  return (
    <ProductConsumer>
      {(value) => {
        const {
          k_navbar_froshtn,
          K_getFroshtn,
          K_gettomarkrdn,
          k_navbar_tomarkrdn,
          k_navbar_garanawa,
          K_getGaranawa,
        } = value;

        return (
          <>
            <div
              className="row mt5 tc text-right tc"
              style={{
                backgroundColor: "#2e3541 ",
              }}
            >
              <div className="col-md-3 mt1 font-weight-bold  pointer form-group text-warning "></div>
              <div className="col-md-8 mt1 fk font-weight-bold   form-group text-warning tc">
                <h4 className="">ڕاپۆرتی كاڵا</h4>
              </div>
              <div className="col-md-1 mt1 font-weight-bold  pointer form-group text-warning"></div>
            </div>

            <div
              className="row text-right pt2"
              style={{ backgroundColor: "#2e3541 " }}
            >
              <div
                className="col-md-4 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    k_navbar_tomarkrdn === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={K_gettomarkrdn}
              >
                <button className="btn btn-info">تۆمارکردن/کڕین</button>
              </div>
              <div
                className="col-md-4 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    k_navbar_froshtn === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={K_getFroshtn}
              >
                <button className="btn btn-info">فرۆشتن</button>
              </div>
              <div
                className="col-md-4 text-warning font-weight-bold  pointer form-group"
                style={{
                  borderBottom: `${
                    k_navbar_garanawa === true ? "4px solid white" : ""
                  }`,
                }}
                onClick={K_getGaranawa}
              >
                <button className="btn btn-info">گەڕانەوە</button>
              </div>
            </div>
          </>
        );
      }}
    </ProductConsumer>
  );
};

export default Header;
