import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
const FooterOfKas = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { RT_para$, RT_paraID, RT_koyparadanID, RT_koyparadan$ } =
            value;
          return (
            <>
              {/* second row */}
              <br />
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="row ">
                    <div className="col-lg-2  col-md-3">
                      <h3 className="bg-white tc">
                        <NumberFormat
                          value={RT_koyparadan$}
                          className="bg-white f4 font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h3>
                    </div>
                    <div className="col-lg-3 col-md-3">
                      <h3 className="text-warning tc ">$کۆی پسولەکان</h3>
                    </div>
                    <div className="col-lg-1 col-md-2">
                      <h3 className="bg-warning tc">دۆلار</h3>
                    </div>

                    <div className="col-lg-2 col-md-2">
                      <h3 className="bg-white tc">
                        <NumberFormat
                          value={RT_para$}
                          className="bg-white f4 font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h3>
                    </div>
                    <div className="col-lg-2 col-md-2">
                      <h3 className="text-warning tc">قەرزی کۆتایی</h3>
                    </div>
                  </div>
                </div>
              </div>

              {/* third row */}
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="row ">
                    <div className="col-lg-2 col-md-2 ">
                      <h3 className="bg-white tc">
                        <NumberFormat
                          value={RT_koyparadanID}
                          className=" f4 font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h3>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <h3 className="text-warning tc">IDکۆی پسولەکان</h3>
                    </div>
                    <div className="col-lg-1 col-md-2">
                      <h3 className="bg-warning tc">دینار</h3>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <h3 className="bg-white tc">
                        <NumberFormat
                          value={RT_paraID}
                          className="bg-white f4 font-weight-bold  tc border-0"
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfKas;
