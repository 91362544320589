import React from 'react'
import './../RaportyRozhana/NavbarRozhana.css'
import { ProductConsumer } from '../../../Context/AddProWakel'
const NavbarOfRaportKala = () => {
    return (<>
        <ProductConsumer>
            {value=>{
              const {k_navbar_froshtn,K_getFroshtn,K_gettomarkrdn,k_navbar_tomarkrdn,k_navbar_garanawa,K_getGaranawa} = value
          return(<>

    <div className="sidebar text-warning font-weight-bold f4" style={{backgroundColor:"#2C3539"}}>
      <center>
        <h4 className=" pr2 f2 text-warning" >ڕاپۆرتی كاڵا</h4>
      </center>
      <td style={{backgroundColor:`${k_navbar_tomarkrdn===true?"#63120f":''}`}} onClick={K_gettomarkrdn}><span className="font-weight-bold pointer f3" style={{paddingLeft:"70px"}} >تۆمارکردن</span></td>
      <td style={{backgroundColor:`${k_navbar_froshtn===true?"#63120f":''}`}} onClick={K_getFroshtn}><span className="font-weight-bold  pointer f3" style={{paddingLeft:"80px"}} > فرۆشتن</span></td>
      <td style={{backgroundColor:`${k_navbar_garanawa===true?"#63120f":''}`}} onClick={K_getGaranawa}><span className="font-weight-bold  pointer f3" style={{paddingLeft:"80px"}} > گەڕانەوە</span></td>
    </div>
    


                           
    </>) 
        }}
  </ProductConsumer>
  </>

          )
    }

export default NavbarOfRaportKala
