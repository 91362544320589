import React from "react";
import { ProductConsumer } from "../../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const Footer = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { KF_Koy_Dolar, KF_Koy_Dinar } = value;
          return (
            <>
              <br />
              <div className="row mb4">
                <div className="col-md-12 ">
                  <div className="row">
                    <div className="col-md-12 tc">
                      <h6 className="text-warning tc">
                        ڕەسیدی کۆتایی معمیل بە دۆلار
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          value={KF_Koy_Dolar}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </h6>
                    </div>

                    <div className="col-md-12 tc">
                      <h6 className="text-warning tc">
                        ڕەسیدی کۆتایی معمیل بە دینار
                      </h6>
                      <h6 style={{ padding: "5px 30px" }} className="bg-white">
                        <NumberFormat
                          value={KF_Koy_Dinar}
                          thousandSeparator={true}
                          displayType="text"
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default Footer;
