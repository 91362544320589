import React from "react"
import { ProductConsumer } from '../../../Context/AddProWakel'
import NumberFormat from 'react-number-format'
import Time from 'react-time-format'
import {Link} from 'react-router-dom'

class ScroollTableKas extends React.Component {

    render() {
        return (
        
            <>
            <ProductConsumer >
                
            {value=>{
                    const {RT_data,RT_gotoParadanakan,RT_gotoMandub}=value
                        const wakelRaport=RT_data.map((user,i)=>{
                            return (
                            <tr key={i} className='fk f4'>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.nawewargr}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}} className={`${user.joripara==='دۆلار'?'dark-green':'dark-red'}`}>{user.joripara}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}><NumberFormat value={user.koywasl} thousandSeparator={true} displayType='text' /></td>
                                <td className="fk text-danger" style={{border:"2px solid #f0ad4e"}}>{user.wasl}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}>{user.jwlla}</td>
                                <td className="fk" style={{border:"2px solid #f0ad4e"}}><Time value={user.barwar}  format="YYYY-MM-DD" style={{color:'black'}} /></td>
                                <td className="fk" style={{border:"2px solid #f0ad4e", cursor:"pointer"}} onClick={(e)=>{user.jwlla==='پارەدان'?RT_gotoParadanakan(e,user.code):RT_gotoMandub(e,user.code)}}><Link to={`${user.jwlla==='پارەدان'?'/Paradan':(user.jwlla==="مەندوب"?'/Mandub':'/raporty-kasakan')}`}>{user.code}</Link></td>
                                
                            </tr>
                            )
                            
            
        })
    
        return (
           
            
                <div className="tableFixHead ">
               <table id="example" className="table  table-bordered text-right table-hover"  >
            <thead>
                <tr className='text-warning f4' style={{backgroundColor:"#2C3539"}}>
                    <th style={{border:"2px solid #f0ad4e"}}>ناوی وەرگر</th>
                    <th style={{border:"2px solid #f0ad4e"}}>دراو</th>
                    <th style={{border:"2px solid #f0ad4e"}}>کۆی پسوولە</th>
                    <th style={{border:"2px solid #f0ad4e"}}>و.کۆمپانیا</th>
                    <th style={{border:"2px solid #f0ad4e"}}>جوولە</th>
                    <th style={{border:"2px solid #f0ad4e"}}>بەروار</th>
                    <th style={{border:"2px solid #f0ad4e"}}>ژمارە</th>
                </tr>
            </thead>
            <tbody>
            {wakelRaport}
            </tbody>
        </table> 
        </div>
        )
            }}
            </ProductConsumer>
            
            </>
        )
    }
}
export default ScroollTableKas