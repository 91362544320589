import React from "react";
import Navbar from "../../../Navbar/Navbar";
import FormTakeMoney from "./FormTakeMoney";

const TakeMoney = () => {
  return (
    <>
      <Navbar />
      <div className="mt5"></div>
      <FormTakeMoney />
    </>
  );
};

export default TakeMoney;
