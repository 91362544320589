import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
const FooterOfKashe7esab = () => {
 return (
  <>
   <ProductConsumer>
    {(value) => {
     const { KF_Koy_Dolar, KF_Koy_Dinar ,P_getPrint} = value;
     return (
      <>
       {/* second row */}
       <div className="row">
                             <div className="col-md-12">
         <div className="row ">
                         <div className="col-lg-2 col-md-5">
           <h4 className="bg-white tc mt3 py-2">
            <NumberFormat
             value={Number(KF_Koy_Dolar).toFixed(2)}
             thousandSeparator={true}
             displayType="text"
             prefix={"$"}
            />
           </h4>
          </div>
                         <div className="col-lg-3 col-md-5 py-3">
           <h4 className="text-warning tc font-weight-bold ">
            ڕەسیدی کۆتایی معمیل بە دۆلار
           </h4>
          </div>

                         <div className="col-lg-2 col-md-6">
           <h4 className="bg-white tc mt3 py-2">
            <NumberFormat
             value={Number(KF_Koy_Dinar).toFixed(2)}
             thousandSeparator={true}
             displayType="text"
             prefix={"ID"}
            />
           </h4>
          </div>
                         <div className="col-lg-3 col-md-6 py-3">
           <h4 className="text-warning tc font-weight-bold ">
            {" "}
            ڕەسیدی کۆتایی معمیل بە دینار
           </h4>
          </div>
                         <div className="col-lg-2 col-md-6 py-3">
           <Link to="/print-kashf" onClick={P_getPrint}>
            {" "}
            <button className="tc btn btn-warning">Print</button>
           </Link>
          </div>
         </div>
        </div>
       </div>

       {/* third row */}
       <div className="row">
                 <div className="col-lg10 col-md-12"></div>
       </div>
      </>
     );
    }}
   </ProductConsumer>
  </>
 );
};

export default FooterOfKashe7esab;
