import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

class ScroollTableSummery extends React.Component {
  render() {
    return (
      <>
        <ProductConsumer>
          {(value) => {
            const {
              PS_all3arzKala,
              PS_code,
              PS_name,
            } = value;
            const wakelRaport = PS_all3arzKala.map((user, i) => {
              return (
                <tr key={i} className="f4">
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {Number(user.totalQuantitySold).toFixed(2)}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {Number(user.averagePrice).toFixed(2)}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {Number(user.totalRevenue).toFixed(2)}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {user.productId}
                  </td>
                  <td className="fk" style={{ border: "2px solid #f0ad4e" }}>
                    {i + 1}
                  </td>
                </tr>
              );
            });

            return (
              <div className="JM">
                <table
                  id="example"
                  className="table table-hover table-bordered text-right "
                >
                  <thead>
                    <tr
                      className="text-warning f4"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <th style={{ border: "2px solid #f0ad4e" }}>دانەی فرۆشراو</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>نرخ بۆ هەردانەیەک</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>نرخی بە گشتی</th>
                      <th style={{ border: "2px solid #f0ad4e" }}>
                        ناوەی کاڵا
                      </th>
                      <th style={{ border: "2px solid #f0ad4e" }}>X</th>
                    </tr>
                  </thead>
                  <tbody>{wakelRaport}</tbody>
                </table>
              </div>
            );
          }}
        </ProductConsumer>
      </>
    );
  }
}
export default ScroollTableSummery;
