import React from "react"
import { ProductConsumer } from '../../../Context/AddProWakel'
import NumberFormat from 'react-number-format'

class FormPrintWakel extends React.Component {

    render() {
        return (

            <>
                <ProductConsumer >

                    {value => {
                        const { W_dataWakel, W_show } = value

                        const wakil = W_dataWakel.map((user, i) => {
                            return (
                                <tr key={i} className="fk">
                                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>{user.adress}</td>
                                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>{W_show === true ? <NumberFormat value={Number(user.rejaDinar).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'ID'} className='f4' /> : "*********"}</td>
                                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>{W_show === true ? <NumberFormat value={Number(user.rejaDolar).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} className='f4' /> : '*********'}</td>
                                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>{user.phone}</td>
                                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>{user.Name}</td>
                                    <td className="fk" style={{ border: "2px solid #f0ad4e" }}>{user.code}</td>
                                </tr>
                            )


                        })

                        return (


                            <table id="example" className="table table-striped table-bordered text-right "  >
                                <thead>
                                    <tr className='text-black f4 fk'>
                                        <th style={{ border: "2px solid #f0ad4e" }}> ناونیشان</th>
                                        <th style={{ border: "2px solid #f0ad4e" }}>ID پارەی بەردەست بە</th>
                                        <th style={{ border: "2px solid #f0ad4e" }}>$ پارەی بەردەست بە</th>
                                        <th style={{ border: "2px solid #f0ad4e" }}>مۆبایل</th>
                                        <th style={{ border: "2px solid #f0ad4e" }}>ناو</th>
                                        <th style={{ border: "2px solid #f0ad4e" }}>کۆد</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {wakil}
                                </tbody>
                            </table>

                        )
                    }}
                </ProductConsumer>

            </>
        )
    }
}
export default FormPrintWakel