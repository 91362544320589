import React from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import NumberFormat from "react-number-format";

const FooterOfgaranawa = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            G_totalID,
            G_waslKrdn,
            G_waslKrdnID,
            G_total,
            G_paraDanID,
            G_paraDan$,
            G_joripara,
            G_rasidWakildolar,
            G_rasidWakildinar,
            G_rasidWakildolarNew,
            G_frosht,
          } = value;
          return (
            <>
              {G_joripara === "دۆلار" ? (
                <>
                  <div className="row fk">
                    <div className="div-col-5 ">
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={G_total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={G_paraDan$ === "" ? 0 : G_paraDan$}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          className=" border-0"
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={G_paraDan$ !== "" ? G_waslKrdn() : G_total}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </h3>
                      <hr />
                    </div>

                    <div className="div-col-4">
                      <h3 className=" ml5 mb3">:$ کۆی گشتی</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:$ پارەی دراو</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:$ پارەی نەدراو</h3>
                      <hr />
                    </div>
                    <div className="col-5">
                      <div className="row">
                        <div className="div-col-6 ">
                          {G_frosht === "فرۆشتنی معمیل" ? (
                            <>
                              <h3>
                                <NumberFormat
                                  value={G_rasidWakildinar}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"ID"}
                                />
                              </h3>
                              <hr />
                              <h3>
                                <NumberFormat
                                  value={G_rasidWakildolar}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </h3>
                              <hr />
                              <h3>
                                <NumberFormat
                                  value={G_rasidWakildolarNew}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />
                              </h3>
                              <hr />
                            </>
                          ) : null}
                        </div>

                        <div className="div-col-4">
                          {G_frosht === "فرۆشتنی معمیل" ? (
                            <>
                              <h3 className="ml5">:ID قەرزی کۆن</h3>
                              <hr />
                              <h3 className="ml5">:$ قەرزی کۆن</h3>
                              <hr />
                              <h3 className="ml5"> :$ قەرزی نوێ</h3>
                              <hr />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* start ID */}

              {G_joripara === "دینار" ? (
                <>
                  <div className="row fk">
                    <div className="div-col-5 ">
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={G_totalID}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"ID"}
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={G_paraDanID === "" ? 0 : G_paraDanID}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"ID"}
                          className=" border-0"
                        />
                      </h3>
                      <hr />
                      <h3 className="mb3 tc">
                        <NumberFormat
                          value={
                            G_paraDanID !== "" ? G_waslKrdnID() : G_totalID
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"ID"}
                        />
                      </h3>
                      <hr />
                    </div>
                    <div className="div-col-4">
                      <h3 className=" ml5 mb3">:ID کۆی گشتی</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:ID پارەی دراو</h3>
                      <hr />
                      <h3 className=" ml5 mb3">:ID پارەی نەدراو</h3>
                      <hr />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default FooterOfgaranawa;
