import React, { Component } from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import styled from "styled-components";
import { Link } from "react-router-dom";

class ModelKashfWakel extends Component {
  render() {
    return (
      <ProductConsumer>
        {(value) => {
          const {
            KF_from,
            KF_to,
            KF_openmodel,
            KF_zhmaraybarez,
            KF_nawe_barez,
            KF_CloseModal,
            KF_getWakilDataBySearchRent,
            KF_peshandan,
            AddProChange,
            KF_emprty,
          } = value;

          if (!KF_openmodel) {
            return null;
          } else {
            return (
              <ModalContainer>
                <div className="container">
                  <div className="row">
                    <div
                      id="modal"
                      className="col-lg-8 col-sm-12 mx-auto col-md-10 col-lg-6 text-center text-capitalize p-5"
                    >
                      <div className="row">
                        <div className="col-md-12 mb2">
                          <h3>ڕاپۆرتی کەشف حساب</h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            onChange={AddProChange}
                            name="KF_nawe_barez"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KF_getWakilDataBySearchRent(event)
                                : null
                            }
                            value={KF_nawe_barez}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="text"
                            onChange={AddProChange}
                            onClick={KF_emprty}
                            name="KF_zhmaraybarez"
                            onKeyPress={(event) =>
                              event.key === "Enter"
                                ? KF_getWakilDataBySearchRent(event)
                                : null
                            }
                            value={KF_zhmaraybarez === 0 ? "" : KF_zhmaraybarez}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-3">
                          <label className="text-danger f4 font-weight-bold">
                            معمیل
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-danger f4 font-weight-bold">
                              هەتاوەکو
                            </label>
                            <br />
                            <input
                              type="date"
                              onChange={AddProChange}
                              name="KF_to"
                              value={KF_to}
                              className="selectpicker btn btn-dark"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="text-danger f4 font-weight-bold">
                              لە
                            </label>
                            <br />
                            <input
                              type="date"
                              onChange={AddProChange}
                              name="KF_from"
                              value={KF_from}
                              className="selectpicker btn btn-dark"
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <Link to="/Product-Raport">
                            <button
                              className="btn btn-success"
                              onClick={() => KF_CloseModal()}
                            >
                              چوونەدەرەوە
                            </button>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/Raport-wakel-kashf">
                            <button
                              className="btn btn-info"
                              onClick={(e) => {
                                KF_peshandan(e);
                              }}
                            >
                              هەموو جووڵەکان
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalContainer>
            );
          }
        }}
      </ProductConsumer>
    );
  }
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  #modal {
    background: #f3e29f;
  }
`;

export default ModelKashfWakel;
