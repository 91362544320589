import React from "react";
import Navbar from "../../../Navbar/Navbar";
import ScroollTableQazanj from "./ScroollTableQazanj";
import NavbarOfQazanj from "./NavbarOfQazanj";
import FooterOfQazanj from "./FooterOfQazanj";
import Time from "react-time-format";
import { ProductConsumer } from "../../../Context/AddProWakel";
import AllMob from "./Mob/AllMob";
import { isMobile } from "react-device-detect";
const AllQazanj = () => {
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const { Q_from, Q_to, AddProChange, Q_getwasl } = value;
          return (
            <>
              <Navbar />
              {isMobile ? (
                <AllMob />
              ) : (
                <>
                  <div className="row" style={{ backgroundColor: "#141e30" }}>
                    <div
                        className="col-lg-10  col-md-12"
                      style={{ marginTop: "60px" }}
                    >
                      <div className="row">
                          <div className="col-md-2 mt4 tc">
                          <button
                            className="btn btn-warning"
                            onClick={() => {
                              Q_getwasl();
                            }}
                          >
                            پیشاندان
                          </button>
                        </div>
                          <div className="col-md-3 mt4">
                          <label
                            className=" font-weight-bold  text-warning"
                            style={{ fontWeight: "bolder" }}
                          >
                            <span className="text-white">
                              <input
                                type="date"
                                value={Q_to}
                                name="Q_to"
                                onChange={AddProChange}
                                className="selectpicker btn btn-info f4"
                              />
                            </span>{" "}
                            :بۆ بەرواری{" "}
                          </label>
                        </div>
                          <div className="col-md-3 mt4">
                          <label className=" font-weight-bold  text-warning">
                            <span className="text-white">
                              <input
                                type="date"
                                name="Q_from"
                                value={Q_from}
                                onChange={AddProChange}
                                className="selectpicker btn btn-info f4"
                              />
                            </span>{" "}
                            : لە بەرواری{" "}
                          </label>
                        </div>
                          <div className="col-md-4 tc" style={{ paddingTop: "7px" }}>
                          <h4 className="font-weight-bold f2 tc text-warning">
                            ڕاپۆرتی قازانج
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                      <div className="col-lg-10 col-md-8">
                      <ScroollTableQazanj />
                    </div>
                      <div className="col-lg-2 col-md-4">
                      <NavbarOfQazanj />
                    </div>
                  </div>
                  <div className="row" style={{ backgroundColor: "#141e30" }}>
                      <div className="col-md-12 col-md-12">
                      <FooterOfQazanj />
                    </div>
                  </div>
                </>
              )}
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default AllQazanj;
