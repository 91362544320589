import React, { useEffect, useState } from "react";
import { ProductConsumer } from "../../../Context/AddProWakel";
import Navbar from "../../../Navbar/Navbar";
import Instance from "../../../util/Instance";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";  

const Setting = () => {
  let today = new Date();
  let kat =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const [sales, setsales] = useState([])
  const [koga, setkoga] = useState([])
  const [wakil, setwakil] = useState([])
  const [kas, setkas] = useState([])
  const [rastawxo, setrastawxo] = useState([])
  const [paradan, setparadan] = useState([])
  const [parawargrtn, setparawargrtn] = useState([])
  const [garanawa, setgaranawa] = useState([])
  const [kompanya, setkompanya] = useState([])
  useEffect(async () => {
    const res = await Instance.get('/api/v1/allData')
    console.log('res', res)
    setsales(res.data.data.sale)
    setkoga(res.data.data.kalakan)
    setwakil(res.data.data.wakil)
    setkas(res.data.data.kasakan)
    setparadan(res.data.data.paradan)
    setparawargrtn(res.data.data.parawargrtn)
    setkompanya(res.data.data.kompanya)
    setgaranawa(res.data.data.garanawa)
  }, [])


  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      <ProductConsumer>
        {(value) => {
          const {
            AddProChange,
            U_currentpasword,
            U_newPassword,
            U_show,
            showpassword,
            U_newPassword2,
            onUpdatePassword,
            U_error,
            U_save,
            U_ClearError,
            labrdnisave,
          } = value;
          return (
            <>
              <Navbar />
              <div className="mt6"></div>
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <div className="card card-primary">
                    <div
                      className="card-header"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <h2 className="card-title tc text-warning font-weight-bold">
                        دابەزاندنی هەموو داتایەکان
                      </h2>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-3 d-flex justify-content-center">
                        <button className="btn btn-large btn-warning "
                          onClick={(e) => exportToCSV(sales, `${kat}-فرۆشتنەکان`)}
                        >⬇️فرۆشتنەکان</button>
                      </div>
                      <div className="col-md-3 d-flex justify-content-center">
                        <button className="btn btn-large btn-warning"
                          onClick={(e) => exportToCSV(koga, `${kat}-کاڵاکان`)}
                        >⬇️کاڵاکان</button>
                      </div>
                      <div className="col-md-3 d-flex justify-content-center">
                        <button className="btn btn-large btn-warning"
                          onClick={(e) => exportToCSV(wakil, `${kat}-وەکیلەکان`)}
                        >⬇️وەکیلەکان</button>
                      </div>
                      <div className="col-md-3 d-flex justify-content-center">
                        <button className="btn btn-large btn-warning "
                          onClick={(e) => exportToCSV(kas, `${kat}-کەسەکان`)}
                        >⬇️کەسەکان</button>
                      </div>
                      <div className="col-md-3 d-flex justify-content-center mt-5">
                        <button className="btn btn-large btn-warning "
                          onClick={(e) => exportToCSV(paradan, `${kat}-پارەدانەکان`)}
                        >⬇️پارەدانەکان</button>
                      </div>
                      <div className="col-md-3 d-flex justify-content-center mt-5">
                        <button className="btn btn-large btn-warning "
                          onClick={(e) => exportToCSV(parawargrtn, `${kat}-پارەوەرگتنەکان`)}
                        >⬇️پارەوەرگتنەکان</button>
                      </div>
                      <div className="col-md-3 d-flex justify-content-center mt-5">
                        <button className="btn btn-large btn-warning"
                          onClick={(e) => exportToCSV(kompanya, `${kat}-کۆمپانیاکان`)}
                        >⬇️کۆمپانیاکان</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="card card-primary">
                    <div
                      className="card-header"
                      style={{ backgroundColor: "#2C3539" }}
                    >
                      <h2 className="card-title tc text-warning font-weight-bold">
                        گۆڕینی وشەی نهێنی
                      </h2>
                    </div>

                    <form>
                      <div
                        className="card-body text-right  f4"
                        style={{ color: "#2C3539" }}
                      >
                        <div className="form-group">
                          <label className="font-weight-bold">
                            :وشەی نهێنی کۆن
                          </label>
                          <input
                            onChange={AddProChange}
                            name="U_currentpasword"
                            value={U_currentpasword}
                            type={`${U_show == false ? "password" : "text"}`}
                            className="form-control text-right font-weight-bold"
                            placeholder="...تۆمار کردن"
                          />
                        </div>
                        <div className="form-group">
                          <label className="font-weight-bold">
                            :وشەی نهێنی نوێ
                          </label>
                          <input
                            onChange={AddProChange}
                            name="U_newPassword"
                            value={U_newPassword}
                            type={`${U_show == false ? "password" : "text"}`}
                            className="form-control text-right font-weight-bold"
                            placeholder="...تۆمار کردن"
                          />
                        </div>

                        <div className="form-group">
                          <label className="font-weight-bold">
                            :وشەی نهێنی نوێ
                          </label>
                          <input
                            onChange={AddProChange}
                            name="U_newPassword2"
                            value={U_newPassword2}
                            type={`${U_show == false ? "password" : "text"}`}
                            className="form-control text-right font-weight-bold"
                            placeholder="...تۆمار کردن"
                          />
                        </div>
                      </div>

                      <div
                        className="card-footer"
                        style={{ backgroundColor: "#2C3539" }}
                      >
                        {U_save == false ? (
                          <button
                            type="button"
                            onClick={onUpdatePassword}
                            className="btn btn-warning fk"
                          >
                            گۆڕین
                          </button>
                        ) : (
                          <>
                              <button
                                type="button"
                                onClick={onUpdatePassword}
                                className="btn btn-primary fk"
                              >
                                گۆڕین
                              </button>
                              <button
                                type="button"
                                onDoubleClick={labrdnisave}
                                className="btn btn-md  btn-success px-4 ml3"
                              >
                                <i className="fas fa-check-double animate__animated animate__heartBeat animate__repeat-2"></i>
                              </button>
                            </>
                        )}
                        {U_show == false ? (
                          <button
                            type="button"
                            onClick={showpassword}
                            className="btn btn-primary ml4"
                          >
                            <i className="fas fa-eye animate__animated animate__heartBeat animate__repeat-2"></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={showpassword}
                            className="btn btn-primary ml4"
                          >
                            <i className="fas fa-eye-slash animate__animated animate__heartBeat animate__repeat-2"></i>
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="b--black text-right">
                    {U_error != null && (
                      <button
                        className="btn btn-success text-right"
                        type="button"
                      >
                        {U_error.msg ? U_error.msg : U_error.error[0].msg}
                        <span
                          className="ml-2  border-black"
                          onClick={() => U_ClearError()}
                        >
                          X
                        </span>
                      </button>
                    )}
                  </div>
                </div> */}
              </div>
            </>
          );
        }}
      </ProductConsumer>
    </>
  );
};

export default Setting;
