import React from "react";
import Navbar from "../../../Navbar/Navbar";
import WakelSecond from "./WakelSecond";
import WakilMob from "./Mob/WakilMob";
import { isMobile } from "react-device-detect";

const InputWakel = () => {
  return (
    <>
      <Navbar />
      <div className="mt5"></div>
      {isMobile ? <WakilMob /> : <WakelSecond />}
    </>
  );
};

export default InputWakel;
