import styled from 'styled-components'

export const ButtonContainer=styled.button`
text-transform:capitalize;
font-size:1.4rem;
background:transparent;
border:0.05rem solid var(--lightBlue);
border-color:${prop=>prop.cart?'var(--mainYellow)':'var(--lightBlue)'};
color:white;
// border-radius:0.5rem;
padding:10px 16px;
border:none;
cursor:pointer;
margin: 0 5px;
// transition:all 0.5s ease-in-out;
&:hover{
    background:#000C66;
}
&:focus{
    outline:none
}

`